import { Theme, useTheme } from '@mui/material/styles';
import { Box, Dialog, DialogContent, Grid } from '@mui/material';
import React from 'react';
import ContentContainer from 'components/ContentContainer';
import AlertIcon from 'components/icons/AlertIcon';
import { Button } from 'components/Button';
import MuiDialogTitle from '@mui/material/DialogTitle';
import Close from 'components/icons/Close';
import { Heading } from 'components/Heading';
import { SXCssObject } from 'models/ThemeImages';

const createCssObject = (theme: Theme): SXCssObject => ({
    root: {
        padding: {
            xs: '10px 0px',
            sm: '18px 0px',
        },
    },
    modalContent: {
        display: 'flex',
        justifyContent: 'center',
        margin: '0px 0px 30px',
        height: 'auto',
        padding: {
            xs: '8px 12px',
            sm: '0px 24px 20px',
        },
    },
    alertIconStyle: {
        marginBottom: '24px',
        display: 'flex',
        justifyContent: 'center',
    },
    centerContent: {
        display: 'flex',
        justifyContent: 'center',
        paddingBottom: '5px',
    },
    buttonStyle: {
        marginTop: '24px',
        display: 'flex',
        justifyContent: 'center',
    },
    modalPaper: {
        borderRadius: '4px',
        margin: '24px',
        width: {
            xs: '80%',
            sm: '504px',
        },
        minWidth: {
            xs: '290px',
            sm: 'inherit',
        },
    },
    fullWidth: {
        width: '100%',
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1.6),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
        cursor: 'pointer',
    },
    modalTitleContainer: {
        padding: '10px 24px 32px',
        position: 'relative',
    },
    textMsg: {
        mx: 1,
        textAlign: 'center',
        display: 'flex',
        justifyContent: 'center',
    },
});

interface Props {
    open: boolean;
    handleClose: (shouldProceed?: boolean) => void;
    confirmButtonText?: string;
    cancelButtonText?: string;
    confirmMsg?: any;
}

export function ConfirmationModal(props: Props) {
    const cssObject: SXCssObject = createCssObject(useTheme());
    const { confirmButtonText = 'YES', cancelButtonText = 'NO', confirmMsg = 'Do you really want to Proceed' } = props;

    const DialogTitle = (dialogProps: any) => {
        const { onClose } = dialogProps;
        return (
            <MuiDialogTitle sx={cssObject.modalTitleContainer}>
                <Box aria-label="close" sx={cssObject.closeButton} onClick={onClose}>
                    <Close />
                </Box>
            </MuiDialogTitle>
        );
    };

    return (
        <Dialog
            onClose={() => {}}
            open={props.open}
            maxWidth={'sm'}
            disableEscapeKeyDown={true}
            aria-describedby="alert-dialog-slide-description"
            PaperProps={{
                sx: cssObject.modalPaper,
            }}
        >
            <DialogTitle id="delete-dialog-title" onClose={() => props.handleClose(false)}></DialogTitle>

            <DialogContent sx={cssObject.modalContent}>
                <ContentContainer disableGrid sx={cssObject.fullWidth}>
                    <Box sx={cssObject.alertIconStyle}>
                        <AlertIcon />
                    </Box>
                    <Box sx={cssObject.centerContent}>
                        <Heading label={'Are You Sure?'} isPopUp />
                    </Box>
                    <Box sx={cssObject.textMsg}>{confirmMsg}</Box>
                    <Box sx={cssObject.buttonStyle}>
                        <Grid item xs={12}>
                            <Grid container spacing={1}>
                                <Grid item xs={12} sm={6} sx={cssObject.previousBtnStyle}>
                                    <Button
                                        id={`del-confirm-back-btn`}
                                        isContrast={true}
                                        onClick={() => props.handleClose(false)}
                                        isFullWidth={true}
                                    >
                                        {cancelButtonText}
                                    </Button>
                                </Grid>
                                <Grid item xs={12} sm={6} sx={cssObject.nextBtnStyle}>
                                    <Button
                                        isFullWidth={true}
                                        id={`del-confirm-ok-btn`}
                                        color={'primary'}
                                        disabled={false}
                                        onClick={() => props.handleClose(true)}
                                    >
                                        {confirmButtonText}
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Box>
                </ContentContainer>
            </DialogContent>
        </Dialog>
    );
}
