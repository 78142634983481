import React from 'react';
import { Theme, useTheme } from '@mui/material/styles';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Collapse from '@mui/material/Collapse';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Link } from './Link';
import { useNavigate } from 'react-router-dom';
import ArrowRightHome from './icons/ArrowRightHome';
import ArrowToggleDown from './icons/ArrowToggleDown';
import ArrowToggleUp from './icons/ArrowToggleUp';
import { SXCssObject } from 'models/ThemeImages';

const createCssObject = (theme: Theme): SXCssObject => ({
    root: {
        width: '100%',
        '& .MuiCollapse-wrapperInner': {
            '& $linkItem:last-child': {
                borderBottomWidth: 0,
            },
        },
    },
    menuToggle: {
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
        border: `1px solid ${theme.palette.primary.contrastText}`,
        borderRadius: '4px',
    },
    menuToggleText: {
        margin: 0,
    },
    linkItem: {
        paddingTop: theme.spacing(1),
        fontSize: "14px",
        fontWeight: 500,
        paddingBottom: theme.spacing(1),
        borderRadius: '4px',
        justifyContent: 'space-between',
        textTransform: 'uppercase',
        '&.Mui-selected': {
            border: `1px solid ${theme.palette.primary.contrastText} !important`,
        },
    },
    noBg: {
        backgroundColor: 'inherit',
        '&:hover': {
            backgroundColor: 'inherit',
        },
        '&.Mui-selected': {
            backgroundColor: 'inherit',
            '&:hover': {
                backgroundColor: 'inherit',
            },
        },
    },
    menuItem: {
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
        border: `1px solid ${theme.palette.divider}`,
        justifyContent: 'space-between',
        borderRadius: '1px',
        borderTopWidth: 0,
        fontSize: "14px",
        textTransform: 'uppercase',
        fontWeight: 500,
    },
    arrowRightAltIcon: {
        color: theme.palette.primary.main,
    },
    arrowIcon: {
        transform: 'rotate(180deg)',
    },
});

export interface Menu {
    id: string;
    onClick?: () => void;
    href?: string;
    color: 'primary' | 'secondary';
    selected?: boolean;
    label: string;
    ref?: React.RefObject<HTMLDivElement>;
}

export interface Props extends Menu {
    subMenus: Menu[];
    onSelect?: (id: string, ref: React.RefObject<HTMLDivElement>) => void;
}

export function SecondaryNavigation(props: Props) {
    const classes: SXCssObject = createCssObject(useTheme());
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));
    const navigate = useNavigate();
    const [open, setOpen] = React.useState(false);
    const [collapseTimeout, setCollapseTimeout] = React.useState<number | 'auto'>(0);
    const { subMenus = [], onSelect } = props;
    const handleClick = () => {
        if (subMenus.length > 0) {
            setCollapseTimeout('auto');
            setOpen(!open);
        } else if (props.onClick) {
            props.onClick();
        }
    };
    const getKnowledgeCenterLable = () => {
        return props.subMenus.filter((subMenu) => subMenu.selected);
    };
    return (
        <List component="nav" sx={classes.root}>
            {isMobile && (
                <ListItem
                    sx={[classes.menuToggle, classes.noBg]}
                    selected={props.selected}
                    id={props.id}
                    button
                    onClick={() => handleClick()}
                >
                    <ListItemText
                        secondary={
                            <Link
                                active
                                label={(getKnowledgeCenterLable() && getKnowledgeCenterLable()[0].label) || ''}
                                color={'primary'}
                            />
                        }
                        sx={classes.menuToggleText}
                    />

                    {isMobile && subMenus.length > 0 && (open ? <ArrowToggleUp /> : <ArrowToggleDown />)}
                </ListItem>
            )}
            {subMenus.length > 0 && (
                <Collapse in={isMobile ? open : true} timeout={collapseTimeout} unmountOnExit>
                    {subMenus.map((menu, index) => (
                        <ListItem
                            key={`menu-item-${index}`}
                            sx={[isMobile && classes.menuItem, !isMobile && classes.linkItem, true && classes.noBg]}
                            selected={menu.selected}
                            id={menu.id}
                            button
                            onClick={() => {
                                setOpen(!open);
                                return menu.href
                                    ? navigate(menu.href)
                                    : menu.ref && menu.id && !!onSelect
                                        ? onSelect(menu.id, menu.ref)
                                        : navigate('#');
                            }}
                        >
                            {/* <Link
                                key={`menu-item-link-${index}`}
                                href={menu.href}
                                label={menu.label}
                                color={'primary'}
                                active={menu.selected}
                            /> */}
                            {menu.label}
                            {menu.selected && <ArrowRightHome sx={classes.arrowRightAltIcon} />}
                        </ListItem>
                    ))}
                </Collapse>
            )}
        </List>
    );
}
