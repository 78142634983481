import heroDesktop1 from 'assets/images/hero/homevestors_hero_big1.webp';
import heroSmall1 from 'assets/images/hero/homevestors_hero_small1.webp';
import heroDesktop2 from 'assets/images/hero/homevestors_hero_big2.webp';
import heroSmall2 from 'assets/images/hero/homevestors_hero_small2.webp';
import heroDesktop3 from 'assets/images/hero/homevestors_hero_big3.webp';
import heroSmall3 from 'assets/images/hero/homevestors_hero_small3.webp';
import heroDesktop4 from 'assets/images/hero/homevestors_hero_big4.webp';
import heroSmall4 from 'assets/images/hero/homevestors_hero_small4.webp';
import heroDesktop5 from 'assets/images/hero/homevestors_hero_big5.webp';
import heroSmall5 from 'assets/images/hero/homevestors_hero_small5.webp';
import heroDesktop6 from 'assets/images/hero/homevestors_hero_big6.webp';
import heroSmall6 from 'assets/images/hero/homevestors_hero_small6.webp';
import heroDesktop7 from 'assets/images/hero/homevestors_hero_big7.webp';
import heroSmall7 from 'assets/images/hero/homevestors_hero_small7.webp';
import heroDesktop8 from 'assets/images/hero/homevestors_hero_big8.webp';
import heroSmall8 from 'assets/images/hero/homevestors_hero_small8.webp';
import heroDesktop9 from 'assets/images/hero/homevestors_hero_big9.webp';
import heroSmall9 from 'assets/images/hero/homevestors_hero_small9.webp';
import heroDesktop10 from 'assets/images/hero/homevestors_hero_big10.webp';
import heroSmall10 from 'assets/images/hero/homevestors_hero_small10.webp';
import homeFinancingImage from 'assets/images/t3_home_financing_image.webp';
import logoMobile from 'assets/images/logo/homevestors_logo_small.png';
import logoDesktop from 'assets/images/logo/homevestors_logo_big.png';
import { ThemeContent, ThemeImages } from 'models/ThemeImages';
import { ThemeOptions } from '@mui/material/styles';
import { customPropertiesObj, muiComponentTheme } from './CommonThemeProperties';

declare module '@mui/material/styles' {
    interface Theme {
        customProperties?: any;
    }
    // allow configuration using `createTheme`
    interface ThemeOptions {
        customProperties?: any;
    }
}
export const homeVestorsImages: ThemeImages = {
    homeFinancingImage,
    logoMobile,
    logoDesktop,
    homePage: {
        heroDesktop: [
            { imageUrl: heroDesktop1 },
            { imageUrl: heroDesktop2 },
            { imageUrl: heroDesktop3 },
            { imageUrl: heroDesktop4 },
            { imageUrl: heroDesktop5 },
            { imageUrl: heroDesktop6 },
            { imageUrl: heroDesktop7 },
            { imageUrl: heroDesktop8 },
            { imageUrl: heroDesktop9 },
            { imageUrl: heroDesktop10 },
        ],
        heroSmall: [
            { imageUrl: heroSmall1 },
            { imageUrl: heroSmall2 },
            { imageUrl: heroSmall3 },
            { imageUrl: heroSmall4 },
            { imageUrl: heroSmall5 },
            { imageUrl: heroSmall6 },
            { imageUrl: heroSmall7 },
            { imageUrl: heroSmall8 },
            { imageUrl: heroSmall9 },
            { imageUrl: heroSmall10 },
        ],
    },
};
const FONTFAMILY_PARAGRAPH = 'Carrois Gothic';
const FONTFAMILY_HEADING = 'IBM Plex Sans';
const COLOR_HEADING = '#1F1F1F';
export const homeVestorsTheme: ThemeOptions = {
    components: { ...muiComponentTheme },
    palette: {
        background: {
            default: '#f5f5f5',
        },
        primary: {
            main: '#EE3E33', // variable; Button background
            light: '#FFF3F2', // variable; light primary
            dark: '#D52217', // variable; button hover
            contrastText: '#F7B8B4', // primary medium light color
        },
        secondary: {
            main: '#F5F5F5', //Secondary button background color
            light: '#3A3A3A', // Secondary button text color
            dark: '#E1E1E1', //Secondary button background(on hover) color
            contrastText: '#1DBAAF', // Secondary button disabled.
        },
        text: {
            primary: '#4A4A4A', // Text
            secondary: '#FFFFFF', // Text
        },
        divider: '#CCCCCC', // also used in border color
        action: {
            active: COLOR_HEADING, // Link active/hover state
            selected: '#8F9197', // variable; Wizard button selected
            hover: '#3A3A3A', // Outlined button border color in hover state
            disabled: '#E1E1E1',
        },
    },
    customProperties: {
        ...customPropertiesObj,
        svgPrimaryColor: '#1DBAAF',
        svgPrimaryLightColor: '#1DBAAF20',
    },
    typography: {
        fontWeightMedium: 600,
        h1: {
            fontFamily: FONTFAMILY_HEADING, // variable
            fontWeight: 700, // variable
            fontSize: '2.6875rem',
            color: COLOR_HEADING,
            lineHeight: '3.125rem',
            letterSpacing: 0,
            textTransform: 'uppercase',
        },
        h2: {
            fontFamily: FONTFAMILY_HEADING, // variable
            fontWeight: 700, // variable
            fontSize: '2rem', //
            color: COLOR_HEADING,
            lineHeight: '2.25rem',
            letterSpacing: 0,
        },
        h3: {
            fontFamily: FONTFAMILY_HEADING, // variable
            fontWeight: 700, // variable
            fontSize: '1.5rem',
            color: COLOR_HEADING,
            lineHeight: '1.75rem',
            letterSpacing: 0,
        },
        h4: {
            fontFamily: FONTFAMILY_HEADING, // variable
            fontWeight: 700, // variable
            fontSize: '1.25rem',
            color: COLOR_HEADING,
            lineHeight: '1.75rem',
            letterSpacing: 0,
        },
        h5: {
            fontFamily: FONTFAMILY_HEADING, // variable
            fontWeight: 700, // variable
            fontSize: '1rem',
            color: COLOR_HEADING,
            lineHeight: '1.75rem',
            letterSpacing: 0,
            textTransform: 'uppercase',
        },
        body1: {
            // Paragraph
            fontFamily: FONTFAMILY_PARAGRAPH, // variable
            fontWeight: 400, // variable
            fontSize: '1rem',
            lineHeight: '1.5rem',
            letterSpacing: 0,
        },
        body2: {
            // Paragraph Small
            fontFamily: FONTFAMILY_PARAGRAPH, // variable
            fontWeight: 400, // variable
            fontSize: '0.875rem',
            lineHeight: '1.25rem',
            letterSpacing: 0,
        },
        subtitle1: {
            // Link
            fontFamily: FONTFAMILY_PARAGRAPH, // variable
            fontWeight: 600, // variable
            fontSize: '0.875rem',
            lineHeight: '1.25rem',
            letterSpacing: 0,
            textTransform: 'uppercase',
            color: COLOR_HEADING,
        },
        subtitle2: {
            // Footer
            fontFamily: FONTFAMILY_PARAGRAPH, // variable
            fontWeight: 600, // variable
            fontSize: '0.75rem',
            lineHeight: '1rem',
            letterSpacing: 0,
            textTransform: 'uppercase',
            color: '#6D6D6D',
        },
        button: {
            // Button
            fontFamily: FONTFAMILY_PARAGRAPH, // variable
            fontWeight: 600, // variable
            fontSize: '0.875rem',
            lineHeight: '1.25rem',
            textAlign: 'center',
            textTransform: 'uppercase',
        },
    },
    spacing: 10,
    shape: {
        borderRadius: 4,
    },
};
export const homeVestorsContent: ThemeContent = {
    label: {
        shippingAddressLabel: 'Property',
    },
    error: {
        invalidDomainErrorMsg: 'Please enter Homevestors email address',
    },
};

export default homeVestorsTheme;
