import React, { Fragment } from 'react';
import { Props as MenuProps } from 'components/SecondaryNavigation';
import { Theme, useTheme } from '@mui/material/styles';
import SubPage from 'components/subpage/SubPage';
import Grid from '@mui/material/Grid';
import CardMedia from '@mui/material/CardMedia';
import { Heading } from 'components/Heading';
import { Paragraph } from 'components/Paragraph';
import { getSubPageMenus } from './menu-util';
import { Box, Hidden } from '@mui/material';
import HowHVACWorksImage from 'components/icons/HowHVACWorksImage';
import { getThemePrimaryMain } from 'components/common/Utilities';
import { SXCssObject } from 'models/ThemeImages';

function HowHVACWorks() {
    const createCssObject = (theme: Theme): SXCssObject => {
        return {
            contentHeading: {
                paddingBottom: theme.spacing(2),
            },
            contentImage: {
                marginTop: theme.spacing(2),
                backgroundColor: theme.customProperties.knowledgeCenterImageBackground,
            },
            contentInteractive: {
                paddingTop: theme.spacing(4),
                paddingBottom: theme.spacing(4),
            },
            contentSecondaryHeading: {
                paddingTop: {
                    xs: theme.spacing(2),
                    sm: theme.spacing(4),
                },
                paddingBottom: theme.spacing(2),
            },
            content: {
                paddingTop: theme.spacing(2),
            },
            image: {
                width: {
                    sm: theme.spacing(50.5),
                },
                margin: 'auto',
            },
            listPoint: {
                backgroundColor: getThemePrimaryMain(theme),
                borderRadius: '50%',
                padding: theme.spacing(0, 0.9, 0, 0.9),
                fontSize: '12px',
                color: theme.palette.common.white,
                marginRight: '5px',
                marginTop: '4px',
            },
            listPointContainer: {
                padding: {
                    xs: '5%',
                    sm: '5% 20%',
                },
            },
            listPointItem: {
                padding: '1px 5px',
            },
        };
    };

    const menu: MenuProps = {
        id: 'menu',
        label: 'More Knowledge Center Topic',
        color: 'primary',
        subMenus: getSubPageMenus('how_hvac_works'),
    };
    const cssObject = createCssObject(useTheme());
    return (
        <SubPage
            menu={menu}
            content1={
                <Fragment>
                    <Grid item xs={12} sx={cssObject.contentHeading}>
                        <Heading variant={'h3'} isPopUp={true} color={'primary'} label={'How HVAC Works'} />
                    </Grid>
                    <Grid item xs={12}>
                        <Paragraph responsiveFontSize16 align={'left'}>
                            Not exactly sure what HVAC means or how it works in your home? You’re not alone.
                        </Paragraph>
                    </Grid>
                    <Grid item xs={12} sx={cssObject.content}>
                        <Paragraph responsiveFontSize16 align={'left'}>
                            We have put together some information to give you a crash course on one of your very
                            important home assets.
                        </Paragraph>
                    </Grid>
                    <Grid item xs={12} sx={cssObject.content}>
                        <Paragraph responsiveFontSize16 align={'left'}>
                            So what is HVAC? Heating, Ventilation, & Air Conditioning (HVAC) systems in your home are
                            what keeps you and your family comfortable by heating and cooling the air indoors.
                        </Paragraph>
                    </Grid>

                    <Grid item xs={12} sx={cssObject.contentImage}>
                        <CardMedia style={{ overflowY: 'auto' }}>
                            <HowHVACWorksImage />
                        </CardMedia>
                        <Grid>
                            <Hidden only={['xs']}>
                                <Grid sx={cssObject.listPointContainer} container spacing={2}>
                                    <Grid item container alignItems={'center'} xs={6} sm={6}>
                                        <Box sx={cssObject.listPoint}>1</Box>
                                        <Paragraph>Supply Air Duct</Paragraph>
                                    </Grid>
                                    <Grid item container alignItems={'center'} xs={6} sm={6}>
                                        <Box sx={cssObject.listPoint}>5</Box>
                                        <Paragraph>Air Conditioner</Paragraph>
                                    </Grid>
                                    <Grid item container alignItems={'center'} xs={6} sm={6}>
                                        <Box sx={cssObject.listPoint}>2</Box>
                                        <Paragraph>Register</Paragraph>
                                    </Grid>
                                    <Grid item container alignItems={'center'} xs={6} sm={6}>
                                        <Box sx={cssObject.listPoint}>6</Box>
                                        <Paragraph>Refrigerator Lines</Paragraph>
                                    </Grid>
                                    <Grid item container alignItems={'center'} xs={6} sm={6}>
                                        <Box sx={cssObject.listPoint}>3</Box>
                                        <Paragraph>Indoor Cool</Paragraph>
                                    </Grid>
                                    <Grid item container alignItems={'center'} xs={6} sm={6}>
                                        <Box sx={cssObject.listPoint}>7</Box>
                                        <Paragraph>Thermostat</Paragraph>
                                    </Grid>
                                    <Grid item container alignItems={'center'} xs={6} sm={6}>
                                        <Box sx={cssObject.listPoint}>4</Box>
                                        <Paragraph>Furnace</Paragraph>
                                    </Grid>
                                </Grid>
                            </Hidden>
                            <Hidden smUp>
                                <Grid sx={cssObject.listPointContainer} container spacing={1}>
                                    <Grid item container sx={cssObject.listPointItem} alignItems={'center'} xs={12}>
                                        <Box sx={cssObject.listPoint}>1</Box>
                                        <Paragraph>Supply Air Duct</Paragraph>
                                    </Grid>

                                    <Grid item container alignItems={'center'} xs={5}>
                                        <Box sx={cssObject.listPoint}>2</Box>
                                        <Paragraph>Register</Paragraph>
                                    </Grid>
                                    <Grid item container alignItems={'center'} xs={6} sm={6}>
                                        <Box sx={cssObject.listPoint}>5</Box>
                                        <Paragraph>Air Conditioner</Paragraph>
                                    </Grid>
                                    <Grid item container alignItems={'center'} xs={5} sm={6}>
                                        <Box sx={cssObject.listPoint}>3</Box>
                                        <Paragraph>Indoor Cool</Paragraph>
                                    </Grid>
                                    <Grid item container alignItems={'center'} xs={7} sm={6}>
                                        <Box sx={cssObject.listPoint}>6</Box>
                                        <Paragraph>Refrigerator Lines</Paragraph>
                                    </Grid>
                                    <Grid item container alignItems={'center'} xs={5} sm={6}>
                                        <Box sx={cssObject.listPoint}>4</Box>
                                        <Paragraph>Furnace</Paragraph>
                                    </Grid>
                                    <Grid item container alignItems={'center'} xs={6} sm={6}>
                                        <Box sx={cssObject.listPoint}>7</Box>
                                        <Paragraph>Thermostat</Paragraph>
                                    </Grid>
                                </Grid>
                            </Hidden>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} sx={cssObject.content}>
                        <Paragraph responsiveFontSize16 align={'left'}>
                            There is no “one size fits all” choice for equipment. Because size, type and circumstances
                            vary from one home to the next, the equipment used to maintain comfortable temperatures in
                            your home will also vary.
                        </Paragraph>
                    </Grid>
                    <Grid item xs={12} sx={cssObject.content}>
                        <Paragraph responsiveFontSize16 align={'left'}>
                            Keeping your home comfortable is all about heat transfer. In the winter months when it's
                            cold, the HVAC equipment in your home transfers heat by adding heat to the air using a gas
                            furnace, electric heat coil/air handler, or heat pump and uses a fan to circulate that air
                            throughout your home.
                        </Paragraph>
                    </Grid>
                    <Grid item xs={12} sx={cssObject.content}>
                        <Paragraph responsiveFontSize16 align={'left'}>
                            During the summer months, your system works a little counter intuitive. Instead of simply
                            "blowing" cold air throughout your home, your HVAC system actually uses refrigerant to
                            absorb heat and humidity from inside your home and then transfers that heat outside. The
                            system circulates air over a coil with refrigerant flowing inside it. As the air passes over
                            the coil, the refrigerant absorbs the heat from the air and moves that heat outside to your
                            outdoor air conditioner or heat pump, where it releases the heat outdoors and recirculates
                            refrigerant back inside to start the process again. With the heat removed, the cool air you
                            feel coming out of the vents is how your HVAC system actually "cools off" your home.
                        </Paragraph>
                    </Grid>
                </Fragment>
            }
        />
    );
}

export default HowHVACWorks;
