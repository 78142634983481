import { Grid, Container, Theme, Divider } from '@mui/material';
import { Formik, FormikValues } from 'formik';
import React, { useRef } from 'react';
import PhoneField from '../../components/FormikPhoneField';
import Navigator from '../../components/Navigator';
import { Paragraph } from '../../components/Paragraph';
import { validate as validatePhone } from '../../validations/phoneNumber';
import FormikTextField from '../../components/FormikTextField';
import ContentContainer from '../../components/ContentContainer';
import { useDispatch, useSelector } from 'react-redux';
import { selectLoginState } from '../../store/selectors/loginSelector';
import { Heading } from '../../components/Heading';
import { Address, AddressType } from '../../models/cart';
import SelectInput from '../../components/common/SelectInput';
import { selectRegions } from '../../store/selectors/country';
import { Button } from '../../components/Button';
import { updateUserProfileSchema } from '../wizard/wizard-schema';
import {
    addDefaultProfileBillingAddress,
    getProfileAddresses,
    performDefaultBillingAddressUpdate,
} from '../../store/actions/addressActions';
import LoadingOverlay from '../../components/common/LoadingOverlay';
import { ContactUsRequest } from '../../models/contact';
import InfoIcon from 'assets/icons/icon_info.svg';
import { SXCssObject } from 'models/ThemeImages';
import useTheme from '@mui/system/useTheme';
import { AddressValidationModal } from 'components/AddressValidationModal';
import {
    AddressValidationFields,
    AddressValidationModalData,
    AddressValidationStatus,
    ValidateAddressResponse,
} from 'models/userProfile';
import { addressValidate } from 'store/services/userProfileServices';
import { extractErrorFromResponseOrShowDefaultMsg } from 'components/common/Utilities';

const createCssObject = (theme: Theme): SXCssObject => ({
    rootContainer: {
        margin: theme.spacing(3, 0),
        borderRadius: '4px',
    },
    formContainer: {
        padding: theme.spacing(2),
    },
    heading: {
        marginTop: {
            xs: theme.spacing(3.5),
            md: theme.spacing(5),
        },
    },
    buttonContainer: {
        textAlign: 'end',
    },
    infoIcon: {
        display: 'flex',
        alignItems: 'center',
    },
});

export function EditMyProfile() {
    const cssObject: SXCssObject = createCssObject(useTheme());
    const dispatch = useDispatch();
    let loginData = useSelector(selectLoginState);
    let mounted: any = useRef();
    React.useEffect(() => {
        if (!mounted.current) {
            mounted.current = true;
            dispatch(getProfileAddresses());
        }
    });
    const regions = useSelector(selectRegions);
    const _prefillRegion: any = null;
    const [addressRegion, setAddressRegion] = React.useState(_prefillRegion);
    const [showPhoneValidMessage, setShowPhoneValidMessage] = React.useState(false);
    const [validatingPhone, setValidatingPhone] = React.useState(false);
    const [checkedPhones, setCheckedPhones] = React.useState(new Array<{ phone: string; valid: boolean }>());
    const [open, setOpen] = React.useState(false);
    const [loader, setLoader] = React.useState(false);
    const [nonValidAddresses, setNonValidAddresses] = React.useState<AddressValidationModalData[]>([]);
    const country = 'US';

    const validate = async (values: FormikValues) => {
        // validate phoneNumber
        const validatePhoneMsg = await validatePhone(values.telephone?.replace(/_|\./gi, ''), {
            isMounted: true,
            setShowValidMessage: setShowPhoneValidMessage,
            setValidating: setValidatingPhone,
            setCheckedPhones: setCheckedPhones,
            checkedPhones: checkedPhones,
        });
        if (validatePhoneMsg) {
            return {
                phone: validatePhoneMsg,
            };
        }
    };
    if (!addressRegion) {
        const val =
            regions?.find((reg) => reg.region === loginData.defaultProfileAddress?.region) ||
            (regions ? regions[0] : null);
        setAddressRegion(val);
    }
    const handleUpdateAddress = (values: FormikValues) => {
        const address = {
            city: nonValidAddresses[0].suggestedAddress?.city,
            zip: nonValidAddresses[0].suggestedAddress?.postcode,
            state: nonValidAddresses[0].suggestedAddress?.region,
        };
        setAddressRegion(address.state);
        const newValues = {
            ...values,
            //updating profile address only based on suggested address
            city: address.city,
            zip: address.zip,
        };
        handleUpdateProfile(newValues);
    };
    const handleValidateAddress = (values: FormikValues) => {
        setLoader(true);
        const addressValidationObj: AddressValidationFields[] = [
            {
                postcode: values?.postcode,
                city: values?.city,
                region: addressRegion?.display,
                country: country,
            },
        ];
        addressValidate(addressValidationObj).subscribe({
            next: (resp) => {
                let validateAddress: AddressValidationModalData[] = [];
                let areAllAddressesValid = true;
                resp.data.forEach((item: ValidateAddressResponse, index: number) => {
                    if (item.status !== AddressValidationStatus.Valid) {
                        areAllAddressesValid = false;
                        validateAddress.push({
                            status: item.status,
                            suggestedAddress: item?.suggestedAddress,
                            reason: item?.reason || '',
                            addressEntered: values,
                            flow: AddressType.Properties,
                        });
                    }
                });
                //if both the addresses are valid
                if (areAllAddressesValid) {
                    handleUpdateProfile(values);
                } else {
                    setNonValidAddresses(validateAddress);
                    setOpen(true);
                }
            },
            error: (err) => {
                setOpen(false);
                extractErrorFromResponseOrShowDefaultMsg(err, 'Unable to Validate Address');
            },
            complete: () => {
                setLoader(false);
            },
        });
    };
    const handleUpdateProfile = (values: FormikValues) => {
        let requestBody: Address = {
            ...values,
            defaultShipping:
                (loginData && loginData.defaultProfileAddress && loginData.defaultProfileAddress?.defaultShipping) ||
                false,
            defaultBilling: true,
            region: addressRegion.region,
            country: country,
            firstname: values.defaultBillingFirstname,
            lastname: values.defaultBillingLastname,
        };
        let contactDetailsRequestBody: ContactUsRequest = {
            firstname: values.firstname,
            lastname: values.lastname,
            email: values.email,
            phone: values.telephone,
        };
        delete requestBody['email'];
        delete requestBody['defaultBillingFirstname'];
        delete requestBody['defaultBillingLastname'];
        if (requestBody && loginData.defaultProfileAddress?.id && loginData.defaultProfileAddress.defaultBilling) {
            dispatch(
                performDefaultBillingAddressUpdate({
                    Address: requestBody,
                    addressId: loginData.defaultProfileAddress.id,
                    contactDetails: contactDetailsRequestBody,
                })
            );
        } else if (requestBody && !loginData.defaultProfileAddress?.defaultBilling) {
            dispatch(
                addDefaultProfileBillingAddress({ Address: requestBody, contactDetails: contactDetailsRequestBody })
            );
        }
    };
    return (
        <div>
            <LoadingOverlay open={loginData.loading || loader} />
            <ContentContainer disableGrid background={'bg3'}>
                <ContentContainer>
                    <Navigator color={'primary'} transparent={false} />
                </ContentContainer>
            </ContentContainer>
            <ContentContainer>
                <Grid style={{ marginBottom: '60px' }}>
                    <Grid container>
                        <Grid item style={{ width: '100%' }}>
                            <Formik
                                enableReinitialize={true}
                                initialValues={{
                                    firstname: loginData.firstname,
                                    lastname: loginData.lastname,
                                    defaultBillingFirstname: loginData.defaultProfileAddress
                                        ? loginData.defaultProfileAddress.firstname
                                        : '',
                                    defaultBillingLastname: loginData.defaultProfileAddress
                                        ? loginData.defaultProfileAddress.lastname
                                        : '',
                                    telephone: loginData.phone,
                                    email: loginData.email,
                                    addressName: loginData.defaultProfileAddress
                                        ? loginData.defaultProfileAddress.addressName
                                        : '',
                                    address1: loginData.defaultProfileAddress
                                        ? loginData.defaultProfileAddress.address1
                                        : '',
                                    address2: loginData.defaultProfileAddress
                                        ? loginData.defaultProfileAddress.address2
                                        : '',
                                    city: loginData.defaultProfileAddress ? loginData.defaultProfileAddress.city : '',
                                    country: loginData.defaultProfileAddress
                                        ? loginData.defaultProfileAddress.country
                                        : country,
                                    postcode: loginData.defaultProfileAddress
                                        ? loginData.defaultProfileAddress.postcode
                                        : '',
                                }}
                                onSubmit={(values) => {
                                    handleValidateAddress(values);
                                }}
                                validateOnMount={true}
                                validationSchema={updateUserProfileSchema}
                                validate={validate}
                                initialTouched={{
                                    telephone: true,
                                }}
                            >
                                {({ submitForm, isValid, values }) => (
                                    <>
                                        <Grid
                                            container
                                            justifyContent={'space-between'}
                                            alignItems={'center'}
                                            sx={cssObject.heading}
                                        >
                                            <Grid xs={6} item>
                                                <Heading label={'Edit My Profile'}></Heading>
                                            </Grid>
                                            <Grid xs={5} sx={cssObject.buttonContainer} item>
                                                <Button
                                                    disabled={!isValid}
                                                    color={'primary'}
                                                    onClick={() => submitForm()}
                                                >
                                                    UPDATE
                                                </Button>
                                            </Grid>
                                        </Grid>
                                        {open && (
                                            <AddressValidationModal
                                                open={open}
                                                handleClose={() => {
                                                    setOpen(false);
                                                }}
                                                handleSubmit={() => {
                                                    setOpen(false);
                                                    handleUpdateAddress(values);
                                                }}
                                                addressData={nonValidAddresses}
                                                handleEdit={() => {
                                                    setOpen(false);
                                                }}
                                            />
                                        )}
                                        <ContentContainer sx={cssObject.rootContainer} disableGrid background={'bg3'}>
                                            <Container maxWidth={'lg'} disableGutters>
                                                <Grid container sx={cssObject.formContainer}>
                                                    <Grid item xs={12}>
                                                        <Grid container spacing={2} alignItems="flex-start">
                                                            <Grid item xs={12} sm={6}>
                                                                <FormikTextField
                                                                    label={'First Name *'}
                                                                    id={'firstname'}
                                                                    name="firstname"
                                                                    placeholder={'First Name'}
                                                                    fontWeightIsMedium={true}
                                                                />
                                                            </Grid>
                                                            <Grid item xs={12} sm={6}>
                                                                <FormikTextField
                                                                    label={'Last Name *'}
                                                                    id={'lastname'}
                                                                    name="lastname"
                                                                    placeholder={'Last Name'}
                                                                    fontWeightIsMedium={true}
                                                                />
                                                            </Grid>
                                                            <Grid item xs={12} sm={6}>
                                                                <FormikTextField
                                                                    label={'Email Address *'}
                                                                    id={'email'}
                                                                    shouldDisableInputField
                                                                    name="email"
                                                                    placeholder={'Email'}
                                                                    fontWeightIsMedium={true}
                                                                />
                                                            </Grid>
                                                            <Grid item xs={12} sm={6}>
                                                                <PhoneField
                                                                    label={'Phone *'}
                                                                    id={'telephone'}
                                                                    name={'telephone'}
                                                                    placeholder={'000.000.0000'}
                                                                    fullWidth
                                                                    enableErrorDisplay={showPhoneValidMessage}
                                                                    validating={validatingPhone}
                                                                    fontWeightIsMedium={true}
                                                                />
                                                            </Grid>
                                                        </Grid>
                                                        <br />
                                                        <Divider />
                                                        <br />
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <Grid item>
                                                            <Paragraph fontColor semibold fontSizeMediumSmall>
                                                                Billing Address
                                                            </Paragraph>
                                                        </Grid>
                                                        <Grid
                                                            container
                                                            spacing={1}
                                                            alignItems={'center'}
                                                            style={{ padding: '10px 0px' }}
                                                        >
                                                            <Grid item sx={cssObject.infoIcon}>
                                                                <img
                                                                    alt={'Info'}
                                                                    src={InfoIcon}
                                                                    style={{
                                                                        width: '20px',
                                                                    }}
                                                                />
                                                            </Grid>
                                                            <Grid item xs={10}>
                                                                <Paragraph fontSizeVerySmall>
                                                                    The address changes won’t reflect in the active cart
                                                                </Paragraph>
                                                            </Grid>
                                                        </Grid>
                                                        <br />
                                                        <Grid container spacing={2} alignItems="flex-start">
                                                            <Grid item xs={12} sm={6}>
                                                                <FormikTextField
                                                                    label={'First Name *'}
                                                                    id={'firstname'}
                                                                    name="defaultBillingFirstname"
                                                                    placeholder={'First Name'}
                                                                    fontWeightIsMedium={true}
                                                                />
                                                            </Grid>
                                                            <Grid item xs={12} sm={6}>
                                                                <FormikTextField
                                                                    label={'Last Name *'}
                                                                    id={'lastname'}
                                                                    name="defaultBillingLastname"
                                                                    placeholder={'Last Name'}
                                                                    fontWeightIsMedium={true}
                                                                />
                                                            </Grid>
                                                            <Grid item xs={12} sm={6}>
                                                                <FormikTextField
                                                                    label={'Address Name *'}
                                                                    id={'addressName'}
                                                                    name="addressName"
                                                                    placeholder={'Address Name'}
                                                                    fontWeightIsMedium={true}
                                                                />
                                                            </Grid>
                                                            <Grid item xs={12} sm={6}>
                                                                <FormikTextField
                                                                    label={'Address Line 1 *'}
                                                                    id={'address1'}
                                                                    name="address1"
                                                                    placeholder={'Address Line 1'}
                                                                    fontWeightIsMedium={true}
                                                                />
                                                            </Grid>
                                                            <Grid item xs={12} sm={6}>
                                                                <FormikTextField
                                                                    label={'Address Line 2'}
                                                                    id={'address2'}
                                                                    name="address2"
                                                                    placeholder={'Address Line 2'}
                                                                    fontWeightIsMedium={true}
                                                                />
                                                            </Grid>
                                                            <Grid item xs={12} sm={6}>
                                                                <FormikTextField
                                                                    label={'City *'}
                                                                    id={'city'}
                                                                    name="city"
                                                                    placeholder={'City'}
                                                                    fontWeightIsMedium={true}
                                                                />
                                                            </Grid>
                                                            <Grid item xs={12} sm={6}>
                                                                <SelectInput
                                                                    id={'billing-region'}
                                                                    value={addressRegion}
                                                                    options={regions}
                                                                    label={'State *'}
                                                                    onSelect={(region: any) => setAddressRegion(region)}
                                                                    fullWidth
                                                                />
                                                            </Grid>
                                                            <Grid item xs={12} sm={6}>
                                                                <FormikTextField
                                                                    label={'Zip Code *'}
                                                                    id={'postcode'}
                                                                    name="postcode"
                                                                    placeholder={'Zip Code'}
                                                                    fontWeightIsMedium={true}
                                                                />
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Container>
                                        </ContentContainer>
                                    </>
                                )}
                            </Formik>
                        </Grid>
                    </Grid>
                </Grid>
            </ContentContainer>
        </div>
    );
}
