import { Theme, useTheme } from '@mui/material/styles';
import { Box, Dialog, DialogContent } from '@mui/material';
import React from 'react';
import { Paragraph } from 'components/Paragraph';
import ContentContainer from 'components/ContentContainer';
import DoneIcon from 'components/icons/DoneIcon';
import { Heading } from 'components/Heading';
import { Button } from 'components/Button';
import { SXCssObject } from 'models/ThemeImages';

const createCssObject = (theme: Theme) => ({
    root: {
        padding: {
            xs: '10px 0px',
            sm: '18px 0px',
        },
    },
    modalContent: {
        display: 'flex',
        justifyContent: 'center',
        margin: '30px 0px',
        height: 'auto',
        padding: {
            xs: '8px 12px',
            sm: '20px 24px',
        },
    },
    doneIconStyle: {
        marginBottom: '24px',
        display: 'flex',
        justifyContent: 'center',
    },
    centerContent: {
        display: 'flex',
        justifyContent: 'center',
        paddingBottom: '5px',
    },
    buttonStyle: {
        marginTop: '24px',
        display: 'flex',
        justifyContent: 'center',
    },
    modalPaper: {
        borderRadius: '4px',
        margin: '24px',
        width: {
            xs: '80%',
            sm: '504px',
        },
        minWidth: {
            xs: '290px',
            sm: 'inherit',
        },
    },
});

interface Props {
    open: boolean;
    handleClose: () => void;
}

export function ScheduleConfirmationModal(props: Props) {
    const cssObject: SXCssObject = createCssObject(useTheme());
    return (
        <Dialog
            onClose={() => {}}
            open={props.open}
            maxWidth={'sm'}
            disableEscapeKeyDown={true}
            aria-describedby="alert-dialog-slide-description"
            PaperProps={{
                sx: cssObject.modalPaper,
            }}
        >
            <DialogContent sx={cssObject.modalContent}>
                <ContentContainer disableGrid>
                    <Box sx={cssObject.doneIconStyle}>
                        <DoneIcon />
                    </Box>
                    <Box sx={cssObject.centerContent}>
                        <Heading label={'Done for Now!'} isPopUp />
                    </Box>
                    <Box style={{ margin: '0px 10px', display: 'flex', justifyContent: 'center' }}>
                        <Paragraph size="normal">
                            We’ll send you a confirmation once a technician is scheduled
                        </Paragraph>
                    </Box>
                    <Box sx={cssObject.buttonStyle}>
                        <Button onClick={props.handleClose} isContrast id={'schedule-confirm-modal-btn'}>
                            View Cart Status
                        </Button>
                    </Box>
                </ContentContainer>
            </DialogContent>
        </Dialog>
    );
}
