import React from 'react';
import { Theme, useTheme } from '@mui/material/styles';
import { Box, ClickAwayListener, IconButton, Tooltip } from '@mui/material';
import TooltipIcon from './icons/TooltipIcon';
import { SXCssObject } from 'models/ThemeImages';
import { Paragraph } from './Paragraph';
import InfoIcon from '@mui/icons-material/Info';

const createCssObject = (theme: Theme): SXCssObject => ({
    root: {
        height: '40px',
        minWidth: theme.spacing(10),
        width: {
            xs: '100%',
            sm: 'inherit',
        },
        '&:hover': {
            background: theme.palette.primary.dark,
        },
        whiteSpace: 'nowrap',
    },
    container: {
        display: 'flex',
    },
    tooltip: {
        backgroundColor: theme.palette.primary.light,
        borderRadius: '0px',
        fontSize: '1.875rem',
        padding: '20px',
        lineHeight: '1.25rem',
        pointerEvents: 'auto',
        display: 'flex',
    },
    tooltipParagraph: {
        fontWeight: 400,
        letterSpacing: '0px',
        fontSize: {
            xs: '0.875rem',
            lg: '1rem',
        },
        lineHeight: {
            lg: '1.375rem',
        },
    },
});

export default function TooltipField() {
    const cssObject: SXCssObject = createCssObject(useTheme());
    const [toolTip, setToolTip] = React.useState(false);
    const handleTooltipOpen = () => {
        setToolTip(true);
    };

    const handleTooltipClose = () => {
        setToolTip(false);
    };
    const tooltipContent = () => (
        <ClickAwayListener touchEvent={false} onClickAway={handleTooltipClose}>
            <Box sx={cssObject.tooltip}>
                <Box display={'flex'}>
                    <TooltipIcon style={{ width: '65px' }} /> &nbsp;{' '}
                    <Box sx={cssObject.tooltipParagraph}>
                        <Paragraph fontSizeVerySmall>
                            The price shown here includes estimated taxes. Final cart total may vary
                        </Paragraph>
                    </Box>
                </Box>
            </Box>
        </ClickAwayListener>
    );
    return (
        <Tooltip
            title={tooltipContent()}
            open={toolTip}
            arrow
            onClose={handleTooltipClose}
            onOpen={handleTooltipOpen}
            placement="top-end"
            componentsProps={{
                tooltip: {
                    sx: {
                        padding: '0',
                        maxWidth: {
                            xs: '100%',
                            sm: '360px',
                        },
                        fontWeight: 400,
                        backgroundColor: 'primary.light',
                        borderStyle: 'solid',
                        borderWidth: '1px',
                        borderColor: 'primary.contrastText',
                        borderRadius: 0,
                    },
                },
                arrow: {
                    sx: {
                        color: 'primary.light',
                        fontSize: '25px',
                        '&:before': {
                            borderStyle: 'solid',
                            borderWidth: '1px',
                            borderColor: 'primary.contrastText',
                        },
                    },
                },
            }}
        >
            <IconButton size="small">
                <InfoIcon onClick={handleTooltipOpen} sx={{ fontSize: '20px' }} />
            </IconButton>
        </Tooltip>
    );
}
