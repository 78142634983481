import { useTheme } from '@mui/material/styles';
import { getThemePrimaryMain } from 'components/common/Utilities';
import * as React from 'react';

function RegenerateIcon(props: any) {
    const theme = useTheme();
    const themePrimaryMain = getThemePrimaryMain(theme);
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" style={{ marginRight: 12 }}>
            <g id="Group_3492" data-name="Group 3492" transform="translate(1121 5647)">
                <g
                    id="Rectangle_627"
                    data-name="Rectangle 627"
                    transform="translate(-1121 -5647)"
                    fill="#fff"
                    stroke="#707070"
                    strokeWidth="1"
                    opacity="0"
                >
                    <rect width="24" height="24" stroke="none" />
                    <rect x="0.5" y="0.5" width="23" height="23" fill="none" />
                </g>
                <g id="Group_3491" data-name="Group 3491" transform="translate(0.743 1)">
                    <g id="Group_3490" data-name="Group 3490" transform="translate(42 68.021)">
                        <path
                            id="Path_268"
                            data-name="Path 268"
                            d="M16.641,26.912a8.18,8.18,0,1,1-6.072-9.846"
                            transform="translate(-1160.424 -5727.782)"
                            fill="none"
                            stroke={`${themePrimaryMain}`}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                        />
                        <path
                            id="Path_269"
                            data-name="Path 269"
                            d="M15.684,31.487A8.18,8.18,0,1,1,4.45,28.729"
                            transform="translate(-1160.431 -5738.481)"
                            fill="none"
                            stroke={`${themePrimaryMain}`}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                        />
                        <path
                            id="Path_270"
                            data-name="Path 270"
                            d="M84.282.362l2.788,2.648L84.282,5.659"
                            transform="translate(-1236.135 -5713.949)"
                            fill="none"
                            stroke={`${themePrimaryMain}`}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                        />
                    </g>
                </g>
            </g>
        </svg>
    );
}

export default RegenerateIcon;
