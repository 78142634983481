import * as React from "react"
import { SVGProps } from "react"

const InspectionsIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={19.74}
    height={19.748}
    {...props}
  >
    <g data-name="Group 3796" fill="#1dbaaf">
      <path
        data-name="Path 377"
        d="M7.014 15.584c-.549.552-1.1 1.108-1.654 1.663-.635.637-1.268 1.276-1.909 1.907a2.024 2.024 0 1 1-2.819-2.906q1.673-1.666 3.341-3.337a1.841 1.841 0 0 1 .2-.157 8.386 8.386 0 1 1 2.836 2.83Zm11.569-7.153a7.23 7.23 0 1 0-7.241 7.189 7.239 7.239 0 0 0 7.243-7.189ZM3.165 15.353c-.587.587-1.171 1.164-1.747 1.749a.866.866 0 1 0 1.227 1.221c.567-.558 1.126-1.123 1.688-1.686a.51.51 0 0 0 .048-.064Zm1.644-1.573-.763.762 1.163 1.172.77-.771Z"
      />
      <path
        data-name="Path 378"
        d="M11.348 14.463a6.073 6.073 0 1 1 6.078-6.1 6.084 6.084 0 0 1-6.078 6.1Zm0-1.157a4.916 4.916 0 1 0-4.91-4.927 4.923 4.923 0 0 0 4.909 4.932Z"
      />
    </g>
  </svg>
)

export default InspectionsIcon
