import { PaymentMethod } from 'models/cart';
import { action, payload } from 'ts-action';
import { Order } from '../../models/order';

export const getOrder = action(
    'ORDER::GET_ORDER',
    payload<{
        id: number;
        redirectToPayment?: boolean;
        doNotRedirect?: boolean;
        callGAPurchase?: boolean;
        paymentMethod?: PaymentMethod;
        cartId?: string;
        finalPayment?: boolean;
        hvFinalOrder?: boolean
    }>()
);
export const getOrderandReset = action('ORDER::GET_ORDER_AND_RESET', payload<{ id: number }>());
export const getMyOrder = action('ORDER::GET_MY_ORDER', payload<{ id: number }>());
export const getOrderSuccess = action('ORDER::GET_ORDER_SUCCESS', payload<Order>());
export const getOrderError = action('ORDER::GET_ORDER_ERROR', payload<Error>());
export const resetOrder = action('ORDER::RESET');

export const getOrderSuccessAndRedirectToBilling = action('MYORDER::GET_ORDER_SUCCESS_AND_REDIRECT', payload<Order>());
export const getOrderSuccessDoNotRedirect = action('MYORDER::GET_ORDER_SUCCESS_AND_DONOTREDIRECT', payload<Order>());

export const getMyOrders = action('MYORDER::GET_ORDER');
export const getMyOrdersSuccess = action('MYORDER::GET_ORDER_SUCCESS', payload<Order[]>());
export const getMyOrdersError = action('MYORDER::GET_ORDER_ERROR', payload<Error>());

export const placeFinalOrder = action(
    'CART::PLACE_FINAL_ORDER',
    payload<{ orderId: string; paymentMethod?: PaymentMethod, hvFinalOrder?: boolean }>()
);
export const placeFinalOrderEquipmentOnly = action(
    'CART::PLACE_FINAL_ORDER_EQ_FLOW',
    payload<{ orderId: string; paymentMethod?: PaymentMethod, hvFinalOrder?: boolean }>()
);

export const myOrderAction = action('ORDER::MINE', payload<{ route?: string }>());
export const myOrderSuccess = action('ORDER::MY_ORDER_SUCCESS', payload<{ order: Order; route?: string }>());
export const myOrderError = action('ORDER::MY_ORDER_ERROR', payload<Error>());
