import React, { SetStateAction, useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import { HostedFieldsHostedFieldsFieldName } from 'braintree-web/modules/hosted-fields';
import { SXCssValueType } from 'models/ThemeImages';

interface Props {
    type: HostedFieldsHostedFieldsFieldName;
    id: string;
    className?: string;
    placeholder: string;
    sx: SXCssValueType;
    setFields: (fn: SetStateAction<HostedFieldsInfo>) => void;
}
export interface HostedFieldsInfo {
    [type: string]: {
        placeholder: string;
        selector: string;
    };
}
function BraintreeHostedField(props: Props) {
    const [state, setStateData] = useState<any>({ fieldId: null });

    const { id } = props;
    // @ts-ignore
    const updateFields = ({ placeholder, type, id, options = {} }) => {
        props.setFields((fieldObj: HostedFieldsInfo) => ({
            ...fieldObj,
            [type]: {
                placeholder,
                selector: `#${id}`,
                ...options,
            },
        }));
    };

    const checkInField = () => {
        setStateData({ fieldId: id });
        updateFields(props);
        return;
    };

    useEffect(() => {
        checkInField();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const { fieldId } = state;
    if (!fieldId) {
        return null;
    }

    return <Box id={fieldId} className={'braintree-hosted-field'} sx={[props.sx !== undefined && props.sx]} />;
}

export default BraintreeHostedField;
