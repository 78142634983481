import LoginProfileIcon from 'components/icons/LoginProfileIcon';
import LogoutIcon from 'components/icons/LogoutIcon';
import OrderIcon from 'components/icons/OrderIcon';
import MyPropertiesIcon from 'components/icons/MyPropertiesIcon';
import MyQuotesIcon from 'components/icons/MyQuotesIcon';

export interface LoginMenu {
    icon?: any;
    label: string;
}

export enum LoginMenuLabels {
    MENU_PAY_NOW = 'Pay now',
    MENU_PROFILE = 'My Profile',
    MENU_ORDER = 'My Orders',
    MENU_PROPERTIES = 'My Properties',
    MENU_QUOTES = 'My Quotes',
    MENU_LOGOUT = 'Log Out',
}
export const LoginMenusList: LoginMenu[] = [
    {
        icon: LoginProfileIcon,
        label: LoginMenuLabels.MENU_PROFILE,
    },
    {
        icon: OrderIcon,
        label: LoginMenuLabels.MENU_ORDER,
    },
    {
        icon: MyPropertiesIcon,
        label: LoginMenuLabels.MENU_PROPERTIES,
    },
    {
        icon: MyQuotesIcon,
        label: LoginMenuLabels.MENU_QUOTES,
    },
    {
        icon: LogoutIcon,
        label: LoginMenuLabels.MENU_LOGOUT,
    },
];
