import { showSnackbarSuccess } from './../../components/common/Snackbar/SnackbarHelper';
import { logoutFailure, logoutSuccess, performLogout } from 'store/actions/loginActions';
import { Action } from 'ts-action';
import { ofType } from 'ts-action-operators';
import { Observable, of } from 'rxjs';
import { catchError, ignoreElements, map, switchMap, tap } from 'rxjs/operators';
import { sendLogOutRequest as LogOutService } from '../services/loginServices';
import { Dependencies } from 'store';
import { RootState } from 'store/reducers';
import { AppRoute } from 'models/route';
import Cookies from 'js-cookie';
import { IS_ALIVE_COOKIE, LoginMessages } from 'components/common/constants';
import { combineEpics, Epic } from 'redux-observable';
import { showSnackbarError } from 'components/common/Snackbar/SnackbarHelper';
import { invokeGAForLogout } from 'components/Analytics/GoogleAnalyticsTagging';

const logOutEpics$: Epic = (action$: Observable<Action>) =>
    action$.pipe(
        ofType(performLogout),
        switchMap(() =>
            LogOutService().pipe(
                map(() => {
                    Cookies.remove(IS_ALIVE_COOKIE);
                    showSnackbarSuccess(LoginMessages.MSG_SUCCESS_LOGOUT);
                    invokeGAForLogout();
                    return logoutSuccess();
                }),

                catchError((err) => {
                    showSnackbarError('Unable to perform logout');
                    return of(logoutFailure(err));
                })
            )
        )
    );

const logoutSuccess$ = (action$: Observable<Action>, state: Observable<RootState>, { history }: Dependencies) =>
    action$.pipe(
        ofType(logoutSuccess),
        tap(() => history.push(AppRoute.Home, { isFromLogout: true })),
        ignoreElements()
    );

export default combineEpics(logOutEpics$, logoutSuccess$);
