import React, { Fragment } from 'react';
import { Props as MenuProps } from 'components/SecondaryNavigation';
import { Theme, useTheme } from '@mui/material/styles';
import SubPage from 'components/subpage/SubPage';
import Grid from '@mui/material/Grid';
import CardMedia from '@mui/material/CardMedia';
import ProTipAndAdditionalLinks from 'components/subpage/ProTipAndAdditionalLinks';
import { Heading } from 'components/Heading';
import { Paragraph } from 'components/Paragraph';
import { getSubPageMenus } from './menu-util';
import ContentContainer from 'components/ContentContainer';
import AirHandlersImage from 'components/icons/AirHandlersImage';
import TooltipIdeaIcon from 'components/icons/TooltipIdeaIcon';
import { getThemePrimaryMain } from 'components/common/Utilities';
import { SXCssObject } from 'models/ThemeImages';

function AirHandlers() {
    const createCssObect = (theme: Theme): SXCssObject => {
        return {
            contentHeading: {
                paddingBottom: theme.spacing(2),
            },
            contentImage: {
                paddingTop: theme.spacing(2),
                marginTop: theme.spacing(2),
                backgroundColor: theme.customProperties.knowledgeCenterImageBackground,
            },
            image: {
                width: {
                    sm: '505px',
                },
                margin: 'auto',
            },
            contentInteractive: {
                paddingTop: theme.spacing(4),
                paddingBottom: theme.spacing(4),
            },
            contentSecondaryHeading: {
                paddingTop: {
                    xs: theme.spacing(2),
                    sm: theme.spacing(4),
                },
                paddingBottom: theme.spacing(2),
            },
            content: {
                paddingTop: theme.spacing(2),
            },
            tooltipContainer: {
                padding: {
                    xs: theme.spacing(2),
                    sm: '35px',
                },
                border: `1px solid ${theme.palette.primary.contrastText}`,
                borderRadius: '4px',
                margin: '40px 0px',
            },
            tooltipIcon: {
                marginRight: '10px',
            },
            toolTipHeading: {
                paddingBottom: theme.spacing(2),
                alignItems: 'center',
            },
            listPoint: {
                backgroundColor: getThemePrimaryMain(theme),
                borderRadius: '50%',
                padding: theme.spacing(0, 0.9, 0, 0.9),
                fontSize: '12px',
                color: theme.palette.common.white,
                marginRight: '10px',
                marginTop: '4px',
            },
            listPointContainer: {
                padding: '5%',
            },
        };
    };
    const menu: MenuProps = {
        id: 'menu',
        label: 'More Knowledge Center Topic',
        color: 'primary',
        subMenus: getSubPageMenus('air_handlers_and_indoor_coils'),
    };
    const cssObject: SXCssObject = createCssObect(useTheme());

    return (
        <SubPage
            menu={menu}
            content1={
                <Fragment>
                    <Grid item xs={12} sx={cssObject.contentHeading}>
                        <Heading variant={'h3'} isPopUp color={'primary'} label={'Air Handlers & Coils'} />
                    </Grid>
                    <Grid item xs={12}>
                        <Paragraph responsiveFontSize16>
                            What do FedEx and air handlers have in common? They both deliver!
                        </Paragraph>
                    </Grid>
                    <Grid item xs={12} sx={cssObject.content}>
                        <Paragraph responsiveFontSize16 align={'left'}>
                            Your air handler assists in regulating the circulation of indoor air and the temperature of
                            the air in your home. Your air handler consists of an evaporator coil, blower motor, air
                            filter and electronic components required to deliver enhanced levels of indoor comfort.
                        </Paragraph>
                    </Grid>
                    <Grid item xs={12} sx={cssObject.content}>
                        <Paragraph responsiveFontSize16 align={'left'}>
                            Depending on the design of your home, an air handler may be a primary indoor component of
                            your heat pump or air conditioning system.
                        </Paragraph>
                    </Grid>
                    <Grid item xs={12} sx={cssObject.contentImage}>
                        <CardMedia style={{ overflowY: 'auto' }}>
                            <AirHandlersImage />
                        </CardMedia>
                        <Grid>
                            <Grid sx={cssObject.listPointContainer} container spacing={2}>
                                <Grid item container alignItems={'flex-start'}>
                                    <Grid item sx={cssObject.listPoint}>
                                        1
                                    </Grid>
                                    <Grid item xs={10} sm={11}>
                                        <Paragraph fontSizeMediumSmall>
                                            Your outdoor Heat Pump on Air Conditioner will be connected to your air
                                            handler or indoor coil
                                        </Paragraph>
                                    </Grid>
                                </Grid>
                                <Grid item container alignItems={'flex-start'}>
                                    <Grid item sx={cssObject.listPoint}>
                                        2
                                    </Grid>
                                    <Grid item xs={10} sm={11}>
                                        <Paragraph fontSizeMediumSmall>
                                            Line-sets are the copper lines that carry the gas between your outdoor heat
                                            pump or air conditioner and the indoor coil. You will typically see the
                                            larger of the two copper lines insulated with black foam.
                                        </Paragraph>
                                    </Grid>
                                </Grid>
                                <Grid item container alignItems={'flex-start'}>
                                    <Grid item sx={cssObject.listPoint}>
                                        3
                                    </Grid>
                                    <Grid item xs={10} sm={11}>
                                        <Paragraph fontSizeMediumSmall>
                                            Air handler blowers can be single-stage, two-stage, or variable speed to
                                            maximize comfort and energy savings. The more “speed settings” or stages
                                            your air handler has typically means a higher overall comfort level for your
                                            family and greater energy savings.
                                        </Paragraph>
                                    </Grid>
                                </Grid>
                                <Grid item container alignItems={'flex-start'}>
                                    <Grid item sx={cssObject.listPoint}>
                                        4
                                    </Grid>
                                    <Grid item xs={10} sm={11}>
                                        <Paragraph fontSizeMediumSmall>
                                            The evaporator coil is an piece of equipment that adsorbs heat using
                                            refrigerant and then Cools your home. They can be individual pieces or
                                            internal in an air handler.
                                        </Paragraph>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <br />
                    <ContentContainer sx={cssObject.tooltipContainer} background={'wizardtip'}>
                        <Grid container>
                            <Grid item xs={12}>
                                <Grid container sx={cssObject.toolTipHeading}>
                                    <TooltipIdeaIcon sx={cssObject.tooltipIcon} />
                                    <Heading variant={'h3'} isPopUp={true} color={'primary'} label={'Pro Tip'} />
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                <Paragraph>
                                    Residential HVAC systems are sized from 1.5 to 5 tons. Our online selection tool
                                    takes a lot of the guess work out, but we still have local technicians complete a
                                    pre-installation inspection to confirm the right size HVAC system for your home.{' '}
                                </Paragraph>
                            </Grid>
                        </Grid>
                    </ContentContainer>
                </Fragment>
            }
            content2={
                <ProTipAndAdditionalLinks
                    additionalLinks={{
                        headLine: 'Want to learn more?  Check out these links',
                        links: [
                            {
                                text: 'What is Variable Speed HVAC Technology?',
                                url: 'https://www.goodmanmfg.com/resources/hvac-learning-center/hvac-101/what-is-variable-speed-technology',
                            },
                            {
                                text: 'Goodman Customer Reviews',
                                url: 'https://www.goodmanmfg.com/resources/customer-reviews',
                            },
                        ],
                    }}
                />
            }
        />
    );
}

export default AirHandlers;
