import { createSelector } from 'reselect';
import { RootState } from '../reducers';

export const selectStoreState = (state: RootState) => state.store;
export const selectStoreCode = createSelector(selectStoreState, (store) => store.storeCode);
export const selectStoreConfig = createSelector(selectStoreState, (store) => store.config);
export const selectStoreFeatures = createSelector(selectStoreState, (store) => store.features);
export const selectStoreLoading = createSelector(selectStoreState, (store) => store.loading);

export const selectPromotionCodeBlockId = createSelector(
    selectStoreConfig,
    (config) => config?.promotionBlockId || 'promo_code'
);
export const selectContentState = (state: RootState) => state.content;
export const selectContent = createSelector(selectContentState, (state) => state.block);
export const selectContentLoading = createSelector(selectContentState, (state) => state.loading);
export const selectMultipleOpenProjects = createSelector(
    selectStoreState,
    (store) => store.features?.multipleOpenProjects.enable
);
export const selectSalesEmail = createSelector(selectStoreState, (store) => store.salesEmail);
export const selectSalesPhone = createSelector(selectStoreState, (store) => store.salesPhone);
