import { ProductReviews } from 'models/productReviews';
import { productReviewSuccess } from 'store/actions/productReviewActions';
import { on, reducer } from 'ts-action';

const initialState: { reviews: ProductReviews[] } = { reviews: [] };

export const productReviewReducer = reducer(
    initialState,
    on(productReviewSuccess, (state, action) => ({
        ...state,
        reviews: [...action.payload],
    }))
);

export default productReviewReducer;
