import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Dispatch } from 'redux';
import { Address, AddressType } from '../../../models/cart';
import { selectLoginState } from '../../../store/selectors/loginSelector';
import { addressState } from '../../../store/selectors/addressSelector';
import { EditAddressModal, EditAddressModalDataParams } from './EditAddressModal';
import { setBillingAddressToCart, setShippingAddressToCart } from 'store/actions/cartActions';
import { ChooseAddressModal } from './ChooseAddressModal';
import {
    hideChooseAddressPopupAction,
    hideEditAddressPopupAction,
    resetData,
    setAddressToEdit,
    showChooseAddressPopupAction,
    showEditAddressPopupAction,
} from 'store/actions/addressActions';
import { getRegion, transformAddressObjForEdit } from './AddressUtils';
import {
    AddressValidationFields,
    AddressValidationModalData,
    AddressValidationStatus,
    ValidateAddressResponse,
} from 'models/userProfile';
import { AddressValidationModal } from 'components/AddressValidationModal';
import { addressValidate } from 'store/services/userProfileServices';
import { extractErrorFromResponseOrShowDefaultMsg } from 'components/common/Utilities';
import LoadingOverlay from 'components/common/LoadingOverlay';
import { selectRegions } from 'store/selectors/country';
interface Props {
    flow: AddressType;
    isFirstTimeCapture: boolean;
    pageToRedirect?: string;
    hideCloseButton?: boolean;
    hideBackButton?: boolean;
    onEditAddressSubmit?: (address: Address) => void;
    invokeDOMEventOnBillingCatpure?: boolean;
    shouldShowChooseAddressModal?: boolean; //This flag Let's components decide whether to show this modal
}
export const AddressContainer = (props: Props) => {
    const dispatch: Dispatch = useDispatch();
    const loginData = useSelector(selectLoginState);
    const addressData = useSelector(addressState);
    const regions = useSelector(selectRegions);

    const {
        flow,
        isFirstTimeCapture,
        pageToRedirect,
        shouldShowChooseAddressModal = true,
        hideCloseButton,
        onEditAddressSubmit,
        hideBackButton,
        invokeDOMEventOnBillingCatpure,
    } = props;

    const { showChooseAddressPopup, showEditAddressPopup, addressList, addressToEdit } = addressData;
    const hideBackBtn = !addressList || (addressList && addressList.length <= 0) || hideBackButton;
    const [openValidationDialog, setOpenValidationDialog] = React.useState(false);
    const [loader, setLoader] = React.useState(false);
    const [nonValidAddresses, setNonValidAddresses] = React.useState<AddressValidationModalData[]>([]);
    const country = 'US';
    useEffect(() => {
        return () => {
            dispatch(resetData());
        };
    }, [dispatch]);

    const dispatchAddress = (transformedAddress: Address, useSame?: boolean) => {
        if (useSame || flow === AddressType.Billing) {
            dispatch(
                setBillingAddressToCart({
                    ...(useSame && { useForShipping: useSame }),
                    address: transformedAddress,
                    invokeDOMEventOnBillingCatpure,
                    pageToRedirect: pageToRedirect,
                })
            );
        } else if (flow === AddressType.Home) {
            dispatch(setShippingAddressToCart({ address: transformedAddress, pageToRedirect: pageToRedirect }));
        }
    };
    //1. if myproperties page scenario
    //2. if edit address
    //3. else choose address
    const handleProceedWithValidAddress = (validAddress: Address, useSame?: boolean, isEdit?: boolean) => {
        validAddress.region = validAddress.region && getRegion(validAddress.region, regions)?.region;
        if (onEditAddressSubmit) {
            onEditAddressSubmit(validAddress);
        } else {
            let addressPayload: Address = {};
            addressPayload.customerAddressId = validAddress.id;
            if (isEdit) {
                addressPayload = {
                    ...transformAddressObjForEdit(validAddress, loginData),
                };
            } else {
                dispatch(setAddressToEdit({ address: undefined }));
            }
            dispatchAddress(addressPayload, useSame);
        }
    };
    //address validation flow
    const handleAddressValidation = (addressToValidate: Address, useSame?: boolean, isEdit?: boolean) => {
        setLoader(true);
        const addressValidationObj: AddressValidationFields[] = [
            {
                postcode: addressToValidate?.postcode,
                city: addressToValidate?.city,
                region: addressToValidate?.region ? getRegion(addressToValidate?.region, regions)?.display : '',
                country: country,
            },
        ];
        addressValidate(addressValidationObj).subscribe({
            next: (resp) => {
                let validateAddress: AddressValidationModalData[] = [];
                let areAllAddressesValid = true;
                resp.data.forEach((item: ValidateAddressResponse, index: number) => {
                    if (item.status !== AddressValidationStatus.Valid) {
                        areAllAddressesValid = false;
                        validateAddress.push({
                            status: item.status,
                            suggestedAddress: item?.suggestedAddress,
                            reason: item?.reason || '',
                            addressEntered: addressToValidate,
                            flow: flow,
                            useSame: useSame,
                        });
                    }
                });
                //if both the addresses are valid
                if (areAllAddressesValid) {
                    handleProceedWithValidAddress(addressToValidate, useSame, isEdit);
                    setOpenValidationDialog(false);
                } else {
                    setNonValidAddresses(validateAddress);
                    setOpenValidationDialog(true);
                }
            },
            error: (err) => {
                setOpenValidationDialog(false);
                extractErrorFromResponseOrShowDefaultMsg(err, 'Unable to Validate Address');
            },
            complete: () => {
                setLoader(false);
            },
        });
    };

    return (
        <>
            <LoadingOverlay open={loader} />
            {showChooseAddressPopup && shouldShowChooseAddressModal && (
                <ChooseAddressModal
                    open={showChooseAddressPopup}
                    flow={flow}
                    isFirstTimeCapture={isFirstTimeCapture}
                    addressesList={addressList}
                    hideCloseButton={hideCloseButton}
                    handleClose={(address: Address | null, goToAddAddress?: boolean, useSameAddress?: boolean) => {
                        dispatch(hideChooseAddressPopupAction());
                        dispatch(setAddressToEdit({ address: address ? address : undefined }));
                        //if user selects to edit/add an address
                        if (goToAddAddress) {
                            dispatch(showEditAddressPopupAction());
                        }
                        //if user submits selected address, validate it and proceed
                        else if (address != null) {
                            handleAddressValidation(address, useSameAddress, false);
                        }
                    }}
                />
            )}
            {showEditAddressPopup && (
                <EditAddressModal
                    open={showEditAddressPopup}
                    addressToModify={addressToEdit!}
                    flow={flow}
                    isFirstTimeCapture={isFirstTimeCapture}
                    hideCloseButton={hideCloseButton}
                    hideBackToAddresses={hideBackBtn}
                    handleClose={(params: EditAddressModalDataParams) => {
                        dispatch(hideEditAddressPopupAction());
                        //if user selects to go back to choose address pop up
                        if (params.displayChooseAddressPopUp) {
                            dispatch(showChooseAddressPopupAction());
                        }
                        //on continue
                        else {
                            params.address && handleAddressValidation(params.address, params.useSame, true);
                        }
                    }}
                />
            )}
            {openValidationDialog && (
                <AddressValidationModal
                    open={openValidationDialog}
                    hideCloseButton={hideCloseButton}
                    handleClose={() => {
                        setOpenValidationDialog(false);
                    }}
                    handleEdit={(address: Address) => {
                        dispatch(setAddressToEdit({ address: address }));
                        dispatch(showEditAddressPopupAction());
                        setOpenValidationDialog(false);
                    }}
                    handleSubmit={(addressObj: Address, useSameFlag?: boolean) => {
                        handleProceedWithValidAddress(addressObj, useSameFlag, true);
                        setOpenValidationDialog(false);
                    }}
                    addressData={nonValidAddresses}
                />
            )}
        </>
    );
};
