import { CartStatus, Schedule } from 'models/cart';
import React from 'react';
import { useSelector } from 'react-redux';
import { selectCart, selectCartProcessing } from 'store/selectors/cart';
import ScheduleInspection from './ScheduleInspection';
import { useDispatch } from 'react-redux';
import { scheduleInspection } from 'store/actions/cartActions';
import ContentContainer from 'components/ContentContainer';
import { ScheduleConfirmationModal } from './ScheduleConfirmationModal';
import { Navigate, useNavigate } from 'react-router-dom';
import { AppRoute } from 'models/route';
import { Order } from 'models/order';
import { selectOrder } from 'store/selectors/order';

export function ScheduleInspectionContainer() {
    const dispatch = useDispatch();

    const cartLoading = useSelector(selectCartProcessing);
    const cartData = useSelector(selectCart);
    const navigate = useNavigate();
    const order: Order = useSelector(selectOrder);

    const getMinDate = () => {
        let minDate = new Date();
        if (minDate.getDay() === 0 || minDate.getDay() === 1) {
            //current day is Sun/monday, set minDate to Thursday/friday(currentDay +4;)
            minDate.setDate(minDate.getDate() + 4);
        } else if (minDate.getDay() === 6) {
            minDate.setDate(minDate.getDate() + 5); //current day is sat, set minDate to Thursday(currentDay +5;)
        } else {
            minDate.setDate(minDate.getDate() + 6);
            //current day is not mon/sat/sun, set currentDay +6;
        }
        return minDate;
    };

    let minDate = getMinDate();

    let maxDate = new Date();
    maxDate.setDate(maxDate.getDate() + 14);
    let scheduleData: Schedule[] = [
        {
            preferredTimeSlot: '',
            schedule: 'schedule1',
            preferredDate: minDate.toString(),
        },
        {
            preferredTimeSlot: '',
            schedule: 'schedule2',
            preferredDate: minDate.toString(),
        },
        {
            preferredTimeSlot: '',
            schedule: 'schedule3',
            preferredDate: minDate.toString(),
        },
    ];
    const [selectedValues] = React.useState<Schedule[]>(scheduleData);
    const [isConfirmationModalVisible, setConfirmationModalVisibility] = React.useState(false);

    const [isWaitingForScheduleApi, setIsWaitingForScheduleApi] = React.useState(false);

    React.useEffect(() => {
        if (isWaitingForScheduleApi && cartData.status && cartData.status === CartStatus.PENDING_INSPECTION) {
            setIsWaitingForScheduleApi(false);
            setConfirmationModalVisibility(true);
        }
    }, [cartData.status, isWaitingForScheduleApi]);

    const handleConfirmationPopupClose = () => {
        setConfirmationModalVisibility(false);
        return <Navigate to={AppRoute.CartPage} />;
    };

    /**
     * Redirect to Home if cart is empty and order is not pending inspection
     */
    if (!cartData || !cartData.items || cartData.items.length === 0) {
        //if cart is empty and has an order available..take to confirmation page
        if (order && order.status) {
            return <Navigate to={AppRoute.Confirmation} />;
        }
        return <Navigate to={AppRoute.Home} />;
    } else if (cartData && cartData.items && cartData.status && cartData.status === CartStatus.READY_FOR_PAYMENT) {
        //valid cart is present. Inspection is done.Take use to payment
        return <Navigate to={AppRoute.Billing} />;
    } else if (
        cartData &&
        cartData.items &&
        cartData.status &&
        cartData.status === CartStatus.PENDING_INSPECTION &&
        !isConfirmationModalVisible &&
        !isWaitingForScheduleApi
    ) {
        //valid cart is present. Schedule done but Inspection is not done.Take use to Cart page if confirmation popup is not open.

        return <Navigate to={AppRoute.CartPage} />;
    }

    return (
        <ContentContainer disableGrid>
            <ScheduleConfirmationModal handleClose={handleConfirmationPopupClose} open={isConfirmationModalVisible} />
            <ScheduleInspection
                schedule={selectedValues}
                loading={cartLoading}
                minDate={minDate}
                maxDate={maxDate}
                onPrev={() => {
                    navigate(-1);
                }}
                onNext={(scheduleList: Schedule[]) => {
                    dispatch(scheduleInspection(scheduleList));
                    setIsWaitingForScheduleApi(true);
                }}
            />
        </ContentContainer>
    );
}
