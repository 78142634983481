import { AppliedCoupon, Cart } from 'models/cart';
import { action, payload } from 'ts-action';
import { Quote } from '../../models/quotes';

export const myQuotesAction = action('QUOTES::MINE');
export const resetQuoteData = action('QUOTES::RESET_DATA');
export const myQuotesSuccess = action('QUOTES::SUCCESS', payload<Quote[]>());
export const myQuotesError = action('QUOTES::MINE_ERROR', payload<Error>());

export const generateQuoteForSelectionPage = action('GENERATE_QUOTE::SELECTION');
export const generateQuoteForCart = action('GENERATE_QUOTE::CART');
export const reGenerateQuoteFromQuotationPage = action('GENERATE_QUOTE::QUOTATION', payload<Quote>());

export const proceedToDownloadQuote = action('GENERATE_QUOTE::SUCCESS', payload<any>());

export const downloadQuoteAction = action('QUOTE::DOWNLOAD', payload<Quote>());

export const moveQuoteToCart = action(
    'QUOTE::MOVE TO CART',
    payload<{ id: number; appliedCoupons?: AppliedCoupon[] }>()
);
export const moveQuoteToCartSuccess = action('QUOTE::MOVE TO CART_SUCCESS', payload<Cart>());
export const moveQuoteToCartError = action('QUOTE::MOVE TO CART_ERROR', payload<Error>());
