import * as React from "react"
import { SVGProps } from "react"

const PermitsIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    data-name="Component 34 \u2013 1"
    xmlns="http://www.w3.org/2000/svg"
    width={19.487}
    height={19.494}
    {...props}
  >
    <path
      data-name="Path 375"
      d="M16.321 7.296a9.7 9.7 0 0 0-1.19-.043h-2.057c.016-.067.029-.124.044-.179.278-1.017.56-2.034.836-3.052a3.319 3.319 0 0 0 .12-.871 2.992 2.992 0 0 0-.446-1.566 3.208 3.208 0 0 0-1.212-1.2 3.392 3.392 0 0 0-1.647-.387h-.118a.885.885 0 0 0-.495.128.629.629 0 0 0-.166.172 1.132 1.132 0 0 0-.12.247c-.365 1.011-.727 2.022-1.1 3.03a.8.8 0 0 1-.182.284q-1.694 1.679-3.4 3.345a.68.68 0 0 1-.316.17 6.142 6.142 0 0 0-4.877 5.938v.034a6.1 6.1 0 0 0 3.83 5.679 6.013 6.013 0 0 0 2.265.465h.041q1.852-.014 3.7-.012 2.116 0 4.232.006h.009a4.925 4.925 0 0 0 2.755-.835 4.267 4.267 0 0 0 1.013-.895 2.9 2.9 0 0 0 .581-1.231c.343-1.647.678-3.3.994-4.95a3.482 3.482 0 0 0 .062-.652 3.7 3.7 0 0 0-3.156-3.625Zm-5.454 6.078v.008a4.76 4.76 0 0 1-4.75 4.737h-.018a4.765 4.765 0 0 1-4.732-4.761v-.016a4.76 4.76 0 0 1 4.75-4.724h.011a4.751 4.751 0 0 1 4.739 4.756Zm.752 2.7a5.488 5.488 0 0 0 .628-2.565 6.594 6.594 0 0 0-.235-1.709 5.92 5.92 0 0 0-1.831-3.035 6.809 6.809 0 0 0-3.106-1.45c.275-.283.549-.569.83-.848.587-.583 1.185-1.158 1.767-1.749a1.493 1.493 0 0 0 .327-.507c.339-.9.662-1.806.982-2.713a.237.237 0 0 1 .05-.094.058.058 0 0 1 .022-.013.13.13 0 0 1 .042-.005.387.387 0 0 1 .056 0 1.8 1.8 0 0 1 1.488 2.238c-.345 1.316-.717 2.626-1.072 3.942a1.542 1.542 0 0 0-.061.394.878.878 0 0 0 .006.107.583.583 0 0 0 .234.4.788.788 0 0 0 .471.145h2.016q.686 0 1.373-.007h.042a2.606 2.606 0 0 1 1.219.271 2.33 2.33 0 0 1 .9.857 2.2 2.2 0 0 1 .347 1.188 2.485 2.485 0 0 1-.049.487q-.476 2.387-.958 4.773a1.754 1.754 0 0 1-.544.95 3.383 3.383 0 0 1-2.293.971c-.612.012-1.225.015-1.838.015-.82 0-1.639-.006-2.46-.007a7.249 7.249 0 0 0 1.646-2.038Z"
      fill="#1dbaaf"
    />
    <path
      data-name="Path 376"
      d="m4.926 14.827-.093-.089c-.416-.415-.831-.832-1.25-1.245a.662.662 0 0 0-.47-.206.724.724 0 0 0-.261.051.668.668 0 0 0-.3.219.7.7 0 0 0-.132.345.677.677 0 0 0 0 .079.632.632 0 0 0 .07.288.923.923 0 0 0 .177.235q.839.833 1.673 1.672a1.2 1.2 0 0 0 .284.219.592.592 0 0 0 .583 0 1.184 1.184 0 0 0 .281-.216l3.1-3.1c.345-.345.692-.688 1.034-1.037a.659.659 0 0 0 .2-.466.72.72 0 0 0-.045-.245.676.676 0 0 0-.212-.3.7.7 0 0 0-.34-.142.649.649 0 0 0-.1-.007.628.628 0 0 0-.3.077 1.035 1.035 0 0 0-.242.187q-1.662 1.668-3.329 3.332c-.11.106-.217.224-.328.349Z"
      fill="#1dbaaf"
    />
  </svg>
)

export default PermitsIcon
