import * as React from 'react';
import { useTheme } from '@mui/material/styles';
import { getThemePrimaryMain, getThemeSecondaryMain } from 'components/common/Utilities';

export default function AddAddressIcon(props: any) {
    const theme = useTheme();
    const themePrimaryMain = getThemePrimaryMain(theme);
    const themeSecondaryMain = getThemeSecondaryMain(theme);
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} {...props}>
            <defs>
                <style>{'.prefix__b7{fill:none;stroke-linecap:round;stroke-width:2px}'}</style>
            </defs>
            <circle cx={10} cy={10} r={10} fill={themeSecondaryMain} />
            <path stroke={themePrimaryMain} className="prefix__b7" d="M10.166 7.012v6.308M13.32 10.166H7.012" />
        </svg>
    );
}
