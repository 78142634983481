import React, { useRef } from 'react';
import { Theme, useTheme } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import { Button } from 'components/Button';
import ContentContainer from 'components/ContentContainer';
import { Box, Dialog, DialogContent, Typography } from '@mui/material';
import OtpInput from 'react-otp-input';
import MuiDialogTitle from '@mui/material/DialogTitle';
import Close from 'components/icons/Close';
import LoadingOverlay from 'components/common/LoadingOverlay';
import { sendLoginOtpToEmail, sendLoginRequest, verifyOtpApi } from 'store/services/loginServices';
import OtpErrorIcon from 'components/icons/OtpErrorIcon';
import { loginSuccess, verifyEnteredOtpSuccess } from 'store/actions/loginActions';
import { useDispatch } from 'react-redux';
import { LoginRequest } from 'models/loginModel';
import clsx from 'clsx';
import { LoginMessages } from 'components/common/constants';
import { setIsAliveCookie } from 'index';
import { Heading } from 'components/Heading';
import { Paragraph } from 'components/Paragraph';
import { showSnackbarError, showSnackbarSuccess } from 'components/common/Snackbar/SnackbarHelper';
import { SXCssObject } from 'models/ThemeImages';
import { invokeGACheckoutPageVerification } from 'components/Analytics/GoogleAnalyticsTagging';
import styles from 'components/common/CommonStyle.module.css';

const createCssObject = (theme: Theme): SXCssObject => ({
    root: { padding: '15px 0px' },
    toggle: {
        width: '100%',
        backgroundColor: '#E9E9E9',
    },
    main: {
        display: 'flex',
        flexDirection: 'column',
        marginBottom: {
            md: theme.spacing(0),
        },
    },
    modalContent: {
        overflow: 'hidden',
    },

    otpSectionStyle: {
        padding: '5px 0px 10px',
    },
    otpErrorSectionStyle: {
        display: 'flex',
        alignItems: 'center',
        marginTop: '10px',
        marginBottom: '15px',
    },
    otpErrorTextStyle: {
        paddingLeft: '5px',
        display: 'flex',
        justifyContent: 'center',
    },
    modalTitle: {
        fontWeight: 'bold',
    },
    modalTitleForOtp: {
        fontWeight: 'bold',
        fontSize: '0.9375rem',
    },
    headingContent: {
        lineHeight: '30px',
        paddingRight: {
            xs: '30px',
            sm: '60px',
        },
        paddingTop: '5px',
    },
    userEmailDescription: {
        fontSize: '16px',
        marginRight: {
            xs: '4px',
            sm: '100px',
        },
        paddingTop: '10px',
    },
    userEmail: {
        fontWeight: theme.typography.fontWeightMedium,
    },
    modalTitleContainer: {
        padding: '10px 24px',
        position: 'relative',
        marign: '0px',
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1.6),
        top: theme.spacing(0),
        color: theme.palette.grey[500],
        cursor: 'pointer',
    },
    actions: {
        position: {
            md: 'fixed',
        },
        left: {
            md: 0,
        },
        bottom: {
            md: 0,
        },
        width: {
            md: '100%',
        },
    },
    modalPaper: {
        borderRadius: '4px',
    },
    resendOtpSection: {
        textAlign: 'center',
        marginTop: '30px',
    },
    resendOtpStyle: {
        display: 'inline',
        color: theme.palette.primary.main,
        fontWeight: theme.typography.fontWeightMedium,
        cursor: 'pointer',
    },

    termsAndConditionsStyle: {
        color: theme.palette.primary.main,
        display: 'inline',
        cursor: 'pointer',
        fontWeight: '600',
    },
    checkboxStyle: {
        color: '#CCCCCC',
    },
});

const OTP_RESEND_COUNTDOWN: number = 20;

interface Props {
    loading: boolean;
    isOpen: boolean;
    handleClose: (snackbarMessage?: string) => void;
    isSignUpOtpFlow?: boolean;
    headingContent?: string;
    isOtpFlow?: boolean;
    email: string;
}

function Login(loginProps: Props) {
    const [loader, setLoader] = React.useState(false);

    const [otpValue, setOtpValue] = React.useState('');
    const [isOtpError, setOtpError] = React.useState(false);

    const [isOtpTimerActive, setIsOtpTimerActive] = React.useState(false);
    const isOtpFlow = loginProps.isOtpFlow;
    const isSignUpOtpFlow = loginProps.isSignUpOtpFlow;

    const loginOrSignUpOtpFlow = loginProps.isOtpFlow || loginProps.isSignUpOtpFlow;

    const [timeLeft, setTimeLeft] = React.useState(OTP_RESEND_COUNTDOWN);
    const dispatch = useDispatch();

    React.useEffect(() => {
        if (!mounted.current) {
            // do componentDidMount logic
            mounted.current = true;
            setIsOtpTimerActive(true);
        } else {
            // do componentDidUpdate logic
            if (!isOtpTimerActive) return;
            if (!timeLeft) {
                setIsOtpTimerActive(false);
                return;
            }
            const intervalId = setInterval(() => {
                setTimeLeft(timeLeft - 1);
            }, 1000);
            return () => clearInterval(intervalId);
        }
    }, [isOtpTimerActive, timeLeft]);

    let mounted: any = useRef();
    const cssObject: SXCssObject = createCssObject(useTheme());
    const handleSubmit = () => {
        if (isSignUpOtpFlow) {
            setLoader(true);
            verifyOtpApi(otpValue).subscribe(
                () => {
                    setLoader(false);
                    invokeGACheckoutPageVerification();
                    dispatch(verifyEnteredOtpSuccess());
                    loginProps.handleClose(LoginMessages.MSG_SIGN_UP_VERIFY_SUCCESS);
                },
                () => {
                    setLoader(false);
                    setOtpError(true);
                }
            );
        } else {
            let loginReq: LoginRequest = {
                customerIdentifier: loginProps.email,
                otp: otpValue,
            };

            setLoader(true);
            sendLoginRequest(loginReq).subscribe(
                (res: any) => {
                    setLoader(false);
                    if (res.data.success) {
                        setIsAliveCookie();
                        dispatch(loginSuccess({ loginResponse: { ...res.data, isFromContactsPage: true } }));
                        loginProps.handleClose(LoginMessages.MSG_SUCCESS_LOGIN);
                    }
                },
                () => {
                    setLoader(false);
                    setOtpError(true);
                }
            );
        }
    };
    const handleOtpPinChange = (otp: string) => {
        setOtpValue(otp);
        setOtpError(false);
    };

    const DialogTitle = (props: any) => {
        const { onClose } = props;
        return (
            <MuiDialogTitle sx={cssObject.modalTitleContainer}>
                {isOtpFlow && (
                    <Typography variant="h3" sx={isOtpFlow ? cssObject.modalTitleForOtp : cssObject.modalTitle}>
                        Welcome back!
                    </Typography>
                )}
                {loginOrSignUpOtpFlow && (
                    <Box sx={cssObject.headingContent}>
                        <Heading isPopUp label={`${loginProps.headingContent}`} />
                    </Box>
                )}
                {isOtpFlow && (
                    <Box sx={cssObject.userEmailDescription}>
                        Enter One-time password sent to{' '}
                        <Box component={'b'} sx={cssObject.userEmail}>
                            {loginProps.email}
                        </Box>
                    </Box>
                )}
                <Box aria-label="close" sx={cssObject.closeButton} onClick={onClose}>
                    <Close />
                </Box>
            </MuiDialogTitle>
        );
    };

    const handleSendOtpToEmail = () => {
        if (isOtpFlow) {
            sendLoginOtp();
        } else {
            sendSignUpOtp();
        }
    };

    const sendLoginOtp = () => {
        sendLoginOtpToEmail(loginProps.email).subscribe(
            (loginOtpRes: any) => {
                setLoader(false);
                setIsOtpTimerActive(true);
                if (!loginOtpRes.data.success) {
                    showSnackbarError(LoginMessages.OTP_SEND_FAIL);
                }
            },
            (err: any) => {
                setLoader(false);
                setIsOtpTimerActive(true);

                let msg = LoginMessages.OTP_SEND_FAIL;
                if (err.response.data.error.message) {
                    msg = err.response.data.error.message;
                }
                showSnackbarError(msg);
            }
        );
    };

    const sendSignUpOtp = () => {
        sendLoginOtpToEmail(loginProps.email).subscribe(
            (loginOtpRes: any) => {
                setLoader(false);
                setIsOtpTimerActive(true);
                if (loginOtpRes.data.success) {
                    showSnackbarSuccess(LoginMessages.ACTIVATION_SEND_SUCCESS);
                } else if (!loginOtpRes.data.success) {
                    showSnackbarError(LoginMessages.ACTIVATION_SEND_FAILURE);
                }
            },
            () => {
                setLoader(false);
                setIsOtpTimerActive(true);
                showSnackbarError(LoginMessages.ACTIVATION_SEND_FAILURE);
            }
        );
    };

    const handleResendOtp = () => {
        setTimeLeft(OTP_RESEND_COUNTDOWN);
        setIsOtpTimerActive(true);
        handleSendOtpToEmail();
    };

    return (
        <Dialog
            onClose={() => {
                loginProps.handleClose();
            }}
            aria-describedby="alert-dialog-slide-description"
            open={loginProps.isOpen}
            maxWidth={'xs'}
            disableEscapeKeyDown={true}
            PaperProps={{
                sx: cssObject.modalPaper,
            }}
        >
            <LoadingOverlay open={loader}></LoadingOverlay>
            <Box sx={cssObject.root}>
                <Box sx={cssObject.main}>
                    <DialogTitle id="customized-dialog-title" onClose={() => loginProps.handleClose('')}></DialogTitle>
                    <DialogContent sx={cssObject.modalContent}>
                        <ContentContainer disableGrid background={'bg3'}>
                            <Container maxWidth={'lg'} disableGutters>
                                <Grid container spacing={2} justifyContent="center">
                                    <Grid item container justifyContent="center">
                                        <Box sx={cssObject.otpSectionStyle}>
                                            {!isOtpFlow && (
                                                <Box>
                                                    <Paragraph size={'normal'}>
                                                        Enter Activation code sent to email
                                                    </Paragraph>
                                                </Box>
                                            )}
                                            <OtpInput
                                                inputStyle={clsx(styles.otpInputStyle, {
                                                    [styles.otpInputFilledStyle]: otpValue.length === 4,
                                                })}
                                                errorStyle={styles.errorStyle}
                                                numInputs={4}
                                                isInputNum={true}
                                                value={otpValue}
                                                hasErrored={isOtpError}
                                                onChange={handleOtpPinChange}
                                            />
                                        </Box>
                                        {isOtpError && (
                                            <Box sx={cssObject.otpErrorSectionStyle}>
                                                <OtpErrorIcon />
                                                <Box sx={cssObject.otpErrorTextStyle}>
                                                    <Paragraph fontSizeVerySmall>
                                                        Code Mismatch, please refer your mail
                                                    </Paragraph>
                                                </Box>
                                            </Box>
                                        )}
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Button
                                            isFullWidth={true}
                                            color="primary"
                                            isSmallerFontForSmallerDevice
                                            disabled={isOtpError || otpValue.length < 4 ? true : false}
                                            onClick={handleSubmit}
                                        >
                                            Verify and Stay Connected
                                        </Button>
                                        <Box sx={cssObject.resendOtpSection}>
                                            {!isOtpTimerActive && (
                                                <Box sx={cssObject.resendOtpStyle} onClick={handleResendOtp}>
                                                    {isSignUpOtpFlow ? 'RESEND ACTIVIATION CODE' : 'RESEND OTP'}
                                                </Box>
                                            )}
                                            {isOtpTimerActive && (
                                                <Paragraph semibold>RESEND OTP in {timeLeft}s</Paragraph>
                                            )}
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Container>
                        </ContentContainer>
                    </DialogContent>
                </Box>
            </Box>
        </Dialog>
    );
}
export default Login;
