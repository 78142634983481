import {
    LoginResponse,
    LoginRequest,
    LogoutResponse,
    SignUpResponse,
    SignUpRequest,
    verifyOtpResponse,
    sendVerificationOtpToEmailResponse,
    sendLoginOtpResponse,
    isEmailAvailableRequest,
} from 'models/loginModel';
import axios from 'axios';
import config from '../../config';

import http, { getHeaders } from '../../index';

export const sendLoginRequest = (loginRequest: LoginRequest) =>
    http().post<LoginResponse>('/Customer/login', loginRequest);

export const sendSignUpRequest = (signupReq: SignUpRequest) =>
    http().post<SignUpResponse>('/Customer/signup', signupReq);

export const sendLogOutRequest = () => http().get<LogoutResponse>('/Customer/revokeToken');

export function sendVerificationOtpToEmail() {
    return http().post<sendVerificationOtpToEmailResponse>('/Customer/sendVerificationOtp');
}
export function verifyOtpApi(otpValue: string) {
    return http().post<verifyOtpResponse>('/Customer/verifyOtp', { otp: otpValue });
}

export function sendLoginOtpToEmail(emailID: string) {
    return http().post<sendLoginOtpResponse>('/Customer/sendLoginOtp', { customerIdentifier: emailID });
}

export const isEmailExists = async (req: isEmailAvailableRequest) => {
    try {
        const axiosObj = axios.create({
            baseURL: config.apiHost,
            headers: getHeaders(),
            timeout: config.httpTimeout,
        });
        const result = await axiosObj.post<boolean>('/Customer/isEmailExists', req);
        return { success: result.data, error: false };
    } catch (e) {
        return { success: false, error: true, message: (e as Error).message };
    }
};
