import { action, payload } from 'ts-action';
import { DoubleSystemWizardState, NumberOfHVAC, ProductCriteria, TypeOfService } from '../../models/productCriteria';

export const setPromotionCode = action('PRODUCT_PROMOTION::SET', payload<ProductCriteria>());
export const setProductCriteria = action('PRODUCT_CRITERIA::SET', payload<ProductCriteria>());
export const nextHomeInfo = action('PRODUCT_CRITERIA::NEXT');
export const resetProductCriteria = action('PRODUCT_CRITERIA::RESET');
export const setDoubleSystemFlag = action(
    'PRODUCT_CRITERIA::SET_DOUBLE_SYSTEM',
    payload<{ doubleSystemFlag?: DoubleSystemWizardState }>()
);
export const clearWizarQuestionData = action(
    'PRODUCT_CRITERIA::CLEAR_WIZARDQUESTION_DATA',
    payload<{ numberOfHVAC?: NumberOfHVAC, typeOfService?: TypeOfService }>()
);
