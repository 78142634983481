import { Address } from 'models/cart';
import { ContactUsRequest } from 'models/contact';
import { SignUpResponse } from 'models/loginModel';
import { action, payload } from 'ts-action';

export const getProfileAddresses = action('GET::PROFILE_ADDRESSES');
export const getProfileAddressesSuccess = action('GET_PROFILE_ADDRESSES::SUCCESS', payload<{ adresses: Address[] }>());
export const getProfileAddressesFailure = action('GET_PROFILE_ADDRESSES::FAILURE', payload<Error>());

export const performDefaultBillingAddressUpdate = action(
    'PERFORM::PROFILE_ADDRESS_UPDATE',
    payload<{ Address: Address; addressId: string; contactDetails: ContactUsRequest }>()
);
export const performDefaultBillingAddressUpdateSuccess = action(
    'PERFORM::PROFILE_ADDRESS_UPDATE_SUCCESS',
    payload<Address>()
);
export const profileDetailsSuccess = action('PROFILE_DETAILS::SUCCESS', payload<SignUpResponse>());
export const defaultBillingAddressUpdateFailure = action('DEFAULT_BILLING_ADDRESS_UPDATE::FAILURE', payload<Error>());

export const addDefaultProfileBillingAddress = action(
    'ADD::DEFAULT_PROFILE_BILLING_ADDRESS',
    payload<{ Address: Address; contactDetails: ContactUsRequest }>()
);
export const addDefaultProfileBillingAddressSuccess = action('ADD_DEFAULT_PROFILE_BILLING_ADDRESS::SUCCESS');
export const addDefaultProfileBillingAddressFailure = action(
    'ADD_DEFAULT_PROFILE_BILLING_ADDRESS::FAILURE',
    payload<Error>()
);

export const sendContactUsRequest = action('SEND::CONTACT_US_REQUEST', payload<ContactUsRequest>());

export const showChooseAddressPopupAction = action('SHOW::CHOOSE_ADDRESS');
export const hideChooseAddressPopupAction = action('HIDE::CHOOSE_ADDRESS');

export const showEditAddressPopupAction = action('SHOW::EDIT_ADDRESS');
export const hideEditAddressPopupAction = action('HIDE::EDIT_ADDRESS');

export const setAddressToEdit = action('ADDRESS::TO_EDIT', payload<{ address?: Address }>());

export const getAddressListAction = action('GET::ADDRESS_LIST', payload<boolean>());
export const getAddressListSuccess = action('ADDRESS_LIST::SUCCESS', payload<Address[]>());
export const getAddressListFailure = action('ADDRESS_LIST::FAILURE');
export const resetData = action('RESET::DATA');
