import { validPhoneNumber } from 'store/services/phoneServices';

async function validate(phoneNumber: string, props: any) {
    const { isMounted, setShowValidMessage, setValidating, setCheckedPhones, checkedPhones } = props;
    // Phone number
    if (isMounted) {
        setShowValidMessage(false);
    }
    let errorMsg;
    if (phoneNumber.length === 10) {
        const checkedPhone = checkedPhones.find((phoneObj: any) => phoneObj.phone === phoneNumber);
        if (checkedPhone) {
            if (!checkedPhone.valid) {
                if (isMounted) {
                    setShowValidMessage(true);
                }
                errorMsg = 'Invalid Phone Number';
            }
        } else {
            if (isMounted) {
                setValidating(true);
            }
            try {
                const result = await validPhoneNumber(phoneNumber);
                if (result) {
                    if (isMounted) {
                        setCheckedPhones([
                            ...checkedPhones,
                            {
                                phone: phoneNumber,
                                valid: true,
                            },
                        ]);
                    }
                } else {
                    errorMsg = 'Invalid Phone Number';
                    if (isMounted) {
                        setCheckedPhones([
                            ...checkedPhones,
                            {
                                phone: phoneNumber,
                                valid: false,
                            },
                        ]);
                        setShowValidMessage(true);
                    }
                }
            } catch (err) {
                if (isMounted) {
                    setShowValidMessage(true);
                }
                errorMsg = 'Invalid Phone Number';
            }
            if (isMounted) {
                setValidating(false);
            }
        }
    } else if (phoneNumber.length === 0) {
        errorMsg = 'Please enter your Phone Number';
    } else {
        errorMsg = 'Invalid Phone Number';
    }
    return errorMsg;
}
export { validate };
