import React from 'react';
import { Theme, useTheme } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import { ExternalLink } from 'components/Link';
import { WizardLogoNewWithLink } from '../Logo';
import MenuDrawer from './MenuDrawer';
import { useNavigate } from 'react-router-dom';
import { AppRoute } from 'models/route';
import { SXCssObject } from 'models/ThemeImages';

const createCssObject = (theme: Theme): SXCssObject => ({
    root: {
        width: '100%',
        height: theme.spacing(8),
        display: 'flex',
        justifyContent: 'center',
    },
    logo: {
        paddingTop: '5px !important',
    },
});

interface Props {
    color?: 'primary' | 'secondary';
}

export default function StoreNavigator(props: Props) {
    const cssObject: SXCssObject = createCssObject(useTheme());
    const { color = 'primary' } = props;
    const navigate = useNavigate();
    return (
        <nav
            style={{
                width: '100%',
                height: '80px',
                display: 'flex',
                justifyContent: 'center',
            }}
        >
            <Grid container alignItems={'center'}>
                <Grid item xs={6} sm={3} md={3} sx={cssObject.logo}>
                    <WizardLogoNewWithLink
                        id={'nav-landing'}
                        color={color}
                        onClick={() => {
                            navigate(AppRoute.Home);
                        }}
                    />
                </Grid>
                <Grid
                    item
                    xs={6}
                    sm={9}
                    md={9}
                    container
                    alignItems={'center'}
                    justifyContent={'flex-end'}
                    sx={{ display: { xs: 'flex', lg: 'none' } }}
                >
                    <MenuDrawer color={color} />
                </Grid>
                <Grid item lg={9} sx={{ display: { xs: 'none', lg: 'flex' } }}>
                    <Grid container spacing={4} alignItems={'center'} justifyContent={'flex-end'}>
                        <Grid item>
                            <ExternalLink
                                id={'nav-link-chicago'}
                                onClick={() => {
                                    window.location.href = `http://chicagoland.${window.location.host}`.toLowerCase();
                                }}
                                label={'CHICAGOLAND (IL)'}
                                color={color}
                            />
                        </Grid>
                        <Grid item>
                            <ExternalLink
                                id={'nav-link-missouri'}
                                onClick={() => {
                                    window.location.href = `http://missouri.${window.location.host}`.toLowerCase();
                                }}
                                label={'MISSOURI (MO, KS)'}
                                color={color}
                            />
                        </Grid>
                        <Grid item>
                            <ExternalLink
                                id={'nav-link-washington'}
                                onClick={() => {
                                    window.location.href = `http://greaterdc.${window.location.host}`.toLowerCase();
                                }}
                                label={'WASHINGTON, DC AREA (VA, MD, DE)'}
                                color={color}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </nav>
    );
}
