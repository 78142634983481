import * as React from 'react';
import { ReactComponent as Icon } from 'assets/icons/icon_loading.svg';
import { styled } from '@mui/material/styles';

const StyledDiv = styled('div')({
    display: 'inline-block',
    animation: 'circular-rotate 1.0s linear infinite',
    '@keyframes circular-rotate': {
        '100%': {
            transform: 'rotate(360deg)',
        },
    },
});

export default function Loading() {
    return (
        <StyledDiv id={'loading'}>
            <Icon title={'Loading'} style={{ display: 'block' }} />
        </StyledDiv>
    );
}
