import { Theme, useTheme } from '@mui/material/styles';
import { Grid, Snackbar } from '@mui/material';
import React from 'react';
import OtpErrorIcon from './icons/OtpErrorIcon';
import { Paragraph } from './Paragraph';
import { SXCssObject } from 'models/ThemeImages';

const createCssObject = (theme: Theme): SXCssObject => ({
    viewCartText: {
        color: theme.palette.primary.main,
        fontWeight: theme.typography.fontWeightMedium,
        cursor: 'pointer',
        whiteSpace: 'nowrap',
    },
    snackbarRoot: {
        bottom: {
            md: '90px',
        },
        position: 'unset',
        zIndex: 0,
    },
    notificationContainer: {
        background: '#FFFFFF 0% 0% no-repeat padding-box',
        boxShadow: '0px 7px 20px #4D4E5729',
        border: '1px solid #F86767',
        padding: '15px',
        display: 'flex',
        justifyContent: 'space-between',
        borderRadius: {
            xs: '30px',
            sm: '40px',
        },
        alignItems: {
            xs: 'flex-start',
            sm: 'inherit',
        },
    },
    closeButton: {
        display: 'flex',
        cursor: 'pointer',
        justifyContent: 'flex-end',
    },
    b: {
        fontWeight: theme.typography.fontWeightMedium,
    },
});

interface Props {
    isOpen: boolean;
    errorText: string;
}

export default function InvalidAmountNotification(props: Props) {
    const cssObject: SXCssObject = createCssObject(useTheme());
    return (
        <React.Fragment>
            <Snackbar
                sx={cssObject.snackbarRoot}
                open={props.isOpen}
                autoHideDuration={null}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
            >
                <Grid container sx={cssObject.notificationContainer} xs={12} md={12}>
                    <Grid item style={{ display: 'flex', alignItems: 'center' }}>
                        <OtpErrorIcon />
                        <span style={{ marginLeft: '10px' }}>
                            <Paragraph size={'small'}>{props.errorText}</Paragraph>
                        </span>
                    </Grid>
                </Grid>
            </Snackbar>
        </React.Fragment>
    );
}
