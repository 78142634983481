import React from 'react';
import { useTheme } from '@mui/material';
import { getThemePrimaryMain } from 'components/common/Utilities';

export const PropertiesIcon = (props: any) => {
    const theme = useTheme();
    let color = getThemePrimaryMain(theme);
    if (props.color) {
        //Color can be passed in props dynamically; if color is not passed, use primary.main
        color = props.color;
    }
    return (
        <svg data-name="Component 180 \u2013 1" xmlns="http://www.w3.org/2000/svg" width={52} height={52} {...props}>
            <g
                data-name="Group 667"
                fill="none"
                stroke={color}
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
            >
                <path
                    data-name="Union 23"
                    d="M19.199 45h-5.2a1 1 0 0 1-1-1V11a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1v7.693h12a2 2 0 0 1 2 2V43a2 2 0 0 1-2 2Z"
                />
                <g data-name="Rectangle 704">
                    <path stroke="none" d="M23.999 31h6v13h-6z" />
                    <path d="M22.999 30h8v15h-8z" />
                </g>
            </g>
        </svg>
    );
};
