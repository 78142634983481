import React, { useRef } from 'react';
import { Form, Formik, FormikValues } from 'formik';
import { Theme } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import { Button } from 'components/Button';
import ContentContainer from 'components/ContentContainer';
import FormikTextField from 'components/FormikTextField';
import { Box, Checkbox, Dialog, DialogContent, useTheme } from '@mui/material';
import OtpInput from 'react-otp-input';
import WizardInputLogin from 'components/WizardInputLogin';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { checkDomainAndLoginSchema, checkDomainAndSignUpSchema } from 'pages/user/login-schema';
import { validate as validatePhone } from '../../validations/phoneNumber';
import MuiDialogTitle from '@mui/material/DialogTitle';
import Close from 'components/icons/Close';
import LoadingOverlay from 'components/common/LoadingOverlay';
import { sendLoginOtpToEmail, sendLoginRequest, sendSignUpRequest } from 'store/services/loginServices';
import PhoneField from 'components/FormikPhoneField';
import OtpErrorIcon from 'components/icons/OtpErrorIcon';
import { loginSuccess, signUpSuccess } from 'store/actions/loginActions';
import { useDispatch, useSelector } from 'react-redux';
import { LoginRequest, LoginValidationObject, SignUpRequest } from 'models/loginModel';
import clsx from 'clsx';
import { LoginMessages } from 'components/common/constants';
import { setIsAliveCookie } from 'index';
import { Paragraph } from 'components/Paragraph';
import { Heading } from 'components/Heading';
import { useLocation } from 'react-router';
import { showSnackbarError } from 'components/common/Snackbar/SnackbarHelper';
import { selectStoreFeatures } from 'store/selectors/storeSelector';
import config from 'config';
import { get } from 'lodash';
import { SXCssObject } from 'models/ThemeImages';
import { invokeGAHomePageSignUp, invokeGAHomePageLogin } from 'components/Analytics/GoogleAnalyticsTagging';
import styles from 'components/common/CommonStyle.module.css';
import { contactPageViewAction } from 'store/actions/hubspotActions';

const createCssObject = (theme: Theme): SXCssObject => ({
    root: { padding: '15px 0px', maxWidth: '360px' },
    toggle: {
        width: '100%',
        backgroundColor: '#E9E9E9',
    },
    main: {
        display: 'flex',
        flexDirection: 'column',
    },
    checkboxParentStyle: {
        color: '#CCCCCC',
        paddingLeft: '0px',
        paddingRight: '0px',
        '&.MuiFormControlLabel-root': {
            marginRight: '0px !important',
        },
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
    },
    checkbox: {
        color: 'divider',
        marginRight: '6px',
        marginLeft: '-9px',
        '&.Mui-checked': {
            color: 'action.hover',
        },
        '&.MuiFormControlLabel-root': {
            marginRight: '0px !important',
        },
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
    },
    modalContent: {
        overflow: 'hidden',
    },
    otpSectionStyle: {
        padding: '5px 0px 10px',
    },
    otpErrorSectionStyle: {
        display: 'flex',
        alignItems: 'center',
        marginTop: '10px',
        marginBottom: '15px',
    },
    otpErrorTextStyle: {
        paddingLeft: '5px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    modalTitleContainer: {
        padding: '10px 24px 24px',
        position: 'relative',
        marign: '0px',
    },
    closeButton: {
        position: 'absolute',
        right: 16,
        top: 0,
        color: '#9e9e9e',
        cursor: 'pointer',
    },
    resendOtpSection: {
        textAlign: 'center',
        marginTop: '30px',
    },
    resendOtpStyle: {
        display: 'inline',
        color: 'primary.main',
        fontWeight: 'fontWeightMedium',
        cursor: 'pointer',
    },
    termsAndConditionsRootStyle: {
        display: 'flex',
        justifyContent: 'left',
        flexDirection: 'row',
    },
    termsAndConditionsSectionStyle: {
        display: 'inline-flex',
        justifyContent: 'center',
        flexDirection: 'column',
    },
    termsAndConditionsStyle: {
        color: 'primary.main',
        display: 'inline',
        cursor: 'pointer',
        fontWeight: '600',
    },
    modalPaper: {
        borderRadius: '4px',
        maxWidth: 360,
        m:{
            xs:'12px !important',
            sm:'inherit'
        }
    },
});

const OTP_RESEND_COUNTDOWN: number = 20;
interface FormValues {
    email: string;
    phone?: string;
    firstname?: string;
    lastname?: string;
}
interface Props {
    loading: boolean;
    isOpen: boolean;
    handleClose: (snackbarMessage?: string) => void;
    clearLoginDataInStore?: boolean;
    isSignUpOtpFlow?: boolean;
    isOtpFlow?: boolean; //will be set true when a registered user is identified(after entering email in emailwizard).
    //Login/Signup tab will be hidden. Only otp box to be shown
}

function LoginInHome(loginProps: Props) {
    const [isMounted, setIsMounted] = React.useState(false);
    const storeFeatures = useSelector(selectStoreFeatures);
    const [loader, setLoader] = React.useState(false);
    const [isLoginInputFieldsVisible, setLoginInputFieldsVisibility] = React.useState(true);
    const [isLoginOtpFieldVisible, setLoginOtpFieldVisibility] = React.useState(false);

    const [validatingPhone, setValidatingPhone] = React.useState(false);
    const [loginOtpValue, setLoginOtpValue] = React.useState('');
    const [isLoginOtpError, setLoginOtpError] = React.useState(false);

    const [termsAndConditionsState, setTermsAndConditionsState] = React.useState(false);

    const [showPhoneValidMessage, setShowPhoneValidMessage] = React.useState(false);
    const [checkedPhones, setCheckedPhones] = React.useState(new Array<{ phone: string; valid: boolean }>());

    const [enteredEmail, setEnteredEmail] = React.useState('');

    const [isOtpTimerActive, setIsOtpTimerActive] = React.useState(false);

    const [alignment, setAlignment] = React.useState('login');

    const [timeLeft, setTimeLeft] = React.useState(OTP_RESEND_COUNTDOWN);
    const dispatch = useDispatch();
    const location: any = useLocation();

    React.useEffect(() => {
        setIsMounted(true);
        // do componentDidMount logic
        mounted.current = true;

        // do componentDidUpdate logic
        if (!isOtpTimerActive) return;
        if (!timeLeft) {
            setIsOtpTimerActive(false);
            return;
        }
        const intervalId = setInterval(() => {
            setTimeLeft(timeLeft - 1);
        }, 1000);

        return () => clearInterval(intervalId);
    }, [isOtpTimerActive, timeLeft, isLoginInputFieldsVisible, isLoginOtpFieldVisible]);

    let mounted: any = useRef();

    const cssObject: SXCssObject = createCssObject(useTheme());
    const shouldCheckDomain = get(storeFeatures, 'signUpEmailDomainCheck.enable', false);
    const validDomains = get(storeFeatures, 'signUpEmailDomainCheck.domains', '');
    const errorMsg = get(config, 'content.error.invalidDomainErrorMsg', '');

    const loginValidationObj: LoginValidationObject = {
        validDomains,
        shouldCheckDomain,
        errorMsg,
    };

    const validate = async (values: FormikValues) => {
        // validate phoneNumber
        const validatePhoneMsg = await validatePhone(values.phone?.replace(/_|\./gi, ''), {
            isMounted: isMounted,
            setShowValidMessage: setShowPhoneValidMessage,
            setValidating: setValidatingPhone,
            setCheckedPhones: setCheckedPhones,
            checkedPhones: checkedPhones,
        });
        if (validatePhoneMsg) {
            return {
                phone: validatePhoneMsg,
            };
        }
    };

    const handleTermsAndConditionsClick = () => {
        window.open('/terms-conditions', '_blank');
    };

    const handleCheckboxStateChange = () => {
        setTermsAndConditionsState(!termsAndConditionsState);
    };
    const showSignUpForm = () => {
        return (
            <Formik
                initialValues={{
                    firstname: '',
                    lastname: '',
                    email: '',
                    phone: '',
                }}
                onSubmit={handleSignUpSubmit}
                validateOnMount={true}
                validationSchema={checkDomainAndSignUpSchema(loginValidationObj)}
                validate={validate}
                initialTouched={{
                    phone: true,
                }}
            >
                {({ submitForm, isValid, values, errors }) => (
                    <ContentContainer disableGrid background={'bg3'}>
                        <Container maxWidth={'lg'} disableGutters>
                            <Grid container spacing={4}>
                                <Grid item xs={12}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12}>
                                            <Grid container spacing={2}>
                                                <Grid item xs={12}>
                                                    <FormikTextField
                                                        id={'firstname'}
                                                        name="firstname"
                                                        label={'First Name *'}
                                                        placeholder={'First Name'}
                                                    />
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <FormikTextField
                                                        id={'lastname'}
                                                        name="lastname"
                                                        label={'Last Name *'}
                                                        placeholder={'Last Name'}
                                                    />
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <FormikTextField
                                                        id={'email'}
                                                        name="email"
                                                        label={'Email Address *'}
                                                        type={'email'}
                                                        placeholder={'Email Address'}
                                                        enableErrorDisplay={
                                                            shouldCheckDomain && errors.email === errorMsg
                                                        }
                                                    />
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <PhoneField
                                                        id={'phone'}
                                                        name={'phone'}
                                                        shouldDisableInputField={false}
                                                        label={'Phone Number *'}
                                                        placeholder={'000.000.0000'}
                                                        fullWidth
                                                        enableErrorDisplay={showPhoneValidMessage}
                                                        validating={validatingPhone}
                                                    />
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <Box sx={cssObject.termsAndConditionsRootStyle}>
                                                        <Box component={'span'} sx={cssObject.checkboxParentStyle}>
                                                            <Checkbox
                                                                id={'terms-and-conditions-checkbox'}
                                                                name={'conditions'}
                                                                sx={cssObject.checkbox}
                                                                checked={termsAndConditionsState}
                                                                onChange={handleCheckboxStateChange}
                                                            />
                                                        </Box>
                                                        <Box
                                                            sx={cssObject.termsAndConditionsSectionStyle}
                                                            onClick={handleTermsAndConditionsClick}
                                                        >
                                                            <Box component={'span'}>
                                                                I agree to the
                                                                <Box
                                                                    component={'span'}
                                                                    sx={cssObject.termsAndConditionsStyle}
                                                                >
                                                                    {' '}
                                                                    Terms and Conditions
                                                                </Box>
                                                            </Box>
                                                        </Box>
                                                    </Box>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <Button
                                                        isFullWidth={true}
                                                        color="primary"
                                                        isSmallerFontForSmallerDevice
                                                        disabled={!isValid || !termsAndConditionsState}
                                                        onClick={submitForm}
                                                    >
                                                        Register
                                                    </Button>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Container>
                    </ContentContainer>
                )}
            </Formik>
        );
    };

    const checkEmailAvailabilityForSignUp = async (values: FormValues) => {
        const signUpReq: SignUpRequest = {
            phone: values.phone?.replace(/\./g, ''),
            firstname: values.firstname,
            lastname: values.lastname,
            email: values.email,
        };
        setLoader(true);
        sendSignUpRequest(signUpReq).subscribe(
            (res: any) => {
                if (res.data.success) {
                    invokeGAHomePageSignUp();
                    setIsAliveCookie();
                    dispatch(signUpSuccess(res.data));
                    loginProps.handleClose(LoginMessages.MSG_SUCCESS_REGISTER);
                    dispatch(contactPageViewAction({ email: res?.data?.email }));
                }
            },
            (err: any) => {
                setLoader(false);
                let msg = LoginMessages.MSG_FAILURE_REGISTER;
                try {
                    if (err.response.data.error.message) {
                        msg = err.response.data.error.message;
                    }
                } catch (e) {}
                showSnackbarError(msg);
            }
        );
    };

    const checkEmailAndVerifyLoginOtp = async (values: FormValues) => {
        setLoader(true);
        setEnteredEmail(values.email);
        handleSendLoginOtpToEmail(values.email);
    };
    const handleLoginSubmit = (values: FormValues) => {
        if (isLoginInputFieldsVisible) {
            checkEmailAndVerifyLoginOtp(values);
        } else {
            submitLoginOtp(values);
        }
    };
    const handleSignUpSubmit = (values: FormValues) => {
        checkEmailAvailabilityForSignUp(values);
    };
    const handleLoginOtpChange = (otp: string) => {
        setLoginOtpValue(otp);
        setLoginOtpError(false);
    };

    const submitLoginOtp = (values: FormValues) => {
        let loginReq: LoginRequest = {
            customerIdentifier: enteredEmail,
            otp: loginOtpValue,
        };
        setLoader(true);
        sendLoginRequest(loginReq).subscribe(
            (res: any) => {
                setLoader(false);
                if (res.data.success) {
                    invokeGAHomePageLogin();
                    setIsAliveCookie();
                    loginProps.handleClose(LoginMessages.MSG_SUCCESS_LOGIN);
                    let obj: any = {};
                    obj.loginResponse = res.data;
                    if (location && location.state) {
                        obj.route = location.state.pageToRedirect;
                        obj.params = location.search;
                    }
                    dispatch(loginSuccess(obj));
                }
            },
            () => {
                setLoader(false);
                setLoginOtpError(true);
            }
        );
    };

    const DialogTitle = (props: any) => {
        const { onClose } = props;
        return (
            <MuiDialogTitle sx={cssObject.modalTitleContainer}>
                <Heading label={'Welcome'} isPopUp />

                <Paragraph semibold>Login or Sign Up Here and stay connected</Paragraph>

                <Box aria-label="close" sx={cssObject.closeButton} onClick={onClose}>
                    <Close />
                </Box>
            </MuiDialogTitle>
        );
    };

    const handleAlignment = (event: any, newAlignment: string) => {
        setTermsAndConditionsState(false);
        if (newAlignment !== null) {
            setAlignment(newAlignment);
        }
    };

    const handleSendLoginOtpToEmail = (email: string) => {
        setLoader(true);
        sendLoginOtpToEmail(email).subscribe(
            (loginOtpRes: any) => {
                setLoader(false);
                if (loginOtpRes.data.success) {
                    setLoginInputFieldsVisibility(false);
                    setLoginOtpFieldVisibility(true);
                    setIsOtpTimerActive(true);
                } else if (!loginOtpRes.data.success) {
                    showSnackbarError(LoginMessages.OTP_SEND_FAIL);
                }
            },
            (err: any) => {
                setLoader(false);
                let msg = LoginMessages.OTP_SEND_FAIL;
                try {
                    if (err.response.data.error.message) {
                        msg = err.response.data.error.message;
                    }
                } catch (e) {}
                showSnackbarError(msg);
            }
        );
    };

    const handleResendOtp = () => {
        setTimeLeft(OTP_RESEND_COUNTDOWN);
        setIsOtpTimerActive(true);

        setLoader(true);
        handleSendLoginOtpToEmail(enteredEmail);
    };
    return (
        <Dialog
            onClose={() => {
                loginProps.handleClose();
            }}
            aria-describedby="alert-dialog-slide-description"
            open={loginProps.isOpen}
            maxWidth={'xs'}
            disableEscapeKeyDown={true}
            PaperProps={{
                sx: cssObject.modalPaper,
            }}
        >
            <LoadingOverlay open={loader}></LoadingOverlay>
            <Box sx={cssObject.root}>
                <Box sx={cssObject.main}>
                    <DialogTitle id="customized-dialog-title" onClose={() => loginProps.handleClose('')}></DialogTitle>
                    <DialogContent sx={cssObject.modalContent}>
                        <Grid container spacing={4} justifyContent="center">
                            <Grid item xs={12}>
                                <ToggleButtonGroup
                                    sx={cssObject.toggle}
                                    value={alignment}
                                    exclusive
                                    onChange={handleAlignment}
                                    aria-label="text alignment"
                                >
                                    <WizardInputLogin value="login">LOGIN</WizardInputLogin>
                                    <WizardInputLogin value="signup">SIGN UP</WizardInputLogin>
                                </ToggleButtonGroup>
                            </Grid>
                        </Grid>
                        <br />
                        {alignment === 'login' && (
                            <Formik
                                initialValues={{
                                    email: '',
                                }}
                                onSubmit={handleLoginSubmit}
                                validateOnMount={true}
                                validationSchema={
                                    isLoginInputFieldsVisible ? checkDomainAndLoginSchema(loginValidationObj) : null
                                }
                            >
                                {({ submitForm, isValid, values, errors }) => (
                                    <Form>
                                        <ContentContainer disableGrid background={'bg3'}>
                                            <Container maxWidth={'lg'} disableGutters>
                                                <Grid container spacing={4} justifyContent="center">
                                                    <Grid container item xs={12}>
                                                        <Grid item container spacing={2}>
                                                            <Grid item xs={12} hidden={!isLoginInputFieldsVisible}>
                                                                <FormikTextField
                                                                    id="email"
                                                                    name="email"
                                                                    label="Email Address *"
                                                                    type="email"
                                                                    placeholder="Email Address"
                                                                    enableErrorDisplay={
                                                                        shouldCheckDomain && errors.email === errorMsg
                                                                    }
                                                                />
                                                            </Grid>
                                                            {isLoginOtpFieldVisible && (
                                                                <Grid item container justifyContent="center">
                                                                    <Box sx={cssObject.otpSectionStyle}>
                                                                        <Paragraph semiLargeFont>
                                                                            Enter One-time password sent to email
                                                                        </Paragraph>
                                                                        <Box
                                                                            sx={{
                                                                                display: 'flex',
                                                                                justifyContent: 'center',
                                                                            }}
                                                                        >
                                                                            <OtpInput
                                                                                inputStyle={clsx(styles.otpInputStyle, {
                                                                                    [styles.otpInputFilledStyle]:
                                                                                        loginOtpValue.length === 4,
                                                                                })}
                                                                                errorStyle={styles.errorStyle}
                                                                                numInputs={4}
                                                                                isInputNum={true}
                                                                                value={loginOtpValue}
                                                                                hasErrored={isLoginOtpError}
                                                                                onChange={handleLoginOtpChange}
                                                                            />
                                                                        </Box>
                                                                    </Box>
                                                                    {isLoginOtpError && (
                                                                        <Box sx={cssObject.otpErrorSectionStyle}>
                                                                            <OtpErrorIcon />
                                                                            <Box sx={cssObject.otpErrorTextStyle}>
                                                                                <Paragraph fontSizeVerySmall>
                                                                                    {' '}
                                                                                    Code Mismatch, please refer your
                                                                                    mail
                                                                                </Paragraph>
                                                                            </Box>
                                                                        </Box>
                                                                    )}
                                                                </Grid>
                                                            )}
                                                            <Grid item xs={12}>
                                                                <Button
                                                                    isFullWidth={true}
                                                                    color="primary"
                                                                    isSmallerFontForSmallerDevice
                                                                    disabled={(() => {
                                                                        if (isLoginInputFieldsVisible) {
                                                                            return !isValid;
                                                                        }
                                                                        return isLoginOtpError ||
                                                                            loginOtpValue.length < 4
                                                                            ? true
                                                                            : false;
                                                                    })()}
                                                                    onClick={submitForm}
                                                                >
                                                                    {isLoginInputFieldsVisible
                                                                        ? 'Send OTP'
                                                                        : 'Verify and Stay Connected'}
                                                                </Button>
                                                                <Box
                                                                    sx={cssObject.resendOtpSection}
                                                                    hidden={isLoginInputFieldsVisible}
                                                                >
                                                                    {!isOtpTimerActive && (
                                                                        <Box
                                                                            sx={cssObject.resendOtpStyle}
                                                                            onClick={handleResendOtp}
                                                                        >
                                                                            RESEND OTP
                                                                        </Box>
                                                                    )}
                                                                    {isOtpTimerActive && (
                                                                        <Box>
                                                                            <Paragraph semibold>
                                                                                RESEND OTP IN {timeLeft}s
                                                                            </Paragraph>
                                                                        </Box>
                                                                    )}
                                                                </Box>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Container>
                                        </ContentContainer>
                                    </Form>
                                )}
                            </Formik>
                        )}
                        {alignment === 'signup' && showSignUpForm()}
                    </DialogContent>
                </Box>
            </Box>
        </Dialog>
    );
}
export default LoginInHome;
