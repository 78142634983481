import { Box, Grid } from '@mui/material';
import React from 'react';
import { Theme, useTheme } from '@mui/material/styles';
import { Add, Remove } from '@mui/icons-material';
import { SXCssObject } from 'models/ThemeImages';

const createCssObject = (theme: Theme): SXCssObject => ({
    counterParent: {
        borderRadius: '20px',
        border: '1px solid #D4D4D4',
        minWidth: '120px',
        width: 'auto',
        height: '40px',
        display: 'inline-flex',
        alignItems: 'center',
    },
    buttonStyle: {
        cursor: 'pointer',
        textAlign: 'center',
        fontSize: '1rem',
        fontWeight: 'bold',
        lineHeight: '22px',
        userSelect: 'none',
        flex: 1,
        display: 'flex',
        justifyContent: 'center',
        '& svg': {
            height: '20px',
            widht: '20px',
            display: 'flex',
            justifyContent: 'center',
        },
    },
    textStyle: {
        fontSize: '0.875rem',
        lineHeight: '22px',
        textAlign: 'center',
        padding: '1px 5px',
        height: '100%',
        flex: 1,
        display: 'flex',
        alignItems: 'center',
        borderLeft: '1px solid #D4D4D4',
        borderRight: '1px solid #D4D4D4',
    },
    disabledStyle: {
        color: '#cccccc',
        cursor: 'default',
    },
});
interface Props {
    quantity: number;
    onCounterChange: (addOnQuantity: number) => void;
}
export default function Counter(props: Props) {
    const cssObject: SXCssObject = createCssObject(useTheme());

    const handleIncrement = () => {
        props.onCounterChange(props.quantity + 1);
    };
    const handleDecrement = () => {
        if (props.quantity! > 0) {
            props.onCounterChange(props.quantity - 1);
        }
    };
    return (
        <Grid sx={cssObject.counterParent}>
            <Box sx={[cssObject.buttonStyle, props.quantity <= 0 && cssObject.disabledStyle]}>
                <Remove onClick={props.quantity > 0 ? handleDecrement : () => {}} />
            </Box>

            <Box sx={[cssObject.textStyle, props.quantity === 0 && cssObject.disabledStyle]}>
                <Box style={{ width: '100%' }}>{props.quantity!}</Box>
            </Box>

            <Box sx={cssObject.buttonStyle}>
                <Add onClick={handleIncrement} />
            </Box>
        </Grid>
    );
}
