import React, { Fragment } from 'react';
import { Props as MenuProps } from 'components/SecondaryNavigation';
import { Theme, useTheme } from '@mui/material/styles';
import SubPage from 'components/subpage/SubPage';
import Grid from '@mui/material/Grid';
import CardMedia from '@mui/material/CardMedia';
import ProTipAndAdditionalLinks from 'components/subpage/ProTipAndAdditionalLinks';
import { Heading } from 'components/Heading';
import { Paragraph } from 'components/Paragraph';
import { getSubPageMenus } from './menu-util';
import VideoOverlay from '../../components/VideoOverlay';
import ContentContainer from 'components/ContentContainer';
import VideoOverlayBackground from 'assets/images/knowledges/kc_video_background_4.png';
import HeatPumpsImage from 'components/icons/HeatPumpsImage';
import PlayButton from 'components/icons/PlayButton';
import TooltipIdeaIcon from 'components/icons/TooltipIdeaIcon';
import { getThemePrimaryMain } from 'components/common/Utilities';
import { SXCssObject } from 'models/ThemeImages';

function HeatPumps() {
    const createCssObject = (theme: Theme): SXCssObject => {
        return {
            contentHeading: {
                paddingBottom: theme.spacing(2),
            },
            contentImage: {
                paddingTop: theme.spacing(2),
                backgroundColor: '#FAFAFA',
            },
            image: {
                margin: 'auto',
                width: {
                    sm: '505px',
                },
            },
            contentInteractive: {
                alignItems: 'center',
                justifyContent: 'center',
                backgroundImage: `url(${VideoOverlayBackground})`,
                margin: theme.spacing(2, 0),
                height: {
                    xs: theme.spacing(20),
                    sm: theme.spacing(34),
                },
                backgroundPosition: 'center',
                backgroundSize: 'contain',
                backgroundRepeat: 'no-repeat',
            },
            contentSecondaryHeading: {
                paddingTop: {
                    xs: theme.spacing(2),
                    sm: theme.spacing(4),
                },
                paddingBottom: theme.spacing(2),
            },
            tooltipContainer: {
                padding: {
                    xs: theme.spacing(2),
                    sm: '35px',
                },
                border: `1px solid ${theme.palette.primary.contrastText}`,
                borderRadius: '4px',
                margin: '40px 0px',
            },
            tooltipIcon: {
                marginRight: '10px',
            },
            toolTipHeading: {
                paddingBottom: theme.spacing(2),
                alignItems: 'center',
            },
            listPoint: {
                backgroundColor: getThemePrimaryMain(theme),
                borderRadius: '50%',
                padding: theme.spacing(0, 0.9, 0, 0.9),
                fontSize: '12px',
                color: theme.palette.common.white,
                marginRight: '10px',
                marginTop: '4px',
            },
            listPointContainer: {
                padding: '5%',
            },
            playButton: {
                cursor: 'pointer',
                '&:hover': {
                    opacity: 0.9,
                },
                width: {
                    xs: '80px',
                    sm: 'inherit',
                },
                paddingTop: {
                    xs: theme.spacing(2),
                    sm: theme.spacing(3),
                },
            },
            contentHeadingText: {
                paddingBottom: '20px',
            },
        };
    };

    const menu: MenuProps = {
        id: 'menu',
        label: 'More Knowledge Center Topic',
        color: 'primary',
        subMenus: getSubPageMenus('heat_pumps'),
    };
    const cssObject: SXCssObject = createCssObject(useTheme());
    const [openVdo, setOpenVdo] = React.useState(false);
    const handleCloseVdo = () => {
        setOpenVdo(false);
    };
    return (
        <SubPage
            menu={menu}
            content1={
                <Fragment>
                    <Grid item xs={12} sx={cssObject.contentHeading}>
                        <Heading variant={'h3'} isPopUp color={'primary'} label={'Heat Pumps'} />
                    </Grid>
                    <Grid item xs={12} sx={cssObject.contentHeadingText}>
                        <Paragraph responsiveFontSize16 align={'left'}>
                            A heat pump transfers heat from one place to another. It sounds simple, but where is the
                            heat coming from if fuel isn’t being burned? An air source heat pump system uses advanced
                            technology and the refrigeration cycle to heat and cool your home. This allows a heat pump
                            to provide year-round indoor comfort – no matter the season. In the warmer months, the heat
                            pump acts as an air conditioner - drawing out interior heat and humidity, and transfers it
                            outside. During colder months, heat from the outdoor air is extracted and transferred to the
                            interior of your home. Believe it or not, even a 32°F day produces enough heat to warm your
                            home. Science is amazing!
                        </Paragraph>
                    </Grid>
                    <Paragraph responsiveFontSize20 bold headingStyle>
                        Watch this video to learn how a heat pump system keeps you comfortable
                    </Paragraph>
                    <Grid item xs={12} container sx={cssObject.contentInteractive}>
                        <div>
                            <PlayButton
                                onClick={() => {
                                    setOpenVdo(true);
                                }}
                            />
                        </div>{' '}
                    </Grid>
                    <Grid item xs={12} sx={cssObject.contentImage}>
                        <CardMedia style={{ overflowY: 'auto' }}>
                            <HeatPumpsImage />
                        </CardMedia>
                        <Grid>
                            <Grid sx={cssObject.listPointContainer} container spacing={2}>
                                <Grid item container alignItems={'flex-start'}>
                                    <Grid item sx={cssObject.listPoint}>
                                        1
                                    </Grid>
                                    <Grid item xs={10} sm={11}>
                                        <Paragraph fontSizeMediumSmall>
                                            The coil and fan inside the air conditioner work together to transfer the
                                            heat from the refrigerant outdoors.
                                        </Paragraph>
                                    </Grid>
                                </Grid>
                                <Grid item container alignItems={'flex-start'}>
                                    <Grid item sx={cssObject.listPoint}>
                                        2
                                    </Grid>
                                    <Grid item xs={10} sm={11}>
                                        <Paragraph fontSizeMediumSmall>
                                            The compressor acts as a pump that circulates the refrigerant throughout the
                                            entire HVAC system.
                                        </Paragraph>
                                    </Grid>
                                </Grid>
                                <Grid item container alignItems={'flex-start'}>
                                    <Grid item sx={cssObject.listPoint}>
                                        3
                                    </Grid>
                                    <Grid item xs={10} sm={11}>
                                        <Paragraph fontSizeMediumSmall>
                                            Two copper pipes carry refrigerant between your outdoor air conditioner and
                                            the indoor coil. You will typically see the larger of the two copper lines
                                            insulated with black foam.
                                        </Paragraph>
                                    </Grid>
                                </Grid>
                                <Grid item container alignItems={'flex-start'}>
                                    <Grid item sx={cssObject.listPoint}>
                                        4
                                    </Grid>
                                    <Grid item xs={10}>
                                        <Paragraph fontSizeMediumSmall>
                                            Blowers can be single-stage, two-stage, or variable speed to maximize
                                            comfort and energy savings. The more “speed settings” or stages your furnace
                                            or air handler has typically means a higher overall comfort level for your
                                            family and greater energy savings.
                                        </Paragraph>
                                    </Grid>
                                </Grid>
                                <Grid item container alignItems={'flex-start'}>
                                    <Grid item sx={cssObject.listPoint}>
                                        5
                                    </Grid>
                                    <Grid item xs={10}>
                                        <Paragraph fontSizeMediumSmall>
                                            The evaporator coil is an piece of equipment that adsorbs heat using
                                            refrigerant and then Cools your home. They can be individual pieces or
                                            internal in an air handler.
                                        </Paragraph>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <ContentContainer sx={cssObject.tooltipContainer} background={'wizardtip'}>
                        <Grid container>
                            <Grid item xs={12}>
                                <Grid container sx={cssObject.toolTipHeading}>
                                    <TooltipIdeaIcon sx={cssObject.tooltipIcon} />
                                    <Heading variant={'h3'} isPopUp={true} color={'primary'} label={'Pro Tip'} />
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                <Paragraph>
                                    SEER ratings of new systems we install start at 14 SEER and go up from there. The
                                    higher the SEER rating, the less energy the unit uses, which means you save money on
                                    your electricity bill.
                                </Paragraph>
                            </Grid>
                        </Grid>
                    </ContentContainer>
                    <VideoOverlay
                        open={openVdo}
                        onClose={handleCloseVdo}
                        heading={'Heat Pumps'}
                        src={
                            'https://res.cloudinary.com/motili/video/upload/v1594976189/ecomm/video/Goodman_HowItWorks_HeatPump_Clean_1080p.mp4'
                        }
                    />
                </Fragment>
            }
            content2={
                <ProTipAndAdditionalLinks
                    additionalLinks={{
                        headLine: 'Want to learn more?  Check out these links',
                        links: [
                            {
                                text: 'Heat Pump and Gas Furnace: What’s the Difference?',
                                url: 'https://www.goodmanmfg.com/resources/hvac-learning-center/hvac-101/whats-the-difference-between-a-heat-pump-and-gas-furnace',
                            },
                            {
                                text: 'What is SEER and Why Does It Matter?',
                                url: 'https://www.goodmanmfg.com/resources/hvac-learning-center/before-you-buy/what-is-seer-and-why-does-it-matter',
                            },
                            {
                                text: 'What is an Air Handler?',
                                url: 'https://www.goodmanmfg.com/resources/hvac-learning-center/hvac-101/what-is-an-air-handler',
                            },
                            {
                                text: 'What is a Two-Stage AC or Heap Pump?',
                                url: 'https://www.goodmanmfg.com/resources/hvac-learning-center/before-you-buy/what-is-a-two-stage-air-conditioner-or-heat-pump',
                            },
                            {
                                text: 'Goodman Customer Reviews',
                                url: 'https://www.goodmanmfg.com/resources/customer-reviews',
                            },
                        ],
                    }}
                />
            }
        />
    );
}

export default HeatPumps;
