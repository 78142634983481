import React from 'react';
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
import { AppRoute } from 'models/route';
import { Order, OrderedItems } from 'models/order';
import { selectOrder, selectOrderedItems } from 'store/selectors/order';
import Confirmation from './OrderConfirmation';

function OrderConfirmationContainer() {
    const order: Order = useSelector(selectOrder);
    const orderItems: OrderedItems = useSelector(selectOrderedItems);
    // validation state before render
    if (!order || !order.id) {
        return <Navigate to={AppRoute.Home} />;
    }

    return <Confirmation order={order} orderItems={orderItems} />;
}

export default OrderConfirmationContainer;
