import * as Yup from 'yup';
import { StringSchema } from 'yup';
import {
    SquareFootage,
    SplitOrPackage,
    NumberOfHVAC,
    HomeSystem,
    SplitReplacementType,
    PackageReplacementType,
    DuctlessNumberOfRooms,
    DuctlessFirstSquareFootage,
    DuctlessSecondSquareFootage,
    FurnaceEfficiency,
    PartialSystemType,
} from '../../models/productCriteria';
export const schemaAboutYouHome = Yup.object({
    numberOfHVAC: Yup.string().required('Hvac system is required to continue').oneOf(Object.values(NumberOfHVAC)),
    homeSystem: Yup.string().when('numberOfHVAC', {
        is: (numberOfHVAC: string, schema: StringSchema) => {
            return numberOfHVAC === NumberOfHVAC.Single || numberOfHVAC === NumberOfHVAC.Double;
        },
        then: (schema: StringSchema) =>
            schema
                .required('Select a Complete Or Partial Or Ductless before continuing')
                .oneOf(Object.values(HomeSystem)),
    }),
    //3
    splitOrPackage: Yup.string().when(['homeSystem', 'numberOfHVAC'], {
        is: (homeSystem: string, numberOfHVAC: string) => {
            return (
                homeSystem === HomeSystem.Complete &&
                (numberOfHVAC === NumberOfHVAC.Single || numberOfHVAC === NumberOfHVAC.Double)
            );
        },
        then: (schema: StringSchema) =>
            schema.required('Select a Split or Package before continuing').oneOf(Object.values(SplitOrPackage)),
    }),
    //3.1
    splitReplacementType: Yup.string().when(['splitOrPackage', 'homeSystem', 'numberOfHVAC'], {
        is: (splitOrPackage: string, homeSystem: string, numberOfHVAC: string) => {
            return (
                splitOrPackage === SplitOrPackage.Split &&
                homeSystem === HomeSystem.Complete &&
                (numberOfHVAC === NumberOfHVAC.Single || numberOfHVAC === NumberOfHVAC.Double)
            );
        },
        then: (schema: StringSchema) =>
            schema
                .required('Select a SplitReplacementType before continuing')
                .oneOf(Object.values(SplitReplacementType)),
    }),
    //3.1
    packageReplacementType: Yup.string().when(['splitOrPackage', 'homeSystem', 'numberOfHVAC'], {
        is: (splitOrPackage: string, homeSystem: string, numberOfHVAC: string) => {
            return (
                splitOrPackage === SplitOrPackage.Package &&
                homeSystem === HomeSystem.Complete &&
                (numberOfHVAC === NumberOfHVAC.Single || numberOfHVAC === NumberOfHVAC.Double)
            );
        },
        then: (schema: StringSchema) =>
            schema
                .required('Select a PackageTypeReplacement before continuing')
                .oneOf(Object.values(PackageReplacementType)),
    }),
    //3
    partialSystemType: Yup.string().when(['homeSystem', 'numberOfHVAC'], {
        is: (homeSystem: string, numberOfHVAC: string) => {
            return (
                homeSystem === HomeSystem.Partial &&
                (numberOfHVAC === NumberOfHVAC.Single || numberOfHVAC === NumberOfHVAC.Double)
            );
        },
        then: (schema: StringSchema) =>
            schema.required('Select a partialSystemType before continuing').oneOf(Object.values(PartialSystemType)),
    }),
    efficiency: Yup.string().when(['splitReplacementType', 'partialSystemType', 'numberOfHVAC', 'homeSystem'], {
        is: (splitReplacementType: string, partialSystemType: string, numberOfHVAC: string, homeSystem: string) => {
            return (
                (splitReplacementType === SplitReplacementType.FurnaceAC ||
                    partialSystemType === PartialSystemType.Furnace) &&
                (numberOfHVAC === NumberOfHVAC.Single || numberOfHVAC === NumberOfHVAC.Double) &&
                (homeSystem === HomeSystem.Partial || homeSystem === HomeSystem.Complete)
            );
        },
        then: (schema: StringSchema) =>
            schema.required('You must choose either 80% or 90%').oneOf(Object.values(FurnaceEfficiency)),
    }),
    squareFootage: Yup.string().when(['homeSystem', 'numberOfHVAC'], {
        is: (homeSystem: string, numberOfHVAC: string) => {
            return (
                (homeSystem === HomeSystem.Complete || homeSystem === HomeSystem.Partial) &&
                (numberOfHVAC === NumberOfHVAC.Single || numberOfHVAC === NumberOfHVAC.Double)
            );
        },
        then: (schema: StringSchema) =>
            schema.required('Select a SquareFootage before continuing').oneOf(Object.values(SquareFootage)),
    }),
    ductlessNumberOfRooms: Yup.string().when(['homeSystem', 'numberOfHVAC'], {
        is: (homeSystem: string, numberOfHVAC: string) => {
            return (
                homeSystem === HomeSystem.Ductless &&
                (numberOfHVAC === NumberOfHVAC.Single || numberOfHVAC === NumberOfHVAC.Double)
            );
        },
        then: (schema: StringSchema) =>
            schema.required('Select a Ductless Rooms before continuing').oneOf(Object.values(DuctlessNumberOfRooms)),
    }),

    ductlessFirstSquareFootage: Yup.string().when(['ductlessNumberOfRooms', 'homeSystem', 'numberOfHVAC'], {
        is: (ductlessNumberOfRooms: string, homeSystem: string, numberOfHVAC: string, schema: StringSchema) => {
            return (
                (ductlessNumberOfRooms === DuctlessNumberOfRooms.Single ||
                    ductlessNumberOfRooms === DuctlessNumberOfRooms.Double) &&
                homeSystem === HomeSystem.Ductless &&
                (numberOfHVAC === NumberOfHVAC.Single || numberOfHVAC === NumberOfHVAC.Double)
            );
        },
        then: (schema: StringSchema) =>
            schema
                .required('You must choose DuctlessFirstSquareFootage of Ductless Rooms')
                .oneOf(Object.values(DuctlessFirstSquareFootage)),
    }),
    ductlessSecondSquareFootage: Yup.string().when(['ductlessNumberOfRooms', 'homeSystem', 'numberOfHVAC'], {
        is: (ductlessNumberOfRooms: string, homeSystem: string, numberOfHVAC: string, schema: StringSchema) => {
            return (
                ductlessNumberOfRooms === DuctlessNumberOfRooms.Double &&
                homeSystem === HomeSystem.Ductless &&
                (numberOfHVAC === NumberOfHVAC.Single || numberOfHVAC === NumberOfHVAC.Double)
            );
        },
        then: (schema: StringSchema) =>
            schema
                .required('You must choose DuctlessSecondSquareFootage of Ductless Rooms')
                .oneOf(Object.values(DuctlessSecondSquareFootage)),
    }),
});

export const schemaSchedule = Yup.object({
    schedule: Yup.array().of(
        Yup.object().shape({
            schedule: Yup.string().required(),
            preferredDate: Yup.date().required(),
            preferredTimeSlot: Yup.string().required(),
        })
    ),
});

export const contactUsSchema = Yup.object({
    firstname: Yup.string().required('Please enter your first name'),
    lastname: Yup.string().required('Please enter your last name'),
    email: Yup.string().email('Invalid email address').required('Please enter your email'),
    reasonForContact: Yup.string().required('Please enter your reason for contact'),
    contactMessage: Yup.string().when('reasonForContact', (reasonForContact: string, schema: StringSchema) => {
        if (reasonForContact === 'OTHER') {
            return schema.required('select OTHER option');
        }
        return schema.notRequired();
    }),
});

export const contractorModalSchema = Yup.object().shape({
    contractorInfo: Yup.string().required('Please enter contact details'),
});

export const updateUserProfileSchema = Yup.object({
    firstname: Yup.string().required('Please enter your first name'),
    lastname: Yup.string().required('Please enter your last name'),
    //email: Yup.string().email('Invalid email address').required('Please enter your email'),
    defaultBillingFirstname: Yup.string().required('Please enter your billing address firstt name'),
    defaultBillingLastname: Yup.string().required('Please enter your billing address last name'),
    addressName: Yup.string().required('Please enter your address name'),
    address1: Yup.string().required('Please enter your address line 1'),
    address2: Yup.string(),
    city: Yup.string().required('Please enter your city'),
    postcode: Yup.string()
        .length(5, 'Only 5 Characters')
        .matches(/^[0-9]{5}$/, 'Invalid Zip Code Characters')
        .required('Required'),
    //region: Yup.string().required('Please enter your region'),
});

export const AddInstructionsValidationSchema = Yup.object().shape({
    addInstructions: Yup.string().required('Add note is required'),
});
