import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { resetProductCriteria } from '../store/actions/productCriteriaActions';
import Grid from '@mui/material/Grid';
import { Theme } from '@mui/material/styles';
import { BackButton, CloseButton, RestartButton, HelpButton } from './WizardButton';
import ContentContainer from './ContentContainer';
import { Logo, WizardLogoNewWithLink } from './Logo';
import { AppRoute } from '../models/route';
import { resetGAProduct } from '../store/actions/gaEcommerceActions';
import { TransitionProps } from '@mui/material/transitions';
import Slide from '@mui/material/Slide';
import Dialog from '@mui/material/Dialog';
import { Button } from './Button';
import { Paragraph } from './Paragraph';
import { resetProductSearch } from '../store/actions/productSearchActions';
import { resetSelection } from 'store/actions/cartActions';
import { Box, useMediaQuery, useTheme } from '@mui/material';
import { Heading } from './Heading';
import { SXCssObject } from 'models/ThemeImages';
// import DaikinLogo from './icons/DaikinLogo';
import MotiliLogo from '../assets/images/Motili_Logo_Blue_Black.png'

const useStyles = (theme: Theme, props: Props): SXCssObject => ({
    root: {
        width: '100%',
        height: '80px',
        padding: {
            xs: '20px 15px 20px 15px',
            sm: '20px 35px 20px 35px',
            md: '20px 60px 20px 60px',
        },
        position: props.isFixed ? 'fixed' : 'unset',
        boxShadow: props.shadow ? '0px 40px 40px 0px #F5F5F5' : '0px',
        zIndex: 5,
        display: 'flex',
        alignItems: 'center',
        backgroundColor: theme.palette.common.white,
    },
    dialog: {
        height: '100%',
    },
    dialogContainer: {
        minHeight: '100vh',
        width: '100%',
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(3),
    },
    header: {
        height: '80px',
    },
});

interface Props {
    color?: 'primary' | 'secondary';
    showBackButton?: true | false;
    showRestartButton?: true | false;
    disableGrid?: true | false;
    isFixed?: true | false;
    shadow?: true | false;
    onHelp?: () => void;
    onBack?: () => void;
    isForSelectionPage?: boolean;
    productCriteria?: () => void;
    showExitButton?: true | false;
    showDaikinLogo?: boolean;
}

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function WizardHeader(props: Props) {
    const classes: SXCssObject = useStyles(useTheme(), props);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { showBackButton = false, showRestartButton = true, showExitButton = true, showDaikinLogo = false } = props;
    const componentId = 'wizard-header';
    const [confirm, setConfirm] = React.useState(false);
    const [action, setAction] = React.useState('');
    const theme = useTheme();
    const isDownSm = useMediaQuery(theme.breakpoints.down('md'));
    const isUpMd = useMediaQuery(theme.breakpoints.up('md'));
    const isOnlySm = useMediaQuery(theme.breakpoints.only('sm'));

    const [confirmMsg, setConfirmMsg] = React.useState('');
    const exitPopUp = () => {
        setAction('close');
        setConfirmMsg('Are you sure you want to exit?');
        setConfirm(true);
    };
    const handleContactUsWindow = () => {
        window.open('/contact-us', '_blank');
    };
    return (
        <nav
            style={{
                width: '100%',
                height: '80px',
                padding: isUpMd ? '20px 60px 20px 60px' : isOnlySm ? '20px 35px 20px 35px' : '20px 15px 20px 15px',
                position: props.isFixed ? 'fixed' : 'unset',
                boxShadow: props.shadow ? '0px 40px 40px 0px #F5F5F5' : '0px',
                zIndex: 5,
                display: 'flex',
                alignItems: 'center',
                backgroundColor: theme.palette.common.white,
            }}
        >
            <Grid container item spacing={2} alignItems={'center'} sx={classes.header}>
                <Grid item xs={4} sm={3} md={4} sx={{ display: 'flex' }}>
                    {/* Show Daikin Logo for Friends and family page alone  */}
                    {showDaikinLogo ? (
                        <Box>
                            {/* <DaikinLogo /> */}
                            <img src={MotiliLogo} height={'48px'} />
                        </Box>
                    ) : (
                        <>
                            {isDownSm ? (
                                <Logo id={'nav-logo'} onClick={exitPopUp} />
                            ) : (
                                <WizardLogoNewWithLink id={'nav-home'} onClick={exitPopUp} />
                            )}
                        </>
                    )}
                </Grid>
                <Grid item xs={8} sm={9} md={8}>
                    <Grid container justifyContent={'flex-end'} alignContent={'center'} spacing={2}>
                        {!!props.onHelp && (
                            <Grid item>
                                <HelpButton id={`${componentId}-back-btn`} onClick={handleContactUsWindow} />
                            </Grid>
                        )}
                        {showBackButton && !!props.onBack && (
                            <Grid item>
                                <BackButton id={`${componentId}-back-btn`} onClick={props.onBack} />
                            </Grid>
                        )}
                        {showRestartButton && (
                            <Grid item>
                                <RestartButton
                                    id={`${componentId}-restart-btn`}
                                    onClick={() => {
                                        setAction('restart');
                                        setConfirmMsg('Are you sure you want to restart?');
                                        setConfirm(true);
                                    }}
                                />
                            </Grid>
                        )}
                        {showExitButton && (
                            <Grid item>
                                <CloseButton id={`${componentId}-close-btn`} onClick={exitPopUp} />
                            </Grid>
                        )}
                    </Grid>
                </Grid>
            </Grid>
            <Dialog
                fullScreen
                open={confirm}
                onClose={() => {
                    setConfirm(false);
                }}
                TransitionComponent={Transition}
            >
                <ContentContainer disableGrid background={'bg2'} sx={classes.dialog}>
                    <Grid
                        container
                        direction={'column'}
                        alignItems={'center'}
                        justifyContent={'center'}
                        spacing={2}
                        sx={classes.dialogContainer}
                    >
                        <Grid item xs={12}>
                            <Heading variant={'h4'} align={'center'} label={confirmMsg} />
                            <br />
                            <Paragraph align={'center'}>Your progress will be lost.</Paragraph>
                        </Grid>
                        <Grid item xs={12} container spacing={2} justifyContent={'center'}>
                            <Grid item>
                                <Button
                                    id={'confirm-yes-btn'}
                                    isContrast
                                    onClick={() => {
                                        if (action === 'restart') {
                                            dispatch(resetSelection());
                                            dispatch(resetProductCriteria());
                                            dispatch(resetGAProduct());
                                            //dispatch(resetOrder());
                                            if (props.productCriteria) {
                                                props.productCriteria();
                                            }
                                            setConfirm(false);
                                            navigate(AppRoute.AboutYourHome);
                                        } else if (action === 'close') {
                                            dispatch(resetSelection());
                                            dispatch(resetProductCriteria());
                                            dispatch(resetGAProduct());
                                            dispatch(resetProductSearch());
                                            //dispatch(resetOrder());
                                            navigate(AppRoute.Home);
                                        }
                                    }}
                                >
                                    Yes
                                </Button>
                            </Grid>
                            <Grid item>
                                <Button
                                    id={'confirm-no-btn'}
                                    color={'primary'}
                                    onClick={() => {
                                        setConfirm(false);
                                    }}
                                >
                                    No
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </ContentContainer>
            </Dialog>
        </nav>
    );
}
