import { useTheme } from '@mui/material/styles';
import { getThemePrimaryMain } from 'components/common/Utilities';
import * as React from 'react';

const MissionIcon = (props: any) => {
    const theme = useTheme();
    const themePrimaryMain = getThemePrimaryMain(theme);
    return (
        <svg
            data-name="Component 49 \u2013 1"
            xmlns="http://www.w3.org/2000/svg"
            width={'100%'}
            height={'100%'}
            viewBox="0 0 161 161"
            {...props}
        >
            <rect data-name="Rectangle 3827" width={161} height={161} rx={7} fill={`${themePrimaryMain}`} />
            <g data-name="Group 3425">
                <g data-name="Group 3424">
                    <g data-name="Group 3423" fill="none" stroke="#fff" strokeLinecap="round" strokeWidth={4}>
                        <path data-name="Path 268" d="M115.957 77.377a39.139 39.139 0 1 1-27.988-27.508" />
                        <path data-name="Path 269" d="M103.29 79.949a26.283 26.283 0 1 1-15.633-16.678" />
                        <path
                            data-name="Path 270"
                            d="M90.827 86.718q.047.544.047 1.1a12.693 12.693 0 1 1-12.692-12.693 12.83 12.83 0 0 1 1.877.138"
                        />
                        <path
                            data-name="Path 272"
                            d="M77.669 87.818 96.78 69.147l18.582-18.155a1.755 1.755 0 0 0 .529-1.256v-7.978a1.755 1.755 0 0 0-2.969-1.267L97.648 55.128a1.755 1.755 0 0 0-.54 1.217l-.328 11.5"
                        />
                        <path
                            data-name="Path 273"
                            d="m115.893 50.476 7.186 1.572a2.439 2.439 0 0 1 1.129 4.18l-13.832 12.7a2.44 2.44 0 0 1-1.737.641l-11.857-.424"
                        />
                    </g>
                </g>
            </g>
        </svg>
    );
};
export default MissionIcon;
