import { axiosRequestConfigForPdfDownload } from 'components/common/Utilities';
import http, { getAsyncAwaitHttpObj } from '../../index';
import { GenerateQuoteReq, Quote } from '../../models/quotes';

export const getMyQuotes = () => http().get<Quote[]>(`/quotations`);
export const deleteQuoteById = (id: number) => getAsyncAwaitHttpObj().delete<{}>(`/quotations/${id}`);

export const downloadQuoteById = (id: number) =>
    http().get(`/quotations/${id}/download`, axiosRequestConfigForPdfDownload as any);

export const generateQuote = (data: GenerateQuoteReq) =>
    http().post(`/quotations`, { ...data }, axiosRequestConfigForPdfDownload as any);

export const regenerateQuote = (id: number) =>
    http().post(`/quotations/${id}/regenerate`, {}, axiosRequestConfigForPdfDownload);
export const moveQuoteToCart = (id: number) => http().post(`/quotations/${id}/moveToCart`);
