import { styled } from '@mui/material/styles';
import ToggleButton from '@mui/material/ToggleButton';

const WizardInputLogin = styled(ToggleButton)(({ theme }) => ({
    width: '50%',
    backgroundColor: '#E9E9E9',
    color: theme.palette.text.primary,
    minWidth: theme.spacing(6),
    borderRadius: theme.shape.borderRadius,
    border: '0px',
    '&.MuiToggleButton-root.Mui-selected': {
        color: theme.palette.common.white,
        backgroundColor: theme.palette.action.hover,
        zIndex: 2,
        '&:hover': {
            backgroundColor: theme.palette.action.hover,
        },
    },
}));

export default WizardInputLogin;
