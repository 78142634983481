import { Box, Container, Grid, Snackbar, Theme } from '@mui/material';
import { Close } from '@mui/icons-material';
import React, { useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppRoute } from 'models/route';
import { hideNotification } from 'store/actions/notificationAction';
import { selectCart } from 'store/selectors/cart';
import { selectCartNotification, selectPendingOrderNotification } from 'store/selectors/notification';
import ContentContainer from './ContentContainer';
import OtpErrorIcon from './icons/OtpErrorIcon';
import { Paragraph } from './Paragraph';
import { getCartStatusText } from 'pages/cart/cart-utils';
import { OrderStatus } from 'models/order';
import { selectOrder } from 'store/selectors/order';
import { CommonLinkInline } from './Link';
import { useTheme } from '@mui/material/styles';
import { SXCssObject } from 'models/ThemeImages';
import { useNavigate } from 'react-router-dom';

const createCssObject = (theme: Theme): SXCssObject => ({
    viewCartText: {
        color: theme.palette.primary.main,
        fontWeight: theme.typography.fontWeightMedium,
        cursor: 'pointer',
        whiteSpace: 'nowrap',
    },
    notificationContainer: {
        background: '#FFFFFF 0% 0% no-repeat padding-box',
        boxShadow: '0px 7px 20px #4D4E5729',
        border: '1px solid #E9E9E9',
        padding: '15px',
        display: 'flex',
        justifyContent: 'space-between',
        borderRadius: {
            xs: '30px',
            sm: '40px',
        },
    },
    closeButton: {
        display: 'flex',
        cursor: 'pointer',
        justifyContent: 'flex-end',
        alignItems: 'center',
    },
    b: {
        fontWeight: theme.typography.fontWeightMedium,
    },
    otpErrorIcon: {
        display: 'flex',
        alignItems: 'center',
    },
});

interface Props {
    isFixed?: boolean;
}

export default function WizardNotification(props: Props) {
    const cssObject: SXCssObject = createCssObject(useTheme());
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const cartNotification = useSelector(selectCartNotification);
    const orderNotification = useSelector(selectPendingOrderNotification);
    const order = useSelector(selectOrder);
    const theme = useTheme();

    const cart = useSelector(selectCart);
    let mounted: any = useRef();
    React.useEffect(() => {
        if (!mounted.current) {
            mounted.current = true;
            if (
                (!cart || !cart.items || cart.items.length <= 0) &&
                !(order.status && order.status === OrderStatus.PENDING_PAYMENT)
            ) {
                dispatch(hideNotification());
            }
        }
    });
    const handleClick = () => {
        dispatch(hideNotification());
    };

    const orderContent = () => {
        return (
            <Paragraph size={'small'}>
                Only one order can be open at a time. Please{' '}
                <CommonLinkInline
                    fontColor={theme.palette.primary.main}
                    href={AppRoute.Billing}
                    label={'complete your current order'}
                />{' '}
                before ordering for a new project.
            </Paragraph>
        );
    };
    const cartContent = () => {
        return (
            <Paragraph size={'small'}>
                There is a selection of HVAC Systems for your{' '}
                <Box component={'b'} sx={cssObject.b}>
                    {cart.shippingAddress?.addressName}
                </Box>{' '}
                in the cart{' '}
                <Box component={'b'} sx={cssObject.b}>
                    {getCartStatusText(cart.status)}
                </Box>{' '}
                <Box
                    component={'span'}
                    sx={cssObject.viewCartText}
                    onClick={() => {
                        navigate(AppRoute.CartPage);
                    }}
                >
                    View cart
                </Box>
            </Paragraph>
        );
    };
    return (
        <React.Fragment>
            <Snackbar
                sx={{
                    '.MuiSnackbar-root': {
                        position: {
                            xs: 'unset',
                            md: props.isFixed ? 'fixed' : 'unset',
                        },
                        bottom: {
                            md: '90px',
                        },
                        zIndex: 0,
                    },
                }}
                open={cartNotification || orderNotification}
                autoHideDuration={null}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
            >
                <Container disableGutters>
                    <ContentContainer>
                        <Grid container sx={cssObject.notificationContainer} xs={12} md={8}>
                            <Grid item xs={1} sx={cssObject.otpErrorIcon}>
                                <OtpErrorIcon />
                            </Grid>
                            <Grid item xs={10} sm={9}>
                                {cartNotification && cartContent()}
                                {orderNotification && orderContent()}
                            </Grid>
                            <Grid item sx={cssObject.closeButton} xs={1} sm={2}>
                                <Close onClick={handleClick} />
                            </Grid>
                        </Grid>
                    </ContentContainer>
                </Container>
            </Snackbar>
        </React.Fragment>
    );
}
