import React, { useEffect } from 'react';
import Router from './router';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';
import { theme } from './themes/theme';
import { SnackbarAlertProvider } from 'components/common/Snackbar/SnackbarAlert';
import WebFont from 'webfontloader';

const App: React.FC = () => {
    useEffect(() => {
        WebFont.load({
            google: {
                families: [
                    'Roboto:300,400,500,600',
                    'Poppins:400,700',
                    'Carrois Gothic:300,400,500,600',
                    'IBM Plex Sans:400,700',
                ],
            },
        });
    }, []);
    return (
        <ThemeProvider theme={theme}>
            <CssBaseline />
            <SnackbarAlertProvider>
                <Router />
            </SnackbarAlertProvider>
        </ThemeProvider>
    );
};
export default App;
