import { Components } from '@mui/material';

export const customPropertiesObj = {
    primaryErrorBorder: '#F97F7F',
    border: '#D4D4D4',
    darkGrey: '#707070',
    lightGrey: '#e4e4e6',
    transparent: 'transparent',
    knowledgeCenterImageBackground: '#FAFAFA',
    listPoint: '#78B14F',
    commonGreenDark: '#4ECE64',
    commonOrange: '#DC5226',
    borderDivider: '#707070',
};
export const muiComponentTheme: Components = {
    MuiDialog: {
        defaultProps: {
            disableScrollLock: true,
        },
    },
    MuiSvgIcon: {
        styleOverrides: {
            root: {
                fontSize: '24px',
            },
        },
    },
};
