import ReactGA from 'react-ga4';
import { GAEvents } from '../../models/googleTracking';

export function sendGAEvent(eventName: string, params?: any) {
    if (params) {
        ReactGA.event(eventName, params);
    } else {
        ReactGA.event(eventName);
    }
}

export const invokeGAForLogout = () => {
    sendGAEvent(GAEvents.Logout);
};
export const invokeGAForAddonInc = (sku: string) => {
    sendGAEvent(GAEvents.AddToCart, { item_id: sku, quantity: 1 });
};
export const invokeGAForAddonDec = (sku: string) => {
    sendGAEvent(GAEvents.RemoveFromCart, { item_id: sku, quantity: 1 });
};
export const invokeGAForPayNowonProfile = () => {
    sendGAEvent(GAEvents.PayNowClickedProfileMenu);
};
export const invokeGAContactLoggedIn = () => {
    sendGAEvent(GAEvents.ContactPageSubmitLoggedIn);
};
export const invokeGAContactNotLoggedIn = () => {
    sendGAEvent(GAEvents.ContactPageSubmitNotLoggedIn);
};
export const invokeGAContactPageSignUp = () => {
    sendGAEvent(GAEvents.ContactPageSignUp);
};
export const invokeGACartGenerateQuote = () => {
    sendGAEvent(GAEvents.CartGenerateQuote);
};
export const invokeGASelectionGenerateQuote = () => {
    sendGAEvent(GAEvents.SelectionGenerateQuote);
};
export const invokeGAEditFirstSystem = () => {
    sendGAEvent(GAEvents.EditFirstSystem);
};
export const invokeGARegenerateQuote = () => {
    sendGAEvent(GAEvents.ProfileReGenerateQuote);
};
export const invokeGADownloadQuote = () => {
    sendGAEvent(GAEvents.ProfileDownloadQuote);
};
export const invokeGADeleteQuote = () => {
    sendGAEvent(GAEvents.ProfileDeleteQuote);
};
export const invokeGAMoveQuoteToCart = () => {
    sendGAEvent(GAEvents.ProfileMoveQuoteToCart);
};
export const invokeGASystemGroupDeletePopup = () => {
    sendGAEvent(GAEvents.SystemGroupDeletePopup);
};
export const invokeGAItemDeletePopup = () => {
    sendGAEvent(GAEvents.ItemDeletePopup);
};
export const invokeGACartDeleteIconClicked = () => {
    sendGAEvent(GAEvents.CartDeleteIconClicked);
};
export const invokeGAAddAnotherSystemFromCart = () => {
    sendGAEvent(GAEvents.AddAnotherSystemFromCart);
};
export const invokeGACartChangeClicked = () => {
    sendGAEvent(GAEvents.CartChangeClicked);
};
export const invokeGAContinueRecommendationsPage = () => {
    sendGAEvent(GAEvents.ContinueRecommendationsPage);
};
export const invokeGAShopIAQRecommendationsPage = () => {
    sendGAEvent(GAEvents.ShopIAQRecommendationsPage);
};
export const invokeGACartIAQRemoved = () => {
    sendGAEvent(GAEvents.CartIAQRemoved);
};
export const invokeGAPayNowClickedMyOrdersPage = () => {
    sendGAEvent(GAEvents.PayNowClickedMyOrdersPage);
};
export const invokeGAHomeAddressCatpure = () => {
    sendGAEvent(GAEvents.HomeAddressCatpure);
};
export const invokeGAHomeAddressUpdate = () => {
    sendGAEvent(GAEvents.HomeAddressUpdate);
};
export const invokeGABillingAddressCatpure = () => {
    sendGAEvent(GAEvents.BillingAddressCatpure);
};
export const invokeGABillingAddressUpdate = () => {
    sendGAEvent(GAEvents.BillingAddressUpdate);
};
export const invokeGAProfilePropertyAdded = () => {
    sendGAEvent(GAEvents.ProfilePropertyAdded);
};
export const invokeGAProfilePropertyUpdated = () => {
    sendGAEvent(GAEvents.ProfilePropertyUpdated);
};
export const invokeGAProfilePropertyDeleted = () => {
    sendGAEvent(GAEvents.ProfilePropertyDeleted);
};
export const invokeGACartPageAddNewHomeAddressDialog = () => {
    sendGAEvent(GAEvents.CartPageAddNewHomeAddressDialog);
};
export const invokeGACartDeleted = () => {
    sendGAEvent(GAEvents.CartDeleted);
};
export const invokeGAItemDeleted = () => {
    sendGAEvent(GAEvents.ItemDeleted);
};
export const invokeGASystemDeleted = () => {
    sendGAEvent( GAEvents.SystemDeleted);
};
export const invokeGAPromocodeApplied = () => {
    sendGAEvent(GAEvents.PromocodeApplied);
};
export const invokeGAWizardPageLogin = () => {
    sendGAEvent(GAEvents.WizardPageLogin);
};
export const invokeGAWizardPageSignUp = () => {
    sendGAEvent(GAEvents.WizardPageSignUp);
};
export const invokeGAHomePageLogin = () => {
    sendGAEvent(GAEvents.HomePageLogin);
};
export const invokeGAHomePageSignUp = () => {
    sendGAEvent(GAEvents.HomePageSignUp);
};
export const invokeGACheckoutPageVerification = () => {
    sendGAEvent( GAEvents.CheckoutPageVerification);
};
export const invokeGAAddProfileDetails = () => {
    sendGAEvent(GAEvents.AddProfileDetails);
};
export const invokeGAUpdateProfile = () => {
    sendGAEvent(GAEvents.UpdateProfile);
};
export const invokeGAPromocodeRemoved = () => {
    sendGAEvent(GAEvents.PromocodeRemoved);
};
export const invokeGACreditCardComplete = () => {
    sendGAEvent(GAEvents.CreditCardComplete);
};
export const invokeGACreditCardPartial = () => {
    sendGAEvent(GAEvents.CreditCardPartial);
};
export const invokeDaikinFriendsAndFamilyFormSubmission = () => {
    sendGAEvent(GAEvents.DaikinFriendsAndFamilyFormSubmitted);
}
