import { combineEpics, Epic } from 'redux-observable';
import { Action } from 'ts-action';
import { ofType } from 'ts-action-operators';
import { Observable, of } from 'rxjs';
import { switchMap, catchError, tap, ignoreElements, map, withLatestFrom } from 'rxjs/operators';
import { selectMultipleOpenProjects } from '../selectors/storeSelector';

import {
    getOrder as getOrderService,
    getMyOrders as getMyOrdersService,
    placeFinalOrder as placeFinalOrderService,
    latestOrder,
    placeFinalOrderEquipmentOnly as placeFinalOrderEquipmentOnlyService,
} from '../services/orderServices';
import { RootState } from '../reducers';
import { Dependencies } from '../index';
import {
    getMyOrder,
    getMyOrders,
    getMyOrdersError,
    getMyOrdersSuccess,
    getOrder,
    getOrderandReset,
    getOrderError,
    getOrderSuccess,
    getOrderSuccessAndRedirectToBilling,
    getOrderSuccessDoNotRedirect,
    myOrderAction,
    myOrderError,
    myOrderSuccess,
    placeFinalOrder,
    placeFinalOrderEquipmentOnly,
} from '../actions/orderActions';
import { AppRoute } from '../../models/route';
import { placeOrderError, placeOrderSuccess, resetCart, resetSelection } from '../actions/cartActions';
import { resetGAProduct } from '../actions/gaEcommerceActions';
import { resetProductSearch } from '../actions/productSearchActions';
import { resetProductCriteria } from '../actions/productCriteriaActions';
import { showSnackbarError } from 'components/common/Snackbar/SnackbarHelper';
import { sendGAPurchase } from 'components/Analytics/GoogleAnalyticsEcommerce';
import { selectOrder } from 'store/selectors/order';
import { AxiosResponse } from 'axios';
import { isFromRoute } from 'components/common/Utilities';
import { Order } from 'models/order';

export const getOrder$ = (action$: Observable<Action>, state$: Observable<RootState>, { alert }: Dependencies) =>
    action$.pipe(
        ofType(getOrder),
        withLatestFrom(state$.pipe(map(selectMultipleOpenProjects))),
        switchMap(([action, multipleProjects]) =>
            getOrderService(action.payload.id).pipe(
                switchMap((resp: AxiosResponse<Order>) => {
                    try {
                        if (action.payload.callGAPurchase && action.payload.paymentMethod) {
                            sendGAPurchase(resp.data, action.payload.cartId!, action.payload.paymentMethod, action?.payload?.finalPayment, action?.payload?.hvFinalOrder);
                        }
                    } catch (err) {}
                    return [
                        action.payload.redirectToPayment
                            ? getOrderSuccessAndRedirectToBilling(resp.data)
                            : action.payload.doNotRedirect
                            ? getOrderSuccessDoNotRedirect(resp.data)
                            : getOrderSuccess(resp.data),
                        // ...(!multipleProjects ? [resetCart()] : []),
                        resetCart(),
                        resetGAProduct(),
                        resetProductSearch(),
                        resetProductCriteria(),
                        resetSelection(),
                    ];
                }),
                catchError((error) => {
                    showSnackbarError('Unable get an order');
                    return of(getOrderError(error));
                })
            )
        )
    );
export const getOrderandReset$ = (action$: Observable<Action>) =>
    action$.pipe(
        ofType(getOrderandReset),
        switchMap((action) =>
            getOrderService(action.payload.id).pipe(
                switchMap((resp: AxiosResponse<Order>) => {
                    return [
                        getOrderSuccess(resp.data),
                        resetCart(),
                        resetGAProduct(),
                        resetProductSearch(),
                        resetProductCriteria(),
                        resetSelection(),
                    ];
                }),
                catchError((error) => {
                    showSnackbarError('Unable get an order');
                    return of(getOrderError(error));
                })
            )
        )
    );
export const getMyOrder$ = (action$: Observable<Action>) =>
    action$.pipe(
        ofType(getMyOrder),
        switchMap((action) =>
            getOrderService(action.payload.id).pipe(
                switchMap((resp: AxiosResponse<Order>) => [getOrderSuccess(resp.data)]),
                catchError((error) => {
                    showSnackbarError('Unable get an order');
                    return of(getOrderError(error));
                })
            )
        )
    );

export const navigateToConfirmation$ = (
    action$: Observable<Action>,
    state$: Observable<RootState>,
    { history }: Dependencies
) =>
    action$.pipe(
        ofType(getOrderSuccess),
        tap(() => !isFromRoute(AppRoute.Confirmation) && history.push(AppRoute.Confirmation)),
        ignoreElements()
    );
export const navigateToMyPaymentPage$ = (
    action$: Observable<Action>,
    state$: Observable<RootState>,
    { history }: Dependencies
) =>
    action$.pipe(
        ofType(getOrderSuccessAndRedirectToBilling),
        tap(() =>
            history.push(AppRoute.Billing, {
                isFromOrderPage: true,
            })
        ),
        ignoreElements()
    );
export const getMyOrders$: Epic = (
    action$: Observable<Action>,
    state$: Observable<RootState>,
    { alert }: Dependencies
) =>
    action$.pipe(
        ofType(getMyOrders),
        switchMap(() =>
            getMyOrdersService().pipe(
                map((resp: AxiosResponse<Order[]>) => getMyOrdersSuccess(resp.data)),
                catchError((error) => {
                    showSnackbarError('Unable get orders');
                    return of(getMyOrdersError(error));
                })
            )
        )
    );
export const myLatestOrder$ = (action$: Observable<Action>) =>
    action$.pipe(
        ofType(myOrderAction),
        switchMap((action: any) =>
            latestOrder().pipe(
                map((resp: AxiosResponse<Order>) => myOrderSuccess({ order: resp.data, route: action.payload.route })),
                catchError((error) => {
                    showSnackbarError('Unable to get latest order');
                    return of(myOrderError(error));
                })
            )
        )
    );

export const placeFinalOrder$ = (action$: Observable<Action>, state$: Observable<RootState>, { alert }: Dependencies) =>
    action$.pipe(
        ofType(placeFinalOrder),
        withLatestFrom(state$.pipe(map(selectOrder))),
        switchMap(([action, order]) => {
            return placeFinalOrderService(action.payload.orderId as string, action.payload.paymentMethod).pipe(
                switchMap((resp) => {
                    return [
                        placeOrderSuccess(resp.data),
                        getOrder({
                            id: resp.data.id,
                            cartId: order.cartId || `${order.id}`,
                            paymentMethod: action.payload.paymentMethod,
                            callGAPurchase: true,
                            finalPayment: true,
                            hvFinalOrder : action.payload?.hvFinalOrder
                        }),
                    ];
                }),
                catchError((error) => {
                    let msg = 'Unable to place an order';
                    try {
                        if (error.response.data.error.message) {
                            msg = error.response.data.error.message;
                        }
                    } catch (e) {}
                    showSnackbarError(msg);
                    return of(placeOrderError(error));
                })
            );
        })
    );

    export const placeFinalOrderEquipmentOnly$ = (action$: Observable<Action>, state$: Observable<RootState>, { alert }: Dependencies) =>
    action$.pipe(
        ofType(placeFinalOrderEquipmentOnly),
        withLatestFrom(state$.pipe(map(selectOrder))),
        switchMap(([action, order]) => {
            return placeFinalOrderEquipmentOnlyService(action.payload.orderId as string, action.payload.paymentMethod).pipe(
                switchMap((resp) => {
                    return [
                        placeOrderSuccess(resp.data),
                        getOrder({
                            id: resp.data.id,
                            cartId: order.cartId || `${order.id}`,
                            paymentMethod: action.payload.paymentMethod,
                            callGAPurchase: true,
                            finalPayment: true,
                            hvFinalOrder : action.payload?.hvFinalOrder
                        }),
                    ];
                }),
                catchError((error) => {
                    let msg = 'Unable to place an order';
                    try {
                        if (error.response.data.error.message) {
                            msg = error.response.data.error.message;
                        }
                    } catch (e) {}
                    showSnackbarError(msg);
                    return of(placeOrderError(error));
                })
            );
        })
    );

export default combineEpics(
    getOrder$,
    navigateToConfirmation$,
    getMyOrders$,
    placeFinalOrder$,
    placeFinalOrderEquipmentOnly$,
    navigateToMyPaymentPage$,
    myLatestOrder$,
    getMyOrder$,
    getOrderandReset$
);
