import React, { Fragment } from 'react';
import { Theme, useTheme } from '@mui/material/styles';
import StaticSubPage from 'components/subpage/StaticSubPage';
import Grid from '@mui/material/Grid';
import { Heading } from 'components/Heading';
import { Paragraph } from 'components/Paragraph';
import SatisfactionImg1 from 'components/icons/SatisfactionImg1';
import SatisfactionImg2 from 'components/icons/SatisfactionImg2';
import SatisfactionImg3 from 'components/icons/SatisfactionImg3';
import SatisfactionImg4 from 'components/icons/SatisfactionImg4';
import { SXCssObject } from 'models/ThemeImages';

function SatisfactionPledge() {
    const createCssObject = (theme: Theme): SXCssObject => {
        return {
            contentHeading: {
                paddingBottom: theme.spacing(1),
            },
            contentSecondaryHeading: {
                marginTop: theme.spacing(5),
                display: 'flex',
                alignItems: 'center',
            },
            content: {
                paddingTop: theme.spacing(2),
            },
            locationName: { paddingLeft: '20px' },
            iconBox: {
                background: '#FFFFFF 0% 0% no-repeat padding-box',
                boxShadow: '0px 6px 21px #0000000D',
                borderRadius: '8px',
                display: 'flex',
                justifyContent: 'center',
                maxHeight: {
                    xs: '44px',
                    sm: '66px',
                },
                maxWidth: {
                    xs: '44px',
                    sm: '66px !important',
                },
            },
            pledgeText: {
                paddingLeft: '20px',
            },
        };
    };
    const cssObject: SXCssObject = createCssObject(useTheme());
    return (
        <StaticSubPage
            content1={
                <Fragment>
                    <Grid item xs={12} sx={cssObject.contentHeading}>
                        <Heading variant={'h1'} color={'primary'} label={'100% Satisfaction Pledge'} />
                    </Grid>
                    <Grid item xs={12} sx={cssObject.content}>
                        <Paragraph align={'justify'} size={'normal'}>
                            Our pledge to you is that you will be 100% satisfied with the installation and operation of
                            your new HVAC system or we’ll make it right!
                        </Paragraph>
                    </Grid>
                    {/* Service Area Begin */}
                    <Grid item xs={12} sx={cssObject.contentSecondaryHeading}>
                        <Heading
                            variant={'h4'}
                            isPopUp
                            color={'primary'}
                            label={'We’re able to make that pledge because'}
                        />
                    </Grid>
                    <Grid item xs={12} sx={cssObject.content} container alignItems={'center'}>
                        <Grid item xs={2} sx={cssObject.iconBox}>
                            <SatisfactionImg1 />
                        </Grid>
                        <Grid item xs={10} sx={cssObject.pledgeText}>
                            <Paragraph align={'justify'} size={'normal'}>
                                First we take the guesswork and hassle out of selecting and scheduling your new HVAC
                                system!
                            </Paragraph>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} sx={cssObject.content} container alignItems={'center'}>
                        <Grid item xs={2} sx={cssObject.iconBox}>
                            <SatisfactionImg2 />
                        </Grid>
                        <Grid item xs={10} sx={cssObject.pledgeText}>
                            <Paragraph align={'justify'} size={'normal'}>
                                Next, we back that up with some of the best warranties, local HVAC contractors, and
                                products in the industry!
                            </Paragraph>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} sx={cssObject.content} container alignItems={'center'}>
                        <Grid item xs={2} sx={cssObject.iconBox}>
                            <SatisfactionImg3 />
                        </Grid>
                        <Grid item xs={10} sx={cssObject.pledgeText}>
                            <Paragraph align={'justify'} size={'normal'}>
                                And finally, Homeowners really appreciate our simple online payment methods, knowing you
                                pay only when the work is done!
                            </Paragraph>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} sx={cssObject.content} container alignItems={'center'}>
                        <Grid item xs={2} sx={cssObject.iconBox}>
                            {' '}
                            <SatisfactionImg4 />
                        </Grid>
                        <Grid item xs={10} sx={cssObject.pledgeText}>
                            <Paragraph align={'justify'} size={'normal'}>
                                Now let's get started selecting the right HVAC system for your home so you too can be
                                our next 100% Satisfied Homeowner, it's our Pledge to you.
                            </Paragraph>
                        </Grid>
                    </Grid>
                    {/*  100% Satisfaction Pledge End */}
                </Fragment>
            }
        />
    );
}

export default SatisfactionPledge;
