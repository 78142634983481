import React from 'react';
import Divider from '@mui/material/Divider';
import { Box, Hidden, Theme, useTheme } from '@mui/material';
import { CartItem, CartItems } from 'models/cart';
import { OrderedItems, OrderProduct } from 'models/order';
import ContentContainer from './ContentContainer';
import Grid from '@mui/material/Grid';
import CurrencyIconNew from './icons/CurrencyIconNew';
import CollapseIcon from './icons/CollapseIcon';
import ExpandIcon from './icons/ExpandIcon';
import { SXCssObject } from 'models/ThemeImages';
import { defaultTaxCalculation, currencyFormat } from './common/NumberUtil';
import { get } from 'lodash';

interface Props {
    taxes?: number;
    subTotal?: number;
    discount?: number;
    items?: CartItems;
    orderItems?: OrderedItems;
    billingPage?: boolean;
    grandTotal?: number;
}

const createCssObject = (theme: Theme): SXCssObject => ({
    summaryHeaderSection: {
        display: 'inline-flex',
        backgroundColor: theme.palette.primary.light,
        alignItems: 'center',
        padding: '16px 22px',
    },
    currencyIconStyle: {
        display: 'flex',
        justifyContent: 'center',
        marginRight: '10px',
        color: 'white',
        border: `1px solid ${theme.palette.primary.contrastText}`,
        borderRadius: '4px',
        background: theme.palette.common.white,
    },
    headingPriceDesc: {
        color: theme.palette.text.primary,
        marginBottom: '4px',
        fontSize: '0.875rem',
    },
    headingPriceText: {
        fontSize: '1.25rem',
        color: theme.palette.action.active,
        fontWeight: theme.typography.fontWeightMedium,
    },
    summaryItems: {
        backgroundColor: theme.palette.primary.light,
        padding: '10px 22px',
    },
    summarySubHeaderStyle: {
        color: theme.palette.action.active,
        fontSize: '0.875rem',
        fontWeight: theme.typography.fontWeightMedium,
        padding: '10px 0px',
    },
    summarySubHeaderStyle2: {
        lineHeight: '15px',
        color: theme.palette.text.primary,
        fontSize: '0.75rem',
        fontWeight: 'normal',
    },
    summarySubHeaderStyle3: {
        color: theme.palette.action.active,
        fontSize: '0.875rem',
        padding: '15px 0px',
        fontWeight: theme.typography.fontWeightMedium,
    },
    summaryContent: {
        color: theme.palette.action.active,
        padding: '10px 0px',
        fontWeight: theme.typography.fontWeightMedium,
    },
    summaryContent2: {
        color: theme.palette.action.active,
        fontSize: '0.75rem',
        fontWeight: theme.typography.fontWeightMedium,
    },
    summaryContent3: {
        color: theme.palette.action.active,
        padding: '15px 0px',
        fontSize: '1.25rem',
        fontWeight: theme.typography.fontWeightMedium,
    },
    dividerStyle: {
        backgroundColor: theme.palette.primary.contrastText,
        height: '1px',
        margin: '0px -22px 0px -22px',
    },
    dividerStyle2: {
        backgroundColor: theme.palette.primary.contrastText,
        height: '1px',
        margin: '0px -22px 15px -22px',
    },
    dividerStyle3: {
        backgroundColor: theme.palette.primary.contrastText,
        height: '1px',
        margin: '-18px -22px 10px -22px',
    },
    cartSummary: {
        border: `1px solid ${theme.palette.primary.contrastText}`,
        marginBottom: '0px',
    },
    minMaxIcon: { display: 'flex', justifyContent: 'end' },
});
export default function CartSummary(props: Props) {
    const { items, orderItems, billingPage } = props;
    const cssObject: SXCssObject = createCssObject(useTheme());
    const [isExpanded, setExpanded] = React.useState(billingPage ? false : true);

    const firstSystemMain = items?.firstSystemMain || orderItems?.firstSystemMain;
    const secondSystemMain = items?.secondSystemMain || orderItems?.secondSystemMain;
    const firstSystemIAQ = items?.firstSystemIAQ || orderItems?.firstSystemIAQ;
    const secondSystemIAQ = items?.secondSystemIAQ || orderItems?.secondSystemIAQ;

    const discountPrice = (props.discount && (props.discount > 0 ? props.discount : props.discount * -1)) || 0;
    const itemLevel = (item: any) => {
        if (item && item.productAttributes) {
            return get(item, 'productAttributes.level', '');
        } else return get(item, 'product.productAttributes.level', '');
    };
    const itemSystemType = (item: any) => {
        if (item && item.productAttributes) {
            return get(item, 'productAttributes.systemType', '');
        } else return get(item, 'product.productAttributes.systemType', '');
    };
    const itemPrice = (item: any) => get(item, 'price', '');

    const addOnList = items?.addOnsList;
    const addOnOrderList = orderItems?.addOnsList;

    //Order page Cart summary
    let addOnSubTotal = 0;
    const addonProducts: any[] = [];
    addOnOrderList &&
        addOnOrderList.length > 0 &&
        addOnOrderList.map((i: OrderProduct) => {
            const quantity: number = i.qtyOrdered;
            for (let j = 0; j < quantity; j++) {
                addOnSubTotal += i.product.price;
                addonProducts.push(i.product);
            }
            return i.product;
        });

    //selection,cart, billing page cart Summary
    addOnList &&
        addOnList.length > 0 &&
        addOnList.map((i: CartItem) => {
            const quantity: number = i.quantity;
            for (let j = 0; j < quantity; j++) {
                addOnSubTotal += i.price;
                addonProducts.push(i);
            }
            return i;
        });

    const subTotal =
        props.subTotal ||
        (itemPrice(firstSystemMain) || 0) +
            (itemPrice(firstSystemIAQ) || 0) +
            (addOnSubTotal || 0) +
            (itemPrice(secondSystemMain) || 0) +
            (itemPrice(secondSystemIAQ) || 0);

    const taxes = props.taxes || props.taxes === 0 ? props.taxes : defaultTaxCalculation(subTotal);
    const total = props.grandTotal || subTotal + taxes - discountPrice;

    const toggleExpanded = (currentState: boolean) => {
        setExpanded(!currentState);
    };
    return (
        <ContentContainer disableGrid background={'bg2'}>
            <Grid container direction={'column'} sx={cssObject.cartSummary}>
                <Grid container item xs={12} sx={cssObject.summaryHeaderSection}>
                    <Grid container item xs={11}>
                        <Box sx={cssObject.currencyIconStyle}>{<CurrencyIconNew />}</Box>
                        <Box>
                            <Box sx={cssObject.headingPriceDesc}>
                                {'Overall Itemized Cost'}
                            </Box>
                            <Box sx={cssObject.headingPriceText}>
                                {currencyFormat.format(total) + (props.taxes || props.taxes === 0 ? '' : '*')}
                            </Box>
                        </Box>
                    </Grid>
                    {billingPage && (
                        <Hidden mdUp>
                            <Grid item xs={1} onClick={() => toggleExpanded(isExpanded)} sx={cssObject.minMaxIcon}>
                                {isExpanded ? <CollapseIcon /> : <ExpandIcon />}
                            </Grid>
                        </Hidden>
                    )}
                </Grid>
                {isExpanded && (
                    <React.Fragment>
                        <Grid item xs={12} container sx={cssObject.summaryItems}>
                            <Grid item xs={8}>
                                <Box sx={cssObject.summarySubHeaderStyle2}>{'First System'}</Box>
                                <ContentContainer disableGrid sx={cssObject.summarySubHeaderStyle}>
                                    <Box> {itemLevel(firstSystemMain) + ' HVAC'}</Box>
                                    <Box sx={cssObject.summarySubHeaderStyle2}>{itemSystemType(firstSystemMain)}</Box>
                                </ContentContainer>
                            </Grid>
                            <Grid item xs={4} container justifyContent={'flex-end'} id={'summary-subtotal'}>
                                <Box sx={cssObject.summarySubHeaderStyle2}></Box>
                                <ContentContainer disableGrid sx={cssObject.summaryContent}>
                                    {' '}
                                    <br />
                                    {currencyFormat.format(itemPrice(firstSystemMain) || 0)}
                                </ContentContainer>
                            </Grid>

                            {/* IAQ */}
                            {firstSystemIAQ && (
                                <Grid item xs={12} container>
                                    <Grid item xs={8}>
                                        <ContentContainer disableGrid sx={cssObject.summarySubHeaderStyle}>
                                            {itemLevel(firstSystemIAQ) + ' IAQ HVAC'}
                                        </ContentContainer>
                                    </Grid>
                                    <Grid item xs={4} container justifyContent={'flex-end'} id={'summary-subtotal'}>
                                        <ContentContainer disableGrid sx={cssObject.summaryContent}>
                                            {' '}
                                            {currencyFormat.format(itemPrice(firstSystemIAQ) || 0)}
                                        </ContentContainer>
                                    </Grid>
                                </Grid>
                            )}
                            {secondSystemMain && (
                                <Grid container>
                                    <Grid item xs={12}>
                                        <Divider sx={cssObject.dividerStyle}></Divider>
                                    </Grid>
                                    <Grid item xs={8}>
                                        <br />
                                        <Box sx={cssObject.summarySubHeaderStyle2}>{'Second System'}</Box>
                                        <ContentContainer disableGrid sx={cssObject.summarySubHeaderStyle}>
                                            <Box> {itemLevel(secondSystemMain) + ' HVAC'}</Box>
                                            <Box sx={cssObject.summarySubHeaderStyle2}>
                                                {itemSystemType(secondSystemMain)}
                                            </Box>
                                        </ContentContainer>
                                    </Grid>
                                    <Grid item xs={4} container justifyContent={'flex-end'} id={'summary-subtotal'}>
                                        <ContentContainer disableGrid sx={cssObject.summaryContent}>
                                            {' '}
                                            <br />
                                            <br />
                                            {currencyFormat.format(itemPrice(secondSystemMain) || 0)}
                                        </ContentContainer>
                                    </Grid>
                                </Grid>
                            )}

                            {/* IAQ */}
                            {secondSystemIAQ && (
                                <Grid item xs={12} container>
                                    <Grid item xs={8}>
                                        <ContentContainer disableGrid sx={cssObject.summarySubHeaderStyle}>
                                            {itemLevel(secondSystemIAQ) + ' IAQ HVAC'}
                                        </ContentContainer>
                                    </Grid>
                                    <Grid item xs={4} container justifyContent={'flex-end'} id={'summary-subtotal'}>
                                        <ContentContainer disableGrid sx={cssObject.summaryContent}>
                                            {' '}
                                            {currencyFormat.format(itemPrice(secondSystemIAQ) || 0)}
                                        </ContentContainer>
                                    </Grid>
                                </Grid>
                            )}
                            {addonProducts && addonProducts?.length > 0 && (
                                <Grid item xs={12}>
                                    <Divider sx={cssObject.dividerStyle2}></Divider>
                                </Grid>
                            )}
                            {addonProducts && addonProducts?.length > 0 && (
                                <Grid sx={cssObject.summarySubHeaderStyle2}>Other Products</Grid>
                            )}
                            {addonProducts?.map((addOnProduct: any, index: number) => (
                                <Grid key={index} item xs={12} container>
                                    <Grid item xs={9}>
                                        <ContentContainer disableGrid sx={cssObject.summarySubHeaderStyle}>
                                            {addOnProduct.name.slice(0, 16)}...
                                        </ContentContainer>
                                    </Grid>
                                    <Grid
                                        item
                                        xs={3}
                                        container
                                        style={{ justifyContent: 'flex-end' }}
                                        id={'summary-subtotal'}
                                    >
                                        <ContentContainer disableGrid sx={cssObject.summaryContent}>
                                            {' '}
                                            {currencyFormat.format(addOnProduct.price || 0)}
                                        </ContentContainer>
                                    </Grid>
                                </Grid>
                            ))}
                        </Grid>
                        {/* Subtotal */}
                        <Grid item xs={12} container sx={cssObject.summaryHeaderSection}>
                            <Grid item xs={12}>
                                <Divider sx={cssObject.dividerStyle3}></Divider>
                            </Grid>
                            <Grid item xs={6}>
                                <ContentContainer disableGrid sx={cssObject.summarySubHeaderStyle2}>
                                    Subtotal
                                </ContentContainer>
                            </Grid>
                            <Grid item xs={6} container justifyContent={'flex-end'} id={'summary-taxes'}>
                                <ContentContainer disableGrid sx={cssObject.summaryContent2}>
                                    {currencyFormat.format(subTotal)}
                                </ContentContainer>
                            </Grid>
                        </Grid>
                        {props.discount && props.discount !== 0 ? (
                            <Grid item xs={12} container sx={cssObject.summaryHeaderSection}>
                                <Grid item xs={12}>
                                    <Divider sx={cssObject.dividerStyle3}></Divider>
                                </Grid>
                                <Grid item xs={6}>
                                    <ContentContainer disableGrid sx={cssObject.summarySubHeaderStyle2}>
                                        {props.discount && 'Discount'}
                                    </ContentContainer>
                                </Grid>

                                <Grid item xs={6} container justifyContent={'flex-end'} id={'summary-taxes'}>
                                    <ContentContainer disableGrid sx={cssObject.summaryContent2}>
                                        {currencyFormat.format(discountPrice * -1)}
                                    </ContentContainer>
                                </Grid>
                            </Grid>
                        ) : (
                            <></>
                        )}
                        {/* Taxes */}
                        <Grid item xs={12} container sx={cssObject.summaryHeaderSection}>
                            <Grid item xs={12}>
                                <Divider sx={cssObject.dividerStyle3}></Divider>
                            </Grid>
                            <Grid item xs={6}>
                                <ContentContainer disableGrid sx={cssObject.summarySubHeaderStyle2}>
                                    {props.taxes || props.taxes === 0 ? 'Taxes' : 'Estimated Taxes'}
                                </ContentContainer>
                            </Grid>

                            <Grid item xs={6} container justifyContent={'flex-end'} id={'summary-taxes'}>
                                <ContentContainer disableGrid sx={cssObject.summaryContent2}>
                                    {' '}
                                    {currencyFormat.format(taxes) + (props.taxes || props.taxes === 0 ? '' : '*')}
                                </ContentContainer>
                            </Grid>
                        </Grid>
                        {/* Total */}
                        <Grid item xs={12} container sx={cssObject.summaryHeaderSection}>
                            <Grid item xs={12}>
                                <Divider sx={cssObject.dividerStyle3}></Divider>
                            </Grid>
                            <Grid item xs={6} container>
                                <ContentContainer disableGrid sx={cssObject.summarySubHeaderStyle3}>
                                    Total
                                </ContentContainer>
                            </Grid>
                            <Grid item xs={6} container justifyContent={'flex-end'} id={'summary-total'}>
                                <ContentContainer disableGrid sx={cssObject.summaryContent3}>
                                    {' '}
                                    {currencyFormat.format(total) + (props.taxes || props.taxes === 0 ? '' : '*')}
                                </ContentContainer>
                            </Grid>
                        </Grid>
                    </React.Fragment>
                )}
            </Grid>
        </ContentContainer>
    );
}
