import * as React from 'react';
import { useTheme } from '@mui/material/styles';
import { getThemePrimaryMain } from 'components/common/Utilities';

export default function AlertIconMedium(props: any) {
    const theme = useTheme();
    const themePrimaryMain = getThemePrimaryMain(theme);
    return (
        <svg
            version="1.2"
            baseProfile="tiny-ps"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 40 40"
            width="40"
            height="40"
        >
            <g id="Group_612">
                <path
                    id="Rectangle_411"
                    fill={themePrimaryMain}
                    d="M0 2.5C0 1.12 1.12 0 2.5 0L37.5 0C38.88 0 40 1.12 40 2.5L40 37.5C40 38.88 38.88 40 37.5 40L2.5 40C1.12 40 0 38.88 0 37.5L0 2.5Z"
                />
                <path
                    id="Union_25"
                    fill="#ffffff"
                    d="M18.13 26.88L18.13 26.88C18.13 26.38 18.32 25.9 18.67 25.55C19.03 25.2 19.5 25 20 25C20.5 25 20.97 25.2 21.33 25.55C21.68 25.9 21.88 26.38 21.88 26.88L21.88 26.88C21.88 27.37 21.68 27.85 21.33 28.2C20.97 28.55 20.5 28.75 20 28.75C19.5 28.75 19.03 28.55 18.67 28.2C18.32 27.85 18.13 27.37 18.13 26.88L18.13 26.88ZM18.13 21.25L18.13 13.75C18.13 13.25 18.32 12.78 18.67 12.42C19.03 12.07 19.5 11.88 20 11.88C20.5 11.88 20.97 12.07 21.33 12.42C21.68 12.78 21.88 13.25 21.88 13.75L21.88 21.25C21.88 21.75 21.68 22.22 21.33 22.58C20.97 22.93 20.5 23.13 20 23.13C19.5 23.13 19.03 22.93 18.67 22.58C18.32 22.22 18.13 21.75 18.13 21.25L18.13 21.25Z"
                />
            </g>
        </svg>
    );
}
