import React, { PropsWithChildren, ReactNode, useContext } from 'react';
import { SnackbarProvider } from 'notistack';
import SnackbarSuccess from '../../icons/SnackbarSuccess';
import CustomSnackMessageUI from '../CustomSnackMessageUI';
import InfoIcon from 'components/icons/InfoIcon';
import OtpErrorIcon from 'components/icons/OtpErrorIcon';
import { SnackbarParams } from './SnackbarModal';

const defaultTimeout = 5000;

enum VariantType {
    Success = 'success',
    Error = 'error',
    Warning = 'warning',
    Info = 'info',
}
export type SnackAlertFunction = (message: React.ReactNode | string, snackbarParams: SnackbarParams) => void;
export interface SnackbarAlert {
    info: SnackAlertFunction;
    success: SnackAlertFunction;
    warning: SnackAlertFunction;
    error: SnackAlertFunction;
    close: (key: string) => void;
}

const snackbarRef = React.createRef<SnackbarProvider>();
export const SnackMessage: SnackbarAlert = {
    info(message, snackbarParams) {
        enqueueMessage(snackbarParams, message, VariantType.Info, InfoIcon());
    },
    success(message, snackbarParams) {
        enqueueMessage(snackbarParams, message, VariantType.Success, SnackbarSuccess());
    },
    warning(message, snackbarParams) {
        enqueueMessage(snackbarParams, message, VariantType.Warning, OtpErrorIcon());
    },
    error(message, snackbarParams) {
        enqueueMessage(snackbarParams, message, VariantType.Error, OtpErrorIcon());
    },
    close(key: string) {
        snackbarRef.current?.closeSnackbar(key);
    },
};

const SnackMessageContext = React.createContext<SnackbarAlert>(SnackMessage);
export const SnackbarAlertProvider: React.FC<PropsWithChildren<{ children: ReactNode | undefined }>> = (props) => {
    return (
        <SnackMessageContext.Provider value={SnackMessage}>
            <SnackbarProvider
                ref={snackbarRef}
                dense
                maxSnack={1}
                anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
            >
                {props.children}
            </SnackbarProvider>
        </SnackMessageContext.Provider>
    );
};

function enqueueMessage(
    snackbarParams: SnackbarParams,
    message: React.ReactNode | string,
    variant: VariantType,
    Icon: JSX.Element
) {
    const { timeout = defaultTimeout, ...others } = snackbarParams;
    snackbarRef.current?.enqueueSnackbar(message, {
        variant: variant,
        autoHideDuration: timeout,
        ...others,
        content: (key, message) => <CustomSnackMessageUI id={key} message={message} iconLeft={Icon} />,
    });
}

export function useCustomAlert() {
    return useContext(SnackMessageContext);
}
