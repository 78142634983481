import React from 'react';
import { Theme, useTheme } from '@mui/material/styles';
import ContentContainer from '../../components/ContentContainer';
import Container from '@mui/material/Container';
import WizardHeader from '../../components/WizardHeader';
import Grid from '@mui/material/Grid';
import { Heading } from '../../components/Heading';
import { Paragraph } from '../../components/Paragraph';
import { Product } from '../../models/product';
import { DoubleSystemWizardState, NumberOfHVAC, ProductCriteria } from '../../models/productCriteria';
import ProductDetail from '../../components/ProductDetail';
import LoadingOverlay from '../../components/common/LoadingOverlay';
import { theme } from '../../themes/theme';
import { useDispatch, useSelector } from 'react-redux';
import { Box, useMediaQuery } from '@mui/material';
import Shield from 'components/icons/Shield';
import WizardNotification from 'components/WizardNotification';
import { Button } from 'components/Button';
import { redirectToCartPage, resetIAQSelection } from 'store/actions/cartActions';
import { useNavigate } from 'react-router-dom';
import { AddressType, SystemGroup } from 'models/cart';
import { selectCart, selectProceedToCart } from 'store/selectors/cart';
import { selectShippingAddressStatus } from 'store/selectors/selection';
import HeadlineParagraph from 'components/HeadlineParagraph';
import { clearWizarQuestionData, setDoubleSystemFlag } from 'store/actions/productCriteriaActions';
import { AppRoute } from 'models/route';
import WizardCriteriaFooterNew from 'components/WizardCriteriaFooterNew';
import { AddressContainer } from 'pages/user/ShippingAddress/AddressContainer';
import { getAddressListAction } from 'store/actions/addressActions';
import { SXCssObject } from 'models/ThemeImages';
import { invokeGACartIAQRemoved } from 'components/Analytics/GoogleAnalyticsTagging';
import DisclaimerText from './DisclaimerText';

const createCssObject = (theme: Theme): SXCssObject => ({
    root: {
        display: 'flex',
        flexDirection: {
            xs: 'column',
            sm: 'inherit',
        },
        justifyContent: {
            xs: 'center',
            sm: 'flex-end',
        },
        minHeight: {
            sm: '80px',
            md: 'inherit',
        },
    },
    button: {
        '&:not(:first-of-type)': {
            paddingTop: {
                xs: theme.spacing(2),
                sm: 'inherit',
            },
            paddingLeft: {
                xs: 0,
                sm: theme.spacing(2),
            },
        },
        width: {
            xs: '100%',
            sm: 'inherit',
        },
        maxWidth: {
            xs: '100%',
            sm: 'inherit',
        },
        flexBasis: {
            xs: '100%',
            sm: 'inherit',
        },
    },
    contentContainer: {
        minHeight: `calc(100vh - ${theme.spacing(8)})`,
    },
    header: {
        paddingTop: {
            xs: '30px',
            sm: '43px',
            md: '72px',
        },
        paddingBottom: {
            xs: '20px',
            md: '46px',
        },
    },
    salesTaxDesc: {
        paddingTop: theme.spacing(3),
        paddingBottom: theme.spacing(3),
    },
    divider: {
        paddingTop: theme.spacing(1.5),
        paddingBottom: theme.spacing(1.5),
    },
    dividerFinalMobile: {
        paddingTop: theme.spacing(4),
    },
    dividerFinal: {
        paddingTop: theme.spacing(7.2),
    },

    noProductList: {
        textAlign: 'center',
        minHeight: '100vh',
    },
    actions: {
        position: {
            sm: 'relative',
        },
        left: {
            sm: 0,
        },
        bottom: {
            sm: 0,
        },
        width: {
            sm: '100%',
        },
        padding: {
            sm: 0,
        },
        height: {
            sm: '80px',
        },
        backgroundColor: theme.palette.common.white,
    },
    helpFooter: {
        backgroundColor: theme.palette.common.white,
        padding: theme.spacing(1, 0, 1, 0),
    },
    buttonSection: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    readMoreText: {
        color: theme.palette.primary.main,
        cursor: 'pointer',
        fontWeight: theme.typography.fontWeightMedium,
        paddingLeft: theme.spacing(0.3),
        '&:hover': {
            color: `${theme.palette.primary.main} !important`,
        },
    },
    iaqHeading: {
        paddingBottom: {
            xs: theme.spacing(2),
            sm: theme.spacing(4),
        },
    },
});

interface Props {
    loading: boolean;
    products?: Product[];
    criteria: ProductCriteria;
    secondSystem: boolean;
    iaqSKU?: string;
    cartMainSku?: boolean;
    selectionMainSku?: string;
    onSelectProduct: (product?: Product) => void;
    onBack?: () => void;
}

function IAQOptions(props: Props) {
    const { products, loading, secondSystem, criteria, iaqSKU, cartMainSku, selectionMainSku } = props;
    const cssObject: SXCssObject = createCssObject(useTheme());
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const isXs = useMediaQuery(theme.breakpoints.only('xs'));
    const isSm = useMediaQuery(theme.breakpoints.only('sm'));
    const cartData = useSelector(selectCart);
    const shippingAddressCaptured = useSelector(selectShippingAddressStatus);
    const proceedToCart = useSelector(selectProceedToCart);
    const shouldRedirectToSecondSystemFlow =
        !secondSystem &&
        criteria.numberOfHVAC === NumberOfHVAC.Double &&
        !cartMainSku &&
        !selectionMainSku &&
        proceedToCart;
    const [isContinueEnabled, setIsContinueEnabled] = React.useState(iaqSKU);

    const componentId = 'iaq-options';
    const sysGroupToChange = props.secondSystem ? SystemGroup.SystemGroup2 : SystemGroup.SystemGroup1;

    const shouldShowAddressPopup =
        (!cartData.shippingAddress || !shippingAddressCaptured) &&
        (secondSystem || criteria.numberOfHVAC === NumberOfHVAC.Single) &&
        !cartMainSku;

    const handleSubmit = () => {
        if (shouldRedirectToSecondSystemFlow) {
            dispatch(clearWizarQuestionData({ numberOfHVAC: NumberOfHVAC.Double }));
            dispatch(setDoubleSystemFlag({ doubleSystemFlag: DoubleSystemWizardState.Second }));
            navigate(AppRoute.AboutYourHome, { state: { isSecondSystem: true } });
        } else if (proceedToCart) {
            if (shouldShowAddressPopup) {
                dispatch(getAddressListAction(true));
            } else {
                dispatch(redirectToCartPage());
            }
        } else {
            navigate(AppRoute.EquipmentSelection, { state: { isSecondSystem: secondSystem } });
        }
    };

    return (
        <Grid>
            <Grid sx={cssObject.contentContainer}>
                <AddressContainer
                    flow={AddressType.Home}
                    pageToRedirect={AppRoute.CartPage}
                    isFirstTimeCapture={true}
                />
                <LoadingOverlay open={loading} />
                <WizardHeader
                    shadow={false}
                    showBackButton={true}
                    onBack={props.onBack}
                    showRestartButton={false}
                    showExitButton={false}
                />
                <ContentContainer disableGrid background={'bg2'}>
                    <Container maxWidth={'lg'} disableGutters>
                        <ContentContainer sx={cssObject.header}>
                            <Grid container xs={12}>
                                {products && products.length > 0 && (
                                    <Grid item xs={12} container>
                                        <Grid item xs={12} style={{ paddingBottom: '30px' }}>
                                            <Heading label={`The Truth About Indoor Air Quality (IAQ)`} />
                                        </Grid>
                                        {!isXs ? (
                                            <Grid item xs={12} container justifyContent="space-between">
                                                <Grid item xs={12} md={6} style={{ paddingRight: isSm ? '' : '20px' }}>
                                                    <Paragraph align={'justify'}>
                                                        Did you know that air pollution can happen indoors? Whether you
                                                        have leaks around your windows and doors, or simply open them,
                                                        you bring outside air inside, including pollen and smoke.
                                                    </Paragraph>
                                                    <br />
                                                    <Paragraph align={'justify'}>
                                                        Indoor air can have allergens like dust, pet dander and mold.
                                                        Humidity can increase dust mite levels. Furnaces, wood-burning
                                                        fires, candles, and cooking can all push particles into the air.
                                                        Even newly installed floors and furniture can release chemical
                                                        pollutants.
                                                    </Paragraph>
                                                    {isSm && <br />}
                                                </Grid>

                                                <Grid item xs={12} md={6} style={{ paddingLeft: isSm ? '' : '20px' }}>
                                                    <Paragraph align={'justify'}>
                                                        In fact, the quality of air indoors—where we spend most of our
                                                        time—can be two to five times more polluted than the air
                                                        outdoors.
                                                    </Paragraph>
                                                    <br />
                                                    <Paragraph align={'justify'}>
                                                        Cutting-edge indoor air quality systems are incredibly effective
                                                        at reducing allergies, alleviating asthma, reducing illness,
                                                        improving sleep, and simply helping us breathe easier.
                                                    </Paragraph>
                                                </Grid>
                                            </Grid>
                                        ) : (
                                            <Grid item xs={12}>
                                                <Paragraph align={'justify'}>
                                                    Indoor air can have allergens like dust, pet dander, mold, and other
                                                    particles.
                                                </Paragraph>
                                                <br />
                                                <Paragraph align={'justify'}>
                                                    Cutting-edge indoor air quality systems are incredibly effective at
                                                    reducing allergies, alleviating asthma, reducing illness, improving
                                                    sleep, and simply helping us breathe easier.
                                                </Paragraph>
                                            </Grid>
                                        )}
                                    </Grid>
                                )}

                                {(!products || !products.length) && (
                                    <Grid item xs={12} container spacing={2} sx={cssObject.noProductList}>
                                        <Grid item xs={12}>
                                            <Heading
                                                variant={'h2'}
                                                label={'Could not find any products based on your requirements.'}
                                            />
                                        </Grid>
                                    </Grid>
                                )}
                            </Grid>
                            {isSm && (
                                <Grid item xs={12} style={{ paddingTop: '30px' }}>
                                    <HeadlineParagraph
                                        headlineSize={'h4'}
                                        label={`Select an IAQ package below and start breathing easier!`}
                                    />
                                </Grid>
                            )}
                        </ContentContainer>
                    </Container>
                </ContentContainer>
                {products && products.length > 0 && (
                    <ContentContainer disableGrid background={'bg2'}>
                        <Container maxWidth={'lg'} disableGutters>
                            <ContentContainer>
                                <Grid container>
                                    {products?.map((product, index) => (
                                        <Grid key={index} item xs={12} container direction={'column'}>
                                            <ProductDetail
                                                item={product}
                                                isIAQ={true}
                                                selectButton={!proceedToCart}
                                                onSelect={(isButtonSelected, componentSku) => {
                                                    if (isButtonSelected) {
                                                        setIsContinueEnabled(componentSku);
                                                        props.onSelectProduct(product);
                                                    } else {
                                                        props.onSelectProduct();
                                                        setIsContinueEnabled('');
                                                    }
                                                }}
                                                selectedButtonSku={isContinueEnabled}
                                            />
                                            {products?.length !== index + 1 && (
                                                <Grid item sx={cssObject.divider}></Grid>
                                            )}
                                        </Grid>
                                    ))}
                                    <Grid item xs={12} container spacing={1} sx={cssObject.salesTaxDesc}>
                                        <Grid item xs={12}>
                                            <Box sx={cssObject.root}>
                                                <Box sx={cssObject.button}>
                                                    <Button
                                                        id={`${componentId}-not-today-btn`}
                                                        color={'primary'}
                                                        isContrast={isContinueEnabled === '0' ? false : true}
                                                        selectedSecondary={isContinueEnabled === '0' ? true : false}
                                                        onClick={() => {
                                                            dispatch(
                                                                resetIAQSelection({
                                                                    systemGroupToReset: sysGroupToChange,
                                                                })
                                                            );
                                                            invokeGACartIAQRemoved();
                                                            setIsContinueEnabled((prevState) =>
                                                                prevState === '0' ? undefined : '0'
                                                            );
                                                            props.onSelectProduct();
                                                        }}
                                                        // disabled={props.disabled}
                                                    >
                                                        NO THANKS, NOT TODAY
                                                    </Button>
                                                </Box>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                    <DisclaimerText />
                                </Grid>
                            </ContentContainer>
                        </Container>
                    </ContentContainer>
                )}
            </Grid>
            <br />
            {!props.secondSystem && <WizardNotification isFixed={false} />}
            <br />
            <Box sx={[cssObject.actions, { display: 'flex', alignItems: 'center' }]}>
                <Container disableGutters sx={{ backgroundColor: 'white' }}>
                    <ContentContainer>
                        <Grid container>
                            <Grid container sx={cssObject.helpFooter} alignItems={'center'} item xs={12} sm={8} md={8}>
                                <Grid item>
                                    <Shield />
                                </Grid>
                                <Grid item xs={10}>
                                    <Paragraph size="small" align="left">
                                        Did you know your new installed HVAC system will be protected by our 1-Year Full Labor
                                        Warranty!
                                    </Paragraph>
                                </Grid>
                            </Grid>
                            <Grid spacing={2} item xs={12} sm={4} md={4} sx={cssObject.buttonSection}>
                                <WizardCriteriaFooterNew
                                    nextLabel={
                                        shouldRedirectToSecondSystemFlow
                                            ? 'Continue with Another Replacement'
                                            : 'continue'
                                    }
                                    onNext={handleSubmit}
                                    disabled={!isContinueEnabled}
                                />
                            </Grid>
                        </Grid>
                    </ContentContainer>
                </Container>
            </Box>
        </Grid>
    );
}

export default IAQOptions;
