import { useTheme } from '@mui/material';
import { getThemePrimaryMain } from 'components/common/Utilities';
import * as React from 'react';

export const EditIconNew = (props: any) => {
    const theme = useTheme();
    let color = getThemePrimaryMain(theme);
    if (props.color) {
        //Color can be passed in props dynamically; if color is not passed, use primary.main
        color = props.color;
    }
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} {...props}>
            <path
                d="m2744.33-2898.746-.114 16.505 2.515 3.422 2.759-3.422.114-16.505Z"
                transform="rotate(38 -2831.923 -5406.77)"
                style={{
                    stroke: color,
                    strokeLinecap: 'round',
                    strokeLinejoin: 'round',
                    strokeWidth: 2,
                    fill: 'none',
                }}
            />
        </svg>
    );
};
