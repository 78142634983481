import heroDesktop from 'assets/images/hero/aahdr_hero_big.webp';
import heroSmall from 'assets/images/hero/aahdr_hero_small.webp';
import homeFinancingImage from 'assets/images/t3_home_financing_image.webp';
import logoMobile from 'assets/icons/logo_Black_Green.svg';
import logoDesktop from 'assets/icons/logo_AAHDR.svg';
import { ThemeContent, ThemeImages } from 'models/ThemeImages';
import { ThemeOptions } from '@mui/material/styles';
import { customPropertiesObj, muiComponentTheme } from './CommonThemeProperties';

declare module '@mui/material/styles' {
    interface Theme {
        customProperties?: any;
    }
    // allow configuration using `createTheme`
    interface ThemeOptions {
        customProperties?: any;
    }
}
export const aahdrImages: ThemeImages = {
    logoMobile,
    logoDesktop,
    homeFinancingImage,
    homePage: {
        heroSmall: [{ imageUrl: heroSmall }],
        heroDesktop: [{ imageUrl: heroDesktop }],
    },
};

const FONTFAMILY_PARAGRAPH = 'Roboto';
const FONTFAMILY_HEADING = 'Poppins';
const COLOR_HEADING = '#1F1F1F';
export const theme: ThemeOptions = {
    components: { ...muiComponentTheme },
    palette: {
        background: {
            default: '#f5f5f5',
        },
        primary: {
            main: '#73AD49', // variable; Button background
            light: '#E9F5E0', // variable; light primary
            dark: '#5B9730', // variable; button hover
            contrastText: '#BFDCAA', // primary medium light color
        },
        secondary: {
            main: '#F5F5F5', //Secondary button background color
            light: '#3A3A3A', // Secondary button text color
            dark: '#E1E1E1', //Secondary button background(on hover) color
            contrastText: '#ADADAD', // Secondary button disabled.
        },
        text: {
            primary: '#4A4A4A', // Text
            secondary: '#FFFFFF', // Text
        },
        divider: '#CCCCCC', // also used in border color
        action: {
            active: COLOR_HEADING, // Link active/hover state
            selected: '#8F9197', // variable; Wizard button selected
            hover: '#3A3A3A', // Outlined button border color in hover state
            disabled: '#E1E1E1',
        },
    },
    customProperties: {
        ...customPropertiesObj,
        svgPrimaryColor: '#73AD49',
        svgPrimaryLightColor: '#E9F5E0',
    },
    typography: {
        fontWeightMedium: 500,
        h1: {
            fontFamily: FONTFAMILY_HEADING, // variable
            fontWeight: 700, // variable
            fontSize: '2.6875rem',
            color: COLOR_HEADING,
            lineHeight: '3.125rem',
            letterSpacing: 0,
            textTransform: 'uppercase',
        },
        h2: {
            fontFamily: FONTFAMILY_HEADING, // variable
            fontWeight: 700, // variable
            fontSize: '2rem', //
            color: COLOR_HEADING,
            lineHeight: '2.25rem',
            letterSpacing: 0,
        },
        h3: {
            fontFamily: FONTFAMILY_HEADING, // variable
            fontWeight: 700, // variable
            fontSize: '1.5rem',
            color: COLOR_HEADING,
            lineHeight: '1.75rem',
            letterSpacing: 0,
        },
        h4: {
            fontFamily: FONTFAMILY_HEADING, // variable
            fontWeight: 700, // variable
            fontSize: '1.25rem',
            color: COLOR_HEADING,
            lineHeight: '1.75rem',
            letterSpacing: 0,
        },
        h5: {
            fontFamily: FONTFAMILY_HEADING, // variable
            fontWeight: 700, // variable
            fontSize: '1rem',
            color: COLOR_HEADING,
            lineHeight: '1.75rem',
            letterSpacing: 0,
            textTransform: 'uppercase',
        },
        body1: {
            // Paragraph
            fontFamily: FONTFAMILY_PARAGRAPH, // variable
            fontWeight: 400, // variable
            fontSize: '1rem',
            lineHeight: '1.5rem',
            letterSpacing: 0,
        },
        body2: {
            // Paragraph Small
            fontFamily: FONTFAMILY_PARAGRAPH, // variable
            fontWeight: 400, // variable
            fontSize: '0.875rem',
            lineHeight: '1.25rem',
            letterSpacing: 0,
        },
        subtitle1: {
            // Link
            fontFamily: FONTFAMILY_PARAGRAPH, // variable
            fontWeight: 500, // variable
            fontSize: '0.875rem',
            lineHeight: '1.25rem',
            letterSpacing: 0,
            textTransform: 'uppercase',
            color: COLOR_HEADING,
        },
        subtitle2: {
            // Footer
            fontFamily: FONTFAMILY_PARAGRAPH, // variable
            fontWeight: 500, // variable
            fontSize: '0.75rem',
            lineHeight: '1rem',
            letterSpacing: 0,
            textTransform: 'uppercase',
            color: '#6D6D6D',
        },
        button: {
            // Button
            fontFamily: FONTFAMILY_PARAGRAPH, // variable
            fontWeight: 500, // variable
            fontSize: '0.875rem',
            lineHeight: '1.25rem',
            textAlign: 'center',
            textTransform: 'uppercase',
        },
    },
    spacing: 10,
    shape: {
        borderRadius: 20,
    },
};
export const aahdrContent: ThemeContent = {
    label: {
        shippingAddressLabel: 'Home',
    },
};

export default theme;
