import { Grid, Hidden, Theme } from '@mui/material';
import React, { useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectLoginState } from '../../store/selectors/loginSelector';
import Navigator from '../../components/Navigator';
import ContentContainer from '../../components/ContentContainer';
import { Paragraph } from '../../components/Paragraph';
import { Heading } from '../../components/Heading';
import { Button } from '../../components/Button';
import { AppRoute } from '../../models/route';
import { useNavigate } from 'react-router';
import LoadingOverlay from '../../components/common/LoadingOverlay';
import { getProfileAddresses } from 'store/actions/addressActions';
import { SXCssObject } from 'models/ThemeImages';
import useTheme from '@mui/system/useTheme';

const createCssObject = (theme: Theme): SXCssObject => ({
    root: {
        backgroundColor: theme.palette.common.white,
        marginBottom: theme.spacing(1.5),
        marginTop: {
            xs: theme.spacing(2.4),
            sm: 'inherit',
        },
        width: '100%',
        borderRadius: '4px',
    },
    '&.MuiContainer-root': {
        padding: {
            xs: ' 0px !important',
            sm: 'inherit',
        },
    },
    myProfileContainer: {
        padding: {
            xs: theme.spacing(1.5),
            md: theme.spacing(1, 3),
        },
    },
    card: {
        padding: {
            xs: theme.spacing(1, 0),
            md: theme.spacing(1.5, 0),
        },
    },
    heading: {
        alignItems: 'center',
        paddingTop: {
            xs: theme.spacing(3),
            md: theme.spacing(5),
        },
        paddingBottom: {
            xs: theme.spacing(0),
            sm: theme.spacing(3),
            md: theme.spacing(5),
        },
    },
    divider: {
        color: theme.palette.divider,
        border: '2px solid',
    },
    buttonContainer: {
        textAlign: 'end',
    },
});

export function MyProfile() {
    const cssObject: SXCssObject = createCssObject(useTheme());
    const navigate = useNavigate();
    const dispatch = useDispatch();
    let loginData = useSelector(selectLoginState);
    let mounted: any = useRef();
    React.useEffect(() => {
        if (!mounted.current) {
            mounted.current = true;
            dispatch(getProfileAddresses());
        }
    });
    return (
        <div>
            <LoadingOverlay open={loginData.loading} />
            <ContentContainer disableGrid background={'bg3'}>
                <ContentContainer>
                    <Navigator color={'primary'} transparent={false} />
                </ContentContainer>
            </ContentContainer>
            <ContentContainer>
                <Grid style={{ marginBottom: '60px' }}>
                    <Grid>
                        <Grid container justifyContent={'space-between'} sx={cssObject.heading}>
                            <Grid item xs={6}>
                                <Heading label={'My Profile'}></Heading>
                            </Grid>
                            <Grid item sx={cssObject.buttonContainer} xs={5}>
                                <Button
                                    isContrast
                                    onClick={() => {
                                        navigate(AppRoute.EditMyProfile);
                                    }}
                                >
                                    EDIT PROFILE
                                </Button>
                            </Grid>
                        </Grid>

                        <Grid container>
                            <Grid sx={cssObject.root}>
                                <Hidden mdDown>
                                    <Grid container justifyContent={'space-between'} sx={cssObject.myProfileContainer}>
                                        <Grid container xs={8}>
                                            <Grid xs={7} sx={cssObject.card}>
                                                <Paragraph semibold fontSizeMediumSmall headingColor>
                                                    First Name
                                                </Paragraph>
                                                <Paragraph fontSizeMediumSmall>{loginData.firstname}</Paragraph>
                                            </Grid>
                                            <Grid xs={5} sx={cssObject.card}>
                                                <Paragraph semibold fontSizeMediumSmall headingColor>
                                                    Last Name
                                                </Paragraph>
                                                <Paragraph fontSizeMediumSmall>{loginData.lastname}</Paragraph>
                                            </Grid>
                                            <Grid xs={7} sx={cssObject.card}>
                                                <Paragraph semibold fontSizeMediumSmall headingColor>
                                                    Email Address
                                                </Paragraph>
                                                <Paragraph fontSizeMediumSmall>{loginData.email}</Paragraph>
                                            </Grid>
                                            <Grid xs={5} sx={cssObject.card}>
                                                <Paragraph semibold fontSizeMediumSmall headingColor>
                                                    Phone
                                                </Paragraph>
                                                <Paragraph fontSizeMediumSmall>
                                                    {loginData.phone.replaceAll('.', '')}
                                                </Paragraph>
                                            </Grid>
                                        </Grid>
                                        {loginData.defaultProfileAddress && (
                                            <Grid xs={4} item>
                                                <Grid sx={cssObject.card}>
                                                    <Paragraph semibold fontSizeMediumSmall headingColor>
                                                        Billing Address
                                                    </Paragraph>
                                                    <Grid>
                                                        <Paragraph>
                                                            <Paragraph semibold>
                                                                {loginData.defaultProfileAddress?.addressName}:{' '}
                                                            </Paragraph>
                                                            {loginData.defaultProfileAddress?.firstname}{' '}
                                                            {loginData.defaultProfileAddress?.lastname}, <br />
                                                            {loginData.defaultProfileAddress?.address1},{' '}
                                                            {loginData.defaultProfileAddress?.address2 && (
                                                                <>
                                                                    {loginData.defaultProfileAddress?.address2},<br />{' '}
                                                                </>
                                                            )}
                                                            {loginData.defaultProfileAddress?.city},{' '}
                                                            {loginData.defaultProfileAddress?.region},{' '}
                                                            {loginData.defaultProfileAddress?.postcode}
                                                        </Paragraph>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        )}
                                    </Grid>
                                </Hidden>
                                <Hidden mdUp>
                                    <Grid container justifyContent={'space-between'} sx={cssObject.myProfileContainer}>
                                        <Grid container>
                                            <Grid xs={12} sm={7} sx={cssObject.card}>
                                                <Paragraph semibold fontSizeMediumSmall headingColor>
                                                    First Name
                                                </Paragraph>
                                                <Paragraph fontSizeMediumSmall>{loginData.firstname}</Paragraph>
                                            </Grid>
                                            <Grid xs={12} sm={5} sx={cssObject.card}>
                                                <Paragraph semibold fontSizeMediumSmall headingColor>
                                                    Last Name
                                                </Paragraph>
                                                <Paragraph fontSizeMediumSmall>{loginData.lastname}</Paragraph>
                                            </Grid>
                                            {!loginData.defaultProfileAddress && (
                                                <Grid xs={12} sm={7} sx={cssObject.card}>
                                                    <Paragraph semibold fontSizeMediumSmall headingColor>
                                                        Email
                                                    </Paragraph>
                                                    <Paragraph fontSizeMediumSmall>{loginData.email}</Paragraph>
                                                </Grid>
                                            )}
                                            {loginData.defaultProfileAddress && (
                                                <Grid xs={12} sm={7} item>
                                                    <Grid sx={cssObject.card} xs={12} sm={7}>
                                                        <Paragraph semibold fontSizeMediumSmall headingColor>
                                                            Billing Address
                                                        </Paragraph>
                                                        <Grid>
                                                            <Paragraph fontSizeMediumSmall>
                                                                {loginData.defaultProfileAddress?.addressName}:{' '}
                                                                {loginData.defaultProfileAddress?.firstname}{' '}
                                                                {loginData.defaultProfileAddress?.lastname},{' '}
                                                                {loginData.defaultProfileAddress?.address1},{' '}
                                                                {loginData.defaultProfileAddress?.address2 && (
                                                                    <>{loginData.defaultProfileAddress?.address2} </>
                                                                )}
                                                                {loginData.defaultProfileAddress?.city},{' '}
                                                                {loginData.defaultProfileAddress?.region},{' '}
                                                                {loginData.defaultProfileAddress?.postcode}
                                                            </Paragraph>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            )}
                                            <Grid xs={12} sm={5} sx={cssObject.card}>
                                                <Paragraph semibold fontSizeMediumSmall headingColor>
                                                    Phone
                                                </Paragraph>
                                                <Paragraph fontSizeMediumSmall>
                                                    {loginData.phone.replaceAll('.', '')}
                                                </Paragraph>
                                            </Grid>
                                        </Grid>
                                        {loginData.defaultProfileAddress && (
                                            <Grid xs={12} sx={cssObject.card}>
                                                <Paragraph semibold fontSizeMediumSmall headingColor>
                                                    Email
                                                </Paragraph>
                                                <Paragraph fontSizeMediumSmall>{loginData.email}</Paragraph>
                                            </Grid>
                                        )}
                                    </Grid>
                                </Hidden>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </ContentContainer>
        </div>
    );
}
