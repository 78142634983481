import React from 'react';
import Grid from '@mui/material/Grid';
import ContentContainer from './ContentContainer';
import { Box } from '@mui/material';
import { Theme, useTheme } from '@mui/material/styles';
import CurrencyIcon from './icons/CurrencyIcon';
import ArrowDown3 from './icons/ArrowDown3';
import { SXCssObject } from 'models/ThemeImages';
import { currencyFormat } from './common/NumberUtil';

interface Props {
    text: string;
    amount: number;
    handleExpand: () => void;
    collapse: boolean;
}
const createCssObject = (theme: Theme): SXCssObject => ({
    summaryHeaderSection: {
        display: 'inline-flex',
        backgroundColor: theme.palette.primary.light,
        alignItems: 'center',
        padding: '16px 22px',
    },
    currencyIconStyle: {
        display: 'flex',
        justifyContent: 'center',
        marginRight: '16px',
        color: 'white',
    },
    headingPriceDesc: {
        color: theme.palette.text.primary,
        marginBottom: '4px',
    },
    headingPriceText: {
        fontSize: '1.25rem',
        color: theme.palette.action.active,
        fontWeight: theme.typography.fontWeightMedium,
    },
    cartSummary: {
        border: `1px solid ${theme.palette.primary.contrastText}`,
    },
    arrowIcon: {
        cursor: 'pointer',
    },
    showMore: {
        color: theme.palette.text.primary,
        marginLeft: '5px',
    },
});
export default function MinimisedCartSummary(props: Props) {
    const cssObject: SXCssObject = createCssObject(useTheme());
    const { text, amount, handleExpand, collapse } = props;

    return (
        <ContentContainer disableGrid background={'bg2'}>
            <Grid container direction={'column'} sx={cssObject.cartSummary}>
                <Grid container item xs={12} sx={cssObject.summaryHeaderSection}>
                    <Grid container item xs={12}>
                        <Box sx={cssObject.currencyIconStyle}>{<CurrencyIcon />}</Box>
                        <Box>
                            <Box sx={cssObject.headingPriceDesc}>{text}</Box>
                            <Box sx={cssObject.headingPriceText}>{currencyFormat.format(amount)}</Box>
                        </Box>
                    </Grid>
                    {collapse && (
                        <Grid container style={{ marginTop: '20px' }} alignItems={'center'}>
                            <Grid item style={{ display: 'flex' }}>
                                <ArrowDown3
                                    onClick={() => {
                                        handleExpand();
                                    }}
                                    style={cssObject.arrowIcon}
                                />
                            </Grid>
                            <Grid sx={cssObject.showMore}>Show More</Grid>
                        </Grid>
                    )}
                </Grid>
            </Grid>
        </ContentContainer>
    );
}
