import { RootState } from '../reducers';
import { createSelector } from 'reselect';

export const selectNotificationState = (state: RootState) => state.notification;
export const selectCartNotification = createSelector(
    selectNotificationState,
    (notification) => notification.cartNotification
);
export const selectPendingOrderNotification = createSelector(
    selectNotificationState,
    (notification) => notification.pendingOrderNotification
);
