import React from 'react';
import { useField } from 'formik';
import { CheckBox } from './CheckBox';

interface Props {
    id?: string;
    fullWidth?: boolean;
    name: string;
    label?: string;
    onChange?: (isChecked: any) => void;
}

export default function FormikCheckBox(props: Props) {
    const { id, name, label, onChange, ...other } = props;
    const [, meta, { setValue }] = useField(props);
    return (
        <CheckBox
            id={id ? id : `${name}`}
            label={label}
            checked={meta.value}
            onChange={() => {
                props.onChange && props.onChange(!meta.value);
                setValue(!meta.value);
                return;
            }}
            error={meta.touched && Boolean(meta.error)}
            helperText={meta.touched ? meta.error : ''}
            {...other}
        />
    );
}
