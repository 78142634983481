import React from 'react';
import Typography from '@mui/material/Typography';
import { useMediaQuery, useTheme } from '@mui/material';
import { SXCssValueType } from 'models/ThemeImages';

interface Props {
    id?: string;
    label: string;
    variant?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5';
    color?: 'primary' | 'secondary';
    align?: 'inherit' | 'left' | 'center' | 'right' | 'justify';
    isPopUp?: boolean;
    sx?:SXCssValueType
}

export function Heading(props: Props) {
    const { label, color = 'primary', isPopUp, ...other } = props;
    const theme = useTheme();
    const isSmDown = useMediaQuery(theme.breakpoints.down('md'));
    const isXs = useMediaQuery(theme.breakpoints.only('xs'));

    const getVariant = () => {
        return isXs ? 'h4' : isSmDown || isPopUp ? 'h3' : 'h2';
    };
    return (
        <Typography variant={getVariant()} color={color === 'secondary' ? 'textSecondary' : undefined} {...other}>
            {label}
        </Typography>
    );
}
