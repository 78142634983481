import * as React from 'react';

export default function SalesPhoneIcon() {
    return (
        <svg
            id="Component_7_1"
            data-name="Component 7 – 1"
            xmlns="http://www.w3.org/2000/svg"
            width="19"
            height="19"
            viewBox="0 0 19 19"
        >
            <g id="Rectangle_80" data-name="Rectangle 80" fill="#fff" stroke="#707070" strokeWidth="1" opacity="0">
                <rect width="19" height="19" stroke="none" />
                <rect x="0.5" y="0.5" width="18" height="18" fill="none" />
            </g>
            <g id="Group_3117" data-name="Group 3117" transform="translate(-503.667 -396.882)">
                <path
                    id="Path_179"
                    data-name="Path 179"
                    d="M515.258,403.858a1.554,1.554,0,0,1,.008,2.853"
                    transform="translate(1.627 1.088)"
                    fill="none"
                    stroke="#73ad49"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="1.5"
                />
                <path
                    id="Path_180"
                    data-name="Path 180"
                    d="M515.38,401.224a5.2,5.2,0,0,1,.026,9.641"
                    transform="translate(1.8 0.315)"
                    fill="none"
                    stroke="#73ad49"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="1.5"
                />
                <path
                    id="Path_181"
                    data-name="Path 181"
                    d="M511.254,408.845h.714a2.339,2.339,0,0,1,2.331,1.978,2.274,2.274,0,0,1-2.162,2.65H508.6a1.25,1.25,0,0,1-1.213-1.284V400.166a1.251,1.251,0,0,1,1.213-1.284h2.325v0l.017,0h1.191a2.253,2.253,0,0,1,2.185,2.315h0a2.252,2.252,0,0,1-2.185,2.314h-.8a.646.646,0,0,0-.683.711v4.022A.56.56,0,0,0,511.254,408.845Z"
                    transform="translate(0 0)"
                    fill="none"
                    stroke="#73ad49"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="1.5"
                />
            </g>
        </svg>
    );
}
