import { combineEpics } from 'redux-observable';
import { Action } from 'ts-action';
import { ofType } from 'ts-action-operators';
import { from, Observable, of } from 'rxjs';
import { switchMap, map, catchError, withLatestFrom } from 'rxjs/operators';
import { Client, client as braintreeClient, PayPalCheckout, paypalCheckout, dataCollector } from 'braintree-web';

import {
    setupPaypalButton,
    setupPaypalButtonError,
    setupPaypalButtonSuccess,
} from '../actions/financialActions';
import { RootState } from '../reducers';
import { selectCart } from '../selectors/cart';
import { CURRENCY } from 'components/common/constants';
import { PaymentConstants } from 'models/financial';
import { selectOrder } from 'store/selectors/order';
import { showSnackbarError } from 'components/common/Snackbar/SnackbarHelper';
import { selectProductCriteriaState } from '../selectors/productCriteria';
import { sendGACheckout } from 'components/Analytics/GoogleAnalyticsEcommerce';


export const setupPaypalButton$ = (action$: Observable<Action>, state$: Observable<RootState>) =>
    action$.pipe(
        ofType(setupPaypalButton),
        withLatestFrom(
            state$.pipe(map(selectCart)),
            state$.pipe(map(selectOrder)),
            state$.pipe(map(selectProductCriteriaState))
        ),
        switchMap(([action, cart, order, productCriteria]) => {
            const {
                containerId,
                totalAmount,
                placeOrderFunction,
                cartProcessFunction,
                placeFinalOrderFunction,
                finalInstallment,
                isOrderBased,
                brainTreeClientToken,
            } = action.payload;
            const amount = totalAmount ? totalAmount : isOrderBased ? order.totalDue : cart.prices?.grandTotal;
            return from(
                braintreeClient.create({
                    // @ts-ignore
                    authorization: brainTreeClientToken,
                })
            ).pipe(
                switchMap((clientInstance: Client) => {
                    return from(
                        paypalCheckout.create({
                            client: clientInstance,
                        })
                    ).pipe(
                        // @ts-ignore
                        switchMap((paypalCheckoutInstance) =>
                            // @ts-ignore
                            paypalCheckoutInstance.loadPayPalSDK({
                                currency: CURRENCY,
                                // components: 'buttons,messages,funding-eligibility',
                                intent: PaymentConstants.PAYPAL_INTENT,
                            })
                        ),
                        switchMap((paypalCheckoutInstance: PayPalCheckout) => {
                            const container = document.querySelector(`#${containerId}`);
                            if (container) {
                                const paypal = window.paypal;
                                // @ts-ignore
                                const fundingSources = [paypal.FUNDING.PAYPAL];
                                fundingSources.forEach((fundingSource) => {
                                    // @ts-ignore
                                    const button = paypal.Buttons({
                                        style: {
                                            height: 40,
                                            // @ts-ignore
                                            shape: 'pill',
                                            // @ts-ignore
                                            label: 'pay',
                                        },
                                        enableStandardCardFields: true,
                                        // @ts-ignore
                                        fundingSource: fundingSource,
                                        createOrder: () => {
                                            sendGACheckout(
                                                'Paypal',
                                                productCriteria?.promotionCode ? 'Marketing Campaign' : 'Product Search'
                                            );
                                            cartProcessFunction(true);
                                            const payment = paypalCheckoutInstance.createPayment({
                                                // @ts-ignore
                                                flow: PaymentConstants.PAYPAL_FLOW, // Required
                                                // @ts-ignore
                                                intent: PaymentConstants.PAYPAL_INTENT,
                                                amount: amount, // Required
                                                currency: CURRENCY, // Required, must match the currency passed in with loadPayPalSDK

                                                enableShippingAddress: true,
                                                shippingAddressEditable: false,
                                                shippingAddressOverride: {
                                                    recipientName: isOrderBased
                                                        ? `${order.billingAddress?.firstName} ${order.billingAddress?.lastName}`
                                                        : `${cart.billingAddress?.firstname} ${cart.billingAddress?.lastname}`,
                                                    // @ts-ignore
                                                    line1: isOrderBased
                                                        ? order.billingAddress?.address1
                                                        : cart.billingAddress?.address1,
                                                    line2: isOrderBased
                                                        ? order.billingAddress?.address2
                                                        : cart.billingAddress?.address2,
                                                    // @ts-ignore
                                                    city: isOrderBased
                                                        ? order.billingAddress?.city
                                                        : cart.billingAddress?.city,
                                                    // @ts-ignore
                                                    countryCode: isOrderBased
                                                        ? order.billingAddress?.country
                                                        : cart.billingAddress?.country,
                                                    // @ts-ignore
                                                    postalCode: isOrderBased
                                                        ? order.billingAddress?.postalCode
                                                        : cart.billingAddress?.postcode,
                                                    // @ts-ignore
                                                    state: isOrderBased
                                                        ? order.billingAddress?.region
                                                        : cart.billingAddress?.region,
                                                    phone: isOrderBased
                                                        ? order.billingAddress?.telephone
                                                        : cart.billingAddress?.telephone,
                                                },
                                            });
                                            return payment;
                                        },
                                        // @ts-ignore
                                        onApprove: (data, actions) => {
                                            // @ts-ignore
                                            return paypalCheckoutInstance
                                                .tokenizePayment(data)
                                                .then((payload) => {
                                                    dataCollector
                                                        .create({
                                                            client: clientInstance,
                                                            paypal: true,
                                                        })
                                                        .then((dataCollectorInstance) => {
                                                            // Place order with device_data
                                                            //creating a payment method obj
                                                            const paymentMethod = {
                                                                method: totalAmount
                                                                    ? PaymentConstants.PARTIAL_PAYMENT
                                                                    : PaymentConstants.PAYPAL_CODE,
                                                                ...(totalAmount && {
                                                                    amount: `${totalAmount}`,
                                                                }),
                                                                code: PaymentConstants.PAYPAL_CODE,
                                                                additionalData: {
                                                                    payment_method_nonce: payload.nonce,
                                                                    device_data: dataCollectorInstance.deviceData,
                                                                },
                                                            };
                                                            if (isOrderBased) {
                                                                finalInstallment
                                                                    ? placeFinalOrderFunction(
                                                                          order,
                                                                          paymentMethod,
                                                                          true
                                                                      )
                                                                    : placeFinalOrderFunction(order, paymentMethod);
                                                            } else {
                                                                placeOrderFunction(cart, paymentMethod);
                                                            }
                                                        })
                                                        .catch((error) => {
                                                            console.error(
                                                                'dataCollector error then placd order without device_data',
                                                                error
                                                            );
                                                            // Place order without device_data
                                                            //creating a payment method obj
                                                            const paymentMethod = {
                                                                method: totalAmount
                                                                    ? PaymentConstants.PARTIAL_PAYMENT
                                                                    : PaymentConstants.PAYPAL_CODE,
                                                                ...(totalAmount && {
                                                                    amount: `${totalAmount}`,
                                                                }),
                                                                code: PaymentConstants.PAYPAL_CODE,
                                                                additionalData: {
                                                                    payment_method_nonce: payload.nonce,
                                                                },
                                                            };
                                                            if (isOrderBased) {
                                                                finalInstallment
                                                                    ? placeFinalOrderFunction(
                                                                          order,
                                                                          paymentMethod,
                                                                          true
                                                                      )
                                                                    : placeFinalOrderFunction(order, paymentMethod);
                                                            } else {
                                                                placeOrderFunction(cart, paymentMethod);
                                                            }
                                                        });
                                                })
                                                .catch((error) => {
                                                    cartProcessFunction(false, error);
                                                    console.error('tokenizePayment Error', error);
                                                });
                                        },
                                        // @ts-ignore
                                        onCancel: (data) => {
                                            cartProcessFunction(false);
                                            window.scrollTo(0, 0);
                                        },
                                        // @ts-ignore
                                        onError: (err) => {
                                            cartProcessFunction(false);
                                            let msg = 'Something went wrong. Please try again';
                                            // @ts-ignore
                                            switch (err.code) {
                                                case 'PAYPAL_INVALID_PAYMENT_OPTION':
                                                    msg = 'Invalid billing address';
                                            }
                                            window.scrollTo(0, 0);
                                            showSnackbarError(msg);
                                        },
                                        // onInit is called when the button first renders
                                        onInit: (actions:any) => {
                                            cartProcessFunction(false);
                                            actions.enable();
                                            // if (isFormValid) {
                                            //     actions.enable();
                                            // } else {
                                            //     actions.disable();
                                            // }
                                        },
                                    });
                                    // Check if the button is eligible
                                    // @ts-ignore
                                    if (button.isEligible()) {
                                        // Render the standalone button for that funding source
                                        button.render(`#${containerId}`);
                                    }
                                });
                            }
                            return of(setupPaypalButtonSuccess());
                        }),
                        catchError((error) => {
                            console.error('Unable to setup paypal button');
                            return of(setupPaypalButtonError(error));
                        })
                    );
                }),
                catchError((err) => {
                    cartProcessFunction(false);
                    return [];
                })
            );
        }),
        catchError((error) => {
            console.error('Unable to setup paypal button');
            return of(setupPaypalButtonError(error));
        })
    );

export default combineEpics(setupPaypalButton$);
