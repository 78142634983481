import React, { useEffect, useRef } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { selectProductCriteriaState } from '../../store/selectors/productCriteria';
import WizardHomeInfo from './WizardHomeInfo';
import ContactPageDialog from './EmailNameCapture';
import { resetProductCriteria, setProductCriteria } from '../../store/actions/productCriteriaActions';
import { AppRoute } from '../../models/route';
import {
    searchProduct,
    searchProductIAQ,
    searchProductSuccess,
    searchSecondProduct,
} from 'store/actions/productSearchActions';
import {
    DuctlessNumberOfRooms,
    HomeSystem,
    NumberOfHVAC,
    PackageReplacementType,
    ProductCriteria,
} from 'models/productCriteria';
import { selectIAQLoading, selectNoProduct, selectProductLoading, selectProductsIAQ } from '../../store/selectors/productSearch';
import { selectCart } from 'store/selectors/cart';
import {
    hideCartNotification,
    hideOrderNotification,
    showCartNotification,
    showOrderNotification,
} from 'store/actions/notificationAction';
import Cookies from 'js-cookie';
import { IS_ALIVE_COOKIE } from 'components/common/constants';
import { selectOrder } from 'store/selectors/order';
import { selectMultipleOpenProjects } from 'store/selectors/storeSelector';
import { showPayNow } from 'pages/order/order-utils';
import { navigateThankyouPage } from 'store/actions/navigateActions';

function AboutYourHome() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const criteria = useSelector(selectProductCriteriaState);
    let loading = useSelector(selectProductLoading);
    let loadingIAQ = useSelector(selectIAQLoading);
    const cart = useSelector(selectCart);
    const productsIAQ = useSelector(selectProductsIAQ);
    const isNoProductPopup = useSelector(selectNoProduct);
    const multipleOpenProjects = useSelector(selectMultipleOpenProjects);
    const emptyCriteria: ProductCriteria = {};
    const order = useSelector(selectOrder);
    let mounted: any = useRef();

    useEffect(() => {
        dispatch(navigateThankyouPage({ isNoProduct: false }));
    }, [dispatch]);

    useEffect(() => {
        if (!mounted.current) {
            mounted.current = true;
            if (cart.items && cart.items?.length > 0) {
                dispatch(hideOrderNotification());
                dispatch(showCartNotification());
            } else if (order.status && showPayNow(order.status) && !multipleOpenProjects) {
                dispatch(hideCartNotification());
                dispatch(showOrderNotification());
            }
        }
    });
    const [isContactPagePopUpOpen, setContactPagePopUpOpen] = React.useState(false);
    const secondSystemWizard: any = useLocation();
    const secondSystem = secondSystemWizard.state && secondSystemWizard.state.isSecondSystem;
    const addSecondSystemFromCartParam: any = useLocation();
    const addSecondSystemFromCart =
        addSecondSystemFromCartParam.state && addSecondSystemFromCartParam.state.isAddSystemFromCartPage;
    return (
        <>
            <WizardHomeInfo
                criteria={criteria}
                loading={isContactPagePopUpOpen ? false : (loading || loadingIAQ)}
                isDoubleSystem={secondSystem}
                addSecondSystemFromCart={addSecondSystemFromCart}
                onNext={(payload) => {
                    const isCookieAlive = Cookies.get(IS_ALIVE_COOKIE);
                    dispatch(setProductCriteria(payload));
                    //search product
                    if (
                        payload.numberOfHVAC !== NumberOfHVAC.Multiple &&
                        payload.packageReplacementType !== PackageReplacementType.ElectricalHeatAC &&
                        payload.ductlessNumberOfRooms !== DuctlessNumberOfRooms.Multiple
                    ) {
                        if (
                            payload.homeSystem !== HomeSystem.Ductless &&
                            payload.homeSystem !== HomeSystem.IAQ &&
                            (!productsIAQ || productsIAQ?.length === 0)
                        ) {
                            dispatch(searchProductIAQ({ ...emptyCriteria, homeSystem: HomeSystem.IAQ }));
                        }
                        secondSystem
                            ? dispatch(searchSecondProduct({ shouldRedirect: isCookieAlive ? true : false }))
                            : dispatch(
                                  searchProduct({
                                      shouldRedirect: isCookieAlive ? true : false,
                                      addSecondSystemFromCart: addSecondSystemFromCart,
                                  })
                              );
                    }
                    //search product not required scenario => No Product case  && already logged/signedup
                    else if (isCookieAlive) {
                        dispatch(searchProductSuccess({ products: [], shouldRedirect: true }));
                    }
                    if (!isCookieAlive) {
                        setContactPagePopUpOpen(true);
                    }
                }}
                onExit={() => {
                    dispatch(resetProductCriteria());
                    navigate(AppRoute.Home);
                }}
            >
                {isContactPagePopUpOpen && !isNoProductPopup && (
                    <ContactPageDialog
                        handleClose={() => {
                            setContactPagePopUpOpen(false);
                        }}
                        isOpen={isContactPagePopUpOpen}
                    />
                )}
            </WizardHomeInfo>
        </>
    );
}

export default AboutYourHome;
