import React from 'react';
import { Theme, useTheme } from '@mui/material/styles';
import ContentContainer from '../../components/ContentContainer';
import Container from '@mui/material/Container';
import WizardHeader from '../../components/WizardHeader';
import Grid from '@mui/material/Grid';
import { Heading } from '../../components/Heading';
import { Paragraph } from '../../components/Paragraph';
import { Product } from '../../models/product';
import { DoubleSystemWizardState, HomeSystem, NumberOfHVAC, ProductCriteria } from '../../models/productCriteria';
import ProductDetail from '../../components/ProductDetail';
import LoadingOverlay from '../../components/common/LoadingOverlay';
import { theme } from '../../themes/theme';
import { useDispatch, useSelector } from 'react-redux';
import { AddressType } from 'models/cart';
import { selectCart, selectProceedToCart } from 'store/selectors/cart';
import { Box, Hidden, Icon, Link, useMediaQuery } from '@mui/material';
import Shield from 'components/icons/Shield';
import Image from 'components/icons/Image';
import WizardNotification from 'components/WizardNotification';
import { Button } from 'components/Button';
import WizardCriteriaFooterNew from 'components/WizardCriteriaFooterNew';
import { clearWizarQuestionData, setDoubleSystemFlag } from 'store/actions/productCriteriaActions';
import { getAddressListAction } from 'store/actions/addressActions';
import { useNavigate } from 'react-router-dom';
import { AppRoute } from 'models/route';
import { selectShippingAddressStatus } from 'store/selectors/selection';
import { redirectToCartPage } from 'store/actions/cartActions';
import { selectProductCriteriaState } from 'store/selectors/productCriteria';
import EquipmentFeature from './EquipmentFeature';
import HeadlineParagraph from 'components/HeadlineParagraph';
import { AddressContainer } from 'pages/user/ShippingAddress/AddressContainer';
import { SXCssObject } from 'models/ThemeImages';
import DisclaimerText from './DisclaimerText';

const createCssObject = (theme: Theme): SXCssObject => ({
    contentContainer: {
        minHeight: `calc(100vh - ${theme.spacing(8)}px)`,
    },
    header: {
        paddingTop: {
            xs: '30px',
            sm: '43px',
            md: '72px',
        },
        paddingBottom: {
            xs: '20px',
            md: '30px',
        },
    },

    divider: {
        paddingTop: theme.spacing(1.5),
        paddingBottom: theme.spacing(1.5),
    },
    dividerFinalMobile: {
        paddingTop: theme.spacing(4),
    },
    dividerFinal: {
        paddingTop: theme.spacing(7.2),
    },
    noProductList: {
        textAlign: 'center',
        minHeight: '100vh',
    },
    actions: {
        position: {
            sm: 'relative',
        },
        left: {
            sm: 0,
        },
        bottom: {
            sm: 0,
        },
        width: {
            sm: '100%',
        },
        padding: {
            sm: 0,
        },
        height: {
            sm: '80px',
        },
        backgroundColor: 'white',
    },
    helpFooter: {
        backgroundColor: theme.palette.common.white,
        padding: theme.spacing(1, 0, 1, 0),
    },
    buttonSection: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    img: {
        marginBottom: '-15px',
        padding: {
            xs: '0px !important ',
            sm: '50px 0px 0px 0px !important',
        },
        display: {
            xs: 'flex ',
            sm: 'inherit',
        },
        justifyContent: {
            xs: 'center',
            sm: 'inherit',
        },
    },
    checkIcon: {
        color: theme.palette.primary.main,
        verticalAlign: 'middle',
        fontSize: '15px',
        fontWeight: 900,
    },
    productDetailLabel: {
        paddingLeft: {
            xs: theme.spacing(0),
            sm: theme.spacing(1),
            lg: 'inherit',
        },
    },
    iaqContent: {
        marginTop: {
            sm: '20px !important',
        },
        paddingLeft: {
            sm: '0px !important',
            md: 'inherit',
        },
    },
    iaqBlock: {
        border: `1px solid ${theme.palette.primary.contrastText}`,
        backgroundColor: theme.palette.primary.light,
        margin: '30px 0px 10px 0px',
        width: '100%',
        height: {
            lg: '265px',
        },
    },
});

interface Props {
    loading: boolean;
    products?: Product[];
    criteria: ProductCriteria;
    mainSku?: string;
    cartMainSku?: string;
    selectionMainSku?: string;
    editMainProductSku?: string;
    onSelectProduct: (product: Product) => void;
    onSelectIAQ: () => void;
    onContinue: () => void;
    isSecondSystemFlow?: boolean;
    isEquipmentOnly: boolean;
}
function EquipmentOptions(props: Props) {
    const { products, loading, cartMainSku, selectionMainSku, isSecondSystemFlow, editMainProductSku, mainSku, isEquipmentOnly } = props;
    const cssObject: SXCssObject = createCssObject(useTheme());
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const cartData = useSelector(selectCart);
    const shippingAddressCaptured = useSelector(selectShippingAddressStatus);
    const proceedToCart = useSelector(selectProceedToCart);
    const criteria = useSelector(selectProductCriteriaState);
    const shouldRedirectToSecondSystemFlow =
        ((!isSecondSystemFlow && criteria.numberOfHVAC === NumberOfHVAC.Double && !cartMainSku && !selectionMainSku) ||
            editMainProductSku) &&
        proceedToCart;
    const systemType =
        props.products &&
        props.products.length > 0 &&
        props.products[0].productAttributes &&
        props?.products[0].productAttributes.systemType;
    const isXs = useMediaQuery(theme.breakpoints.only('xs'));
    const isSm = useMediaQuery(theme.breakpoints.only('sm'));
    const isMd = useMediaQuery(theme.breakpoints.only('md'));
    let homeSystem = '';
    const iaqSystem = systemType && systemType?.indexOf('IAQ') !== -1 ? true : false;
    const ductlessSystem = systemType && systemType?.indexOf('Ductless') !== -1 ? true : false;
    if (systemType) {
        if (iaqSystem) {
            homeSystem = 'Indoor Air Quality Package';
        } else if (ductlessSystem) {
            homeSystem = 'Ductless System';
        } else if (
            systemType === 'AC & Coil Only' ||
            systemType === 'Furnace Upgrade Only' ||
            systemType === 'Furnace Only'
        ) {
            homeSystem = 'Partial HVAC';
        } else homeSystem = 'Complete HVAC';
    }
    const componentId = 'equipment-options';
    const [isContinueEnabled, setIsContinueEnabled] = React.useState(
        cartMainSku || selectionMainSku || editMainProductSku || mainSku
    );
    const shopIAQButton = (
        <Button
            id={`${componentId}-shop-iaq-btn`}
            color="primary"
            onClick={() => props.onSelectIAQ()}
            disabled={!isContinueEnabled}
            isFullWidth={isSm}
        >
            shop iaq
        </Button>
    );
    const feature = (text: string) => (
        <Grid container spacing={1} sx={cssObject.productDetailLabel}>
            <Grid item>
                <Icon sx={cssObject.checkIcon}>check_icon</Icon>
            </Grid>
            <Grid item xs={10}>
                <Paragraph fontSizeVerySmall={true} size={'small'} id={`iaq-description`}>
                    {text}
                </Paragraph>
            </Grid>
        </Grid>
    );

    const shouldShowAddressPopup =
        (!cartData.shippingAddress || !shippingAddressCaptured) &&
        (isSecondSystemFlow || criteria.numberOfHVAC === NumberOfHVAC.Single) &&
        !cartMainSku;
    const handleSubmit = () => {
        if (shouldRedirectToSecondSystemFlow) {
            dispatch(clearWizarQuestionData({ numberOfHVAC: NumberOfHVAC.Double }));
            dispatch(setDoubleSystemFlag({ doubleSystemFlag: DoubleSystemWizardState.Second }));
            navigate(AppRoute.AboutYourHome, { state: { isSecondSystem: true } });
        } else if (proceedToCart) {
            if (shouldShowAddressPopup) {
                dispatch(getAddressListAction(true));
            } else {
                dispatch(redirectToCartPage());
            }
        } else {
            props.onContinue();
        }
    };

    return (
        <Grid>
            <Grid sx={cssObject.contentContainer}>
                <AddressContainer
                    flow={AddressType.Home}
                    pageToRedirect={AppRoute.CartPage}
                    isFirstTimeCapture={true}
                />
                <LoadingOverlay open={loading} />
                <WizardHeader shadow={false} showRestartButton={cartMainSku ? false : true} />
                <ContentContainer disableGrid background={'bg2'}>
                    <Container maxWidth={'lg'} disableGutters>
                        <ContentContainer sx={cssObject.header}>
                            <Grid container>
                                {products && products.length > 0 && (
                                    <>
                                        <Grid item xs={12} container justifyContent={'space-around'}>
                                            <Grid item xs={12} style={{ padding: '0px 5px' }}>
                                                <Heading
                                                    label={`Recommendations For ${homeSystem ? homeSystem : 'HVAC'}`}
                                                />
                                            </Grid>
                                        </Grid>
                                        <Grid container style={{ padding: '30px 0px 5px 0px' }}>
                                            <Grid item xs={12} md={8}>
                                                {props.criteria.homeSystem !== HomeSystem.Ductless && (
                                                    <Paragraph>
                                                        Goodman brand systems are{' '}
                                                        <Link
                                                            href={
                                                                'https://www.goodmanmfg.com/resources/customer-reviews'
                                                            }
                                                            target={'_blank'}
                                                        >
                                                            {'highly rated by consumers'}
                                                        </Link>
                                                        &nbsp;and proudly made in the USA.{' '}
                                                        {isEquipmentOnly &&
                                                            `All prices 
                                                        INCLUDE 10 Year Limited System Warranty, taxes.`}
                                                    </Paragraph>
                                                )}

                                                <Paragraph semibold>
                                                    {!isEquipmentOnly &&
                                                        `All prices INCLUDE expert installation, 10 Year Limited System
                                                    Warranty, 1 Year Full Labor Warranty, taxes, and our 100% Homeowner
                                                    Satisfaction Pledge. Our expert HVAC installers will confirm your
                                                    selection is the best fit for your home prior to final purchase and
                                                    installation.`}
                                                </Paragraph>
                                            </Grid>
                                        </Grid>
                                    </>
                                )}
                                {products && products.length > 0 && <EquipmentFeature products={products} />}

                                {(!products || !products.length) && (
                                    <Grid item xs={12} container spacing={2} sx={cssObject.noProductList}>
                                        <Grid item xs={12}>
                                            <Heading
                                                variant={'h2'}
                                                label={'Could not find any products based on your requirements.'}
                                            />
                                        </Grid>
                                    </Grid>
                                )}
                            </Grid>
                        </ContentContainer>
                    </Container>
                </ContentContainer>

                {products && products.length > 0 && (
                    <ContentContainer disableGrid background={'bg2'}>
                        <Container maxWidth={'lg'} disableGutters>
                            <ContentContainer>
                                <Grid sx={cssObject.productList} container>
                                    {products?.map((product, index) => (
                                        <Grid key={index} item xs={12} container direction={'column'}>
                                            <ProductDetail
                                                item={product}
                                                selectButton={!proceedToCart}
                                                onSelect={(isButtonSelected, componentSku) => {
                                                    isButtonSelected
                                                        ? setIsContinueEnabled(componentSku)
                                                        : setIsContinueEnabled('');
                                                    isButtonSelected && props.onSelectProduct(product);
                                                }}
                                                selectedButtonSku={isContinueEnabled}
                                            />
                                            {products?.length !== index + 1 && (
                                                <Grid item sx={cssObject.divider}>
                                                    {''}
                                                </Grid>
                                            )}
                                        </Grid>
                                    ))}
                                    {!iaqSystem && !ductlessSystem && !cartMainSku && !isEquipmentOnly && (
                                        <Grid item sx={cssObject.iaqBlock}>
                                            <ContentContainer size={'small'}>
                                                <Grid container spacing={isMd ? 7 : isXs ? 2 : 5}>
                                                    <Grid item xs={12} sm={6} md={3} sx={cssObject.img}>
                                                        <Image />
                                                    </Grid>
                                                    <Grid
                                                        item
                                                        xs={12}
                                                        sm={6}
                                                        md={7}
                                                        container
                                                        lg={7}
                                                        sx={cssObject.iaqContent}
                                                    >
                                                        <Grid item direction={'column'}>
                                                            <HeadlineParagraph
                                                                headlineSize={'h4'}
                                                                label={`Learn about Indoor Air Quality Products:`}
                                                            />
                                                            <br />
                                                            {feature(
                                                                'Remove allergens, dust, dander, bacteria and some viruses'
                                                            )}
                                                            {feature(
                                                                'Save energy by improving airflow and ventilation'
                                                            )}
                                                            {feature('Save money on replacement filters')}
                                                            {feature('Control mold and mildew')}
                                                        </Grid>
                                                        {isSm && (
                                                            <Grid item sx={cssObject.iaqContent}>
                                                                {shopIAQButton}
                                                            </Grid>
                                                        )}
                                                    </Grid>
                                                    <Hidden only={['sm']}>
                                                        <Grid
                                                            item
                                                            xs={12}
                                                            md={2}
                                                            lg={2}
                                                            sx={cssObject.iaqContent}
                                                            container
                                                        >
                                                            {shopIAQButton}
                                                        </Grid>
                                                    </Hidden>
                                                </Grid>
                                            </ContentContainer>
                                        </Grid>
                                    )}
                                    <DisclaimerText />
                                </Grid>
                            </ContentContainer>
                        </Container>
                    </ContentContainer>
                )}
            </Grid>
            <br />
            {!props.isSecondSystemFlow && !selectionMainSku && <WizardNotification isFixed={false} />}
            <br />
            <Box sx={[cssObject.actions, { display: 'flex', alignItems: 'center' }]}>
                <Container disableGutters style={{ backgroundColor: 'white' }}>
                    <ContentContainer>
                        <Grid container>
                            <Grid container sx={cssObject.helpFooter} alignItems={'center'} item xs={12} sm={6} md={8}>
                                {!isEquipmentOnly && (
                                    <>
                                        <Grid item display={'flex'}>
                                            <Shield />
                                        </Grid>
                                        <Grid item xs={10}>
                                            <Paragraph size="small" align="left">
                                                Did you know your new installed HVAC system will be protected by our
                                                1-Year Full Labor Warranty!
                                            </Paragraph>
                                        </Grid>
                                    </>
                                )}
                            </Grid>
                            <Grid spacing={1} item xs={12} sm={6} md={4} sx={cssObject.buttonSection}>
                                <WizardCriteriaFooterNew
                                    nextLabel={
                                        shouldRedirectToSecondSystemFlow
                                            ? 'Continue with Another Replacement'
                                            : 'continue'
                                    }
                                    onNext={handleSubmit}
                                    isSmallerFont={shouldRedirectToSecondSystemFlow ? true : false}
                                    disabled={!isContinueEnabled}
                                />
                            </Grid>
                        </Grid>
                    </ContentContainer>
                </Container>
            </Box>
        </Grid>
    );
}

export default EquipmentOptions;
