import React, { useRef } from 'react';
import { showProduct } from 'store/actions/productSearchActions';
import { Form, Formik, FormikValues } from 'formik';
import { Theme, useTheme } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import { Button } from 'components/Button';
import ContentContainer from 'components/ContentContainer';
import FormikTextField from 'components/FormikTextField';
import { Box, Dialog, DialogContent } from '@mui/material';
import OtpInput from 'react-otp-input';
import OtpErrorIcon from 'components/icons/OtpErrorIcon';
import PhoneField from 'components/FormikPhoneField';
import { validate as validatePhone } from '../../validations/phoneNumber';
import { LoginRequest, LoginValidationObject, SignUpRequest } from 'models/loginModel';
import { isEmailExists, sendLoginOtpToEmail, sendLoginRequest, sendSignUpRequest } from 'store/services/loginServices';
import { setIsAliveCookie } from 'index';
import { loginSuccess, signUpSuccess } from 'store/actions/loginActions';
import clsx from 'clsx';
import { LoginMessages } from 'components/common/constants';
import LoadingOverlay from 'components/common/LoadingOverlay';
import MuiDialogTitle from '@mui/material/DialogTitle';
import Close from 'components/icons/Close';
import { useDispatch, useSelector } from 'react-redux';
import { createContact } from 'store/actions/hubspotActions';
import { NumberOfHVAC } from 'models/productCriteria';
import { selectProductCriteriaState } from 'store/selectors/productCriteria';
import { selectProducts } from 'store/selectors/productSearch';
import { Heading } from 'components/Heading';
import { Paragraph } from 'components/Paragraph';
import { showSnackbarError } from 'components/common/Snackbar/SnackbarHelper';
import { checkDomainAndLoginSchema, schemaEmailNameCapture } from 'pages/user/login-schema';
import config from 'config';
import { get } from 'lodash';
import { selectStoreFeatures } from 'store/selectors/storeSelector';
import { SXCssObject } from 'models/ThemeImages';
import { invokeGAWizardPageLogin, invokeGAWizardPageSignUp } from 'components/Analytics/GoogleAnalyticsTagging';
import styles from 'components/common/CommonStyle.module.css';

const createCssObject = (theme: Theme): SXCssObject => ({
    root: { padding: '15px 0px', maxWidth: '420px' },
    main: {
        display: 'flex',
        flexDirection: 'column',
        marginBottom: {
            md: theme.spacing(0),
        },
    },
    b: {
        fontWeight: theme.typography.fontWeightMedium,
    },
    modalContent: {
        overflow: 'hidden',
    },

    otpSectionStyle: {
        padding: '0px 0px 10px',
    },

    signupInfoStyle: {
        display: 'flex',
        justifyContent: 'center',
        marginTop: '10px',
        marginBottom: '15px',
        paddingLeft: '10px',
        alignItems: 'center',
    },
    otpErrorSectionStyle: {
        display: 'flex',
        alignItems: 'center',
        marginTop: '10px',
        marginBottom: '15px',
    },
    otpErrorTextStyle: {
        paddingLeft: '5px',
        display: 'flex',
        justifyContent: 'center',
    },
    modalTitle: {
        fontWeight: 'bold',
        paddingRight: '30px',
    },

    modalTitleContainer: {
        padding: '10px 27px 0px 24px',
        position: 'relative',
        marign: '0px',
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1.6),
        top: theme.spacing(0),
        color: theme.palette.grey[500],
        cursor: 'pointer',
    },
    actions: {
        position: {
            md: 'fixed',
        },
        left: {
            md: 0,
        },
        bottom: {
            md: 0,
        },
        width: {
            md: '100%',
        },
    },
    modalPaper: {
        borderRadius: '4px',
    },
    resendOtpSection: {
        textAlign: 'center',
        marginTop: '30px',
    },
    resendOtpStyle: {
        display: 'inline',
        color: theme.palette.primary.main,
        fontSize: '0.875rem',
        fontWeight: 'bold',
        cursor: 'pointer',
    },
    resendOtpText: {
        display: 'inline',
        fontWeight: theme.typography.fontWeightMedium,
    },
});

const OTP_RESEND_COUNTDOWN: number = 20;
interface FormValues {
    email: string;
    phone?: string;
    firstname?: string;
    lastname?: string;
}
interface ContactProps {
    isOpen: boolean;
    handleClose: () => void;
}
const ContactPageDialog = (props: ContactProps) => {
    const [isLoginModalShown, setLoginModalVisibility] = React.useState(false);
    const [isSignUpModalShown, setSignUpModalVisibility] = React.useState(false);
    const dispatch = useDispatch();
    const storeFeatures = useSelector(selectStoreFeatures);

    const cssObject: SXCssObject = createCssObject(useTheme());

    const [isMounted, setIsMounted] = React.useState(false);

    const [loader, setLoader] = React.useState(false);
    const [isLoginInputFieldsVisible, setLoginInputFieldsVisibility] = React.useState(true);
    const [isLoginOtpFieldVisible, setLoginOtpFieldVisibility] = React.useState(false);

    const [validatingPhone, setValidatingPhone] = React.useState(false);
    const [loginOtpValue, setLoginOtpValue] = React.useState('');
    const [isLoginOtpError, setLoginOtpError] = React.useState(false);

    const [showPhoneValidMessage, setShowPhoneValidMessage] = React.useState(false);
    const [checkedPhones, setCheckedPhones] = React.useState(new Array<{ phone: string; valid: boolean }>());

    const [enteredEmail, setEnteredEmail] = React.useState('');

    const [isOtpTimerActive, setIsOtpTimerActive] = React.useState(false);

    const [timeLeft, setTimeLeft] = React.useState(OTP_RESEND_COUNTDOWN);
    const criteria = useSelector(selectProductCriteriaState);
    const productsList: any = useSelector(selectProducts);
    React.useEffect(() => {
        setLoginModalVisibility(true);
    }, []);
    React.useEffect(() => {
        setIsMounted(true);
        // do componentDidMount logic
        mounted.current = true;

        // do componentDidUpdate logic
        if (!isOtpTimerActive) return;
        if (!timeLeft) {
            setIsOtpTimerActive(false);
            return;
        }
        const intervalId = setInterval(() => {
            setTimeLeft(timeLeft - 1);
        }, 1000);

        return () => clearInterval(intervalId);
    }, [isOtpTimerActive, timeLeft, isLoginInputFieldsVisible, isLoginOtpFieldVisible]);
    const shouldCheckDomain = get(storeFeatures, 'signUpEmailDomainCheck.enable', false);
    const validDomains = get(storeFeatures, 'signUpEmailDomainCheck.domains', '');
    const errorMsg = get(config, 'content.error.invalidDomainErrorMsg', '');

    const loginValidationObj: LoginValidationObject = {
        validDomains,
        shouldCheckDomain,
        errorMsg,
    };
    let mounted: any = useRef();
    //helper methods
    const validate = async (values: FormikValues) => {
        // validate phoneNumber
        const validatePhoneMsg = await validatePhone(values.phone?.replace(/_|\./gi, ''), {
            isMounted: isMounted,
            setShowValidMessage: setShowPhoneValidMessage,
            setValidating: setValidatingPhone,
            setCheckedPhones: setCheckedPhones,
            checkedPhones: checkedPhones,
        });
        if (validatePhoneMsg) {
            return {
                phone: validatePhoneMsg,
            };
        }
    };
    const showSignUpForm = () => {
        return (
            <Formik
                initialValues={{
                    firstname: '',
                    lastname: '',
                    email: enteredEmail || '',
                    phone: '',
                }}
                onSubmit={handleSignUpSubmit}
                validateOnMount={true}
                validationSchema={schemaEmailNameCapture}
                validate={validate}
                initialTouched={{
                    phone: true,
                }}
            >
                {({ submitForm, isValid }) => (
                    <ContentContainer disableGrid background={'bg3'}>
                        <Container maxWidth={'lg'} disableGutters>
                            <Grid container spacing={4}>
                                <Grid item xs={12}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12}>
                                            <Grid container spacing={2}>
                                                <Grid item xs={12}>
                                                    <FormikTextField
                                                        id={'email'}
                                                        name="email"
                                                        label={'Email Address *'}
                                                        type={'email'}
                                                        placeholder={'Email Address'}
                                                        shouldDisableInputField={!!enteredEmail}
                                                    />
                                                </Grid>
                                                <Box sx={cssObject.signupInfoStyle}>
                                                    <OtpErrorIcon />
                                                    <Box sx={cssObject.otpErrorTextStyle}>
                                                        <Paragraph fontSizeVerySmall>
                                                            No account found with above email, Please signup below
                                                        </Paragraph>
                                                    </Box>
                                                </Box>
                                                <Grid item xs={12}>
                                                    <FormikTextField
                                                        id={'firstname'}
                                                        name="firstname"
                                                        label={'First Name *'}
                                                        placeholder={'First Name'}
                                                    />
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <FormikTextField
                                                        id={'lastname'}
                                                        name="lastname"
                                                        label={'Last Name *'}
                                                        placeholder={'Last Name'}
                                                    />
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <PhoneField
                                                        id={'phone'}
                                                        name={'phone'}
                                                        shouldDisableInputField={false}
                                                        label={'Phone Number *'}
                                                        placeholder={'000.000.0000'}
                                                        fullWidth
                                                        enableErrorDisplay={showPhoneValidMessage}
                                                        validating={validatingPhone}
                                                    />
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <Button
                                                        isFullWidth={true}
                                                        color="primary"
                                                        isSmallerFontForSmallerDevice
                                                        disabled={!isValid}
                                                        onClick={submitForm}
                                                    >
                                                        Continue
                                                    </Button>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Container>
                    </ContentContainer>
                )}
            </Formik>
        );
    };

    const showLoginForm = () => {
        return (
            <Formik
                initialValues={{
                    email: '',
                }}
                onSubmit={handleLoginSubmit}
                validateOnMount={true}
                validationSchema={isLoginInputFieldsVisible ? checkDomainAndLoginSchema(loginValidationObj) : null}
            >
                {({ submitForm, isValid, errors }) => (
                    <Form>
                        <ContentContainer disableGrid background={'bg3'}>
                            <Container maxWidth={'lg'} disableGutters>
                                <Grid container spacing={4} justifyContent="center">
                                    <Grid item xs={12}>
                                        <Grid container spacing={2}>
                                            <Grid item xs={12} hidden={!isLoginInputFieldsVisible}>
                                                <FormikTextField
                                                    id="email"
                                                    name="email"
                                                    label="Email Address *"
                                                    type="email"
                                                    placeholder="Email Address"
                                                    enableErrorDisplay={shouldCheckDomain && errors.email === errorMsg}
                                                />
                                            </Grid>
                                            {isLoginOtpFieldVisible && (
                                                <Grid item container justifyContent="center">
                                                    <Box sx={cssObject.otpSectionStyle}>
                                                        <OtpInput
                                                            inputStyle={clsx(styles.otpInputStyle, {
                                                                [styles.otpInputFilledStyle]:
                                                                    loginOtpValue.length === 4,
                                                            })}
                                                            errorStyle={styles.errorStyle}
                                                            numInputs={4}
                                                            isInputNum={true}
                                                            value={loginOtpValue}
                                                            hasErrored={isLoginOtpError}
                                                            onChange={handleLoginOtpChange}
                                                        />
                                                    </Box>
                                                    {isLoginOtpError && (
                                                        <Box sx={cssObject.otpErrorSectionStyle}>
                                                            <OtpErrorIcon />
                                                            <Box sx={cssObject.otpErrorTextStyle}>
                                                                <Paragraph fontSizeVerySmall>
                                                                    Code Mismatch, please refer your mail
                                                                </Paragraph>
                                                            </Box>
                                                        </Box>
                                                    )}
                                                </Grid>
                                            )}
                                            <Grid item xs={12}>
                                                <Button
                                                    isFullWidth={true}
                                                    color="primary"
                                                    isSmallerFontForSmallerDevice
                                                    disabled={(() => {
                                                        if (isLoginInputFieldsVisible) {
                                                            return !isValid;
                                                        }
                                                        return isLoginOtpError || loginOtpValue.length < 4
                                                            ? true
                                                            : false;
                                                    })()}
                                                    onClick={submitForm}
                                                >
                                                    {isLoginInputFieldsVisible
                                                        ? 'Continue'
                                                        : 'Verify and Get Recommendations'}
                                                </Button>
                                                <Box sx={cssObject.resendOtpSection} hidden={isLoginInputFieldsVisible}>
                                                    {!isOtpTimerActive && (
                                                        <Box sx={cssObject.resendOtpStyle} onClick={handleResendOtp}>
                                                            RESEND OTP
                                                        </Box>
                                                    )}
                                                    {isOtpTimerActive && (
                                                        <Box>
                                                            <Paragraph semibold>RESEND OTP in {timeLeft}s</Paragraph>
                                                        </Box>
                                                    )}
                                                </Box>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Container>
                        </ContentContainer>
                    </Form>
                )}
            </Formik>
        );
    };
    const checkEmailAvailabilityForSignUp = async (values: FormValues) => {
        const signUpReq: SignUpRequest = {
            phone: values.phone?.replace(/\./g, ''),
            firstname: values.firstname,
            lastname: values.lastname,
            email: values.email,
        };
        setLoader(true);
        sendSignUpRequest(signUpReq).subscribe(
            (res: any) => {
                if (res.data.success) {
                    const customerInfo: any = {
                        firstname: res.data.firstname,
                        lastname: res.data.lastname,
                        email: res.data.email,
                        phone: res.data.phone,
                        isUserDetailAlreadyAvailable: false,
                        remark:
                            criteria.numberOfHVAC === NumberOfHVAC.Multiple
                                ? 'multiUnit'
                                : productsList && productsList.length < 1
                                ? 'noProduct'
                                : undefined,
                    };
                    invokeGAWizardPageSignUp();
                    setIsAliveCookie();
                    dispatch(signUpSuccess(res.data));
                    setLoginModalVisibility(false);
                    dispatch(showProduct({ isSecondSystem: false }));
                    dispatch(createContact(customerInfo));
                }
            },
            (err: any) => {
                setLoader(false);
                let msg = LoginMessages.MSG_FAILURE_REGISTER;
                try {
                    if (err.response.data.error.message) {
                        msg = err.response.data.error.message;
                    }
                } catch (e) {}
                showSnackbarError(msg);
            }
        );
    };

    const checkEmailAndVerifyLoginOtp = async (values: FormValues) => {
        setLoader(true);
        setEnteredEmail(values.email);
        const result = await isEmailExists({ email: values.email });
        setLoader(false);
        if (result.error) {
            showSnackbarError(LoginMessages.EMAIL_EXISTS_FAIL);
        } else if (result.success) {
            handleSendLoginOtpToEmail(values.email);
        } else {
            setSignUpModalVisibility(true);
            setLoginModalVisibility(false);
        }
    };
    const handleLoginSubmit = (values: FormValues) => {
        if (isLoginInputFieldsVisible) {
            checkEmailAndVerifyLoginOtp(values);
        } else {
            submitLoginOtp(values);
        }
    };
    const handleSignUpSubmit = (values: FormValues) => {
        checkEmailAvailabilityForSignUp(values);
    };
    const handleLoginOtpChange = (otp: string) => {
        setLoginOtpValue(otp);
        setLoginOtpError(false);
    };

    const submitLoginOtp = (values: FormValues) => {
        let loginReq: LoginRequest = {
            customerIdentifier: enteredEmail,
            otp: loginOtpValue,
        };
        setLoader(true);
        sendLoginRequest(loginReq).subscribe(
            (res: any) => {
                setLoader(false);
                if (res.data.success) {
                    const customerInfo: any = {
                        firstname: res.data.firstname,
                        lastname: res.data.lastname,
                        email: res.data.email,
                        phone: res.data.phone,
                        isUserDetailAlreadyAvailable: true,
                        remark:
                            criteria.numberOfHVAC === NumberOfHVAC.Multiple
                                ? 'multiUnit'
                                : productsList && productsList.length < 1
                                ? 'noProduct'
                                : undefined,
                    };
                    invokeGAWizardPageLogin();
                    setIsAliveCookie();
                    setLoginModalVisibility(false);
                    dispatch(loginSuccess({ loginResponse: res.data }));
                    dispatch(showProduct({ isSecondSystem: false }));
                    setSignUpModalVisibility(false);
                    dispatch(createContact(customerInfo));
                }
            },
            () => {
                setLoader(false);
                setLoginOtpError(true);
            }
        );
    };
    const handleSendLoginOtpToEmail = (email: string) => {
        setLoader(true);
        sendLoginOtpToEmail(email).subscribe(
            (loginOtpRes: any) => {
                setLoader(false);
                if (loginOtpRes.data.success) {
                    setLoginInputFieldsVisibility(false);
                    setLoginOtpFieldVisibility(true);
                    setIsOtpTimerActive(true);
                } else if (!loginOtpRes.data.success) {
                    showSnackbarError(LoginMessages.OTP_SEND_FAIL);
                }
            },
            (err: any) => {
                let msg = LoginMessages.OTP_SEND_FAIL;
                try {
                    if (err.response.data.error.message) {
                        msg = err.response.data.error.message;
                    }
                } catch (e) {}
                showSnackbarError(msg);
            }
        );
    };

    const handleResendOtp = () => {
        setTimeLeft(OTP_RESEND_COUNTDOWN);
        setIsOtpTimerActive(true);

        setLoader(true);
        handleSendLoginOtpToEmail(enteredEmail);
    };
    const DialogTitle = (props: any) => {
        const { onClose } = props;
        return (
            <MuiDialogTitle sx={cssObject.modalTitleContainer}>
                <Box sx={cssObject.modalTitle}>
                    <Heading isPopUp label={'Get Your Recommendations and Pricing'} />
                </Box>
                <Box sx={{ margin: '16px 0px' }}>
                    {isLoginOtpFieldVisible ? (
                        <Paragraph size={'normal'}>
                            Enter One-time password sent to{' '}
                            <Box component={'span'} sx={cssObject.b}>
                                {enteredEmail}
                            </Box>
                        </Paragraph>
                    ) : (
                        <Paragraph size={'normal'}>To continue, login or signup here</Paragraph>
                    )}
                </Box>

                <Box aria-label="close" sx={cssObject.closeButton} onClick={onClose}>
                    <Close />
                </Box>
            </MuiDialogTitle>
        );
    };
    return (
        <Dialog
            onClose={() => {
                props.handleClose();
            }}
            aria-describedby="alert-dialog-slide-description"
            open={props.isOpen}
            maxWidth={'xs'}
            disableEscapeKeyDown={true}
            // cssObject={{
            //     paper: cssObject.modalPaper,
            // }}
            PaperProps={{
                sx: cssObject.modalPaper,
            }}
        >
            <LoadingOverlay open={loader}></LoadingOverlay>
            <Box sx={cssObject.root}>
                <Box sx={cssObject.main}>
                    <DialogTitle
                        id="customized-dialog-title"
                        onClose={() => {
                            props.handleClose();
                        }}
                    ></DialogTitle>
                    <DialogContent sx={cssObject.modalContent}>
                        {isLoginModalShown && showLoginForm()}
                        {isSignUpModalShown && showSignUpForm()}
                    </DialogContent>
                </Box>
            </Box>
        </Dialog>
    );
};
export default ContactPageDialog;
