import * as React from "react"
import { SVGProps } from "react"

const WarrantyIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    data-name="Component 35 \u2013 4"
    xmlns="http://www.w3.org/2000/svg"
    width={19.058}
    height={19.085}
    {...props}
  >
    <g data-name="Group 3797" fill="#1dbaaf">
      <path
        data-name="Path 379"
        d="M19.056 6.776a5.48 5.48 0 0 1-.351 1.82 3.993 3.993 0 0 0-.079 2.427 7.708 7.708 0 0 1 .25 1.61 2.193 2.193 0 0 1-.855 1.888 8.316 8.316 0 0 1-1.408.893 3.832 3.832 0 0 0-1.7 1.6 9.08 9.08 0 0 1-.583.89 2.558 2.558 0 0 1-2.824 1.1 7.937 7.937 0 0 1-1.028-.269 3.9 3.9 0 0 0-2.39-.093 8.5 8.5 0 0 1-1.513.26 2.355 2.355 0 0 1-2.162-1 8.2 8.2 0 0 1-.755-1.25 3.85 3.85 0 0 0-1.594-1.711 10.092 10.092 0 0 1-.844-.551 2.649 2.649 0 0 1-1.122-3.012c.083-.351.185-.7.3-1.042a3.9 3.9 0 0 0 .041-2.174 11.07 11.07 0 0 1-.275-1.683 2.1 2.1 0 0 1 .54-1.559 4.08 4.08 0 0 1 1.547-1.172 3.717 3.717 0 0 0 1.844-1.606c.173-.3.358-.6.561-.883A2.6 2.6 0 0 1 7.737.122c.307.076.613.165.911.27a3.693 3.693 0 0 0 2.261.073 9.527 9.527 0 0 1 1.4-.261 2.338 2.338 0 0 1 1.859.55 4.05 4.05 0 0 1 1.152 1.515 3.789 3.789 0 0 0 1.673 1.88 8.132 8.132 0 0 1 1.224.864 2.225 2.225 0 0 1 .839 1.763Zm-17.7 5.335.064.006a1.029 1.029 0 0 0 .486 1.048c.314.225.632.447.966.64a4.818 4.818 0 0 1 2.08 2.372 2.831 2.831 0 0 0 .9 1.161.623.623 0 0 0 .253.118 2.82 2.82 0 0 0 1.464-.095 4.934 4.934 0 0 1 3.478.084 4.932 4.932 0 0 0 1.113.206.971.971 0 0 0 .949-.446c.236-.329.471-.662.675-1.011a4.886 4.886 0 0 1 2.384-2.1 2.8 2.8 0 0 0 1.157-.9.594.594 0 0 0 .11-.237 2.788 2.788 0 0 0-.091-1.464 4.915 4.915 0 0 1 .081-3.478 4.958 4.958 0 0 0 .205-1.113.96.96 0 0 0-.435-.936 11.565 11.565 0 0 0-1.009-.678 4.832 4.832 0 0 1-2.1-2.383 2.823 2.823 0 0 0-.9-1.16.626.626 0 0 0-.253-.117 2.694 2.694 0 0 0-1.446.086 4.8 4.8 0 0 1-3.509-.1 3.714 3.714 0 0 0-.718-.176 1.348 1.348 0 0 0-1.518.677c-.149.25-.324.486-.469.738a4.9 4.9 0 0 1-2.409 2.123 2.791 2.791 0 0 0-1.143.891.63.63 0 0 0-.117.253 2.764 2.764 0 0 0 .091 1.464 4.817 4.817 0 0 1-.086 3.457 8.147 8.147 0 0 0-.252 1.07Z"
      />
      <path
        data-name="Path 380"
        d="m8.471 10.54 2.935-2.8c.234-.223.467-.448.7-.669a.7.7 0 1 1 .959 1q-2.064 1.958-4.134 3.91a.654.654 0 0 1-.969 0q-.99-.961-1.97-1.932a.692.692 0 1 1 .964-.991c.506.491.999.981 1.515 1.482Z"
      />
    </g>
  </svg>
)

export default WarrantyIcon
