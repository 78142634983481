import { PageViewRequest } from 'models/contact';
import { action, payload } from 'ts-action';
import { Cart, ContactInformation } from '../../models/cart';

export const createContact = action('CONTACT::CREATE', payload<ContactInformation>());
export const createContactSuccess = action('CREATE_CONTACT::SUCCESS', payload<{ secondSystem: boolean }>());

export const contactInprogress = action('CONTACT::INPROGRESS', payload<Cart>());
export const contactInprogressSuccess = action('CONTACT::INPROGRESS_SUCCESS', payload<ContactInformation>());

export const contactOpendeal = action('CONTACT::OPEN_DEAL', payload<Cart>());
export const contactPageViewAction = action('CONTACT::PAGEVIEW', payload<PageViewRequest>());
export const contactPageViewSuccess = action('CONTACT::SUCCESS');
