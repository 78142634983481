import { action, payload } from 'ts-action';
import {
    AddCartItemActionType,
    Address,
    Cart,
    CartItem,
    PaymentMethod,
    Schedule,
    SystemGroup,
} from '../../models/cart';

export const setCart = action('CART::SET', payload<Cart>());
export const setMainSelection = action('SELECTION::SET_MAIN', payload<CartItem[]>());
export const setIAQSelection = action(
    'SELECTION::SET_IAQ',
    payload<{ newIAQ: CartItem; systemGroupToSetOrChange: SystemGroup }>()
);
export const setAddonSelection = action(
    'SELECTION::SET_ADDON',
    payload<{ addOnsList: CartItem[]; shouldReplace: boolean }>()
);
export const resetSelection = action('SELECTION::RESET');
export const resetAddonSelection = action('SELECTION_ADDON::RESET');
export const resetIAQSelection = action('SELECTION_IAQ::RESET', payload<{ systemGroupToReset: SystemGroup }>());
export const shippingAddressCaptured = action('SELECTION::SHIPPING_ADDRESS_CAPTURE_STATUS');
export const getCart = action('CART::GET');

export const deleteCart = action(
    'CART::DELETE',
    payload<{
        cartItems?: CartItem[];
        shouldRedirectToHome?: boolean;
        shouldBlockSaveForLaterApi?: boolean;
        shouldRedirectToCart?: boolean;
        shippingAddress?: Address;
        billingAddress?: Address;
    }>()
); //blocks redirection. only deletes/adds items. donot redirect
export const moveToHome = action('MOVE::HOME');
export const deleteCartSuccess = action('CART::DELETE_SUCCESS', payload<Cart>());
export const deleteCartError = action('CART::DELETE_ERROR', payload<Error>());

export const addCartItem = action('CART::ADD', payload<AddCartItemActionType>());

export const addCartItemAndRedirectToCart = action(
    'CART::ADD_AND_CHECK_COMPATIBILITY',
    payload<{ cartItems: CartItem[]; shouldRedirectToCart: boolean }>()
);
export const addCartItemAndRedirectToWizard = action(
    'CART::ADD_AND_REDIRECT_WIZARD',
    payload<{ cartItems: CartItem[]; shouldRedirectToCart: boolean }>()
);

export const addCartItemSuccess = action('CART::ADD_SUCCESS');
export const addCartItemError = action('CART::ADD_ERROR', payload<Error>());

export const setShippingAddressToCart = action(
    'SET_SHIPPINGADDRESS::TOCART',
    payload<{ address: Address; pageToRedirect?: string }>()
);

export const setShippingAddressToCartSuccess = action('SET_SHIPPINGADDRESS::SUCCESS', payload<Cart>());

export const setShippingAddressToCartFailure = action('SET_SHIPPINGADDRESS::FAILURE', payload<Error>());

export const setBillingAddressToCart = action(
    'SET_BILLINGADDRESS::TOCART',
    payload<{
        address: Address;
        pageToRedirect?: string;
        useForShipping?: boolean;
        invokeDOMEventOnBillingCatpure?: boolean;
    }>()
);

export const setBillingAddressToCartSuccess = action('SET_BILLINGADDRESS::SUCCESS', payload<Cart>());

export const setBillingAddressToCartFailure = action('SET_BILLINGADDRESS::FAILURE', payload<Error>());
export const redirectToCartPage = action('REDIRECT::CART_PAGE');

export const redirectToWizardPage = action('REDIRECT::WIZARD_PAGE');

export const redirectToPaymentPage = action('REDIRECT::PAYMENT_PAGE');

export const redirectToSchedulePage = action('REDIRECT::SCHEDULE_PAGE');

export const redirectToPage = action('REDIRECT::PAGE', payload<string>());

export const createCart = action(
    'CART::CREATE',
    payload<{
        cartItems: CartItem[];
        shouldBlockSaveForLaterApi?: boolean;
        shouldRedirectToCart?: boolean;
        shippingAddress?: Address;
        billingAddress?: Address;
    }>()
);
export const saveForLater = action('CART::SAVE_FOR_LATER', payload<Cart>());
export const saveForLaterSuccess = action('CART::SAVE_FOR_LATER_SUCCESS');

export const createCartSuccess = action('CART::CREATE_SUCCESS', payload<Cart>());
export const createCartError = action('CART::CREATE_ERROR', payload<Error>());

export const placeOrder = action('CART::PLACE_ORDER', payload<{ cart: Cart; paymentMethod?: PaymentMethod }>());
export const placeOrderForEquipmentOnly = action('CART::PLACE_ORDER_EQ_FLOW', payload<{ cart: Cart; contractorInfo: string; paymentMethod?: PaymentMethod }>());
export const placeOrderSuccess = action('CART::PLACE_ORDER_SUCCESS', payload<any>());
export const placeOrderError = action('CART::PLACE_ORDER_ERROR', payload<Error>());

export const resetCart = action('CART::RESET');
export const myCartAction = action('CART::MINE', payload<{ route?: string; params?: string }>());
export const myCartSuccess = action(
    'CART::MY_CART_SUCCESS',
    payload<{ cart: Cart; route?: string; params?: string }>()
);
export const myCartError = action('CART::MY_CART_ERROR', payload<Error>());

export const scheduleInspection = action('CART::SCHEDULE_INSPECTION', payload<Schedule[]>());
export const scheduleInspectionSuccess = action('CART::SCHEDULE_INSPECTION_SUCCESS', payload<any>());
export const scheduleInspectionError = action('CART::SCHEDULE_INSPECTION_ERROR', payload<Error>());

export const applyCouponAction = action('CART::APPLY_COUPON', payload<string>());
export const applyCouponSuccess = action('CART::APPLY_COUPON_SUCCESS', payload<Cart>());
export const applyCouponError = action('CART::APPLY_COUPON_ERROR', payload<string>());
export const resetCouponErr = action('CART::COUPON_CLEAR_ERROR');
export const removeCoupon = action('CART::REMOVE_COUPON');
export const removeCouponSuccess = action('CART::REMOVE_COUPON_SUCCESS', payload<Cart>());
export const removeCouponError = action('CART::REMOVE_COUPON_ERROR', payload<Error>());
export const cartProgress = action('CART::LOADER', payload<{ loading: boolean }>());

export const updateCartItem = action('CART::UPDATE_ITEM', payload<CartItem>());
export const updateCartItemSuccess = action('CART::UPDATE_ITEM_SUCCESS', payload<Cart>());
export const updateCartItemFailure = action('CART::UPDATED_ITEM_FAILURE', payload<Error>());

export const changeOrRemoveProduct = action(
    'CART::CHANGE_OR_REMOVE_PRODUCT',
    payload<{
        itemToAdd?: CartItem;
        itemToRemove: number;
        shouldRedirectToCartPage: boolean;
        isRemoveItemOnly?: boolean;
    }>()
);
export const changeOrRemoveProductSuccess = action('CART::CHANGE_OR_REMOVE_PRODUCT_SUCCESS', payload<Cart>());
export const changeOrRemoveProductFailure = action('CART::CHANGE_OR_REMOVE_PRODUCT_FAILURE', payload<Error>());
export const deleteMultipleItems = action(
    'CART::DELETE_MULTIPLE_ITEMS',
    payload<{ itemsToDelete: CartItem[]; isFromAddOnDeletePopup?: boolean; reArrangeCartItems?: boolean }>()
);
export const deleteMultipleItemSuccess = action('CART::DELETE_MULTIPLE_SUCCESS', payload<Cart>());
export const setAddOnCompabilityCheck = action('CART::ENABLE_ADDON_CHECK', payload<boolean>());
export const changeOrRemoveIAQ = action(
    'CART::CHANGE_IAQ',
    payload<{ itemIAQToAdd?: CartItem; itemToRemove: number; isRemoveIAQ: boolean }>()
);
export const changeOrRemoveIAQSuccess = action('CART::CHANGE_IAQ_SUCCESS', payload<Cart>());
export const changeOrIAQFailure = action('CART::CHANGE_IAQ_FAILURE', payload<Error>());
export const deleteMainAndMakeIAQMain = action('CART::DELETE_MAIN_MAKE_IAQ_MAIN', payload<SystemGroup>());
export const cartCommonFailureAction = action('CART::COMMON_FAILURE_ACTION', payload<Error>());
export const addInstructons = action('ADD::INSTRUCTIONS', payload<string>());
