import { Action } from 'ts-action';
import { ofType } from 'ts-action-operators';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import {
    loginFailure,
    sendLoginOtpFailure,
    sendLoginOtpSuccess,
    loginSuccess,
    performLogin,
    performSignUp,
    sendLoginOtp,
    sendVerificationOtp,
    verifyEnteredOtp,
    sendVerificationOtpFailure,
    sendVerificationOtpSuccess,
    verifyEnteredOtpSuccess,
    verifyEnteredOtpFailure,
    signUpSuccess,
    signUpFailure,
} from 'store/actions/loginActions';
import {
    sendLoginOtpToEmail,
    sendLoginRequest as LoginService,
    sendSignUpRequest,
    sendVerificationOtpToEmail,
    verifyOtpApi,
} from '../services/loginServices';
import { combineEpics, Epic } from 'redux-observable';
import { myCartAction } from 'store/actions/cartActions';
import { showProduct } from 'store/actions/productSearchActions';
import { showSnackbarError } from 'components/common/Snackbar/SnackbarHelper';
import { AxiosResponse } from 'axios';
import { LoginResponse, SignUpResponse } from 'models/loginModel';

export const loginEpic$ = (action$: Observable<Action>) =>
    action$.pipe(
        ofType(performLogin),
        switchMap((action) =>
            LoginService(action.payload).pipe(
                map((res: AxiosResponse<LoginResponse>) => loginSuccess({ loginResponse: { ...res.data } })),
                catchError((err) => {
                    showSnackbarError('Unable to perform login');
                    return of(loginFailure(err));
                })
            )
        )
    );

export const signUpEpic$: Epic = (action$: Observable<Action>) =>
    action$.pipe(
        ofType(performSignUp),
        switchMap((action) =>
            sendSignUpRequest(action.payload).pipe(
                map((res: AxiosResponse<SignUpResponse>) => {
                    return signUpSuccess({ ...res.data });
                }),
                catchError((err) => {
                    showSnackbarError('Unable to perform Sign up');
                    return of(signUpFailure(err));
                })
            )
        )
    );

export const navigateToRecommendationPage$ = (action$: Observable<Action>) =>
    action$.pipe(
        ofType(loginSuccess),
        switchMap((action) => {
            if (action.payload.loginResponse.isFromContactsPage) {
                return [myCartAction({ route: action.payload.route }), showProduct({ isSecondSystem: false })];
            } else {
                return [myCartAction({ route: action.payload.route, params: action.payload.params })];
            }
        })
    );

export const sendLoginOtp$ = (action$: Observable<Action>) =>
    action$.pipe(
        ofType(sendLoginOtp),
        switchMap((action) =>
            sendLoginOtpToEmail(action.payload.email).pipe(
                map(() => sendLoginOtpSuccess({ email: action.payload.email })),
                catchError((err) => {
                    showSnackbarError('unable to Send otp to email');
                    return of(sendLoginOtpFailure(err));
                })
            )
        )
    );

export const sendVerificationOtp$ = (action$: Observable<Action>) =>
    action$.pipe(
        ofType(sendVerificationOtp),
        switchMap(() =>
            sendVerificationOtpToEmail().pipe(
                map(() => sendVerificationOtpSuccess()),
                catchError((err) => {
                    showSnackbarError('unable to Send verification otp to email');
                    return of(sendVerificationOtpFailure(err));
                })
            )
        )
    );

export const verifOtp$ = (action$: Observable<Action>) =>
    action$.pipe(
        ofType(verifyEnteredOtp),
        switchMap((action) =>
            verifyOtpApi(action.payload.otp).pipe(
                map(() => verifyEnteredOtpSuccess()),
                catchError((err) => {
                    showSnackbarError('unable to verify otp');
                    return of(verifyEnteredOtpFailure(err));
                })
            )
        )
    );

export default combineEpics(
    loginEpic$,
    signUpEpic$,
    sendLoginOtp$,
    sendVerificationOtp$,
    navigateToRecommendationPage$,
    verifOtp$
);
