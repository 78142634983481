import React from 'react';
import Backdrop from '@mui/material/Backdrop';
import LoadingInternal from '../icons/Loading';
import { Box } from '@mui/material';

interface Props {
    backdrop?: boolean;
}
export default function Loading(props: Props) {
    return (
        <Box
            sx={{
                display: 'flex',
                minHeight: '100vh',
                justifyContent: 'center',
                alignItems: 'center',
            }}
        >
            {props.backdrop ? (
                <Backdrop open>
                    <LoadingInternal />
                </Backdrop>
            ) : (
                <LoadingInternal />
            )}
        </Box>
    );
}
