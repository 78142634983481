import Grid from '@mui/material/Grid';
import React from 'react';
import ContentContainer from '../../components/ContentContainer';
import { Paragraph } from '../../components/Paragraph';
import { Box, Container, Theme, useMediaQuery, useTheme } from '@mui/material';
import WizardCriteriaFooterNew from '../../components/WizardCriteriaFooterNew';
import Shield from '../../components/icons/Shield';
import { useDispatch, useSelector } from 'react-redux';
import AddOnBoxComponent from '../../components/AddOnBoxComponent';
import { Cart, CartItem, ItemType, SystemGroup } from 'models/cart';
import { Navigate } from 'react-router-dom';
import { AppRoute } from 'models/route';
import { isCartForEquipmentOnly, selectAddons, selectCart, selectCartProcessing } from 'store/selectors/cart';
import { RelatedProduct } from 'models/product';
import { addCartItem, updateCartItem } from 'store/actions/cartActions';
import LoadingOverlay from '../../components/common/LoadingOverlay';
import { useNavigate } from 'react-router-dom';
import { CloseButton } from '../../components/WizardButton';
import { Heading } from '../../components/Heading';
import { Logo, WizardLogoNewWithLink } from '../../components/Logo';
import { SXCssObject } from 'models/ThemeImages';

const createCssObject = (theme: Theme): SXCssObject => ({
    root: {
        minHeight: '60vh',
        marginBottom: '20%',
    },
    b: {
        fontWeight: theme.typography.fontWeightMedium,
    },
    main: {
        height: '80%',
    },
    header: {
        paddingTop: {
            xs: '20px',
            md: '50px',
        },
        paddingBottom: {
            xs: '10px',
            md: '15px',
        },
    },
    header2: {
        px: { xs: 1, sm: 3, md: 5, lg: 7.5 },
    },
    actions: {
        position: {
            sm: 'fixed',
        },
        left: {
            sm: 0,
        },
        bottom: {
            sm: 0,
        },
        width: {
            sm: '100%',
        },
        height: 'auto',
        backgroundColor: 'white',
        display: 'flex',
        alignItems: 'center',
    },
    helpFooter: {
        backgroundColor: theme.palette.common.white,
        padding: theme.spacing(1, 0, 1, 0),
        fontSize: '12px !important',
    },
    buttonSection: {
        display: 'flex',
        alignItems: 'center',
    },
    HeadingNavRoot: {
        width: '100%',
        height: '80px',
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
        boxShadow: '0px',
        display: 'flex',
        alignItems: 'center',
    },
});
export default function CartPageAddOnModal() {
    const cssObject: SXCssObject = createCssObject(useTheme());
    const dispatch = useDispatch();
    let combinedRelatedProducts: any[] = [];
    const cart: Cart = useSelector(selectCart);
    const theme = useTheme();
    const addOnsInCart: CartItem[] | undefined = useSelector(selectAddons);
    const isXs = useMediaQuery(theme.breakpoints.only('xs'));
    const isCartLoading = useSelector(selectCartProcessing);
    const isEquipmentOnlyCart = useSelector(isCartForEquipmentOnly);

    const navigate = useNavigate();

    if (!cart || !cart.cartId || !cart.items || cart.items.length === 0 || !cart.prices) {
        return <Navigate to={AppRoute.Home} />;
    }

    const itemMain = cart!.items!.filter((cartItem) => cartItem.itemType === ItemType.MainSystem);

    itemMain.forEach((item) => {
        combinedRelatedProducts.push(...item.relatedProducts);
    });

    if (!combinedRelatedProducts || combinedRelatedProducts.length <= 0) {
        return <Navigate to={AppRoute.CartPage} />;
    }
    /**
     * Take main product. Get the AddOns from it. If any of the AddOns exist in cart, shown the quantity added in cart.
     */
    showAddonsListWithCorrectQuantity();

    const submitToCart = () => {
        navigate(AppRoute.CartPage);
    };

    const handleChangeQuantity = (newQuantity: number, addOnItem: any) => {
        const addOnItemInCart: any = cart?.items?.find((item) => item.sku === addOnItem.sku);
        let updatedItem = { ...addOnItemInCart };
        updatedItem.quantity = newQuantity;
        dispatch(updateCartItem(updatedItem));
    };
    const handleAddToCart = (sku: string) => {
        //taking addOn from Cart based on sku.

        let addOnItem: any = combinedRelatedProducts.find((item: RelatedProduct) => item.sku === sku);
        addOnItem.quantity = 1;
        addOnItem.systemGroup = SystemGroup.AddOn;
        addOnItem.itemType = ItemType.AddOn;
        dispatch(
            addCartItem({
                cartItems: [{ ...addOnItem }],
                shouldShowSnackbarOnSuccess: true,
                shouldBlockSaveForLaterApi: true,
            })
        );
    };

    const MyNav = () => {
        return (
            <ContentContainer disableGrid background={'bg3'} sx={cssObject.HeadingNavRoot}>
                <Grid container alignItems={'center'} justifyContent={'space-between'} sx={cssObject.header2}>
                    <Grid item xs={6}>
                        {isXs ? (
                            <Logo id={'nav-logo-order-confirmation'} />
                        ) : (
                            <WizardLogoNewWithLink
                                id={'nav-logo-order-confirmation'}
                                onClick={() => {
                                    navigate(AppRoute.Home);
                                }}
                            />
                        )}
                    </Grid>
                    <Grid item container justifyContent={'flex-end'} alignItems={'center'} xs={6}>
                        <CloseButton onClick={() => navigate(AppRoute.CartPage)} />
                    </Grid>
                </Grid>
            </ContentContainer>
        );
    };

    return (
        <ContentContainer disableGrid sx={cssObject.root}>
            <ContentContainer disableGrid sx={cssObject.main}>
                <MyNav />
                <ContentContainer sx={cssObject.header}>
                    <Heading label={'Select Your Other Products'}></Heading>
                </ContentContainer>
                <LoadingOverlay open={isCartLoading} />
                <ContentContainer>
                    {combinedRelatedProducts.length > 0 && (
                        <Grid container spacing={2}>
                            {combinedRelatedProducts.map((addOnItem: RelatedProduct) => {
                                return (
                                    <AddOnBoxComponent
                                        key={addOnItem.sku}
                                        addOnListItem={addOnItem}
                                        onQuantityChange={(newQuantity) => handleChangeQuantity(newQuantity, addOnItem)}
                                        onAddToCart={(sku: string) => handleAddToCart(sku)}
                                    />
                                );
                            })}
                        </Grid>
                    )}
                </ContentContainer>
            </ContentContainer>
            <Box sx={cssObject.actions}>
                <Container disableGutters maxWidth="xl">
                    <ContentContainer>
                        <Grid container>
                            <Grid sx={cssObject.helpFooter} container alignItems={'center'} item xs={12} sm={5} md={8}>
                                <Grid container>
                                    {!isEquipmentOnlyCart && (
                                        <>
                                            <Grid item display={'flex'}>
                                                <Shield />
                                            </Grid>
                                            <Grid item xs={10}>
                                                <Paragraph size="small" align="left">
                                                    Did you know your new installed HVAC system will be protected by our
                                                    1-Year Full Labor Warranty!
                                                </Paragraph>
                                            </Grid>
                                        </>
                                    )}
                                </Grid>
                            </Grid>
                            <Grid spacing={2} item xs={12} sm={7} md={4} sx={cssObject.buttonSection}>
                                <WizardCriteriaFooterNew
                                    onBack={() => navigate(AppRoute.CartPage)}
                                    backLabel={'PREVIOUS'}
                                    nextLabel={'PROCEED TO CART'}
                                    onNext={submitToCart}
                                />
                            </Grid>
                        </Grid>
                    </ContentContainer>
                </Container>
            </Box>
        </ContentContainer>
    );

    function showAddonsListWithCorrectQuantity() {
        if (itemMain && itemMain.length > 0) {
            combinedRelatedProducts.forEach((addOnItem) => {
                const isAddOnExistOnCart = cart.items?.find((cartItem) => cartItem.sku === addOnItem.sku);
                addOnItem.quantity = isAddOnExistOnCart ? isAddOnExistOnCart.quantity : 0;
            });
            //Adding Incompatible addOns to show in UI
            addOnsInCart &&
                addOnsInCart.forEach((item) => {
                    if (!combinedRelatedProducts.find((i) => i.sku === item.sku)) {
                        combinedRelatedProducts.push({ ...item });
                    }
                });
            const uniqueValuesSet = new Set();
            const uniqueAddons = combinedRelatedProducts.filter((obj) => {
                const isPresentInSet = uniqueValuesSet.has(obj.sku);
                uniqueValuesSet.add(obj.sku);
                return !isPresentInSet;
            });
            const nonIAQAddons = uniqueAddons.filter((uniqueAddon) => {
                return uniqueAddon.sku.indexOf('IAQ') === -1;
            });
            combinedRelatedProducts = nonIAQAddons;
        }
    }
}
