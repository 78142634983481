import { useTheme } from '@mui/material/styles';
import { getThemePrimaryMain } from 'components/common/Utilities';
import * as React from 'react';
function ExpandIcon(props: any) {
    const theme = useTheme();
    const themePrimaryMain = getThemePrimaryMain(theme);
    return (
        <svg
            id="Component_22_1"
            data-name="Component 22 – 1"
            xmlns="http://www.w3.org/2000/svg"
            width="14.539"
            height="14.539"
            viewBox="0 0 14.539 14.539"
        >
            <g id="Group_3290" data-name="Group 3290" transform="translate(-20053.773 5538.309)">
                <path
                    id="Fill_4"
                    data-name="Fill 4"
                    d="M12.552,2.086H-.013A1.018,1.018,0,0,1-1,1.043,1.018,1.018,0,0,1-.013,0H12.552a1.017,1.017,0,0,1,.987,1.043,1.017,1.017,0,0,1-.987,1.043"
                    transform="translate(20054.773 -5532.082)"
                    fill={`${themePrimaryMain}`}
                />
                <path
                    id="Fill_4-2"
                    data-name="Fill 4"
                    d="M13.552,2.086H.987A1.018,1.018,0,0,1,0,1.043,1.018,1.018,0,0,1,.987,0H13.552a1.017,1.017,0,0,1,.987,1.043,1.017,1.017,0,0,1-.987,1.043"
                    transform="translate(20062.086 -5538.309) rotate(90)"
                    fill={`${themePrimaryMain}`}
                />
            </g>
        </svg>
    );
}
export default ExpandIcon;
