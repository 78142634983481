import React, { useRef, useState } from 'react';
import { Box, Checkbox, Container, Dialog, DialogContent, Grid, useTheme } from '@mui/material';
import { Theme } from '@mui/material/styles';
import MuiDialogTitle from '@mui/material/DialogTitle';
import Close from 'components/icons/Close';
import { Button } from 'components/Button';
import { Formik, FormikValues } from 'formik';
import ContentContainer from 'components/ContentContainer';
import FormikTextField from 'components/FormikTextField';
import * as yup from 'yup';
import SelectInput from 'components/common/SelectInput';
import { useSelector } from 'react-redux';
import { selectRegions } from 'store/selectors/country';
import { Address, AddressType, Cart, CartStatus } from 'models/cart';
import { selectLoginState } from 'store/selectors/loginSelector';
import { Heading } from 'components/Heading';
import { selectCart } from 'store/selectors/cart';
import { Paragraph } from 'components/Paragraph';
import BackIcon from 'components/icons/BackIcon';
import { ThemeWithAdditionalData } from 'themes/theme';
import { SXCssObject } from 'models/ThemeImages';
import {
    invokeGAHomeAddressCatpure,
    invokeGAHomeAddressUpdate,
    invokeGABillingAddressCatpure,
    invokeGABillingAddressUpdate,
    invokeGAProfilePropertyAdded,
    invokeGAProfilePropertyUpdated
} from 'components/Analytics/GoogleAnalyticsTagging';

const createCssObject = (theme: Theme): SXCssObject => ({
    root: {
        padding: {
            xs: '10px 0px',
            sm: '18px 0px',
        },
    },
    main: {
        display: 'flex',
        flexDirection: 'column',
        marginBottom: {
            md: theme.spacing(0),
        },
    },
    modalContent: {
        overflowY: 'hidden',
        padding: {
            xs: '0px 16px 8px',
            sm: '8px 24px',
        },
    },
    modalTitle: {
        fontWeight: 'bold',
        paddingRight: {
            xs: '40px',
            sm: '60px',
        },
    },
    modalTitleContainer: {
        padding: {
            xs: '10px 16px',
            sm: '10px 24px',
        },
        position: 'relative',
        marign: '0px',
    },
    backIconSection: {
        cursor: 'pointer',
        margin: {
            xs: '10px 40px 10px 0px',
            sm: '10px 60px 20px 0px',
        },
        display: 'inline-flex',
        alignItems: 'center',
    },
    backButtonText: {
        color: theme.palette.primary.main,
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1.6),
        top: theme.spacing(0.5),
        color: theme.palette.grey[500],
        cursor: 'pointer',
    },
    modalPaper: {
        borderRadius: '4px',
        margin: '24px',
        width: {
            xs: '80%',
            sm: '504px',
        },
        minWidth: {
            xs: '290px',
            sm: 'inherit',
        },
    },
    submitBtnStyle: {
        marginTop: '23px',
    },
    checkbox: {
        color: theme.palette.divider,
        '&.MuiFormControlLabel-root': {
            marginRight: '0px !important',
        },
        '&.Mui-checked': {
            color: theme.palette.action.hover,
        },
        justifyContent: 'end',
    },
    rootCheckboxStyle: {
        display: 'flex',
        justifyContent: 'left',
        flexDirection: 'row',
        alignItems: 'center',
        paddingTop: '13px ',
        marginLeft: '-10px',
    },
    checkboxParentStyle: {
        color: '#CCCCCC',
        paddingLeft: '0px',
        paddingRight: '0px',
        '&.MuiFormControlLabel-root': {
            marginRight: '0px !important',
        },
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
    },
    addressCheckBoxTextStyle: {
        justifyContent: 'center',
        flexDirection: 'column',
    },
});
export interface EditAddressModalDataParams {
    displayChooseAddressPopUp?: boolean;
    address?: Address;
    useSame?: boolean;
}
interface Props {
    handleClose: (params: EditAddressModalDataParams) => void;
    open: boolean;
    flow?: AddressType;
    addressToModify?: Address;
    isFirstTimeCapture?: boolean; //will be false when already an address is choosen (for this shipping/billing)and we are changing it.
    hideBackToAddresses?: boolean;
    hideCloseButton?: boolean;
}
export function EditAddressModal(props: Props) {
    const cssObject: SXCssObject = createCssObject(useTheme());
    const regions = useSelector(selectRegions);
    const _prefillRegion: any = null;
    const [addressRegion, setAddressRegion] = useState(_prefillRegion);
    const { addressToModify, hideBackToAddresses, flow, isFirstTimeCapture } = props;
    const loginData = useSelector(selectLoginState);
    const theme = useTheme<ThemeWithAdditionalData>();
    const shippingAddressLabel = theme.content.label.shippingAddressLabel;

    let mounted: any = useRef();
    const [isChecked, setIsChecked] = React.useState(false);
    const handleCheckboxChange = () => {
        setIsChecked(!isChecked);
    };
    const cart: Cart = useSelector(selectCart);
    const { status } = cart;
    const proceedToCart = !status || status === CartStatus.READY_TO_SCHEDULE ? true : false;
    const showSameAddressCheckBox = proceedToCart && flow !== AddressType.Properties;

    React.useEffect(() => {
        if (!mounted.current) {
            // do componentDidMount logic
            mounted.current = true;
        } else {
            // do componentDidUpdate logic
        }
    });

    if (!addressRegion) {
        const val = regions?.find((reg) => reg.region === addressToModify?.region) || (regions ? regions[0] : null);
        setAddressRegion(val);
    }

    const handleGAEvent = () => {
        if (isFirstTimeCapture) {
            if (flow === AddressType.Home) {
                isFirstTimeCapture ? invokeGAHomeAddressCatpure() : invokeGAHomeAddressUpdate();
            } else if (flow === AddressType.Billing) {
                isFirstTimeCapture ? invokeGABillingAddressCatpure() : invokeGABillingAddressUpdate();
            } else if (flow === AddressType.Properties) {
                isFirstTimeCapture ? invokeGAProfilePropertyAdded() : invokeGAProfilePropertyUpdated();
            }
        }
    };

    const getTitleBasedOnScenario = () => {
        if (flow === AddressType.Home || flow === AddressType.Billing) {
            const addressLabel = flow !== AddressType.Home ? flow : shippingAddressLabel;
            return addressToModify?.firstname
                ? `Edit Your ${addressLabel} Address`
                : `Add Your ${addressLabel} Address`;
        } else {
            return addressToModify?.firstname ? 'Edit Property' : 'Add New Property';
        }
    };

    const getSubmitBtnText = () => {
        if (flow === AddressType.Home || flow === AddressType.Billing) {
            return isFirstTimeCapture ? 'ADD TO CART' : 'UPDATE CART';
        } else {
            return addressToModify ? 'UPDATE PROPERTY' : 'ADD TO PROPERTY';
        }
    };
    const DialogTitle = (dialogTitleProps: any) => {
        const { onClose } = dialogTitleProps;
        const title = getTitleBasedOnScenario();
        // const showBackToAddressListPopUp = addressProps.hideBackToAddresses !== true;
        const backBtnText = isFirstTimeCapture ? 'BACK TO EXISTING ADDRESS' : 'SELECT FROM EXISTING ADDRESS';
        const hideBackBtn = hideBackToAddresses || flow === AddressType.Properties;
        return (
            <MuiDialogTitle sx={cssObject.modalTitleContainer}>
                {!hideBackBtn && (
                    <Box
                        sx={cssObject.backIconSection}
                        onClick={() => props.handleClose({ displayChooseAddressPopUp: true })}
                    >
                        {isFirstTimeCapture && <BackIcon style={{ marginRight: 12 }} />}
                        <Paragraph semibold size={'normal'}>
                            <Box component={'span'} sx={cssObject.backButtonText}>
                                {backBtnText}
                            </Box>
                        </Paragraph>
                    </Box>
                )}
                <Box sx={cssObject.modalTitle}>
                    <Heading label={title} isPopUp />
                </Box>

                {!props.hideCloseButton && (
                    <Box aria-label="close" sx={cssObject.closeButton} onClick={onClose}>
                        <Close />
                    </Box>
                )}
            </MuiDialogTitle>
        );
    };
    return (
        <Dialog
            onClose={() => {}}
            open={props.open}
            aria-describedby="alert-dialog-slide-description"
            maxWidth={'sm'}
            disableEscapeKeyDown={true}
            PaperProps={{
                sx: cssObject.modalPaper,
            }}
        >
            <Box sx={cssObject.root}>
                <Box sx={cssObject.main}>
                    <DialogTitle
                        id="customized-dialog-title"
                        onClose={() => {
                            props.handleClose({});
                        }}
                    ></DialogTitle>

                    <DialogContent sx={cssObject.modalContent}>
                        <Formik
                            initialValues={{
                                addressName: addressToModify?.addressName || '',
                                firstname: addressToModify?.firstname || loginData.firstname || '',
                                lastname: addressToModify?.lastname || loginData.lastname || '',
                                address1: addressToModify?.address1 || '',
                                address2: addressToModify?.address2 || '',
                                city: addressToModify?.city || '',
                                postcode: addressToModify?.postcode || '',
                            }}
                            onSubmit={(values: FormikValues) => {
                                isFirstTimeCapture ? handleGAEvent() : handleGAEvent();
                                props.handleClose({
                                    displayChooseAddressPopUp: false,
                                    address: {
                                        ...addressToModify,
                                        ...values,
                                        region: addressRegion.region,
                                    },
                                    useSame: isChecked,
                                });
                            }}
                            validateOnMount={true}
                            validationSchema={validationSchema}
                        >
                            {({ submitForm, isValid, values }) => (
                                <ContentContainer disableGrid background={'bg3'}>
                                    <Container maxWidth={'lg'} disableGutters>
                                        <Grid container spacing={3}>
                                            <Grid item xs={12}>
                                                <Grid container spacing={2}>
                                                    <Grid item xs={12}>
                                                        <FormikTextField
                                                            id={'addressName'}
                                                            name="addressName"
                                                            label={'Address Name *'}
                                                            placeholder={'Ex. My Home, Rental Property, etc.'}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <FormikTextField
                                                            id={'firstname'}
                                                            name="firstname"
                                                            label={'First Name *'}
                                                            placeholder={'First Name'}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <FormikTextField
                                                            id={'lastname'}
                                                            name="lastname"
                                                            label={'Last Name *'}
                                                            placeholder={'Last Name'}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <FormikTextField
                                                            id={'address1'}
                                                            name="address1"
                                                            label={'Address Line 1 *'}
                                                            placeholder={'Street Address 1'}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <FormikTextField
                                                            id={'address2'}
                                                            name="address2"
                                                            label={'Address Line 2'}
                                                            placeholder={'Street Address 2'}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <FormikTextField
                                                            id={'city'}
                                                            name="city"
                                                            label={'City *'}
                                                            placeholder={'City'}
                                                        />
                                                    </Grid>

                                                    <Grid item xs={12} sm={6}>
                                                        <SelectInput
                                                            id={'billing-region'}
                                                            value={addressRegion}
                                                            options={regions}
                                                            label={'State *'}
                                                            onSelect={(region: any) => setAddressRegion(region)}
                                                            fullWidth
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12} sm={6}>
                                                        <FormikTextField
                                                            id={'billing-zipcode'}
                                                            name={'postcode'}
                                                            label={'Zip Code *'}
                                                            placeholder={'Zip Code'}
                                                        />
                                                    </Grid>
                                                </Grid>
                                                {showSameAddressCheckBox && (
                                                    <Box sx={cssObject.rootCheckboxStyle}>
                                                        <Box component={'span'} sx={cssObject.checkboxParentStyle}>
                                                            <Checkbox
                                                                id={'address-checkbox'}
                                                                name={'conditions'}
                                                                sx={cssObject.checkbox}
                                                                checked={isChecked}
                                                                onChange={handleCheckboxChange}
                                                            />
                                                        </Box>
                                                        <Box>
                                                            <Box
                                                                component={'span'}
                                                                sx={cssObject.addressCheckBoxTextStyle}
                                                            >
                                                                <Paragraph headingColor fontSizeVerySmall>
                                                                    {' '}
                                                                    Same as{' '}
                                                                    {flow === AddressType.Home
                                                                        ? 'Billing'
                                                                        : shippingAddressLabel}{' '}
                                                                    Address
                                                                </Paragraph>
                                                            </Box>
                                                        </Box>
                                                    </Box>
                                                )}
                                                <Grid item xs={12} sx={cssObject.submitBtnStyle}>
                                                    <Button
                                                        isFullWidth={true}
                                                        color="primary"
                                                        disabled={!isValid}
                                                        onClick={submitForm}
                                                    >
                                                        {getSubmitBtnText()}
                                                    </Button>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Container>
                                </ContentContainer>
                            )}
                        </Formik>
                    </DialogContent>
                </Box>
            </Box>
        </Dialog>
    );
}

const validationSchema = yup.object().shape({
    address1: yup.string().required('Required'),
    address2: yup.string(),
    city: yup.string().required('Required'),
    postcode: yup
        .string()
        .length(5, 'Only 5 Characters')
        .matches(/^[0-9]{5}$/, 'Invalid Zip Code Characters')
        .required('Required'),
    firstname: yup.string().required('Required'),
    lastname: yup.string().required('Required'),
    addressName: yup.string().required('Required'),
});
