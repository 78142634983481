import React, { PropsWithChildren } from 'react';
import { Theme } from '@mui/material/styles';
import { Box, useTheme } from '@mui/material';
import { SXCssValueType } from 'models/ThemeImages';

const createCssObject = (theme: Theme) => ({
    background1: {
        backgroundColor: 'action.selected',
    },
    background2: {
        backgroundColor: 'background.default',
    },
    background3: {
        backgroundColor: 'common.white',
    },
    wizardTipBackground: {
        backgroundColor: 'primary.light',
    },
    footerBackgroundLandingPage: {
        backgroundColor: 'common.white',
        overflow: 'hidden', //added new
        paddingBottom: {
            xs: '15px !important',
            sm: 'inherit',
        },
        paddingTop: {
            xs: '30px !important',
            sm: 'inherit',
        },
    },
    gridContainer: {
        px: {
            xs: theme.spacing(1.5),
            sm: `${theme.spacing(4)} !important`,
            md: `${theme.spacing(6)} !important`,
            lg: theme.spacing(8.5),
        },
    },
    small: {
        py: theme.spacing(3),
    },
    medium: {
        py: {
            xs: theme.spacing(5),
            sm: theme.spacing(10),
        },
    },
    large: {
        py: {
            xs: theme.spacing(5),
            sm: theme.spacing(10),
            md: theme.spacing(15),
        },
    },
    footer: {
        paddingTop: theme.spacing(5),
        paddingBottom: theme.spacing(10),
    },
    footer2: {
        paddingTop: theme.spacing(5),
        paddingBottom: {
            xs: theme.spacing(10),
            sm: theme.spacing(5),
        },
    },
    wizard: {
        py: {
            xs: theme.spacing(5),
            sm: theme.spacing(10),
        },
    },
    wizardsmall: {
        paddingTop: {
            xs: theme.spacing(5),
            md: theme.spacing(10),
        },
        paddingBottom: {
            xs: theme.spacing(2),
            sm: theme.spacing(5),
            md: theme.spacing(4),
        },
    },
    wizardtip: {
        py: theme.spacing(2),
    },
    wizardtipNoPaddingTop: {
        paddingBottom: theme.spacing(2),
        paddingTop: {
            md: theme.spacing(1.5),
        },
    },
});

interface Props {
    size?:
        | 'small'
        | 'medium'
        | 'large'
        | 'footer'
        | 'footer2'
        | 'wizard'
        | 'wizardtip'
        | 'wizardtipNoPaddingTop'
        | 'wizardsmall';
    disableGrid?: boolean;
    background?: 'bg1' | 'bg2' | 'bg3' | 'footerWhite' | 'wizardtip';
    className?: SXCssValueType | any; //any should be removed once complete css is migrated to sx
    sx?: SXCssValueType;
}

export default function ContentContainer(props: PropsWithChildren<Props>) {
    const classes = createCssObject(useTheme());
    const { size, disableGrid = false, background, className, sx } = props;
    return (
        <Box
            sx={[
                !disableGrid && classes.gridContainer,
                size === 'small' && classes.small,
                size === 'medium' && classes.medium,
                size === 'large' && classes.large,
                size === 'footer' && classes.footer,
                size === 'footer2' && classes.footer2,
                size === 'wizard' && classes.wizard,
                size === 'wizardtip' && classes.wizardtip,
                size === 'wizardsmall' && classes.wizardsmall,
                background === 'wizardtip' && classes.wizardTipBackground,
                size === 'wizardtipNoPaddingTop' && classes.wizardtipNoPaddingTop,
                background === 'bg1' && classes.background1,
                background === 'bg2' && classes.background2,
                background === 'bg3' && classes.background3,
                background === 'footerWhite' && classes.footerBackgroundLandingPage,
                className && { ...className },
                sx && { ...sx },
            ]}
        >
            {props.children}
        </Box>
    );
}
