import React, { useEffect } from 'react';
import { Theme, useTheme } from '@mui/material/styles';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import { Props as MenuProps, SecondaryNavigation } from '../SecondaryNavigation';
import Navigator from '../Navigator';
import Footer from 'components/Footer';
import ContentContainer from '../ContentContainer';
import StoreNavigator from 'components/airandheatdoneright/StoreNavigator';
import ScheduleYourInstallation from './ScheduleYourInstallation';
import { isStoreNone } from 'config';
import { Box } from '@mui/material';
import { SXCssObject } from 'models/ThemeImages';

const createCssObject = (theme: Theme): SXCssObject => {
    return {
        root: {
            overflow: 'hidden',
        },
        header: {
            display: 'flex',
            height: '110px',
        },
        mainContainer: {
            margin: {
                xs: '80px 0px 10px 0px',
                sm: '1px 0px 30px 0px',
                lg: '50px 50px 50px 50px',
            },
            px: {
                xs: '10px',
                sm: '30px',
                lg: 'inherit',
            },
        },
        content: {
            padding: {
                xs: theme.spacing(1),
                sm: theme.spacing(2),
                lg: theme.spacing(3.5),
            },
        },
        menu: {
            borderRight: {
                md: '2px solid #F6F6F6',
            },
            padding: {
                xs: theme.spacing(1),
                sm: theme.spacing(2),
            },
        },
        whiteBg: {
            backgroundColor: {
                xs: theme.palette.common.white,
                sm: 'inherit',
            },
            top: {
                xs: 0,
                sm: 'inherit',
            },
            width: {
                xs: '100%',
                sm: 'inherit',
            },
            position: {
                xs: 'fixed',
                sm: 'inherit',
            },
            zIndex: {
                xs: 10,
                sm: 'inherit',
            },
        },
    };
};

export default function SubPage(props: SubPageProps) {
    const cssObject: SXCssObject = createCssObject(useTheme());
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    const handleSelect = (id: string, ref: React.RefObject<HTMLDivElement>) => {
        !!props.onSelect && props.onSelect(id, ref);
    };
    return (
        <Box sx={cssObject.root}>
            <Box sx={cssObject.whiteBg}>
                <ContentContainer disableGrid background={'bg3'}>
                    <ContentContainer>
                        {!isStoreNone() ? (
                            <Navigator color={'primary'} transparent={false} />
                        ) : (
                            <StoreNavigator color={'primary'} />
                        )}
                    </ContentContainer>
                </ContentContainer>
            </Box>
            <ContentContainer background={'bg3'} sx={cssObject.mainContainer} disableGrid>
                <Container maxWidth={'lg'} disableGutters>
                    <Grid container>
                        <Grid sx={cssObject.menu} item md={4} xs={12}>
                            {props.menu && <SecondaryNavigation {...props.menu} onSelect={handleSelect} />}
                        </Grid>
                        <Grid sx={cssObject.content} item md={8} xs={12} container>
                            {props.content1}
                        </Grid>
                    </Grid>
                </Container>
            </ContentContainer>
            {props.content2}
            <ScheduleYourInstallation
                title={'Schedule your installation'}
                detail={
                    'Schedule your Goodman Gold or Platinum HVAC installation today and receive a complimentary a Bronze Level Clean Comfort® indoor air quality (IAQ) package (up to $878 Savings!)'
                }
            />
            <Footer />
        </Box>
    );
}

export interface SubPageProps {
    menu?: MenuProps;
    content1: any;
    content2?: any;
    onSelect?: (id: string, ref: React.RefObject<HTMLDivElement>) => void;
}
