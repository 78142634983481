import { useTheme } from '@mui/material/styles';
import { getThemePrimaryLight, getThemePrimaryMain } from 'components/common/Utilities';
import * as React from 'react';

function CallIcon(props: any) {
    const theme = useTheme();
    const themePrimaryMain = getThemePrimaryMain(theme);
    const themePrimaryLight = getThemePrimaryLight(theme);
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={27} height={27} {...props}>
            <circle data-name="Ellipse 18" cx={13.5} cy={13.5} r={13.5} fill={`${themePrimaryLight}`} />
            <g data-name="Group 3018">
                <g data-name="Union 26" fill="none">
                    <path d="M17.697 20.986l-1.82.81a.983.983 0 01-.339.083 3.002 3.002 0 01-3.381-1.71l-4.29-9.635a3.002 3.002 0 01.99-3.66.983.983 0 01.288-.195l2.802-1.248a2.956 2.956 0 012.404 5.4l-.981.437 1.923 4.319.981-.437a2.956 2.956 0 012.405 5.4z" />
                    <path
                        d="M18.071 19.177a1.456 1.456 0 00.737-1.92 1.456 1.456 0 00-1.92-.738l-2.356 1.049-3.144-7.06 2.356-1.05a1.456 1.456 0 00.737-1.92 1.456 1.456 0 00-1.92-.737L9.84 8.01l-.016.016-.094.067a1.504 1.504 0 00-.494 1.829l4.29 9.634a1.504 1.504 0 001.69.857l.113-.025.021-.001 2.72-1.211m.61 1.37l-2.804 1.25a.982.982 0 01-.339.083 3.002 3.002 0 01-3.381-1.712l-4.29-9.635a3.002 3.002 0 01.99-3.658.982.982 0 01.288-.196l2.805-1.25a2.955 2.955 0 012.404 5.4l-.985.439 1.924 4.318.984-.438a2.956 2.956 0 012.405 5.4z"
                        fill={`${themePrimaryMain}`}
                    />
                </g>
            </g>
        </svg>
    );
}

export default CallIcon;
