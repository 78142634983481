import React from 'react';
import { Theme } from '@mui/material/styles';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import ContentContainer from './ContentContainer';
import { Button } from './Button';
import { Paragraph } from './Paragraph';
import { Box, useMediaQuery, useTheme } from '@mui/material';
import { ItemType } from 'models/cart';
import { Address, CartPrice } from '../models/cart';
import { getCartStatusText, isCartReadyForPayment } from '../pages/cart/cart-utils';

import {
    selectBillingAddress,
    selectCartPrice,
    selectCartStatus,
    selectHomeAddress,
    selectItems,
} from '../store/selectors/cart';
import { useSelector } from 'react-redux';
import ArrowDown from 'assets/icons/icon_drop-down.svg';
import { useNavigate } from 'react-router';
import { AppRoute } from 'models/route';
import { ThemeWithAdditionalData } from 'themes/theme';
import { SXCssObject } from 'models/ThemeImages';
import { currencyFormat } from './common/NumberUtil';

const createCssObject = (theme: Theme): SXCssObject => ({
    root: {
        padding: '0px !important',
    },
    address: {
        textAlign: 'left',
        alignContent: {
            md: 'center',
        },
    },
    systemPrice: {
        alignContent: {
            md: 'center',
        },
        textAlign: {
            xs: 'left',
            sm: 'inherit',
        },
        paddingTop: {
            xs: theme.spacing(0),
            sm: 'inherit',
        },
        paddingBottom: {
            xs: theme.spacing(1),
            sm: 'inherit',
        },
        paddingLeft: {
            xs: theme.spacing(0),
            sm: 'inherit',
        },
    },

    productDetail: {
        padding: theme.spacing(2),
        marginBottom: {
            sm: theme.spacing(1),
            md: 'inherit',
        },
    },
    systemDetail: {
        paddingBottom: {
            xs: '10px',
            sm: 'inherit',
        },
    },

    cartStatusContainer: {
        display: 'flex',
    },
    cartStatusSection: {
        padding: {
            xs: '8px 16px 20px',
            sm: 'inherit',
        },
        justifyContent: {
            md: 'flex-start',
        },
    },
    cartStatusLabel: {
        justifyContent: {
            md: 'flex-start',
        },
        display: 'flex',
    },
    cartStatusContent: {
        display: 'flex',
        alignItems: 'center',
        marginTop: '8px',
        marginBottom: {
            sm: '15px',
        },
    },
    cartStatusIconStyle: {
        border: `3px solid ${theme.customProperties.commonOrange}`,
        width: '14px',
        height: '14px',
        borderRadius: '50%',
        marginRight: '8px',
    },
    reverseArrowIcon: {
        transform: 'rotate(180deg)',
        width: '15px',
        cursor: 'pointer',
    },
    arrowIcon: {
        width: '15px',
        cursor: 'pointer',
    },
    arrowWidth: {
        display: 'flex',
        marginLeft: '5px',
    },
    groupSystem: {
        marginTop: '5px',
        paddingTop: '5px',
        borderTop: `1px solid ${theme.customProperties.border}`,
        width: 'fit-content',
    },
    paddingTop: {
        paddingTop: '10px',
    },
});

function ActiveCartDetail() {
    const cssObject = createCssObject(useTheme());
    const theme = useTheme<ThemeWithAdditionalData>();
    const [open, setOpen] = React.useState(false);

    const isDownSm = useMediaQuery(theme.breakpoints.down('md'));
    const cartItems = useSelector(selectItems);
    const cartStatus = useSelector(selectCartStatus);
    const cartHomeAddress = useSelector(selectHomeAddress);
    const cartBillingAddress = useSelector(selectBillingAddress);
    const cartPrice = useSelector(selectCartPrice);
    const navigate = useNavigate();

    const systemsLabel = (
        <div>
            <Paragraph semibold fontSizeVerySmall={true}>
                Systems
            </Paragraph>
        </div>
    );
    const homeAddressLabel = (
        <Paragraph semibold fontSizeVerySmall={true}>
            {`${theme.content.label.shippingAddressLabel} Address`}
        </Paragraph>
    );
    const billingAddressLabel = (
        <Paragraph semibold fontSizeVerySmall={true}>
            Billing Address
        </Paragraph>
    );
    const totalPriceLabel = (
        <Paragraph semibold fontSizeVerySmall={true}>
            Total Price
        </Paragraph>
    );
    const cartStatusLabel = (
        <Paragraph semibold fontSizeVerySmall={true}>
            Cart Status
        </Paragraph>
    );
    const systemPrice = (cartPrice: CartPrice) => {
        return (
            <Box sx={cssObject.paddingTop}>
                <Paragraph semibold>{currencyFormat.format(cartPrice.grandTotal)}</Paragraph>
            </Box>
        );
    };
    const address = (addressItem: Address) => {
        return (
            <Box sx={cssObject.paddingTop}>
                <Paragraph>
                    {addressItem.addressName}:{' '}
                    {addressItem.address1 + ', ' + (addressItem.address2 ? addressItem.address2 + ', ' : '')}{' '}
                </Paragraph>
                <Paragraph>{addressItem.city + ', ' + addressItem.region + ', ' + addressItem.postcode}</Paragraph>
            </Box>
        );
    };

    const UpArrow = () => {
        return (
            <Grid item sx={cssObject.arrowWidth}>
                <Box
                    component={'img'}
                    alt={'arrow'}
                    sx={cssObject.reverseArrowIcon}
                    src={ArrowDown}
                    onClick={() => setOpen(!open)}
                />
            </Grid>
        );
    };
    const handleGoToCart = () => {
        navigate(AppRoute.CartPage);
    };
    const handleGoToPayment = () => {
        navigate(AppRoute.Billing);
    };
    const cartOrPaymentButton = (payment: boolean | undefined) => {
        return (
            <Button
                id={`Go-to-cart`}
                color="primary"
                onClick={payment ? handleGoToPayment : handleGoToCart}
                isFullWidth
            >
                {payment ? 'Pay now' : 'Go To Cart'}
            </Button>
        );
    };
    return (
        <ContentContainer disableGrid>
            <Container maxWidth={false} sx={cssObject.root} id={`active-cart`}>
                <ContentContainer disableGrid background={'bg3'} sx={cssObject.productDetail}>
                    <Grid container spacing={2}>
                        {cartItems && (
                            <Grid item xs={12} sm={12} md={2} sx={cssObject.systemDetail} justifyContent={'center'}>
                                {systemsLabel}
                                {open ? (
                                    cartItems &&
                                    cartItems.map((item, index) => {
                                        if (item.itemType === ItemType.MainSystem) {
                                            return (
                                                <Box sx={index !== 0 ? cssObject.groupSystem : cssObject.paddingTop}>
                                                    <Grid container>
                                                        <Paragraph
                                                            semibold
                                                        >{`${item.productAttributes.level} HVAC`}</Paragraph>
                                                        {open && index === cartItems.length - 1 && <UpArrow />}
                                                    </Grid>
                                                    <Paragraph fontSizeVerySmall>
                                                        <Box>{item.productAttributes.systemType}</Box>
                                                    </Paragraph>
                                                </Box>
                                            );
                                        } else if (item.itemType === ItemType.CrossSell) {
                                            return (
                                                <Grid container>
                                                    <Paragraph
                                                        semibold
                                                    >{`${item.productAttributes.level} IAQ HVAC`}</Paragraph>
                                                    {open && index === cartItems.length - 1 && <UpArrow />}
                                                </Grid>
                                            );
                                        } else {
                                            return (
                                                <Grid container sx={cssObject.groupSystem}>
                                                    <Grid item xs={11}>
                                                        {item.quantity > 1 ? (
                                                            <Paragraph
                                                                semibold
                                                            >{`${item.quantity} x ${item.name}`}</Paragraph>
                                                        ) : (
                                                            <Paragraph semibold>{item.name}</Paragraph>
                                                        )}
                                                    </Grid>
                                                    <Grid item xs={1} style={{ paddingTop: '4px' }}>
                                                        {open && index === cartItems.length - 1 && <UpArrow />}
                                                    </Grid>
                                                </Grid>
                                            );
                                        }
                                    })
                                ) : (
                                    <Box sx={cssObject.paddingTop}>
                                        <Paragraph semibold>{`${cartItems[0].productAttributes.level} HVAC`}</Paragraph>
                                        <Paragraph fontSizeVerySmall>
                                            {cartItems[0].productAttributes.systemType}
                                        </Paragraph>
                                    </Box>
                                )}

                                {!open && cartItems.length > 1 && (
                                    <Grid container style={{ fontSize: '10px' }}>
                                        <Grid item>{`+ ${cartItems.length - 1} more system(s) added`}</Grid>
                                        <Grid item style={{ marginLeft: '5px', marginTop: '3px' }}>
                                            <Box
                                                component={'img'}
                                                alt={'arrow'}
                                                src={ArrowDown}
                                                onClick={() => setOpen(!open)}
                                                sx={cssObject.arrowIcon}
                                            />
                                        </Grid>
                                    </Grid>
                                )}
                            </Grid>
                        )}
                        {cartHomeAddress && (
                            <Grid item xs={12} sm={6} md={3}>
                                <Grid container direction={'column'} sx={cssObject.address}>
                                    {homeAddressLabel}
                                    {address(cartHomeAddress)}
                                </Grid>
                            </Grid>
                        )}
                        {cartBillingAddress && (
                            <Grid item xs={12} sm={6} md={3} container>
                                <Grid item container direction={'column'} sx={cssObject.address}>
                                    {billingAddressLabel}
                                    {address(cartBillingAddress)}
                                </Grid>
                            </Grid>
                        )}
                        {cartPrice && (
                            <Grid item xs={12} sm={6} md={2} container>
                                <Grid item container direction={'column'} sx={cssObject.systemPrice}>
                                    {totalPriceLabel}
                                    {systemPrice(cartPrice)}
                                </Grid>
                            </Grid>
                        )}
                        <Grid item xs={12} sm={6} md={2} sx={cssObject.cartStatusSection} container>
                            <Box sx={cssObject.cartStatusContainer}>
                                <Box>
                                    <Box sx={cssObject.cartStatusLabel}>{cartStatusLabel}</Box>
                                    <ContentContainer disableGrid sx={cssObject.cartStatusContent}>
                                        <ContentContainer
                                            disableGrid
                                            sx={cssObject.cartStatusIconStyle}
                                        ></ContentContainer>
                                        <Paragraph semibold headingColor size={'normal'}>
                                            {getCartStatusText(cartStatus)}
                                        </Paragraph>
                                    </ContentContainer>
                                    {!isDownSm &&
                                        cartOrPaymentButton(isCartReadyForPayment(cartStatus))}
                                </Box>
                            </Box>
                        </Grid>
                        {isDownSm && (
                            <Grid item xs={12}>
                                {cartOrPaymentButton(isCartReadyForPayment(cartStatus))}
                            </Grid>
                        )}
                    </Grid>
                </ContentContainer>
            </Container>
        </ContentContainer>
    );
}

export default ActiveCartDetail;
