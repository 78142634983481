import { ItemType, SystemGroup } from './cart';
import { ProductAttributes, RelatedProduct, Category } from './product';
export interface Order {
    orderId?: string;
    id?: number;
    items?: OrderProduct[];
    shippingAmount?: number;
    shippingInclTax?: number;
    shippingTaxAmount?: number;
    subtotal?: number;
    subtotalInclTax?: number;
    taxAmount?: number;
    grandTotal?: number;
    totalDue?: number;
    totalPaid?: number;
    incrementId?: string;
    shippingAddress?: OrderAddress;
    billingAddress?: OrderAddress;
    status?: OrderStatus;
    discountAmount?: number;
    createdAt?: string;
    cartId?: string;
}
export interface OrderProduct {
    product: Product;
    qtyOrdered: number;
    itemType?: ItemType;
    name?: string;
    sku: string;
    price: string;
}
export interface Product {
    id: number;
    extId: string;
    sku: string;
    name: string;
    description: string;
    image: string;
    thumbnail: string;
    price: number;
    itemType?: ItemType;
    productAttributes: ProductAttributes;
    relatedProducts: RelatedProduct[];
    categories: Category[];
    systemGroup?: SystemGroup;
}
export interface OrderAddress {
    id?: string;
    customerAddressId?: string;
    city?: string;
    company?: string;
    country?: string;
    firstName?: string;
    lastName?: string;
    postalCode?: string;
    region?: string;
    address1?: string;
    address2?: string;
    telephone?: string;
    email?: string;
    addressName?: string;
    defaultShipping?: boolean;
    defaultBilling?: boolean;
    saveInAddressBook?: boolean;
}

export interface MyOrders {
    myOrders?: Order[];
}

export enum OrderType {
    EquipmentOnly = "equipmentOnly"
}

export enum OrderStatus {
    PENDING_PAYMENT = 'partially_paid',
    PROCESSING = 'processing',
    COMPLETE = 'complete',
    PENDING = 'pending',
    CANCELLED = 'canceled',
    READY_FOR_PAYMENT = 'ready_for_payment',
    PENDING_INSPECTION = 'inspection_scheduled',
}
export interface OrderedItems {
    firstSystemMain?: OrderProduct;
    secondSystemMain?: OrderProduct;
    firstSystemIAQ?: OrderProduct;
    secondSystemIAQ?: OrderProduct;
    addOnsList?: OrderProduct[];
}
