import React from 'react';
import { Theme, useTheme } from '@mui/material/styles';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import FormLabel from '@mui/material/FormLabel';
import OutlinedInput from '@mui/material/OutlinedInput';
import { Box, CircularProgress } from '@mui/material';
import OtpErrorIcon from './icons/OtpErrorIcon';
import { SXCssObject } from 'models/ThemeImages';

const createCssObject = (theme: Theme, props: Props) => ({
    label: {
        marginBottom: theme.spacing(1),
        color: theme.palette.text.primary,
        fontWeight: props.fontWeightIsMedium ? theme.typography.fontWeightMedium : theme.typography.fontWeightRegular,
        '&.Mui-focused': {
            color: theme.palette.text.primary,
        },
        '&.Mui-error': {
            color: theme.palette.error.main,
        },
    },
    signupInfoStyle: {
        display: 'flex',
        marginTop: '10px',
    },
    input: {
        borderRadius: '8px',
        backgroundColor: theme.palette.common.white,
        '& input': {
            padding: theme.spacing(1.2),
        },
        '&.MuiOutlinedInput-root': {
            '&:hover fieldset': {
                borderColor: props.isTransparentBorderForInputField ? 'transparent' : theme.palette.text.primary,
            },
            '& fieldset': {
                border: props.isTransparentBorderForInputField
                    ? '1px solid transparent'
                    : `1px solid ${theme.palette.divider}`,
            },
            '&.Mui-focused fieldset': {
                borderColor: props.isTransparentBorderForInputField ? 'transparent' : theme.palette.text.primary,
                borderWidth: '1px',
            },
            '&.Mui-disabled': {
                backgroundColor: `${theme.palette.action.disabled}`,
            },
        },
    },
    helperText: {
        color: theme.palette.text.primary,
    },
    helperError: {
        color: '#F4434C',
        marginLeft: 6,
    },
});

interface Props {
    id: string;
    type?: string;
    label?: string;
    fullWidth?: boolean;
    helperText?: string;
    error?: boolean;
    placeholder?: string;
    enableErrorDisplay?: boolean;
    validating?: boolean;
    isDisabled?: boolean;
    onBlur?: (e: any) => void;
    onChange?: (e: any) => void;
    isTransparentBorderForInputField?: boolean;
    fontWeightIsMedium?: boolean;
    maxRows?: number | string;
    minRows?: number | string;
    multiLine?: boolean;
    showInfoMsgAlone?: boolean; //when it is set to true, display error msg alone. Do not display error border and error hint
}
export function TextField(props: Props) {
    const cssObject: SXCssObject = createCssObject(useTheme(), props);
    const {
        id,
        type = 'text',
        label,
        fullWidth,
        helperText,
        error,
        placeholder,
        enableErrorDisplay = false,
        validating,
        isDisabled,
        onBlur,
        onChange,
        isTransparentBorderForInputField,
        showInfoMsgAlone,
        fontWeightIsMedium,
        maxRows,
        minRows,
        multiLine,
        ...other
    } = props;
    return (
        <FormControl id={`${id}-root`} fullWidth={fullWidth} error={enableErrorDisplay && error && !showInfoMsgAlone}>
            {label && (
                <FormLabel id={`${id}-label`} htmlFor={id} sx={cssObject.label}>
                    {label}
                </FormLabel>
            )}
            <OutlinedInput
                id={id}
                fullWidth={fullWidth}
                type={type}
                maxRows={maxRows}
                minRows={minRows}
                multiline={multiLine}
                onChange={onChange}
                onBlur={(e) => (onBlur ? onBlur(e) : '')}
                disabled={isDisabled || validating}
                sx={cssObject.input}
                placeholder={placeholder}
                endAdornment={validating ? <CircularProgress size={20} /> : undefined}
                {...other}
            />
            {(enableErrorDisplay || showInfoMsgAlone) && (
                <Box sx={cssObject.signupInfoStyle}>
                    <OtpErrorIcon />
                    &nbsp;
                    <FormHelperText
                        id={`${id}-helper`}
                        sx={[cssObject.helperText, showInfoMsgAlone === true && cssObject.helperError]}
                    >
                        {helperText}
                    </FormHelperText>
                </Box>
            )}
        </FormControl>
    );
}
