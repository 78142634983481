import React from 'react';
import { useField } from 'formik';
import { PhoneField } from './PhoneField';

interface Props {
    id?: string;
    fullWidth?: boolean;
    name: string;
    label?: string;
    placeholder?: string;
    enableErrorDisplay?: boolean;
    validating?: boolean;
    shouldDisableInputField?: boolean;
    fontWeightIsMedium?: boolean;
}

export default function FormikPhoneField(props: Props) {
    const { id, fullWidth = true, name, label, shouldDisableInputField, fontWeightIsMedium, ...other } = props;
    const [field, meta] = useField(props);
    return (
        <PhoneField
            id={id ? id : `${name}-input`}
            label={label}
            fullWidth={fullWidth}
            fontWeightIsMedium={fontWeightIsMedium}
            isDisabled={shouldDisableInputField}
            error={meta.touched && Boolean(meta.error)}
            helperText={meta.touched ? 'Enter a valid phone number' : ''}
            {...other}
            {...field}
        />
    );
}
