import * as React from "react"
import { SVGProps } from "react"

const QuotesIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    data-name="Component 32 \u2013 1"
    xmlns="http://www.w3.org/2000/svg"
    width={15.903}
    height={21.891}
    {...props}
  >
    <defs>
      <clipPath id="a">
        <path
          data-name="Rectangle 4018"
          fill="#1dbaaf"
          d="M0 0h15.903v21.891H0z"
        />
      </clipPath>
    </defs>
    <g data-name="Group 3789" clipPath="url(#a)" fill="#1dbaaf">
      <path
        data-name="Path 368"
        d="M14.457 1.47h-13v18.475c.067-.027.128-.048.185-.076.513-.254 1.03-.5 1.536-.769a.819.819 0 0 1 .93.062c.483.336.981.651 1.461.992a.321.321 0 0 0 .435 0c.491-.339 1-.655 1.492-.986a.761.761 0 0 1 .925 0c.514.346 1.033.682 1.545 1.029a.226.226 0 0 0 .3 0c.509-.352 1.028-.689 1.536-1.043a.794.794 0 0 1 .889-.061c.569.292 1.144.57 1.762.877V5.925c0-.487.3 5.87.726 5.871s.719-6.357.719-5.87v15.165a.741.741 0 0 1-.385.719.792.792 0 0 1-.807-.073c-.751-.4-1.518-.771-2.284-1.142a.3.3 0 0 0-.238.055c-.53.348-1.058.7-1.578 1.061a.785.785 0 0 1-.982 0c-.5-.345-1.021-.672-1.527-1.015a.225.225 0 0 0-.3 0c-.513.346-1.036.677-1.549 1.023a.773.773 0 0 1-.963 0c-.515-.357-1.041-.7-1.555-1.054a.309.309 0 0 0-.366-.028c-.769.4-1.544.785-2.316 1.179A.721.721 0 0 1 0 21.248a2.56 2.56 0 0 1 0-.279V.923C0 .25.247 0 .911 0H15c.648 0 .9.254.9.913v13.938a.725.725 0 1 1-1.444.008V1.469"
      />
      <path
        data-name="Path 369"
        d="M7.946 2.919h4.291a.724.724 0 0 1 .727.966.7.7 0 0 1-.618.491 1.524 1.524 0 0 1-.18 0H3.565a.726.726 0 0 1-.668-.73.718.718 0 0 1 .675-.721c.278-.014.557 0 .835 0h3.539"
      />
      <path
        data-name="Path 370"
        d="M7.946 5.838h4.291a.724.724 0 0 1 .725.968.7.7 0 0 1-.623.487 1.8 1.8 0 0 1-.2 0H3.562a.727.727 0 0 1-.666-.732.716.716 0 0 1 .677-.719c.323-.015.647 0 .971 0h3.402"
      />
    </g>
  </svg>
)

export default QuotesIcon
