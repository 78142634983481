import React, { useEffect } from 'react';
import { Theme, useTheme } from '@mui/material/styles';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import { Props as MenuProps } from '../SecondaryNavigation';
import Navigator from '../Navigator';
import Footer from 'components/Footer';
import ContentContainer from '../ContentContainer';
import StoreNavigator from 'components/airandheatdoneright/StoreNavigator';
import ScheduleYourInstallation from './ScheduleYourInstallation';
import { isStoreNone } from 'config';
import { SXCssObject } from 'models/ThemeImages';
import { Box } from '@mui/material';

const createCssObject = (theme: Theme): SXCssObject => {
    return {
        root: {
            margin: {
                md: '55px 125px',
                lg: '55px 185px',
            },
            borderRadius: {
                md: '18px',
            },
            padding: {
                xs: '40px 20px',
                md: '80px',
            },
            marginTop: {
                xs: '60px',
                sm: 'inherit',
            },
            background: theme.palette.common.white,
        },
        header: {
            display: 'flex',
            height: '110px',
        },
        contentContainer: {
            paddingTop: theme.spacing(5),
            paddingBottom: theme.spacing(10),
        },
        whiteBg: {
            backgroundColor: {
                xs: theme.palette.common.white,
                sm: 'inherit',
            },
            top: {
                xs: 0,
                sm: 'inherit',
            },
            width: {
                xs: '100%',
                sm: 'inherit',
            },
            position: {
                xs: 'fixed',
                sm: 'inherit',
            },
            zIndex: {
                xs: 10,
                sm: 'inherit',
            },
        },
    };
};

export default function StaticSubPage(props: SubPageProps) {
    const cssObject = createCssObject(useTheme());
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <Box>
            <Box sx={cssObject.whiteBg}>
                <ContentContainer disableGrid background={'bg3'}>
                    <ContentContainer>
                        {!isStoreNone() ? (
                            <Navigator color={'primary'} transparent={false} />
                        ) : (
                            <StoreNavigator color={'primary'} />
                        )}
                    </ContentContainer>
                </ContentContainer>
            </Box>
            <ContentContainer disableGrid sx={cssObject.root}>
                <Container maxWidth={'lg'} disableGutters>
                    <Grid container spacing={3}>
                        <Grid item xs={12} container>
                            {props.content1}
                        </Grid>
                    </Grid>
                </Container>
            </ContentContainer>
            <ScheduleYourInstallation
                title={'Schedule your installation'}
                detail={
                    'Schedule your Goodman Gold or Platinum HVAC installation today and receive a complimentary a Bronze Level Clean Comfort® indoor air quality (IAQ) package (up to $878 Savings!)'
                }
            />
            <Footer />
        </Box>
    );
}

export interface SubPageProps {
    menu?: MenuProps;
    content1: any;
    content2?: any;
}
