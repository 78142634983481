import * as React from 'react';

const AddInstructionIcon = (props: React.SVGProps<SVGSVGElement>) => {
    return (
        <svg data-name="Component 202 \u2013 1" xmlns="http://www.w3.org/2000/svg" width={24} height={24} {...props}>
            <g data-name="Group 3930" fill="none" stroke={"#ffffff"} strokeWidth={2}>
                <g data-name="Group 3909" strokeLinecap="round">
                    <path data-name="Path 628" d="M12.5 11.407v7.011" />
                    <path data-name="Path 629" d="M16.006 14.912H8.995" />
                </g>
                <g data-name="Rectangle 4216" transform="translate(3 5)">
                    <rect width={19} height={19} rx={3} stroke="none" />
                    <rect x={1} y={1} width={17} height={17} rx={2} />
                </g>
                <path data-name="Line 212" strokeLinecap="round" d="M17.5 2.5h-10" />
            </g>
        </svg>
    );
};

export default AddInstructionIcon;
