import * as React from 'react';
import { useTheme } from '@mui/material/styles';
import { getThemePrimaryMain } from 'components/common/Utilities';

function LoginProfileIcon(props: any) {
    const theme = useTheme();
    const themePrimaryMain = getThemePrimaryMain(theme);
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} {...props}>
            <defs>
                <style>{'.prefix__b{stroke-width:.2px}'}</style>
            </defs>
            <path
                className="prefix__b"
                fill={`${themePrimaryMain}`}
                d="M11.984 21.145H5.591c-.8 0-1.065-.276-1.071-1.078a7.308 7.308 0 014.348-7.155 7.467 7.467 0 0110.609 6.672v.477c0 .8-.276 1.085-1.063 1.085h-6.43zm-5.729-1.721h11.51a5.754 5.754 0 00-6.157-5.518 5.692 5.692 0 00-5.353 5.518zM15.992 6.812a3.993 3.993 0 11-4.053-3.962 3.985 3.985 0 014.053 3.962zm-1.69.024a2.3 2.3 0 10-2.285 2.309 2.31 2.31 0 002.285-2.305z"
            />
        </svg>
    );
}

export default LoginProfileIcon;
