import React from 'react';
import { Theme } from '@mui/material/styles';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import CardMedia from '@mui/material/CardMedia';
import ContentContainer from './ContentContainer';
import { Level as ProductLevel } from '../models/product';
import { Paragraph } from './Paragraph';
import striptags from 'striptags';
import { ItemType } from 'models/cart';
import { Box, useMediaQuery, useTheme } from '@mui/material';
import { SXCssObject } from 'models/ThemeImages';
import { currencyFormat } from './common/NumberUtil';

const createCssObject = (theme: Theme): SXCssObject => ({
    root: {
        padding: '0px !important',
    },
    systemSKU: {
        textAlign: 'left',
        wordBreak: 'break-all',
        paddingTop: {
            xs: theme.spacing(1),
            lg: theme.spacing(2),
        },
        paddingBottom: {
            xs: theme.spacing(1),
            sm: 0,
        },
        paddingLeft: {
            sm: theme.spacing(1),
        },
    },
    systemPriceSelected: {
        textAlign: 'left',
        paddingTop: {
            xs: theme.spacing(0),
            sm: theme.spacing(1),
            md: theme.spacing(2),
        },
        paddingBottom: {
            xs: theme.spacing(1),
            sm: 'inherit',
        },
        paddingLeft: {
            xs: theme.spacing(0),
            sm: theme.spacing(2),
        },
    },
    systemQuantity: {
        textAlign: 'left',
        fontWeight:600,
        paddingTop: {
            xs: theme.spacing(0),
            sm: theme.spacing(1),
            md: theme.spacing(2),
        },
        paddingBottom: {
            xs: theme.spacing(1),
            sm: 'inherit',
        },
        paddingLeft: {
            xs: theme.spacing(0),
            sm: theme.spacing(2),
        },
    },
    buttonBlock: {
        textAlign: {
            xs: 'center',
            sm: 'right',
        },
        paddingTop: {
            xs: '20px',
            sm: '10px',
        },
        paddingRight: {
            lg: theme.spacing(2.6),
        },
    },
    productDetail: {
        padding: theme.spacing(2),
    },
    imageBox: {
        paddingBottom: {
            xs: '10px',
            sm: 'inherit',
        },
    },
    img: {
        height: {
            xs: '96px',
            sm: '70px',
        },
        objectFit: 'contain',
        border: '1px solid #D4D4D4',
        borderRadius: '4px',
        marginRight: {
            sm: 4,
            md: 'inherit',
        },
        width: {
            sm: '83px',
        },
    },
    panel: {
        border: `1px solid ${theme.palette.divider}`,
    },
    panelSummary: {
        padding: theme.spacing(0, 1),
        '&.MuiExpansionPanelSummary-root': {
            height: theme.spacing(4),
            minHeight: theme.spacing(4),
        },
    },
    panelDetail: {
        borderTop: `1px solid ${theme.palette.divider}`,
        padding: theme.spacing(2),
    },
    productDetailLabel: {
        paddingLeft: {
            xs: theme.spacing(0),
            sm: '0px',
        },
    },
    readMoreText: {
        color: theme.palette.primary.main,
        cursor: 'pointer',
        fontWeight: 600,
        paddingLeft: theme.spacing(0.3),
        '&:hover': {
            color: `${theme.palette.primary.main} !important`,
        },
    },
});

interface Props {
    sku: string;
    quantity: number;
    level: ProductLevel;
    price: number;
    description: any;
    image: string;
    name: string;
    itemType?: string;
    width?: 'xs' | 'sm' | 'md' | 'lg' | 'xl';
}

function OrderProductDetail(props: Props) {
    const [showFullText, setShowFullText] = React.useState(false);
    const cssObject: SXCssObject = createCssObject(useTheme());
    const theme = useTheme();
    const isXsDown = useMediaQuery(theme.breakpoints.only('xs'));
    const isSmDown = useMediaQuery(theme.breakpoints.down('lg'));
    const isMdDown = useMediaQuery(theme.breakpoints.down('lg'));
    const description = (trimLength: number) => (
        <Grid sx={cssObject.productDetailLabel}>
            <Paragraph size={'small'} id={`description-level-${props.level}`}>
                {trimLength ? striptags(props.description).slice(0, trimLength) + '  ' : striptags(props.description)}
                {!!striptags(props.description) && (
                    <Box component={'span'} onClick={toggleFullText} sx={cssObject.readMoreText}>
                        {showFullText ? 'Read less' : 'Read more'}
                    </Box>
                )}
            </Paragraph>
        </Grid>
    );

    const price = props.price;

    const systemPrice = <Paragraph bold>{currencyFormat.format(price)}</Paragraph>;
    const systemPriceLabel = <Paragraph fontSizeVerySmall={true}>System Price</Paragraph>;
    const systemSKULabel = <Paragraph fontSizeVerySmall={true}>SKU</Paragraph>;
    const quantityLabel = <Paragraph fontSizeVerySmall={true}>Quantity</Paragraph>;
    const systemSKU = (
        <Grid sx={cssObject.productDetailLabel}>
            <Paragraph size={'small'} semibold>
                {props.sku}
            </Paragraph>
        </Grid>
    );
    const systemName = (
        <Grid sx={cssObject.productDetailLabel}>
            <Paragraph semibold size={'small'} fontSizeVerySmall>
                {props.itemType === ItemType.MainSystem
                    ? props.level + ' HVAC'
                    : props.itemType === ItemType.CrossSell
                    ? props.level + ' IAQ HVAC'
                    : props.name}
                {props.sku.includes('-EO') ? ` - Equipment only` : ''}
            </Paragraph>
        </Grid>
    );
    const image = <CardMedia image={props.image} component={'img'} sx={cssObject.img} />;

    const toggleFullText = () => {
        setShowFullText(!showFullText);
    };

    const getTextBasedOnSize = () => {
        if (isXsDown) {
            return description(75);
        } else if (isSmDown) {
            return description(40);
        } else if (isMdDown) {
            return description(50);
        } else {
            return description(70);
        }
    };

    return (
        <ContentContainer disableGrid>
            <Container maxWidth={false} sx={cssObject.root} id={`card-level-${props.level}`}>
                <ContentContainer disableGrid background={'bg3'} sx={cssObject.productDetail}>
                    <Grid container>
                        <Grid item xs={12} sm={2} md={2} sx={cssObject.imageBox} justifyContent={'center'}>
                            {image}
                        </Grid>

                        <Grid item xs={12} sm={4} md={4}>
                            <Grid container direction={'column'}>
                                {systemName}
                                <div>{showFullText ? description(0) : getTextBasedOnSize()}</div>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} sm={2} md={2} container>
                            <Grid
                                item
                                container
                                direction={'column'}
                                sx={cssObject.systemSKU}
                                id={`system-price-level-${props.level}`}
                            >
                                {systemSKULabel}
                                {systemSKU}
                            </Grid>
                        </Grid>
                        <Grid item xs={12} sm={2} md={2} container>
                            <Grid
                                item
                                container
                                direction={'column'}
                                sx={cssObject.systemQuantity}
                                id={`system-price-level-${props.level}`}
                            >
                                {quantityLabel}
                                {props.quantity}
                            </Grid>
                        </Grid>
                        <Grid item xs={12} sm={2} md={2} container>
                            <Grid
                                item
                                container
                                direction={'column'}
                                sx={cssObject.systemPriceSelected}
                                id={`system-price-level-${props.level}`}
                            >
                                {systemPriceLabel}
                                {systemPrice}
                            </Grid>
                        </Grid>
                    </Grid>
                </ContentContainer>
            </Container>
        </ContentContainer>
    );
}

export default OrderProductDetail;
