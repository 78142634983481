import { Box, Theme, useTheme } from '@mui/material';
import { Button } from 'components/Button';
import { Paragraph } from 'components/Paragraph';
import React from 'react';
import ContentContainer from '../../components/ContentContainer';
import { PropertiesIcon } from '../../components/icons/PropertiesIcon';
import { SXCssObject } from 'models/ThemeImages';
const createCssObject = (theme: Theme): SXCssObject => ({
    root: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: {
            xs: '60px',
            sm: '100px',
        },
    },
    noContentLayout: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
    },
    noContentTextStyle: {
        marginTop: '16px',
        marginBottom: '32px',
    },
});
interface Props {
    onAddAddressClick: () => void;
}
export function NoPropertiesLayout(props: Props) {
    const cssObject = createCssObject(useTheme());
    const theme = useTheme();
    const { onAddAddressClick } = props;
    return (
        <ContentContainer disableGrid sx={cssObject.root}>
            <Box sx={cssObject.noContentLayout}>
                <PropertiesIcon color={theme.customProperties.darkGrey} />
                <Box sx={cssObject.noContentTextStyle}>
                    <Paragraph semiLargeFont>You have not added any of your addresses</Paragraph>
                </Box>
                <Button color="primary" homePageLargeButton onClick={onAddAddressClick}>
                    ADD NEW PROPERTY
                </Button>
            </Box>
        </ContentContainer>
    );
}
