import { LoginValidationObject } from './../../models/loginModel';

/**
 * We can restrict specific email domain's to be used for signUp.
 * @param value - Entered Email
 * @param shouldCheckDomain - is domain check enabled in featuresList for current store(from store API)
 * @returns - is entered email's domain valid
 */
export function validateDomain(value: string, validationObj: LoginValidationObject) {
    if (!validationObj.shouldCheckDomain || !validationObj.validDomains) {
        return true;
    } else {
        if (!value || value.length <= 4) {
            return false;
        }
        const domainAllowed = validationObj.validDomains.split(',');
        let isDomainValid = false;

        domainAllowed.forEach((domain: string) => {
            if (value && value.endsWith(domain)) {
                isDomainValid = true;
            }
        });

        return isDomainValid;
    }
}
