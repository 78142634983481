import { ItemType, SystemGroup } from 'models/cart';
import { createSelector } from 'reselect';
import { RootState } from '../reducers';

export const selectOrder = (state: RootState) => state.order;
export const selectOrderLoading = createSelector(selectOrder, (order) => order.loading);

export const selectMyOrder = (state: RootState) => state.myOrders;
export const selectMyOrdersLoading = createSelector(selectMyOrder, (myOrders) => myOrders.loading);
export const selectMyOrders = createSelector(selectMyOrder, (myOrder) => myOrder.myOrders);
export const selectOrderItems = createSelector(selectOrder, (order) => order.items);
export const selectFirstMainOrderItem = createSelector(
    selectOrderItems,
    (orderItems) =>
        orderItems &&
        orderItems.find(
            (item) =>
                item.product &&
                item.product.systemGroup === SystemGroup.SystemGroup1 &&
                item.product.itemType === ItemType.MainSystem
        )
);
export const selectSecondMainOrderItem = createSelector(
    selectOrderItems,
    (orderItems) =>
        orderItems &&
        orderItems.find(
            (item) =>
                item.product &&
                item.product.systemGroup === SystemGroup.SystemGroup2 &&
                item.product.itemType === ItemType.MainSystem
        )
);
export const selectFirstIAQOrderItem = createSelector(
    selectOrderItems,
    (orderItems) =>
        orderItems &&
        orderItems.find(
            (item) =>
                item.product &&
                item.product.systemGroup === SystemGroup.SystemGroup1 &&
                item.product.itemType === ItemType.CrossSell
        )
);
export const selectOrderAddons = createSelector(
    selectOrderItems,
    (items) => items && items && items.filter((item) => item.product && item.product.itemType === ItemType.AddOn)
);
export const selectSecondIAQOrderItem = createSelector(
    selectOrderItems,
    (orderItems) =>
        orderItems &&
        orderItems.find(
            (item) =>
                item.product &&
                item.product.systemGroup === SystemGroup.SystemGroup2 &&
                item.product.itemType === ItemType.CrossSell
        )
);
export const selectOrderedItems = createSelector(
    selectFirstMainOrderItem,
    selectSecondMainOrderItem,
    selectFirstIAQOrderItem,
    selectSecondIAQOrderItem,
    selectOrderAddons,
    (firstSystemMain, secondSystemMain, firstSystemIAQ, secondSystemIAQ, addOns) => {
        return {
            firstSystemMain: firstSystemMain,
            secondSystemMain: secondSystemMain,
            firstSystemIAQ: firstSystemIAQ,
            secondSystemIAQ: secondSystemIAQ,
            addOnsList: addOns,
        };
    }
);
