import React, { Fragment, useRef } from 'react';
import { Theme, useTheme } from '@mui/material/styles';
import SubPage from 'components/subpage/SubPage';
import Grid from '@mui/material/Grid';
import { Heading } from 'components/Heading';
import { Paragraph } from 'components/Paragraph';
import { Menu, Props as MenuProps } from 'components/SecondaryNavigation';
import { customBulletParagraph } from './TermsAndConditions';
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, useMediaQuery } from '@mui/material';
import { CommonLink } from 'components/Link';
import { AppRoute } from 'models/route';
import Green from 'components/icons/Green';
import { SXCssObject } from 'models/ThemeImages';

function SecurityAndPrivacyPolicy() {
    const createCssObject = (theme: Theme): SXCssObject => {
        return {
            contentHeading: {
                paddingBottom: theme.spacing(1),
            },
            contentSecondaryHeading: {
                paddingTop: theme.spacing(4),
            },
            contentImage: {
                paddingTop: theme.spacing(2),
            },
            content: {
                paddingTop: theme.spacing(2),
            },
            disclaimer: {
                marginTop: theme.spacing(3),
                borderTop: `3px solid ${theme.palette.primary.main}`,
                borderBottom: `3px solid ${theme.palette.primary.main}`,
            },
        };
    };
    const [id, setId] = React.useState('introduction');
    const theme = useTheme();
    const xsDown = useMediaQuery(theme.breakpoints.only('xs'));
    const smDown = useMediaQuery(theme.breakpoints.down('md'));
    function getTermsMenus(selectedMenu?: string) {
        const menus: Menu[] = [
            {
                selected: false,
                id: 'introduction',
                label: `introduction`,
                color: 'primary',
                ref: introRef,
            },
            {
                selected: false,
                id: 'collection',
                label: `Collection`,
                color: 'primary',
                ref: collectionRef,
            },
            {
                selected: false,
                id: `personal_info`,
                color: 'primary',
                label: `USE PERSONAL INFO`,
                ref: usePersonalInfoRef,
            },
            {
                selected: false,
                id: 'california_privacy_rights',
                label: `CALIFORNIA PRIVACY RIGHTS`,
                color: 'primary',
                ref: rightsRef,
            },
            {
                selected: false,
                id: 'sharing',
                label: `sharing`,
                color: 'primary',
                ref: sharingRef,
            },
            {
                selected: false,
                id: 'retention',
                label: `RETENTION`,
                color: 'primary',
                ref: retentionRef,
            },
            {
                selected: false,
                id: 'security',
                label: `security`,
                color: 'primary',
                ref: securityRef,
            },
            {
                selected: false,
                id: 'transfers',
                label: `transfers`,
                color: 'primary',
                ref: transfersRef,
            },
            {
                selected: false,
                id: 'eea_induviduals',
                label: `EEA Individuals`,
                color: 'primary',
                ref: induvidualsRef,
            },

            {
                selected: true,
                id: 'minors',
                label: `Minors`,
                color: 'primary',
                ref: minorsRef,
            },
            {
                selected: false,
                id: 'automatically_collected_information',
                label: 'AUTOMATICALLY COLLECTED INFORMATION',
                color: 'primary',
                ref: infoRef,
            },
            {
                selected: false,
                id: 'choices',
                label: 'Your Choices',
                color: 'primary',
                ref: choicesRef,
            },
            {
                selected: false,
                id: 'direct_marketing',
                label: 'Direct Marketing',
                color: 'primary',
                ref: marketingRef,
            },
            {
                selected: false,
                id: 'contact_us',
                label: 'Contact Us',
                color: 'primary',
                ref: contactRef,
            },
        ];
        return menus.map((m) => ({
            ...m,
            selected: m.id === selectedMenu,
        }));
    }
    const cssObject: SXCssObject = createCssObject(useTheme());
    const introRef = useRef<HTMLDivElement>(null);
    const collectionRef = useRef<HTMLDivElement>(null);
    const usePersonalInfoRef = useRef<HTMLDivElement>(null);
    const retentionRef = useRef<HTMLDivElement>(null);
    const securityRef = useRef<HTMLDivElement>(null);
    const transfersRef = useRef<HTMLDivElement>(null);
    const induvidualsRef = useRef<HTMLDivElement>(null);
    const rightsRef = useRef<HTMLDivElement>(null);
    const minorsRef = useRef<HTMLDivElement>(null);
    const infoRef = useRef<HTMLDivElement>(null);
    const choicesRef = useRef<HTMLDivElement>(null);
    const marketingRef = useRef<HTMLDivElement>(null);
    const contactRef = useRef<HTMLDivElement>(null);
    const sharingRef = useRef<HTMLDivElement>(null);

    function menu(id: string): MenuProps {
        return {
            id: 'menu',
            label: 'Terms and conditions',
            color: 'primary',
            subMenus: getTermsMenus(id),
        };
    }
    const handleSelect = (id: string, ref: React.RefObject<HTMLDivElement>) => {
        setId(id);
        //ref && ref.current && ref.current.scrollIntoView({ behavior: 'smooth' });
        if (ref.current) {
            if (xsDown) {
                window.scroll({
                    top: ref.current.offsetTop - 630,
                    behavior: 'smooth',
                });
            } else if (smDown) {
                window.scroll({
                    top: ref.current.offsetTop - 570,
                    behavior: 'smooth',
                });
            } else {
                ref.current && ref.current.scrollIntoView({ behavior: 'smooth' });
            }
        }
        //ref && ref.current && ref.current.scrollIntoView();
    };
    return (
        <SubPage
            menu={menu(id)}
            onSelect={handleSelect}
            content1={
                <Fragment>
                    <Grid item xs={12} sx={cssObject.contentHeading} ref={introRef}>
                        <Heading isPopUp color={'primary'} label={'Privacy Policy'} />
                    </Grid>
                    {/* Privacy Policy Begin */}
                    <Grid item xs={12}>
                        <Paragraph>Last Revised: November 12, 2021</Paragraph>
                    </Grid>
                    <Grid item xs={12} sx={cssObject.content}>
                        <Paragraph align={'left'} size={'normal'}>
                            Your privacy is important to us. And we want you to know how we collect, use, transfer, and
                            store personal information that identifies or could reasonably identify an individual.
                        </Paragraph>
                    </Grid>
                    <Grid item xs={12} sx={cssObject.content}>
                        <Paragraph align={'left'} size={'normal'}>
                            This Privacy Policy (“Policy”) describes how Air and Heat Done Right (“we” or “us” or “our”)
                            collect and use personal information when you access or use our products, offerings,
                            services, websites, software, applications, or otherwise interact with us (collectively
                            “Services”) or when we receive your personal information from third parties, such as
                            subcontractors, dealers, distributors, or other third parties that we work with.
                        </Paragraph>
                    </Grid>
                    <Grid item xs={12} sx={cssObject.content}>
                        <Paragraph align={'left'} size={'normal'}>
                            For certain Services, there may be a separate privacy policy or a separate written agreement
                            (e.g., an end-user license agreement) between you and us—and, in such instances, that other
                            privacy policy or separate written agreement controls.
                        </Paragraph>
                    </Grid>
                    <Grid item xs={12} sx={cssObject.content}>
                        <Paragraph align={'left'} size={'normal'}>
                            We may revise this Policy at any time. And when we do, we will notify you that revisions
                            have been made by, for example, updating the revision date provided above or, if
                            circumstances warrant, by contacting you directly via email or traditional mail. Please
                            review this Policy each time you access or use our Services, so you can stay informed about
                            our current privacy practices. By accessing and using our Services, you are signifying your
                            agreement with and to this Policy. If you do not agree to or are otherwise uncomfortable
                            with this Policy, you should not use, or immediately discontinue your use of, our Services.
                        </Paragraph>
                    </Grid>
                    <Grid item xs={12} sx={cssObject.content}>
                        <Paragraph align={'left'} size={'normal'}>
                            <b>Rights Under the California Consumer Privacy Act (CCPA):</b> If you are a California
                            resident, you may have additional rights related to our use of your personal information,
                            including additional rights and choices regarding
                        </Paragraph>
                    </Grid>
                    <Grid item xs={12} sx={cssObject.content}>
                        {customBulletParagraph('Accessing your personal information,')}
                        <br />
                        {customBulletParagraph(' Our disclosure of your personal information,')}
                        <br />
                        {customBulletParagraph(' Deletion of your personal information,')}
                        <br />
                        {customBulletParagraph(
                            'Opting out of the sale of your personal information (although we do not sell your information), and'
                        )}
                        <br />
                        {customBulletParagraph(
                            'A right to applicable non-discrimination based on your exercise of your rights under California law.'
                        )}
                        <br />
                    </Grid>
                    <Grid item xs={12} sx={cssObject.content}>
                        <Paragraph align={'left'} size={'normal'}>
                            For California residents, specific disclosures required by California law are in Section 3
                            below.
                        </Paragraph>
                    </Grid>
                    <Grid item xs={12} sx={cssObject.content}>
                        <Paragraph align={'left'} size={'normal'}>
                            <b>Rights Under Nevada Law:</b> If you are a Nevada resident, you may have additional rights
                            regarding the sale of your personal information, as defined by Nevada law. In the past 12
                            months, we have not exchanged personal information for money with anyone so that person can
                            sell or license that subject personal information to additional persons, and we will not do
                            so without appropriately notifying you under applicable Nevada law. To exercise your rights,
                            if applicable to you, under Nevada law please submit your request to us at{' '}
                            <CommonLink
                                fontColor={theme.palette.primary.main}
                                href={'mailto:cs@airandheatdoneright.com'}
                                label={'cs@airandheatdoneright.com'}
                            />
                            .
                        </Paragraph>
                    </Grid>
                    {/* Privacy Policy End */}

                    {/* Collection Begin */}
                    <Grid item xs={12} sx={cssObject.contentSecondaryHeading} ref={collectionRef}>
                        <Heading isPopUp color={'primary'} label={'Information We Collect And How We Collect It.'} />
                    </Grid>
                    <Grid item xs={12} sx={cssObject.content}>
                        <Paragraph align={'left'} size={'normal'}>
                            We collect personal information in a variety of ways during our interactions with you. For
                            example, we may collect personal information when you create an account or use our Services
                            alone or in conjunction with products and services provided by us, our corporate affiliates,
                            or our subcontractors. You, of course, are not required to provide certain personal
                            information when we request it, but then we might not be able to provide you with the
                            Services or respond to your questions and comments.
                        </Paragraph>
                    </Grid>
                    <Grid item xs={12} sx={cssObject.content}>
                        <Paragraph align={'left'} size={'normal'}>
                            The type of personal information we collect depends on the particulars of your interaction
                            with us, your choices, and applicable law. But the following are examples of personal
                            information we may collect and when we may collect it.
                        </Paragraph>
                    </Grid>
                    <Grid item xs={12} sx={cssObject.content}>
                        <Paragraph align={'left'} size={'normal'}>
                            <b>Source 1—Information You Voluntarily Provide:</b> We may collect information that you
                            voluntarily provide to us or our corporate affiliates, such as your name, your email
                            address, your physical address, your phone number, your demographic information, your
                            birthdate, your employment history, your transactions with us, any feedback you provide,
                            your credit card information, your preferences, your warranty claims, your customer-service
                            requests, your technical-service requests, or any other information that you may provide to
                            us. You may voluntarily provide this information to us electronically when you use our
                            applications, websites, products, or other Services. Or you may also provide this
                            information to us manually, such as when you provide us with a physical document, contact us
                            by phone, submit a warranty-registration card, or provide your information at a product
                            showroom or kiosk.
                        </Paragraph>
                    </Grid>
                    <Grid item xs={12} sx={cssObject.content}>
                        <Paragraph align={'left'} size={'normal'}>
                            <b>Source 2—Usage/Account Information:</b> If use of a Service requires you to create an
                            account with us, we may collect information about your account credentials (e.g., password,
                            user-ID, authentication information), your use of the Services or products associated with
                            the Services, or any other information associated with your account. And we may collect
                            information obtained about you from a third-party service (e.g., Facebook, Google) when you
                            use these services to create or log in to the account, pursuant to that third party’s
                            authorizations and practices.
                        </Paragraph>
                    </Grid>
                    <Grid item xs={12} sx={cssObject.content}>
                        <Paragraph align={'left'} size={'normal'}>
                            <b>Source 3—Automatically Collected Information:</b> We may also collect information
                            automatically. For example, when you use our Services, we may collect information about how
                            and when you used our Services, the actions you performed or requested through the use of
                            our Services, the type of device used to access our Services, your location (which may be
                            provided by the device you are accessing our Services from), and your IP address. We may
                            also use “cookies” and other automated tracking systems (also see Section 10 below) to
                            collect information about you. For example, we may also collect information regarding the
                            use of other website, apps, and online services.
                        </Paragraph>
                    </Grid>
                    <Grid item xs={12} sx={cssObject.content}>
                        <Paragraph align={'left'} size={'normal'}>
                            <b>Source 4—Information About You From Equipment:</b> In certain instances, you may be using
                            our Services with equipment provided by us or with equipment or services provided by our
                            corporate affiliates, our subcontractors, or others with whom we may be working
                            (collectively “Equipment”). We may collect information both internal and external to the
                            Equipment, and we may collect information in an automated manner, manually, or both.
                        </Paragraph>
                    </Grid>
                    <Grid item xs={12} sx={cssObject.content}>
                        <Paragraph align={'left'} size={'normal'}>
                            For example, we may collect information about
                        </Paragraph>
                        <br />
                    </Grid>
                    <Grid item xs={12}>
                        {customBulletParagraph(
                            'The Equipment’s setup, network connections, location, performance, resource consumption, and operation, Our disclosure of your personal information, '
                        )}
                        <br />
                        {customBulletParagraph('Adjustments made to the Equipment,')}
                        <br />
                        {customBulletParagraph('The Equipment’s usage information,')}
                        <br />
                        {customBulletParagraph('Any faults or errors detected by the Equipment, and ')}
                        <br />
                        {customBulletParagraph('Technical information related to the Equipment.')}
                        <br />
                    </Grid>
                    <Grid item xs={12} sx={cssObject.content}>
                        <Paragraph align={'left'} size={'normal'}>
                            In addition, we may collect information about the environment surrounding the Equipment
                            using sensors in or connected to the Equipment. For example, we may collect information
                            about
                        </Paragraph>
                        <br />
                    </Grid>
                    <Grid item xs={12}>
                        {customBulletParagraph(
                            'The occupancy, activity, temperature, air quality, humidity, illuminance, decibel levels, or other parameters of the environment the Equipment is used in or with, and Adjustments made to the Equipment, '
                        )}
                        <br />
                        {customBulletParagraph('You and your activity within the environment.')}
                        <br />
                    </Grid>
                    <Grid item xs={12} sx={cssObject.content}>
                        <Paragraph align={'left'} size={'normal'}>
                            Collecting information about you, your environment, and your interactions with the
                            environment helps the Equipment and Services automatically adjust, for example, the
                            temperature settings of your home or office to a setting that may be more comfortable to
                            you. And we may make these adjustments by making inferences about your activity based on
                            information from the Equipment and/or from other information we may have.
                        </Paragraph>
                    </Grid>
                    <Grid item xs={12} sx={cssObject.content}>
                        <Paragraph align={'left'} size={'normal'}>
                            <b>Source 5—Third Party Sources:</b> We may also obtain information from other sources,
                            including from private agencies (e.g., credit-rating services) or public sources. For
                            example, we may collect information about you from public databases and property records,
                            and we may collect information from third parties that install, repair, or maintain the
                            Equipment we manufactured or supplied. One common example is when we receive your
                            information from the dealer or distributor that installed the equipment at your property,
                            who may be giving us that information to, for example, register the warranty associated with
                            that installed equipment. As permitted by applicable law, we may de-identify and aggregate
                            information we collect from you.
                            <br />
                            We are also always looking for ways we can provide better Services to you. As a result, we
                            may develop new ways to collect or use (see also Section 2) your information. If the way we
                            collect and/or use your information is materially different from how it is described in this
                            Policy, we will notify you directly by updating this Policy or by providing specific
                            notification with a particular Service about how that Service materially alters the way your
                            information is collected and/or used.
                        </Paragraph>
                    </Grid>

                    {/* Collection End */}
                    {/* Use Begin */}
                    <Grid item xs={12} sx={cssObject.contentSecondaryHeading} ref={usePersonalInfoRef}>
                        <Heading isPopUp color={'primary'} label={'How We Use The Collected Information.'} />
                    </Grid>
                    <Grid item xs={12} sx={cssObject.content}>
                        <Paragraph align={'left'} size={'normal'}>
                            We use your personal information to provide you meaningful interactions with us, to fulfill
                            our contractual and legal obligations, to advertise to you about our products and services
                            or those offered by third parties, and to advance our legitimate business interests. We
                            describe below how we use or share (also see Section 3) the information we collect and
                            provide some examples of what we do.
                        </Paragraph>
                    </Grid>
                    <Grid item xs={12} sx={cssObject.content}>
                        <Paragraph align={'left'} size={'normal'}>
                            <b>Purpose 1—To Communicate With You:</b> We may use your personal information to send you
                            updates, technical information, warranty information, recall information, marketing
                            information, reviews or feedback information, or customer-service information related to the
                            Services or Equipment you used or purchased.
                        </Paragraph>
                    </Grid>
                    <Grid item xs={12} sx={cssObject.content}>
                        <Paragraph align={'left'} size={'normal'}>
                            <b>Purpose 2—To Provide Requested Information, Equipment, or Services:</b> We may use your
                            personal information to administer any account you may have with us, to provide
                            interactivity and automated actions by or through the Services and Equipment, to update,
                            repair or otherwise maintain the Services and Equipment, to transact commerce or billing, or
                            to personalize the Services and Equipment for you.
                        </Paragraph>
                    </Grid>
                    <Grid item xs={12} sx={cssObject.content}>
                        <Paragraph align={'left'} size={'normal'}>
                            <b>Purpose 3—To Comply With Our Contractual, Legal, and Regulatory Obligations:</b> We may
                            use your personal information to respond to legitimate requests from law enforcement,
                            regulatory bodies, or other competent authorities, to protect the safety, rights, and
                            property of us and others, to address legal claims and disputes, or to prevent or
                            investigate crime, fraud, cyber risks, and risks in general.
                        </Paragraph>
                    </Grid>
                    <Grid item xs={12} sx={cssObject.content}>
                        <Paragraph align={'left'} size={'normal'}>
                            <b>Purpose 4—To Establish a Potential Commercial or Employment Relationship With You:</b> We
                            may use your personal information to evaluate a potential business relationship with you,
                            including the potential extension of credit to you. And if you apply for employment or
                            currently are or previously were an employee, we may use your personal information to make
                            employment and personnel decisions or use the information to improve and manage our internal
                            processes and procedures.
                        </Paragraph>
                    </Grid>
                    <Grid item xs={12} sx={cssObject.content}>
                        <Paragraph align={'left'} size={'normal'}>
                            <b>Purpose 5—To Conduct Research and Development:</b> We may use your personal information
                            to improve our Services and Equipment or the services and equipment of third parties. We may
                            use your personal information to develop new offerings, develop and conduct training, and
                            maintain and improve the security of our Services and Equipment. And we may do this
                            internally, with our corporate affiliates, or with third parties.
                        </Paragraph>
                    </Grid>
                    <Grid item xs={12} sx={cssObject.content}>
                        <Paragraph align={'left'} size={'normal'}>
                            <b>
                                Purpose 6—To Analyze the Usage, Performance, Specifications, and Operation of the
                                Services and Equipment:
                            </b>{' '}
                            We may use your personal information to analyze the Equipment and Services you use and the
                            way you may be using them—often to provide customer support or help you resolve technical
                            issues. Some of our Equipment may be making inferences about your activity and the occupancy
                            within your environment by, for example, monitoring changes in ambient temperature or motion
                            adjacent the Equipment. We may also use your personal information to analyze our
                            performance, monitor trends, provide automated adjustment of the Equipment, investigate
                            suspected violations or malicious or illegal activity, and for internal business purposes
                            and development.
                        </Paragraph>
                    </Grid>
                    <Grid item xs={12} sx={cssObject.content}>
                        <Paragraph align={'left'} size={'normal'}>
                            <b>Purpose 7—To Advertise and Market to You:</b> We may use your personal information to
                            send you promotional offers, targeting advertising, and general marketing communications
                            (“Ads”), about our Services or Equipment or the services, equipment, or other offerings of
                            third parties. For example, we may send you Ads on behalf of third-party dealers and
                            distributors of our Equipment or Services or on behalf of third parties that provide
                            equipment and services that interact with or support ours; but when we provide such Ads, we
                            do not provide your personal information to those third-party dealers or distributors. Or we
                            may send you Ads regarding products or services—whether ours or those of third parties—that
                            we believe may be of interest to you. And we may use your personal information to understand
                            how you interact with these Ads.
                        </Paragraph>
                    </Grid>
                    <Grid item xs={12} sx={cssObject.content}>
                        <Paragraph align={'left'} size={'normal'}>
                            <b>Purpose 8—To Further any Purpose Described to You When the Information Was Collected.</b>
                        </Paragraph>
                    </Grid>
                    {/* Use End */}
                    {/* California Privacy Rights Begin */}
                    <Grid item xs={12} sx={cssObject.contentSecondaryHeading} ref={rightsRef}>
                        <Heading isPopUp color={'primary'} label={'California Privacy Rights'} />
                    </Grid>
                    <Grid item xs={12} sx={cssObject.content}>
                        <Paragraph align={'left'} size={'normal'}>
                            Under the California Consumer Privacy Act of 2018 (“CCPA”), certain consumers that are
                            residents of California have rights—which are subject to certain exceptions and
                            limitations—regarding their personal information. This California Privacy Notice (“CCPA
                            Notice” or “this Notice”) is provided here in Section 3 to comply with the CCPA, and all
                            terms defined in the CCPA shall have the same meaning when used in this Notice.
                            <br /> This Notice applies solely to consumers that reside in California and are entitled to
                            avail themselves of the CCPA. And, for those consumers, this CCPA Notice supplements and is
                            made a part of our Privacy Policy.
                        </Paragraph>
                    </Grid>
                    <Grid item xs={12} sx={cssObject.content}>
                        <Paragraph align={'left'} size={'normal'}>
                            <b>a) Information We Have Collected in the Past 12 Months</b>
                            <br /> The following chart—which is to be interpreted consistent with and in light of
                            Sections 1 and 2 above describing examples of our sources of personal information and the
                            purposes for which we use such information—summarizes the categories of personal information
                            of applicable California consumers we have collected, used, and disclosed in the last 12
                            months
                        </Paragraph>
                    </Grid>
                    <Grid item xs={12} sx={cssObject.content}>
                        <CustomizedTables />
                    </Grid>

                    <Grid item xs={12} sx={cssObject.content}>
                        <Paragraph align={'left'} size={'normal'}>
                            We have disclosed your personal information for a business purpose to the following
                            categories of parties.
                        </Paragraph>
                    </Grid>
                    <Grid item xs={12} sx={cssObject.content}>
                        {customBulletParagraph('Our corporate affiliates.')}
                        <br />
                        {customBulletParagraph('Service providers.')}
                        <br />
                        {customBulletParagraph('Dealers and distributors of our equipment. ')}
                        <br />
                        {customBulletParagraph(
                            'In response to a request pursuant to a governmental, administrative, or judicial proceeding. '
                        )}
                        <br />
                        {customBulletParagraph(
                            'Third parties to whom you or your agent authorized—either directly or by virtue of requesting services that one would reasonably understand necessitated our disclosure—us to disclose information in connection with the products and services we provide.'
                        )}
                        <br />
                    </Grid>
                    <Grid item xs={12} sx={cssObject.content}>
                        <Paragraph align={'left'} size={'normal'}>
                            <b>b) What is Not Personal Information</b> <br />
                            For the purposes of this Notice, the term “personal information” is to be interpreted
                            consistent with the CCPA and, thus, does not, among other things, include: 1) publicly
                            available information that is lawfully made available from federal, state, or local
                            government records; 2) consumer information that is deidentified; and 3) aggregate consumer
                            data.
                        </Paragraph>
                    </Grid>
                    <Grid item xs={12} sx={cssObject.content}>
                        <Paragraph align={'left'} size={'normal'}>
                            <b>c) CCPA Rights</b> <br />
                            <br />
                            <b>Right to Know:</b> Under the CCPA, California consumers—upon receipt and confirmation by
                            us of a verifiable request and in relation to information collected in the 12&nbsp;months
                            preceding the request - have certain rights to request
                        </Paragraph>
                    </Grid>
                    <Grid item xs={12} sx={cssObject.content}>
                        {customBulletParagraph('The categories of personal information we have collected about you, ')}
                        <br />
                        {customBulletParagraph(
                            'The categories of sources from which the personal information was collected, '
                        )}
                        <br />
                        {customBulletParagraph(
                            'The business or commercial purpose for collection or selling the personal information, '
                        )}
                        <br />
                        {customBulletParagraph(
                            'The categories of third parties with whom we share the personal information, and'
                        )}
                        <br />
                        {customBulletParagraph('The specific pieces of information we have collected about you; and ')}
                        <br />
                        {customBulletParagraph(
                            <span>
                                If we sold or disclosed for a business purpose the personal information, two separate
                                lists providing 1) a list of categories of personal information we have sold about
                                consumer in the preceding 12&nbsp;months and 2) a list of categories of personal
                                information we have disclosed about consumers for a business purpose in the preceding
                                12&nbsp;months.
                            </span>
                        )}
                        <br />
                    </Grid>
                    <Grid item xs={12} sx={cssObject.content}>
                        <Paragraph align={'left'} size={'normal'}>
                            <b>Right to Delete:</b> Under the CCPA, applicable California consumers—upon receipt and
                            confirmation by us of a verifiable request—have certain rights to request the deletion of
                            their personal information that we and our service providers have collected.
                        </Paragraph>
                    </Grid>
                    <Grid item xs={12} sx={cssObject.content}>
                        {customBulletParagraph(
                            'To complete the transaction for which we collected the personal information, provide a good or service that you requested, take actions reasonably anticipated within the context of our ongoing business relationship with you (e.g., warranty support), or otherwise perform a contract with you; '
                        )}
                        <br />
                        {customBulletParagraph(
                            'To detect security incidents, protect against malicious, deceptive, fraudulent, or illegal activity, or prosecute those responsible for such activities; '
                        )}
                        <br />
                        {customBulletParagraph(
                            'To debug products and services or to identify and repair errors that impair the existing intended functionality of products and services, including contacting you regarding recall information; '
                        )}
                        <br />
                        {customBulletParagraph(
                            'To exercise free speech, to ensure the right of another consumer to exercise their free speech rights, or to exercise another right provided by law; '
                        )}
                        <br />
                        {customBulletParagraph(
                            'To comply with the California Electronic Communications Privacy Act (CalECPA, SB 178); '
                        )}
                        <br />
                        {customBulletParagraph(
                            'To engage in public or peer-reviewed scientific, historical, or statistical research in the public interest that adheres to all other applicable ethics and privacy laws, when the information’s deletion may likely render impossible or seriously impair the research’s achievement, if you previously provided informed consent;'
                        )}
                        <br />
                        {customBulletParagraph(
                            'To enable our internal uses that are reasonably aligned with the expectation of a consumer based on that consumer’s relationship with us; '
                        )}
                        <br />
                        {customBulletParagraph('To comply with a legal obligation; or ')}
                        <br />
                        {customBulletParagraph(
                            'To use your personal information internally and in a lawful manner that is compatible with the context in which you provided that information.'
                        )}
                        <br />
                    </Grid>
                    <Grid item xs={12} sx={cssObject.content}>
                        <Paragraph align={'left'} size={'normal'}>
                            <b>Exercising your Rights to Know and Delete:</b> To exercise the above-described rights to
                            know and delete, you may
                        </Paragraph>
                    </Grid>
                    <Grid item xs={12} sx={cssObject.content}>
                        <Grid container>
                            <Grid item style={{ marginTop: '4px', display: 'flex', alignItems: 'flex-start' }}>
                                {Green()}
                            </Grid>
                            <Grid item xs={11} style={{ paddingLeft: '10px' }}>
                                <Paragraph size={'normal'}>
                                    Fill out and submit the webform at the{' '}
                                    <CommonLink
                                        fontColor={theme.palette.primary.main}
                                        href={'http://www.daikin-northamerica.com/ConsumerRequestForInfo'}
                                        label={'link'}
                                    />{' '}
                                    here or as also available at{' '}
                                    <CommonLink
                                        fontColor={theme.palette.primary.main}
                                        href={'http://www.daikin-northamerica.com/ConsumerRequestForInfo'}
                                        label={'www.daikin-northamerica.com/ConsumerRequestForInfo'}
                                    />
                                    , or
                                </Paragraph>
                            </Grid>
                        </Grid>

                        <br />
                        {customBulletParagraph(
                            'Call us at 1-(844)-353-4895 and leave us a voice message providing the us the information we request for us to process your request. '
                        )}
                        <br />
                    </Grid>
                    <Grid item xs={12} sx={cssObject.content}>
                        <Paragraph align={'left'} size={'normal'}>
                            However, please understand that we can only process your request if we can verify its
                            authenticity. A verifiable request must
                        </Paragraph>
                    </Grid>
                    <Grid item xs={12} sx={cssObject.content}>
                        {customBulletParagraph(
                            'Provide sufficient information that allows us to reasonably verify that you are the person about whom we have collected the information, and '
                        )}
                        <br />
                        {customBulletParagraph(
                            'Provide sufficient detail in your request to enable us to properly understand, evaluate, and respond to it.'
                        )}
                        <br />
                    </Grid>
                    <Grid item xs={12} sx={cssObject.content}>
                        <Paragraph align={'left'} size={'normal'}>
                            Please understand that under the CCPA, the materials and level of proof we require to verify
                            your request may depend upon the information you request be provided or deleted.
                        </Paragraph>
                    </Grid>
                    <Grid item xs={12} sx={cssObject.content}>
                        <Paragraph align={'left'} size={'normal'}>
                            You may also use an authorized agent to submit a request. However, we may deny that request
                            if the authorized agent fails to submit proof that the agent has been authorized to act on
                            your behalf or if we cannot verify the agent’s identity. We will typically respond to your
                            agent’s request if that agent submits proof of being registered with the California
                            Secretary of State to be able to act on your behalf or submits proof that you have provided
                            that agent with a power of attorney pursuant to California Probate Code sections 4000 to
                            4465.
                        </Paragraph>
                    </Grid>
                    <Grid item xs={12} sx={cssObject.content}>
                        <Paragraph align={'left'} size={'normal'}>
                            <b>Right to Opt-Out:</b> California residents have the right to opt-out of the “sale” of
                            their information under the CCPA. We do not sell your personal information unless we have
                            your consent. In the proceeding 12 months we have not sold any personal information, and we
                            have no plans of doing so. If our practices change and we do “sell” information to third
                            parties, we will notify you by, for example, amending this Privacy Policy and taking actions
                            as required by applicable law.
                        </Paragraph>
                    </Grid>
                    <Grid item xs={12} sx={cssObject.content}>
                        <Paragraph align={'left'} size={'normal'}>
                            <b>Non-discrimination:</b> We do not discriminate when you exercise any of your consumer
                            rights under the CCPA.{' '}
                        </Paragraph>
                    </Grid>
                    <Grid item xs={12} sx={cssObject.content}>
                        <Paragraph align={'left'} size={'normal'}>
                            <b>d) Shine the Light Provisions</b> If you are resident of California, you may have certain
                            rights under California’s “Shine-the-Light” Law (California Civil Code § 1798.83). While we
                            have not done so at the date of this Notice, we may elect to share the personal information
                            of California residents with third parties for those third parties’ direct marketing
                            purposes. If you are a California resident, you may ask us to refrain from sharing your
                            information with third parties for their direct marketing purposes by contacting us at{' '}
                            <CommonLink
                                fontColor={theme.palette.primary.main}
                                href={'mailto:cs@airandheatdoneright.com'}
                                label={'cs@airandheatdoneright.com'}
                            />
                            . To assist us, please place “Shine the Light Opt-Out” in the memo line of your email.
                        </Paragraph>
                    </Grid>
                    {/* California Privacy Rights End */}
                    {/* Sharing Begin */}
                    <Grid item xs={12} sx={cssObject.contentSecondaryHeading} ref={sharingRef}>
                        <Heading isPopUp color={'primary'} label={'Sharing'} />
                    </Grid>
                    <Grid item xs={12} sx={cssObject.content}>
                        <Paragraph align={'left'} size={'normal'}>
                            We may share your personal information with the following persons or entities, for the
                            following purposes or for purposes as otherwise described in this Policy.
                        </Paragraph>
                    </Grid>
                    <Grid item xs={12} sx={cssObject.content}>
                        <Paragraph align={'left'} size={'normal'}>
                            <b>With our corporate affiliates.</b> We are a wholly owned subsidiary of Daikin Industries,
                            Ltd. (“DIL”), which is based in Osaka, Japan. We may share information with DIL and its
                            current or future subsidiaries that DIL has direct or indirect control of (collectively “the
                            Daikin Group”) to perform one or more of the uses described in this Policy. Some members of
                            the Daikin Group use their own privacy policy, which may be different from this Policy.
                        </Paragraph>
                    </Grid>
                    <Grid item xs={12} sx={cssObject.content}>
                        <Paragraph align={'left'} size={'normal'}>
                            <b>With service providers.</b> We may share information with third party service
                            providers—such as agents, contractors, dealers, distributors, or other companies—to provide
                            Services or Equipment on our behalf, to assist us with the provision or development of such,
                            or to help us with our internal processes. For example, we may engage third-party service
                            providers to provide marketing, advertising, communications, infrastructure, IT, or
                            processing services. Or we, or other members of the Daikin Group, may work with third-party
                            HVAC technicians, service providers, or dealers to provide the Services or Equipment to you
                            on our behalf. Further still, we may work with third parties to develop and improve our
                            Services or Equipment.
                        </Paragraph>
                    </Grid>
                    <Grid item xs={12} sx={cssObject.content}>
                        <Paragraph align={'left'} size={'normal'}>
                            <b>With third parties.</b> We may share information with third parties—dealers,
                            distributors, other companies, or contractors—that provide our Services or Equipment to you.
                            As one example, we may share your personal information with dealers and distributors of our
                            equipment to help us maintain or improve the Equipment we provided to you, to help us manage
                            our relationship with you, or as otherwise necessary for the operation of our business. We
                            may also provide your personal information to dealer and distributors of our equipment to
                            help them fulfill or support their obligations to you, such as providing warranty service or
                            repair or distributing recall information.
                        </Paragraph>
                    </Grid>
                    <Grid item xs={12} sx={cssObject.content}>
                        <Paragraph align={'left'} size={'normal'}>
                            Some of our Services or Equipment may link to products or services of third parties whose
                            privacy policies may be different than ours. If you provide information to a third party,
                            then their privacy policy will control the information you provide to them.
                        </Paragraph>
                    </Grid>
                    <Grid item xs={12} sx={cssObject.content}>
                        <Paragraph align={'left'} size={'normal'}>
                            <b>For legal proceedings.</b> We may share information as required for legal proceedings and
                            processes, including i) responding to requests for information from public, regulatory,
                            judicial, governmental, or law-enforcement agencies, ii) complying with our legal
                            obligations or when we, in good faith, believe the law requires it, iii) investigating or
                            enforcing potential violations by you of our terms and policies, or iv) protecting the
                            rights, property, or safety of us or others. For corporate reorganization, M&A. We may share
                            information in relation to any potential or realized reorganization, merger, sale,
                            liquidation, bankruptcy, or transfer of a portion or all of our assets or businesses.
                        </Paragraph>
                    </Grid>
                    <Grid item xs={12} sx={cssObject.content}>
                        <Paragraph align={'left'} size={'normal'}>
                            <b>For corporate reorganization, M&A.</b> We may share information in relation to any
                            potential or realized reorganization, merger, sale, liquidation, bankruptcy, or transfer of
                            a portion or all of our assets or businesses
                        </Paragraph>
                    </Grid>
                    <Grid item xs={12} sx={cssObject.content}>
                        <Paragraph align={'left'} size={'normal'}>
                            <b>With your consent.</b> We may share information collected from you for any purpose that
                            you consented to, that you directed us to do, or that is reasonable required to accomplish a
                            task or Service you directed us to do.
                        </Paragraph>
                    </Grid>
                    <Grid item xs={12} sx={cssObject.content}>
                        <Paragraph align={'left'} size={'normal'}>
                            We do not sell your personal information to third parties unless we have your consent.
                        </Paragraph>
                    </Grid>
                    <Grid item xs={12} sx={cssObject.content}>
                        <Paragraph align={'left'} size={'normal'}>
                            However, we may, without your consent, provide third parties information that has been
                            de-identified, aggregated, anonymized, or otherwise processed so that it does not reasonably
                            identify you to, for example, perform research, advertising, marketing, internal business,
                            strategic planning, vendor management, and development on our or the third party’s behalf.
                        </Paragraph>
                    </Grid>
                    {/* Sharing End */}
                    {/* Retention Begin */}
                    <Grid item xs={12} sx={cssObject.contentSecondaryHeading} ref={retentionRef}>
                        <Heading isPopUp color={'primary'} label={'Retention'} />
                    </Grid>
                    <Grid item xs={12} sx={cssObject.content}>
                        <Paragraph align={'left'} size={'normal'}>
                            We may retain your personal information as long as necessary to accomplish the purposes and
                            uses outlined in this Policy, but no longer than is permitted by applicable law. When and
                            where required by applicable law, we will delete your information. But even when we delete
                            your information from active databases, such information may be retained in dormancy on
                            automatic back-ups.
                        </Paragraph>
                    </Grid>
                    <Grid item xs={12} sx={cssObject.content}>
                        <Paragraph align={'left'} size={'normal'}>
                            Your personal information may be stored locally on the Equipment, locally on devices
                            connected to the Equipment or Services, or remotely on servers located in the United States
                            or other jurisdictions. (Also see Sections 6 & 7 below.)
                        </Paragraph>
                    </Grid>
                    {/* Retention End */}
                    {/* Security Begin */}
                    <Grid item xs={12} sx={cssObject.contentSecondaryHeading} ref={securityRef}>
                        <Heading isPopUp color={'primary'} label={'Security'} />
                    </Grid>
                    <Grid item xs={12} sx={cssObject.content}>
                        <Paragraph align={'left'} size={'normal'}>
                            We use reasonable administrative, physical, and technological security measures to safeguard
                            your personal information against theft or other unauthorized access, use, or modification.
                            And we require relevant third parties to, through contracts, do the same. The security
                            measures we use are designed to provide a level of security appropriate to that personal
                            information.
                        </Paragraph>
                    </Grid>
                    <Grid item xs={12} sx={cssObject.disclaimer}>
                        <br />
                        <Paragraph semibold size={'normal'}>
                            UNFORTUNATELY, NO SECURITY MEASURE OR COMBINATION OF SECURITY MEASURES IS PERFECT. AS SUCH,
                            WE CANNOT GUARANTEE THE SECURITY OF YOUR PERSONAL INFORMATION THAT WE HAVE COLLECTED.
                        </Paragraph>
                        <br />
                    </Grid>

                    {/* Security End */}
                    {/* Transfers Begin */}
                    <Grid item xs={12} sx={cssObject.contentSecondaryHeading} ref={transfersRef}>
                        <Heading isPopUp color={'primary'} label={'Transfers'} />
                    </Grid>
                    <Grid item xs={12} sx={cssObject.content}>
                        <Paragraph align={'left'} size={'normal'}>
                            The Services are intended for users in the United States. We are based in the US, and that
                            is where we primarily process and store personal information. However, there may be
                            instances where personal information is transferred internationally. For example, you may be
                            using our Services from outside the United States. If that is the case, you, by using the
                            Services, consent to the international transfer of your personal information, to the extent
                            provided by applicable law.
                        </Paragraph>
                    </Grid>
                    <Grid item xs={12} sx={cssObject.content}>
                        <Paragraph align={'left'} size={'normal'}>
                            As another example, your personal information may have been collected not by us but by
                            another member of the Daikin Group that may be located inside or outside the United States
                            and that may have a privacy policy different from this Policy. When and where necessary, we
                            take appropriate measures—such as contractual agreements—when such information is
                            transferred to us. For example, if your personal information was collected from the European
                            Economic Area (“EEA”), we use a variety of legal mechanisms to help ensure your personal
                            information is appropriately protected when we transfer it to other countries, including the
                            United States. For example, we may use “model contract clauses” that are approved by the
                            European Commission when we receive personal information from a member of the Daikin Group
                            located in the EEA or when we transfer personal information we have collected from the EEA
                            to another jurisdiction.
                        </Paragraph>
                    </Grid>
                    <Grid item xs={12} sx={cssObject.content}>
                        <Paragraph align={'left'} size={'normal'}>
                            Moreover, we may transfer your personal information to third parties—such as service
                            providers that provide processing services for the information or researchers that are
                            helping develop and improve our Services and Equipment—that may be located or have
                            facilities located around the globe, including in countries that may not provide equivalent
                            levels of protection as provided in your home jurisdiction. When and where necessary under
                            applicable law, we use a variety of legal mechanisms to help ensure your personal
                            information is appropriately protected when we transfer it to or within other countries. For
                            example, we may use “model contract clauses” that are approved by the European Commission
                            for personal information from the EEA.
                        </Paragraph>
                    </Grid>
                    {/* Transfers End */}

                    {/* EEA Individuals Begin */}
                    <Grid item xs={12} sx={cssObject.contentSecondaryHeading} ref={induvidualsRef}>
                        <Heading isPopUp color={'primary'} label={'EEA Individuals'} />
                    </Grid>
                    <Grid item xs={12} sx={cssObject.content}>
                        <Paragraph align={'left'} size={'normal'}>
                            Our Services are intended for users located in the United States. But, to the extent you are
                            in the EEA, you may have certain rights under the law regarding the collection and
                            processing of the personal information you have provided to us. In such cases, we will
                            process your personal information only in the following instances:{' '}
                        </Paragraph>
                    </Grid>
                    <Grid item xs={12} sx={cssObject.content}>
                        <Paragraph align={'left'} size={'normal'}>
                            <b>You have given consent.</b> We may use your personal information if you have given us
                            your consent for one or more specific purposes. For example, you may consent to digital
                            marketing or the use of cookies on our website. You may withdraw that consent at any time.
                            You can manage or disable cookies at any time by adjusting your browser settings (also see
                            Section 10).{' '}
                        </Paragraph>
                    </Grid>
                    <Grid item xs={12} sx={cssObject.content}>
                        <Paragraph align={'left'} size={'normal'}>
                            <b>To fulfill our obligations to you.</b> We may use your personal information to fulfill
                            our contractual obligations to you. For example, we may need to use your personal
                            information because it is reasonably needed to provide the Services or Equipment you have
                            requested, or to take requested steps for you before we enter into a contract.
                        </Paragraph>
                    </Grid>
                    <Grid item xs={12} sx={cssObject.content}>
                        <Paragraph align={'left'} size={'normal'}>
                            <b>To fulfill a legal obligation.</b> We may use your personal information to comply with a
                            legal obligation we are subject to.
                        </Paragraph>
                    </Grid>
                    <Grid item xs={12} sx={cssObject.content}>
                        <Paragraph align={'left'} size={'normal'}>
                            <b>To protect a vital interest.</b> We may use your personal information to protect your
                            vital interests or those of another person, or if necessary to perform a task carried out in
                            the public interest or in the exercise of official authority vested in us.
                        </Paragraph>
                    </Grid>
                    <Grid item xs={12} sx={cssObject.content}>
                        <Paragraph align={'left'} size={'normal'}>
                            <b>For a legitimate interest.</b> We may use your personal information if we have a
                            legitimate interest in doing so. For example, we may use your personal information to send
                            you updates about the Services and Equipment you used or purchased, or we may use your
                            personal information to provide and improve the Services and Equipment.
                        </Paragraph>
                    </Grid>
                    <Grid item xs={12} sx={cssObject.content}>
                        <Paragraph align={'left'} size={'normal'}>
                            To the extent that you are in the EEA, you have certain rights to access the personal
                            information we hold about you. And you may ask us, to the extent provided by applicable law,
                            to correct, erase, or restrict the processing of that information. If you would like to
                            exercise one of those rights, please contact us at:{' '}
                            <CommonLink
                                fontColor={theme.palette.primary.main}
                                href={'mailto:cs@airandheatdoneright.com'}
                                label={'cs@airandheatdoneright.com'}
                            />
                            . Moreover, as we are a member of the Daikin Group, you may also address any questions or
                            complaints you have to the Data Protection Officer for the Daikin Group by using{' '}
                            <CommonLink
                                fontColor={theme.palette.primary.main}
                                href={'http://www.daikin-northamerica.com/ConsumerRequestForInfo'}
                                label={'this online form'}
                            />{' '}
                            or by sending mail to:
                        </Paragraph>
                    </Grid>
                    <Grid item xs={12} sx={cssObject.content}>
                        <Paragraph align={'left'} size={'normal'}>
                            Daikin Industries, Ltd.br
                            <br /> c/o Global Data Protection Officer
                            <br /> Umeda Center Building
                            <br /> 2-4-12, Nakazaki-Nishi, Kita-Ku, Osaka 530-8323, Japan
                            <br /> To the extent that you are in the EEA and if you have questions or complaints that we
                            are not able to resolve, you have the right to submit a complaint to the data privacy
                            authority where you reside.
                        </Paragraph>
                    </Grid>
                    {/* EEA Individuals End */}

                    {/* Minors Begin */}
                    <Grid item xs={12} sx={cssObject.contentSecondaryHeading} ref={minorsRef}>
                        <Heading isPopUp color={'primary'} label={'Minors'} />
                    </Grid>
                    <Grid item xs={12} sx={cssObject.content}>
                        <Paragraph align={'left'} size={'normal'}>
                            Our Services are intended for those who are 18 years of age or older. However, if you are
                            between the ages of 13 (or the equivalent minimum age in your jurisdiction) and 18, you
                            agree you will only use the Services under the direct supervision of a legal guardian or
                            parent who has agreed to our{' '}
                            <CommonLink
                                fontColor={theme.palette.primary.main}
                                href={AppRoute.TermsAndConditions}
                                label={'Terms'}
                            />{' '}
                            and this Policy for themselves and on your behalf. You may not use our Services if you are
                            under 13 years of age. We do not knowingly collect or store any personal information from
                            anyone under the age of 18 unless we believe we have received consent from that person’s
                            legal guardian or parent who has agreed to our{' '}
                            <CommonLink
                                fontColor={theme.palette.primary.main}
                                href={AppRoute.TermsAndConditions}
                                label={'Terms'}
                            />{' '}
                            and this Policy.
                        </Paragraph>
                    </Grid>
                    {/* Minors End */}
                    {/* Automatically Collected Information Begin */}
                    <Grid item xs={12} sx={cssObject.contentSecondaryHeading} ref={infoRef}>
                        <Heading isPopUp color={'primary'} label={'Automatically Collected Information'} />
                    </Grid>
                    <Grid item xs={12} sx={cssObject.content}>
                        <Paragraph align={'left'} size={'normal'}>
                            Our Services are intended for those who are 18 years of age or older. However, if you are
                            between the ages of 13 (or the equivalent minimum age in your jurisdiction) and 18, you
                            agree you will only use the Services under the direct supervision of a legal guardian or
                            parent who has agreed to our Terms and this Policy for themselves and on your behalf. You
                            may not use our Services if you are under 13 years of age. We do not knowingly collect or
                            store any personal information from anyone under the age of 18 unless we believe we have
                            received consent from that person’s legal guardian or parent who has agreed to our Terms and
                            this Policy.{' '}
                        </Paragraph>
                    </Grid>
                    <Grid item xs={12} sx={cssObject.content}>
                        {customBulletParagraph(
                            <span>
                                <b>Cookies</b> are small files that are stored on your device (e.g., computer, mobile
                                phone) when you visit or access some of our Services through a web browser. Cookies can,
                                among other things, remember your preferences, remember your login information, and
                                track your visits to our websites. By doing this, the cookies help us customize your
                                experience with our Services. We use the following types of cookies:
                            </span>
                        )}
                        <br />
                        {customBulletParagraph(
                            <span>
                                <b>Necessary cookies:</b> These cookies are essential to enable basic functions on the
                                website and make the website usable. Once you close your browser, these cookies
                                disappear.
                            </span>,
                            true
                        )}
                        <br />
                        {customBulletParagraph(
                            <span>
                                <b>Functional cookies:</b> These cookies enable the website to save information which
                                has already been entered (such as language choices or your location), so that we can
                                offer you more personalized functions. These cookies collect anonymous information and
                                cannot track your movements on other websites.
                            </span>,
                            true
                        )}
                        <br />
                        {customBulletParagraph(
                            <span>
                                <b>Performance cookies:</b> These cookies are used for collecting information about the
                                use of our website, including the number of unique visitors to our website, the number
                                of views for a webpage, the amount of time a user spends on a webpage, and other website
                                statistics. These cookies are used to improve the performance of the website and the
                                user experience. The collected information is aggregated and made anonymous.
                            </span>,
                            true
                        )}
                        <br />
                        {customBulletParagraph(
                            <span>
                                <b>Web Beacons</b> are small files that collect advertising data. For example, a Web
                                Beacon may count page views or responses to our advertising—allowing us to provide
                                information that may be more relevant to you.
                            </span>
                        )}
                        <br />
                        {customBulletParagraph(
                            <span>
                                <b>Website Analytics</b> We use a web analytics service, Google Analytics. This service
                                uses cookies to collect information such as your IP address or other identifiers,
                                browser information, and the content you view. Google Analytics uses this information on
                                our behalf for the purpose of analyzing how visitors use our website, the number of
                                users on our website, the location of our users, the most frequently used parts of our
                                website, the most commonly used browsers of visitors to our website, and the interests
                                of our users.
                            </span>
                        )}
                        <br />
                    </Grid>
                    <Grid item xs={12} sx={cssObject.content}>
                        <Paragraph align={'left'} size={'normal'}>
                            You can manage or disable cookies at any time by adjusting your browser settings. You can
                            also set your browser to notify you when you receive certain cookies, giving you the chance
                            to decide whether to accept it or not. Browsers are different, so refer to the settings menu
                            of your browser for instructions on how to change your cookie preferences. If you choose not
                            to receive cookies, our website may not function properly and certain services may not be
                            available. You can find out more information about cookies and how to manage them by
                            visiting the website{' '}
                            <CommonLink
                                fontColor={theme.palette.primary.main}
                                href={'https://www.allaboutcookies.org'}
                                label={'www.allaboutcookies.org'}
                            />
                            . If you have questions or comments about the use of cookies on our website, please contact
                            us at{' '}
                            <CommonLink
                                fontColor={theme.palette.primary.main}
                                href={'mailto:cs@airandheatdoneright.com'}
                                label={'cs@airandheatdoneright.com'}
                            />
                            .
                        </Paragraph>
                    </Grid>
                    {/* Automatically Collected Information End */}
                    {/* Your Choices Begin */}
                    <Grid item xs={12} sx={cssObject.contentSecondaryHeading} ref={choicesRef}>
                        <Heading isPopUp color={'primary'} label={'Your Choices'} />
                    </Grid>
                    <Grid item xs={12} sx={cssObject.content}>
                        <Paragraph align={'left'} size={'normal'}>
                            By providing your personal information to us, you agree to the collection, processing,
                            international transfer and use of that information as set forth in this Policy. Where
                            required by applicable law, we will ask for your explicit consent.
                        </Paragraph>
                    </Grid>
                    <Grid item xs={12} sx={cssObject.content}>
                        <Paragraph align={'left'} size={'normal'}>
                            You may also have the right to lodge a complaint with a supervisory authority.
                        </Paragraph>
                    </Grid>
                    {/* Your Choices End */}
                    {/* Direct Marketing Begin */}
                    <Grid item xs={12} sx={cssObject.contentSecondaryHeading} ref={marketingRef}>
                        <Heading isPopUp color={'primary'} label={'Direct Marketing'} />
                    </Grid>
                    <Grid item xs={12} sx={cssObject.content}>
                        <Paragraph align={'left'} size={'normal'}>
                            We may process personal information provided by you for marketing purposes. You may have the
                            right to opt-out of our use of your personal information for electronic marketing purposes.
                            If you have received electronic marketing communications from us and would like to submit an
                            opt-out request for these marketing communications, you can let us know by email at{' '}
                            <CommonLink
                                fontColor={theme.palette.primary.main}
                                href={'mailto:cs@airandheatdoneright.com'}
                                label={'cs@airandheatdoneright.com'}
                            />
                            . You may also use, if provided, the opt-out mechanism at the bottom of our electronic
                            marketing communications to change your marketing preferences or opt-out of all electronic
                            marketing communications.
                        </Paragraph>
                    </Grid>
                    {/* Direct Marketing End */}
                    {/* Contact Us Begin */}
                    <Grid item xs={12} sx={cssObject.contentSecondaryHeading} ref={contactRef}>
                        <Heading isPopUp color={'primary'} label={'Contact Us'} />
                    </Grid>
                    <Grid item xs={12} sx={cssObject.content}>
                        <Paragraph align={'left'} size={'normal'}>
                            If you have any questions about this Privacy Policy or the information we have collected
                            about you, please contact us by email at{' '}
                            <CommonLink
                                fontColor={theme.palette.primary.main}
                                href={'mailto:cs@airandheatdoneright.com'}
                                label={'cs@airandheatdoneright.com'}
                            />{' '}
                            or by mail
                            <br />
                            at Daikin Texas Technology Park
                            <br /> Attn: Legal, IP
                            <br /> 19001 Kermier Rd.,
                            <br /> Waller, TX 77484.
                        </Paragraph>
                    </Grid>
                    {/* Contact Us End */}
                </Fragment>
            }
        />
    );
}
function createData(columnOne: string, columnTwo: string) {
    return { columnOne, columnTwo };
}

const privacyRows = [
    createData('A. Identifiers, such as your name, your mailing address.', 'Yes'),
    createData(
        'B. Personal information categories listed in the California Customer Records Statute (Cal. Civ. Code Section 1798.80(e)), such as your signature or government id number.',
        'Yes'
    ),
    createData(
        'C. Protected classifications under California or Federal Law, such as sex or military or marital status.',
        'Yes'
    ),
    createData(
        'D. Commercial information, such as property records, purchase history, and use of equipment manufactured by us.',
        'Yes'
    ),
    createData(
        'E. Biometric information, such as health data or identifying physical characteristics.',
        'No. (However, we may make may inferences about your activity levels based on data from the Services. See Category J.)'
    ),
    createData('F. Usage data, such as Internet or network usage information.', 'Yes'),
    createData('G. Geolocation data, such as your location from an IP address or mobile device.', 'Yes'),
    createData('H. Sensed data, such as thermal information.', 'Yes'),
    createData('I. Professional or employment-related information, such as work history.', 'Yes'),
    createData('J. Non-public education information, such as your transcripts.', 'Yes'),
    createData(
        'K. Inferences from the foregoing categories, such as your activities levels based on how you use our equipment or inferences about a desire to purchase or service equipment.',
        'Yes'
    ),
];
function CustomizedTables() {
    return (
        <TableContainer component={Paper} style={{ borderRadius: '0px' }}>
            <Table aria-label="customized table">
                <TableHead style={{ backgroundColor: '#F2F8ED' }}>
                    <TableRow>
                        <TableCell>Category of Personal Information</TableCell>
                        <TableCell align="left">Collected (Yes/No)</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {privacyRows.map((row) => (
                        <TableRow key={row.columnOne}>
                            <TableCell component="th" scope="row">
                                {row.columnOne}
                            </TableCell>
                            <TableCell align="left">{row.columnTwo}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
}
export default SecurityAndPrivacyPolicy;
