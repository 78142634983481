import { CartItem, ItemType, SystemGroup } from 'models/cart';
import { GenerateQuoteItems } from 'models/quotes';

export const prepareObjectForGenerateQuote = (itemsList: CartItem[], taxPercent?: number): GenerateQuoteItems[] => {
    return itemsList.map((item) => {
        let generateQuoteItem: GenerateQuoteItems = {};
        generateQuoteItem.sku = item!.sku;
        generateQuoteItem.quantity = item!.quantity;
        if (taxPercent) {
            generateQuoteItem.taxPercent = taxPercent;
        }
        const itemType: any = item.itemType;
        if (itemType === 'MAIN_SYSTEM') {
            item.itemType = ItemType.MainSystem;
        } else if (itemType === 'CROSS_SELL') {
            item.itemType = ItemType.CrossSell;
        } else {
            generateQuoteItem.itemType = item.itemType;
        }
        if (!item.systemGroup) {
            generateQuoteItem.systemGroup = SystemGroup.SystemGroup1;
        } else {
            generateQuoteItem.systemGroup = item.systemGroup;
        }

        return generateQuoteItem;
    });
};
