import React, { Fragment } from 'react';
import { Props as MenuProps } from 'components/SecondaryNavigation';
import { Theme, useTheme } from '@mui/material/styles';
import { Box, Divider } from '@mui/material';
import SubPage from 'components/subpage/SubPage';
import Grid from '@mui/material/Grid';
import CardMedia from '@mui/material/CardMedia';
import { Heading } from 'components/Heading';
import { Paragraph } from 'components/Paragraph';
import Image from 'assets/images/knowledges/kc_indoor_air_quality_bundles.webp';
import { getSubPageMenus } from './menu-util';
import { tileColor } from 'components/icons/Level';
import Silver from 'components/icons/Silver';
import Bronze from 'components/icons/Bronze';
import Gold from 'components/icons/Gold';
import Platinum from 'components/icons/Platinum';
import { SXCssObject } from 'models/ThemeImages';

function IndoorAirQuality() {
    const createCssObject = (theme: Theme): SXCssObject => {
        return {
            contentHeading: {
                paddingBottom: theme.spacing(2),
            },
            contentInteractive: {
                paddingTop: theme.spacing(4),
                paddingBottom: theme.spacing(4),
            },
            contentSecondaryHeading: {
                paddingTop: {
                    xs: theme.spacing(2),
                    sm: theme.spacing(4),
                },
                paddingBottom: theme.spacing(2),
            },
            content: {
                paddingTop: theme.spacing(2),
                paddingBottom: theme.spacing(3),
            },
            boldContent: {
                fontWeight: theme.typography.fontWeightMedium,
                fontSize: '1rem',
            },
            package: {
                padding: '10px',
                fontWeight: theme.typography.fontWeightMedium,
                fontSize: '1.25rem',
                display: 'inline-block',
                verticalAlign: 'middle',
            },
            bronzePackageContainer: {
                backgroundColor: `${tileColor('Bronze')}`,
                padding: '10px',
                borderRadius: '4px',
            },
            silverPackageContainer: {
                backgroundColor: `${tileColor('Silver')}`,
                padding: '10px',
                borderRadius: '4px',
            },
            goldPackageContainer: {
                backgroundColor: `${tileColor('Gold')}`,
                padding: '10px',
                borderRadius: '4px',
            },
            platinumPackageContainer: {
                backgroundColor: `${tileColor('Platinum')}`,
                padding: '10px',
                borderRadius: '4px',
            },
            stepsHeader: {
                padding: '10px 0px',
            },
            stepsInfo: {
                marginBottom: '20px',
            },
            packageHeading: {
                margin: '15px 0px',
            },
            divider: {
                border: `1px solid ${theme.palette.divider}`,
                opacity: '0.4',
            },
            hollowRing: {
                marginRight: '5px',
                display: 'flex',
                alignItems: 'center',
            },
        };
    };

    const menu: MenuProps = {
        id: 'menu',
        label: 'More Knowledge Center Topic',
        color: 'primary',
        subMenus: getSubPageMenus('indoor_air_quality_bundles'),
    };
    const cssObject: SXCssObject = createCssObject(useTheme());
    return (
        <SubPage
            menu={menu}
            content1={
                <Fragment>
                    <Grid item xs={12} sx={cssObject.contentHeading}>
                        <Heading variant={'h3'} isPopUp={true} color={'primary'} label={'Indoor Air Quality Bundles'} />
                    </Grid>
                    <Grid item xs={12} sx={cssObject.contentImage}>
                        <CardMedia component={'img'} image={Image} title={'Heat Pumps'} />
                    </Grid>
                    <Grid item xs={12} sx={cssObject.content}>
                        <Grid container sx={cssObject.bronzePackageContainer} alignItems={'center'}>
                            <Grid item sx={cssObject.hollowRing}>
                                {Bronze()}
                            </Grid>
                            <Paragraph headingStyle bold responsiveFontSize18>
                                Bronze
                            </Paragraph>
                        </Grid>
                        <Box sx={cssObject.packageHeading}>
                            <Paragraph headingColor bold responsiveFontSize18>
                                IAQ Bundle - Air Cleaner & UV Coil Light
                            </Paragraph>
                        </Box>
                        <Paragraph responsiveFontSize16 align={'left'}>
                            The Bronze package improves indoor air quality for your family by including a 5” MERV 11
                            media air cleaner and a 16” dual-lamp UV coil light. This bundle filters dust, dander, and
                            other allergens down to 0.3 microns. Additionally, the coil light employs UV technology to
                            inhibit the growth of bacteria, viruses, and other microbes on the evaporator coil inside
                            your heating and cooling system.
                        </Paragraph>
                    </Grid>
                    <Grid item xs={12} sx={cssObject.content}>
                        <Grid container alignItems={'center'} sx={cssObject.silverPackageContainer}>
                            <Grid item sx={cssObject.hollowRing}>
                                {Silver()}
                            </Grid>
                            <Paragraph headingStyle bold responsiveFontSize18>
                                Silver
                            </Paragraph>
                        </Grid>
                        <Box sx={cssObject.packageHeading}>
                            <Paragraph headingColor bold responsiveFontSize18>
                                IAQ Bundle - Electronic Air Cleaner, UV Coil Light & Purifier
                            </Paragraph>
                        </Box>
                        <Paragraph responsiveFontSize16 align={'left'}>
                            The Silver package further improves indoor air quality for your family by upgrading to an
                            electronic air cleaner with a four-stage filtration system to attract and trap additional
                            air debris and remove unpleasant odors. In addition to the UV coil light, this bundle
                            includes the Clean Comfort® Whole Home UA Series air purifier to help sterilize indoor air
                            and remove harmful contaminants without harsh chemicals.
                        </Paragraph>
                    </Grid>
                    <Grid item xs={12} sx={cssObject.content}>
                        <Grid container alignItems={'center'} sx={cssObject.goldPackageContainer}>
                            <Grid item sx={cssObject.hollowRing}>
                                {Gold()}
                            </Grid>
                            <Paragraph headingStyle bold responsiveFontSize18>
                                Gold
                            </Paragraph>
                        </Grid>
                        <Box sx={cssObject.packageHeading}>
                            <Paragraph headingColor bold responsiveFontSize18 align={'left'}>
                                Electronic Air Cleaner, UV Coil Light & Purifier with a HEPA Bypass
                            </Paragraph>
                        </Box>
                        <Paragraph responsiveFontSize16 align={'left'}>
                            The Gold package further enhances the quality of air in your home by adding a Clean Comfort®
                            bypass HEPA filtration system is certified to remove 99.97% of all particles as small as 0.3
                            microns from a home’s air. This system also includes an activated carbon prefilter to
                            attract and trap additional air debris and remove unpleasant odors.
                        </Paragraph>
                    </Grid>
                    <Grid item xs={12} sx={cssObject.content}>
                        <Grid container alignItems={'center'} sx={cssObject.platinumPackageContainer}>
                            <Grid item sx={cssObject.hollowRing}>
                                {Platinum()}
                            </Grid>
                            <Paragraph headingStyle bold responsiveFontSize18>
                                Platinum
                            </Paragraph>
                        </Grid>
                        <Box sx={cssObject.packageHeading}>
                            <Paragraph headingColor bold responsiveFontSize18>
                                Electronic Air Cleaner, UV Coil Light & Purifier with an Advanced HEPA Air Cleaner
                            </Paragraph>
                        </Box>
                        <Paragraph responsiveFontSize16 align={'left'}>
                            The Platinum package adds our most advanced whole home HEPA air cleaner system with five
                            stages of air treatment to clean and sterilize the air in your home.
                        </Paragraph>
                    </Grid>

                    <Grid item xs={12} sx={cssObject.content}>
                        <Divider sx={cssObject.divider} />
                        <Box sx={cssObject.stepsHeader}>
                            <Paragraph semibold headingColor responsiveFontSize18>
                                Stage 1
                            </Paragraph>
                        </Box>
                        <Box sx={cssObject.stepsInfo}>
                            <Paragraph semiLargeFont={true} align={'left'}>
                                The VOC prefilter removes lint, hair and large particles.
                            </Paragraph>
                        </Box>
                        <Divider sx={cssObject.divider} />
                        <Box sx={cssObject.stepsHeader}>
                            <Paragraph semibold headingColor responsiveFontSize18>
                                Stage 2
                            </Paragraph>
                        </Box>
                        <Box sx={cssObject.stepsInfo}>
                            <Paragraph semiLargeFont={true} align={'left'}>
                                The HEPA filtration system is certified to remove 99.97% of all particles as small as
                                0.3 microns from a home's air.
                            </Paragraph>
                        </Box>
                        <Divider sx={cssObject.divider} />
                        <Box sx={cssObject.stepsHeader}>
                            <Paragraph semibold headingColor responsiveFontSize18>
                                Stage 3
                            </Paragraph>
                        </Box>
                        <Box sx={cssObject.stepsInfo}>
                            <Paragraph semiLargeFont={true} align={'left'}>
                                Non-ozone creating dual UV lamps emit powerful UVC light rays that deactivate bacteria,
                                viruses, mold, and microbial growth.
                            </Paragraph>
                        </Box>
                        <Divider sx={cssObject.divider} />
                        <Box sx={cssObject.stepsHeader}>
                            <Paragraph semibold headingColor responsiveFontSize18>
                                Stage 4
                            </Paragraph>
                        </Box>
                        <Box sx={cssObject.stepsInfo}>
                            <Paragraph semiLargeFont={true} align={'left'}>
                                The CinQuartz PICO filter matches witrh the UVC light decomposes VOCs (volatile organic
                                compounds) into basic molecules, reducing odors and airborne chemicals.
                            </Paragraph>
                        </Box>
                        <Divider sx={cssObject.divider} />
                        <Box sx={cssObject.stepsHeader}>
                            <Paragraph semibold headingColor responsiveFontSize18>
                                Stage 5
                            </Paragraph>
                        </Box>
                        <Box sx={cssObject.stepsInfo}>
                            <Paragraph semiLargeFont={true} align={'left'}>
                                Air passes through a final activated carbon and potassium filter to further reduce ordor
                                and other light gasses.
                            </Paragraph>
                        </Box>
                    </Grid>
                </Fragment>
            }
        />
    );
}

export default IndoorAirQuality;
