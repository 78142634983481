import React from 'react';
import { Theme, useTheme } from '@mui/material/styles';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Grid from '@mui/material/Grid';
import { Logo } from 'components/Logo';
import { CloseButtonNew } from 'components/WizardButton';
import { AppRoute } from '../models/route';
import { useLocation } from 'react-router-dom';
import ContentContainer from './ContentContainer';
import { NavigationButton } from './NavigationButton';
import { Link } from './Link';
import menuDrawerBackGround from '../assets/icons/menuDrawerBackGround.png';
import menuDrawerBackGroundMobile from '../assets/icons/menuDrawerBackGround_mobile.png';
import useMediaQuery from '@mui/material/useMediaQuery';
import { LoginAndSignUpSection } from './LoginAndSignUpMenu';
import Hamburger from './icons/Hamburger';
import { SXCssObject } from 'models/ThemeImages';
import { Box } from '@mui/material';

const createCssObject = (theme: Theme): SXCssObject => ({
    menuIcon: {
        border: `1px solid #CCCCCC`,
        width: '40px',
        height: '40px',
        '& svg': {
            marginTop: '-4px',
        },
    },
    primaryIcon: {
        color: theme.palette.text.primary,
    },
    menuItem: {
        justifyContent: 'center',
        textAlign: 'center',
        paddingBottom: theme.spacing(3.5),
        '&:hover': {
            backgroundColor: 'transparent',
        },
    },
    header: {
        paddingBottom: {
            xs: theme.spacing(3),
            sm: theme.spacing(3.7),
        },
    },
    paper: {
        backgroundSize: 'cover',
        backgroundColor:'#ffffff !important',
        backgroundPosition: {
            xs: 'inherit',
            sm: 'initial',
        },
        background: {
            xs: `transparent url(${menuDrawerBackGroundMobile}) 0% 0% no-repeat`,
            sm: `transparent url(${menuDrawerBackGround}) 0% 0% no-repeat`,
        },
    },
    dividerLineStyle: {
        backgroundColor: theme.palette.primary.main,
        width: '16px',
        height: '2px',
        display: 'inline-flex',
        marginBottom: '10px',
    },
});

interface Props {
    color?: 'primary' | 'secondary';
    isUserDetailAvailable?: boolean;
    onLogin?: () => void;
}

interface Menu {
    text: string;
    route: string;
    isButton: boolean;
}

const menus: Menu[] = [
    {
        text: 'Who We Are',
        route: AppRoute.WhoWeAre,
        isButton: false,
    },
    {
        text: 'How it works',
        route: AppRoute.WhatIsOurProcess,
        isButton: false,
    },
    {
        text: `Contact Us`,
        route: AppRoute.ContactUs,
        isButton: false,
    },
    {
        text: `Let's Get Started`,
        route: AppRoute.AboutYourHome,
        isButton: true,
    },
];
export default function MenuDrawer(props: Props) {
    const classes: SXCssObject = createCssObject(useTheme());
    const [state, setState] = React.useState(false);
    const { color } = props;
    const theme = useTheme();
    const isXs = useMediaQuery(theme.breakpoints.only('xs'));
    const location = useLocation();
    const path = location.pathname;
    const toggleDrawer = (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
        if (
            event.type === 'keydown' &&
            ((event as React.KeyboardEvent).key === 'Tab' || (event as React.KeyboardEvent).key === 'Shift')
        ) {
            return;
        }

        setState(open);
    };
    const list = () => (
        <div role="presentation" onClick={toggleDrawer(false)} onKeyDown={toggleDrawer(false)}>
            <ContentContainer size={'small'}>
                <Grid sx={classes.header} item xs={12} container>
                    {isXs && (
                        <Grid item xs={12} container justifyContent={'flex-end'}>
                            <CloseButtonNew id={'menu-close-ico'} onClick={toggleDrawer(false)} square />
                        </Grid>
                    )}
                    <Grid item xs={12} sm={6} container justifyContent={isXs ? 'center' : 'flex-start'}>
                        <Logo id="menu-logo" />
                    </Grid>
                    {!isXs && (
                        <Grid item xs={6} container justifyContent={'flex-end'} alignItems={'center'}>
                            <CloseButtonNew id={'menu-close-ico'} onClick={toggleDrawer(false)} square />
                        </Grid>
                    )}
                </Grid>
                <Grid item xs={12}>
                    <List>
                        {menus.map((menu, index) =>
                            menu.isButton ? (
                                <Grid key={index} sx={classes.menuItem} hidden={props.isUserDetailAvailable}>
                                    <LoginAndSignUpSection
                                        id={'login-section'}
                                        onClick={props.onLogin}
                                        isTransparent={false}
                                    />
                                </Grid>
                            ) : (
                                <ListItem sx={classes.menuItem} component={'div'} button key={`menu-${index}`}>
                                    <ListItemText
                                        primary={<Link label={menu.text} color={'primary'} href={menu.route} />}
                                        secondary={
                                            <Box
                                                component={'span'}
                                                sx={[[menu.route].includes(path) && classes.dividerLineStyle]}
                                            />
                                        }
                                    />
                                </ListItem>
                            )
                        )}
                    </List>
                </Grid>
            </ContentContainer>
        </div>
    );

    return (
        <div>
            <NavigationButton color={color ? color : 'primary'} onClick={toggleDrawer(true)} icon={<Hamburger />} />
            <Drawer PaperProps={{ sx: classes.paper }} anchor={'top'} open={state} onClose={toggleDrawer(false)}>
                {list()}
            </Drawer>
        </div>
    );
}
