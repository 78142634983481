import { useTheme } from '@mui/material/styles';
import { getThemePrimaryMain } from 'components/common/Utilities';
import * as React from 'react';

const ArrowToggleDown = (props: any) => {
    const theme = useTheme();
    const themePrimaryMain = getThemePrimaryMain(theme);
    return (
        <svg
            id="Component_97_1"
            data-name="Component 97 – 1"
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
        >
            <circle id="Ellipse_193" data-name="Ellipse 193" cx="12" cy="12" r="12" fill="#e7efe2" />
            <path
                id="Path_267"
                data-name="Path 267"
                d="M0,0,4.15,4.542,8.282,0"
                transform="translate(7.718 10.344)"
                fill="none"
                stroke={`${themePrimaryMain}`}
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
            />
        </svg>
    );
};
export default ArrowToggleDown;
