import React from 'react';
import { Theme } from '@mui/material/styles';
import { Button } from 'components/Button';
import { Grid, useTheme } from '@mui/material';
import { useMediaQuery } from '@mui/material';
import { SXCssObject } from 'models/ThemeImages';

const createCssObject = (theme: Theme) => ({
    root: {
        display: 'flex',
        flexDirection: {
            xs: 'column',
            sm: 'inherit',
        },
        justifyContent: {
            xs: 'center',
            sm: 'flex-end',
        },
        minHeight: {
            sm: 'auto',
            md: 'inherit',
        },
    },
    button: {
        '&:not(:first-of-type)': {
            paddingTop: {
                xs: theme.spacing(2),
                sm: 'inherit',
            },
            paddingLeft: {
                xs: 0,
                sm: theme.spacing(2),
            },
        },
        width: '100%',
        maxWidth: '100%',
        flexBasis: '100%',
    },
    buttonLayoutsStyle: {
        padding: {
            xs: theme.spacing(0.5, 0, 2.5, 0),
            sm: theme.spacing(2.5, 0, 1, 0),
            md: theme.spacing(2, 0, 1, 0),
            xl: theme.spacing(1.5, 0, 1, 0),
        },
    },
});

interface Props {
    onBack?: () => void;
    onNext?: () => void;
    onHelp?: () => void;
    disabled?: boolean;
    nextLabel?: string;
    backLabel?: string;
    helpLabel?: string;
    isSmallerFont?: boolean;
}

export default function WizardCriteriaFooterNew(props: Props) {
    const cssObject: SXCssObject = createCssObject(useTheme());
    const componentId = 'wizard-footer';
    const theme = useTheme();
    const upXs = useMediaQuery(theme.breakpoints.only('xs'));

    return !upXs ? (
        <Grid container sx={cssObject.buttonLayoutsStyle} spacing={1}>
            {!!props.onBack && (
                <Grid item xs={12} sm={!!props.onBack ? 4 : 12} md={!!props.onBack ? 5 : 12}>
                    <div>
                        <div>
                            <Button
                                isFullWidth={true}
                                id={`${componentId}-back-btn`}
                                isSmallerFont={props.isSmallerFont}
                                isContrast
                                onClick={props.onBack}
                            >
                                {props.backLabel ? props.backLabel : 'Back'}
                            </Button>
                        </div>
                    </div>
                </Grid>
            )}{' '}
            <Grid item xs={12} sm={props.onBack ? 8 : 12} md={props.onBack ? 7 : 12}>
                <Button
                    id={`${componentId}-next-btn`}
                    color={'primary'}
                    isFullWidth={true}
                    onClick={props.onNext}
                    isSmallerFont={props.isSmallerFont}
                    disabled={props.disabled}
                >
                    {props.nextLabel ? props.nextLabel : 'Next'}
                </Button>
            </Grid>
        </Grid>
    ) : (
        <Grid container sx={cssObject.buttonLayoutsStyle} spacing={2}>
            <Grid item xs={12} sm={props.onBack ? 8 : 12} md={props.onBack ? 8 : 12}>
                <Button
                    id={`${componentId}-next-btn`}
                    color={'primary'}
                    isFullWidth={true}
                    onClick={props.onNext}
                    disabled={props.disabled}
                >
                    {props.nextLabel ? props.nextLabel : 'Next'}
                </Button>
            </Grid>
            {!!props.onBack && (
                <Grid item xs={12} sm={!!props.onBack ? 3 : 12} md={!!props.onBack ? 4 : 12}>
                    <div>
                        <div>
                            <Button isFullWidth={true} id={`${componentId}-back-btn`} isContrast onClick={props.onBack}>
                                {props.backLabel ? props.backLabel : 'Back'}
                            </Button>
                        </div>
                    </div>
                </Grid>
            )}{' '}
        </Grid>
    );
}
