import { action, payload } from 'ts-action';
import { Store, CmsBlock } from '../../models/storeConfig';

export const getStore = action('STORE::GET');
export const getStoreSuccess = action('STORE::GET_SUCCESS', payload<Store>());
export const getStoreError = action('STORE::GET_ERROR', payload<Error>());

export const getContentBlock = action('STORE::GET_CONTENT_BLOCK');
export const getContentBlockSuccess = action('STORE::GET_CONTENT_BLOCK_SUCCESS', payload<CmsBlock>());
export const getContentBlockError = action('STORE::GET_CONTENT_BLOCK_ERROR', payload<Error>());
