import React, { useEffect } from 'react';
import history from './history';
import { hotjar } from 'react-hotjar';
import { useDispatch, useSelector } from 'react-redux';
import { selectStoreLoading } from '../store/selectors/storeSelector';
import { getStore } from '../store/actions/storeActions';
import { getCountryById } from '../store/actions/countryActions';
import ScrollToTop from '../components/ScrollToTop';
import { getProductAttribute } from '../store/actions/productAttributeActions';
import config from '../config';
import { isStoreNone } from 'config';
import { RoutesList } from './RoutesListComponent';
import Loading from 'components/common/Loading';
import { unstable_HistoryRouter as HistoryRouter } from 'react-router-dom';
const AppRouter: React.FC = () => {
    const dispatch = useDispatch();
    const loading = useSelector(selectStoreLoading);
    useEffect(() => {
        if (!isStoreNone()) {
            dispatch(getStore());
            dispatch(getCountryById({ id: 'US' }));
            dispatch(getProductAttribute({ id: 'EFFICIENCY' }));
        }
        hotjar.initialize(Number(config.hotjarId), Number(config.hotjarSv));
    }, [dispatch]);
    if (loading) {
        return <Loading />;
    }
    return (
        <HistoryRouter history={history} >
            <ScrollToTop />
            <RoutesList />
        </HistoryRouter>
    );
};

export default AppRouter;
