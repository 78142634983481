import { useTheme } from '@mui/material/styles';
import { getThemePrimaryMain } from 'components/common/Utilities';
import * as React from 'react';

function DownloadIcon(props: any) {
    const theme = useTheme();
    const themePrimaryMain = getThemePrimaryMain(theme);
    return (
        <svg
            id="Component_133_1"
            data-name="Component 133 – 1"
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            style={{ marginRight: 12 }}
        >
            <g id="Rectangle_627" data-name="Rectangle 627" fill="#fff" stroke="#707070" strokeWidth="1" opacity="0">
                <rect width="24" height="24" stroke="none" />
                <rect x="0.5" y="0.5" width="23" height="23" fill="none" />
            </g>
            <g id="Group_725" data-name="Group 725" transform="translate(-701.475 -371.107)">
                <path
                    id="Path_94"
                    data-name="Path 94"
                    d="M720.772,386.522v4.6h-14.6v-4.6"
                    fill="none"
                    stroke={`${themePrimaryMain}`}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                />
                <path
                    id="Path_95"
                    data-name="Path 95"
                    d="M717.374,381.67l-3.917,3.917-3.917-3.917"
                    fill="none"
                    stroke={`${themePrimaryMain}`}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                />
                <line
                    id="Line_98"
                    data-name="Line 98"
                    y1="10.489"
                    transform="translate(713.457 375.097)"
                    fill="none"
                    stroke={`${themePrimaryMain}`}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                />
            </g>
        </svg>
    );
}

export default DownloadIcon;
