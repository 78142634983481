export enum LoginMessages {
    MSG_SUCCESS_LOGOUT = 'You have logged out successfully',
    MSG_SUCCESS_LOGIN = 'You have logged in successfully',
    MSG_FAILURE_LOGIN = 'Unable to login',
    OTP_SEND_SUCCESS = 'OTP has been sent succesffully',
    OTP_SEND_FAIL = 'Unable to send OTP to email',
    MSG_SUCCESS_REGISTER = 'Your account is registered successfully',
    MSG_FAILURE_REGISTER = 'Unable to register',
    EMAIL_EXISTS_FAIL = 'Unable to verify your email',
    ACTIVATION_SEND_FAILURE = 'Unable to send Activation code to email',
    ACTIVATION_SEND_SUCCESS = 'Activation code has been sent successfully',
    MSG_EMAIL_ALREADY_EXIST = 'Email already exists. Please login',
    MSG_EMAIL_NOT_REGISTERED = 'Email is not registered. Please sign up',
    MSG_SIGN_UP_VERIFY_FAIL = 'Unable to Verify Activation code',
    MSG_SIGN_UP_VERIFY_SUCCESS = 'Your account is activated successfully',
}
export const IS_ALIVE_COOKIE = 'isAlive';
export const PROMO_CODE_REMOVE = 'Unable to remove Promocode';
export const CURRENCY = 'USD';
export const NOT_TODAY = 'Not Today';
export const AAHDR_DOMAIN = 'airandheatdoneright.com';
export enum InvalidAmountText {
    LowerLimit = 'Minimum down-payment should be 50%.',
    HigherLimit = 'The entered amount exceeds the order total',
    InvalidAmt = 'Enter a valid amount',
}

export enum DOMEvent {
    EVENT_SHOW_CONTRACTOR_MODAL_AFTER_MANDATORY_BILLING_CAPTURE = "ShowContractorModalAfterBillingCapture"
}

export const SCHEDULE_TOOLTIP =
    'Select “Schedule Inspection” to choose the inspection date/times that work best for you. This visit allows the contractor an opportunity to verify that the selected HVAC product will meet your needs and determine what materials will be needed to complete the installation. The inspection is free of charge.';