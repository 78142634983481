import { on, reducer } from 'ts-action';

import { addGAProduct, resetGAProduct } from '../actions/gaEcommerceActions';

export const gaEcommerceReducer = reducer(
    {},
    on(addGAProduct, (state, action) => ({
        ...state,
        ...action.payload,
    })),
    on(resetGAProduct, (state, action) => ({}))
);

export default gaEcommerceReducer;
