import { Box, CardMedia, Dialog, DialogContent, DialogContentText, Grid, Hidden } from '@mui/material';
import React from 'react';
import { Theme, useTheme } from '@mui/material/styles';
import { Close } from '@mui/icons-material';
import FormikCheckBox from './FormikCheckBox';
import { Button } from 'components/Button';
import { Formik, FormikValues } from 'formik';
import { deleteMultipleItems } from 'store/actions/cartActions';
import { useDispatch } from 'react-redux';
import AlertIcon from './icons/AlertIcon';
import MuiDialogTitle from '@mui/material/DialogTitle';
import { Heading } from './Heading';
import { Paragraph } from './Paragraph';
import { SXCssObject } from 'models/ThemeImages';

const createCssObject = (theme: Theme): SXCssObject => ({
    title: {
        marginRight: '2px',
    },

    subTitle: {
        paddingTop: '12px',
        marginBottom: '4px',
    },
    modalTitleContainer: {
        padding: '10px 24px 0px',
        position: 'relative',
        marign: '0px',
        lineHeight: {
            xs: '28px',
            sm: '36px',
        },
    },
    modalPaper: {
        borderRadius: '4px',
        paddingTop: '15px',
        width: {
            xs: '80%',
            sm: '504px',
        },
        minWidth: {
            xs: '290px',
            sm: 'inherit',
        },
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1.6),
        top: theme.spacing(0),
        color: theme.palette.grey[500],
        cursor: 'pointer',
        marginTop: '-8px',
        marginBottom: '-6px',
        marginRight: '-4px',
    },
    centerContent: {
        display: 'flex',
        justifyContent: 'center',
    },
    imageAndName: {
        display: 'flex',
        justifyContent: 'center',
        border: '1px solid #D4D4D4',
        padding: '5px',
        borderRadius: '4px',
        margin: '5px 0px 5px 0px',
    },
    img: {
        objectFit: 'contain',
        border: '1px solid #D4D4D4',
        borderRadius: '4px',
        width: '80px',
    },
    addOnNameStyle: {
        marginRight: {
            xs: '4px',
            sm: 'inherit',
        },
    },
    addOnPriceStyle: {
        paddingTop: {
            xs: '4px',
            sm: '8px',
        },
    },
    alertIconStyle: {
        marginTop: '6px',
        display: 'flex',
        justifyContent: 'center',
        marginBottom: {
            xs: '16px',
            sm: '24px',
        },
    },
    buttonContainer: {
        padding: '20px 0px',
    },
});

interface Props {
    open: boolean;
    nonCompatibleAddOns: any[];
    onClose: () => void;
}
function AddOnPopup(props: Props) {
    const cssObject: SXCssObject = createCssObject(useTheme());
    const dispatch = useDispatch();
    return (
        <Dialog
            open={props.open}
            maxWidth={'sm'}
            aria-describedby="alert-dialog-slide-description"
            disableEscapeKeyDown={true}
            PaperProps={{
                sx: cssObject.modalPaper,
            }}
        >
            <div>
                <MuiDialogTitle sx={cssObject.modalTitleContainer}>
                    <Box sx={cssObject.alertIconStyle}>
                        <AlertIcon />
                    </Box>
                    <Heading isPopUp label={'Potential Compatibility Issue'} />
                    <DialogContentText sx={cssObject.subTitle}>
                        <Paragraph>
                            Add-ons listed below are not supported by the HVAC systems(s) in your cart.
                        </Paragraph>
                    </DialogContentText>
                    <Box aria-label="close" sx={cssObject.closeButton} onClick={() => props.onClose()}>
                        <Close />
                    </Box>
                </MuiDialogTitle>
                <DialogContent>
                    <Box>
                        <Grid container alignItems={'center'}>
                            <Grid item sx={cssObject.title}>
                                <Paragraph size={'normal'} semibold>
                                    Other Products
                                </Paragraph>
                            </Grid>
                            &nbsp;
                            <Grid item>
                                <Paragraph fontSizeVerySmall>{props.nonCompatibleAddOns.length} added</Paragraph>
                            </Grid>
                        </Grid>
                    </Box>
                    <Formik
                        initialValues={{
                            checked: props.nonCompatibleAddOns.map((item) => true),
                        }}
                        onSubmit={(values: FormikValues) => {
                            const addOnsToDelete = props.nonCompatibleAddOns.filter((item, index) => {
                                return values.checked[index];
                            });
                            dispatch(
                                deleteMultipleItems({ itemsToDelete: addOnsToDelete, isFromAddOnDeletePopup: true })
                            );
                            props.onClose();
                            //Take itemId from addOnsToDelete, and pass to delete
                        }}
                    >
                        {({ submitForm, isValid, values }) => (
                            <Box>
                                <Grid container>
                                    {props.nonCompatibleAddOns.map((addOnItem, index) => (
                                        <Grid
                                            key={index}
                                            item
                                            xs={12}
                                            container
                                            spacing={1}
                                            sx={cssObject.imageAndName}
                                        >
                                            <Hidden only={['xs']}>
                                                <Grid item sm={3} sx={cssObject.centerContent}>
                                                    <CardMedia
                                                        image={addOnItem.image}
                                                        component={'img'}
                                                        sx={cssObject.img}
                                                    />
                                                </Grid>
                                                <Grid item sm={7}>
                                                    <Box sx={cssObject.addOnNameStyle}>
                                                        <Paragraph semibold>{addOnItem.name}</Paragraph>
                                                    </Box>
                                                    <Box sx={cssObject.addOnPriceStyle}>
                                                        <Paragraph size={'normal'} semibold>
                                                            ${addOnItem.price}
                                                        </Paragraph>
                                                    </Box>
                                                </Grid>
                                                <Grid item xs={2} justifyContent={'center'} style={{ display: 'flex' }}>
                                                    <FormikCheckBox
                                                        id={`id-${addOnItem.sku}`}
                                                        name={`checked[${index}]`}
                                                    ></FormikCheckBox>
                                                </Grid>
                                            </Hidden>
                                            <Hidden smUp>
                                                <Grid item xs={3} sx={cssObject.centerContent}>
                                                    <CardMedia
                                                        image={addOnItem.image}
                                                        component={'img'}
                                                        sx={cssObject.img}
                                                    />
                                                </Grid>
                                                <Grid container xs={12} spacing={1} sx={{ mt: '12px' }}>
                                                    <Grid item xs={10}>
                                                        <Box sx={cssObject.addOnNameStyle}>
                                                            <Paragraph semibold>{addOnItem.name}</Paragraph>
                                                        </Box>
                                                        <Box sx={cssObject.addOnPriceStyle}>
                                                            <Paragraph size={'normal'} semibold>
                                                                ${addOnItem.price}
                                                            </Paragraph>
                                                        </Box>
                                                    </Grid>
                                                    <Grid
                                                        item
                                                        xs={2}
                                                        style={{
                                                            display: 'flex',
                                                            marginLeft: '4px',
                                                            marginRight: '-16px',
                                                        }}
                                                    >
                                                        <FormikCheckBox
                                                            id={`id-${addOnItem.sku}`}
                                                            name={`checked[${index}]`}
                                                        ></FormikCheckBox>
                                                    </Grid>
                                                </Grid>
                                            </Hidden>
                                        </Grid>
                                    ))}
                                </Grid>
                                <Grid container spacing={1} sx={cssObject.buttonContainer}>
                                    <Grid item xs={12} sm={6}>
                                        <Button
                                            isFullWidth={true}
                                            color="primary"
                                            isSmallerFontForSmallerDevice
                                            disabled={false}
                                            onClick={() => {
                                                props.onClose();
                                            }}
                                            isContrast
                                        >
                                            KEEP ALL ITEMS
                                        </Button>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <Button
                                            isFullWidth={true}
                                            color="primary"
                                            isSmallerFontForSmallerDevice
                                            disabled={(() => {
                                                const addOnsToDelete = props.nonCompatibleAddOns.filter(
                                                    (item, index) => {
                                                        return values.checked[index];
                                                    }
                                                );
                                                if (addOnsToDelete.length > 0) {
                                                    return false;
                                                }
                                                return true;
                                            })()}
                                            onClick={submitForm}
                                        >
                                            REMOVE SELECTED ITEMS
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Box>
                        )}
                    </Formik>
                </DialogContent>
            </div>
        </Dialog>
    );
}

export default AddOnPopup;
