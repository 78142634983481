import React, { MouseEventHandler, PropsWithChildren } from 'react';
import { Theme, useTheme } from '@mui/material/styles';
import MuiButton from '@mui/material/Button';
import CloseIcon from 'components/icons/Close';
import RestartIcon from 'components/icons/Restart';
import BackIcon from 'components/icons/Back';
import HelpIcon from '@mui/icons-material/HelpOutline';
import { SXCssObject, SXCssValueType } from 'models/ThemeImages';

const createCssObject = (theme: Theme): SXCssObject => ({
    root: {
        height: theme.spacing(4),
        minWidth: theme.spacing(4),
        padding: 0,
        backgroundColor: theme.palette.background.default,
        border: `none`,
        '&:hover': {
            backgroundColor: '#E1E1E1',
        },
        width: {
            xs: theme.spacing(4),
            sm: theme.spacing(10),
        },
    },
    square: {
        width: theme.spacing(4),
    },
});

interface Props {
    id?: string;
    onClick?: MouseEventHandler;
    sx?: SXCssValueType;
    square?: boolean;
}

function ButtonBase(props: PropsWithChildren<Props>) {
    const cssObject: SXCssObject = createCssObject(useTheme());
    const { sx, square, children, ...other } = props;
    return (
        <MuiButton
            disableElevation
            sx={[cssObject.root, square === true && cssObject.square, sx !== undefined && sx]}
            variant={'outlined'}
            {...other}
        >
            {children}
        </MuiButton>
    );
}

export function BackButton(props: Props) {
    return (
        <ButtonBase {...props}>
            <BackIcon />
        </ButtonBase>
    );
}

export function CloseButton(props: Props) {
    return (
        <ButtonBase {...props}>
            <CloseIcon />
        </ButtonBase>
    );
}
export function CloseButtonNew(props: Props) {
    return (
        // <ButtonBase {...props}>
        <CloseIcon />
        // </ButtonBase>
    );
}

export function RestartButton(props: Props) {
    return (
        <ButtonBase {...props}>
            <RestartIcon />
        </ButtonBase>
    );
}

export function HelpButton(props: Props) {
    return (
        <ButtonBase {...props}>
            <HelpIcon sx={{ color: 'text.primary' }} />
        </ButtonBase>
    );
}
