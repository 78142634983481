import React, { useEffect, useState } from 'react';
import { HostedField } from './';
import { Grid, Theme, useTheme } from '@mui/material';
import {
    client as BraintreeClient,
    hostedFields as HostedFieldsInstance,
    HostedFields,
    dataCollector as BraintreeDataCollector,
    HostedFieldsTokenizePayload,
    BraintreeError,
    Client,
    HostedFieldsEvent,
} from 'braintree-web';
import { HostedFieldsInfo } from './field';
import { SXCssObject } from 'models/ThemeImages';
import { Paragraph } from 'components/Paragraph';
export interface BrainTreeProps {
    onAuthorizationSuccess: () => void;
    authorization: string;
    getTokenRef: (ref: () => Promise<HostedFieldsTokenizePayload | undefined>) => void;
    classname: string;
    onDataCollectorInstanceReady?: (err: BraintreeError | undefined, data: any) => void;
    onValidityChange: (event: HostedFieldsEvent) => void;
    onError?: (err: BraintreeError) => void;
    styles: any;
    children?: any;
}

const createCssObject = (theme: Theme): SXCssObject => ({
    buttonContainer: {
        marginTop: {
            xs: '8px',
            sm: '20px',
        },
        display: 'flex',
        justifyContent: 'space-between',
    },
    creditCardInput: {
        height: '44px',
        mt: '5px',
        border: `1px solid ${theme.palette.divider}`,
        padding: '0px 12px',
        borderRadius: '1px',
        '&:hover': {
            borderColor: theme.palette.action.hover,
        },
        '&.braintree-hosted-fields-focused': {
            borderColor: theme.palette.action.hover,
        },
    },
});

function Braintree(props: BrainTreeProps) {
    const theme = useTheme();
    const classes = createCssObject(theme);
    const [fields, setFields] = useState<HostedFieldsInfo>({});
    const [hostedFields, setHostedFields] = useState<HostedFields>();
    const { onAuthorizationSuccess, authorization, getTokenRef, classname: providedClass, onValidityChange } = props;

    useEffect(() => {
        if (authorization && fields && Object.keys(fields).length === 3) {
            createBraintreeInstance(authorization, onAuthorizationSuccess);
        }
        return () => {
            teardown();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [Object.keys(fields).length]);

    const createBraintreeInstance = (authorization: string, onAuthorizationSuccess: () => void) => {
        BraintreeClient.create({ authorization }, (err: BraintreeError | undefined, clientInstance: Client) => {
            if (err) {
                onError(err);
            } else {
                createHostedFields(clientInstance, onAuthorizationSuccess);

                if (props.onDataCollectorInstanceReady) {
                    BraintreeDataCollector.create(
                        {
                            client: clientInstance,
                            kount: true,
                        },
                        props.onDataCollectorInstanceReady
                    );
                }
            }
        });
    };

    const tokenize = (
        hostedFieldInstance: HostedFields,
        options = {}
    ): (() => Promise<HostedFieldsTokenizePayload | undefined>) => {
        return () =>
            new Promise((resolve, reject) => {
                hostedFieldInstance.tokenize(
                    options,
                    (err: BraintreeError | undefined, payload: HostedFieldsTokenizePayload | undefined) => {
                        if (err) {
                            onError(err);
                            reject(err);
                        } else {
                            resolve(payload);
                        }
                    }
                );
            });
    };

    const onError = (err: BraintreeError) => {
        if (!err) {
            return;
        }
        if (props.onError) {
            props.onError(err);
        }
    };

    const createHostedFields = (client: Client, onAuthorizationSuccess: () => void) => {
        try {
            HostedFieldsInstance.create(
                {
                    client,
                    styles: props.styles,
                    fields: fields,
                },
                (err: BraintreeError | undefined, hostedFields: HostedFields | undefined) => {
                    if (err) {
                        onError(err);
                        return;
                    }
                    if (!hostedFields) {
                        return;
                    }
                    setHostedFields(hostedFields);
                    if (getTokenRef) {
                        getTokenRef(tokenize(hostedFields));
                    }
                    hostedFields.on('validityChange', onValidityChange);

                    if (onAuthorizationSuccess) {
                        onAuthorizationSuccess();
                    }
                }
            );
        } catch (e: any) {
            onError(e);
        }
    };

    const teardown = () => {
        try {
            if (hostedFields) {
                hostedFields.teardown();
            }
        } catch (e) {}
    };

    let className = 'braintree-hosted-fields-wrapper';
    if (providedClass) {
        className += ` ${providedClass}`;
    }
    return (
        <div className={className}>
            <Grid container spacing={1}>
                <Grid item xs={12} md={6}>
                    <Paragraph>Card Number</Paragraph>
                    <HostedField
                        id={'card-number-text-field'}
                        type={'number'}
                        placeholder={'0000 0000 0000 0000'}
                        sx={classes.creditCardInput}
                        setFields={setFields}
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                    <Paragraph>Exp Date</Paragraph>
                    <HostedField
                        id={'card-exp-date-text-field'}
                        type={'expirationDate'}
                        placeholder={'MM/YY'}
                        sx={classes.creditCardInput}
                        setFields={setFields}
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                    <Paragraph>CVV</Paragraph>
                    <HostedField
                        id={'cvv-text-field'}
                        type="cvv"
                        placeholder={'CVV'}
                        sx={classes.creditCardInput}
                        setFields={setFields}
                    />
                </Grid>
            </Grid>
        </div>
    );
}

export default Braintree;
