import { on, reducer } from 'ts-action';
import {
    resetProductSearch,
    searchProduct,
    searchProductError,
    searchProductSuccess,
    changeProduct,
    changeProductError,
    changeProductSuccess,
    searchProductIAQ,
    searchProductIAQSuccess,
    searchProductIAQError,
    resetIAQProductSearch,
    getProductBySkuAction,
    getProductBySkuSuccess,
    getProductBySkuError,
    resetMainProductSearch,
    searchSecondProduct,
    searchSecondProductError,
    searchSecondProductSuccess,
    changeProductIAQ,
    changeProductIAQError,
    changeProductIAQSuccess
} from '../actions/productSearchActions';
import { ProductSearch } from '../../models/productSearch';
import { navigateThankyouPage } from 'store/actions/navigateActions';

export interface ProductSearchState extends ProductSearch {
    loading: boolean;
    isIAQSearchLoading: boolean; //Maintaining two loaders, since both product search & IAQ Search will be triggered at same time.
    isNoProduct: boolean;
    error: Error | null;
}

const initialState: ProductSearchState = {
    loading: false,
    isIAQSearchLoading: false,
    isNoProduct: false,
    error: null,
};

export const productSearchReducer = reducer(
    initialState,
    on(searchProduct, (state, action) => ({
        ...state,
        error: null,
        products: [],
        loading: true,
    })),
    on(searchProductSuccess, (state, action) => ({
        ...state,
        products: [...action.payload.products],
        loading: false,
    })),
    on(searchProductError, (state, action) => ({
        ...initialState,
        loading: false,
        error: action.payload,
    })),
    on(searchSecondProduct, (state, action) => ({
        ...state,
        error: null,
        productsSystemTwo: [],
        loading: true,
    })),
    on(searchSecondProductSuccess, (state, action) => ({
        ...state,
        productsSystemTwo: [...action.payload.productsSystemTwo],
        loading: false,
    })),
    on(searchSecondProductError, (state, action) => ({
        ...initialState,
        loading: false,
        error: action.payload,
    })),
    on(getProductBySkuAction, (state, action) => ({
        ...state,
        loading: true,
    })),
    on(getProductBySkuSuccess, (state, action) => ({
        ...state,
        loading: false,
        products: action.payload,
    })),
    on(getProductBySkuError, (state, action) => ({
        ...state,
        loading: false,
    })),
    on(searchProductIAQ, (state, action) => ({
        ...state,
        isIAQSearchLoading: true,
        error: null,
    })),
    on(changeProductIAQ, (state, action) => ({
        ...state,
        error: null,
        loading: true,
    })),
    on(changeProductIAQSuccess, (state, action) => ({
        ...state,
        productsIAQ: action.payload.productsIAQ,
        loading: false,
    })),
    on(changeProductIAQError, (state, action) => ({
        ...initialState,
        loading: false,
        error: action.payload,
    })),

    on(searchProductIAQSuccess, (state, action) => ({
        ...state,
        productsIAQ: action.payload.productsIAQ,
        isIAQSearchLoading: false,
    })),
    on(searchProductIAQError, (state, action) => ({
        ...initialState,
        isIAQSearchLoading: false,
        error: action.payload,
    })),
   
    on(resetProductSearch, () => ({
        ...initialState,
    })),
    on(resetIAQProductSearch, (state) => ({
        ...state,
        productsIAQ: [],
    })),
    on(resetMainProductSearch, (state) => ({
        ...state,
        products: [],
    })),
    on(changeProduct, (state, action) => ({
        ...state,
        error: null,
        loading: true,
    })),
    on(changeProductSuccess, (state, action) => ({
        ...state,
        ...action.payload.data,
        loading: false,
    })),
    on(changeProductError, (state, action) => ({
        ...initialState,
        loading: false,
        error: action.payload,
    })),
    on(navigateThankyouPage, (state, action) => ({
        ...state,
        loading: false,
        error: null,
        isNoProduct: action.payload.isNoProduct,
    }))
);
export default productSearchReducer;
