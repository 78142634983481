import React from 'react';
import { Link } from 'components/Link';
import { AppRoute } from 'models/route';
import { Theme, useTheme } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import { useLocation } from 'react-router-dom';
import { List, ListItem, ListItemText, Box } from '@mui/material';
import { SXCssObject } from 'models/ThemeImages';

const createCssObject = (theme: Theme): SXCssObject => ({
    menuItem: {
        justifyContent: 'center',
        width: 'auto',
        textAlign: 'center',
        height: '40px',
        padding: '0px 10px',
        alignItems: 'flex-start',
        '&:hover': {
            backgroundColor: 'transparent',
        },
    },
    dividerLineStyle: {
        backgroundColor: theme.palette.primary.main,
        width: '16px',
        height: '2px',
        display: 'inline-flex',
        marginBottom: '10px',
    },
    listRoot: {
        display: 'flex',
        flexDirection: 'row',
        padding: 0,
    },
    disableRipple: {
        '& .MuiTouchRipple-root': {
            display: 'none',
        },
    },
});

interface Menu {
    text: string;
    route: string;
    isButton: boolean;
}
export const HomePageHeaderMenus = () => {
    const cssObject: SXCssObject = createCssObject(useTheme());
    const location = useLocation();
    const path = location.pathname;
    const menus: Menu[] = [
        {
            text: 'Who We Are',
            route: AppRoute.WhoWeAre,
            isButton: false,
        },
        {
            text: 'How it works',
            route: AppRoute.WhatIsOurProcess,
            isButton: false,
        },

        {
            text: `Contact Us`,
            route: AppRoute.ContactUs,
            isButton: false,
        },
    ];
    return (
        <Grid item>
            <List
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    padding: 0,
                    '& .MuiList-root': {
                        '& .MuiTouchRipple-root': {
                            display: 'none',
                        },
                    },
                }}
            >
                {menus.map((menu, index) => (
                    <ListItem sx={cssObject.menuItem} component={'div'} button key={`menu-${index}`}>
                        <ListItemText
                            primary={
                                <Link
                                    id={`menu-home-${index}`}
                                    label={menu.text}
                                    color={'primary'}
                                    active={[menu.route].includes(path)}
                                    href={menu.route}
                                />
                            }
                            secondary={
                                <Box
                                    component={'span'}
                                    sx={[[menu.route].includes(path) && cssObject.dividerLineStyle]}
                                />
                            }
                        />
                    </ListItem>
                ))}
            </List>
        </Grid>
    );
};
