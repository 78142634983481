import { on, reducer } from 'ts-action';

import {
    resetOrder,
    getOrder,
    getOrderSuccess,
    getOrderError,
    getOrderSuccessAndRedirectToBilling,
    myOrderAction,
    myOrderSuccess,
    myOrderError,
    getMyOrder,
    getOrderSuccessDoNotRedirect,
    getOrderandReset,
} from '../actions/orderActions';
import { Order, OrderStatus } from '../../models/order';

interface OrderState extends Order {
    loading: boolean;
    error: Error | null;
}
const initialState: OrderState = {
    loading: false,
    error: null,
};

export const orderReducer = reducer(
    initialState,
    on(getOrder, (state, action) => ({
        ...state,
        ...action.payload,
        loading: true,
        error: null,
    })),
    on(getOrderandReset, (state, action) => ({
        ...state,
        ...action.payload,
        loading: true,
        error: null,
    })),
    on(getMyOrder, (state, action) => ({
        ...state,
        ...action.payload,
        loading: true,
        error: null,
    })),
    on(getOrderSuccess, (state, action) => ({
        ...state,
        ...action.payload,
        loading: false,
        error: null,
    })),
    on(getOrderSuccessAndRedirectToBilling, (state, action) => ({
        ...state,
        ...action.payload,
        loading: false,
        error: null,
    })),
    on(getOrderSuccessDoNotRedirect, (state, action) => ({
        ...state,
        ...action.payload,
        loading: false,
        error: null,
    })),
    on(getOrderError, (state, action) => ({
        ...state,
        loading: false,
        error: action.payload,
    })),
    on(resetOrder, (state, action) => ({
        ...initialState,
    })),
    on(myOrderAction, (state) => ({
        ...state,
        loading: true,
        error: null,
    })),
    on(myOrderSuccess, (state, action) => ({
        ...state,
        ...(action.payload.order.status === OrderStatus.PENDING_PAYMENT && action.payload.order),
        loading: false,
        error: null,
    })),

    on(myOrderError, (state, action) => ({
        ...state,
        loading: false,
        error: action.payload,
    }))
);

export default orderReducer;
