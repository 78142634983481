import React from 'react';
import { useSelector } from 'react-redux';
import { selectCartProcessing } from '../../store/selectors/cart';
import { selectProductLoading } from '../../store/selectors/productSearch';
import { selectProductCriteriaState } from '../../store/selectors/productCriteria';
import EquipmentSelection from './EquipmentSelection';
import { AppRoute } from 'models/route';
import { Navigate, useLocation } from 'react-router-dom';
import { selectPreCart, selectPreCartAddon, selectPreCartIAQ, selectPreCartMain } from 'store/selectors/selection';
import { PreCart } from 'store/reducers/selectionReducer';
import { selectQuotes } from 'store/selectors/quotes';

function EquipmentSelectionContainer() {
    const loading = useSelector(selectCartProcessing);
    const quote = useSelector(selectQuotes);
    const preCart: PreCart = useSelector(selectPreCart);

    const preCartItemsMain = useSelector(selectPreCartMain);
    const preCartItemsIAQ = useSelector(selectPreCartIAQ);
    const preCartItemsAddons = useSelector(selectPreCartAddon);

    const criteria = useSelector(selectProductCriteriaState);

    const isproductSearchLoading: boolean = useSelector(selectProductLoading);

    const secondSystemWizard: any = useLocation();

    if (!preCart || !preCart.items || preCart.items.length === 0) {
        return <Navigate to={AppRoute.Home} />;
    }
    return (
        <EquipmentSelection
            criteria={criteria}
            secondSystemWizard={secondSystemWizard}
            preCartItemsMain={preCartItemsMain}
            preCartItemsIAQ={preCartItemsIAQ}
            preCartItemsAddons={preCartItemsAddons}
            loading={loading || isproductSearchLoading || quote.loading} //loading will be true when cart api is in progress or product search in progress
        />
    );
}

export default EquipmentSelectionContainer;
