import React from 'react';

function DeliveryIcon() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" data-name="Component 25 – 4" viewBox="0 0 24 24">
            <g fill="#1dbaaf" data-name="Group 3931" transform="translate(-4.105 -.737)">
                <path
                    stroke="#1dbaaf"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="0.7"
                    d="M17.284 18.835h-5.23a1.245 1.245 0 01-1.379-1.373V7.971a1.234 1.234 0 011.347-1.336h10.527a1.23 1.23 0 011.345 1.34v9.555a1.223 1.223 0 01-1.292 1.305h-5.316zm1.865-11.523V10.967c0 .355-.233.5-.548.345s-.659-.322-.984-.492a.3.3 0 00-.312 0c-.311.167-.629.319-.946.474-.382.187-.6.052-.6-.377V7.328a.858.858 0 00-.094-.014h-3.727a.544.544 0 00-.584.623v9.594a.555.555 0 00.621.629h10.589c.447 0 .653-.2.653-.641V7.967c0-.454-.2-.654-.661-.654h-3.407m-.695 3.17V7.325h-2v3.161c.252-.126.489-.233.714-.361a.524.524 0 01.575 0c.224.13.463.234.715.359"
                    data-name="Path 643"
                ></path>
                <path
                    d="M2.935 0h2.1a.735.735 0 11-.005 1.463H.803A.742.742 0 010 .74.741.741 0 01.81 0h2.125z"
                    data-name="Path 644"
                    transform="rotate(180 5.338 5.985)"
                ></path>
                <path
                    d="M2.2 1.464H.783A.732.732 0 11.778 0h2.834a.732.732 0 11.007 1.461c-.472.006-.944 0-1.417 0z"
                    data-name="Path 645"
                    transform="rotate(180 6.4 7.448)"
                ></path>
            </g>
        </svg>
    );
}

export default DeliveryIcon;
