import { useTheme } from '@mui/material/styles';
import { getThemePrimaryMain } from 'components/common/Utilities';
import * as React from 'react';

function ExtendedLabourWarranty(props: any) {
    const theme = useTheme();
    const themePrimaryMain = getThemePrimaryMain(theme);
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={40} height={40} {...props}>
            <defs>
                <style>{'.prefix__b3{fill:none;stroke-linecap:round;stroke-linejoin:round;stroke-width:1.5px}'}</style>
            </defs>
            <path
                className="prefix__b3"
                stroke={`${themePrimaryMain}`}
                d="M20.204 33.379l5.875-4.47a13.18 13.18 0 003.5-3.848 13.7 13.7 0 001.832-6.842V9.895a1.9 1.9 0 00-1.9-1.9H10.898a1.9 1.9 0 00-1.9 1.9v7.975a14.539 14.539 0 002.475 8.115 13.107 13.107 0 003.043 3.114l5.687 4.279zM15.868 18.9h8.559"
            />
            <path
                className="prefix__b3"
                stroke={`${themePrimaryMain}`}
                d="M23.931 16.595l2.3 2.3-2.3 2.3M16.477 16.595l-2.3 2.3 2.3 2.3"
            />
        </svg>
    );
}

export default ExtendedLabourWarranty;
