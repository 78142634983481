import React, { Fragment } from 'react';
import { Theme, useTheme } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import { Heading } from 'components/Heading';
import { Paragraph } from 'components/Paragraph';
import Image from 'assets/images/made-in-usa/usa_story_image.webp';
import CardMedia from '@mui/material/CardMedia';
import Link from '@mui/material/Link';
import StaticSubPage from 'components/subpage/StaticSubPage';
import { Box, useMediaQuery } from '@mui/material';
import ArrowRight from 'components/icons/ArrowRight';
import { SXCssObject } from 'models/ThemeImages';

function MadeInUsa() {
    const createCssObject = (theme: Theme): SXCssObject => {
        return {
            contentHeading: {
                paddingBottom: theme.spacing(1),
            },
            contentSecondaryHeading: {
                paddingTop: theme.spacing(4),
            },
            contentImage: {
                paddingTop: theme.spacing(2),
            },
            content: {
                paddingTop: theme.spacing(2),
            },
            bullets: { paddingLeft: '20px', color: theme.palette.primary.main },
        };
    };
    const cssObject: SXCssObject = createCssObject(useTheme());
    const theme = useTheme();
    const isXs = useMediaQuery(theme.breakpoints.only('xs'));
    return (
        <StaticSubPage
            content1={
                <Fragment>
                    <Grid item xs={12} sx={cssObject.contentHeading}>
                        <Heading variant={'h1'} color={'primary'} label={'Made in USA'} />
                    </Grid>
                    <Grid item xs={12} sx={cssObject.contentImage}>
                        <CardMedia component={'img'} image={Image} title={'Energy Star'} />
                    </Grid>
                    <Grid item xs={12} sx={cssObject.contentSecondaryHeading}>
                        <Heading
                            variant={'h3'}
                            color={'primary'}
                            label={'Designed, Engineered, and Assembled in the USA  '}
                            isPopUp
                        />
                    </Grid>
                    <Grid item xs={12} sx={cssObject.content}>
                        <Paragraph align={'justify'} size={isXs ? 'small' : 'normal'}>
                            Goodman HVAC equipment is proudly Designed, Engineered, and Assembled in America.
                        </Paragraph>
                    </Grid>
                    <Grid item xs={12} sx={cssObject.content}>
                        <Paragraph align={'justify'} size={isXs ? 'small' : 'normal'}>
                            Goodman's new manufacturing facility, currently the largest HVAC building in the USA, is
                            located just outside of Houston, TX. The 4.2 million square feet Daikin Texas Technology
                            Park brings together Goodman’s manufacturing, engineering, logistics, and customer support
                            under one very large roof.
                        </Paragraph>
                    </Grid>

                    <Grid item xs={12} sx={cssObject.contentSecondaryHeading}>
                        <Heading variant={'h3'} color={'primary'} isPopUp label={'Why American Pride Matters'} />
                    </Grid>
                    <Grid item xs={12} sx={cssObject.content}>
                        <Paragraph align={'justify'} size={isXs ? 'small' : 'normal'}>
                            By keeping assembly operations in the US, Goodman can provide these benefits:
                        </Paragraph>
                    </Grid>
                    <Grid item xs={12}>
                        <Box component={'ul'} sx={cssObject.bullets}>
                            <li>
                                <Paragraph size={isXs ? 'small' : 'normal'}>Provide jobs for Americans</Paragraph>
                            </li>
                            <li>
                                <Paragraph size={isXs ? 'small' : 'normal'}>
                                    Harness the innovation and productivity of America
                                </Paragraph>
                            </li>
                        </Box>
                    </Grid>
                    <Grid item xs={12} sx={cssObject.content}>
                        <Paragraph align={'justify'}>
                            <ArrowRight />
                            <Link href={'https://www.goodmanmfg.com/about/why-goodman'} target={'_blank'}>
                                {' Five Reasons to Select the Goodman Brand'}
                            </Link>
                        </Paragraph>
                        <Paragraph>
                            <ArrowRight />
                            <Link href={'https://www.goodmanmfg.com/resources/customer-reviews'} target={'_blank'}>
                                {' Customer Reviews'}
                            </Link>
                        </Paragraph>
                    </Grid>
                </Fragment>
            }
        />
    );
}

export default MadeInUsa;
