import React from 'react';
import ContentContainer from '../../components/ContentContainer';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import { Theme, useTheme } from '@mui/material/styles';
import FormControl from '@mui/material/FormControl';
import Navigator from '../../components/Navigator';
import LoadingOverlay from '../../components/common/LoadingOverlay';
import { Paragraph } from '../../components/Paragraph';
import { Options } from '../../components/WizardInputs';
import { FormikProps, withFormik } from 'formik';
import { Box, InputLabel } from '@mui/material';
import { schemaSchedule } from 'pages/wizard/wizard-schema';
import FormikDateFieldSchedulePage from 'components/FormikDateFieldSchedulePage';
import WizardSelectionSchedule from 'components/WizardSelectionSchedule';
import { Schedule } from 'models/cart';
import { Button } from 'components/Button';
import { Heading } from 'components/Heading';
import { closeSnack, showSnackbarError } from 'components/common/Snackbar/SnackbarHelper';
import { SXCssObject } from 'models/ThemeImages';
import { format } from 'date-fns';
import MinimisedInstructionsDrawer from './MinimisedInstructionsDrawer';
import { useSelector } from 'react-redux';
import { selectMultipleOpenProjects } from 'store/selectors/storeSelector';

const createCssObject = (theme: Theme): SXCssObject => ({
    root: {
        position: 'relative',
    },
    header: {
        py: {
            xs: 3,
            sm: 4,
            md: 5,
        },
    },
    subHeadingStyle: {
        marginTop: {
            xs: '20px',
            md: 'inherit',
        },
    },
    divider: {
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
    },
    gridContainerRow: {
        marginBottom: {
            xs: '25px',
            md: '40px',
        },
    },
    dividerRoot: {
        height: '1px',
        borderTop: `1px solid ${theme.customProperties.borderDivider}`,
        borderBottom: '0px',
        marginBottom: {
            xs: '20px',
            md: '32px',
        },
        marginTop: {
            xs: '5px',
            md: '8px',
        },
    },
    whiteBg: {
        backgroundColor: theme.palette.common.white,
        padding: {
            xs: '20px 5px 60px',
            sm: '40px 0px 25px',
            md: '20px 0px',
        },
        marginTop: {
            xs: '20px',
            sm: '30px',
            md: '70px',
        },
    },
    labelStyle: {
        paddingBottom: '10px',
        fontSize: '0.875rem',
        color: theme.palette.text.primary,
    },

    nextBtnStyle: {
        width: '100%',
        display: 'inline-block',
        flex: {
            xs: '2',
            md: '3',
        },
    },
    previousBtnStyle: {
        width: '100%',
        display: 'inline-block',
        flex: {
            sm: '2',
        },
    },
    contentContainer: {
        minHeight: `calc(100vh - ${theme.spacing(15)})`,
    },
});

interface Props {
    schedule: Schedule[];
    onNext: (scheduleList: Schedule[]) => void;
    loading: boolean;
    onPrev: () => void;
    minDate: Date;
    maxDate: Date;
}
const errorSnackbarKey = 'Schedule-sameslot-error';
const snackbarParams = { timeout: null, preventDuplicate: true, key: errorSnackbarKey };
function ScheduleHomeInspection(props: Props & FormikProps<FormValues>) {
    const cssObject: SXCssObject = createCssObject(useTheme());
    const multipleOpenProjects = useSelector(selectMultipleOpenProjects);
    const { loading } = props;
    const { submitForm, minDate, maxDate } = props;
    React.useEffect(() => {
        const inputList = document.querySelectorAll('.MuiOutlinedInput-input') as any;
        for (let i = 0; i < inputList.length; i++) {
            inputList[i].disabled = true;
        }
    }, []);
    const timeSlotOptions: Options[] = [
        { id: 'time-slot-1', value: '08:00AM-12:00PM', label: '08:00AM-12:00PM' },
        { id: 'time-slot-2', value: '10:00AM-02:00PM', label: '10:00AM-02:00PM' },
        { id: 'time-slot-3', value: '01:00PM-05:00PM', label: '01:00PM-05:00PM' },
    ];
    return (
        <ContentContainer disableGrid background={'bg2'}>
            <LoadingOverlay open={loading} />
            <Box sx={cssObject.contentContainer}>
                <ContentContainer disableGrid background={'bg3'}>
                    <ContentContainer>
                        <Navigator color={'primary'} />
                    </ContentContainer>
                </ContentContainer>
                <ContentContainer disableGrid>
                    <ContentContainer sx={cssObject.header}>
                        <Grid container spacing={2} alignItems={'center'} justifyContent={'flex-end'}>
                            <Grid item xs={12} sm={12} md={multipleOpenProjects ? 9 : 12}>
                                <Heading label={'Schedule Time for Your In-Home HVAC Pre-Installation'} />
                                <Box sx={cssObject.subHeadingStyle}>
                                    <Paragraph size="small">
                                        Select Three Preferred Date/Time Windows To Schedule Your In-Home
                                        Pre-Installation Visit With Our Local Hvac Technician{' '}
                                        <Paragraph component="span" semibold>
                                            (Free Of Charge)
                                        </Paragraph>
                                    </Paragraph>
                                </Box>
                            </Grid>
                            <Grid
                                item
                                xs={12}
                                sm={12}
                                md={3}
                                display={'flex'}
                                sx={{ justifyContent: { xs: 'flex-start', md: 'flex-end' } }}
                            >
                                {multipleOpenProjects ? <MinimisedInstructionsDrawer /> : ''}
                            </Grid>
                        </Grid>
                    </ContentContainer>

                    <ContentContainer>
                        <Paragraph largeFont semibold headingColor>
                            Schedule 01
                        </Paragraph>
                        <Divider sx={cssObject.dividerRoot}></Divider>
                        <Grid spacing={2} container sx={cssObject.gridContainerRow}>
                            <Grid item xs={12} md={4}>
                                <InputLabel sx={cssObject.labelStyle}>Preferred Date </InputLabel>
                                <FormikDateFieldSchedulePage
                                    name={'schedule[0].preferredDate'}
                                    id="sch1"
                                    label={'Preferred Time'}
                                    fullWidth={true}
                                    maxDate={maxDate}
                                    minDate={minDate}
                                />
                            </Grid>
                            <Grid item xs={12} md={8}>
                                <FormControl style={{ width: '100%' }}>
                                    <ContentContainer disableGrid>
                                        <Grid container>
                                            <WizardSelectionSchedule
                                                id={'schedule-day-1'}
                                                name="schedule[0].preferredTimeSlot"
                                                label={'Preferred Time'}
                                                options={timeSlotOptions}
                                            />
                                        </Grid>
                                    </ContentContainer>
                                </FormControl>
                            </Grid>
                        </Grid>
                        <Paragraph largeFont semibold headingColor>
                            Schedule 02
                        </Paragraph>
                        <Divider sx={cssObject.dividerRoot}></Divider>
                        <Grid spacing={2} container sx={cssObject.gridContainerRow}>
                            <Grid item xs={12} md={4}>
                                <InputLabel sx={cssObject.labelStyle}>Preferred Date </InputLabel>
                                <FormikDateFieldSchedulePage
                                    name={'schedule[1].preferredDate'}
                                    id="sch2"
                                    label={'Preferred Time'}
                                    fullWidth={true}
                                    maxDate={maxDate}
                                    minDate={minDate}
                                />
                            </Grid>
                            <Grid item xs={12} md={8}>
                                <FormControl style={{ width: '100%' }}>
                                    <ContentContainer disableGrid>
                                        <Grid container>
                                            <WizardSelectionSchedule
                                                id={'schedule-day-2'}
                                                name="schedule[1].preferredTimeSlot"
                                                label={'Preferred Time'}
                                                options={timeSlotOptions}
                                            />
                                        </Grid>
                                    </ContentContainer>
                                </FormControl>
                            </Grid>
                        </Grid>
                        <Paragraph largeFont semibold headingColor>
                            Schedule 03
                        </Paragraph>
                        <Divider sx={cssObject.dividerRoot}></Divider>
                        <Grid spacing={2} container sx={cssObject.gridContainerRow}>
                            <Grid item xs={12} md={4}>
                                <InputLabel sx={cssObject.labelStyle}>Preferred Date </InputLabel>
                                <FormikDateFieldSchedulePage
                                    name={'schedule[2].preferredDate'}
                                    id="sch3"
                                    label={'Preferred Time'}
                                    fullWidth={true}
                                    maxDate={maxDate}
                                    minDate={minDate}
                                />
                            </Grid>
                            <Grid item xs={12} md={8}>
                                <FormControl style={{ width: '100%' }}>
                                    <ContentContainer disableGrid>
                                        <Grid container>
                                            <WizardSelectionSchedule
                                                id={'schedule-day-3'}
                                                name="schedule[2].preferredTimeSlot"
                                                label={'Preferred Time'}
                                                options={timeSlotOptions}
                                            />
                                        </Grid>
                                    </ContentContainer>
                                </FormControl>
                            </Grid>
                        </Grid>
                    </ContentContainer>
                </ContentContainer>
            </Box>
            <Grid style={{ position: 'relative' }}>
                <ContentContainer disableGrid sx={cssObject.whiteBg}>
                    <ContentContainer>
                        <Grid container justifyContent="flex-end">
                            <Grid item xs={12} md={5} lg={4}>
                                <Grid container spacing={1}>
                                    <Grid item xs={12} sm={6} md={6} sx={cssObject.previousBtnStyle}>
                                        <Button
                                            id={`schedule-back-btn`}
                                            isContrast
                                            onClick={props.onPrev}
                                            isFullWidth={true}
                                        >
                                            Previous
                                        </Button>
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={6} sx={cssObject.nextBtnStyle}>
                                        <Button
                                            isFullWidth={true}
                                            id={`schedule-next-btn`}
                                            color={'primary'}
                                            disabled={!props.isValid}
                                            onClick={submitForm}
                                        >
                                            Submit
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </ContentContainer>
                </ContentContainer>
            </Grid>
        </ContentContainer>
    );
}

interface FormValues {
    schedule: Schedule[];
}

const scheduleComponent = withFormik<Props, FormValues>({
    mapPropsToValues: ({ schedule }) => ({
        schedule: schedule.map((item, key) => {
            return {
                preferredTimeSlot: item.preferredTimeSlot,
                schedule: `schedule${key}`,
                preferredDate: item.preferredDate,
            };
        }),
    }),

    handleSubmit: (values, { props }) => {
        const apiData: Schedule[] = values.schedule.map((item, key) => {
            return {
                preferredTimeSlot: item.preferredTimeSlot.replace(/\s/g, ''),
                schedule: `schedule${key + 1}`,
                preferredDate: format(new Date(item.preferredDate as Date), 'MM-dd-yyyy'),
            };
        });

        props.onNext(apiData);
    },
    validateOnMount: true,
    validationSchema: schemaSchedule,

    validate: (values, propsData) => {
        const apiData: Schedule[] = values.schedule.map((item, key) => {
            return {
                preferredTimeSlot: item.preferredTimeSlot.replace(/\s/g, ''),
                schedule: `schedule${key + 1}`,
                preferredDate: format(new Date(item.preferredDate as Date), 'MM-dd-yyyy'),
            };
        });
        if (
            apiData[0].preferredDate !== '' &&
            apiData[1].preferredDate !== '' &&
            apiData[2].preferredDate !== '' &&
            apiData[0].preferredTimeSlot !== '' &&
            apiData[1].preferredTimeSlot !== '' &&
            apiData[2].preferredTimeSlot !== ''
        ) {
            if (
                apiData[0].preferredDate === apiData[1].preferredDate &&
                apiData[0].preferredTimeSlot === apiData[1].preferredTimeSlot
            ) {
                showSnackbarError('Date and Time Slot for Schedule 1 and Schedule 2 are the same.', snackbarParams);
                return {
                    schedule: [
                        {
                            preferredDate: 'Date and Time Slot for Schedule 1 and Schedule 2 are the same.',
                        },
                    ],
                };
            } else if (
                apiData[1].preferredDate === apiData[2].preferredDate &&
                apiData[1].preferredTimeSlot === apiData[2].preferredTimeSlot
            ) {
                showSnackbarError('Date and Time Slot for Schedule 2 and Schedule 3 are the same.', snackbarParams);
                return {
                    schedule: [
                        {},
                        {
                            preferredDate: 'Date and Time Slot for Schedule 1 and Schedule 2 are the same.',
                        },
                    ],
                };
            } else if (
                apiData[0].preferredDate === apiData[2].preferredDate &&
                apiData[0].preferredTimeSlot === apiData[2].preferredTimeSlot
            ) {
                showSnackbarError('Date and Time Slot for Schedule 1 and Schedule 3 are the same.', snackbarParams);
                return {
                    schedule: [
                        {
                            preferredDate: 'Date and Time Slot for Schedule 1 and Schedule 2 are the same.',
                        },
                    ],
                };
            } else {
                closeSnack(errorSnackbarKey);
                return;
            }
        } else {
            closeSnack(errorSnackbarKey);
        }
    },
})(ScheduleHomeInspection);

export default scheduleComponent;
