import React, { forwardRef, Ref, useImperativeHandle, useRef } from 'react';
import Grid from '@mui/material/Grid';
import { Box, Hidden, useMediaQuery } from '@mui/material';
import { Theme, useTheme } from '@mui/material/styles';
import { Button } from './Button';
import { Paragraph } from './Paragraph';
import { Address, AddressType, Cart, CartStatus } from 'models/cart';
import { useDispatch, useSelector } from 'react-redux';
import { isCartForEquipmentOnly, selectCart, selectCartProcessing } from 'store/selectors/cart';
import LoadingOverlay from './common/LoadingOverlay';
import CollapseIcon from './icons/CollapseIcon';
import ExpandIcon from './icons/ExpandIcon';
import AddAddressIcon from './icons/AddAddressIcon';
import { OrderAddress } from 'models/order';
import { AppRoute } from 'models/route';
import { AddressContainer } from 'pages/user/ShippingAddress/AddressContainer';
import { getAddressListAction } from 'store/actions/addressActions';
import { addressState } from 'store/selectors/addressSelector';
import { ThemeWithAdditionalData } from 'themes/theme';
import { SXCssObject } from 'models/ThemeImages';
import { isCartOpenForModification } from 'pages/cart/cart-utils';

const createCssObject = (theme: Theme): SXCssObject => ({
    root: {
        backgroundColor: theme.palette.common.white,
        height: '100%',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        borderRadius: {
            sm: '4px',
        },
        minHeight: {
            sm: '150px',
        },
        padding: {
            xs: '18px 16px 18px',
            sm: '24px 18px',
        },
        border: {
            xs: 'none',
            sm: 'inherit',
        },
        borderBottom: {
            xs: '1px solid #E0E0E0',
            sm: 'inherit',
        },
    },
    addressLayout: {
        transition: '200ms',
        paddingBottom: {
            xs: '16px',
            md: 'inherit',
        },
        paddingTop: {
            xs: '8px',
            sm: 'inherit',
        },
    },
    dividerStyle: {
        border: 'none',
        borderTop: `2px solid ${theme.palette.primary.contrastText}`,
        margin: '10px 0px 8px',
        display: {
            xs: 'none',
            sm: 'inherit',
        },
    },
    addBillingAddressSection: {
        backgroundColor: 'white',
        padding: '12px',
        height: '100%',
        borderRadius: '4px',
    },
    addBillingAddressContainer: {
        height: '100%',
        border: `1px solid ${theme.palette.primary.contrastText}`,
        display: 'flex',
        justifyContent: 'center',
        cursor: 'pointer',
        minHeight: {
            xs: '50px',
            sm: '150px',
        },
    },
    addBillingAddressWrapper: {
        display: 'flex',
        alignItems: 'center',
    },
    reduceOpacity: {
        opacity: 0.4,
    },
});
interface Props {
    shippingAddress: Address | undefined;
    billingAddress: Address | undefined;
    removeEventListenerForBilling?: () => void; // For EO Flow, while Mandatorily capturing billing on "Continue CTA" in Cart
}
interface InnerProps {
    onChange: () => void;
    flow: AddressType;
    address: Address;
    cartData: Cart;
}
interface RefObject {
    getBillingAddressAndRedirectToRoute: () => void;
}
function AddressLayoutForCartAndBilling(props: Props, ref: Ref<RefObject>) {
    const dispatch = useDispatch();
    const cssObject: SXCssObject = createCssObject(useTheme());
    let cartLoading = useSelector(selectCartProcessing);
    const cartData = useSelector(selectCart);
    let addressData = useSelector(addressState);
    const theme = useTheme();
    const isXs = useMediaQuery(theme.breakpoints.only('xs'));
    const { shippingAddress, billingAddress, removeEventListenerForBilling } = props;
    const [redirectToSchedule, setRedirectionToSchedule] = React.useState(false);
    const [invokeDOMEventOnBillingCatpure, setInvokeDOMEventOnBillingCatpure] = React.useState(false);
    const isEquipmentOnly = useSelector(isCartForEquipmentOnly);
    let mounted: any = useRef();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    React.useEffect(() => {
        if (!mounted.current) {
            mounted.current = true;
            if (!(shippingAddress && shippingAddress.firstname)) {
                setAddressCaptureScenario(AddressType.Home);
                dispatch(getAddressListAction(true));
            }
        }
    });
    useImperativeHandle(ref, () => ({ getBillingAddressAndRedirectToRoute }));

    const getBillingAddressAndRedirectToRoute = () => {
        setInvokeDOMEventOnBillingCatpure(cartData.status === CartStatus.EQ_READY_FOR_PAYMENT);
        setRedirectionToSchedule(true);
        setBillingAddress();
    };
    const [addressCaptureScenario, setAddressCaptureScenario] = React.useState<AddressType>(AddressType.Home); //either shipping address or billing address
    const shouldHideCloseButton = () => {
        if (addressCaptureScenario === AddressType.Home && (!shippingAddress || !shippingAddress.firstname)) {
            return true;
        }
        return false;
    };

    const setBillingAddress = () => {
        setAddressCaptureScenario(AddressType.Billing);
        dispatch(getAddressListAction(true));
    };

    const setHomeAddress = () => {
        setAddressCaptureScenario(AddressType.Home);
        dispatch(getAddressListAction(true));
    };

     /**
     * DOMEvent.EVENT_SHOW_CONTRACTOR_MODAL_AFTER_MANDATORY_BILLING_CAPTURE 
     * setInvokeDOMEventOnBillingCatpure(false) - will be set to false, if User volutarily chooses to set/Change billing address
     * Also the Above DOMEvent will be unregistered.
     */
    const handleAddBillingAddressClick = () => {
        setRedirectionToSchedule(false);
        setBillingAddress();
        setInvokeDOMEventOnBillingCatpure(false);
        removeEventListenerForBilling && removeEventListenerForBilling();
    };

    const getIsFirstTimeAddressCaptureScenario = () => {
        return (addressCaptureScenario === AddressType.Home && !(shippingAddress && shippingAddress.firstname)) ||
            (addressCaptureScenario === AddressType.Billing && !(billingAddress && billingAddress.firstname))
            ? true
            : false;
    };
    return (
        <Box>
            <LoadingOverlay open={addressData.loading || cartLoading} />
            <AddressContainer
                flow={addressCaptureScenario}
                isFirstTimeCapture={getIsFirstTimeAddressCaptureScenario()}
                hideCloseButton={shouldHideCloseButton()}
                invokeDOMEventOnBillingCatpure={invokeDOMEventOnBillingCatpure}
                pageToRedirect={redirectToSchedule ? isEquipmentOnly ? undefined : AppRoute.Schedule : undefined}
            />

            <Grid container spacing={isXs ? 0 : 1}>
                {shippingAddress && shippingAddress.firstname && (
                    <AddressComponent cartData={cartData} flow={AddressType.Home} address={shippingAddress} onChange={setHomeAddress} />
                )}
                {billingAddress ? (
                    <AddressComponent
                        flow={AddressType.Billing}
                        address={billingAddress}
                        cartData={cartData}
                        onChange={handleAddBillingAddressClick}
                    />
                ) : (
                    <Grid item xs={12} sm={6}>
                        <Box sx={cssObject.addBillingAddressSection}>
                            <Box sx={cssObject.addBillingAddressContainer} onClick={handleAddBillingAddressClick}>
                                <Box sx={cssObject.addBillingAddressWrapper} >
                                    <AddAddressIcon />
                                    <div style={{ marginLeft: '8px' }}>
                                        <Paragraph semibold headingColor>
                                            ADD BILLING ADDRESS
                                        </Paragraph>
                                    </div>
                                </Box>
                            </Box>
                        </Box>
                    </Grid>
                )}
            </Grid>
        </Box>
    );
}

function AddressComponent(innerProps: InnerProps) {
    const { flow, address, onChange,cartData } = innerProps;
    const cssObject: SXCssObject = createCssObject(useTheme());
    const [isExpanded, setExpanded] = React.useState(false);
    const theme = useTheme<ThemeWithAdditionalData>();
    const isXs = useMediaQuery(theme.breakpoints.only('xs'));
    const isDownSm = useMediaQuery(theme.breakpoints.down('md'));
   
    const toggleExpanded = (currentState: boolean) => {
        setExpanded(!currentState);
    };
    const isCartOpen = React.useMemo(() => isCartOpenForModification(cartData.status), [cartData.status]);
    const isBtnDisabled = (flow === AddressType.Home) && !isCartOpen;
    const addressLabel = flow !== AddressType.Home ? flow : theme.content.label.shippingAddressLabel;
    return (
        <Grid item container xs={12} sm={6}>
            <Grid container sx={cssObject.root}>
                <Grid item container style={{ flex: '0 1 auto' }} justifyContent={'space-between'}>
                    <Grid item>
                        <Paragraph headingStyle largeFont bold>
                            {`${addressLabel} Address`}
                        </Paragraph>
                    </Grid>
                    <Hidden smUp>
                        <Grid
                            item
                            onClick={() => toggleExpanded(isExpanded)}
                            style={{ display: 'flex', alignItems: 'center' }}
                        >
                            {isExpanded ? <CollapseIcon /> : <ExpandIcon />}
                        </Grid>
                    </Hidden>
                </Grid>
                <Grid item xs={12} style={{ flex: '0 1 auto' }}>
                    <Box component={'hr'} sx={cssObject.dividerStyle} />
                </Grid>
                {(isExpanded || !isXs) && (
                    <Grid item container style={{ flex: '0 1 auto' }} justifyContent={'space-between'}>
                        <Grid item xs={12} md={7} sx={cssObject.addressLayout}>
                            <Paragraph headingColor>{address.addressName}</Paragraph>
                            <Paragraph>{address.firstname + ' ' + address.lastname}</Paragraph>
                            <Paragraph>
                                {address.address1}
                                {address.address2 ? ', ' + address.address2 : ''}
                                {','}
                            </Paragraph>
                            <Paragraph>{address.city + ', ' + address.region + ' ' + address.postcode}</Paragraph>
                        </Grid>
                        <Grid item xs={12} md={5} container alignItems={'flex-end'} justifyContent={'flex-end'}>
                            <Button
                                color={'primary'}
                                isContrast
                                onClick={onChange}
                                disabled={isBtnDisabled}
                                isFullWidth={isDownSm}
                            >
                                Change Address
                            </Button>
                        </Grid>
                    </Grid>
                )}
            </Grid>
        </Grid>
    );
}
interface OrderProps {
    flow: AddressType;
    address: OrderAddress;
}
export function OrderAddressComponent(orderProps: OrderProps) {
    const { flow, address } = orderProps;
    const firstName = address.firstName;
    const lastName = address.lastName;
    const postcode = address.postalCode;
    const cssObject: SXCssObject = createCssObject(useTheme());
    const [isExpanded, setExpanded] = React.useState(false);
    const theme = useTheme<ThemeWithAdditionalData>();
    const isXs = useMediaQuery(theme.breakpoints.only('xs'));

    const toggleExpanded = (currentState: boolean) => {
        setExpanded(!currentState);
    };
    const addressLabel = flow !== AddressType.Home ? flow : theme.content.label.shippingAddressLabel;
    return (
        <Grid item container xs={12} sm={6}>
            <Grid container sx={cssObject.root}>
                <Grid item container style={{ flex: '0 1 auto' }} justifyContent={'space-between'}>
                    <Grid item>
                        <Paragraph headingStyle largeFont bold>
                            {`${addressLabel} Address`}
                        </Paragraph>
                    </Grid>
                    <Hidden smUp>
                        <Grid item onClick={() => toggleExpanded(isExpanded)} style={{ display: 'flex' }}>
                            {isExpanded ? <CollapseIcon /> : <ExpandIcon />}
                        </Grid>
                    </Hidden>
                </Grid>
                <Grid item xs={12} style={{ flex: '0 1 auto' }}>
                    <Box component={'hr'} sx={cssObject.dividerStyle} />
                </Grid>
                {(isExpanded || !isXs) && (
                    <Grid item justifyContent={'space-between'} container style={{ flex: '1 1 auto' }}>
                        <Grid item xs={12} md={7} sx={cssObject.addressLayout}>
                            <Paragraph headingColor>{address.addressName}</Paragraph>
                            {firstName && lastName && <Paragraph>{firstName + ' ' + lastName}</Paragraph>}
                            <Paragraph>
                                {address.address1}
                                {address.address2 ? ', ' + address.address2 : ''}
                                {','}
                            </Paragraph>
                            {postcode && <Paragraph>{address.city + ', ' + address.region + ' ' + postcode}</Paragraph>}
                        </Grid>
                    </Grid>
                )}
            </Grid>
        </Grid>
    );
}

export default forwardRef(AddressLayoutForCartAndBilling);
