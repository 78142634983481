import React, { MouseEventHandler, PropsWithChildren } from 'react';
import { Theme, useTheme } from '@mui/material/styles';
import MuiButton from '@mui/material/Button';
import { Box } from '@mui/material';
import { SXCssObject } from 'models/ThemeImages';
import TooltipSchedule from './TooltipSchedule';

const createCssObject = (theme: Theme, props: PropsWithChildren<Props>): SXCssObject => ({
    root: {
        height: '40px',
        padding: props.hideInfoIcon ? theme.spacing(1, 5) : theme.spacing(1, 6.3, 1, 4),
        minWidth: theme.spacing(10),
        width: {
            xs: '100%',
            sm: 'inherit',
        },
        '&:hover': {
            background: theme.palette.primary.dark,
        },
        whiteSpace: 'nowrap',
    },
    container: {
        display: 'flex',
        width: {
            xs: '100%',
            md: 'auto',
        },
    },
    isSmallerFontForSmallerDevice: {
        fontSize: {
            xs: '12px',
            sm: 'inherit',
        },
    },
    isSmallerButton: {
        height: '24px !important',
        fontSize: '12px',
    },
    fullWidth: {
        height: '40px',
        width: '100%',
        padding: theme.spacing(1, 2),
        whiteSpace: 'nowrap',
    },
    primary: {
        color: theme.palette.text.secondary,
        backgroundColor: theme.palette.primary.main,
        '&.Mui-disabled': {
            color: theme.palette.text.secondary,
            backgroundColor: theme.palette.primary.main,
            opacity: '0.4',
        },
        '&:hover': {
            backgroundColor: theme.palette.primary.dark,
        },
    },

    transparent: {
        backgroundColor: 'transparent !important',
        color: 'white',
        border: '1px solid white',
    },
    contrast: {
        color: theme.palette.secondary.light,
        backgroundColor: theme.palette.secondary.main,
        border: `1px solid ${theme.palette.secondary.light}`,
        '&:hover': {
            backgroundColor: theme.palette.secondary.dark,
        },
        '&.Mui-disabled': {
            color: theme.palette.secondary.light,
            backgroundColor: theme.palette.secondary.main,
            border: `1px solid ${theme.palette.secondary.light}`,
        },
    },
});

interface Props {
    id?: string;
    color?: 'primary' | 'secondary';
    onClick?: MouseEventHandler;
    disabled?: boolean;
    isFullWidth?: boolean;
    isSmallerButton?: boolean;
    isTransparent?: boolean;
    isContrast?: boolean;
    isSmallerFontForSmallerDevice?: boolean;
    hideInfoIcon?: any;
    toolTipText?: string;
    toolTipHead?: string;
    quoteTip?: boolean;
}

export function ButtonWithIcon(props: PropsWithChildren<Props>) {
    const cssObject: SXCssObject = createCssObject(useTheme(), props);
    const {
        children,
        color,
        isFullWidth,
        isTransparent,
        isSmallerFontForSmallerDevice,
        isSmallerButton,
        isContrast,
        hideInfoIcon,
        toolTipText,
        quoteTip,
        toolTipHead,
        ...other
    } = props;
    const rootStyle = isFullWidth ? cssObject.fullWidth : cssObject.root;

    return (
        <Box sx={cssObject.container}>
            <MuiButton
                disableElevation
                sx={[
                    rootStyle,
                    color === 'primary' && cssObject.primary,
                    color === 'secondary' && cssObject.secondary,
                    isSmallerFontForSmallerDevice === true && cssObject.isSmallerFontForSmallerDevice,
                    isSmallerButton === true && cssObject.isSmallerButton,
                    isTransparent === true && cssObject.transparent,
                    isContrast === true && cssObject.contrast,
                ]}
                variant={'contained'}
                color={color}
                {...other}
            >
                {children}
            </MuiButton>
            {!hideInfoIcon && <TooltipSchedule toolTipText={toolTipText} />}
        </Box>
    );
}
