import * as React from 'react';
import { useTheme } from '@mui/material/styles';
import { getThemePrimaryMain } from 'components/common/Utilities';

export default function ArrowRight(props: any) {
    const theme = useTheme();
    const themePrimaryMain = getThemePrimaryMain(theme);
    return (
        <svg
            id="Component_28_1"
            data-name="Component 28 – 1"
            xmlns="http://www.w3.org/2000/svg"
            width="12"
            height="12"
            viewBox="0 0 12 12"
        >
            <g
                id="Rectangle_3"
                data-name="Rectangle 3"
                transform="translate(12 12) rotate(180)"
                fill="#fff"
                stroke={themePrimaryMain}
                strokeWidth="1"
                opacity="0"
            >
                <rect width="12" height="12" stroke="none" />
                <rect x="0.5" y="0.5" width="11" height="11" fill="none" />
            </g>
            <path
                id="Path_1"
                data-name="Path 1"
                d="M4.509,0,0,3.988,4.509,8.371"
                transform="translate(9.158 10.186) rotate(180)"
                fill="none"
                stroke={themePrimaryMain}
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
            />
        </svg>
    );
}
