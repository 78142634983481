import React from 'react';
import ContentContainer from 'components/ContentContainer';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import { Theme, useTheme } from '@mui/material/styles';
import { Heading } from 'components/Heading';
import { Paragraph } from 'components/Paragraph';
import { Order } from 'models/order';
import { Container, useMediaQuery } from '@mui/material';
import ActiveCartDetail from 'components/ActiveCartDetails';
import OrderDetail from './OrderDetail';
import Navigator from '../../components/Navigator';
import NoOrdersIcon from 'assets/icons/icon_no_orders.svg';
import { useSelector } from 'react-redux';
import { selectCartStatus } from 'store/selectors/cart';
import { CartStatus } from 'models/cart';
import { Button } from 'components/Button';
import { AppRoute } from 'models/route';
import { useNavigate } from 'react-router';
import { SXCssObject } from 'models/ThemeImages';
import { selectMyOrdersLoading } from 'store/selectors/order';
import LoadingOverlay from 'components/common/LoadingOverlay';
import { isHomeVestors } from '../../config';

const createCssObject = (theme: Theme): SXCssObject => ({
    header: {
        pt: { xs: 2.5, sm: 4, md: 6 },
        pb: { xs: 1.5, sm: 2, md: 3 },
    },
    header2: {
        pt: { xs: 2, sm: 2 },
        pb: { xs: 2, sm: 3 },
    },
    header3: {
        backgroundColor: '#EFEFEF',
        margin: '0px',
        maxWidth: '100%',
    },
    header1: {
        pt: { xs: 0.5, sm: 0 },
        pb: 1.5,
    },
    navContainer: {
        pl: {
            xs: 0,
            sm: 'inherit',
        },
        pr: {
            xs: 0,
            sm: 'inherit',
        },
        backgroundColor: {
            xs: theme.palette.common.white,
        },
    },
    noOrders: {
        mt: { xs: 5, sm: 10, md: 15 },
    },
});
const OrderHeading = () => {
    const cssObject: SXCssObject = createCssObject(useTheme());
    return (
        <Container maxWidth={false} id={`active-cart`} disableGutters>
            <Grid container sx={cssObject.header3} spacing={3}>
                <Grid item xs={2} style={{ paddingBottom: 15, paddingTop: 15 }}>
                    <Paragraph semibold headingColor>
                        Order Number
                    </Paragraph>
                </Grid>
                <Grid container xs={2} style={{ paddingBottom: 15, paddingTop: 15 }} justifyContent={'left'}>
                    <Paragraph semibold headingColor>
                        Product’s Purchased
                    </Paragraph>
                </Grid>
                <Grid
                    xs={2}
                    sx={{ paddingBottom: 1.5, paddingTop: 1.5, paddingLeft: 1 }}
                    container
                    justifyContent={'left'}
                >
                    <Grid item>
                        <Paragraph semibold headingColor>
                            Payment Date
                        </Paragraph>
                    </Grid>
                </Grid>
                <Grid item xs={3} style={{ paddingBottom: 15, paddingTop: 15 }}>
                    <Paragraph semibold headingColor>
                        Property
                    </Paragraph>
                </Grid>
                <Grid container xs={1} sx={{ p: 1.5 }} justifyContent={'left'}>
                    <Paragraph semibold headingColor>
                        Total Price
                    </Paragraph>
                </Grid>
                <Grid item xs={2} style={{ paddingBottom: 15, paddingTop: 15 }}>
                    <Paragraph semibold headingColor>
                        Order Status
                    </Paragraph>
                </Grid>
            </Grid>
        </Container>
    );
};

interface Props {
    myOrders?: Order[];
}

function MyOrders(props: Props) {
    const cssObject: SXCssObject = createCssObject(useTheme());

    const theme = useTheme();
    const isUpMd = useMediaQuery(theme.breakpoints.up('md'));
    const cartStatus = useSelector(selectCartStatus);
    const { myOrders } = props;
    const navigate = useNavigate();
    const myOrdersLoading = useSelector(selectMyOrdersLoading);

    return (
        <ContentContainer disableGrid background={'bg2'}>
            <LoadingOverlay open={myOrdersLoading} />
            <ContentContainer disableGrid background={'bg3'} sx={cssObject.navContainer}>
                <ContentContainer>
                    <Navigator color={'primary'} />
                </ContentContainer>
            </ContentContainer>
            <Divider />
            <ContentContainer disableGrid>
                <ContentContainer sx={cssObject.header}>
                    <Grid item xs={12} container alignItems={'center'}>
                        <Grid item xs={6}>
                            <Heading label={'My Orders'} />
                        </Grid>
                    </Grid>
                </ContentContainer>
            </ContentContainer>
            {!isHomeVestors() && cartStatus && cartStatus !== CartStatus.READY_TO_SCHEDULE && (
                <div>
                    <ContentContainer>
                        <Grid container sx={cssObject.header1}>
                            <Grid item xs={12} container spacing={2}>
                                <Grid item xs={12}>
                                    <Paragraph bold responsiveFontSize22 headingColor>
                                        Active Cart Details
                                    </Paragraph>
                                </Grid>
                            </Grid>
                        </Grid>
                    </ContentContainer>
                    <ContentContainer>
                        <ContentContainer disableGrid background={'bg2'}>
                            <Grid container direction={'column'}>
                                <Grid container spacing={2} justifyContent="space-between">
                                    <Grid item xs={12}>
                                        <ActiveCartDetail />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </ContentContainer>
                    </ContentContainer>
                </div>
            )}
            {myOrders && myOrders.length > 0 && !myOrdersLoading && (
                <div style={{ marginBottom: '100px' }}>
                    {cartStatus && cartStatus !== CartStatus.READY_TO_SCHEDULE && (
                        <ContentContainer>
                            <Grid container sx={cssObject.header2}>
                                <Paragraph bold responsiveFontSize22 headingColor>
                                    Order Details
                                </Paragraph>
                            </Grid>
                        </ContentContainer>
                    )}
                    <ContentContainer>
                        {isUpMd && <OrderHeading />}
                        {myOrders.map((order, index) => {
                            if (
                                order.incrementId &&
                                order.id &&
                                order.items &&
                                order.shippingAddress &&
                                order.createdAt &&
                                order.grandTotal &&
                                order.status
                            ) {
                                return (
                                    <OrderDetail
                                        key={index}
                                        orderID={order.incrementId}
                                        id={order.id}
                                        items={order.items}
                                        property={order.shippingAddress}
                                        paymentDate={order.createdAt}
                                        totalPrice={order.grandTotal}
                                        orderStatus={order.status}
                                        totalDue={order.totalDue}
                                    />
                                );
                            } else {
                                return <></>;
                            }
                        })}
                    </ContentContainer>
                </div>
            )}
            {(!cartStatus || cartStatus === CartStatus.READY_TO_SCHEDULE) &&
                (!myOrders || myOrders.length === 0) &&
                !myOrdersLoading && (
                    <Grid sx={cssObject.noOrders}>
                        <Grid style={{ display: 'flex', justifyContent: 'center' }}>
                            <img alt={'no-orders'} src={NoOrdersIcon} />
                        </Grid>
                        <br />
                        <Grid style={{ display: 'flex', justifyContent: 'center' }}>
                            <Paragraph>You have placed no orders yet.</Paragraph>
                        </Grid>
                        <Grid style={{ display: 'flex', justifyContent: 'center', padding: '20px' }}>
                            <Button
                                id={'sub-content-get-started-btn'}
                                color={'primary'}
                                onClick={() => {
                                    navigate(AppRoute.AboutYourHome);
                                }}
                            >
                                Let's Get Started
                            </Button>
                        </Grid>
                    </Grid>
                )}
        </ContentContainer>
    );
}

export default MyOrders;
