import { useTheme } from '@mui/material/styles';
import { getThemePrimaryMain } from 'components/common/Utilities';
import * as React from 'react';

export default function VideoBg(props: any) {
    const theme = useTheme();
    const themePrimaryMain = getThemePrimaryMain(theme);
    return (
        <svg baseProfile="tiny-ps" xmlns="http://www.w3.org/2000/svg" width="100%" viewBox="0 0 2700 1600" {...props}>
            <path
                id="prefix__Shape_1_copy_2"
                d="M2325 1552c-475.03 0-859-383.97-859-859s383.97-859 859-859 859 383.97 859 859-383.97 859-859 859z"
                opacity={0.102}
                fill={`${themePrimaryMain}`}
            />
            <path
                id="prefix__Shape_1_copy"
                d="M2325.5 1241c-302.77 0-547.5-244.73-547.5-547.5S2022.73 146 2325.5 146 2873 390.73 2873 693.5 2628.27 1241 2325.5 1241z"
                opacity={0.2}
                fill={`${themePrimaryMain}`}
            />
            <path
                id="prefix__Shape_1"
                d="M2323 916c-123.32 0-223-99.68-223-223s99.68-223 223-223 223 99.68 223 223-99.68 223-223 223z"
                opacity={0.302}
                fill={`${themePrimaryMain}`}
            />
        </svg>
    );
}
