import * as React from 'react';
import { useTheme } from '@mui/material/styles';
import { getThemePrimaryMain } from 'components/common/Utilities';

export default function ReviewIcon(props: any) {
    const theme = useTheme();
    const themePrimaryMain = getThemePrimaryMain(theme);
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={31} height={31} {...props}>
            <circle cx={15.5} cy={15.5} r={15.5} fill={`${themePrimaryMain}`} />
            <path
                d="M14.562 19.899l.313-.516q-2.657-1.406-2.657-2.656 0-.531 1-1.047a3.7 3.7 0 001.243-.937 1.978 1.978 0 00.352-1.219 2.332 2.332 0 00-.711-1.711 2.316 2.316 0 00-1.7-.711 2.142 2.142 0 00-1.774.875 3.613 3.613 0 00-.68 2.281 5.273 5.273 0 001.751 3.969 6.938 6.938 0 001.164.883 13.4 13.4 0 001.699.789zm6.174 0l.313-.516q-2.657-1.422-2.657-2.656a.731.731 0 01.219-.523 3.246 3.246 0 01.782-.523 3.472 3.472 0 001.227-.93 1.994 1.994 0 00.367-1.227 2.338 2.338 0 00-.7-1.719 2.3 2.3 0 00-1.688-.7 2.157 2.157 0 00-1.79.875 3.613 3.613 0 00-.68 2.281 5.235 5.235 0 00.445 2.125 5.663 5.663 0 001.321 1.844 9.273 9.273 0 002.844 1.669z"
                fill="#fff"
            />
        </svg>
    );
}
