import * as React from 'react';
import { useTheme } from '@mui/material/styles';
import { getThemePrimaryMain } from 'components/common/Utilities';

export default function ArrowDown2(props: any) {
    const theme = useTheme();
    const themePrimaryMain = getThemePrimaryMain(theme);
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={15.478} height={13.121} {...props}>
            <g stroke={themePrimaryMain} strokeLinecap="round" strokeWidth={2} fill="none">
                <path d="M2.9 6.306h9.729" />
                <path d="M10.804 3.959l2.347 2.347-2.347 2.347" strokeLinejoin="round" />
            </g>
        </svg>
    );
}
