import { Theme } from '@mui/material/styles';
import { Container, Grid, Checkbox, useMediaQuery, useTheme, Typography, Box } from '@mui/material';
import ContentContainer from 'components/ContentContainer';
import React from 'react';
import { Paragraph } from 'components/Paragraph';
import FormikTextField from 'components/FormikTextField';
import { Field, Formik, FormikValues } from 'formik';
import { Button } from 'components/Button';
import FormikWizardInputs from 'components/FormikWizardInputs';
import contactUsBackground from 'assets/images/contactus/aahdr_contactus.webp';
import CustomerSupport from 'components/icons/CustomerSupport';
import CallUs from 'components/icons/CallUs';
import SalesSupport from 'components/icons/SalesSupport';
import Footer from 'components/Footer';
import { contactUsSchema } from './wizard/wizard-schema';
import Navigator from 'components/Navigator';
import StoreNavigator from '../components/airandheatdoneright/StoreNavigator';
import { useDispatch, useSelector } from 'react-redux';
import { selectLoginState } from 'store/selectors/loginSelector';
import { validate as validatePhone } from '../validations/phoneNumber';
import PhoneField from 'components/FormikPhoneField';
import { IS_ALIVE_COOKIE } from 'components/common/constants';
import Cookies from 'js-cookie';
import { sendCustomerContactRequest } from 'store/services/customerContactServices';
import { signUpSuccess } from 'store/actions/loginActions';
import { setIsAliveCookie } from 'index';
import LoadingOverlay from 'components/common/LoadingOverlay';
import { CommonLink } from 'components/Link';
import { AppRoute } from 'models/route';
import { useNavigate } from 'react-router-dom';
import { isStoreNone } from 'config';
import { showSnackbarError, showSnackbarSuccess } from 'components/common/Snackbar/SnackbarHelper';
import { getSnackbarMsg } from 'components/common/CustomSnackMessageUI';
import { SXCssObject } from 'models/ThemeImages';
import {
    invokeGAContactLoggedIn,
    invokeGAContactNotLoggedIn,
    invokeGAContactPageSignUp,
} from 'components/Analytics/GoogleAnalyticsTagging';
import { selectMultipleOpenProjects, selectSalesEmail, selectSalesPhone } from 'store/selectors/storeSelector';

const createCssObject = (theme: Theme): SXCssObject => ({
    root: {
        width: `${theme.spacing(10)}%`,
        height: '80px',
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
        zIndex: 5,
    },
    rootContainer: {
        paddingBottom: {
            xs: '40px',
            md: '80px'
        },
    },
    imageOverlapContent: {
        position: 'absolute',
        width: '100%',
        top: '0%',
    },
    marquee: {
        width: '100%',
        height: '100%',
        marginTop: {
            xs: '70px',
            sm: 'inherit',
        },
    },
    dialog: {
        height: '100%',
    },
    dialogContainer: {
        minHeight: '100vh',
        paddingLeft: theme.spacing(4),
        paddingRight: theme.spacing(4),
    },
    heading: {
        background: `${theme.palette.primary.main} 0% 0% no-repeat padding-box`,
        borderRadius: theme.spacing(0.8),
        padding: {
            sm: theme.spacing(0.8),
            lg: theme.spacing(0.5),
        },
        margin: {
            sm: theme.spacing(0.5),
            lg: theme.spacing(0.2),
        },
    },
    headingContainer: {
        paddingTop: {
            xs: '10%',
            sm: '6%',
            lg: '10%',
        },
        paddingLeft: `11%`,
    },
    logo: {
        cursor: 'pointer',
    },
    contactUsIconsContainer: {
        justifyContent: 'center',
        padding: {
            xs: '30px 10px',
            sm: '80px 0px',
        },
        flexDirection: {
            xs: 'column',
            md: 'inherit',
        },
        alignContent: {
            sm: 'center',
            md: 'inherit',
        },
    },
    contactUsIconsWrapper: {
        padding: {
            xs: '10px 0px',
            md: '10px 20px',
            lg: '10px 100px 10px 12px',
        },
    },
    iconLabel: {
        fontSize: '1.125rem',
        lineHeight: '21px',
        color: theme.palette.text.primary,
        margin: '15px 0px',
    },
    icon: {
        '& svg': {
            backgroundColor: theme.palette.common.white,
            boxShadow: '0px 17px 18px #BFD5AF24',
            borderRadius: '10px',
        },
        display: 'flex',
        alignItems: 'center',
    },
    contactUsIcon: {
        display: 'flex',
        justifyContent: 'center',
    },
    supportTextLayout: {
        textAlign: {
            xs: 'center',
            sm: 'left',
            md: 'center',
        },
        marginLeft: {
            sm: '40px',
            md: 'inherit',
        },
    },
    getInTouchContainer: {
        backgroundColor: theme.palette.common.white,
        boxShadow: ' 0px 23px 33px #AAB5A717',
        padding: {
            xs: '10px',
            sm: '55px 48px 0px',
            md: '55px 60px',
            lg: '55px 120px',
        },
        borderRadius: {
            xs: '0px',
            md: '18px',
        },
        borderBottom: {
            xs: '1px solid #CBCBCB',
            md: 'inherit',
        },
        maxWidth: {
            md: '910px',
        },
        justifyContent: 'center',
        textAlign: 'center',
    },
    getInTouchText: {
        paddingBottom: '15px',
    },
    headerContainer: {
        padding: {
            xs: '30px 35px !important',
            sm: '15px 10px',
            md: '15px 60px',
            lg: '15px 100px',
        },
    },
    formikTextField: {
        backgroundColor: theme.palette.common.white,
        border: `1px solid ${theme.customProperties.border}`,
    },
    formContainer: {
        padding: {
            xs: '10px',
            sm: '15px 10px',
        },
    },
    textArea: {
        width: '100%',
        backgroundColor: theme.palette.common.white,
        border: `1px solid ${theme.customProperties.border}`,
        height: '60px',
        borderRadius: '8px',
        padding: '10px',
        '&::placeholder': {
            textAlign: 'left',
            fontSize: '0.875rem',
            fontWeight: '500',
            letterSpacing: '0px',
            opacity: '0.8',
        },
        '&:focus': {
            outline: `1px solid ${theme.palette.action.hover}`,
        },
    },
    link: {
        '&.MuiLink-root': {
            fontWeight: '500',
        },
    },

    checkbox: {
        color: '#ccc',
        '&.MuiFormControlLabel-root': {
            marginRight: '0px !important',
        },
        '&.Mui-checked': {
            color: theme.palette.action.hover,
        },
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        marginLeft: '-6px',
    },
    hr: {
        borderTop: '1px solid #D4D4D4',
        borderBottom: '0px',
        borderLeft: '0px',
    },

    form: {
        paddingBottom: '10px',
    },
    rootContentContainer: {
        paddingBottom: {
            sm: '80px',
        },
    },
});

function ContactUs() {
    const theme = useTheme();
    const cssObject: SXCssObject = createCssObject(useTheme());
    const downXs = useMediaQuery(theme.breakpoints.only('xs'));
    const downSm = useMediaQuery(theme.breakpoints.down('md'));
    const onlySm = useMediaQuery(theme.breakpoints.only('sm'));
    const [declarationState, setDeclarationState] = React.useState(false);
    const [loader, setLoader] = React.useState<boolean>(false);
    const [showPhoneValidMessage, setShowPhoneValidMessage] = React.useState(false);
    const [validatingPhone, setValidatingPhone] = React.useState(false);
    const [checkedPhones, setCheckedPhones] = React.useState(new Array<{ phone: string; valid: boolean }>());
    const dispatch = useDispatch();
    const handleCheckboxStateChange = () => {
        setDeclarationState(!declarationState);
    };
    let loginData = useSelector(selectLoginState);
    const url = window.location;
    const navigate = useNavigate();
    const multipleOpenProjects = useSelector(selectMultipleOpenProjects);
    const isFromLandingPage = isStoreNone();
    const salesEmail = useSelector(selectSalesEmail);
    const salesPhone = useSelector(selectSalesPhone);
    const salesPhoneNumber = salesPhone ? salesPhone : '8333717706';
    const customerSupportEmail = multipleOpenProjects
        ? 'customer.support@motili.com'
        : 'cs@airandheatdoneright.com';
    const customerSalesEmail = salesEmail || 'sales@airandheatdoneright.com';

    const Options = [
        {
            id: '1',
            value: 'MY AC/HEATING SYSTEM IS NOT WORKING I NEED ASSISTANCE ASAP',
            label: 'MY AC/HEATING SYSTEM IS NOT WORKING I NEED ASSISTANCE ASAP',
        },
        {
            id: '2',
            value: 'I HAVE QUESTIONS ABOUT HVAC SYSTEM PRICING',
            label: 'I HAVE QUESTIONS ABOUT HVAC SYSTEM PRICING',
        },
        {
            id: '3',
            value: 'I HAVE QUESTIONS ABOUT THE AIR AND HEAT DONE RIGHT PURCHASE AND INSTALL PROCESS',
            label: `I HAVE QUESTIONS ABOUT THE ${multipleOpenProjects ? 'HOMEVESTORS MOTILI' : 'AIR AND HEAT DONE RIGHT'
                } PURCHASE AND INSTALL PROCESS`,
        },
        {
            id: '4',
            value: 'OTHER',
            label: 'OTHER',
        },
    ];

    const validate = async (values: FormikValues) => {
        // validate phoneNumber
        const validatePhoneMsg = await validatePhone(values.phone?.replace(/_|\./gi, ''), {
            isMounted: true,
            setShowValidMessage: setShowPhoneValidMessage,
            setValidating: setValidatingPhone,
            setCheckedPhones: setCheckedPhones,
            checkedPhones: checkedPhones,
        });
        if (validatePhoneMsg) {
            return {
                phone: validatePhoneMsg,
            };
        }
    };

    const sendRequest = async (values: FormikValues, resetForm: () => void, validateForm: () => Promise<any>) => {
        const isCookieAlive = Cookies.get(IS_ALIVE_COOKIE);
        const requestBody = {
            firstname: values.firstname,
            lastname: values.lastname,
            email: values.email,
            phone: values.phone.replace(/_|\./gi, ''),
            reasonForContact: values.reasonForContact,
            contactMessage: values.contactMessage,
        };
        setLoader(true);
        const response = await sendCustomerContactRequest({ ...requestBody }, isFromLandingPage ? url.origin : '');
        if (response.success && response.data) {
            //if user already logged in/signedup
            if (isCookieAlive) {
                //dispatch(loginSuccess({ loginResponse: { ...response.data } }));
                invokeGAContactLoggedIn();
            }
            //user not logged in
            else {
                //new user-send details and signup
                if (response.data.isVerified === false) {
                    invokeGAContactPageSignUp();
                    !isCookieAlive && setIsAliveCookie();
                    dispatch(signUpSuccess({ ...response.data }));
                }
                //existing user-just send details to API
                else {
                    invokeGAContactNotLoggedIn();
                }
            }
            navigate(AppRoute.Home);
            showSnackbarSuccess(getSnackbarMsg('Thanks! We’ll be in touch soon.', ''));
        } else showSnackbarError(getSnackbarMsg('Sorry! Contact has not been stored', ''));
        setLoader(false);
        resetForm();
        validateForm();
        handleCheckboxStateChange();
    };

    const removeStr = (str = "") => {
        let result = str.split(',')
        return result[0];
    }

    return (
        <div>
            <Box>
                <LoadingOverlay open={loader} />

                <Box sx={cssObject.imageOverlapContent}>
                    <ContentContainer sx={cssObject.rootContentContainer}>
                        {isFromLandingPage ? <StoreNavigator color={'primary'} /> : <Navigator color={'secondary'} />}
                        <ContentContainer sx={cssObject.headingContainer} disableGrid>
                            <Grid container>
                                <Box sx={cssObject.heading}>
                                    <Typography variant={downXs ? 'h3' : onlySm ? 'h2' : 'h1'} color={'textSecondary'}>
                                        CONTACT
                                    </Typography>
                                </Box>
                            </Grid>
                            <Grid container>
                                <Box sx={cssObject.heading}>
                                    <Typography variant={downXs ? 'h3' : onlySm ? 'h2' : 'h1'} color={'textSecondary'}>
                                        US
                                    </Typography>
                                </Box>
                            </Grid>
                        </ContentContainer>
                    </ContentContainer>
                </Box>
                <Box component={'img'} alt={'background'} src={contactUsBackground} sx={cssObject.marquee} />
            </Box>
            <ContentContainer sx={cssObject.rootContainer}>
                <Grid container sx={cssObject.contactUsIconsContainer}>
                    <Grid item sx={cssObject.contactUsIconsWrapper}>
                        <Grid direction={onlySm ? 'row' : 'column'} container>
                            <Grid sx={cssObject.contactUsIcon}>
                                <Box sx={cssObject.icon}>
                                    <CustomerSupport />
                                </Box>
                            </Grid>
                            <Grid sx={cssObject.supportTextLayout}>
                                <Box sx={cssObject.iconLabel}>Customer Support Address</Box>
                                <Box sx={cssObject.iconLabel}>
                                    <CommonLink
                                        fontColor={theme.customProperties.svgPrimaryColor}
                                        label={customerSupportEmail}
                                        href={`mailto:${customerSupportEmail}`}
                                    />
                                </Box>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item sx={cssObject.contactUsIconsWrapper}>
                        <Grid direction={onlySm ? 'row' : 'column'} container>
                            <Grid sx={cssObject.contactUsIcon}>
                                <Box sx={cssObject.icon}>
                                    <SalesSupport />
                                </Box>
                            </Grid>
                            <Grid sx={cssObject.supportTextLayout}>
                                <Box sx={cssObject.iconLabel}>Sales Support</Box>
                                <Box sx={cssObject.iconLabel}>
                                    <CommonLink
                                        fontColor={theme.customProperties.svgPrimaryColor}
                                        href={`mailto:${customerSalesEmail}`}
                                        label={customerSalesEmail}
                                    />
                                </Box>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item sx={cssObject.contactUsIconsWrapper}>
                        <Grid direction={onlySm ? 'row' : 'column'} container>
                            <Grid sx={cssObject.contactUsIcon}>
                                <Box sx={cssObject.icon}>
                                    <CallUs />
                                </Box>
                            </Grid>
                            <Grid sx={cssObject.supportTextLayout}>
                                <Box sx={cssObject.iconLabel}>Customer Support</Box>
                                <Box sx={cssObject.iconLabel}>
                                    <CommonLink
                                        id={'contact-page-tel'}
                                        fontColor={theme.customProperties.svgPrimaryColor}
                                        href={`tel:${removeStr(salesPhoneNumber)}`}
                                        label={salesPhoneNumber}
                                    />
                                </Box>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container justifyContent={'center'}>
                    <Grid sx={cssObject.getInTouchContainer}>
                        <Grid item sx={cssObject.headerContainer}>
                            <Typography variant={downXs ? 'h4' : 'h2'} sx={cssObject.getInTouchText}>
                                Get in touch with us
                            </Typography>
                            <Paragraph size="small">
                                Fill out the boxes below and one of our service specialists will get back to you within
                                one business day!
                            </Paragraph>
                        </Grid>
                        <Grid item sx={cssObject.formContainer}>
                            <Formik
                                enableReinitialize={true}
                                initialValues={{
                                    firstname: loginData.firstname ? loginData.firstname : '',
                                    lastname: loginData.lastname ? loginData.lastname : '',
                                    phone: loginData.phone ? loginData.phone : '',
                                    email: loginData.email ? loginData.email : '',
                                    reasonForContact: '',
                                    contactMessage: '',
                                }}
                                onSubmit={(values: FormikValues, { resetForm, validateForm }) => {
                                    sendRequest(values, resetForm, validateForm);
                                }}
                                validateOnMount={true}
                                validationSchema={contactUsSchema}
                                validate={validate}
                                initialTouched={{
                                    phone: true,
                                }}
                            >
                                {({ submitForm, isValid, values }) => (
                                    <ContentContainer sx={cssObject.form} disableGrid background={'bg3'}>
                                        <Container maxWidth={'lg'} disableGutters>
                                            <Grid container spacing={3}>
                                                <Grid item xs={12}>
                                                    <Grid container spacing={2} alignItems="flex-start">
                                                        <Grid item xs={12} sm={6}>
                                                            <FormikTextField
                                                                id={'firstname'}
                                                                name="firstname"
                                                                placeholder={'First Name'}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={12} sm={6}>
                                                            <FormikTextField
                                                                id={'lastname'}
                                                                name="lastname"
                                                                placeholder={'Last Name'}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={12} sm={6}>
                                                            <PhoneField
                                                                id={'phone'}
                                                                name={'phone'}
                                                                placeholder={'000.000.0000'}
                                                                fullWidth
                                                                enableErrorDisplay={showPhoneValidMessage}
                                                                validating={validatingPhone}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={12} sm={6}>
                                                            <FormikTextField
                                                                id={'email'}
                                                                name="email"
                                                                placeholder={'Email'}
                                                            />
                                                        </Grid>
                                                        <Grid
                                                            style={{
                                                                padding: '30px 10px 0px 20px',
                                                            }}
                                                        >
                                                            <Paragraph semibold size="small" fontSizeVerySmall>
                                                                REASON FOR CONTACT
                                                            </Paragraph>
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            <FormikWizardInputs
                                                                isForContactUsPage={true}
                                                                id="id-contact-page-reason"
                                                                name="reasonForContact"
                                                                options={Options}
                                                            />
                                                        </Grid>
                                                        {/* {values.reasonForContact === 'OTHER' && ( */}
                                                        <Grid item xs={12}>
                                                            <Field
                                                                style={{
                                                                    width: '100%',
                                                                    backgroundColor: `${theme.palette.common.white}`,
                                                                    border: `1px solid ${theme.customProperties.border}`,
                                                                    height: '60px',
                                                                    borderRadius: '8px',
                                                                    padding: '10px',
                                                                    '&::placeholder': {
                                                                        textAlign: 'left',
                                                                        fontSize: '0.875rem',
                                                                        fontWeight: '500',
                                                                        letterSpacing: '0px',
                                                                        opacity: '0.8',
                                                                    },
                                                                    '&:focus': {
                                                                        outline: `1px solid ${theme.palette.action.hover}`,
                                                                    },
                                                                }}
                                                                as="textarea"
                                                                name="contactMessage"
                                                                placeholder={'Message'}
                                                            />
                                                        </Grid>
                                                        {/* )} */}
                                                    </Grid>
                                                    <Grid
                                                        container
                                                        alignItems="center"
                                                        justifyContent="space-between"
                                                        spacing={1}
                                                    >
                                                        <Grid item xs={12} style={{ paddingTop: '10px' }}>
                                                            <Paragraph align="justify" size="small" fontSizeVerySmall>
                                                                {`Our sales team will contact you, usually within one
                                                                business day. By clicking submit, you agree to receive
                                                                email communication from ${window.location.host} and
                                                                agree to our Terms & Conditions and our Privacy Policy.
                                                                Upon submission, an account may be created for you on
                                                                this website.`}
                                                            </Paragraph>
                                                        </Grid>
                                                        <Grid item xs={12} sm={5}>
                                                            <Grid
                                                                container
                                                                wrap="nowrap"
                                                                spacing={1}
                                                                style={{
                                                                    alignItems: 'center',
                                                                    color: `${theme.palette.action.active} !important`,
                                                                    justifyContent: 'flex-start',
                                                                }}
                                                            >
                                                                <Grid item>
                                                                    <Box component={'span'} sx={cssObject.checkbox}>
                                                                        <Checkbox
                                                                            id={'declaration-checkbox'}
                                                                            name={'conditions'}
                                                                            sx={cssObject.checkbox}
                                                                            checked={declarationState}
                                                                            onChange={handleCheckboxStateChange}
                                                                        />
                                                                    </Box>
                                                                </Grid>
                                                                <Grid item>
                                                                    <Paragraph
                                                                        align="left"
                                                                        size="small"
                                                                        fontSizeVerySmall
                                                                    >
                                                                        I agree
                                                                    </Paragraph>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                    <br />
                                                    {!downSm && <Box component={'hr'} sx={cssObject.hr} />}
                                                    <br />
                                                    <Grid container justifyContent="flex-end">
                                                        <Grid item xs={12} md={6} sx={cssObject.submitBtnStyle}>
                                                            <Button
                                                                isFullWidth={true}
                                                                color="primary"
                                                                disabled={!isValid || !declarationState}
                                                                onClick={() => {
                                                                    submitForm();
                                                                }}
                                                            >
                                                                SUBMIT DETAILS
                                                            </Button>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Container>
                                    </ContentContainer>
                                )}
                            </Formik>
                        </Grid>
                    </Grid>
                </Grid>
            </ContentContainer>
            <Footer />
        </div>
    );
}

export default ContactUs;
