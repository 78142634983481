import { useTheme } from '@mui/material/styles';
import { getThemePrimaryMain } from 'components/common/Utilities';
import * as React from 'react';

const GiftIcon = (props: any) => {
    const theme = useTheme();
    const themePrimaryMain = getThemePrimaryMain(theme);

    return (
        <svg
            style={{ borderRadius: '4px' }}
            data-name="Component 25 \u2013 1"
            xmlns="http://www.w3.org/2000/svg"
            width={49}
            height={49}
            {...props}
        >
            <path
                data-name="Path 270"
                d="M3 0h43a3 3 0 0 1 3 3v43a3 3 0 0 1-3 3H3a3 3 0 0 1-3-3V3a3 3 0 0 1 3-3Z"
                fill="#fff"
            />
            <g data-name="Component 24 \u2013 2">
                <g data-name="Group 3429">
                    <path
                        data-name="Path 268"
                        d="M25.735 35.445V24.656h8.14v10.036a.767.767 0 0 1-.781.752Zm-8.828 0a.767.767 0 0 1-.784-.753V24.656h8.143v10.789Zm8.828-12.126v-5.042h8.056A1.21 1.21 0 0 1 35 19.487v2.622a1.209 1.209 0 0 1-1.209 1.21Zm-9.527 0A1.208 1.208 0 0 1 15 22.109v-2.622a1.208 1.208 0 0 1 1.207-1.21h8.058v5.042Zm10-6.439a.515.515 0 0 1-.416-.745c.234-.452 1.441-2.734 2.307-3.215a2.081 2.081 0 0 1 1.027-.269 2.114 2.114 0 0 1 1.031 3.96 4.829 4.829 0 0 1-2.314.328c-.85 0-1.6-.058-1.636-.06Zm2.438-2.725a5 5 0 0 0-.983 1.361c.148.006.317.011.5.011a2.856 2.856 0 0 0 1.183-.132.71.71 0 0 0-.348-1.331.7.7 0 0 0-.351.092Zm-8.8 2.36a2.113 2.113 0 0 1 2.058-3.691c.863.482 2.071 2.766 2.3 3.218a.506.506 0 0 1-.413.741c-.032 0-.786.062-1.636.062a4.808 4.808 0 0 1-2.309-.328Zm.476-2.107a.651.651 0 0 0 .25.883 2.633 2.633 0 0 0 1.081.119c.16 0 .316 0 .452-.009a4.637 4.637 0 0 0-.9-1.244.631.631 0 0 0-.313-.082.651.651 0 0 0-.57.333Z"
                        fill={`${themePrimaryMain}`}
                        stroke="rgba(0,0,0,0)"
                        strokeMiterlimit={10}
                    />
                </g>
            </g>
        </svg>
    );
};

export default GiftIcon;
