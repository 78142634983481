import { getSnackbarMsg } from './../../components/common/CustomSnackMessageUI';
import { showSnackbarSuccess } from './../../components/common/Snackbar/SnackbarHelper';
import { showSnackbarError } from 'components/common/Snackbar/SnackbarHelper';
import { AppRoute } from 'models/route';
import { combineEpics, Epic } from 'redux-observable';
import { Observable, of } from 'rxjs';
import { catchError, exhaustMap, ignoreElements, map, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { Dependencies } from 'store';
import {
    addDefaultProfileBillingAddress,
    profileDetailsSuccess,
    getProfileAddresses,
    getProfileAddressesFailure,
    getProfileAddressesSuccess,
    performDefaultBillingAddressUpdate,
    sendContactUsRequest,
    performDefaultBillingAddressUpdateSuccess,
} from 'store/actions/addressActions';

import { RootState } from 'store/reducers';
import { selectLoginState } from 'store/selectors/loginSelector';
import { sendCustomerContactRequestFromProfile } from 'store/services/customerContactServices';
import {
    createDefaultProfileBillingAddress,
    getAddressApi,
    updateDefaultBillingAddress,
} from 'store/services/userProfileServices';
import { Action } from 'ts-action';
import { ofType } from 'ts-action-operators';
import { invokeGAUpdateProfile, invokeGAAddProfileDetails } from 'components/Analytics/GoogleAnalyticsTagging';
import { AxiosResponse } from 'axios';
import { UserAddressDetails } from 'models/userProfile';
import { Address } from 'models/cart';

export const profileAdd$ = (action$: Observable<Action>) =>
    action$.pipe(
        ofType(getProfileAddresses),
        switchMap(() =>
            getAddressApi().pipe(
                map((resp: AxiosResponse<UserAddressDetails>) =>
                    getProfileAddressesSuccess({
                        adresses: resp.data.addresses,
                    })
                ),
                catchError((error: Error) => {
                    showSnackbarError('Unable to load country data');
                    return of(getProfileAddressesFailure(error));
                })
            )
        )
    );

export const navigateToUserProfile$ = (
    action$: Observable<Action>,
    state$: Observable<RootState>,
    { history }: Dependencies
) =>
    action$.pipe(
        ofType(profileDetailsSuccess),
        tap(() => {
            history.push({
                pathname: AppRoute.MyProfile,
            });
            showSnackbarSuccess(getSnackbarMsg('Your Profile', ' has been updated successfully!'));
        }),
        ignoreElements()
    );

export const updateDefaultBillingAddress$: Epic = (action$: Observable<Action>) =>
    action$.pipe(
        ofType(performDefaultBillingAddressUpdate),
        switchMap((action) =>
            updateDefaultBillingAddress(action.payload.Address, action.payload.addressId).pipe(
                switchMap((resp: AxiosResponse<Address>) => {
                    invokeGAUpdateProfile();
                    return [
                        performDefaultBillingAddressUpdateSuccess(resp.data),
                        sendContactUsRequest(action.payload.contactDetails),
                    ];
                }),
                catchError((error: Error) => {
                    showSnackbarError('Unable to Add Default Billing Address');
                    return of(getProfileAddressesFailure(error));
                })
            )
        )
    );

export const addDefaultProfileBillingAddress$ = (action$: Observable<Action>) =>
    action$.pipe(
        ofType(addDefaultProfileBillingAddress),
        switchMap((action) =>
            createDefaultProfileBillingAddress(action.payload.Address).pipe(
                switchMap((resp: AxiosResponse<Address>) => {
                    invokeGAAddProfileDetails();
                    return [
                        performDefaultBillingAddressUpdateSuccess(resp.data),
                        sendContactUsRequest(action.payload.contactDetails),
                    ];
                }),
                catchError((error: Error) => {
                    showSnackbarError('Unable to Add Default Billing Address');
                    return of(getProfileAddressesFailure(error));
                })
            )
        )
    );

export const sendCustomerContactRequest$ = (action$: Observable<Action>, state$: Observable<RootState>) =>
    action$.pipe(
        ofType(sendContactUsRequest),
        withLatestFrom(state$.pipe(map(selectLoginState))),
        switchMap(([action]) =>
            sendCustomerContactRequestFromProfile(action.payload).pipe(
                exhaustMap((resp: AxiosResponse<any>) => {
                    return [profileDetailsSuccess(resp.data)];
                }),
                catchError((error: any) => {
                    showSnackbarError('Unable to load country data');
                    return of(getProfileAddressesFailure(error));
                })
            )
        )
    );

export default combineEpics(
    profileAdd$,
    navigateToUserProfile$,
    updateDefaultBillingAddress$,
    addDefaultProfileBillingAddress$,
    sendCustomerContactRequest$
);
