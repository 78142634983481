import { styled } from '@mui/material/styles';
import ToggleButton from '@mui/material/ToggleButton';

const WizardInput = styled(ToggleButton)(({ theme }) => ({
    '& .MuiToggleButton-root': {
        color: theme.palette.text.primary,
        backgroundColor: theme.palette.common.white,
        border: `1px solid ${theme.palette.divider}`,
        minWidth: theme.spacing(10),
        width: {
            xs: '100%',
            sm: 'inherit',
        },
        '&:hover': {
            borderColor: theme.palette.action.hover,
            backgroundColor: theme.palette.common.white,
        },
    },
    '& .Mui-selected': {
        color: theme.palette.common.white,
        backgroundColor: theme.palette.action.hover,
        '&:hover': {
            borderColor: theme.palette.action.hover,
            backgroundColor: theme.palette.action.hover,
        },
    },
}));

export default WizardInput;
