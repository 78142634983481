import React, { Fragment } from 'react';
import { Props as MenuProps } from 'components/SecondaryNavigation';
import { Theme, useTheme } from '@mui/material/styles';
import SubPage from 'components/subpage/SubPage';
import Grid from '@mui/material/Grid';
import CardMedia from '@mui/material/CardMedia';
import ProTipAndAdditionalLinks from 'components/subpage/ProTipAndAdditionalLinks';
import { Heading } from 'components/Heading';
import { Paragraph } from 'components/Paragraph';
import { getSubPageMenus } from './menu-util';
import ContentContainer from 'components/ContentContainer';
import PackageUnitImage from 'components/icons/PackageUnitImage';
import TooltipIdeaIcon from 'components/icons/TooltipIdeaIcon';
import { getThemePrimaryMain } from 'components/common/Utilities';
import { SXCssObject } from 'models/ThemeImages';

function PackagedUnits() {
    const createCssObject = (theme: Theme) => {
        return {
            contentHeading: {
                paddingBottom: theme.spacing(2),
            },
            contentImage: {
                marginTop: theme.spacing(2),
                paddingTop: theme.spacing(2),
                backgroundColor: theme.customProperties.knowledgeCenterImageBackground,
            },
            image: {
                width: {
                    md: '505px',
                },
                margin: 'auto',
            },
            contentInteractive: {
                paddingTop: theme.spacing(4),
                paddingBottom: theme.spacing(4),
            },
            contentSecondaryHeading: {
                paddingTop: {
                    xs: theme.spacing(2),
                    sm: theme.spacing(4),
                },
                paddingBottom: theme.spacing(2),
            },
            tooltipContainer: {
                padding: {
                    xs: theme.spacing(2),
                    sm: '35px',
                },
                border: `1px solid ${theme.palette.primary.contrastText}`,
                borderRadius: '4px',
                margin: '40px 0px',
            },
            listPoint: {
                backgroundColor: getThemePrimaryMain(theme),
                borderRadius: '50%',
                padding: theme.spacing(0, 0.9, 0, 0.9),
                fontSize: '12px',
                color: theme.palette.common.white,
                marginRight: '10px',
                marginTop: '4px',
            },
            listPointContainer: {
                padding: '5%',
            },
            tooltipIcon: {
                marginRight: '10px',
            },
            toolTipHeading: {
                paddingBottom: theme.spacing(2),
                alignItems: 'center',
            },
        };
    };

    const menu: MenuProps = {
        id: 'menu',
        label: 'More Knowledge Center Topic',
        color: 'primary',
        subMenus: getSubPageMenus('packaged_units'),
    };
    const cssObject: SXCssObject = createCssObject(useTheme());
    return (
        <SubPage
            menu={menu}
            content1={
                <Fragment>
                    <Grid item xs={12} sx={cssObject.contentHeading}>
                        <Heading variant={'h3'} isPopUp color={'primary'} label={'Packaged Units'} />
                    </Grid>
                    <Grid item xs={12}>
                        <Paragraph responsiveFontSize16 align={'left'}>
                            A packaged unit is an "all-in-one system" that can provide both cooling and heating from a
                            single piece of equipment and can be located on the ground or roof.{' '}
                        </Paragraph>
                    </Grid>
                    <Grid item xs={12} sx={cssObject.contentImage}>
                        <CardMedia style={{ overflowY: 'auto' }}>
                            <PackageUnitImage />
                        </CardMedia>
                        <Grid>
                            <Grid sx={cssObject.listPointContainer} container spacing={2}>
                                <Grid item container alignItems={'flex-start'}>
                                    <Grid item sx={cssObject.listPoint}>
                                        1
                                    </Grid>
                                    <Grid item xs={10} sm={11}>
                                        <Paragraph fontSizeMediumSmall>
                                            Packaged units have all components needed to heat and cool your built into
                                            singular piece of equipment
                                        </Paragraph>
                                    </Grid>
                                </Grid>
                                <Grid item container alignItems={'flex-start'}>
                                    <Grid item sx={cssObject.listPoint}>
                                        2
                                    </Grid>
                                    <Grid item xs={10} sm={11}>
                                        <Paragraph fontSizeMediumSmall>
                                            In gas heating units, the exhaust requires no additional piping, and vents
                                            directly outdoors.
                                        </Paragraph>
                                    </Grid>
                                </Grid>
                                <Grid item container alignItems={'flex-start'}>
                                    <Grid item sx={cssObject.listPoint}>
                                        3
                                    </Grid>
                                    <Grid item xs={10} sm={11}>
                                        <Paragraph fontSizeMediumSmall>
                                            Packaged units have duct work connected outside which leads into your home
                                            and circulates air with a blower motor.
                                        </Paragraph>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <ContentContainer sx={cssObject.tooltipContainer} background={'wizardtip'}>
                        <Grid container>
                            <Grid item xs={12}>
                                <Grid container sx={cssObject.toolTipHeading}>
                                    <TooltipIdeaIcon sx={cssObject.tooltipIcon} />
                                    <Heading variant={'h3'} isPopUp={true} color={'primary'} label={'Pro Tip'} />
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                <Paragraph>
                                    SEER ratings of new systems we install start at 14 SEER and go up from there. The
                                    higher the SEER rating, the less energy the unit uses, which means you save money on
                                    your electricity bill.
                                </Paragraph>
                            </Grid>
                        </Grid>
                    </ContentContainer>
                </Fragment>
            }
            content2={
                <ProTipAndAdditionalLinks
                    additionalLinks={{
                        headLine: 'Want to learn more? Check out these links',
                        links: [
                            {
                                text: 'Learn More About Packaged Units ',
                                url: 'https://www.goodmanmfg.com/resources/hvac-learning-center/hvac-101/all-in-one-comfort-how-an-hvac-packaged-system-works',
                            },
                            {
                                text: 'Goodman Customer Reviews',
                                url: 'https://www.goodmanmfg.com/resources/customer-reviews',
                            },
                        ],
                    }}
                />
            }
        />
    );
}

export default PackagedUnits;
