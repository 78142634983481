import LoginCart from 'components/icons/LoginCart';
import IconDeleteNew from 'components/icons/IconDeleteNew';
import RegenerateIcon from 'components/icons/RegenerateIcon';
import DownloadIcon from 'components/icons/DownloadIcon';

export enum QuotesGridHeadingMenu {
    QUOTE_ID = 'Quote ID',
    QUOTE_PRODUCTS = 'Quote Products',
    QUOTE_PROPERTY = 'Property',
    EXPIRY_DATE = 'Expires on',
    QUOTE_ORIGINAL_PRICE = 'Quoted Price',
    QUOTE_CURRENT_PRICE = ' Current Price',
}

export enum QuoteMenus {
    REGENERATE_QUOTE = 'Re-Generate Quote',
    MOVE_TO_CART = 'Move to Cart',
    DOWNLOAD_QUOTE = 'Download',
    DELETE_QUOTE = 'Delete',
}

export interface QuoteMenu {
    label: QuoteMenus;
    icon: any; //toBeChecked
}
export const ActiveQuotesMenus: QuoteMenu[] = [
    { label: QuoteMenus.MOVE_TO_CART, icon: LoginCart },
    { label: QuoteMenus.DOWNLOAD_QUOTE, icon: DownloadIcon },
    { label: QuoteMenus.DELETE_QUOTE, icon: IconDeleteNew },
];

export const ExpiredQuotesMenus: QuoteMenu[] = [
    { label: QuoteMenus.REGENERATE_QUOTE, icon: RegenerateIcon },
    { label: QuoteMenus.DELETE_QUOTE, icon: IconDeleteNew },
];
