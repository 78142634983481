import React, { Fragment } from 'react';
import { Props as MenuProps } from 'components/SecondaryNavigation';
import { Theme, useTheme } from '@mui/material/styles';
import SubPage from 'components/subpage/SubPage';
import Grid from '@mui/material/Grid';
import CardMedia from '@mui/material/CardMedia';
import { Heading } from 'components/Heading';
import { Paragraph } from 'components/Paragraph';
import { getSubPageMenus } from './menu-util';
import VideoOverlay from '../../components/VideoOverlay';
import VideoOverlayBackground from 'assets/images/knowledges/kc_video_background_3.png';
import ContentContainer from 'components/ContentContainer';
import AirConditionersImage from 'components/icons/AirConditionersImage';
import PlayButton from 'components/icons/PlayButton';
import TooltipIdeaIcon from 'components/icons/TooltipIdeaIcon';
import { getThemePrimaryMain } from 'components/common/Utilities';
import { SXCssObject } from 'models/ThemeImages';

function AirConditioners() {
    const createCssObject = (theme: Theme): SXCssObject => {
        return {
            contentHeading: {
                paddingBottom: theme.spacing(2),
            },
            contentImage: {
                paddingTop: theme.spacing(2),
                backgroundColor: '#FAFAFA',
            },
            image: {
                margin: 'auto',
                width: {
                    sm: '505px',
                },
            },
            contentInteractive: {
                alignItems: 'center',
                justifyContent: 'center',
                backgroundImage: `url(${VideoOverlayBackground})`,
                marginBottom: theme.spacing(1),
                height: {
                    xs: theme.spacing(20),
                    sm: theme.spacing(34),
                },
                backgroundPosition: 'center',
                backgroundSize: 'contain',
                backgroundRepeat: 'no-repeat',
            },
            contentSecondaryHeading: {
                paddingTop: {
                    xs: theme.spacing(2),
                    sm: theme.spacing(4),
                },
                paddingBottom: theme.spacing(2),
            },
            playButton: {
                cursor: 'pointer',
                '&:hover': {
                    opacity: 0.9,
                },
                width: {
                    xs: '80px',
                    sm: 'inherit',
                },
                paddingTop: {
                    xs: theme.spacing(2),
                    sm: theme.spacing(3),
                },
            },
            listPoint: {
                backgroundColor: getThemePrimaryMain(theme),
                borderRadius: '50%',
                padding: theme.spacing(0, 0.9, 0, 0.9),
                fontSize: '12px',
                color: theme.palette.common.white,
                marginRight: '10px',
                marginTop: '4px',
            },
            listPointContainer: {
                padding: '5%',
            },

            tooltipContainer: {
                padding: {
                    xs: theme.spacing(2),
                    sm: '35px',
                },
                border: `1px solid ${theme.palette.primary.contrastText}`,
                borderRadius: '4px',
                margin: '40px 0px',
            },
            tooltipIcon: {
                marginRight: '10px',
            },
            toolTipHeading: {
                paddingBottom: theme.spacing(2),
                alignItems: 'center',
            },
        };
    };

    const menu: MenuProps = {
        id: 'menu',
        label: 'More Knowledge Center Topic',
        color: 'primary',
        subMenus: getSubPageMenus('air_conditioners'),
    };
    const cssObject: SXCssObject = createCssObject(useTheme());
    const [openVdo, setOpenVdo] = React.useState(false);
    const handleCloseVdo = () => {
        setOpenVdo(false);
    };
    return (
        <SubPage
            menu={menu}
            content1={
                <Fragment>
                    <Grid item xs={12} sx={cssObject.contentHeading}>
                        <Heading variant={'h3'} isPopUp={true} color={'primary'} label={'Air Conditioners'} />
                    </Grid>
                    <Grid item xs={12}>
                        <Paragraph responsiveFontSize16 align={'left'}>
                            The best air conditioner is the one you don’t have to think about. It comes on the moment
                            the indoor temperature set on the thermostat requires cooling, and then runs quietly and
                            efficiently as needed. But when it’s time to upgrade or replace your system, it’s helpful to
                            understand how an air conditioning system works. Did you know that your air conditioning
                            system typically consists of two pieces of equipment?
                        </Paragraph>
                    </Grid>
                    <Grid sx={cssObject.contentSecondaryHeading}>
                        <Paragraph responsiveFontSize20 bold headingStyle>
                            Watch this video to learn how an air conditioning system keeps you comfortable
                        </Paragraph>
                    </Grid>
                    <Grid item xs={12} container sx={cssObject.contentInteractive}>
                        <div>
                            <PlayButton
                                onClick={() => {
                                    setOpenVdo(true);
                                }}
                            />
                        </div>
                    </Grid>
                    <Grid item xs={12} sx={cssObject.contentImage}>
                        <CardMedia style={{ overflowY: 'auto' }}>
                            <AirConditionersImage />
                        </CardMedia>
                        <Grid>
                            <Grid sx={cssObject.listPointContainer} container spacing={2}>
                                <Grid item container alignItems={'flex-start'}>
                                    <Grid item sx={cssObject.listPoint}>
                                        1
                                    </Grid>
                                    <Grid item xs={10} sm={11}>
                                        <Paragraph fontSizeMediumSmall>
                                            The coil and fan inside the air conditioner work together to transfer the
                                            heat from the refrigerant outdoors.
                                        </Paragraph>
                                    </Grid>
                                </Grid>
                                <Grid item container alignItems={'flex-start'}>
                                    <Grid item sx={cssObject.listPoint}>
                                        2
                                    </Grid>
                                    <Grid item xs={10} sm={11}>
                                        <Paragraph fontSizeMediumSmall>
                                            The compressor acts as a pump that circulates the refrigerant throughout the
                                            entire HVAC system.
                                        </Paragraph>
                                    </Grid>
                                </Grid>
                                <Grid item container alignItems={'flex-start'}>
                                    <Grid item sx={cssObject.listPoint}>
                                        3
                                    </Grid>
                                    <Grid item xs={10} sm={11}>
                                        <Paragraph fontSizeMediumSmall>
                                            Two copper pipes carry refrigerant between your outdoor air conditioner and
                                            the indoor coil. You will typically see the larger of the two copper lines
                                            insulated with black foam.
                                        </Paragraph>
                                    </Grid>
                                </Grid>
                                <Grid item container alignItems={'flex-start'}>
                                    <Grid item sx={cssObject.listPoint}>
                                        4
                                    </Grid>
                                    <Grid item xs={10} sm={11}>
                                        <Paragraph fontSizeMediumSmall>
                                            Blowers can be single-stage, two-stage, or variable speed to maximize
                                            comfort and energy savings. The more “speed settings” or stages your furnace
                                            or air handler has typically means a higher overall comfort level for your
                                            family and greater energy savings.
                                        </Paragraph>
                                    </Grid>
                                </Grid>
                                <Grid item container alignItems={'flex-start'}>
                                    <Grid item sx={cssObject.listPoint}>
                                        5
                                    </Grid>
                                    <Grid item xs={10} sm={11}>
                                        <Paragraph fontSizeMediumSmall>
                                            The evaporator coil is an piece of equipment that adsorbs heat using
                                            refrigerant and then Cools your home. They can be individual pieces or
                                            internal in an air handler.
                                        </Paragraph>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <ContentContainer sx={cssObject.tooltipContainer} background={'wizardtip'}>
                        <Grid container>
                            <Grid item xs={12}>
                                <Grid container sx={cssObject.toolTipHeading}>
                                    <TooltipIdeaIcon sx={cssObject.tooltipIcon} />
                                    <Heading variant={'h3'} isPopUp={true} color={'primary'} label={'Pro Tip'} />
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                <Paragraph>
                                    SEER ratings of new systems we install start at 14 SEER and go up from there. The
                                    higher the SEER rating, the less energy the unit uses, which means you save money on
                                    your electricity bill.
                                </Paragraph>
                            </Grid>
                        </Grid>
                    </ContentContainer>
                    <VideoOverlay
                        open={openVdo}
                        onClose={handleCloseVdo}
                        heading={'Air Conditioners'}
                        src={
                            'https://res.cloudinary.com/motili/video/upload/v1594975953/ecomm/video/Goodman_HowItWorks_AC_Clean_1080p.mp4'
                        }
                    />
                </Fragment>
            }
        />
    );
}

export default AirConditioners;
