import { Observable, of } from 'rxjs';
import { Action } from 'ts-action';
import { ofType } from 'ts-action-operators';
import {
    changeProductIAQ,
    changeProductIAQError,
    changeProductIAQSuccess,
    getProductBySkuAction,
    getProductBySkuError,
    getProductBySkuSuccess,
    searchProduct,
    searchProductError,
    searchProductIAQ,
    searchProductIAQError,
    searchProductIAQSuccess,
    searchProductSuccess,
    searchSecondProduct,
    searchSecondProductError,
    searchSecondProductSuccess,
    showProduct,
    showProductSuccess,
} from '../actions/productSearchActions';
import { catchError, ignoreElements, map, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { getProductBySkuService, searchProduct as searchProductService } from '../services/productSevices';
import { RootState } from '../reducers';
import { selectProductCriteriaState } from '../selectors/productCriteria';
import { Dependencies } from '../index';
import { AppRoute } from '../../models/route';
import {
    DuctlessNumberOfRooms,
    FurnaceEfficiency,
    NumberOfHVAC,
    PackageReplacementType,
    TypeOfService,
} from '../../models/productCriteria';
import { selectProductAttributeEfficiency } from '../selectors/productAttributes';
import { selectProducts, selectProductSearchState } from '../selectors/productSearch';
import { navigateThankyouPage } from '../actions/navigateActions';
import { combineEpics, Epic } from 'redux-observable';
import { orderBy } from 'lodash';
import { selectLoginState } from 'store/selectors/loginSelector';
import { createContact, createContactSuccess } from 'store/actions/hubspotActions';
import { SystemGroup } from 'models/cart';
import { showSnackbarError } from 'components/common/Snackbar/SnackbarHelper';
import { AxiosResponse } from 'axios';
import { Product } from 'models/product';
import { selectFirstSystemCriteria } from 'store/selectors/selection';

const order = ['Platinum', 'Gold', 'Silver', 'Bronze'];

export const productSearch$ = (action$: Observable<Action>, state$: Observable<RootState>) =>
    action$.pipe(
        ofType(searchProduct),
        withLatestFrom(
            state$.pipe(map(selectProductCriteriaState)),
            state$.pipe(map(selectProductAttributeEfficiency))
        ),
        map(([action, criteria, efficiencyAttr]) => {
            const { efficiency, ...other } = criteria;
            let alterEfficiency = efficiency;
            if (efficiency && efficiencyAttr?.options.length) {
                if (efficiency === FurnaceEfficiency.Percent80) {
                    alterEfficiency = efficiencyAttr.options
                        .filter((o) => +o.label <= 80)
                        .map((o) => o.label)
                        .join();
                }
                if (efficiency === FurnaceEfficiency.Percent90) {
                    alterEfficiency = efficiencyAttr.options
                        .filter((o) => +o.label >= 90)
                        .map((o) => o.label)
                        .join();
                }
            }
            return {
                shouldRedirect: action.payload.shouldRedirect,
                addSecondSystemFromCart: action.payload.addSecondSystemFromCart,
                criteriaMain: {
                    ...other,
                    efficiency: alterEfficiency,
                },
            };
        }),
        switchMap((criteria) =>
            searchProductService(criteria.criteriaMain).pipe(
                map((resp: AxiosResponse<Product[]>) => resp.data || []),
                map((products) =>
                    searchProductSuccess({
                        products: orderBy(
                            products
                                .map((p) => ({ ...p, id: p.id ? p.id : +p.extId }))
                                .filter((product) => {
                                    if(criteria.criteriaMain.typeOfService === TypeOfService.EquipmentOnly) {
                                        return product.options && product.options?.optionId && product.options?.optionTypeId
                                    } else {
                                        return true;
                                    }
                                }) 
                                .sort((a, b) => {
                                    return (
                                        (order.findIndex((o) => o === a.productAttributes.level) -
                                            order.findIndex((o) => o === b.productAttributes.level)) *
                                        -1
                                    );
                                }),
                            ['price'],
                            ['asc']
                        ),
                        shouldRedirect: criteria.shouldRedirect,
                        addSecondSystemFromCart: criteria.addSecondSystemFromCart,
                    })
                ),
                catchError((error) => {
                    showSnackbarError('Unable to load product base on you criteria');
                    return of(searchProductError(error));
                })
            )
        )
    );
export const productSearchDouble$ = (action$: Observable<Action>, state$: Observable<RootState>) =>
    action$.pipe(
        ofType(searchSecondProduct),
        withLatestFrom(
            state$.pipe(map(selectProductCriteriaState)),
            state$.pipe(map(selectProductAttributeEfficiency)),
            state$.pipe(map(selectFirstSystemCriteria))
        ),
        map(([action, criteria, efficiencyAttr, firstCriteria]) => {
            const { efficiency, ...other } = criteria;
            const {typeOfService} = firstCriteria;
            let alterEfficiency = efficiency;
            if (efficiency && efficiencyAttr?.options.length) {
                if (efficiency === FurnaceEfficiency.Percent80) {
                    alterEfficiency = efficiencyAttr.options
                        .filter((o) => +o.label <= 80)
                        .map((o) => o.label)
                        .join();
                }
                if (efficiency === FurnaceEfficiency.Percent90) {
                    alterEfficiency = efficiencyAttr.options
                        .filter((o) => +o.label >= 90)
                        .map((o) => o.label)
                        .join();
                }
            }
            return {
                shouldRedirect: action.payload.shouldRedirect,
                criteriaMain: {
                    ...other,
                    typeOfService:typeOfService,
                    efficiency: alterEfficiency,
                },
            };
        }),
        switchMap((criteria) =>
            searchProductService(criteria.criteriaMain).pipe(
                map((resp: AxiosResponse<Product[]>) => resp.data || []),
                map((products) =>
                    searchSecondProductSuccess({
                        productsSystemTwo: orderBy(
                            products
                                .map((p) => ({ ...p, id: p.id ? p.id : +p.extId }))
                                .filter((product) => {
                                    if(criteria.criteriaMain.typeOfService === TypeOfService.EquipmentOnly) {
                                        return product.options && product.options?.optionId && product.options?.optionTypeId
                                    } else {
                                        return true;
                                    }
                                }) 
                                .sort((a, b) => {
                                    return (
                                        (order.findIndex((o) => o === a.productAttributes.level) -
                                            order.findIndex((o) => o === b.productAttributes.level)) *
                                        -1
                                    );
                                }),
                            ['price'],
                            ['asc']
                        ),
                        shouldRedirect: criteria.shouldRedirect,
                    })
                ),
                catchError((error) => {
                    showSnackbarError('Unable to load product base on you criteria');
                    return of(searchSecondProductError(error));
                })
            )
        )
    );

export const getProductBySku$ = (action$: Observable<Action>, state$: Observable<RootState>, { alert }: Dependencies) =>
    action$.pipe(
        ofType(getProductBySkuAction),
        withLatestFrom(state$.pipe(map(selectProducts))),
        switchMap(([action, productList]) =>
            getProductBySkuService(action.payload.sku).pipe(
                map((resp: AxiosResponse<Product>) => {
                    let updatedProducts;
                    if (productList && productList.length > 0) {
                        updatedProducts = [...productList, resp.data];
                    } else {
                        updatedProducts = [resp.data];
                    }
                    return getProductBySkuSuccess(updatedProducts);
                }),
                catchError((err) => {
                    return of(getProductBySkuError(err));
                })
            )
        )
    );

export const productIAQSearch$: Epic = (action$: Observable<Action>) =>
    action$.pipe(
        ofType(searchProductIAQ),
        switchMap((action) =>
            searchProductService(action.payload).pipe(
                map((resp: AxiosResponse<Product[]>) => resp.data || []),
                map((productsIAQ) =>
                    searchProductIAQSuccess({
                        productsIAQ: orderBy(
                            productsIAQ
                                .map((p) => ({ ...p, id: p.id ? p.id : +p.extId }))
                                .sort((a, b) => {
                                    return (
                                        (order.findIndex((o) => o === a.productAttributes.level) -
                                            order.findIndex((o) => o === b.productAttributes.level)) *
                                        -1
                                    );
                                }),
                            ['price'],
                            ['asc']
                        ),
                    })
                ),
                catchError((error) => {
                    showSnackbarError('Unable to load product base on you criteria');
                    return of(searchProductIAQError(error));
                })
            )
        )
    );
export const changeIAQSearch$ = (action$: Observable<Action>) =>
    action$.pipe(
        ofType(changeProductIAQ),
        switchMap((action) =>
            searchProductService(action.payload.productCriteria).pipe(
                map((resp: AxiosResponse<Product[]>) => resp.data || []),
                map((productsIAQ) =>
                    changeProductIAQSuccess({
                        productsIAQ: orderBy(
                            productsIAQ
                                .map((p) => ({ ...p, id: p.id ? p.id : +p.extId }))
                                .sort((a, b) => {
                                    return (
                                        (order.findIndex((o) => o === a.productAttributes.level) -
                                            order.findIndex((o) => o === b.productAttributes.level)) *
                                        -1
                                    );
                                }),
                            ['price'],
                            ['asc']
                        ),
                        redirectToIAQPage: action.payload.systemGroup,
                    })
                ),
                catchError((error) => {
                    showSnackbarError('Unable to load product base on you criteria');
                    return of(changeProductIAQError(error));
                })
            )
        )
    );

export const processContact$ = (action$: Observable<Action>, state$: Observable<RootState>) =>
    action$.pipe(
        ofType(showProduct),
        withLatestFrom(state$.pipe(map(selectProductCriteriaState)), state$.pipe(map(selectProductSearchState))),
        map(([action, criteria, productSearch]) => {
            if (
                criteria.numberOfHVAC === NumberOfHVAC.Multiple ||
                criteria.packageReplacementType === PackageReplacementType.ElectricalHeatAC ||
                criteria.ductlessNumberOfRooms === DuctlessNumberOfRooms.Multiple ||
                (criteria.doubleSystemFlag !== 'Second' &&
                    (!productSearch.products || productSearch.products.length < 1)) ||
                (criteria.doubleSystemFlag === 'Second' &&
                    (!productSearch.productsSystemTwo || productSearch.productsSystemTwo.length < 1))
            ) {
                return navigateThankyouPage({ isNoProduct: true });
            } else if (!action.payload.isSecondSystem) {
                return showProductSuccess({
                    isSecondSystem: false,
                    addSecondSystemFromCart: action.payload.addSecondSystemFromCart,
                });
            } else {
                return showProductSuccess({
                    isSecondSystem: true,
                    addSecondSystemFromCart: action.payload.addSecondSystemFromCart,
                });
            }
        })
    );

export const redirectToEquipmentOptions$ = (action$: Observable<Action>, state$: Observable<RootState>) =>
    action$.pipe(
        ofType(searchProductSuccess, searchSecondProductSuccess),
        withLatestFrom(state$.pipe(map(selectLoginState)), state$.pipe(map(selectProductCriteriaState))),
        switchMap(([action, loginData, criteria]) => {
            if (action.type === searchProductSuccess.type && action.payload.shouldRedirect) {
                const customerInfo = {
                    firstname: loginData.firstname,
                    lastname: loginData.lastname,
                    email: loginData.email,
                    phone: loginData.phone,
                    isUserDetailAlreadyAvailable: true,
                    remark:
                        criteria.numberOfHVAC === NumberOfHVAC.Multiple
                            ? 'multiUnit'
                            : action.payload.products && action.payload.products.length < 1
                            ? 'noProduct'
                            : undefined,
                };
                return [
                    showProduct({
                        isSecondSystem: false,
                        addSecondSystemFromCart: action.payload.addSecondSystemFromCart,
                    }),
                    createContact(customerInfo),
                ];
            } else if (action.type === searchSecondProductSuccess.type) {
                return [showProduct({ isSecondSystem: true }), createContactSuccess({ secondSystem: true })];
            } else {
                return [];
            }
        })
    );

export const navigateToEquipmentOptions$ = (
    action$: Observable<Action>,
    state$: Observable<RootState>,
    { history }: Dependencies
) =>
    action$.pipe(
        ofType(showProductSuccess),
        tap((action) =>
            action.payload.isSecondSystem
                ? history.push(AppRoute.EquipmentOptions, {
                      isSecondSystem: true,
                      isAddSystemFromCartPage: action.payload.addSecondSystemFromCart,
                  })
                : history.push(AppRoute.EquipmentOptions, {
                      isSecondSystem: false,
                      isAddSystemFromCartPage: action.payload.addSecondSystemFromCart,
                  })
        ),
        ignoreElements()
    );

export const navigateToIAQ$ = (action$: Observable<Action>, state$: Observable<RootState>, { history }: Dependencies) =>
    action$.pipe(
        ofType(searchProductIAQSuccess, changeProductIAQSuccess),
        tap((action) => {
            if (action.payload.redirectToIAQPage) {
                const sysGroup = action.payload.redirectToIAQPage;
                history.push(AppRoute.IAQOptions, {
                    systemGroup: sysGroup,
                    isSecondSystem: sysGroup === SystemGroup.SystemGroup2 ? true : false,
                    isByClickingChangeInCartPage: true,
                });
            }
        }),
        ignoreElements()
    );
export default combineEpics(
    productSearch$,
    productIAQSearch$,
    processContact$,
    redirectToEquipmentOptions$,
    navigateToEquipmentOptions$,
    navigateToIAQ$,
    getProductBySku$,
    productSearchDouble$,
    changeIAQSearch$
);
