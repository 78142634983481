import React, { Fragment } from 'react';
import { Theme, useTheme } from '@mui/material/styles';
import StaticSubPage from 'components/subpage/StaticSubPage';
import Grid from '@mui/material/Grid';
import { Heading } from 'components/Heading';
import { Paragraph } from 'components/Paragraph';
import { useMediaQuery } from '@mui/material';
import MissionIconSmall from 'components/icons/MissionIconSmall';
import MissionIcon from 'components/icons/MissionIcon';
import LocationIcon from 'components/icons/LocationIcon';
import { SXCssObject } from 'models/ThemeImages';
import { useSelector } from 'react-redux';
import { selectMultipleOpenProjects } from 'store/selectors/storeSelector';

function WhoWeAre() {
    const createCssObject = (theme: Theme): SXCssObject => {
        return {
            contentHeading: {
                paddingBottom: theme.spacing(1),
            },
            contentSecondaryHeading: {
                display: 'flex',
                alignItems: 'center',
            },
            content: {
                paddingTop: theme.spacing(2),
            },
            mission: {
                background: `${theme.palette.primary.light} 0% 0% no-repeat padding-box`,
                border: `1px solid ${theme.palette.primary.contrastText}`,
                borderRadius: '8px',
                marginTop: '50px',
                padding: {
                    xs: '20px',
                    sm: '44px 50px 42px 25px',
                },
                marginBottom: {
                    xs: '50px',
                    sm: '65px',
                },
            },
            location: {
                width: '360px',
                height: '88px',
                boxShadow: '-10px 5px 33px #AAB5A717',
                borderRadius: '8px',
                marginBottom: '16px',
                paddingLeft: '10px',
                opacity: 1,
            },
            locationName: { paddingLeft: '20px' },
            missionText: {
                paddingTop: {
                    xs: '20px',
                    sm: 'inherit',
                },
                paddingLeft: {
                    sm: '20px',
                },
            },
            img: {
                maxWidth: {
                    xs: '100%',
                    lg: 'inherit',
                },
            },
            locationIcon: {
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
            },
        };
    };
    const cssObject: SXCssObject = createCssObject(useTheme());
    const theme = useTheme();
    const isXs = useMediaQuery(theme.breakpoints.only('xs'));
    const multipleOpenProjects = useSelector(selectMultipleOpenProjects);
    const project = multipleOpenProjects ? 'Motili' : 'AirandHeatDoneRight.com';
    return (
        <StaticSubPage
            content1={
                <Fragment>
                    <Grid item xs={12} sx={cssObject.contentHeading}>
                        <Heading variant={'h1'} color={'primary'} label={'About Us'} />
                    </Grid>

                    {/* About Us Begin */}

                    <Grid item xs={12} sx={cssObject.content}>
                        <Paragraph align={'justify'} size={isXs ? 'small' : 'normal'}>
                            {project} started out as a project of passion by several HVAC industry veterans. The idea
                            was simple - there had to be a simpler, more efficient way for homeowners to shop for
                            heating and air conditioning equipment.
                        </Paragraph>
                    </Grid>
                    <Grid item xs={12} sx={cssObject.content}>
                        <Paragraph align={'justify'} size={isXs ? 'small' : 'normal'}>
                            In the past, the typical process for homeowners involved finding an HVAC contractor, vetting
                            the contractor, price shopping contractors to get the best deal, researching equipment, and
                            finally making a decision. This process, while functional, requires a lot of resources, time
                            and effort on the part of the homeowner, often leading to frustrating experiences and time
                            wasted on finding the right contractor and equipment.
                        </Paragraph>
                    </Grid>
                    <Grid item xs={12} sx={cssObject.content}>
                        <Paragraph align={'justify'} size={isXs ? 'small' : 'normal'}>
                            Our HVAC experts knew there was a better way, using technology, to make the experience of
                            shopping for HVAC much easier, and so {project} was created. The biggest difference between{' '}
                            {project} and the traditional approach is: we provide a simple tool which will guide you
                            through the process of identifying which HVAC setup you have, recommending the most
                            efficient HVAC system, connecting you to a qualified and experienced contractor and
                            completing the job. This means no more looking for contractors or trying to figure out which
                            system you have or looking through hundreds of options.
                        </Paragraph>
                    </Grid>
                    <Grid item xs={12} sx={cssObject.content}>
                        <Paragraph align={'justify'} size={isXs ? 'small' : 'normal'}>
                            {project} makes upgrading your HVAC system fast, easy, with one point of contact for the
                            entire process.
                        </Paragraph>
                    </Grid>
                    <Grid item xs={12} sx={cssObject.content}>
                        <Paragraph align={'justify'} size={isXs ? 'small' : 'normal'}>
                            Our foundation is based on a simple process: you complete the HVAC guide to determine which
                            is the best system for you, we connect you to a contractor who validates the choice of
                            system, we schedule your installation. That’s it!
                        </Paragraph>
                    </Grid>
                    <Grid item xs={12} sx={cssObject.content}>
                        <Paragraph align={'justify'} size={isXs ? 'small' : 'normal'}>
                            Our network of over 2,000 contractors around the US means no matter where you are, we can
                            help.
                        </Paragraph>
                    </Grid>
                    {/* About Us End */}

                    {/* Mission Statement */}
                    <Grid container xs={12} sx={cssObject.mission}>
                        <Grid container item xs={12} sm={3} alignItems={'center'} justifyContent={'space-evenly'}>
                            {isXs ? <MissionIconSmall sx={cssObject.img} /> : <MissionIcon sx={cssObject.img} />}
                        </Grid>
                        <Grid container item xs={12} sm={9} sx={cssObject.missionText}>
                            <Grid item xs={12} sx={cssObject.contentSecondaryHeading} alignItems={'center'}>
                                <Heading variant={'h3'} isPopUp color={'primary'} label={'Mission Statement'} />
                            </Grid>
                            <Grid item xs={12}>
                                <Paragraph align={'justify'} size={isXs ? 'small' : 'normal'}>
                                    To proactively transform the residential HVAC replacement experience using the best
                                    people, technology, and analytics – for homeowners in the local markets we serve. We
                                    treat our customers’ properties as if they were our own.
                                </Paragraph>
                            </Grid>
                        </Grid>
                    </Grid>
                    {/* Service Area Begin */}
                    <Grid item xs={12} sx={cssObject.contentSecondaryHeading}>
                        <Heading variant={'h4'} isPopUp color={'primary'} label={'Service Area'} />
                    </Grid>
                    <Grid item xs={12} sx={cssObject.content}>
                        <Paragraph align={'justify'} size={isXs ? 'small' : 'normal'}>
                            We proudly serve the following markets:
                        </Paragraph>
                    </Grid>
                    <Grid container item xs={12} style={{ marginTop: '25px' }}>
                        <Grid container item xs={12} sm={6} sx={cssObject.location} alignItems={'center'}>
                            <Grid item xs={1} sx={cssObject.locationIcon}>
                                <LocationIcon />
                            </Grid>
                            <Grid xs={11} item sx={cssObject.locationName}>
                                <Paragraph size={isXs ? 'small' : 'normal'} bold headingStyle>
                                    Greater Chicagoland Area
                                </Paragraph>
                            </Grid>
                        </Grid>
                        <Grid container item xs={12} sm={6} sx={cssObject.location} alignItems={'center'}>
                            <Grid item xs={1} sx={cssObject.locationIcon}>
                                <LocationIcon />
                            </Grid>
                            <Grid xs={11} item sx={cssObject.locationName}>
                                <Paragraph size={isXs ? 'small' : 'normal'} bold headingStyle>
                                    Greater Kansas City Metro Area
                                </Paragraph>
                            </Grid>{' '}
                        </Grid>
                        <Grid container item xs={12} sm={6} sx={cssObject.location} alignItems={'center'}>
                            <Grid item xs={1} sx={cssObject.locationIcon}>
                                <LocationIcon />
                            </Grid>
                            <Grid xs={11} item sx={cssObject.locationName}>
                                <Paragraph size={isXs ? 'small' : 'normal'} bold headingStyle>
                                    Greater St. Louis Metro Area
                                </Paragraph>
                            </Grid>
                        </Grid>
                        <Grid container item xs={12} sm={6} sx={cssObject.location} alignItems={'center'}>
                            <Grid item xs={1} sx={cssObject.locationIcon}>
                                <LocationIcon />
                            </Grid>
                            <Grid xs={11} item sx={cssObject.locationName}>
                                <Paragraph size={isXs ? 'small' : 'normal'} bold headingStyle>
                                    Greater Baltimore / Washington, D.C. Metro Area
                                </Paragraph>
                            </Grid>
                        </Grid>
                    </Grid>
                </Fragment>
            }
        />
    );
}

export default WhoWeAre;
