import * as React from 'react';
import { useTheme } from '@mui/material/styles';
import { getThemePrimaryMain } from 'components/common/Utilities';

export default function AlertIcon(props: any) {
    const theme = useTheme();
    const themePrimaryMain = getThemePrimaryMain(theme);
    return (
        <svg data-name="Component 29 \u2013 1" xmlns="http://www.w3.org/2000/svg" width={64} height={64} {...props}>
            <g data-name="Group 612" transform="translate(-609 -228)">
                <rect
                    data-name="Rectangle 411"
                    width={64}
                    height={64}
                    rx={4}
                    transform="translate(609 228)"
                    fill={themePrimaryMain}
                />
                <path
                    data-name="Union 25"
                    d="M638 271a3 3 0 016 0 3 3 0 01-6 0zm0-9v-12a3 3 0 116 0v12a3 3 0 11-6 0z"
                    fill="#fff"
                />
            </g>
        </svg>
    );
}
