import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';

import createStore from './store';
import { swInit, swUpdate } from './store/actions/serviceWorkerActions';
import * as serviceWorker from './serviceWorker';
import App from './App';
import { PersistGate } from 'redux-persist/integration/react';
import Axios from 'axios-observable';
import config from './config';
import { StyledEngineProvider } from '@mui/material/styles';
import { IS_ALIVE_COOKIE } from 'components/common/constants';
import Cookies from 'js-cookie';
import axios from 'axios';
import { showSnackbarError } from 'components/common/Snackbar/SnackbarHelper';
import { clearLoginData } from 'store/actions/loginActions';

const { store, persistor } = createStore();

export const setIsAliveCookie = () => {
    const jwtTokenExpireInMins = store.getState().store.jwtTokenExpireInMins;
    let date: Date = new Date(new Date().getTime() + (jwtTokenExpireInMins ? jwtTokenExpireInMins - 5 : 235) * 60000); //60000 * mins
    Cookies.set(IS_ALIVE_COOKIE, IS_ALIVE_COOKIE, {
        expires: date,
    });
};
const http = () => {
    const axiosObj = Axios.create(getAxiosObj());

    axiosObj.interceptors.response.use((response) => {
        return response;
    }, errorCallback);
    return axiosObj;
};
export default http;

const errorCallback = (err: any) => {
    if (err.response.status === 403 || err.response.status === 401) {
        Cookies.remove(IS_ALIVE_COOKIE);
        store.dispatch(clearLoginData());
        showSnackbarError('Your session expired');
    }
    return Promise.reject(err);
};

const getAxiosObj = () => {
    return {
        baseURL: config.apiHost,
        headers: getHeaders(),
        timeout: config.httpTimeout,
        withCredentials: true,
    };
};

export const getHeaders = () => {
    let obj: any = {
        store: config.storeCode,
    };
    return obj;
};

export const getAsyncAwaitHttpObj = () => {
    const axiosObj = axios.create(getAxiosObj());
    axiosObj.interceptors.response.use((response) => {
        return response;
    }, errorCallback);
    return axiosObj;
};

ReactDOM.render(
    <React.StrictMode>
        <Provider store={store}>
            <PersistGate loading={null} persistor={persistor}>
                <StyledEngineProvider injectFirst>
                    <App />
                </StyledEngineProvider>
            </PersistGate>
        </Provider>
    </React.StrictMode>,
    document.getElementById('root') 
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register({
    onSuccess: (registration) => store.dispatch(swInit(registration)),
    onUpdate: (registration) => store.dispatch(swUpdate(registration)),
});
