import { showSnackbarSuccess } from './../../components/common/Snackbar/SnackbarHelper';
import { combineEpics, Epic } from 'redux-observable';
import { Action } from 'ts-action';
import { ofType } from 'ts-action-operators';
import { Observable, of } from 'rxjs';
import { switchMap, catchError, map, withLatestFrom, tap, ignoreElements } from 'rxjs/operators';
import { generateQuoteForCart, moveQuoteToCart, moveQuoteToCartError, moveQuoteToCartSuccess } from '../actions/quotes';
import { RootState } from '../reducers';
import { getMyQuotes, regenerateQuote } from '../services/quoteServices';
import {
    downloadQuoteAction,
    generateQuoteForSelectionPage,
    reGenerateQuoteFromQuotationPage,
    proceedToDownloadQuote,
    myQuotesAction,
    myQuotesError,
    myQuotesSuccess,
} from '../actions/quotes';
import { selectCombinedList, selectFirstSystemCriteria } from '../selectors/selection';
import { downloadQuoteById, generateQuote, moveQuoteToCart as moveQuoteToCartService } from '../services/quoteServices';
import { selectCart } from '../selectors/cart';
import { GenerateQuoteItems, GenerateQuoteReq, Quote } from 'models/quotes';
import { getSnackbarMsg } from 'components/common/CustomSnackMessageUI';
import { prepareObjectForGenerateQuote } from '../../pages/MyQuotes/QuoteUtils';
import { showSnackbarError } from 'components/common/Snackbar/SnackbarHelper';
import { AxiosResponse } from 'axios';
import { productCriteriaPayload } from './cartEpics';
import { selectProductCriteriaState } from 'store/selectors/productCriteria';
import { NumberOfHVAC } from 'models/productCriteria';
const getQuotes$ = (action$: Observable<Action>) =>
    action$.pipe(
        ofType(myQuotesAction),
        switchMap(() =>
            getMyQuotes().pipe(
                map((resp: AxiosResponse<Quote[]>) => myQuotesSuccess(resp.data)),
                catchError((error) => {
                    showSnackbarError('Unable to get quotes');
                    return of(myQuotesError(error));
                })
            )
        )
    );

const generateQuoteForSelection$: Epic = (action$: Observable<Action>, state$: Observable<RootState>) =>
    action$.pipe(
        ofType(generateQuoteForSelectionPage),
        withLatestFrom(
            state$.pipe(map(selectCombinedList)),
            state$.pipe(map(selectFirstSystemCriteria)),
            state$.pipe(map(selectProductCriteriaState))
        ),
        switchMap(([action, selectionCombinedData, firstReplacement, secondReplacement]) => {
            const productCriteria = productCriteriaPayload(
                firstReplacement.numberOfHVAC === NumberOfHVAC.Single
                    ? { ...firstReplacement }
                    : { firstReplacement, secondReplacement }
            );
            const itemsData: GenerateQuoteItems[] = prepareObjectForGenerateQuote(selectionCombinedData);
            const generateQuoteData = { items: itemsData, ...(productCriteria && { searchCriteria: productCriteria }) };
            return generateQuote(generateQuoteData).pipe(
                map((response: AxiosResponse<GenerateQuoteReq>) => proceedToDownloadQuote(response.data)),
                catchError((err) => {
                    return of(myQuotesError(err));
                })
            );
        })
    );

const regenerateQuoteFromQuotationPage$ = (action$: Observable<Action>) =>
    action$.pipe(
        ofType(reGenerateQuoteFromQuotationPage),
        switchMap((action) => {
            return regenerateQuote(action.payload.id).pipe(
                switchMap((response) => {
                    const quoteId = action.payload.quoteId ? `"${action.payload.quoteId}" ` : '';
                    showSnackbarSuccess(getSnackbarMsg(`Quote ${quoteId}`, 'has been regenerated successfully'));
                    return [proceedToDownloadQuote(response.data), myQuotesAction()];
                }),
                catchError((err) => {
                    return of(myQuotesError(err));
                })
            );
        })
    );

const generateQuoteForCart$ = (action$: Observable<Action>, state$: Observable<RootState>) =>
    action$.pipe(
        ofType(generateQuoteForCart),
        withLatestFrom(state$.pipe(map(selectCart))),
        switchMap(([action, cartData]) => {
            const searchCriteria = cartData.contactInformation?.searchCriteria;
            const productCriteria = searchCriteria && productCriteriaPayload(searchCriteria);
            const generateQuoteData = {
                cartId: cartData.cartId!,
                ...(productCriteria && { searchCriteria: productCriteria }),
            };
            return generateQuote(generateQuoteData).pipe(
                map((res: AxiosResponse<GenerateQuoteReq>) => proceedToDownloadQuote(res.data)),
                catchError((err) => {
                    return of(myQuotesError(err));
                })
            );
        })
    );

const downloadQuote$ = (action$: Observable<Action>) =>
    action$.pipe(
        ofType(downloadQuoteAction),
        switchMap((action) =>
            downloadQuoteById(action.payload.id).pipe(
                map((res) => {
                    showSnackbarSuccess(
                        getSnackbarMsg(`Quote "${action.payload.quoteId}"`, ' has been downloaded successfully')
                    );
                    return proceedToDownloadQuote(res.data);
                }),
                catchError((err) => {
                    return of(myQuotesError(err));
                })
            )
        )
    );

const downloadQuotePdf$ = (action$: Observable<Action>) =>
    action$.pipe(
        ofType(proceedToDownloadQuote),
        tap((action) => {
            const link = document.createElement('a');
            link.href = window.URL.createObjectURL(new Blob([action.payload]));
            link.setAttribute('download', `HVAC System Quote.pdf`);
            document.body.appendChild(link);
            link.click();
        }),
        ignoreElements()
    );
const moveQuoteToCart$ = (action$: Observable<Action>) =>
    action$.pipe(
        ofType(moveQuoteToCart),
        switchMap((action) => {
            return moveQuoteToCartService(action.payload.id).pipe(
                map((res) => {
                    return moveQuoteToCartSuccess(res.data);
                }),
                catchError((err) => {
                    return of(moveQuoteToCartError(err));
                })
            );
        })
    );
export default combineEpics(
    getQuotes$,
    generateQuoteForSelection$,
    regenerateQuoteFromQuotationPage$,
    generateQuoteForCart$,
    downloadQuotePdf$,
    downloadQuote$,
    moveQuoteToCart$
);
