import React, { useEffect, useRef } from 'react';
import { Navigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import CartPage from './CartPage';
import { Cart, ItemType, WizardSelectedOptions } from '../../models/cart';
import { selectCart, selectCartProcessing } from '../../store/selectors/cart';
import { AppRoute } from '../../models/route';
import { myCartAction, redirectToPaymentPage } from 'store/actions/cartActions';
import { selectProductLoading } from 'store/selectors/productSearch';
import { NumberOfHVAC } from 'models/productCriteria';
import { myQuotesLoading } from 'store/selectors/quotes';

function CartPageContainer() {
    const cart: Cart = useSelector(selectCart);
    const dispatch = useDispatch();
    const quoteLoading = useSelector(myQuotesLoading);
    const cartLoading = useSelector(selectCartProcessing);
    const changeMainProductLoading = useSelector(selectProductLoading);
    let mounted: any = useRef();

    useEffect(() => {
        if (!mounted.current) {
            // do componentDidMount logic
            mounted.current = true;
            dispatch(myCartAction({}));
        }
    });
    if (!cart || !cart.cartId || !cart.items || cart.items.length === 0 || !cart.prices) {
        return <Navigate to={AppRoute.Home} />;
    }
    const { cartId, items, prices } = cart;

    let wizardSelectedOptions: WizardSelectedOptions;
    if (cart.contactInformation && cart.contactInformation.wizardSelectedOptions) {
        wizardSelectedOptions = cart.contactInformation.wizardSelectedOptions;
    } else {
        const mainSystemsInCart = cart.items.filter((cartItem) => cartItem.itemType === ItemType.MainSystem);
        wizardSelectedOptions = {
            numberOfSystems: mainSystemsInCart.length === 2 ? NumberOfHVAC.Double : NumberOfHVAC.Single,
        };
    }
    return (
        <CartPage
            loading={cartLoading || changeMainProductLoading || quoteLoading}
            cartItems={items}
            cartId={cartId}
            cartPrices={prices}
            wizardSelectedOptions={wizardSelectedOptions}
            shippingAddress={cart?.shippingAddress}
            billingAddress={cart?.billingAddress}
            cartStatus={cart?.status}
            shouldCheckForAddOnCompatibility={cart?.shouldCheckForAddOnCompatibility}
            onClickContinueToPaymentPage={() => {
                dispatch(redirectToPaymentPage());
            }}
        />
    );
}

export default CartPageContainer;
