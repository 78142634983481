import { useTheme } from '@mui/material/styles';
import { getThemePrimaryMain } from 'components/common/Utilities';
import * as React from 'react';
function CollapseIcon(props: any) {
    const theme = useTheme();
    const themePrimaryMain = getThemePrimaryMain(theme);
    return (
        <svg
            id="Fill_4"
            data-name="Fill 4"
            xmlns="http://www.w3.org/2000/svg"
            width="14.539"
            height="2.086"
            viewBox="0 0 14.539 2.086"
        >
            <path
                id="Fill_4-2"
                data-name="Fill 4"
                d="M12.552,2.086H-.013A1.018,1.018,0,0,1-1,1.043,1.018,1.018,0,0,1-.013,0H12.552a1.017,1.017,0,0,1,.987,1.043,1.017,1.017,0,0,1-.987,1.043"
                transform="translate(1)"
                fill={`${themePrimaryMain}`}
            />
        </svg>
    );
}
export default CollapseIcon;
