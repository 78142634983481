import { BraintreeError } from 'braintree-web';
import { showSnackbarError } from 'components/common/Snackbar/SnackbarHelper';

export const showBraintreeErrorMsg = (err: BraintreeError) => {
    if (err && err.code && err.message) {
        showSnackbarError(err.message);
    } else if (err && err.message) {
        showSnackbarError('Please correct the Credit card information.');
    }
};
