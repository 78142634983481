import { Address, AppliedCoupon, CartItem, CartPrice, ItemType, SearchCriteria, SystemGroup } from './cart';
export interface Quote {
    customerId: number;
    cartId: string;
    quoteId: string;
    quotationJson: QuotationJson;
    storeCode: string;
    createdAt: string;
    updatedAt: string;
    expireAt: Date;
    status: QuoteStatus;
    id: number;
    priceDelta: PriceDelta;
}
export interface GenerateQuoteReq {
    items?: GenerateQuoteItems[];
    cartId?: string;
    shippingAddress?: Address;
    billingAddress?: Address;
    searchCriteria?: SearchCriteria;
}

export interface GenerateQuoteItems {
    sku?: string;
    quantity?: number;
    itemType?: ItemType;
    systemGroup?: SystemGroup;
    taxPercent?: number;
}

export interface QuotationJson {
    isEquipmentOnly?: boolean;
    items: CartItem[];
    cartId: string;
    prices: CartPrice;
    shippingAddress: Address;
    billingAddress: Address;
    appliedCoupons?: AppliedCoupon[];
}
export enum QuoteStatus {
    EXPIRED = 'Expired',
    ACTIVE = 'Active',
}
export enum PriceDelta {
    INCREASED = 'Increased',
    DECREASED = 'Decreased',
    NO_CHANGE = 'NoChange',
}
