import {
    resetData,
    getAddressListAction,
    getAddressListFailure,
    getAddressListSuccess,
    hideChooseAddressPopupAction,
    hideEditAddressPopupAction,
    setAddressToEdit,
    showChooseAddressPopupAction,
    showEditAddressPopupAction,
} from 'store/actions/addressActions';
import { reducer, on } from 'ts-action';

const initialState: any = {
    loading: false,
    showChooseAddressPopup: false,
    showEditAddressPopup: false,
    addressList: [],
    addressToEdit: null,
};

export const addressReducer = reducer(
    initialState,
    on(getAddressListAction, (state, action) => ({
        ...state,
        addressList: action.payload ? [] : [...state.addressList],
        loading: true,
    })),
    on(getAddressListSuccess, (state, action) => ({
        ...state,
        addressList: action.payload,
        loading: false,
    })),
    on(getAddressListFailure, (state, action) => ({
        ...state,
        loading: false,
    })),
    on(resetData, (state, action) => ({
        ...initialState,
    })),
    on(showChooseAddressPopupAction, (state, action) => ({
        ...state,
        showChooseAddressPopup: true,
    })),
    on(hideChooseAddressPopupAction, (state, action) => ({
        ...state,
        showChooseAddressPopup: false,
    })),
    on(setAddressToEdit, (state, action) => ({
        ...state,
        addressToEdit: action.payload.address!,
    })),
    on(hideEditAddressPopupAction, (state, action) => ({
        ...state,
        showEditAddressPopup: false,
        addressToEdit: null,
    })),
    on(showEditAddressPopupAction, (state, action) => ({
        ...state,
        showEditAddressPopup: true,
        showChooseAddressPopup: false,
    }))
);

export default addressReducer;
