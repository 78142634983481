import React, { PropsWithChildren } from 'react';
import { styled, Theme, useTheme } from '@mui/material/styles';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import WizardInput from './WizardInput';
import ChooseOptionInput from './ChooseOptionInput';
import { SXCssObject } from 'models/ThemeImages';

const WizardInputButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
    display: 'flex',
    flexWrap: 'wrap',
    margin: theme.spacing(-0.5),

    '& .MuiToggleButtonGroup-root': {
        display: 'flex',
        flexWrap: 'wrap',
    },
    '& .MuiToggleButtonGroup-grouped': {
        color: theme.palette.text.primary,
        margin: theme.spacing(0.5),
        padding: theme.spacing(1, 2),
        marginLeft: `0px !important`,
        '&:first-of-type': {
            borderRadius: 1,
            '&:hover': {
                border: `1px solid ${theme.palette.action.hover} !important`,
            },
        },
        '&:not(:first-of-type)': {
            borderRadius: 1,
            marginLeft: `theme.spacing(0.5) !important`,
            borderLeft: `1px solid ${theme.palette.divider} !important`,
            '&:hover': {
                border: `1px solid ${theme.palette.action.hover} !important`,
            },
        },
    },
    '& .Mui-selected': {
        color: `${theme.palette.common.white} !important`,
        backgroundColor: `${theme.palette.action.hover} !important`,
        '&:hover': {
            backgroundColor: `${theme.palette.action.hover} !important`,
        },
    },
}));

const InputButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
    display: 'flex',
    flexWrap: 'wrap',
    '& .MuiToggleButtonGroup-root': {
        display: 'flex',
        flexWrap: 'wrap',
        width: `calc(100% + ${theme.spacing(1)})`,
    },
    '& .MuiToggleButtonGroup-grouped': {
        minHeight:theme.spacing(4.8),
        margin: theme.spacing(0.5, 0),
        padding: theme.spacing(1, 2),
        borderRadius: 1,
        border:`1px solid ${theme.palette.background.default}`,
        '&:not(:first-of-type)': {
            borderRadius: 1,
            marginLeft: `theme.spacing(0.5) !important`,
        },
    },
    '& .Mui-selected': {
        border: `1px solid ${theme.palette.primary.main} !important`,
    },
}));
export interface Options {
    id: string;
    value: string;
    label: string;
}

interface Props {
    id: string;
    options: Options[];
    onChange: (id: string) => void;
    value?: string;
    isForSchedulePage?: boolean;
    isForContactUsPage?: boolean;
}

const createCssObject = (theme: Theme): SXCssObject => ({
    wizInputStyle: {
        height: '48px',
        width: {
            xs: '100%',
            sm: 'inherit',
        },
        display: {
            sm: 'flex',
        },
        flex: {
            sm: 1,
        },
        px: {
            sm: '12px',
            md: 'inherit',
        },
    },
    contactUsInputStyle: {
        height: 'auto',
        borderRadius: '8px !important',
        textAlign: 'left',
        justifyContent: 'flex-start !important',
        backgroundColor: '#F8F8F8',
        color: theme.palette.text.primary,
        fontSize: '0.75rem',
        fontWeight: theme.typography.fontWeightMedium,
        width: '100%',
        px: {
            sm: '12px',
            md: 'inherit',
        },
    },
});

export default function WizardInputs(props: PropsWithChildren<Props>) {
    const { options, onChange, isForSchedulePage, isForContactUsPage, ...other } = props;

    const cssObject = createCssObject(useTheme());
    return isForContactUsPage === true ? (
        <InputButtonGroup exclusive onChange={(e, v) => onChange(v)} {...other}>
            {options.map((option) => (
                <ChooseOptionInput
                    key={option.id}
                    id={option.id}
                    value={option.value}
                    sx={[isForContactUsPage === true && cssObject.contactUsInputStyle]}
                >
                    {option.label}
                </ChooseOptionInput>
            ))}
        </InputButtonGroup>
    ) : (
        <WizardInputButtonGroup exclusive onChange={(e, v) => onChange(v)} {...other}>
            {options.map((option) => (
                <WizardInput
                    key={option.id}
                    id={option.id}
                    value={option.value}
                    sx={[isForSchedulePage === true && cssObject.wizInputStyle]}
                >
                    {option.label}
                </WizardInput>
            ))}
        </WizardInputButtonGroup>
    );
}
