import React, { useRef } from 'react';
import { Theme } from '@mui/material/styles';
import { Box, Container, useMediaQuery, useTheme } from '@mui/material';
import Grid from '@mui/material/Grid';
import ContentContainer from 'components/ContentContainer';
import { Order } from '../../models/order';
import { useDispatch, useSelector } from 'react-redux';
import { AddressType, Cart } from '../../models/cart';
import { selectCart, selectCartProcessing } from '../../store/selectors/cart';
import { Navigate, useLocation } from 'react-router-dom';
import Navigator from '../../components/Navigator';
import BillPayments from './BillPayment';
import { selectOrder, selectOrderLoading } from 'store/selectors/order';
import { AppRoute } from 'models/route';
import { ThemeWithAdditionalData } from 'themes/theme';
import { Heading } from 'components/Heading';
import { Paragraph } from 'components/Paragraph';
import AddressLayoutForCartAndBilling, { OrderAddressComponent } from 'components/AddressLayoutForCartAndBilling';
import { getOrderStatus, orderProcessing, showPayNow } from 'pages/order/order-utils';
import { getOrder } from 'store/actions/orderActions';
import { getCartStatusText, isCartReadyForPayment } from 'pages/cart/cart-utils';
import { SXCssObject } from 'models/ThemeImages';
import { selectMultipleOpenProjects } from 'store/selectors/storeSelector';

const createCssObject = (theme: Theme): SXCssObject => ({
    b: {
        fontWeight: theme.typography.fontWeightMedium,
    },
    billpayment_header: {
        pt: { xs: 1, sm: 4, md: 6 },
    },
    billpayment_divider: {
        m: { xs: '16px 0px', sm: '20px 0px', md: '24px 0px' },
    },
    actionsGrid: {
        '& .MuiContainer-root': {
            marginRight: '0px',
        },
    },
    navigatorRoot: {
        width: '100% !important',
        maxWidth: '100% !important',
        '&.MiuiGrid-root a': {
            backgroundColor: 'red',
        },
    },
    addressContent: {
        mt: { xs: '4px', sm: '32px' },
        ml: { xs: -1.5, sm: 0 },
        mr: { xs: -1.5, sm: 0 },
    },
    editAddressIconStyle: {
        display: 'inline-flex',
        cursor: 'pointer',
        marginBottom: '-4px',
        padding: '5px',
        ml: { xs: '32px', sm: '18px', md: 0 },
    },
    cartStatusSection: {
        display: 'flex',
        alignItems: 'end',
        justifyContent: 'end',
        mt: { xs: 2, sm: 0 },
    },
    cartStatusLabel: {
        justifyContent: {
            sm: 'flex-end',
        },
        display: 'flex',
    },
    cartStatusContent: {
        display: 'flex',
        alignItems: 'center',
    },
    cartStatusTextStyle: {
        fontWeight: theme.typography.fontWeightMedium,
        fontSize: '16px',
    },
    cartStatusIconStyle: {
        border: `3px solid ${theme.customProperties.commonOrange}`,
        width: '14px',
        height: '14px',
        borderRadius: '50%',
        marginRight: '5px',
    },
    cartStatusContainer: {
        display: 'flex',
        padding: {
            xs: '16px',
            sm: 'inherit',
        },
        backgroundColor: {
            xs: theme.palette.background.default,
            sm: 'inherit',
        },
        borderRadius: {
            xs: '4px',
            sm: 'inherit',
        },
        width: {
            xs: '100%',
            sm: 'inherit',
        },
    },
    orderStatusContainer: {
        display: 'flex',
        p: {
            xs: '5px 0px',
            sm: 'inherit',
        },
        width: {
            xs: '100%',
            sm: 'inherit',
        },
    },
    billPaymentAndStatus: {
        borderBottom: {
            xs: `1px solid ${theme.customProperties.border}`,
            sm: 'inherit',
        },
        pb: {
            xs: 2,
            sm: 'inherit',
        },
    },
});

export default function BillPaymentContainer() {
    const cssObject: SXCssObject = createCssObject(useTheme());
    const theme = useTheme<ThemeWithAdditionalData>();
    const dispatch = useDispatch();
    const cart: Cart = useSelector(selectCart);
    const order: Order = useSelector(selectOrder);
    const cartLoading = useSelector(selectCartProcessing);
    const orderLoading = useSelector(selectOrderLoading);
    const isXs = useMediaQuery(theme.breakpoints.only('xs'));
    const billingAddress = cart?.billingAddress;
    const shippingAddress = cart?.shippingAddress;
    const orderBillingAddress = order?.billingAddress;
    const orderShippingAddress = order?.shippingAddress;
    const multipleOpenProjects = useSelector(selectMultipleOpenProjects);
    const multipleProjects = order.status && showPayNow(order.status) && multipleOpenProjects;
    const location = useLocation();
    const orderIdQueryParam = new URLSearchParams(location.search).get('order_id');
    const orderIdParam = orderIdQueryParam && Number(orderIdQueryParam);
    const isFromOrderPage = location.state && ((location.state) as any).isFromOrderPage;
    let mounted: any = useRef();
    React.useEffect(() => {
        if (!mounted.current) {
            mounted.current = true;
            if (isOrderDetailsMissing(order) && order.id && !multipleOpenProjects) {
                dispatch(getOrder({ id: order.id, doNotRedirect: true }));
            } else if (orderIdParam) {
                dispatch(getOrder({ id: orderIdParam, redirectToPayment: true }));
            }
        }
    });

    if (
        (!cart ||
            !cart.cartId ||
            !cart.items ||
            cart.items.length === 0 ||
            !cart.prices ||
            !cart.billingAddress ||
            !isCartReadyForPayment(cart.status)) &&
        !(order && order.status && (showPayNow(order.status) || orderProcessing(order.status)))
    ) {
        return <Navigate to={AppRoute.Home} />;
    } else if (
        order &&
        order.status &&
        order.items &&
        order.items.length > 0 &&
        orderProcessing(order.status) &&
        !(cart && cart.status && isCartReadyForPayment(cart.status))
    ) {
        return <Navigate to={AppRoute.Confirmation} />;
    } else if(multipleOpenProjects && !isFromOrderPage){
        //For MultipleOpenProject(Fully Integrated or EO, Payment page can only be opened from Orders Page. 
        // If payment page is being opened from some other page or by entering payment page url in address bar, then redirect to home
        return <Navigate to={AppRoute.Home} />;
    }

    return (
        <ContentContainer disableGrid background={isXs ? 'bg3' : 'bg2'}>
            <ContentContainer disableGrid background={'bg3'}>
                <Container sx={cssObject.navigatorRoot} maxWidth={'lg'} disableGutters>
                    <ContentContainer>
                        <Navigator color={'primary'} />
                    </ContentContainer>
                </Container>
            </ContentContainer>

            <ContentContainer disableGrid>
                <ContentContainer sx={cssObject.billPaymentAndStatus}>
                    <Grid container justifyContent={'space-between'}>
                        <Grid sx={cssObject.billpayment_header} item xs={12} sm={6}>
                            <Heading label={'Billing and Payment'} />
                        </Grid>
                        {/* cart status or order status shown based on multipleProjects && orderststus flag */}
                        {multipleProjects || (order.status && showPayNow(order.status)) ? (
                            <Grid item xs={12} sm={6} md={4} container>
                                <Grid
                                    item
                                    xs={12}
                                    sm={6}
                                    sx={cssObject.cartStatusSection}
                                    justifyContent={isXs ? 'flex-start' : 'flex-end'}
                                >
                                    <Box sx={cssObject.orderStatusContainer}>
                                        <Box>
                                            <Box sx={cssObject.cartStatusLabel}>
                                                <Paragraph fontSizeVerySmall>Order Number</Paragraph>
                                            </Box>
                                            <ContentContainer disableGrid sx={cssObject.cartStatusContent}>
                                                <Paragraph size={'normal'} semibold headingColor>
                                                    {order.incrementId}
                                                </Paragraph>
                                            </ContentContainer>
                                        </Box>
                                    </Box>
                                </Grid>
                                <Grid
                                    item
                                    xs={12}
                                    sm={6}
                                    sx={cssObject.cartStatusSection}
                                    justifyContent={isXs ? 'flex-start' : 'flex-end'}
                                >
                                    <Box sx={cssObject.orderStatusContainer}>
                                        <Box>
                                            <Box sx={cssObject.cartStatusLabel}>
                                                <Paragraph fontSizeVerySmall>Order Status</Paragraph>
                                            </Box>
                                            <ContentContainer disableGrid sx={cssObject.cartStatusContent}>
                                                <ContentContainer
                                                    disableGrid
                                                    sx={cssObject.cartStatusIconStyle}
                                                ></ContentContainer>
                                                <Paragraph size={'normal'} semibold headingColor>
                                                    {order.status && getOrderStatus(order.status)}
                                                </Paragraph>
                                            </ContentContainer>
                                        </Box>
                                    </Box>
                                </Grid>
                            </Grid>
                        ) : (
                            <Grid item xs={12} sm={4} md={4} sx={cssObject.cartStatusSection}>
                                <Box sx={cssObject.cartStatusContainer}>
                                    <Box>
                                        <Box sx={cssObject.cartStatusLabel}>
                                            <Paragraph fontSizeVerySmall>Cart Status</Paragraph>
                                        </Box>
                                        <ContentContainer disableGrid sx={cssObject.cartStatusContent}>
                                            <ContentContainer
                                                disableGrid
                                                sx={cssObject.cartStatusIconStyle}
                                            ></ContentContainer>
                                            <Paragraph size={'normal'} semibold headingColor>
                                                {getCartStatusText(cart.status)}
                                            </Paragraph>
                                        </ContentContainer>
                                    </Box>
                                </Box>
                            </Grid>
                        )}
                    </Grid>
                </ContentContainer>
                {/* address show based on cart or order(final payment) */}
                {multipleOpenProjects || (order.status && showPayNow(order.status)) ? (
                    <ContentContainer>
                        <Box sx={cssObject.addressContent}>
                            <Grid container spacing={isXs ? 0 : 1}>
                                {orderShippingAddress && (
                                    <OrderAddressComponent flow={AddressType.Home} address={orderShippingAddress} />
                                )}
                                {orderBillingAddress && (
                                    <OrderAddressComponent flow={AddressType.Billing} address={orderBillingAddress} />
                                )}
                            </Grid>
                        </Box>
                    </ContentContainer>
                ) : (
                    <ContentContainer>
                        <Box sx={cssObject.addressContent}>
                            {cart && cart.status && (
                                <AddressLayoutForCartAndBilling
                                    shippingAddress={shippingAddress}
                                    billingAddress={billingAddress}
                                />
                            )}
                        </Box>
                    </ContentContainer>
                )}
                <ContentContainer disableGrid>
                    <BillPayments loading={cartLoading || orderLoading} />
                </ContentContainer>
            </ContentContainer>
        </ContentContainer>
    );
}
const isOrderDetailsMissing = (order?: Order) => {
    if (
        order &&
        order.id &&
        order.items &&
        order.items.length > 0 &&
        !order.items[0].product &&
        order.status &&
        showPayNow(order.status)
    ) {
        return true;
    } else {
        return false;
    }
};
