import { SystemGroup } from 'models/cart';
import { Product } from 'models/product';
import { ProductCriteria } from 'models/productCriteria';
import { action, payload } from 'ts-action';
import { ProductChange, ProductSearch } from '../../models/productSearch';

export const searchProduct = action(
    'PRODUCT::SEARCH',
    payload<{ shouldRedirect?: boolean; addSecondSystemFromCart?: boolean }>()
);
export const searchProductSuccess = action(
    'PRODUCT::SEARCH_SUCCESS',
    payload<{ products: Product[]; shouldRedirect?: boolean; addSecondSystemFromCart?: boolean }>()
);
export const searchSecondProduct = action('PRODUCT::SEARCH_SECOND', payload<{ shouldRedirect?: boolean }>());
export const searchSecondProductSuccess = action(
    'PRODUCT::SEARCH_SUCCESS_SECOND',
    payload<{ productsSystemTwo: Product[]; shouldRedirect?: boolean }>()
);
export const showProduct = action(
    'PRODUCT::SHOW',
    payload<{ isSecondSystem?: boolean; addSecondSystemFromCart?: boolean }>()
);
export const showProductSuccess = action(
    'PRODUCT::SHOW_SUCCESS',
    payload<{ isSecondSystem?: boolean; addSecondSystemFromCart?: boolean }>()
);
export const searchProductError = action('PRODUCT::SEARCH_ERROR', payload<Error>());
export const searchSecondProductError = action('PRODUCT::SEARCH_ERROR_SECOND', payload<Error>());

export const resetProductSearch = action('PRODUCT::RESET');
export const resetIAQProductSearch = action('PRODUCT_IAQ::RESET');
export const resetMainProductSearch = action('PRODUCT_MAIN::RESET');

export const changeProduct = action('PRODUCT::CHANGE', payload<ProductChange>());
export const changeProductSuccess = action(
    'PRODUCT::CHANGE_SUCCESS',
    payload<{
        data: { products?: Product[]; productsSystemTwo?: Product[] };
        sku?: string;
        isByClickingChangeinSelection?: string;
        changingSystemGroup?: SystemGroup;
        isByClickingEditInWizard?: string;
    }>()
);
export const changeProductError = action('PRODUCT::CHANGE_ERROR', payload<Error>());

export const searchProductIAQ = action('IAQPRODUCT::SEARCH', payload<ProductCriteria>());
export const changeProductIAQ = action(
    'IAQPRODUCT::CHANGE',
    payload<{ productCriteria: ProductCriteria; systemGroup: SystemGroup }>()
);

export const changeProductIAQSuccess = action('IAQPRODUCT::CHANGE_SUCCESS', payload<ProductSearch>());
export const changeProductIAQError = action('IAQPRODUCT::CHANGE_ERROR', payload<Error>());

export const searchProductIAQSuccess = action('IAQPRODUCT::SEARCH_SUCCESS', payload<ProductSearch>());
export const searchProductIAQError = action('IAQPRODUCT::SEARCH_ERROR', payload<Error>());

export const getProductBySkuAction = action('GET_PRODUCT::BY_SKU', payload<{ sku: string }>());
export const getProductBySkuSuccess = action('GET_PRODUCT::BY_SKU_SUCCESS', payload<Product[]>());
export const getProductBySkuError = action('GET_PRODUCT::BY_SKU_ERROR', payload<Error>());
