import { useTheme } from '@mui/material/styles';
import { getThemePrimaryLight, getThemePrimaryMain } from 'components/common/Utilities';
import * as React from 'react';

function HomeScheduleBg(props: any) {
    const theme = useTheme();
    const themePrimaryMain = getThemePrimaryMain(theme);
    const themePrimaryLight = getThemePrimaryLight(theme);
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            width="100%"
            viewBox="0 0 513 222"
        >
            <defs>
                <clipPath id="clip-path">
                    <rect
                        id="Rectangle_3767"
                        data-name="Rectangle 3767"
                        width="513"
                        height="222"
                        fill="#fff"
                        stroke="#707070"
                        strokeWidth="1"
                    />
                </clipPath>
            </defs>
            <g id="Component_46_1" data-name="Component 46 – 1" opacity="0.3">
                <g id="Mask_Group_20" data-name="Mask Group 20" clipPath="url(#clip-path)">
                    <g id="Group_82" data-name="Group 82" transform="translate(55.37 15.017)" opacity="0.1">
                        <ellipse
                            id="Ellipse_120"
                            data-name="Ellipse 120"
                            cx="153.957"
                            cy="154.189"
                            rx="153.957"
                            ry="154.189"
                            transform="translate(50.339 50.415)"
                            fill={`${themePrimaryMain}`}
                            opacity="0.6"
                        />
                        <ellipse
                            id="Ellipse_121"
                            data-name="Ellipse 121"
                            cx="204.297"
                            cy="204.604"
                            rx="204.297"
                            ry="204.604"
                            transform="translate(0 0)"
                            fill={`${themePrimaryMain}`}
                            opacity="0.2"
                        />
                        <ellipse
                            id="Ellipse_122"
                            data-name="Ellipse 122"
                            cx="102.148"
                            cy="102.302"
                            rx="102.148"
                            ry="102.302"
                            transform="translate(102.148 102.302)"
                            fill={`${themePrimaryMain}`}
                            opacity="0.69"
                        />
                        <ellipse
                            id="Ellipse_123"
                            data-name="Ellipse 123"
                            cx="48.87"
                            cy="48.943"
                            rx="48.87"
                            ry="48.943"
                            transform="translate(155.427 155.661)"
                            fill={`${themePrimaryMain}`}
                        />
                    </g>
                    <g id="Group_81" data-name="Group 81" transform="translate(54.031 15.018)">
                        <g id="Group_80" data-name="Group 80" transform="translate(0 0)">
                            <g id="Group_75" data-name="Group 75" opacity="0.7">
                                <ellipse
                                    id="Ellipse_108"
                                    data-name="Ellipse 108"
                                    cx="153.957"
                                    cy="154.189"
                                    rx="153.957"
                                    ry="154.189"
                                    transform="translate(50.339 50.415)"
                                    fill="none"
                                    stroke={`${themePrimaryMain}`}
                                    strokeWidth="2"
                                    opacity="0.6"
                                />
                                <ellipse
                                    id="Ellipse_109"
                                    data-name="Ellipse 109"
                                    cx="204.297"
                                    cy="204.604"
                                    rx="204.297"
                                    ry="204.604"
                                    fill="none"
                                    stroke={`${themePrimaryMain}`}
                                    strokeWidth="2"
                                    opacity="0.2"
                                />
                                <ellipse
                                    id="Ellipse_110"
                                    data-name="Ellipse 110"
                                    cx="102.148"
                                    cy="102.302"
                                    rx="102.148"
                                    ry="102.302"
                                    transform="translate(102.148 102.302)"
                                    fill="none"
                                    stroke={`${themePrimaryMain}`}
                                    strokeWidth="2"
                                    opacity="0.69"
                                />
                                <ellipse
                                    id="Ellipse_111"
                                    data-name="Ellipse 111"
                                    cx="48.87"
                                    cy="48.943"
                                    rx="48.87"
                                    ry="48.943"
                                    transform="translate(155.427 155.661)"
                                    fill="none"
                                    stroke={`${themePrimaryMain}`}
                                    strokeWidth="2"
                                />
                            </g>
                            <ellipse
                                id="Ellipse_112"
                                data-name="Ellipse 112"
                                cx="26.614"
                                cy="26.654"
                                rx="26.614"
                                ry="26.654"
                                transform="translate(70.784 66.087)"
                                fill={`${themePrimaryMain}`}
                            />
                            <ellipse
                                id="Ellipse_113"
                                data-name="Ellipse 113"
                                cx="8.769"
                                cy="8.782"
                                rx="8.769"
                                ry="8.782"
                                transform="translate(199.938 39.433)"
                                fill={`${themePrimaryMain}`}
                            />
                            <ellipse
                                id="Ellipse_114"
                                data-name="Ellipse 114"
                                cx="8.769"
                                cy="8.782"
                                rx="8.769"
                                ry="8.782"
                                transform="translate(306.445 21.869)"
                                fill={`${themePrimaryLight}`}
                            />
                            <ellipse
                                id="Ellipse_115"
                                data-name="Ellipse 115"
                                cx="6.636"
                                cy="6.646"
                                rx="6.636"
                                ry="6.646"
                                transform="translate(1.397 140.616)"
                                fill={`${themePrimaryLight}`}
                            />
                            <ellipse
                                id="Ellipse_116"
                                data-name="Ellipse 116"
                                cx="7.974"
                                cy="7.986"
                                rx="7.974"
                                ry="7.986"
                                transform="translate(395.321 151.227)"
                                fill={`${themePrimaryLight}`}
                            />
                            <ellipse
                                id="Ellipse_117"
                                data-name="Ellipse 117"
                                cx="19.42"
                                cy="19.449"
                                rx="19.42"
                                ry="19.449"
                                transform="translate(270.35 131.778)"
                                fill={`${themePrimaryMain}`}
                            />
                            <g id="Group_79" data-name="Group 79" transform="translate(86.408 81.571)">
                                <rect
                                    id="Rectangle_102"
                                    data-name="Rectangle 102"
                                    width="21.98"
                                    height="22.013"
                                    rx="2.324"
                                    fill="none"
                                    stroke="#f6f6f6"
                                    strokeLinecap="round"
                                    strokeWidth="2"
                                />
                                <line
                                    id="Line_70"
                                    data-name="Line 70"
                                    x2="20.926"
                                    transform="translate(0.422 6.203)"
                                    fill="none"
                                    stroke="#f6f6f6"
                                    strokeLinecap="round"
                                    strokeWidth="2"
                                />
                                <g id="Group_76" data-name="Group 76" transform="translate(4.059 10.162)">
                                    <path
                                        id="Path_189"
                                        data-name="Path 189"
                                        d="M2695.409,482.052"
                                        transform="translate(-2691.996 -482.052)"
                                        fill="none"
                                        stroke="#f6f6f6"
                                        strokeLinecap="round"
                                        strokeWidth="2"
                                    />
                                    <path
                                        id="Path_190"
                                        data-name="Path 190"
                                        d="M2691.1,482.052"
                                        transform="translate(-2691.098 -482.052)"
                                        fill="none"
                                        stroke="#f6f6f6"
                                        strokeLinecap="round"
                                        strokeWidth="2"
                                    />
                                    <line
                                        id="Line_71"
                                        data-name="Line 71"
                                        transform="translate(6.826 0)"
                                        fill="none"
                                        stroke="#f6f6f6"
                                        strokeLinecap="round"
                                        strokeWidth="2"
                                    />
                                    <line
                                        id="Line_72"
                                        data-name="Line 72"
                                        transform="translate(10.239 0)"
                                        fill="none"
                                        stroke="#f6f6f6"
                                        strokeLinecap="round"
                                        strokeWidth="2"
                                    />
                                    <line
                                        id="Line_73"
                                        data-name="Line 73"
                                        transform="translate(13.652 0)"
                                        fill="none"
                                        stroke="#f6f6f6"
                                        strokeLinecap="round"
                                        strokeWidth="2"
                                    />
                                </g>
                                <g id="Group_77" data-name="Group 77" transform="translate(4.059 13.752)">
                                    <line
                                        id="Line_74"
                                        data-name="Line 74"
                                        transform="translate(3.413)"
                                        fill="none"
                                        stroke="#f6f6f6"
                                        strokeLinecap="round"
                                        strokeWidth="2"
                                    />
                                    <line
                                        id="Line_75"
                                        data-name="Line 75"
                                        fill="none"
                                        stroke="#f6f6f6"
                                        strokeLinecap="round"
                                        strokeWidth="2"
                                    />
                                    <line
                                        id="Line_76"
                                        data-name="Line 76"
                                        transform="translate(6.826)"
                                        fill="none"
                                        stroke="#f6f6f6"
                                        strokeLinecap="round"
                                        strokeWidth="2"
                                    />
                                    <line
                                        id="Line_77"
                                        data-name="Line 77"
                                        transform="translate(10.239)"
                                        fill="none"
                                        stroke="#f6f6f6"
                                        strokeLinecap="round"
                                        strokeWidth="2"
                                    />
                                    <line
                                        id="Line_78"
                                        data-name="Line 78"
                                        transform="translate(13.652)"
                                        fill="none"
                                        stroke="#f6f6f6"
                                        strokeLinecap="round"
                                        strokeWidth="2"
                                    />
                                </g>
                                <g id="Group_78" data-name="Group 78" transform="translate(4.059 17.341)">
                                    <line
                                        id="Line_79"
                                        data-name="Line 79"
                                        transform="translate(3.413 0)"
                                        fill="none"
                                        stroke="#f6f6f6"
                                        strokeLinecap="round"
                                        strokeWidth="2"
                                    />
                                    <line
                                        id="Line_80"
                                        data-name="Line 80"
                                        transform="translate(0 0)"
                                        fill="none"
                                        stroke="#f6f6f6"
                                        strokeLinecap="round"
                                        strokeWidth="2"
                                    />
                                    <line
                                        id="Line_81"
                                        data-name="Line 81"
                                        transform="translate(6.826 0)"
                                        fill="none"
                                        stroke="#f6f6f6"
                                        strokeLinecap="round"
                                        strokeWidth="2"
                                    />
                                    <path
                                        id="Path_191"
                                        data-name="Path 191"
                                        d="M2704.031,491.107"
                                        transform="translate(-2693.792 -491.107)"
                                        fill="none"
                                        stroke="#f6f6f6"
                                        strokeLinecap="round"
                                        strokeWidth="2"
                                    />
                                    <path
                                        id="Path_192"
                                        data-name="Path 192"
                                        d="M2708.342,491.107"
                                        transform="translate(-2694.69 -491.107)"
                                        fill="none"
                                        stroke="#f6f6f6"
                                        strokeLinecap="round"
                                        strokeWidth="2"
                                    />
                                </g>
                            </g>
                            <ellipse
                                id="Ellipse_118"
                                data-name="Ellipse 118"
                                cx="8.732"
                                cy="8.745"
                                rx="8.732"
                                ry="8.745"
                                transform="translate(281.039 142.482)"
                                fill="none"
                                stroke="#f6f6f6"
                                strokeLinecap="round"
                                strokeWidth="2"
                            />
                            <path
                                id="Path_193"
                                data-name="Path 193"
                                d="M2942.849,552v4.582h3.74"
                                transform="translate(-2653.079 -404.808)"
                                fill="none"
                                stroke="#f6f6f6"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                            />
                            <ellipse
                                id="Ellipse_119"
                                data-name="Ellipse 119"
                                cx="2.328"
                                cy="2.331"
                                rx="2.328"
                                ry="2.331"
                                transform="translate(206.379 45.596)"
                                fill="none"
                                stroke="#f6f6f6"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                            />
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    );
}

export default HomeScheduleBg;
