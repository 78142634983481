import React, { useState } from 'react';
import { useTheme } from '@mui/material/styles';
import { useDispatch, useSelector } from 'react-redux';
import Box from '@mui/material/Box';
import { useMediaQuery } from '@mui/material';
import Grid from '@mui/material/Grid';
import { Paragraph } from 'components/Paragraph';
import { ThemeWithAdditionalData } from '../../themes/theme';
import ContentContainer from 'components/ContentContainer';
import { Cart, PaymentMethod } from 'models/cart';
import { selectCart, selectCartItems } from 'store/selectors/cart';
import CartSummary from 'components/CartSummary';
import MinimisedCartSummary from 'components/MinimisedCartSummary';
import { placeOrder } from 'store/actions/cartActions';
import LoadingOverlay from 'components/common/LoadingOverlay';
import PromoCode from './PromoCode';
import PaymentTab from './PaymentTab';
import { selectOrder, selectOrderedItems } from 'store/selectors/order';
import { Order, OrderedItems, OrderStatus } from 'models/order';
import { placeFinalOrder, placeFinalOrderEquipmentOnly } from 'store/actions/orderActions';
import ArrowDown3 from 'components/icons/ArrowDown3';
import CurrencyIcon from 'components/icons/CurrencyIcon';
import { SXCssObject } from 'models/ThemeImages';
import { showPayNow } from 'pages/order/order-utils';
import { selectMultipleOpenProjects } from 'store/selectors/storeSelector';
import { taxFromCartPrice, discountFromCartPrice } from 'pages/cart/cart-utils';

const createCssObject = (theme: ThemeWithAdditionalData): SXCssObject => ({
    root: {
        flexGrow: 1,
        borderRadius: '1px',
        pt: { xs: 2, md: 5 },
        mb: 4,
    },
    tabHeaderContainer: {
        display: 'flex',
    },
    tabHeadingContent: {
        display: 'flex',
        width: '100%',
        flexDirection: 'row',
        outline: 'none',
        m: { xs: '16px ', sm: '20px ', md: '24px ' },
    },
    iconStyle: {
        width: { xs: '32px', sm: '50px' },
        height: { xs: '32px', sm: '50px' },
    },
    icons: {
        mr: { xs: 1, sm: 1.6 },
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
    },
    iconsNotSelected: {
        mr: { xs: 1, sm: 1.6 },
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        opacity: '0.3',
    },
    centerColumn: {
        display: 'flex',
        justifyContent: 'flex-start',
        fontSize: '20px',
        flexDirection: 'column',
    },
    tabheadingText: {
        fontSize: { xs: '16px', sm: '18px', md: '20px' },
    },
    tabSubheading: {
        fontSize: '12px',
        marginTop: {
            xs: '6px',
            sm: 'inherit',
        },
        display: {
            xs: 'none',
            sm: 'inherit',
        },
    },
    tabPanelText: {
        fontSize: '22px',
        fontWeight: theme.typography.fontWeightMedium,
    },
    swipeViews: {
        borderLeft: `1px solid ${theme.palette.action.hover}`,
        borderRight: `1px solid ${theme.palette.action.hover}`,
        borderBottom: `1px solid ${theme.palette.action.hover}`,
        height: 'auto',
        backgroundColor: theme.palette.common.white,
    },
    promoCodeContent: {
        marginBottom: {
            sm: '24px',
        },
    },
    reverseArrowIcon: {
        transform: 'rotate(180deg)',
        cursor: 'pointer',
    },
    showLess: {
        color: theme.palette.text.primary,
        marginLeft: '5px',
    },
    showLessContainer: {
        backgroundColor: theme.palette.primary.light,
        paddingLeft: '20px',
        border: `1px solid ${theme.palette.primary.contrastText}`,
        borderTop: 'none',
        height: '70px',
    },
    divider: {
        width: '94%',
        height: '10px',
        margin: '0px auto',
        borderBottom: `2px solid ${theme.palette.primary.contrastText}`,
    },
    tabHeaderStyle: {
        width: '100%',
        cursor: 'pointer',
        display: 'flex',
        alignContent: 'center',
        WebkitTapHighlightColor: 'transparent',
    },
    tabSelected: {
        border: `1px solid ${theme.palette.text.primary}`,
        borderBottom: 'none',
        backgroundColor: theme.palette.common.white,
    },
    tabNonSelected: {
        border: `1px solid ${theme.palette.divider}`,
        borderBottom: `1px solid ${theme.palette.text.primary}`,
        backgroundColor: `${theme.palette.secondary.main}`,
    },
    tabHide: {
        width: '100% !important',
        cursor: 'default',
    },
});

interface Props {
    loading: boolean;
}

export default function BillPayments(props: Props) {
    const cssObject: SXCssObject = createCssObject(useTheme());
    const dispatch = useDispatch();
    const order = useSelector(selectOrder);
    const balanceAmountPending = order && order.status && order.status === OrderStatus.PENDING_PAYMENT;
    const [selectedTab, setSelectedTab] = React.useState(0);
    const cart: Cart = useSelector(selectCart);
    const { cartId, prices } = cart;
    const cartItems = useSelector(selectCartItems);
    const orderItems: OrderedItems = useSelector(selectOrderedItems);
    const summarySubtotal = prices?.subtotalExcludingTax! | 0;
    const taxesAverage = prices && taxFromCartPrice(prices);
    const multipleOpenProjects = useSelector(selectMultipleOpenProjects);
    const multipleProjects = order.status && showPayNow(order.status) && multipleOpenProjects;
    const [loader, setLoader] = React.useState(false);
    const discount = prices && discountFromCartPrice(prices);
    const theme = useTheme();
    const isXs = useMediaQuery(theme.breakpoints.only('xs'));
    const isMdDown = useMediaQuery(theme.breakpoints.down('md'));
    const [expandCartSummary, setExpandCartSummary] = useState(false);
    const [collapseCartSummary, setCollapseCartSummary] = useState(true);
    const balanceAmount = order && order.totalDue;

    const handleChangeIndex = (index: number, e: any) => {
        if (selectedTab === index) return;
        setSelectedTab(index);
    };

    const handlePlaceOrder = (cart: Cart, paymentMethod: PaymentMethod) => {
        if (!!cart && cart.cartId) {
            const payload = {
                cart: {
                    cartId: cartId,
                    email: cart.email,
                    billingAddress: cart.billingAddress,
                    shippingAddress: cart.shippingAddress,
                },
                paymentMethod: paymentMethod,
            };
             dispatch(placeOrder(payload));
        }
    };
    const handlePlaceFinalOrder = (order: Order, paymentMethod: PaymentMethod, hvFinalOrder?: boolean) => {
        const isEquipmentOnlyOrder = order.items?.some((item) => item.sku?.includes('-EO'));
        // For EO Flow, use Separate Order Payment API
        if (multipleOpenProjects && isEquipmentOnlyOrder) {
            if (!!order && order.id) {
                dispatch(
                    placeFinalOrderEquipmentOnly({
                        orderId: `${order.id}`,
                        paymentMethod: paymentMethod,
                        hvFinalOrder: hvFinalOrder,
                    })
                )
            }
        }
        else {
            if (!!order && order.id) {
                dispatch(
                    placeFinalOrder({
                        orderId: `${order.id}`,
                        paymentMethod: paymentMethod,
                        hvFinalOrder: hvFinalOrder,
                    })
                )
            }
        }

    };
    const ShowLess = () => {
        return (
            <Grid container sx={cssObject.showLessContainer} alignItems={'center'}>
                <Grid item style={{ display: 'flex' }}>
                    <ArrowDown3
                        onClick={() => {
                            setCollapseCartSummary(true);
                        }}
                        style={cssObject.reverseArrowIcon}
                    />
                </Grid>
                <Grid sx={cssObject.showLess}>Show Less</Grid>
            </Grid>
        );
    };

    const billingCartSummary = (isSmDown: boolean) => {
        return (
            //conditionally render cart summary based on multipleProjectsEnable
            multipleProjects ? (
                <ContentContainer disableGrid background={isXs ? 'bg3' : 'bg2'}>
                    <CartSummary
                        subTotal={order.subtotal}
                        taxes={order.taxAmount}
                        orderItems={orderItems}
                        discount={order.discountAmount}
                        grandTotal={order.grandTotal}
                    />
                </ContentContainer>
            ) : (
                <ContentContainer disableGrid background={isXs ? 'bg3' : 'bg2'}>
                    <CartSummary
                        items={cartItems}
                        subTotal={summarySubtotal}
                        taxes={taxesAverage}
                        discount={discount || 0}
                        grandTotal={prices?.grandTotal}
                        billingPage={isSmDown ? true : false}
                    />
                </ContentContainer>
            )
        );
    };

    const BillingOrderSummary = () => {
        return (
            <Grid item xs={12} md={4} lg={3}>
                {balanceAmount && (
                    <MinimisedCartSummary
                        text={'Pending Amount'}
                        amount={balanceAmount}
                        collapse={collapseCartSummary}
                        handleExpand={() => {
                            setExpandCartSummary(true);
                            setCollapseCartSummary(false);
                        }}
                    />
                )}
                {expandCartSummary && !collapseCartSummary && (
                    <ContentContainer disableGrid background={'bg2'}>
                        <br />
                        <CartSummary
                            subTotal={order.subtotal}
                            taxes={order.taxAmount}
                            orderItems={orderItems}
                            discount={order.discountAmount}
                            grandTotal={order.grandTotal}
                        />
                        <ShowLess />
                    </ContentContainer>
                )}
            </Grid>
        );
    };
    return (
        <ContentContainer disableGrid sx={cssObject.root}>
            <LoadingOverlay open={props.loading || loader} />
            <ContentContainer>
                <Grid container spacing={2}>
                    {/* Promocode and Cartsummary for smaller devices */}
                    {isMdDown &&
                        (!balanceAmountPending ? (
                            <Grid container item xs={12}>
                                {!multipleOpenProjects && (
                                    <Grid item xs={12} sx={cssObject.promoCodeContent}>
                                        <PromoCode onClick={(loaderState) => setLoader(loaderState)} />
                                    </Grid>
                                )}

                                <Grid item xs={12}>
                                    {billingCartSummary(true)}
                                </Grid>
                            </Grid>
                        ) : (
                            <BillingOrderSummary />
                        ))}
                    <Grid item xs={12} md={8} lg={9}>
                        <ContentContainer disableGrid sx={cssObject.tabHeaderContainer}>
                            <Box
                                onClick={(e) => {
                                    handleChangeIndex(0, e);
                                }}
                                sx={[
                                    cssObject.tabHeaderStyle,
                                    selectedTab === 0 && cssObject.tabSelected,
                                    selectedTab === 1 && cssObject.tabNonSelected,
                                    balanceAmountPending === true && cssObject.tabHide,
                                    {
                                        cursor: balanceAmountPending ? 'default' : 'pointer',
                                    },
                                ]}
                            >
                                <Box sx={cssObject.tabHeadingContent}>
                                    <Box
                                        sx={[
                                            cssObject.iconStyle,
                                            selectedTab === 0 && cssObject.icons,
                                            selectedTab === 1 && cssObject.iconsNotSelected,
                                        ]}
                                    >
                                        <CurrencyIcon />
                                    </Box>
                                    <Box sx={cssObject.centerColumn}>
                                        <Box sx={cssObject.tabheadingText}>
                                            <Paragraph responsiveFontSize20 bold headingStyle>
                                                Payment
                                            </Paragraph>
                                        </Box>
                                        <Box sx={cssObject.tabSubheading}>
                                            <Paragraph fontSizeVerySmall>Pay today</Paragraph>
                                        </Box>
                                    </Box>
                                </Box>
                            </Box>

                        </ContentContainer>

                        <Box sx={cssObject.swipeViews}>
                            {selectedTab === 0 && (
                                <Box>
                                    <PaymentTab
                                        placeOrderFunction={handlePlaceOrder}
                                        placeFinalOrderFunction={handlePlaceFinalOrder}
                                    />
                                </Box>
                            )}
                        </Box>
                    </Grid>
                    {/* Promocode and cart summary for larger devices */}
                    {!isMdDown &&
                        (!balanceAmountPending ? (
                            <Grid item xs={12} md={4} lg={3}>
                                {/* PROMOCODE */}
                                {!multipleOpenProjects && (
                                    <>
                                        <PromoCode onClick={(loaderState) => setLoader(loaderState)} />
                                        <br />
                                    </>
                                )}

                                {billingCartSummary(false)}
                            </Grid>
                        ) : (
                            <BillingOrderSummary />
                        ))}
                </Grid>
            </ContentContainer>
        </ContentContainer>
    );
}
