import { reducer, on } from 'ts-action';
import { getContentBlock, getContentBlockSuccess, getContentBlockError } from '../actions/storeActions';
import { CmsBlock } from '../../models/storeConfig';

export interface ContentState {
    block?: CmsBlock;
    error?: Error | null;
    loading: boolean;
}

const initialState: ContentState = {
    loading: false,
    block: {
        id: 0,
        identifier: 'promo_code',
        title: 'Promotion',
        content:
            'All of our HVAC systems include Installation, Equipment assembled in the USA, 1-Year Labor Warranty, and 100% Homeowner Satisfaction Guaranteed!',
        creation_time: '',
        update_time: '',
        active: true,
    },
};

export const contentReducer = reducer(
    initialState,
    on(getContentBlock, (state) => ({
        ...state,
        loading: true,
        error: null,
    })),
    on(getContentBlockSuccess, (state, action) => ({
        ...state,
        block: action.payload,
        loading: false,
    })),
    on(getContentBlockError, (state, action) => ({
        ...state,
        error: action.payload,
        loading: false,
    }))
);

export default contentReducer;
