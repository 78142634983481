import { useTheme } from '@mui/material/styles';
import { getThemePrimaryMain } from 'components/common/Utilities';
import * as React from 'react';

function BackIcon(props: any) {
    const theme = useTheme();
    const themePrimaryMain = getThemePrimaryMain(theme);
    return (
        <svg width={20} height={16} xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                d="M19.216 7.22H2.676l5.92-5.887a.781.781 0 000-1.105.789.789 0 00-1.11 0L.228 7.448c-.027.028-.039.063-.062.094-.038.051-.08.1-.106.16a.759.759 0 00-.054.263C.005 7.976 0 7.988 0 8c0 .012.005.024.006.035.004.09.02.18.054.263.025.06.068.109.106.16.023.031.035.066.062.094l7.258 7.22a.786.786 0 00.556.228.781.781 0 00.555-1.331l-5.92-5.89h16.539c.433 0 .784-.348.784-.779 0-.43-.351-.78-.784-.78"
                fill={`${themePrimaryMain}`}
                fillRule="evenodd"
            />
        </svg>
    );
}

export default BackIcon;
