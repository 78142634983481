import React, { PropsWithChildren } from 'react';
import { Theme, useTheme } from '@mui/material/styles';
import { Box, ClickAwayListener, Grid, Tooltip } from '@mui/material';
import TooltipIcon from './icons/TooltipIcon';
import { SXCssObject } from 'models/ThemeImages';
import ScheduleInfoIcon from 'assets/icons/icon_info_schedule.svg';
import { Button } from './Button';

interface Props {
    hideInfoIcon?: any;
    toolTipText?: string;
    quoteTip?: boolean;
}

const createCssObject = (theme: Theme, props: PropsWithChildren<Props>): SXCssObject => ({
    root: {
        height: '40px',
        padding: props.hideInfoIcon ? theme.spacing(1, 5) : theme.spacing(1, 6.3, 1, 4),
        minWidth: theme.spacing(10),
        width: {
            xs: '100%',
            sm: 'inherit',
        },
        '&:hover': {
            background: theme.palette.primary.dark,
        },
        whiteSpace: 'nowrap',
    },
    container: {
        display: 'flex',
    },
    tooltip: {
        backgroundColor: theme.palette.primary.light,
        borderRadius: '0px',
        fontSize: '1.875rem',
        padding: '20px',
        lineHeight: '1.25rem',
        pointerEvents: 'auto',
        display: 'flex',
    },
    tooltipParagraph: {
        textAlign: 'justify',
        fontWeight: 400,
        letterSpacing: '0px',
        color: theme.typography.h1.color,
        fontSize: {
            xs: '0.875rem',
            lg: '1rem',
        },
        lineHeight: {
            lg: '1.375rem',
        },
    },
    tolltipHeading: {
        textAlign: 'left',
        fontSize: '1.4375rem',
        letterSpacing: '0px',
        color: theme.typography.h1.color,
        paddingBottom: '10px',
        display: 'flex',
        alignItems: 'center',
    },
    infoIconContainer: {
        zIndex: 2,
    },
    hideContainer: {
        display: 'none',
    },
    infoIconStyle: {
        backgroundColor: theme.palette.action.hover,
        border: '4px solid #FFFFFF',
        borderRadius: '20px',
        marginLeft: `${-14 - Number(theme.shape.borderRadius)}px`,
        width: '40px',
        padding: '3px',
        cursor: 'pointer',
    },
    tooltipButtonContainer: {
        display: 'flex',
        alignItems: 'center',
        paddingTop: '10px',
        justifyContent: {
            xs: 'left',
            sm: 'flex-end',
        },
    },
});

export default function TooltipSchedule(props: PropsWithChildren<Props>) {
    const cssObject: SXCssObject = createCssObject(useTheme(), props);
    const [toolTip, setToolTip] = React.useState(false);
    const handleTooltipOpen = () => {
        setToolTip(true);
    };

    const handleTooltipClose = () => {
        setToolTip(false);
    };
    const tooltipContent = (text?: string) => (
        <Box sx={cssObject.tooltip}>
            <Grid container>
                {!props.quoteTip && (
                    <Grid item xs={12}>
                        <Box sx={cssObject.tolltipHeading}>
                            <TooltipIcon /> &nbsp; Scheduling Tip
                        </Box>
                    </Grid>
                )}
                <Grid item xs={12}>
                    <Box sx={cssObject.tooltipParagraph}>
                        {!!text && <div dangerouslySetInnerHTML={{ __html: text }}></div>}
                    </Box>
                </Grid>
                <Grid sx={cssObject.tooltipButtonContainer} item xs={12}>
                    <Button onClick={handleTooltipClose} isContrast isTransparent>
                        GOT IT
                    </Button>
                </Grid>
            </Grid>
        </Box>
    );
    return (
        <ClickAwayListener onClickAway={handleTooltipClose}>
            <Tooltip
                title={tooltipContent(props.toolTipText)}
                open={toolTip}
                arrow
                onClose={handleTooltipClose}
                onOpen={handleTooltipOpen}
                placement="top-end"
                PopperProps={{
                    disablePortal: true,
                }}
                disableFocusListener
                disableHoverListener
                disableTouchListener
                componentsProps={{
                    tooltip: {
                        sx: {
                            padding: '0',
                            maxWidth: {
                                xs: '100%',
                                sm: '520px',
                            },
                            fontWeight: 400,
                            backgroundColor: 'primary.light',
                            borderStyle: 'solid',
                            borderWidth: '1px',
                            borderColor: 'primary.contrastText',
                            borderRadius: 0,
                        },
                    },
                    arrow: {
                        sx: {
                            color: 'primary.light',
                            fontSize: '25px',
                            '&:before': {
                                borderStyle: 'solid',
                                borderWidth: '1px',
                                borderColor: 'primary.contrastText',
                            },
                        },
                    },
                }}
            >
                <Box sx={[cssObject.infoIconContainer, props.hideInfoIcon && cssObject.hideContainer]}>
                    <Box
                        component={'img'}
                        src={ScheduleInfoIcon}
                        onClick={handleTooltipOpen}
                        sx={cssObject.infoIconStyle}
                        alt={'Info'}
                    />
                </Box>
            </Tooltip>
        </ClickAwayListener>
    );
}
