import React, { MouseEventHandler } from 'react';
import { Theme, useTheme } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import ContentContainer from './ContentContainer';
import { Heading } from './Heading';
import { Paragraph } from './Paragraph';
import CardMedia from '@mui/material/CardMedia';
import Hidden from '@mui/material/Hidden';
import { CloseButtonNew } from './WizardButton';
import { Box, Container, Drawer, useMediaQuery } from '@mui/material';
import VideoBg from './icons/VideoBg';
import { SXCssObject } from 'models/ThemeImages';

const createCssObject = (theme: Theme): SXCssObject => {
    return {
        root: {
            overflowY: 'hidden',
            maxHeight: '100%',
            paddingTop: theme.spacing(0),
            px: {
                xs: theme.spacing(0),
                sm: 'inherit',
            },
            backgroundSize: 'cover',
            backgroundPosition: 'right',
            backgroundColor: '#ffffff',
        },
        heading: {
            paddingTop: theme.spacing(0),
            fontSize: '20px',
            paddingBottom: {
                xs: theme.spacing(0),
                sm: theme.spacing(1),
            },
            px: {
                xs: theme.spacing(1.5),
                sm: 'inherit',
            },
        },
        paragraph: {
            paddingBottom: theme.spacing(2),
            px: {
                xs: theme.spacing(1.5),
                sm: 'inherit',
            },
        },
        vdo: {
            paddingBottom: {
                xs: theme.spacing(5),
                sm: theme.spacing(4),
            },
        },
        vdoOverlay: {
            position: 'relative',
        },
        closeIcon: {
            paddingRight: theme.spacing(2),
            paddingTop: theme.spacing(2),
            cursor: 'pointer',
            position: 'relative',
        },
        videoPadding: {
            position: 'relative',
            px: {
                xs: theme.spacing(0),
                sm: 'inherit',
            },
        },
    };
};
export default function VideoOverlay(props: Props) {
    const cssObject: SXCssObject = createCssObject(useTheme());
    const theme = useTheme();
    const isXs = useMediaQuery(theme.breakpoints.only('xs'));
    const isSm = useMediaQuery(theme.breakpoints.only('sm'));
    const isMd = useMediaQuery(theme.breakpoints.up('md'));

    return (
        <Drawer anchor={'top'} open={props.open} onClose={props.onClose}>
            <ContentContainer disableGrid background={'bg2'} sx={cssObject.root}>
                <VideoBg style={{ position: 'absolute', width: '100%' }} />
                <Grid container style={{ overflowX: 'hidden' }}>
                    <Grid
                        item
                        xs={12}
                        container
                        justifyContent={'flex-end'}
                        alignItems={'flex-start'}
                        onClick={props.onClose}
                        sx={cssObject.closeIcon}
                    >
                        <CloseButtonNew id={'menu-close-ico'} square />
                    </Grid>
                    <Container disableGutters>
                        <ContentContainer sx={cssObject.videoPadding}>
                            <Box sx={[cssObject.vdoOverlay, { flexDirection: 'column' }]}>
                                <Hidden mdUp>
                                    <div style={{ position: 'absolute', top: '4%' }}>
                                        <Grid item sx={cssObject.heading}>
                                            <Heading variant={isXs ? 'h4' : isSm ? 'h3' : 'h2'} label={props.heading} />
                                        </Grid>
                                        {props.paragraph && (
                                            <Grid item sx={cssObject.paragraph}>
                                                <Paragraph
                                                    color={'primary'}
                                                    size={isMd ? 'normal' : 'small'}
                                                    fontSizeVerySmall={isXs}
                                                >
                                                    {props.paragraph}
                                                </Paragraph>
                                            </Grid>
                                        )}
                                    </div>
                                </Hidden>
                                <Hidden smDown>
                                    <Grid item sx={cssObject.heading}>
                                        <Heading variant={isXs ? 'h4' : isSm ? 'h3' : 'h2'} label={props.heading} />
                                    </Grid>
                                    {props.paragraph && (
                                        <Grid item sx={cssObject.paragraph}>
                                            <Paragraph
                                                color={'primary'}
                                                size={isMd ? 'normal' : 'small'}
                                                fontSizeVerySmall={isXs}
                                            >
                                                {props.paragraph}
                                            </Paragraph>
                                        </Grid>
                                    )}
                                </Hidden>
                                <Grid item>
                                    <CardMedia
                                        sx={cssObject.vdo}
                                        src={props.src}
                                        component={'video'}
                                        autoPlay
                                        controls
                                        preload={'auto'}
                                    />
                                </Grid>
                            </Box>
                        </ContentContainer>
                    </Container>
                </Grid>
            </ContentContainer>
        </Drawer>
    );
}

export interface Props {
    id?: string;
    open: boolean;
    heading: any;
    paragraph?: any;
    src: string;
    onClose: MouseEventHandler;
}
