import React, { useState, useEffect } from 'react';
import ContentContainer from 'components/ContentContainer';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import { Theme, useTheme } from '@mui/material/styles';
import { Heading } from 'components/Heading';
import { Paragraph } from 'components/Paragraph';
import CartSummary from 'components/CartSummary';
import { Order, OrderedItems, OrderStatus } from 'models/order';
import { getProductImage, ThemeWithAdditionalData } from 'themes/theme';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Box, Hidden, Typography } from '@mui/material';
import OrderProductDetail from 'components/OrderProductDetail';
import { useNavigate } from 'react-router-dom';
import Navigator from 'components/Navigator';
import { AppRoute } from 'models/route';
import { useDispatch, useSelector } from 'react-redux';
import { getOrderSuccessAndRedirectToBilling, getOrder } from 'store/actions/orderActions';
import { AddressType } from 'models/cart';
import { OrderAddressComponent } from 'components/AddressLayoutForCartAndBilling';
import { Button } from 'components/Button';
import MinimisedCartSummary from 'components/MinimisedCartSummary';
import { getOrderStatus, showPayNow } from 'pages/order/order-utils';
import ArrowDown3 from 'components/icons/ArrowDown3';
import { SXCssObject } from 'models/ThemeImages';
import BackArrowIcon from 'components/icons/BackArrowIcon';
import { NextStepsModal } from './NextStepsModal';
import { selectMultipleOpenProjects } from 'store/selectors/storeSelector';

const createCssObject = (theme: Theme): SXCssObject => ({
    header: {
        pt: { xs: 2.5, sm: 4, md: 6 },
        pb: { xs: 1.5, sm: 2, md: 4 },
    },
    header1: {
        pt: { xs: 2.5, sm: 4 },
        pb: 1.5,
    },
    orderItemsStyle: {
        pb: {
            xs: 2,
            sm: 3,
            lg: 4,
        }
    },
    subhead: {
        paddingLeft: theme.spacing(2),
        borderBottom: `2px solid #EAEAF0`,
        marginTop: '10px',
        pt: { xs: 2.5, sm: 2, lg: 1 },
        pb: { xs: 1.5, sm: 2, lg: 1 },
    },
    footer: {
        paddingTop: theme.spacing(3),
        paddingBottom: theme.spacing(3),
    },
    nav: {
        paddingTop: theme.spacing(3),
        paddingBottom: theme.spacing(3),
    },
    nextStepBox: {
        padding: theme.spacing(2),
        backgroundColor: '#FFFFFF',
        marginTop: '20px',
    },

    divider: {
        paddingBottom: theme.spacing(4),
    },
    summary: {
        mt: {
            xs: '20px',
            md: 0,
        },
        mb: 4,
    },
    content: {
        marginTop: '40px',
        marginBottom: '40px',
    },
    cartStatusIconStyle: {
        border: '3px solid #DC5226',
        width: '14px',
        height: '14px',
        borderRadius: '50%',
        marginRight: '5px',
    },
    cartStatusContainer: {
        display: 'flex',
    },
    cartStatusSection: {
        margin: {
            xs: '10px 0px',
            sm: 'inherit',
        },
    },
    cartStatusLabel: {
        justifyContent: {
            sm: 'flex-end',
        },
        display: 'flex',
        color: '#848484',
        marginBottom: '5px', //reduced 5px because editicon has padding
    },
    cartStatusContent: {
        display: 'flex',
        alignItems: 'center',
        marginTop: {
            sm: '8px',
        },
    },
    cartStatusTextStyle: {
        fontWeight: theme.typography.fontWeightMedium,
        fontSize: '16px',
    },
    HeadingNavRoot: {
        width: '100%',
        height: '80px',
        boxShadow: '0px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        py: { xs: '20px !important', sm: 2 },
    },
    b: {
        fontWeight: theme.typography.fontWeightMedium,
    },
    reverseArrowIcon: {
        transform: 'rotate(180deg)',
        cursor: 'pointer',
    },
    showLess: {
        color: theme.palette.text.primary,
        marginLeft: '5px',
    },
    showLessContainer: {
        backgroundColor: theme.palette.primary.light,
        paddingLeft: '20px',
        border: `1px solid ${theme.palette.primary.contrastText}`,
        borderTop: 'none',
        height: '70px',
    },
    backIconSection: {
        cursor: 'pointer',
        m: { xs: '10px 40px 10px 0px', sm: '0px 60px 20px 0px' },
        display: 'inline-flex',
        alignItems: 'center',
    },
});

interface Props {
    order: Order;
    orderItems: OrderedItems;
}

function Confirmation(props: Props) {
    const cssObject: SXCssObject = createCssObject(useTheme());
    const multipleOpenProjects = useSelector(selectMultipleOpenProjects);
    const theme = useTheme<ThemeWithAdditionalData>();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { order, orderItems } = props;
    const isEquipmentOnly = React.useMemo(() => {
        if (order && order.items) {
            return (order?.items[0]?.product?.sku).includes('-EO');
        }
    }, []);
    const isXs = useMediaQuery(theme.breakpoints.only('xs'));
    const itemsFirst = [
        ...(orderItems.firstSystemMain ? [orderItems.firstSystemMain] : []),
        ...(orderItems.firstSystemIAQ ? [orderItems.firstSystemIAQ] : []),
    ];

    const itemsSecond = [
        ...(orderItems.secondSystemMain ? [orderItems.secondSystemMain] : []),
        ...(orderItems.secondSystemIAQ ? [orderItems.secondSystemIAQ] : []),
    ];

    const itemsAddons = orderItems.addOnsList ? [...orderItems.addOnsList] : [];
    const shippingAddress = order.shippingAddress;
    const billingAddress = order.billingAddress;
    const id = order.id;
    const orderId = order.incrementId;

    const balanceAmountPending = order && order.status && order.status === OrderStatus.PENDING_PAYMENT;
    const [expandCartSummary, setExpandCartSummary] = useState(false);
    const [collapseCartSummary, setCollapseCartSummary] = useState(true);

    const [isPopUpVisible, setIsPopUpVisible] = useState(false);

    useEffect(() => {
        id && dispatch(getOrder({ id: id }));
    }, [dispatch, id]);

    const handleBalancePayment = () => {
        id && orderId && dispatch(getOrderSuccessAndRedirectToBilling(order));
    };
    const ShowLess = () => {
        return (
            <Grid container sx={cssObject.showLessContainer} alignItems={'center'}>
                <Grid item style={{ display: 'flex' }}>
                    <ArrowDown3
                        onClick={() => {
                            setCollapseCartSummary(true);
                        }}
                        style={cssObject.reverseArrowIcon}
                    />
                </Grid>
                <Grid sx={cssObject.showLess}>Show Less</Grid>
            </Grid>
        );
    };
    const PendingOrderCartSummary = () => {
        return (
            <Grid sx={cssObject.summary} item xs={12} md={4} lg={3}>
                {order.totalDue && (
                    <MinimisedCartSummary
                        text={'Pending Amount'}
                        amount={order.totalDue}
                        collapse={collapseCartSummary}
                        handleExpand={() => {
                            setExpandCartSummary(true);
                            setCollapseCartSummary(false);
                        }}
                    />
                )}
                {expandCartSummary && !collapseCartSummary && (
                    <div>
                        <CartSummary
                            subTotal={order.subtotal}
                            taxes={order.taxAmount}
                            orderItems={orderItems}
                            discount={order.discountAmount}
                            grandTotal={order.grandTotal}
                        />
                        <ShowLess />
                    </div>
                )}
            </Grid>
        );
    };
    const CompleteOrderCartSummary = () => {
        return (
            <Grid sx={cssObject.summary} item xs={12} md={4} lg={3}>
                <CartSummary
                    subTotal={order.subtotal}
                    taxes={order.taxAmount}
                    orderItems={orderItems}
                    discount={order.discountAmount}
                    grandTotal={order.grandTotal}
                />
            </Grid>
        );
    };
    return (
        <ContentContainer disableGrid background={'bg2'}>
            <NextStepsModal
                open={isPopUpVisible ? true : false}
                handleClose={() => {
                    setIsPopUpVisible(false);
                }}
                isEquipmentOnly={isEquipmentOnly || false}
            />
            <ContentContainer disableGrid background={'bg3'}>
                <ContentContainer>
                    <Navigator color={'primary'} transparent={false} hideCart />
                </ContentContainer>
            </ContentContainer>
            <Divider />
            <ContentContainer disableGrid>
                <ContentContainer sx={cssObject.header}>
                    <Box sx={cssObject.backIconSection} onClick={() => navigate(AppRoute.MyOrders)}>
                        <Paragraph semibold size={'normal'} sx={{ display: 'flex', alignItems: 'center' }}>
                            <BackArrowIcon style={{ marginRight: 6 }} />
                            <Box component={'span'} sx={cssObject.backButtonText}>
                                BACK TO MY ORDERS
                            </Box>
                        </Paragraph>
                    </Box>
                    <Grid item xs={12} container alignItems={'center'} justifyContent={'space-between'}>
                        <Grid item xs={12} sm={6} md={8}>
                            <Heading label={'Order Details'} />
                        </Grid>
                        <Grid
                            item
                            xs={12}
                            sm={3}
                            md={2}
                            sx={cssObject.cartStatusSection}
                            container
                            justifyContent={isXs ? 'flex-start' : 'flex-end'}
                        >
                            <Grid sx={cssObject.cartStatusContainer}>
                                <Grid>
                                    <Typography variant={'body2'} sx={cssObject.cartStatusLabel}>
                                        Order Number
                                    </Typography>
                                    <ContentContainer disableGrid sx={cssObject.cartStatusContent}>
                                        <Grid sx={cssObject.cartStatusTextStyle}>{orderId}</Grid>
                                    </ContentContainer>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid
                            item
                            xs={12}
                            sm={3}
                            md={2}
                            sx={cssObject.cartStatusSection}
                            container
                            justifyContent={isXs ? 'flex-start' : 'flex-end'}
                        >
                            <Grid sx={cssObject.cartStatusContainer}>
                                <Grid>
                                    <Typography variant={'body2'} sx={cssObject.cartStatusLabel}>
                                        Order Status
                                    </Typography>
                                    <ContentContainer disableGrid sx={cssObject.cartStatusContent}>
                                        <ContentContainer
                                            disableGrid
                                            sx={cssObject.cartStatusIconStyle}
                                        ></ContentContainer>
                                        <Grid sx={cssObject.cartStatusTextStyle}>
                                            {order.status && getOrderStatus(order.status)}{' '}
                                        </Grid>
                                    </ContentContainer>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </ContentContainer>
            </ContentContainer>
            <ContentContainer>
                <Grid container>
                    <Grid item xs={12} container>
                        <Grid container spacing={2}>
                            {shippingAddress && (
                                <OrderAddressComponent flow={AddressType.Home} address={shippingAddress} />
                            )}
                            {billingAddress && (
                                <OrderAddressComponent flow={AddressType.Billing} address={billingAddress} />
                            )}
                        </Grid>
                    </Grid>
                </Grid>
            </ContentContainer>
            <ContentContainer>
                <Grid container sx={cssObject.header1}>
                    <Grid item xs={12} container spacing={2}>
                        <Grid item xs={12}>
                            <Paragraph semibold responsiveFontSize22>
                                Order Items
                            </Paragraph>
                            <div id={'order_ref'} data-id={order.id} data-number={order.incrementId} />
                        </Grid>
                    </Grid>
                </Grid>
            </ContentContainer>
            <ContentContainer sx={cssObject.orderItemsStyle}>
                <ContentContainer disableGrid background={'bg2'}>
                    <Grid container direction={'column'}>
                        <Grid container item spacing={2} justifyContent="space-between">
                            <Grid item xs={12} md={8} lg={9}>
                                {itemsFirst && (
                                    <ContentContainer disableGrid background={'bg3'}>
                                        <Grid container sx={cssObject.subhead} style={{ marginTop: '0px' }}>
                                            <Grid item xs={12} container spacing={2}>
                                                <Grid item xs={12}>
                                                    <Paragraph semibold responsiveFontSize18>
                                                        First System{' '}
                                                    </Paragraph>
                                                    <div
                                                        id={'order_ref'}
                                                        data-id={order.id}
                                                        data-number={order.incrementId}
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </ContentContainer>
                                )}
                                {itemsFirst &&
                                    itemsFirst?.map((item, index) => {
                                        return (
                                            item && (
                                                <Grid item xs={12} key={index}>
                                                    <OrderProductDetail
                                                        image={getProductImage(item.product)}
                                                        description={item.product.description}
                                                        level={item.product.productAttributes.level}
                                                        price={item.product.price}
                                                        sku={item.product.sku}
                                                        quantity={item.qtyOrdered}
                                                        name={item.product.name}
                                                        itemType={item.product.itemType}
                                                    />
                                                </Grid>
                                            )
                                        );
                                    })}
                                {itemsSecond && itemsSecond.length > 0 && (
                                    <ContentContainer disableGrid background={'bg3'}>
                                        <Grid container sx={cssObject.subhead}>
                                            <Grid item xs={12} container spacing={2}>
                                                <Grid item xs={12}>
                                                    <Paragraph semibold responsiveFontSize18>
                                                        Second System{' '}
                                                    </Paragraph>
                                                    <div
                                                        id={'order_ref'}
                                                        data-id={order.id}
                                                        data-number={order.incrementId}
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </ContentContainer>
                                )}
                                {itemsSecond &&
                                    itemsSecond.length > 0 &&
                                    itemsSecond?.map((item, index) => {
                                        return (
                                            item && (
                                                <Grid item xs={12} key={index}>
                                                    <OrderProductDetail
                                                        image={getProductImage(item.product)}
                                                        description={item.product.description}
                                                        level={item.product.productAttributes.level}
                                                        price={item.product.price}
                                                        sku={item.product.sku}
                                                        quantity={item.qtyOrdered}
                                                        name={item.product.name}
                                                        itemType={item.product.itemType}
                                                    />
                                                </Grid>
                                            )
                                        );
                                    })}
                                {itemsAddons && itemsAddons.length > 0 && (
                                    <ContentContainer disableGrid background={'bg3'}>
                                        <Grid container sx={cssObject.subhead}>
                                            <Grid item xs={12} container spacing={2}>
                                                <Grid item xs={12}>
                                                    <Paragraph semibold responsiveFontSize18>
                                                        Other Products
                                                    </Paragraph>
                                                    <div
                                                        id={'order_ref'}
                                                        data-id={order.id}
                                                        data-number={order.incrementId}
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </ContentContainer>
                                )}
                                {itemsAddons &&
                                    itemsAddons.length > 0 &&
                                    itemsAddons?.map((item, index) => {
                                        return (
                                            <Grid item xs={12} key={index}>
                                                <OrderProductDetail
                                                    image={getProductImage(item.product)}
                                                    description={item.product.description}
                                                    level={item.product.productAttributes.level}
                                                    price={item.product.price}
                                                    sku={item.product.sku}
                                                    quantity={item.qtyOrdered}
                                                    name={item.product.name}
                                                    itemType={item.product.itemType}
                                                />
                                            </Grid>
                                        );
                                    })}
                                <Hidden mdUp>
                                    {balanceAmountPending ? <PendingOrderCartSummary /> : <CompleteOrderCartSummary />}
                                </Hidden>
                            </Grid>
                            <Hidden mdDown>
                                {balanceAmountPending ? <PendingOrderCartSummary /> : <CompleteOrderCartSummary />}
                            </Hidden>
                        </Grid>
                    </Grid>
                </ContentContainer>
            </ContentContainer>
            <ContentContainer background={'footerWhite'} sx={cssObject.HeadingNavRoot}>
                {order.status && showPayNow(order.status) && (
                    <Box display={'flex'} justifyContent={'flex-end'}>
                        <Button
                            id={`Procced-to-Pay`}
                            color="primary"
                            onClick={handleBalancePayment}
                        >
                            Procced to Pay
                        </Button>
                    </Box>
                )}
                {multipleOpenProjects && (
                    <Box display={'flex'} justifyContent={'flex-end'}>
                        <Button
                            id={`Next-Steps`}
                            color="primary"
                            sx={{ ml: 2 }}
                            onClick={() => setIsPopUpVisible(true)}
                        >
                            Next Steps
                        </Button>
                    </Box>
                )}
            </ContentContainer>
        </ContentContainer>
    );
}

export default Confirmation;
