import { Action } from 'ts-action';
import { ofType } from 'ts-action-operators';
import { EMPTY, Observable } from 'rxjs';
import { ignoreElements, map, mergeMap, tap, withLatestFrom } from 'rxjs/operators';
import ReactGA from 'react-ga4';
import TagManager from 'react-gtm-module';
import { RootState } from '../reducers';
import config from '../../config';
import { Dependencies } from '../index';
import { appInitial } from '../actions/appActions';
import { selectCart } from '../selectors/cart';
import { selectOrder } from '../selectors/order';
import { selectProductCriteriaState } from '../selectors/productCriteria';
import { selectProductSearchState } from '../selectors/productSearch';
import { trackAction } from 'components/Analytics/GoogleAnalyticsEcommerce';

export const googleAnalytic$ = (
    action$: Observable<Action>,
    state$: Observable<RootState>,
    { history }: Dependencies
) =>
    action$.pipe(
        ofType(appInitial),
        mergeMap(() => {
            if (!config.googleAnalyticId) {
                return EMPTY;
            }
            ReactGA.initialize(config.googleAnalyticId);
            if (config.googleTagManagerId) {
                TagManager.initialize({ gtmId: config.googleTagManagerId });
            }
            return action$.pipe(
                withLatestFrom(
                    state$.pipe(map(selectCart)),
                    state$.pipe(map(selectOrder)),
                    state$.pipe(map(selectProductCriteriaState)),
                    state$.pipe(map(selectProductSearchState))
                ),
                tap(([action, cart, order, productCriteria, productSearch]) => {
                    trackAction(action, cart, order, productCriteria, productSearch);
                })
            );
        }),
        ignoreElements()
    );
