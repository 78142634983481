export enum AppRoute {
    Home = '/home',
    KnowledgeCenter = '/knowledge-center/what-is-our-process',
    AirConditioners = '/knowledge-center/air-conditioners',
    AirHandlers = '/knowledge-center/air-handlers',
    Furnaces = '/knowledge-center/furnaces',
    HeatPumps = '/knowledge-center/heat-pumps',
    HowHvacWorks = '/knowledge-center/how-hvac-works',
    PackagedUnits = '/knowledge-center/packaged-units',
    WhatIsOurProcess = '/knowledge-center/what-is-our-process',
    IndoorAirQuality = '/knowledge-center/indoor-air-quality',
    WhoWeAre = '/who-we-are',
    SatisfactionPledge = '/satisfaction-pledge',
    ContactUs = '/contact-us',
    EnergyStar = '/energy-star',
    MadeInUsa = '/made-in-usa',
    SecurityAndPrivacyPolicy = '/security-privacy-policy',
    TermsAndConditions = '/terms-conditions',
    AboutYourHome = '/wizard/about-your-home',
    EquipmentOptions = '/equipment-options',
    IAQOptions = '/iaq-options',
    EquipmentSelection = '/equipment-selection',
    CartPage = '/cart',
    Schedule = '/schedule',
    AddOnPage = '/addons-page',
    Billing = '/payment-options',
    Confirmation = '/order-confirmation',
    MyOrders = '/my-orders',
    MyProfile = '/my-profile',
    MyQuotes = '/my-quotes',
    EditMyProfile = '/edit-my-profile',
    MyProperties = '/my-properties',
    MotiliFriendsAndFamily = '/motili'
}
