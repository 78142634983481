import React from 'react';
import { useTheme } from '@mui/material';
import { getThemePrimaryMain } from 'components/common/Utilities';
const IconDeleteNew = (props: any) => {
    const theme = useTheme();
    let color = getThemePrimaryMain(theme);
    if (props.color) {
        //Color can be passed in props dynamically; if color is not passed, use primary.main
        color = props.color;
    }
    return (
        <svg data-name="Component 44 \u2013 31" xmlns="http://www.w3.org/2000/svg" width={24} height={24} {...props}>
            <g data-name="Group 482">
                <g data-name="Group 481">
                    <path
                        data-name="Union 22"
                        d="M8.987 20.281a3.116 3.116 0 0 1-3.134-3.18c-.009-2.456 0-4.911 0-7.366V9.15h-.726A1.078 1.078 0 0 1 4 8.087a1.056 1.056 0 0 1 1.121-1.056h3.155V4.747a1.046 1.046 0 0 1 .041-.294 1.071 1.071 0 0 1 1.112-.852q2.714 0 5.433.008a1.066 1.066 0 0 1 1.113.872 1.073 1.073 0 0 1 .035.267c0 1.239.006 1.481 0 2.28h3.174a1.06 1.06 0 0 1 1.1 1.045 1.073 1.073 0 0 1-1.11 1.077h-.735c0 2.007.006 6.156 0 7.968a3 3 0 0 1-1.791 2.864 3.653 3.653 0 0 1-1.357.3q-1.576.013-3.152.013t-3.152-.014Zm4.213-2.114h1.969a1.031 1.031 0 0 0 1.145-1.154V9.155H13.2c.005 1.503.007 6.646 0 9.012ZM8.3 9.153c-.1 0-.195.009-.327.015V17.002a1.033 1.033 0 0 0 1.168 1.163h1.945V9.148H9.229c-.313.004-.623.004-.929.005Zm5.6-2.125V5.726H10.41v1.3h3.5Z"
                        fill={color}
                        stroke="rgba(0,0,0,0)"
                    />
                </g>
            </g>
        </svg>
    );
};

export default IconDeleteNew;
