import { Cart, PaymentMethod } from 'models/cart';
import { Order } from 'models/order';
import { action, payload } from 'ts-action';

export const setupPaypalButton = action(
    'PAYPAL::OPEN_POPUP',
    payload<{
        containerId: string;
        totalAmount: number;
        isOrderBased: boolean;
        placeOrderFunction: (cart: Cart, paymentMethod: PaymentMethod) => void;
        placeFinalOrderFunction: (order: Order, paymentMethod: PaymentMethod, hvFinalOrder?: boolean) => void;
        finalInstallment?: number,
        loadingPaypalButton?: boolean;
        cartProcessFunction: (loading: boolean, error?: Error) => void;
        brainTreeClientToken: string;
    }>()
);
export const setupPaypalButtonSuccess = action('PAYPAL::SUCCESS');
export const setupPaypalButtonError = action('PAYPAL::ERROR', payload<Error>());
