import React, { useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectMyOrders } from 'store/selectors/order';
import MyOrders from './MyOrders';

import { getMyOrders } from 'store/actions/orderActions';

function OrdersContainer() {
    const dispatch = useDispatch();
    const myOrders = useSelector(selectMyOrders);

    let mounted: any = useRef();
    React.useEffect(() => {
        if (!mounted.current) {
            mounted.current = true;
            dispatch(getMyOrders());
        }
    });
    return <MyOrders myOrders={myOrders} />;
}

export default OrdersContainer;
