import http from '../../index';
import { Cart, ContactInformation } from '../../models/cart';
import { PageViewRequest } from 'models/contact';

export const createContact = (contact: ContactInformation) => http().post<ContactInformation>('/contacts', contact);
export const contactInprogress = (contact: ContactInformation) =>
    http().post<ContactInformation>('/contacts/inprogress', contact);
export const contactQuotedOptions = (contact: ContactInformation) =>
    http().post<ContactInformation>('/contacts/quotedOptions', contact);
export const contactOpendeal = (cart: Cart) => http().post<Cart>('/contacts/opendeal', cart);
export const contactPageView = (email: PageViewRequest) => http().post('/contacts/pageView', email);
