import React, { useState } from 'react';
import { Theme, useTheme } from '@mui/material/styles';
import { useSelector } from 'react-redux';
import Box from '@mui/material/Box';
import { Cart, CartStatus, PaymentMethod } from 'models/cart';
import { selectCart, selectCartPrice } from 'store/selectors/cart';
import { BrainTree, PaymentConstants, PaymentTypes } from 'models/financial';
import CreditCardTab from 'assets/icons/icon_tab_creditcard.svg';
import PaypalTab from 'assets/icons/icon_tab_paypal.svg';
import selectedPaymentIcon from 'assets/icons/icon_selected_address.svg';
import { selectOrder } from 'store/selectors/order';
import { Order, OrderStatus } from 'models/order';
import { SXCssObject } from 'models/ThemeImages';
import { showPayNow } from 'pages/order/order-utils';
import { selectMultipleOpenProjects } from 'store/selectors/storeSelector';
import PartialPaymentContainer from './PartialPayment';
import { calculateRoundedPercent } from 'components/common/NumberUtil';
import CreditCard from './CreditCard';
import Paypal from './Paypal';
import { setupCheckOutAsync } from 'store/services/cartServices';
import LoadingOverlay from 'components/common/LoadingOverlay';
import { showSnackbarError } from 'components/common/Snackbar/SnackbarHelper';
import OrderConfirmationInfo from './OrderConfirmationInfo';

const createCssObject = (theme: Theme): SXCssObject => ({
    paymentTabPage: {
        p: { xs: 1.5, sm: 2, md: 2.7 },
        pt: { xs: 0, sm: 0, md: 0.5 },
    },
    icons: {
        mr: { xs: 1, sm: 1.6, md: 1.6 },
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
    },
    tabPanelText: {
        fontSize: '1rem',
        fontWeight: theme.typography.fontWeightMedium,
    },
    wizardSection: {
        marginTop: '16px',
        display: 'inline-flex',
    },
    paymentTabStyle: {
        WebkitTapHighlightColor: 'transparent',
        marginTop: '6px',
        padding: '4px 20px',
        border: `1px solid ${theme.palette.action.disabled}`,
        borderRadius: '4px',
        color: theme.palette.common.white,
        cursor: 'pointer',
        '&:hover': {
            border: `1px solid ${theme.palette.action.hover}`,
        },
    },
    selectedPaymentIconWrapperStyle: {
        float: 'right',
        width: '8px',
        height: '8px',
    },
    selectedPaymentIconStyle: {
        height: '16px',
        width: '16px',
    },
    selectedPaymentStyle: {
        border: `1px solid ${theme.palette.action.hover}`,
    },
    divider: {
        height: '24px',
        marginLeft: '5px',
        backgroundColor: theme.customProperties.divider,
    },

    disablePaymentStyle: {
        backgroundColor: `${theme.palette.secondary.main}`,
        border: `1px solid ${theme.palette.action.disabled}`,
        '&:hover': {
            border: `1px solid ${theme.palette.action.disabled}`,
        },
        cursor: 'default',
    },
    submitBtnSection: {
        m: { xs: '30px 10px 10px 10px', sm: '24px 24px 24px 0px', md: '32px 24px 10px 0px' },
    },
});

interface PaymentProps {
    placeOrderFunction: (cart: Cart, paymentMethod: PaymentMethod) => void;
    placeFinalOrderFunction: (order: Order, paymentMethod: PaymentMethod, hvFinalOrder?: boolean) => void;
}

export default function PaymentTab(props: PaymentProps) {
    React.useEffect(() => {
        checkoutApi();
    }, []);
    async function checkoutApi() {
        setLoader(true);
        try {
            let response = await setupCheckOutAsync({
                code: PaymentConstants.BRAINTREE,
            });
            setBraintree(response.data);
            setLoader(false);
        } catch (err) {
            setLoader(false);
            showSnackbarError('Unable to setup checkout process');
        }
    }
    const [loader, setLoader] = useState<boolean>(false);
    const [braintree, setBraintree] = useState<BrainTree | undefined>({});
    const cssObject: SXCssObject = createCssObject(useTheme());
    const cartPrice = useSelector(selectCartPrice);
    const order = useSelector(selectOrder);
    const cart: Cart = useSelector(selectCart);
    const [paymentType, setPaymentType] = useState<PaymentTypes>(PaymentTypes.CREDIT_CARD);
    const brainTreeClientToken = braintree && braintree?.clientToken;
    const multipleOpenProjects = useSelector(selectMultipleOpenProjects);
    const OrderPaymentOnMultipleProjects = order.status && showPayNow(order.status) && multipleOpenProjects;
    const grandTotal = OrderPaymentOnMultipleProjects ? (order && order.totalDue) || 0 : (cartPrice && cartPrice?.grandTotal) || 0;
    //placeFinalOrderFunction- payment API
    //placeOrderFunction- placeOrder API
    const { placeFinalOrderFunction, placeOrderFunction } = props;

    const isEquipmentOnlyOrder = React.useMemo(() => {
        if (order && order.items) {
            return (order?.items[0]?.product?.sku).includes('-EO');
        }
    }, [order]);

    const initalPercent = (isEquipmentOnlyOrder || cart?.status === CartStatus.EQ_READY_FOR_PAYMENT) ? 100 : 50;
   

    const [partialPaymentPrice, setPartialPaymentPrice] = useState(calculateRoundedPercent(grandTotal * initalPercent));
    const orderTotalDue = (order && order.totalDue) || 0;
    const orderTotalPaid = (order && order.totalPaid) || 0;
    const orderTotal = order && order.grandTotal;

    const balanceAmountPending = order && order.status && order.status === OrderStatus.PENDING_PAYMENT;
    const pendingAmountPercent = orderTotalDue && orderTotal ? (orderTotalDue * 100) / orderTotal : 0;
    const finalInstallment = balanceAmountPending ? pendingAmountPercent : 0;

    const isPaymentTypePayPal = paymentType === PaymentTypes.PAYPAL;
    const isPaymentTypeCard = paymentType === PaymentTypes.CREDIT_CARD

    const changePaymentMode = (newType: PaymentTypes) => {
        if (paymentType !== newType) {
            setPaymentType(newType);
        }
    };
    const isSelectedAmtValid = (partialPaymentPrice: number, grandTotal: number) => {
        if (partialPaymentPrice * 2 >= grandTotal && partialPaymentPrice <= grandTotal) {
            return true;
        } else return false;
    };
    const totalAmount = (
        finalInstallment: number | undefined,
        orderTotalDue: number,
        partialPaymentPrice: number,
        grandTotal: number
    ) => {
        if (finalInstallment) {
            return orderTotalDue;
        } else if (partialPaymentPrice === grandTotal) {
            return 0;
        } else {
            return partialPaymentPrice;
        }
    };
    //evaluates whether the selected amt is between 50-100% of grandtotal
    const amountAlert = !balanceAmountPending && !isSelectedAmtValid(partialPaymentPrice, grandTotal);

    const isOrderBased = finalInstallment || OrderPaymentOnMultipleProjects ? true : false;
    const amountToPay = totalAmount(finalInstallment, orderTotalDue, partialPaymentPrice, grandTotal);
    const shouldShowPartialPayment = React.useMemo(() => {
        if(isOrderBased) {
            return !isEquipmentOnlyOrder
        } else {
            return cart?.status !== CartStatus.EQ_READY_FOR_PAYMENT
        }
    }, [order, cart?.status, isEquipmentOnlyOrder])
    return (
        <Box sx={cssObject.paymentTabPage}>
            <OrderConfirmationInfo />
            <LoadingOverlay open={loader} />
            {shouldShowPartialPayment && 
            <PartialPaymentContainer
            finalInstallment={finalInstallment}
            amountAlert={amountAlert}
            grandTotal={grandTotal}
            orderTotalDue={orderTotalDue}
            orderTotalPaid={orderTotalPaid}
            onAmountChange={setPartialPaymentPrice}
            initalPercent={initalPercent}
            />
        }
            <br />
            <Box component={'b'} sx={cssObject.tabPanelText}>
                Select Payment Methods
            </Box>
            <br />
            <Box sx={cssObject.wizardSection}>
                <Box>
                    {isPaymentTypeCard && !amountAlert && (
                        <Box component={'span'} sx={cssObject.selectedPaymentIconWrapperStyle}>
                            {' '}
                            <Box
                                component={'img'}
                                alt={'selcted-icon'}
                                src={selectedPaymentIcon}
                                sx={cssObject.selectedPaymentIconStyle}
                            />{' '}
                        </Box>
                    )}
                    <Box
                        sx={[
                            cssObject.paymentTabStyle,
                            isPaymentTypeCard && cssObject.selectedPaymentStyle,
                            amountAlert && cssObject.disablePaymentStyle,
                        ]}
                        onClick={() => {
                            !amountAlert && changePaymentMode(PaymentTypes.CREDIT_CARD);
                        }}
                    >
                        <img src={CreditCardTab} alt={'Credit Card'} />
                    </Box>
                </Box>
                <Box>
                    {isPaymentTypePayPal && !amountAlert && (
                        <Box component={'span'} sx={cssObject.selectedPaymentIconWrapperStyle}>
                            {' '}
                            <Box
                                component={'img'}
                                alt={'selcted-icon'}
                                src={selectedPaymentIcon}
                                sx={cssObject.selectedPaymentIconStyle}
                            />{' '}
                        </Box>
                    )}
                    <Box
                        sx={[
                            cssObject.paymentTabStyle,
                            isPaymentTypePayPal && cssObject.selectedPaymentStyle,
                            amountAlert && cssObject.disablePaymentStyle,
                            {
                                ml: '20px',
                            },
                        ]}
                        onClick={() => {
                            !amountAlert && changePaymentMode(PaymentTypes.PAYPAL);
                        }}
                    >
                        <img src={PaypalTab} alt={'Paypal'} />
                    </Box>
                </Box>
            </Box>
            {isPaymentTypeCard && (
                <CreditCard
                    brainTreeClientToken={brainTreeClientToken}
                    placeOrderFunction={placeOrderFunction}
                    placeFinalOrderFunction={placeFinalOrderFunction}
                    finalInstallment={finalInstallment}
                    amountAlert={amountAlert}
                    isOrderBased={isOrderBased}
                    amountToPay={amountToPay}
                />
            )}
            {isPaymentTypePayPal && (
                <Paypal
                    brainTreeClientToken={brainTreeClientToken}
                    placeOrderFunction={placeOrderFunction}
                    placeFinalOrderFunction={placeFinalOrderFunction}
                    finalInstallment={finalInstallment}
                    amountAlert={amountAlert}
                    isOrderBased={isOrderBased}
                    amountToPay={amountToPay}
                />
            )}
        </Box>
    );
}
