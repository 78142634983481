import React from 'react';
import { Theme } from '@mui/material/styles';
import ContentContainer from '../../components/ContentContainer';
import WizardHeader from '../../components/WizardHeader';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import { Heading } from '../../components/Heading';
import { Paragraph } from '../../components/Paragraph';
import { DoubleSystemWizardState, NumberOfHVAC, ProductCriteria } from '../../models/productCriteria';
import LoadingOverlay from '../../components/common/LoadingOverlay';
import { useDispatch, useSelector } from 'react-redux';
import { SystemGroup, ItemType } from 'models/cart';
import { Box, Hidden, useMediaQuery, useTheme } from '@mui/material';
import Shield from 'components/icons/Shield';
import { CartItem } from '../../models/cart';
import CartSummary from '../../components/CartSummary';
import AddOnProducts from 'components/AddOnProducts';
import { changeProduct } from 'store/actions/productSearchActions';
import WizardCriteriaFooterNew from 'components/WizardCriteriaFooterNew';
import { useNavigate } from 'react-router-dom';
import { AppRoute } from 'models/route';
import WizardNotification from 'components/WizardNotification';

import { selectProducts, selectProductsIAQ, selectSecondSysProducts } from 'store/selectors/productSearch';
import { setDoubleSystemFlag, clearWizarQuestionData } from 'store/actions/productCriteriaActions';
import { isTypeEQFromSearchCriteria, selectProductCriteriaState } from '../../store/selectors/productCriteria';
import CartItemDetail from 'components/CartItemDetail';
import { ButtonWithIcon } from 'components/ButtonWithIcon';
import { generateQuoteForSelectionPage } from 'store/actions/quotes';
import { SXCssObject } from 'models/ThemeImages';
import { selectPreCartItems } from 'store/selectors/selection';
import { invokeGASelectionGenerateQuote } from 'components/Analytics/GoogleAnalyticsTagging';
import DisclaimerText from './DisclaimerText';

const createCssObject = (theme: Theme): SXCssObject => ({
    contentContainer: {
        minHeight: `calc(100vh - ${theme.spacing(8)})`,
    },
    summary: {
        pt: { xs: 2, sm: 4 },
        m: { xs: '0px 16px', sm: 0 },
    },
    header: {
        pt: { xs: 2, sm: 4 },
        pb: { xs: 2, sm: 2.4 },
    },
    contentArea: {
        backgroundColor: {
            xs: theme.palette.common.white,
            sm: 'inherit',
        },
    },
    addOnContainer: {
        marginTop: {
            sm: '20px',
        },
    },
    dividerStyle: {
        border: 'none',
        width: '100%',
        borderBottom: `1px solid ${theme.customProperties.borderDivider}`,
        opacity: '0.4',
        margin: '0px',
        display: {
            sm: 'none',
        },
    },
    divider2: {
        border: 'none',
        width: '100%',
        borderBottom: `1px solid ${theme.customProperties.borderDivider}`,
        opacity: '0.4',
        marginTop: '24px',
        marginBottom: '0px',
        display: {
            sm: 'none',
        },
    },
    actions: {
        position: {
            md: 'relative',
        },
        left: {
            md: 0,
        },
        bottom: {
            md: 0,
        },
        width: {
            md: '100%',
        },
        padding: {
            md: 0,
        },
        height: {
            md: '80px',
        },
        backgroundColor: theme.palette.common.white,
    },
    helpFooter: {
        backgroundColor: theme.palette.common.white,
        fontSize: '12px !important',
        padding: {
            xs: theme.spacing(2, 0, 2, 0),
            sm: theme.spacing(3, 0, 1, 0),
        },
    },

    tabScrollControl: {
        overflowX: 'auto',
        msOverflowStyle: 'none' /* IE and Edge */,
        scrollbarWidth: 'none',
        '&::-webkit-scrollbar': {
            display: 'none',
        },
    },
    tabHeaderContainer: {
        display: 'flex',
        width: {
            xs: '140%',
            sm: '80%',
            md: '66%',
            lg: '62%',
            xl: '60%',
        },
        marginBottom: {
            xs: '0px',
            lg: '24px',
            xl: '16px',
        },
        backgroundColor: {
            xs: theme.palette.background.default,
            sm: 'inherit',
        },
        padding: {
            xs: '8px 0px 8px 16px',
            sm: 'inherit',
        },
        minWidth: {
            sm: '500px',
            lg: '1000px',
        },
    },
    tabSelected: {
        backgroundColor: theme.palette.common.white,
        padding: {
            sm: '10px',
        },
    },
    tabNonSelected: {
        border: '1px solid #CCCCCC',
        backgroundColor: '#F5F5F5',
    },
    tabHeaderStyle: {
        width: '50%',
        cursor: 'pointer',
        display: 'flex',
        borderRadius: '4px',
        alignContent: 'center',
        WebkitTapHighlightColor: 'transparent',
        marginRight: {
            xs: '8px',
            sm: 'inherit',
        },
    },
    tabHeadingContentSelected: {
        display: 'flex',
        width: '100%',
        flexDirection: 'row',
        outline: 'none',
        border: `1px solid ${theme.palette.primary.contrastText}`,
        backgroundColor: theme.palette.primary.light,
        padding: '16px',
        borderRadius: '4px',
    },
    tabHeadingContentNonSelected: {
        display: 'flex',
        width: '100%',
        flexDirection: 'row',
        outline: 'none',
        padding: '16px',
    },
    centerColumn: {
        display: 'flex',
        justifyContent: 'center',
        fontSize: '20px',
        flexDirection: 'column',
        flex: 6,
    },
    tabheadingText: {
        fontWeight: theme.typography.fontWeightMedium,
        marginTop: '4px',
        color: theme.typography.h1.color,
        fontSize: { xs: '0.875rem', md: '1.125rem' },
    },
    tabSubheading: {
        marginTop: {
            xs: '6px',
            sm: 'inherit',
        },
    },
    submitBtns: {
        paddingTop: {
            xs: '30px',
            sm: '20px',
        },
        width: {
            xs: '100%',
            md: 'inherit',
        },
        paddingRight: {
            xs: theme.spacing(0),
            sm: theme.spacing(4),
        },
        paddingLeft: {
            sm: '20px',
        },
        padding: '5px',
    },
    button: {
        marginBottom: {
            xs: '-20px',
            sm: 'inherit',
        },
        paddingTop: {
            xs: '20px',
            sm: 'inherit',
        },
    },
});

interface Props {
    preCartItemsMain: CartItem[];
    preCartItemsIAQ: CartItem[];
    preCartItemsAddons: CartItem[];
    secondSystemWizard: any;
    criteria: ProductCriteria;
    loading: boolean;
}

function EquipmentSelection(props: Props) {
    const { preCartItemsMain, preCartItemsIAQ, preCartItemsAddons, loading, secondSystemWizard } = props;
    const cssObject = createCssObject(useTheme());
    const dispatch = useDispatch();
    const theme = useTheme();
    const isXsDown = useMediaQuery(theme.breakpoints.only('xs'));
    const firstSystemProducts = useSelector(selectProducts);
    const secondSystemProducts = useSelector(selectSecondSysProducts);
    //for setting the local state of IAQ when toggled
    const secondSystem = props.secondSystemWizard.state && props.secondSystemWizard.state.isSecondSystem;
    const [selectedTab, setSelectedTab] = React.useState(secondSystem ? 1 : 0);
    const productsIAQ = useSelector(selectProductsIAQ);
    const criteria = useSelector(selectProductCriteriaState);
    const preCartItems = useSelector(selectPreCartItems);
    const isEquipmentOnlySelection = useSelector(isTypeEQFromSearchCriteria);

    const getItemIAQ = () => {
        if (productsIAQ) {
            if (selectedTab === 0) {
                return preCartItemsIAQ.find((item) => item.systemGroup === SystemGroup.SystemGroup1);
            } else {
                return preCartItemsIAQ.find((item) => item.systemGroup === SystemGroup.SystemGroup2);
            }
        }
        return undefined;
    };
    const itemIAQ = getItemIAQ();

    const itemPrimary = (() => {
        if (preCartItemsMain && preCartItemsMain.length > 0) {
            if (selectedTab === 0) {
                return preCartItemsMain.find((item) => item.systemGroup === SystemGroup.SystemGroup1);
            } else {
                return preCartItemsMain.find((item) => item.systemGroup === SystemGroup.SystemGroup2);
            }
        }
    })();
    const navigate = useNavigate();
    const shouldShowTab = () => {
        const itemMain = preCartItemsMain.filter((item) => item.itemType === ItemType.MainSystem);
        return itemMain && itemMain.length > 1;
    };
    const shouldRedirectToSecondSystemFlow =
        !secondSystem && criteria.numberOfHVAC === NumberOfHVAC.Double && !shouldShowTab();
    const handleChangeIndex = (index: number, e: any) => {
        if (selectedTab === index) return;
        setSelectedTab(index);
    };

    const getSystemType = (cartItem: CartItem, index: Number) => {
        const systemType = cartItem && cartItem.productAttributes && cartItem.productAttributes.systemType;
        if (systemType) {
            if (systemType?.indexOf('IAQ') !== -1) {
                return 'Indoor Air Quality Package';
            } else if (systemType?.indexOf('Ductless') !== -1) {
                return 'Ductless System';
            } else if (
                systemType === 'AC & Coil Only' ||
                systemType === 'Furnace Upgrade Only' ||
                systemType === 'Furnace Only'
            ) {
                return 'Partial HVAC';
            } else return 'Complete HVAC';
        }
        return `System ${index}`;
    };

    const isDuctless = () => {
        if (itemPrimary && itemPrimary.productAttributes) {
            return itemPrimary.productAttributes.systemType.toLowerCase().indexOf('ductless') !== -1;
        }
        return false;
    };
    const isMainProductIAQ = () => {
        if (itemPrimary && itemPrimary.productAttributes) {
            return itemPrimary.productAttributes.systemType.toLowerCase().indexOf('iaq') !== -1;
        }
        return false;
    };

    const checkAndGetProducts = (sku: string) => {
        if (selectedTab === 0 && firstSystemProducts && firstSystemProducts.length > 0) {
            dispatch(
                changeProduct({
                    sku: sku,
                    isChangeForSecondSystem: false,
                    isByClickingChangeinSelection: sku,
                    changingSystemGroup: SystemGroup.SystemGroup1,
                })
            );
        } else if (selectedTab === 1 && secondSystemProducts && secondSystemProducts.length > 0) {
            dispatch(
                changeProduct({
                    sku: sku,
                    isChangeForSecondSystem: true,
                    isByClickingChangeinSelection: sku,
                    changingSystemGroup: SystemGroup.SystemGroup2,
                })
            );
        } else if (itemPrimary) {
            dispatch(changeProduct({ sku: itemPrimary.sku, isChangeForSecondSystem: selectedTab === 1 }));
        }
    };
    const handleSubmit = () => {
        //check if (productCriteria.numberofHVAC===double && !productsSystemTwo)
        //take back to wizard question page
        if (shouldRedirectToSecondSystemFlow) {
            dispatch(clearWizarQuestionData({ numberOfHVAC: NumberOfHVAC.Double }));
            dispatch(setDoubleSystemFlag({ doubleSystemFlag: DoubleSystemWizardState.Second }));
            navigate(AppRoute.AboutYourHome, { state: { isSecondSystem: true } });
        } else {
            invokeGASelectionGenerateQuote();
            dispatch(generateQuoteForSelectionPage());
        }
    };
    return (
        <ContentContainer disableGrid background={'bg2'}>
            <Box>
                <Box sx={cssObject.contentContainer}>
                    <LoadingOverlay open={loading} />
                    <WizardHeader shadow={false} isForSelectionPage onHelp={() => {}} />
                    <Divider />

                    <ContentContainer disableGrid>
                        <ContentContainer sx={cssObject.header}>
                            <Grid container>
                                <Grid>
                                    <Heading label={'Your Selected HVAC System'} />
                                </Grid>
                            </Grid>
                        </ContentContainer>
                    </ContentContainer>

                    <ContentContainer disableGrid={isXsDown} sx={cssObject.contentArea}>
                        <ContentContainer disableGrid>
                            {shouldShowTab() && (
                                <Box sx={cssObject.tabScrollControl}>
                                    <ContentContainer disableGrid sx={cssObject.tabHeaderContainer}>
                                        <Box
                                            onClick={(e) => {
                                                handleChangeIndex(0, e);
                                            }}
                                            sx={[
                                                cssObject.tabHeaderStyle,
                                                selectedTab === 0 && cssObject.tabSelected,
                                                selectedTab === 1 && cssObject.tabNonSelected,
                                            ]}
                                        >
                                            <Box
                                                sx={[
                                                    selectedTab === 0 && cssObject.tabHeadingContentSelected,
                                                    selectedTab === 1 && cssObject.tabHeadingContentNonSelected,
                                                ]}
                                            >
                                                <Box sx={cssObject.centerColumn}>
                                                    <Box component={'span'} sx={cssObject.tabSubheading}>
                                                        <Paragraph fontSizeVerySmall={isXsDown}>
                                                            Your First Replacement
                                                        </Paragraph>
                                                    </Box>
                                                    <Box sx={cssObject.tabheadingText}>
                                                        {getSystemType(
                                                            preCartItemsMain.find(
                                                                (item) => item.systemGroup === SystemGroup.SystemGroup1
                                                            )!,
                                                            0
                                                        )}
                                                    </Box>
                                                </Box>
                                            </Box>
                                        </Box>
                                        <Box
                                            onClick={(e) => {
                                                handleChangeIndex(1, e);
                                            }}
                                            sx={[
                                                cssObject.tabHeaderStyle,
                                                selectedTab === 1 && cssObject.tabSelected,
                                                selectedTab === 0 && cssObject.tabNonSelected,
                                            ]}
                                        >
                                            <Box
                                                sx={[
                                                    selectedTab === 1 && cssObject.tabHeadingContentSelected,
                                                    selectedTab === 0 && cssObject.tabHeadingContentNonSelected,
                                                ]}
                                            >
                                                <Box sx={cssObject.centerColumn}>
                                                    <Box sx={cssObject.tabSubheading}>
                                                        <Paragraph fontSizeVerySmall={isXsDown}>
                                                            Your Second Replacement
                                                        </Paragraph>
                                                    </Box>
                                                    <Box sx={cssObject.tabheadingText}>
                                                        {getSystemType(
                                                            preCartItemsMain.find(
                                                                (item) => item.systemGroup === SystemGroup.SystemGroup2
                                                            )!,
                                                            1
                                                        )}
                                                    </Box>
                                                </Box>
                                            </Box>
                                        </Box>
                                    </ContentContainer>
                                </Box>
                            )}
                            <Grid container spacing={isXsDown ? 0 : 2} justifyContent="space-between">
                                <Grid xs={12} md={8} lg={9} item spacing={isXsDown ? 0 : 2}>
                                    <Grid container spacing={isXsDown ? 0 : 2}>
                                        {itemPrimary && itemPrimary.productAttributes && (
                                            <Grid item sx={{ width: '100%' }}>
                                                <CartItemDetail
                                                    item={itemPrimary}
                                                    onSelect={() => {
                                                        checkAndGetProducts(itemPrimary.sku);
                                                    }}
                                                />
                                            </Grid>
                                        )}
                                        {productsIAQ && productsIAQ.length > 0 && itemIAQ && itemIAQ.productAttributes && (
                                            <Grid container item>
                                                <CartItemDetail
                                                    item={itemIAQ}
                                                    onSelect={() => {
                                                        navigate(AppRoute.IAQOptions, {
                                                            state: {
                                                                isFullSystem: 'isFullSystem',
                                                                systemGroup:
                                                                    selectedTab === 1
                                                                        ? SystemGroup.SystemGroup2
                                                                        : SystemGroup.SystemGroup1,
                                                                isSecondSystem: selectedTab === 1 ? true : false,
                                                                isByClickingChangeinSelection: true,
                                                            },
                                                        });
                                                    }}
                                                />
                                            </Grid>
                                        )}
                                        {productsIAQ &&
                                            productsIAQ.length > 0 &&
                                            !itemIAQ &&
                                            !isDuctless() &&
                                            !isMainProductIAQ() && (
                                                <Grid container item>
                                                    <CartItemDetail
                                                        onSelect={() => {
                                                            navigate(AppRoute.IAQOptions, {
                                                                state: {
                                                                    isFullSystem: 'isFullSystem',
                                                                    systemGroup:
                                                                        selectedTab === 1
                                                                            ? SystemGroup.SystemGroup2
                                                                            : SystemGroup.SystemGroup1,
                                                                    isSecondSystem: selectedTab === 1 ? true : false,
                                                                    isByClickingChangeinSelection: true,
                                                                },
                                                            });
                                                        }}
                                                    />
                                                </Grid>
                                            )}
                                    </Grid>
                                    {preCartItemsAddons && preCartItemsAddons.length > 0 && (
                                        <Grid item sx={cssObject.addOnContainer}>
                                            <AddOnProducts addOnList={preCartItemsAddons} />
                                        </Grid>
                                    )}
                                </Grid>
                                {/* cart summary */}
                                <Grid sx={cssObject.summary} item xs={12} md={4} lg={3}>
                                    <CartSummary items={preCartItems} />
                                </Grid>
                                <Box component={'hr'} sx={cssObject.divider2} />
                            </Grid>
                            <ContentContainer disableGrid={!isXsDown}>
                                <DisclaimerText />
                            </ContentContainer>

                            <ContentContainer background={'bg3'} disableGrid>
                                <Box component={'hr'} sx={cssObject.dividerStyle} />
                            </ContentContainer>
                        </ContentContainer>
                    </ContentContainer>
                </Box>

                {!secondSystemWizard && <WizardNotification isFixed={true} />}
                <Grid sx={cssObject.actions}>
                    <ContentContainer>
                        <Grid container alignItems={'center'}>
                            <Hidden only={['xs']}>
                                <Grid sx={cssObject.helpFooter} item xs={12} sm={6} md={8}>
                                    {!isEquipmentOnlySelection && (
                                        <Grid container>
                                            <Grid item display={'flex'}>
                                                <Shield />
                                            </Grid>
                                            <Grid item xs={10}>
                                                <Paragraph size="small" align="left">
                                                    Did you know your new installed HVAC system will be protected by our
                                                    1-Year Full Labor Warranty!
                                                </Paragraph>
                                            </Grid>
                                        </Grid>
                                    )}
                                </Grid>
                            </Hidden>
                            {shouldRedirectToSecondSystemFlow ? (
                                <Grid
                                    spacing={2}
                                    item
                                    xs={12}
                                    sm={6}
                                    md={4}
                                    lg={3}
                                    sx={cssObject.button}
                                    alignItems={'center'}
                                >
                                    <WizardCriteriaFooterNew
                                        nextLabel={'Continue with Another Replacement'}
                                        onNext={handleSubmit}
                                    />
                                </Grid>
                            ) : (
                                <Grid item xs={12} sm={6} md={4} sx={cssObject.submitBtns} justifyContent={'flex-end'}>
                                    <ButtonWithIcon
                                        id={`cart-continue-withtooltip-btn`}
                                        color={'primary'}
                                        isFullWidth={true}
                                        onClick={handleSubmit}
                                        quoteTip
                                        toolTipText={
                                            'Planning another project? We’re here to help! Our quotes are guaranteed for 30 days, but we ask that you complete your first project or contact us directly to start a new order.'
                                        }
                                    >
                                        Generate quote
                                    </ButtonWithIcon>
                                </Grid>
                            )}
                            <Hidden smUp>
                                <Grid sx={cssObject.helpFooter} item xs={12} sm={5} md={8}>
                                {!isEquipmentOnlySelection && (
                                        <Grid container>
                                            <Grid item display={'flex'}>
                                                <Shield />
                                            </Grid>
                                            <Grid item xs={10}>
                                                <Paragraph size="small" align="left">
                                                    Did you know your new installed HVAC system will be protected by our
                                                    1-Year Full Labor Warranty!
                                                </Paragraph>
                                            </Grid>
                                        </Grid>
                                    )}
                                </Grid>
                            </Hidden>
                        </Grid>
                    </ContentContainer>
                </Grid>
            </Box>
        </ContentContainer>
    );
}
export default EquipmentSelection;
