import * as React from "react"

const ThumbUpIcon = (props:React.SVGProps<SVGSVGElement>) => (
  <svg
    data-name="Component 58 \u2013 1"
    xmlns="http://www.w3.org/2000/svg"
    width={66}
    height={66}
    {...props}
  >
    <g
      data-name="Rectangle 4019"
      transform="translate(8 8)"
      fill="#eaf6f5"
      stroke="#1dbaaf"
      strokeWidth={3}
    >
      <rect width={50} height={50} rx={25} stroke="none" />
      <rect x={1.5} y={1.5} width={47} height={47} rx={23.5} fill="none" />
    </g>
    <g data-name="Path 2309" fill="none" opacity={0.2}>
      <path d="M32 0h2a32 32 0 0 1 32 32v2a32 32 0 0 1-32 32h-2A32 32 0 0 1 0 34v-2A32 32 0 0 1 32 0Z" />
      <path
        d="M32 2a29.902 29.902 0 0 0-21.213 8.787A29.902 29.902 0 0 0 2 32v2a29.902 29.902 0 0 0 8.787 21.213A29.902 29.902 0 0 0 32 64h2a29.902 29.902 0 0 0 21.213-8.787A29.902 29.902 0 0 0 64 34v-2a29.902 29.902 0 0 0-8.787-21.213A29.902 29.902 0 0 0 34 2h-2m0-2h2c17.673 0 32 14.327 32 32v2c0 17.673-14.327 32-32 32h-2C14.327 66 0 51.673 0 34v-2C0 14.327 14.327 0 32 0Z"
        fill="#1dbaaf"
      />
    </g>
    <path
      data-name="Path 2310"
      d="M38.177 28.254c1.472 0 2.867-.016 4.261 0a4.015 4.015 0 0 1 3.989 3.33 6.056 6.056 0 0 1-.132 2.182q-.987 4.525-2.08 9.028a4.029 4.029 0 0 1-3.572 3.018 8.85 8.85 0 0 1-.846.035H22.332a2.56 2.56 0 0 1-2.81-2.8V31.796a2.34 2.34 0 0 1 2.64-2.633c.848 0 1.7-.042 2.541.007a4.823 4.823 0 0 0 4.989-4.837 4.15 4.15 0 0 1 3.1-4.067 4.582 4.582 0 0 1 5.478 3.75 24.322 24.322 0 0 1-.022 3.61c0 .184-.041.369-.071.624M27.244 43.292H39.922a1.674 1.674 0 0 0 1.932-1.513q1.02-4.423 2.006-8.855a1.584 1.584 0 0 0-1.7-2.121h-7.451a2.387 2.387 0 0 1 .041-.24 19.146 19.146 0 0 0 .827-2.739 16.486 16.486 0 0 0 .153-3.5 1.961 1.961 0 0 0-1.99-1.66c-.924.076-1.425.743-1.5 1.881a7.3 7.3 0 0 1-4.645 6.673.54.54 0 0 0-.388.586c.012 3.657.008 7.313.009 10.97 0 .154.013.308.023.521m-5.147-.062h2.52V31.787h-2.514Z"
      fill="#1dbaaf"
    />
  </svg>
)

export default ThumbUpIcon
