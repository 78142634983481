import React from 'react';
import { Theme, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import { SXCssObject } from 'models/ThemeImages';
import { Cart, PaymentMethod } from 'models/cart';
import { Order } from 'models/order';
import { useDispatch, useSelector } from 'react-redux';
import { cartProgress } from 'store/actions/cartActions';
import { setupPaypalButton } from 'store/actions/financialActions';
import { selectCart } from 'store/selectors/cart';

const createCssObject = (theme: Theme): SXCssObject => ({
    myPaypalBtn: {
        marginTop: '36px',
        minWidth: '200px',
        maxWidth: {
            sm: '250px',
        },
        width: {
            xs: '100%',
            sm: '30%',
        },
    },
    myPaypalBtnDisabled: {
        marginTop: '36px',
        minWidth: '200px',
        opacity: 0.3,
        pointerEvents: 'none',
        maxWidth: {
            sm: '250px',
        },
        width: {
            xs: '100%',
            sm: '30%',
        },
    },
});
interface Props {
    brainTreeClientToken?: string;
    placeOrderFunction: (cart: Cart, paymentMethod: PaymentMethod) => void;
    placeFinalOrderFunction: (order: Order, paymentMethod: PaymentMethod, hvFinalOrder?: boolean) => void;
    amountAlert: boolean;
    isOrderBased: boolean;
    amountToPay: number;
    finalInstallment: number
}
const Paypal = (props: Props) => {
    const {
        placeOrderFunction,
        placeFinalOrderFunction,
        brainTreeClientToken,
        amountAlert,
        isOrderBased,
        amountToPay,
        finalInstallment
    } = props;
    const cssObject: SXCssObject = createCssObject(useTheme());
    const dispatch = useDispatch();
    const cart = useSelector(selectCart);

    React.useEffect(() => {
        const cartLoader = (loading: boolean, error?: any) => {
            dispatch(cartProgress({ loading }));
        };
        if (brainTreeClientToken && !amountAlert) {
            dispatch(cartProgress({ loading: true }));
            dispatch(
                setupPaypalButton({
                    totalAmount: amountToPay,
                    placeOrderFunction: placeOrderFunction,
                    placeFinalOrderFunction: placeFinalOrderFunction,
                    finalInstallment: finalInstallment,
                    containerId: 'paypal-button',
                    cartProcessFunction: cartLoader,
                    isOrderBased: isOrderBased,
                    brainTreeClientToken: brainTreeClientToken,
                })
            );
        }
        return () => {
            dispatch(cartProgress({ loading: false }));
        };
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [cart.billingAddress, brainTreeClientToken, amountToPay, amountAlert]);
    return (
        <div>
            <Box id="paypal-button" sx={!amountAlert ? cssObject.myPaypalBtn : cssObject.myPaypalBtnDisabled}></Box>
        </div>
    );
};
export default Paypal;
