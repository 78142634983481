import { LoginRequest, LoginResponse, SignUpRequest, SignUpResponse } from 'models/loginModel';
import { action, payload } from 'ts-action';

export const emailAlreadyExist = action('EMAIL::ALREADY_EXIST');

export const emailDoesNotExist = action('EMAIL::DOESNOT_EXIST');

export const performLogin = action('PERFORM::LOGIN', payload<LoginRequest>());

export const loginSuccess = action(
    'LOGIN::SUCCESS',
    payload<{ loginResponse: LoginResponse; route?: string; params?: string }>()
);

export const loginFailure = action('LOGIN::FAILURE', payload<Error>());

export const performSignUp = action('PERFORM::SIGNUP', payload<SignUpRequest>());

export const signUpSuccess = action('SIGNUP::SUCCESS', payload<SignUpResponse>());

export const signUpFailure = action('SIGNUP::FAILURE', payload<Error>());

export const performLogout = action('PERFORM::LOGOUT');

export const clearLoginData = action('CLEAR::LOGINDATA');
export const clearLoginDataSuccess = action('CLEAR::LOGINDATA_SUCCESS');

export const logoutSuccess = action('LOGOUT::SUCCESS');

export const logoutFailure = action('LOGOUT::FAILURE', payload<Error>());

export const sendVerificationOtp = action('SEND::VERIFICATION_OTP');

export const sendVerificationOtpSuccess = action('VERIFICATION_OTP::SUCCESS');

export const sendVerificationOtpFailure = action('VERIFICATION_OTP::FAILURE', payload<Error>());

export const verifyEnteredOtp = action('PERFORM::VERIFY_OTP', payload<{ otp: string }>());

export const verifyEnteredOtpSuccess = action('VERIFY_OTP::SUCCESS');

export const verifyEnteredOtpFailure = action('VERIFY_OTP::FAILURE', payload<Error>());

export const sendLoginOtp = action('SEND::LOGIN_OTP', payload<{ email: string }>());

export const sendLoginOtpSuccess = action('LOGIN_OTP::SUCCESS', payload<{ email: string }>());

export const sendLoginOtpFailure = action('LOGIN_OTP::FAILURE', payload<Error>());
