import React, { useState } from 'react';
import { Theme } from '@mui/material/styles';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import ContentContainer from '../../components/ContentContainer';
import { Paragraph } from '../../components/Paragraph';
import { Box, Hidden, useMediaQuery, useTheme, Icon } from '@mui/material';
import { Address, ItemType } from 'models/cart';
import { useDispatch, useSelector } from 'react-redux';
import { ReactComponent as DownArrowIcon } from 'assets/icons/down_arrrow_quote.svg';
import { ReactComponent as UpArrowIcon } from 'assets/icons/up_arrow_quote.svg';
import QuoteDropdown from './QuoteDropdown';
import { Quote, PriceDelta, QuoteStatus } from 'models/quotes';
import { ActiveQuotesMenus, ExpiredQuotesMenus, QuoteMenu, QuoteMenus, QuotesGridHeadingMenu } from './QuoteMenus';
import {
    downloadQuoteAction,
    reGenerateQuoteFromQuotationPage,
    myQuotesAction,
    moveQuoteToCart,
} from 'store/actions/quotes';
import { deleteQuoteById } from 'store/services/quoteServices';
import {
    extractErrorFromResponseOrShowDefaultMsg,
    getThemePrimaryLight,
    getThemePrimaryMain,
} from 'components/common/Utilities';
import { WeAreSorryModal } from './modal/WeAreSorryModal';
import { getSnackbarMsg } from 'components/common/CustomSnackMessageUI';
import LoadingOverlay from 'components/common/LoadingOverlay';
import { selectItems, selectProceedToCart } from 'store/selectors/cart';
import { ConfirmationModal } from 'components/common/ConfirmationModal';
import CollapseIcon from 'components/icons/CollapseIcon';
import ExpandIcon from 'components/icons/ExpandIcon';
import OrderIcon from 'components/icons/OrderIcon';
import ArrowDown3 from 'components/icons/ArrowDown3';
import { get } from 'lodash';
import { showSnackbarInfo } from 'components/common/Snackbar/SnackbarHelper';
import { SXCssObject } from 'models/ThemeImages';
import { currencyFormat } from 'components/common/NumberUtil';
import {
    invokeGADeleteQuote,
    invokeGADownloadQuote,
    invokeGAMoveQuoteToCart,
    invokeGARegenerateQuote,
} from 'components/Analytics/GoogleAnalyticsTagging';
import { format } from 'date-fns';

interface Props {
    quote: Quote;
}
const createCssObject = (theme: Theme, props: Props): SXCssObject => ({
    root: {
        padding: '0px',
        borderBottom: '1px solid #E2E2E2',
        px: '0px !important',
    },
    paymentDate: {
        textAlign: 'left',
        marginTop: {
            xs: theme.spacing(0.5),
            md: 'inherit',
        },
        paddingLeft: {
            xs: theme.spacing(0),
            md: 'inherit',
        },
    },
    quoteNumber: {
        padding: theme.spacing(0.8),
        borderRadius: '4px',
        backgroundColor: getThemePrimaryLight(theme),
        display: 'inline-flex',
        marginTop: {
            xs: theme.spacing(1),
            md: 'inherit',
        },
    },
    quotePriceLayout: {
        marginTop: {
            xs: theme.spacing(0.5),
            md: 'inherit',
        },
        paddingLeft: {
            xs: theme.spacing(0),
            md: 'inherit',
        },
        display: {
            md: ' flex',
        },
        alignItems: {
            md: 'center',
        },
    },
    QuoteDetail: {
        padding: {
            xs: `20px 20px 20px 20px`,
        },
    },
    quotePriceSection: {
        pl: 3,
        height: {
            md: '50px',
        },
        display: {
            md: 'flex',
        },
        alignItems: {
            md: 'center',
        },
    },
    expiresStatusContent: {
        display: 'flex',
        alignItems: 'center',
    },
    reverseArrowIcon: {
        transform: 'rotate(180deg)',
        cursor: 'pointer',
    },
    productDetail: {
        marginTop: {
            xs: theme.spacing(1),
            md: 'inherit',
        },
    },
    groupSystem: {
        marginTop: '5px',
        paddingTop: '5px',
        borderTop: '1px solid #E2E2E2',
        width: 'fit-content',
    },
    arrowIcon: {
        width: '15px',
        cursor: 'pointer',
    },
    payButton: {
        marginTop: '10px',
        width: {
            md: '70%',
        },
    },
    expireStatus: {
        padding: theme.spacing(0.5, 1),
        backgroundColor: props.quote.status === QuoteStatus.ACTIVE ? getThemePrimaryLight(theme) : '#EF9D9640',
        marginBottom: '5px',
        borderRadius: '4px',
        maxWidth: '90px',
        marginTop: {
            xs: theme.spacing(1),
            md: 'inherit',
        },
    },
    expireStatusIconStyle: {
        border: `3px solid ${props.quote.status === QuoteStatus.ACTIVE ? getThemePrimaryMain(theme) : '#F06F64'}`,
        width: '14px',
        height: '14px',
        borderRadius: '50%',
        marginRight: '8px',
    },
    statusColor: {
        color: props.quote.status === QuoteStatus.ACTIVE ? getThemePrimaryMain(theme) : '#F06F64',
    },
});

function QuoteDetail(props: Props) {
    const { quoteId, quotationJson, expireAt, status, id, priceDelta } = props.quote;
    const { items, shippingAddress, prices, isEquipmentOnly } = quotationJson;
    const cssObject: SXCssObject = createCssObject(useTheme(), props);
    const theme = useTheme();
    const isSmDown = useMediaQuery(theme.breakpoints.down('md'));
    const isMdUp = useMediaQuery(theme.breakpoints.up('md'));
    const [loader, setLoader] = useState(false);
    const [openSorryModal, setOpenSorryModal] = useState(false);
    const [openCartItemsAlertModal, setOpenCartItemsAlertModal] = useState<boolean>(false);
    const [openDeleteQuoteModal, setOpenDeleteQuoteModal] = useState<boolean>(false);

    const isXs = useMediaQuery(theme.breakpoints.only('xs'));
    const [isProductDetailsExpanded, setIsProductDetailsExpanded] = React.useState(false);
    const [isQuoteDetailsExpanded, setIsQuoteDetailsExpanded] = React.useState(false);
    const dispatch = useDispatch();
    const proceedToCart = useSelector(selectProceedToCart);
    const cartItems = useSelector(selectItems);

    const shouldShowQuoteDetails = isQuoteDetailsExpanded || isMdUp;

    const handleRegenerateQuote = () => {
        invokeGARegenerateQuote();
        dispatch(reGenerateQuoteFromQuotationPage(props.quote));
    };

    const quoteNumberLabel = (
        <Grid container style={{ flex: '0 1 auto' }} justifyContent={'space-between'}>
            <Grid item>
                <Paragraph semibold>{QuotesGridHeadingMenu.QUOTE_ID}</Paragraph>
            </Grid>
            {(!isQuoteDetailsExpanded || isXs) && (
                <Hidden mdUp>
                    <Grid item onClick={() => toggleExpanded(isQuoteDetailsExpanded)}>
                        {isQuoteDetailsExpanded ? <CollapseIcon /> : <ExpandIcon />}
                    </Grid>
                </Hidden>
            )}
        </Grid>
    );
    const productsLabel = (
        <Grid container justifyContent={'space-between'}>
            <Grid item>
                <Paragraph semibold>{QuotesGridHeadingMenu.QUOTE_PRODUCTS}</Paragraph>
            </Grid>
            {isQuoteDetailsExpanded && (
                <Hidden only={['xl', 'lg', 'md', 'xs']}>
                    <Grid item onClick={() => toggleExpanded(isQuoteDetailsExpanded)} style={{ display: 'flex' }}>
                        {isQuoteDetailsExpanded ? <CollapseIcon /> : <ExpandIcon />}
                    </Grid>
                </Hidden>
            )}
        </Grid>
    );

    const address = (addressItem: Address) => {
        return (
            <div>
                {addressItem && addressItem.firstname && (
                    <>
                        <Paragraph>
                            {addressItem.addressName}:{' '}
                            {addressItem.firstname +
                                ' ' +
                                addressItem.lastname +
                                ', ' +
                                addressItem.address1 +
                                ', ' +
                                (addressItem.address2 ? addressItem.address2 + ', ' : '')}{' '}
                            {addressItem.city + ', ' + addressItem.region + ', ' + addressItem.postcode}
                        </Paragraph>
                    </>
                )}
            </div>
        );
    };
    const toggleExpanded = (currentState: boolean) => {
        setIsQuoteDetailsExpanded(!currentState);
    };
    const UpArrow = () => {
        return (
            <Grid
                item
                style={{
                    marginLeft: '5px',
                }}
            >
                <ArrowDown3
                    style={cssObject.reverseArrowIcon}
                    onClick={() => setIsProductDetailsExpanded(!isProductDetailsExpanded)}
                />
            </Grid>
        );
    };
    const getDropdownMenus = (status: QuoteStatus) => {
        return status === QuoteStatus.ACTIVE ? ActiveQuotesMenus : ExpiredQuotesMenus;
    };

    const handleDownloadQuote = () => {
        invokeGADownloadQuote();
        dispatch(downloadQuoteAction(props.quote));
    };
    const handleDeleteQuote = async (shouldProceed?: boolean) => {
        setOpenDeleteQuoteModal(false);
        if (shouldProceed) {
            invokeGADeleteQuote();
            setLoader(true);
            try {
                const quoteIdToShow = quoteId ? `"${quoteId}" ` : '';
                await deleteQuoteById(id);
                dispatch(myQuotesAction());
                setLoader(false);
                showSnackbarInfo(getSnackbarMsg(`Quote ${quoteIdToShow}`, ' has been deleted'));
            } catch (err) {
                setLoader(false);
                extractErrorFromResponseOrShowDefaultMsg(err, 'Unable to Delete Quote');
            }
        }
    };

    const handleMoveToCart = () => {
        if (!proceedToCart) {
            setOpenSorryModal(true);
        } else {
            if (cartItems && cartItems.length > 0) {
                setOpenCartItemsAlertModal(true);
            } else {
                invokeGAMoveQuoteToCart();
                dispatch(moveQuoteToCart({ id: id, appliedCoupons: quotationJson?.appliedCoupons }));
            }
        }
    };
    const handleAddToCart = (shouldProceed?: boolean) => {
        if (shouldProceed) {
            dispatch(moveQuoteToCart({ id: id, appliedCoupons: quotationJson?.appliedCoupons }));
            invokeGAMoveQuoteToCart();
        }
        setOpenCartItemsAlertModal(false);
    };
    const onMenuClick = (menu: QuoteMenu) => {
        switch (menu.label) {
            case QuoteMenus.REGENERATE_QUOTE:
                handleRegenerateQuote();
                return;
            case QuoteMenus.MOVE_TO_CART:
                handleMoveToCart();
                return;
            case QuoteMenus.DOWNLOAD_QUOTE:
                handleDownloadQuote();
                return;
            case QuoteMenus.DELETE_QUOTE:
                setOpenDeleteQuoteModal(true);
                return;
        }
    };
    return (
        <ContentContainer disableGrid>
            <LoadingOverlay open={loader} />
            <WeAreSorryModal
                open={openSorryModal}
                confirmMsg={
                    'You currently have an open order in our system. Please contact us directly to start a new order or complete your open order to begin a new one.'
                }
                handleClose={() => {
                    setOpenSorryModal(!openSorryModal);
                }}
            />
            <ConfirmationModal
                open={openDeleteQuoteModal}
                handleClose={(shouldProceed?: boolean) => handleDeleteQuote(shouldProceed)}
                cancelButtonText={'NO, KEEP IT'}
                confirmButtonText={'YES, DELETE'}
                confirmMsg={'Do you really want to delete the quote'}
            />
            <ConfirmationModal
                open={openCartItemsAlertModal}
                handleClose={(shouldProceed?: boolean) => handleAddToCart(shouldProceed)}
                cancelButtonText={'NO, KEEP IT'}
                confirmButtonText={'YES, PROCEED'}
                confirmMsg={
                    <span>
                        There is other HVAC product in your cart.
                        <br />
                        Proceeding with this quote will remove that equipment and replace it with the equipment from
                        this new quote
                    </span>
                }
            />
            <Container maxWidth={false} sx={cssObject.root} id={`active-cart`}>
                <div id={'quote_ref'} data-id={id} />
                <ContentContainer disableGrid background={'bg3'} sx={cssObject.QuoteDetail}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={isQuoteDetailsExpanded ? 6 : 12} md={2}>
                            {isSmDown && quoteNumberLabel}
                            {quoteId && (
                                <Grid item sx={cssObject.quoteNumber}>
                                    <Grid container alignItems={'center'}>
                                        <Grid
                                            item
                                            style={{ display: 'flex', alignItems: 'center', marginRight: '5px' }}
                                        >
                                            <OrderIcon />
                                        </Grid>
                                        <Paragraph semibold fontSizeMediumSmall>
                                            {quoteId}
                                        </Paragraph>
                                    </Grid>
                                </Grid>
                            )}
                        </Grid>
                        {items && shouldShowQuoteDetails && (
                            <Grid item xs={12} sm={6} md={2}>
                                {isSmDown && productsLabel}
                                {isProductDetailsExpanded ? (
                                    items &&
                                    items.map((item, index) => {
                                        if (item.itemType === ItemType.MainSystem) {
                                            return (
                                                <Box
                                                    key={index}
                                                    sx={[cssObject.productDetail, index !== 0 && cssObject.groupSystem]}
                                                >
                                                    <Paragraph semibold>{`${get(
                                                        item,
                                                        'productAttributes.level',
                                                        ''
                                                    )} HVAC`}
                                                    {isEquipmentOnly ? '-Equipment only' : ''}
                                                    </Paragraph>

                                                    <Grid container>
                                                        <Paragraph fontSizeVerySmall>
                                                            {' '}
                                                            {get(item, 'productAttributes.systemType', '')}
                                                        </Paragraph>
                                                        {isProductDetailsExpanded && index === items.length - 1 && (
                                                            <UpArrow />
                                                        )}
                                                    </Grid>
                                                </Box>
                                            );
                                        } else if (item.itemType === ItemType.CrossSell) {
                                            return (
                                                <Grid container>
                                                    <Paragraph semibold>{`${get(
                                                        item,
                                                        'productAttributes.level',
                                                        ''
                                                    )} IAQ HVAC`}</Paragraph>
                                                    {isProductDetailsExpanded && index === items.length - 1 && (
                                                        <UpArrow />
                                                    )}
                                                </Grid>
                                            );
                                        } else {
                                            return (
                                                <Grid container sx={[index !== 0 && cssObject.groupSystem]}>
                                                    <Grid item xs={11}>
                                                        {item.quantity > 1 ? (
                                                            <Paragraph
                                                                semibold
                                                            >{`${item.quantity} x ${item.name}`}</Paragraph>
                                                        ) : (
                                                            <Paragraph semibold>{item.name}</Paragraph>
                                                        )}
                                                    </Grid>
                                                    <Grid item xs={1} style={{ paddingTop: '4px' }}>
                                                        {isProductDetailsExpanded && index === items.length - 1 && (
                                                            <UpArrow />
                                                        )}
                                                    </Grid>
                                                </Grid>
                                            );
                                        }
                                    })
                                ) : (
                                    <Box sx={cssObject.productDetail}>
                                        <Paragraph semibold>{`${get(
                                            items[0],
                                            'productAttributes.level',
                                            ''
                                        )} HVAC`}
                                        {isEquipmentOnly ? '-Equipment only' : ''}
                                        </Paragraph>
                                        <Paragraph fontSizeVerySmall>
                                            {get(items[0], 'productAttributes.systemType', '')}
                                        </Paragraph>
                                    </Box>
                                )}

                                {!isProductDetailsExpanded && items.length > 1 && (
                                    <Grid container style={{ fontSize: '10px' }}>
                                        <Grid item>{`+ ${items.length - 1} more system(s) added`}</Grid>
                                        <Grid item sx={{ ml: 0.5, mt: 0.5 }}>
                                            <ArrowDown3
                                                style={cssObject.arrowIcon}
                                                onClick={() => setIsProductDetailsExpanded(!isProductDetailsExpanded)}
                                            />
                                        </Grid>
                                    </Grid>
                                )}
                            </Grid>
                        )}

                        {shouldShowQuoteDetails && (
                            <Grid item xs={12} sm={6} md={2}>
                                {isSmDown && <Paragraph semibold>{QuotesGridHeadingMenu.QUOTE_PROPERTY}</Paragraph>}
                                <Grid container direction={'column'} sx={cssObject.paymentDate}>
                                    {address(shippingAddress)}
                                </Grid>
                            </Grid>
                        )}

                        {shouldShowQuoteDetails && (
                            <Grid item xs={12} sm={6} md={2} container>
                                {isSmDown && <Paragraph semibold>{QuotesGridHeadingMenu.EXPIRY_DATE}</Paragraph>}
                                <Grid item container direction={'column'} sx={cssObject.paymentDate}>
                                    <Grid sx={cssObject.expireStatus}>
                                        <ContentContainer disableGrid sx={cssObject.expiresStatusContent}>
                                            <ContentContainer
                                                disableGrid
                                                sx={cssObject.expireStatusIconStyle}
                                            ></ContentContainer>
                                            <Paragraph semibold headingColor>
                                                <Box component={'span'} sx={cssObject.statusColor}>
                                                    {status}
                                                </Box>
                                            </Paragraph>
                                        </ContentContainer>
                                    </Grid>
                                    <Paragraph fontColor fontSizeVerySmall>
                                        Expires on {format(new Date(expireAt), 'dd MMM yyyy')}
                                    </Paragraph>
                                </Grid>
                            </Grid>
                        )}

                        {shouldShowQuoteDetails && (
                            <Grid item xs={12} sm={6} md={1} container sx={cssObject.quotePriceSection}>
                                {isSmDown && (
                                    <Paragraph semibold>{QuotesGridHeadingMenu.QUOTE_CURRENT_PRICE}</Paragraph>
                                )}
                                <Grid container sx={cssObject.quotePriceLayout}>
                                    <Box display="flex">
                                        <Paragraph semibold>
                                            {currencyFormat.format(prices.currentGrandTotal!)}{' '}
                                        </Paragraph>
                                        <span style={{ paddingLeft: '4px' }}>
                                            {priceDelta === PriceDelta.INCREASED && <UpArrowIcon />}
                                            {priceDelta === PriceDelta.DECREASED && <DownArrowIcon />}
                                        </span>
                                    </Box>
                                </Grid>
                            </Grid>
                        )}

                        {shouldShowQuoteDetails && (
                            <Grid item xs={12} sm={6} md={3} sx={cssObject.quotePriceSection}>
                                {isSmDown && (
                                    <Paragraph semibold>{QuotesGridHeadingMenu.QUOTE_ORIGINAL_PRICE}</Paragraph>
                                )}
                                <Grid container sx={cssObject.quotePriceLayout}>
                                    <Grid item xs={11} md={9} lg={9}>
                                        <Paragraph semibold>{currencyFormat.format(prices.grandTotal)}</Paragraph>
                                        {quotationJson.appliedCoupons && quotationJson.appliedCoupons.length > 0 && (
                                            <Grid sx={{ mb: { sm: 0, md: -4.5 }, mt: 0.5 }}>
                                                <Grid container sx={cssObject.quotePriceLayout}>
                                                    <Box display="flex">
                                                        <span>
                                                            <Icon
                                                                style={{
                                                                    color: theme.customProperties.svgPrimaryColor,
                                                                    fontSize: '15px',
                                                                    fontWeight: 900,
                                                                }}
                                                            >
                                                                check_icon
                                                            </Icon>
                                                        </span>
                                                        <Paragraph fontSizeVerySmall sx={{ ml: 0.25 }}>
                                                            Applied Promo code {quotationJson.appliedCoupons[0].code}
                                                        </Paragraph>
                                                    </Box>
                                                </Grid>
                                                {prices.discounts && prices.discounts.length > 0 && (
                                                    <Paragraph fontSizeVerySmall sx={{ pl: 1.75, mt: -0.5 }}>
                                                        Received {currencyFormat.format(prices.discounts[0].amount)}{' '}
                                                        discount
                                                    </Paragraph>
                                                )}
                                            </Grid>
                                        )}
                                    </Grid>
                                    <Grid item lg={3} xs={1} md={3}>
                                        <QuoteDropdown
                                            menuList={getDropdownMenus(status)}
                                            handleMenuClick={onMenuClick}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                        )}
                    </Grid>
                </ContentContainer>
            </Container>
        </ContentContainer>
    );
}

export default QuoteDetail;
