import React from 'react';
import { Theme } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import { Button } from '../Button';
import { Heading } from '../Heading';
import { Paragraph } from '../Paragraph';
import ContentContainer from 'components/ContentContainer';
import { Container, useMediaQuery, useTheme } from '@mui/material';
import { useNavigate } from 'react-router';
import { AppRoute } from 'models/route';
import HomeScheduleBgKc from 'components/icons/HomeScheduleBgKc';
import { isStoreNone } from 'config';
import { SXCssObject } from 'models/ThemeImages';

const createCssObject = (theme: Theme): SXCssObject => ({
    title: {
        pb: '20px',
    },
    mainContainer: {
        overflow: 'hidden',
    },
    rootPadding: {
        textAlign: 'center',
        py: {
            xs: '30px',
            sm: '60px',
            md: '80px',
            lg: '80px',
            xl: '80px',
        },
    },
    headingStyle: {
        marginBottom: {
            xs: '16px',
            sm: '12px',
            md: '12px',
            lg: '12px',
            xl: '12px',
        },
    },
    details: {
        py: '20px',
        px: {
            xs: '3%',
            sm: '14%',
            md: '14%',
            lg: '14%',
            xl: '14%',
        },
    },
    centerContent: {
        display: 'flex',
        justifyContent: 'center',
    },
    scheduleWrapper: {
        display: 'flex',
        justifyContent: 'flex-end',
    },
});

interface Props {
    title: string;
    detail?: any;
}

export default function ScheduleYourInstallation(props: Props) {
    const navigate = useNavigate();

    const cssObject: SXCssObject = createCssObject(useTheme());
    const theme = useTheme();
    const downXs = useMediaQuery(theme.breakpoints.only('xs'));

    return (
        <ContentContainer sx={cssObject.mainContainer} disableGrid>
            <Container maxWidth={'lg'} disableGutters>
                <ContentContainer sx={cssObject.rootPadding}>
                    <Heading variant={'h3'} color={'primary'} label={props.title} />
                    <Grid sx={cssObject.details}>
                        <Paragraph>{props.detail}</Paragraph>
                    </Grid>
                    <Grid item xs={12} sx={cssObject.centerContent}>
                        <Button
                            id={'sub-content-get-started-btn'}
                            color={'primary'}
                            isFullWidth={downXs}
                            homePageLargeButton
                            onClick={() => {
                                !isStoreNone()
                                    ? navigate(AppRoute.AboutYourHome)
                                    : navigate(AppRoute.Home, { state: { scrollToStores: true } });
                            }}
                        >
                            Let's Get Started
                        </Button>
                    </Grid>
                    <HomeScheduleBgKc sx={cssObject.scheduleIconBg} />
                </ContentContainer>
            </Container>
        </ContentContainer>
    );
}
