import { combineEpics, Epic } from 'redux-observable';
import { Action } from 'ts-action';
import { ofType } from 'ts-action-operators';
import { EMPTY, Observable } from 'rxjs';
import { switchMap, ignoreElements, catchError, withLatestFrom, map, exhaustMap } from 'rxjs/operators';
import { RootState } from '../reducers';
import {
    createContact as createContactAction,
    contactInprogress as contactInprogressAction,
    contactOpendeal as contactOpendealAction,
    contactPageViewAction,
    contactPageViewSuccess,
    createContactSuccess,
    contactInprogressSuccess,
} from '../actions/hubspotActions';
import {
    createContact,
    contactInprogress,
    contactQuotedOptions,
    contactOpendeal,
    contactPageView,
} from '../services/hubspotSevices';
import { selectCart } from '../selectors/cart';
import { selectLoginState } from 'store/selectors/loginSelector';
import { Cart, ContactInformation, ItemType } from 'models/cart';

export const createContact$: Epic = (action$: Observable<Action>) =>
    action$.pipe(
        ofType(createContactAction),
        switchMap((action) =>
            createContact(action.payload).pipe(
                map(() => createContactSuccess({ secondSystem: false })),
                catchError(() => {
                    return EMPTY;
                })
            )
        )
    );

export const contactPageView$: Epic = (action$: Observable<Action>) =>
    action$.pipe(
        ofType(contactPageViewAction),
        exhaustMap((action) =>
            contactPageView(action.payload).pipe(
                map(() => contactPageViewSuccess()),
                catchError(() => {
                    return EMPTY;
                })
            )
        )
    );

//Whenever cart is created/updated, price,name,qty of complete cart is sent to hubspot.
export const updateContactInProgress$ = (action$: Observable<Action>, state$: Observable<RootState>) =>
    action$.pipe(
        ofType(contactInprogressAction),
        withLatestFrom(state$.pipe(map(selectLoginState))),
        switchMap(([action, loginData]) => {
            let getDataFromCart: ContactInformation;
            getDataFromCart = passDataToInProgress(action.payload, loginData.email);
            return contactInprogress(getDataFromCart).pipe(
                map(() => contactInprogressSuccess(getDataFromCart)),
                catchError((error) => {
                    console.error(error);
                    return EMPTY;
                })
            );
        }),
        ignoreElements()
    );

const passDataToInProgress = (cart: Cart, email: string) => {
    const itemMain = cart.items!.find((cartItem) => cartItem.itemType === ItemType.MainSystem);
    const itemIAQ = cart!.items!.find((cartItem) => cartItem.itemType === ItemType.CrossSell);
    const cartAddons = cart!.items!.filter((cartItem) => cartItem.itemType === ItemType.AddOn);

    let mainProduct = '';
    let obj = {
        selectedPackage: ' ',
        otherProductsQuoted: ' ',
        email: email,
    };
    let OtherProducts: string[] = [];
    if (itemMain) {
        mainProduct = `${itemMain.name} (${itemMain.productAttributes.level}) - $${itemMain.price}`;
        obj.selectedPackage = mainProduct;
    }
    if (itemIAQ) {
        OtherProducts.push(`${itemIAQ.name} (${itemIAQ.productAttributes.level}) - $${itemIAQ.price}`);
    }
    if (cartAddons && cartAddons.length > 0) {
        for (let index = 0; index < cartAddons.length; index++) {
            const { name, quantity, price } = cartAddons[index];
            OtherProducts.push(`${name}(${quantity}) - $${price * quantity}`);
        }
    }

    if (OtherProducts && OtherProducts.length) {
        obj.otherProductsQuoted = OtherProducts.join(';');
    }
    return obj;
};

export const updateContactQuotedOptions$ = (action$: Observable<Action>, state$: Observable<RootState>) =>
    action$.pipe(
        ofType(createContactSuccess),
        withLatestFrom(state$.pipe(map(selectCart))),
        switchMap(([action, cart]) => {
            if (
                !action.payload.secondSystem &&
                cart.contactInformation &&
                !isEmpty(cart.contactInformation.quotedOptions)
            ) {
                return contactQuotedOptions({ ...cart.contactInformation }).pipe(
                    catchError((error) => {
                        console.error(error);
                        return EMPTY;
                    })
                );
            } else if (
                action.payload.secondSystem &&
                cart.contactInformation &&
                !isEmpty(cart.contactInformation.quotedOptionsB)
            ) {
                return contactQuotedOptions({ ...cart.contactInformation }).pipe(
                    catchError((error) => {
                        console.error(error);
                        return EMPTY;
                    })
                );
            } else return EMPTY;
        }),
        ignoreElements()
    );

export const updateContactOpendeal$ = (action$: Observable<Action>) =>
    action$.pipe(
        ofType(contactOpendealAction),
        switchMap((action) =>
            contactOpendeal(action.payload).pipe(
                catchError((error) => {
                    console.error(error);
                    return EMPTY;
                })
            )
        ),
        ignoreElements()
    );

const isEmpty = (object?: object) => {
    return object ? Object.values(object).every((x) => x === null || x === '' || x === undefined) : null;
};

export default combineEpics(
    contactPageView$,
    createContact$,
    updateContactInProgress$,
    updateContactQuotedOptions$,
    updateContactOpendeal$
);
