import { on, reducer } from 'ts-action';
import { getMyOrdersSuccess, getMyOrdersError, getMyOrders } from '../actions/orderActions';
import { Order } from '../../models/order';

interface MyOrdersState {
    loading: boolean;
    error: Error | null;
    myOrders: Order[];
}
const initialState: MyOrdersState = {
    loading: false,
    error: null,
    myOrders: [],
};

export const myOrdersReducer = reducer(
    initialState,
    on(getMyOrders, (state) => ({
        ...state,
        loading: true,
        error: null,
    })),
    on(getMyOrdersSuccess, (state, action) => ({
        ...state,
        myOrders: action.payload,
        loading: false,
        error: null,
    })),
    on(getMyOrdersError, (state, action) => ({
        ...state,
        loading: false,
        error: action.payload,
    }))
);

export default myOrdersReducer;
