import { Theme } from '@mui/material';
import { AxiosRequestConfig } from 'axios';
import { showSnackbarError } from './Snackbar/SnackbarHelper';
export const extractErrorFromResponseOrShowDefaultMsg = (err: any, defaultMsg: string) => {
    let msg = defaultMsg;
    try {
        if (err?.response?.data?.error?.message) {
            msg = err.response.data.error.message;
        } else if (err.message) {
            msg = err.message;
        }
    } catch (err) {
        return msg;
    }
    showSnackbarError(msg);
    return msg;
};

export const axiosRequestConfigForPdfDownload: AxiosRequestConfig = {
    responseType: 'arraybuffer',
    headers: {
        'Content-Type': 'application/json',
        Accept: 'application/pdf',
    },
};

export const isFromRoute = (route: string) => {
    if (window.location.pathname === route) {
        return true;
    } else {
        return false;
    }
};

export const getThemePrimaryMain = (theme: Theme) => {
    return theme.customProperties.svgPrimaryColor;
};

export const getThemePrimaryLight = (theme: Theme) => {
    return theme.customProperties.svgPrimaryLightColor;
};

export const getThemeSecondaryMain = (theme: Theme) => {
    return theme.palette.secondary.main;
};
export const getThemeSvgPrimaryMain = (theme: Theme) => {
    return theme.palette.primary.main;
};
