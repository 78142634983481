import * as React from 'react';
import { useTheme } from '@mui/material/styles';
import { getThemePrimaryMain } from 'components/common/Utilities';

function LimitedEquipmentWarranty(props: any) {
    const theme = useTheme();
    const themePrimaryMain = getThemePrimaryMain(theme);
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={40} height={40} {...props}>
            <defs>
                <style>{'.prefix__b2{fill:none;stroke-linecap:round;stroke-linejoin:round;stroke-width:1.5px}'}</style>
            </defs>
            <path
                className="prefix__b2"
                stroke={`${themePrimaryMain}`}
                d="M19.857 32.297l5.693-4.331a12.784 12.784 0 003.39-3.729 13.276 13.276 0 001.774-6.629V9.543a1.839 1.839 0 00-1.839-1.839H10.839A1.839 1.839 0 009 9.543v7.727a14.088 14.088 0 002.4 7.863 12.7 12.7 0 002.948 3.018l5.511 4.146z"
            />
            <path stroke={`${themePrimaryMain}`} className="prefix__b2" d="M16.038 18.343l2.825 3.076 5.7-5.882" />
        </svg>
    );
}

export default LimitedEquipmentWarranty;
