import { useTheme } from '@mui/material/styles';
import { getThemePrimaryMain } from 'components/common/Utilities';
import * as React from 'react';

function FullInstallation(props: any) {
    const theme = useTheme();
    const themePrimaryMain = getThemePrimaryMain(theme);
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={40} height={40} {...props}>
            <defs>
                <style>{'.prefix__b{fill:none;stroke-linecap:round;stroke-linejoin:round;stroke-width:1.5px}'}</style>
            </defs>
            <path
                className="prefix__b"
                stroke={`${themePrimaryMain}`}
                d="M28.456 9H11.514A2.515 2.515 0 009 11.515v17.118a2.489 2.489 0 00.048.476l6.168-6.756-6.168 6.756a2.512 2.512 0 002.466 2.038h1.348l5.479-6-5.479 6h15.594a2.514 2.514 0 002.514-2.514V11.515A2.515 2.515 0 0028.456 9zM24.15 23.662a5.385 5.385 0 11-2.179-8.708l-2.648 2.9.189 2.9 2.871-.037 2.683-2.939a5.385 5.385 0 01-.916 5.884z"
            />
            <path
                className="prefix__b"
                stroke={`${themePrimaryMain}`}
                d="M22.379 20.719l-2.871.037-.189-2.9 2.648-2.9a5.385 5.385 0 103.095 2.826z"
            />
        </svg>
    );
}

export default FullInstallation;
