import Grid from '@mui/material/Grid';
import AddOnProducts from 'components/AddOnProducts';
import CartSummary from 'components/CartSummary';
import ContentContainer from 'components/ContentContainer';
import { Address, CartItem, CartPrice, CartStatus, ItemType, SystemGroup, WizardSelectedOptions } from 'models/cart';
import { RelatedProduct } from 'models/product';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    deleteCart,
    deleteMainAndMakeIAQMain,
    deleteMultipleItems,
    resetAddonSelection,
    resetSelection,
    setAddOnCompabilityCheck,
    setAddonSelection,
    setIAQSelection,
    setMainSelection,
    shippingAddressCaptured,
} from 'store/actions/cartActions';
import { changeProduct, changeProductIAQ, resetProductSearch } from 'store/actions/productSearchActions';
import { selectProductsIAQ } from 'store/selectors/productSearch';
import CartItemDetail from '../../components/CartItemDetail';
import { Theme, useTheme } from '@mui/material/styles';
import { Box, useMediaQuery } from '@mui/material';
import AddOnPopup from 'components/AddOnPopup';
import { ConfirmationModal } from '../../components/common/ConfirmationModal';
import { DeleteIAQModal } from 'pages/cart/DeleteIAQModal';
import { DoubleSystemWizardState, HomeSystem, NumberOfHVAC, ProductCriteria, TypeOfService } from 'models/productCriteria';
import { useNavigate } from 'react-router-dom';
import { AppRoute } from 'models/route';
import { setDoubleSystemFlag, clearWizarQuestionData } from 'store/actions/productCriteriaActions';
import { isCartForEquipmentOnly, selectCart, selectCartItems, selectNonCompatibleAddOns } from 'store/selectors/cart';
import DeleteIcon from 'components/icons/DeleteIcon';
import AddAddressIcon from 'components/icons/AddAddressIcon';
import { Paragraph } from 'components/Paragraph';
import { SXCssObject } from 'models/ThemeImages';
import {
    invokeGASystemGroupDeletePopup,
    invokeGAItemDeletePopup,
    invokeGACartDeleteIconClicked,
    invokeGAAddAnotherSystemFromCart,
    invokeGACartChangeClicked
} from 'components/Analytics/GoogleAnalyticsTagging';
import { taxFromCartPrice, discountFromCartPrice, isCartOpenForModification } from './cart-utils';
import PromoCode from 'pages/payment/PromoCode';
import { selectMultipleOpenProjects } from 'store/selectors/storeSelector';
import { Heading } from 'components/Heading';
interface Props {
    cartStatus: CartStatus | undefined;
    cartItems: CartItem[];
    cartPrices: CartPrice;
    shouldCheckForAddOnCompatibility: boolean | undefined;
    shippingAddress?: Address;
    wizardSelectedOptions?: WizardSelectedOptions;
    onLoader: (loaderState: boolean) => void;
}

const createCssObject = (theme: Theme): SXCssObject => ({
    tabScrollControl: {
        overflowX: 'auto',
        msOverflowStyle: 'none' /* IE and Edge */,
        scrollbarWidth: 'none',
        '&::-webkit-scrollbar': {
            display: 'none',
        },
    },
    tabHeaderContainer: {
        display: 'flex',
        width: {
            xs: '140%',
            sm: '80%',
            md: '66%',
            lg: '62%',
            xl: '60%',
        },
        marginBottom: {
            xs: '0px',
            lg: '24px',
            xl: '16px',
        },
        backgroundColor: {
            xs: theme.palette.background.default,
            sm: 'inherit',
        },
        padding: {
            xs: '8px 0px 8px 16px',
            sm: 'inherit',
        },
        minWidth: {
            sm: '500px',
            md: 'inherit',
        },
        maxWidth: {
            lg: '1000px',
        },
    },
    tabSelected: {
        backgroundColor: theme.palette.common.white,
        padding: {
            sm: '10px',
        },
    },
    tabAddAnotherSystem: {
        border: `1px solid ${theme.palette.primary.contrastText}`,
        backgroundColor: theme.palette.common.white,
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        fontWeight: theme.typography.fontWeightMedium,
    },
    addAnotherSystemWrapper: {
        justifyContent: 'center',
        alignItems: 'center',
        display: 'flex',
    },
    addAnotherSystemIconStyle: {
        width: '20px',
        height: '20px',
        marginRight: '8px',
    },
    tabNonSelected: {
        border: '1px solid #CCCCCC',
        backgroundColor: '#F5F5F5',
    },
    tabHeaderStyle: {
        width: '50%',
        cursor: 'pointer',
        display: 'flex',
        borderRadius: '4px',
        alignContent: 'center',
        WebkitTapHighlightColor: 'transparent',
        marginRight: {
            xs: '8px',
            sm: 'inherit',
        },
    },
    tabHeadingContentSelected: {
        display: 'flex',
        width: '100%',
        flexDirection: 'row',
        outline: 'none',
        border: `1px solid ${theme.palette.primary.contrastText}`,
        backgroundColor: theme.palette.primary.light,
        padding: '16px',
        borderRadius: '4px',
    },
    tabHeadingContentNonSelected: {
        display: 'flex',
        width: '100%',
        flexDirection: 'row',
        outline: 'none',
        padding: '16px',
    },
    tabsHeader: {
        boxShadow: 'none',
    },
    tabHeader: {
        backgroundColor: '#FFFFFF',
    },
    headerIndicator: {
        color: theme.palette.common.white,
        backgroundColor: 'transparent',
        transition: 'none',
    },
    iconStyle: {
        width: '24px',
        height: '24px',
    },
    icons: {
        flex: 1,
        marginLeft: {
            xs: '10px',
            md: '16px',
        },
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
    },
    centerColumn: {
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        flex: 6,
    },
    tabheadingText: {
        fontWeight: theme.typography.fontWeightMedium,
        marginTop: '4px',
        color: theme.typography.h1.color,
        fontSize: {
            xs: '1rem',
            sm: '1.125rem',
            lg: '1.25rem',
        },
    },
    tabSubheading: {
        marginTop: {
            xs: '6px',
            sm: 'inherit',
        },
    },
    deleteIconSection: {
        display: 'inline-flex',
        height: '100%',
        width: '100%',
        justifyContent: 'center',
        flexDirection: 'column',
    },
    hideVisibilityStyle: {
        display: 'none',
    },
    deleteIconWrapper: {
        display: 'flex',
        cursor: 'pointer',
        padding: '10px 16px',
        justifyContent: 'center',
        alignItems: 'center',
        border: '1px solid #CECECE',
        borderRadius: theme.typography.button.borderRadius,
        '&:hover': {
            backgroundColor: `${theme.palette.action.disabled} !important`,
        },
        backgroundColor: {
            xs: theme.palette.background.default,
            sm: 'inherit',
        },
    },
    deleteImg: {
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        paddingRight: '12px',
        '& svg': {
            height: '24px',
            width: '24px',
        },
    },
    deleteButton: {
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
        margin: '0px',
    },
    deleteText: {
        color: theme.palette.action.active,
        fontWeight: theme.typography.fontWeightMedium,
    },
    addOnBlock: {
        marginTop: {
            sm: '20px',
        },
    },
    deleteIconNew: {
        padding: '8px',
        background: 'white',
        width: '40px',
        height: '40px',
        cursor: 'pointer',
    },
    dividerStyle: {
        display: {
            sm: 'none',
        },
        opacity: '0.4',
        width: '100%',
        border: 'none',
        margin: '24px 0px 4px',
        borderBottom: `1px solid ${theme.customProperties.borderDivider}`,
    },
});
function CartPageTabsSection(props: Props) {
    const { cartStatus, cartItems, cartPrices, shouldCheckForAddOnCompatibility, onLoader } = props;
    const mainSystemsInCart = cartItems.filter((cartItem) => cartItem.itemType === ItemType.MainSystem);
    const IAQInCart = cartItems.filter((cartItem) => cartItem.itemType === ItemType.CrossSell);
    const firstSystem = cartItems.filter((cartItem) => cartItem.systemGroup === SystemGroup.SystemGroup1);
    const secondSystem = cartItems.filter((cartItem) => cartItem.systemGroup === SystemGroup.SystemGroup2);
    const theme = useTheme();
    const isXs = useMediaQuery(theme.breakpoints.only('xs'));
    const isSm = useMediaQuery(theme.breakpoints.up('sm'));
    const [itemIAQ, setItemIAQ] = React.useState<CartItem>();
    const dispatch = useDispatch();
    const productsIAQ = useSelector(selectProductsIAQ);
    const cartItemsObj = useSelector(selectCartItems);
    const { firstSystemMain, secondSystemMain, firstSystemIAQ, secondSystemIAQ, addOnsList } = cartItemsObj;
    const summarySubtotal = cartPrices.subtotalExcludingTax | 0;
    const taxesAverage = taxFromCartPrice(cartPrices);
    const discount = discountFromCartPrice(cartPrices);
    const [selectedTab, setSelectedTab] = React.useState(0);
    let itemMain = (() => (selectedTab === 1 ? secondSystemMain : firstSystemMain))();
    const nonCompatibleAddOns = useSelector(selectNonCompatibleAddOns);
    const [isIAQModalVisible, setIsIAQModalVisible] = React.useState(false);
    const [isAddOnDeleteModalVisible, setAddOnDeleteModalVisibility] = React.useState(false);
    const [isConfirmDeleteModalVisible, setConfirmDeleteModalVisibility] = React.useState(false);
    const [sysToDelete, setSystemToDelete] = React.useState<{ systemGroup: SystemGroup; itemType?: ItemType }>();
    const multipleOpenProjects = useSelector(selectMultipleOpenProjects);
    const cartData = useSelector(selectCart);

    const isCartOpen = React.useMemo(() => isCartOpenForModification(cartData.status), [cartData.status]);
    const isEquipmentOnlyCart = useSelector(isCartForEquipmentOnly);

    React.useEffect(() => {
        if (IAQInCart) {
            if (selectedTab === 0 && firstSystemIAQ) {
                setItemIAQ(firstSystemIAQ);
            } else if (selectedTab === 0) {
                setItemIAQ(undefined);
            }
            if (selectedTab === 1 && secondSystemIAQ) {
                setItemIAQ(secondSystemIAQ);
            } else if (selectedTab === 1) {
                setItemIAQ(undefined);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [IAQInCart]);

    //Whenever delete/change operation is performed in cart, shouldCheckForAddOnCompatibility is set to true
    //If it is true, addOn compatbility is checked. If any addon is irrelevant to mainProduct(s) in cart,then show popup
    React.useEffect(() => {
        if (shouldCheckForAddOnCompatibility) {
            if (nonCompatibleAddOns.length > 0) {
                setAddOnDeleteModalVisibility(true);
            }
            dispatch(setAddOnCompabilityCheck(false));
        }
    }, [shouldCheckForAddOnCompatibility, nonCompatibleAddOns.length, dispatch]);
    const checkForIAQ = () => {
        const itemMain = mainSystemsInCart.find((mainSystemInCart) => {
            return (
                mainSystemInCart.productAttributes &&
                mainSystemInCart.productAttributes.systemType &&
                mainSystemInCart.productAttributes.systemType.toLowerCase().indexOf('ductless') === -1 &&
                mainSystemInCart.productAttributes.systemType.toLowerCase().indexOf('iaq') === -1
            );
        });
        if (itemMain) {
            return true;
        } else {
            return false;
        }
    };
    const cssObject: SXCssObject = createCssObject(useTheme());
    const showConfirmationAndDeleteSystemGroup = (systemIndex: Number) => {
        if (systemIndex === 0) {
            setSystemToDelete({ systemGroup: SystemGroup.SystemGroup1 });
        } else {
            setSystemToDelete({ systemGroup: SystemGroup.SystemGroup2 });
        }
        invokeGASystemGroupDeletePopup();
        setConfirmDeleteModalVisibility(true);
    };
    const showConfirmationAndDeleteCartItem = (itemType: ItemType) => {
        setSystemToDelete({
            systemGroup: selectedTab === 0 ? SystemGroup.SystemGroup1 : SystemGroup.SystemGroup2,
            itemType,
        });
        setConfirmDeleteModalVisibility(true);
        invokeGAItemDeletePopup();
    };

    const isDuctless = () => {
        if (itemMain && itemMain.productAttributes) {
            return itemMain.productAttributes.systemType.toLowerCase().indexOf('ductless') !== -1;
        }
        return false;
    };
    const isMainProductIAQ = () => {
        if (itemMain && itemMain.productAttributes) {
            return itemMain.productAttributes.systemType.toLowerCase().indexOf('iaq') !== -1;
        }
        return false;
    };
    const handleChangeIndex = (index: number, e: any) => {
        if (selectedTab === index) {
            return;
        }
        setSelectedTab(index);
        itemMain = mainSystemsInCart![index];
        if (IAQInCart) {
            if (index === 0 && firstSystemIAQ) {
                setItemIAQ(firstSystemIAQ);
            } else if (index === 0) {
                setItemIAQ(undefined);
            }
            if (index === 1 && secondSystemIAQ) {
                setItemIAQ(secondSystemIAQ);
            } else if (index === 1) {
                setItemIAQ(undefined);
            }
        }
    };

    const shouldShowTab = () => {
        if (
            (firstSystem.length > 0 && secondSystem.length > 0) ||
            (props.wizardSelectedOptions &&
                props.wizardSelectedOptions.numberOfSystems === NumberOfHVAC.Double &&
                isCartOpen)
        ) {
            return true;
        }
    };

    /**
     * Show add-on section based on
    1. If status is READY_TO_SCHEDULE or empty, show add on section only if main product has addons so that user can modify it.
    2. If status is READY_FOR_PAYMENT or PENDING_INSPECTION, show this section only if addons are already in cart.
    **/
    const getUniqueAddOnsFromProduct = (addOnRelatedProducts: RelatedProduct[]) => {
        const uniqueValuesSet = new Set();
        const uniqueAddons = addOnRelatedProducts?.filter((obj) => {
            const isPresentInSet = uniqueValuesSet.has(obj.sku);
            uniqueValuesSet.add(obj.sku);
            return !isPresentInSet;
        });
        const nonIAQAddons =
            uniqueAddons &&
            uniqueAddons.filter((uniqueAddon) => {
                return uniqueAddon.sku.indexOf('IAQ') === -1;
            });
        return nonIAQAddons;
    };

    const getSystemType = (cartItem: CartItem, index: Number) => {
        const systemType = cartItem && cartItem.productAttributes && cartItem.productAttributes.systemType;
        if (systemType) {
            if (systemType?.indexOf('IAQ') !== -1) {
                return 'Indoor Air Quality Package';
            } else if (systemType?.indexOf('Ductless') !== -1) {
                return 'Ductless System';
            } else if (
                systemType === 'AC & Coil Only' ||
                systemType === 'Furnace Upgrade Only' ||
                systemType === 'Furnace Only'
            ) {
                return 'Partial HVAC';
            } else return 'Complete HVAC';
        }
        return `System ${index}`;
    };



    const totalAddOnsAvailable = () => {
        if (mainSystemsInCart && mainSystemsInCart.length > 0) {
            let combinedRelatedProducts: RelatedProduct[] = [];
            mainSystemsInCart.forEach((mainProduct) => {
                combinedRelatedProducts.push(...mainProduct.relatedProducts);
            });
            const totalAddOnCount = combinedRelatedProducts && combinedRelatedProducts.length > 0;
            if (totalAddOnCount && isCartOpen) {
                //return count only if status is empty or yet to be scheduled
                return getUniqueAddOnsFromProduct(combinedRelatedProducts).length;
            } else if (
                cartStatus &&
                (cartStatus === CartStatus.PENDING_INSPECTION || cartStatus === CartStatus.READY_FOR_PAYMENT) &&
                addOnsList &&
                addOnsList.length > 0
            ) {
                return getUniqueAddOnsFromProduct(combinedRelatedProducts).length;
            }
        }
        return 0;
    };
    const canShowIAQInCart = () => {
        if (cartStatus === CartStatus.PENDING_INSPECTION || cartStatus === CartStatus.READY_FOR_PAYMENT) {
            if (selectedTab === 0) {
                return firstSystemIAQ;
            } else if (selectedTab === 1) {
                return secondSystemIAQ;
            }
        }
        return true;
    };
    const navigate = useNavigate();
    const handleDeleteCart = (shouldProceed?: boolean) => {
        setConfirmDeleteModalVisibility(false);
        if (shouldProceed && sysToDelete) {
            let itemsToDelete = cartItems.filter((item) => item.systemGroup === sysToDelete.systemGroup);
            let completeSystemGroupItems = [...itemsToDelete];
            if (sysToDelete.itemType) {
                itemsToDelete = itemsToDelete.filter((item) => item.itemType === sysToDelete.itemType);
            }
            if (sysToDelete.itemType && sysToDelete.itemType === ItemType.MainSystem) {
                const isIAQInSystem = completeSystemGroupItems.find((item) => item.itemType === ItemType.CrossSell);
                if (isIAQInSystem) {
                    setIsIAQModalVisible(true); //show iaq modal
                    return;
                }
                setSelectedTab(0);
            } else if (!sysToDelete.itemType) {
                setSelectedTab(0);
            }
            callDeleteApi(itemsToDelete);
        } else if (shouldProceed && !sysToDelete) {
            setConfirmDeleteModalVisibility(false);
            if (shouldProceed) {
                dispatch(resetProductSearch());
                dispatch(resetSelection());
                invokeGACartDeleteIconClicked();
                dispatch(deleteCart({ shouldRedirectToHome: true }));
            }
        }
    };

    const callDeleteApi = (itemsToDelete: CartItem[]) => {
        if (sysToDelete) {
            const shouldRearrange =
                sysToDelete.systemGroup === SystemGroup.SystemGroup1 &&
                (!sysToDelete.itemType || sysToDelete.itemType === ItemType.MainSystem);
            shouldRedirectToHomeAfterDelete(itemsToDelete)
                ? dispatch(deleteCart({ shouldRedirectToHome: true }))
                : dispatch(
                    deleteMultipleItems({
                        itemsToDelete: itemsToDelete,
                        reArrangeCartItems: shouldRearrange,
                    })
                );
        }
    };

    const shouldRedirectToHomeAfterDelete = (itemsToDelete: CartItem[]) => {
        const mainAndIAQsAfterDelete = cartItems.filter((item) => {
            if (item.itemType === ItemType.AddOn || itemsToDelete.find((item2) => item2.id === item.id)) {
                return false;
            }
            return true;
        });
        return mainAndIAQsAfterDelete.length <= 0;
    };

    const handleAddAnotherSystemClick = () => {
        invokeGAAddAnotherSystemFromCart();
        const typeOfService = isEquipmentOnlyCart ? TypeOfService.EquipmentOnly : TypeOfService.FullyInstalled;
        dispatch(clearWizarQuestionData({ numberOfHVAC: NumberOfHVAC.Double, typeOfService: typeOfService }));
        dispatch(setDoubleSystemFlag({ doubleSystemFlag: DoubleSystemWizardState.Second }));
        navigate(AppRoute.AboutYourHome, { state: { isSecondSystem: true } });
        dispatch(resetSelection());
        dispatch(setMainSelection([mainSystemsInCart[0]]));
        if (IAQInCart && IAQInCart.length > 0) {
            dispatch(setIAQSelection({ newIAQ: IAQInCart[0], systemGroupToSetOrChange: SystemGroup.SystemGroup1 }));
        }
        const addOnToSet = getUniqueAddOnsFromProduct(mainSystemsInCart[0].relatedProducts);
        const addOnWithQuantityInCart = addOnToSet.filter((item) => {
            const isInCart = addOnsList && addOnsList.find((i) => i.sku === item.sku);
            if (!isInCart) {
                item.quantity = 0;
            } else {
                item.quantity = isInCart.quantity;
            }
            return true;
        });
        dispatch(setAddonSelection({ addOnsList: [...addOnWithQuantityInCart], shouldReplace: true }));
        dispatch(shippingAddressCaptured());
    };

    const handleChangeIAQ = () => {
        invokeGACartChangeClicked();
        dispatch(resetAddonSelection());
        if (productsIAQ) {
            navigate(AppRoute.IAQOptions, {
                state: {
                    systemGroup: selectedTab === 1 ? SystemGroup.SystemGroup2 : SystemGroup.SystemGroup1,
                    isSecondSystem: selectedTab === 1 ? true : false,
                    isByClickingChangeInCartPage: true,
                    isFullSystem: 'isFullSystem'
                },
            });
        } else {
            const emptyCriteria: ProductCriteria = {};
            dispatch(
                changeProductIAQ({
                    productCriteria: { ...emptyCriteria, homeSystem: HomeSystem.IAQ },
                    systemGroup: selectedTab === 1 ? SystemGroup.SystemGroup2 : SystemGroup.SystemGroup1,
                })
            );
        }
    };
    return (
        <ContentContainer disableGrid>
            <ConfirmationModal
                open={isConfirmDeleteModalVisible}
                handleClose={(shouldProceed?: boolean) => handleDeleteCart(shouldProceed)}
                cancelButtonText={'NO, KEEP IT'}
                confirmButtonText={'YES, DELETE'}
                confirmMsg={'Do you really want to remove the system(s) in cart?'}
            />
            <DeleteIAQModal
                open={isIAQModalVisible}
                itemIAQ={(() => {
                    const iaqToShow = cartItems.find(
                        (item) => item.systemGroup === sysToDelete?.systemGroup && item.itemType === ItemType.CrossSell
                    );
                    return iaqToShow!;
                })()}
                handleClose={(shouldDelete) => {
                    if (shouldDelete) {
                        const itemsToDelete = cartItems.filter((item) => item.systemGroup === sysToDelete?.systemGroup);
                        callDeleteApi(itemsToDelete);
                        setSelectedTab(0);
                    } else {
                        //dispatch delete main && updateIAQ
                        dispatch(deleteMainAndMakeIAQMain(sysToDelete!.systemGroup));
                    }
                    setIsIAQModalVisible(false);
                }}
            />
            <AddOnPopup
                open={isAddOnDeleteModalVisible}
                nonCompatibleAddOns={nonCompatibleAddOns}
                onClose={() => {
                    setAddOnDeleteModalVisibility(false);
                }}
            />
            {shouldShowTab() && (
                <Box sx={cssObject.tabScrollControl}>
                    <ContentContainer disableGrid sx={cssObject.tabHeaderContainer}>
                        <Box
                            onClick={(e) => {
                                handleChangeIndex(0, e);
                            }}
                            sx={[
                                cssObject.tabHeaderStyle,
                                selectedTab === 0 && cssObject.tabSelected,
                                selectedTab === 1 && cssObject.tabNonSelected,
                            ]}
                        >
                            <Box
                                sx={[
                                    selectedTab === 0 && cssObject.tabHeadingContentSelected,
                                    selectedTab === 1 && cssObject.tabHeadingContentNonSelected,
                                ]}
                            >
                                <Box sx={cssObject.centerColumn}>
                                    <Box sx={cssObject.tabSubheading}>
                                        <Paragraph fontSizeVerySmall={isXs}>Your First Replacement</Paragraph>
                                    </Box>
                                    <Box sx={cssObject.tabheadingText}>{getSystemType(mainSystemsInCart![0], 0)}</Box>
                                </Box>
                                <Box
                                    sx={[
                                        cssObject.icons,
                                        (cartStatus === CartStatus.PENDING_INSPECTION ||
                                            cartStatus === CartStatus.READY_FOR_PAYMENT) &&
                                        cssObject.hideVisibilityStyle,
                                    ]}
                                >
                                    <Box
                                        onClick={() => showConfirmationAndDeleteSystemGroup(0)}
                                        sx={cssObject.deleteIconNew}
                                    >
                                        <DeleteIcon />
                                    </Box>
                                </Box>
                            </Box>
                        </Box>
                        {mainSystemsInCart.length > 1 ? (
                            <Box
                                onClick={(e) => {
                                    handleChangeIndex(1, e);
                                }}
                                sx={[
                                    cssObject.tabHeaderStyle,
                                    selectedTab === 1 && cssObject.tabSelected,
                                    selectedTab === 0 && cssObject.tabNonSelected,
                                ]}
                            >
                                <Box
                                    sx={[
                                        selectedTab === 1 && cssObject.tabHeadingContentSelected,
                                        selectedTab === 0 && cssObject.tabHeadingContentNonSelected,
                                    ]}
                                >
                                    <Box sx={cssObject.centerColumn}>
                                        <Box sx={cssObject.tabSubheading}>
                                            <Paragraph fontSizeVerySmall={isXs}>Your Second Replacement</Paragraph>
                                        </Box>
                                        <Box sx={cssObject.tabheadingText}>
                                            {getSystemType(mainSystemsInCart![1], 1)}
                                        </Box>
                                    </Box>
                                    <Box
                                        sx={[
                                            cssObject.icons,
                                            (cartStatus === CartStatus.PENDING_INSPECTION ||
                                                cartStatus === CartStatus.READY_FOR_PAYMENT) &&
                                            cssObject.hideVisibilityStyle,
                                        ]}
                                    >
                                        <Box
                                            onClick={() => showConfirmationAndDeleteSystemGroup(1)}
                                            sx={cssObject.deleteIconNew}
                                        >
                                            <DeleteIcon />
                                        </Box>
                                    </Box>
                                </Box>
                            </Box>
                        ) : isCartOpen ? (
                            <Box
                                onClick={(e) => {
                                    handleAddAnotherSystemClick();
                                }}
                                sx={[cssObject.tabHeaderStyle, cssObject.tabAddAnotherSystem]}
                            >
                                <Box sx={cssObject.addAnotherSystemWrapper}>
                                    <AddAddressIcon />
                                    <span style={{ color: theme.palette.action.hover, marginLeft: '5px' }}>
                                        ADD ANOTHER SYSTEM
                                    </span>
                                </Box>
                            </Box>
                        ) : (
                            <Box></Box>
                        )}
                    </ContentContainer>
                </Box>
            )}
            <Grid container spacing={isXs ? 0 : 2} justifyContent="space-between">
                <Grid item xs={12} md={8} lg={9}>
                    <Grid container spacing={isXs ? 0 : 2}>
                        {itemMain && itemMain.productAttributes && (
                            <Grid item style={{ width: '100%' }}>
                                <CartItemDetail
                                    item={itemMain}
                                    showDelete
                                    onDelete={() => {
                                        showConfirmationAndDeleteCartItem(ItemType.MainSystem);
                                    }}
                                    shouldHideActionButton={!isCartOpen}
                                    onSelect={() => {
                                        invokeGACartChangeClicked();
                                        dispatch(resetAddonSelection());
                                        dispatch(
                                            changeProduct({
                                                sku: itemMain!.sku,
                                                isChangeForSecondSystem: selectedTab === 1,
                                            })
                                        );
                                    }}
                                />
                            </Grid>
                        )}
                        {!isEquipmentOnlyCart &&
                            checkForIAQ() &&
                            itemIAQ &&
                            itemIAQ.productAttributes &&
                            canShowIAQInCart() && (
                                <Grid container item>
                                    <CartItemDetail
                                        item={itemIAQ}
                                        showDelete
                                        onDelete={() => {
                                            showConfirmationAndDeleteCartItem(ItemType.CrossSell);
                                        }}
                                        shouldHideActionButton={!isCartOpen}
                                        onSelect={handleChangeIAQ}
                                    />
                                </Grid>
                            )}
                        {!isEquipmentOnlyCart &&
                            checkForIAQ() &&
                            !itemIAQ &&
                            canShowIAQInCart() &&
                            !isDuctless() &&
                            !isMainProductIAQ() && (
                                <Grid container item>
                                    <CartItemDetail
                                        showDelete
                                        shouldHideActionButton={!isCartOpen}
                                        onSelect={handleChangeIAQ}
                                    />
                                </Grid>
                            )}
                    </Grid>
                    {(totalAddOnsAvailable() > 0 || (addOnsList && addOnsList.length > 0)) && (
                        <Grid item sx={cssObject.addOnBlock}>
                            <AddOnProducts
                                addOnList={addOnsList}
                                isInteractiveTile={true}
                                totalAddOnsAvailable={totalAddOnsAvailable()}
                                shouldEnableChanges={isCartOpen}
                            />
                        </Grid>
                    )}
                    <Grid item>
                        <ContentContainer disableGrid background={'bg3'}></ContentContainer>
                    </Grid>
                    {multipleOpenProjects && (
                        <Box sx={{ my: 5, mx: { xs: 1.5, sm: 0 } }}>
                            <Heading variant={isXs ? 'h4' : isSm ? 'h3' : 'h2'} label="Please Note:" />
                            <Paragraph sx={{ mt: '8px' }}>
                                All applicable permits will be pulled for each job, in accordance with state and local
                                law.
                            </Paragraph>
                        </Box>
                    )}
                </Grid>
                <Grid item xs={12} md={4} lg={3}>
                    <Box sx={{ mx: { xs: 1.5, sm: 0 } }}>
                        {multipleOpenProjects && (
                            <>
                                <PromoCode
                                    onClick={(loaderState) => {
                                        onLoader(loaderState);
                                    }}
                                />
                                <br />
                            </>
                        )}
                        <CartSummary
                            items={cartItemsObj}
                            subTotal={summarySubtotal}
                            taxes={props.shippingAddress && props.shippingAddress.postcode ? taxesAverage : undefined}
                            discount={discount || 0}
                            grandTotal={cartPrices.grandTotal}
                        />
                        <Grid
                            item
                            xs={12}
                            container
                            sx={[
                                cssObject.deleteButton,
                                (cartStatus === CartStatus.PENDING_INSPECTION ||
                                    cartStatus === CartStatus.READY_FOR_PAYMENT) &&
                                cssObject.hideVisibilityStyle,
                            ]}
                        >
                            <Box sx={cssObject.deleteIconSection}>
                                <Box
                                    sx={cssObject.deleteIconWrapper}
                                    onClick={() => {
                                        setConfirmDeleteModalVisibility(true);
                                    }}
                                >
                                    <Box component={'span'} sx={cssObject.deleteImg}>
                                        <DeleteIcon />
                                    </Box>
                                    <Box component={'span'} sx={cssObject.deleteText}>
                                        Empty Cart
                                    </Box>
                                </Box>
                            </Box>
                        </Grid>
                    </Box>
                </Grid>
                <Box component={'hr'} sx={cssObject.dividerStyle} />
            </Grid>
        </ContentContainer>
    );
}
export default CartPageTabsSection;
