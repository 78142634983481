import { getSnackbarMsg } from './../../components/common/CustomSnackMessageUI';
import { showSnackbarSuccess } from './../../components/common/Snackbar/SnackbarHelper';
import { AxiosResponse } from 'axios';
import { CartItem, ScheduleApiRequest, SearchCriteria } from './../../models/cart';
import { combineEpics, Epic } from 'redux-observable';
import { Action } from 'ts-action';
import { ofType } from 'ts-action-operators';
import { EMPTY, Observable, of } from 'rxjs';
import { switchMap, map, catchError, withLatestFrom, tap, ignoreElements, exhaustMap } from 'rxjs/operators';

import {
    createCart as createCartService,
    updateCartItem as updateCartService,
    saveForLater as saveForLaterService,
    placeOrder as placeOrderService,
    placeOrderForEquipmentOnly as placeOrderForEquipmentOnlyService,
    addItem as addCartItemService,
    deleteCart as deleteCartService,
    myCart,
    setShippingAddressToCartApi,
    setBillingAddressToCartApi,
    scheduleInspectionApi,
    deleteItemInCart,
    applyCoupon as applyCouponService,
    deleteMultipleItemsInCart,
} from '../services/cartServices';
import {
    createCart,
    createCartSuccess,
    createCartError,
    saveForLater,
    placeOrder,
    placeOrderSuccess,
    placeOrderError,
    addCartItem,
    deleteCart,
    myCartAction,
    myCartSuccess,
    deleteCartError,
    addCartItemError,
    myCartError,
    setBillingAddressToCart,
    setBillingAddressToCartSuccess,
    setBillingAddressToCartFailure,
    redirectToCartPage,
    saveForLaterSuccess,
    redirectToPaymentPage,
    scheduleInspection,
    scheduleInspectionError,
    scheduleInspectionSuccess,
    redirectToSchedulePage,
    redirectToPage as redirectToParticularPage,
    moveToHome,
    deleteCartSuccess,
    updateCartItem,
    updateCartItemSuccess,
    updateCartItemFailure,
    changeOrRemoveProduct,
    deleteMainAndMakeIAQMain,
    changeOrRemoveProductFailure,
    applyCouponSuccess,
    applyCouponError,
    applyCouponAction,
    changeOrRemoveIAQ,
    changeOrIAQFailure,
    changeOrRemoveIAQSuccess,
    deleteMultipleItems,
    setAddOnCompabilityCheck,
    addCartItemAndRedirectToCart,
    redirectToWizardPage,
    cartCommonFailureAction,
    redirectToPage,
    placeOrderForEquipmentOnly,
} from '../actions/cartActions';
import { RootState } from '../reducers';
import { selectCart } from '../selectors/cart';
import { selectMultipleOpenProjects } from '../selectors/storeSelector';

import { Dependencies } from '../index';
import { getOrder, getOrderandReset, myOrderAction, myOrderSuccess } from '../actions/orderActions';
import { AppRoute } from '../../models/route';
import { selectProductCriteriaState } from '../selectors/productCriteria';
import { selectFirstSystemCriteria } from '../selectors/selection';
import { selectLoginState } from '../selectors/loginSelector';
import {
    setShippingAddressToCart,
    setShippingAddressToCartFailure,
    setShippingAddressToCartSuccess,
    deleteMultipleItemSuccess,
} from 'store/actions/cartActions';
import { getAddress } from 'store/actions/UserProfileActions';
import { AddCartItemActionType, Cart, ItemType, WizardSelectedOptions } from 'models/cart';
import { NumberOfHVAC, ProductCriteria, TypeOfService } from 'models/productCriteria';
import { isFromRoute } from 'components/common/Utilities';
import { showSnackbarError } from 'components/common/Snackbar/SnackbarHelper';
import {
    invokeGACartDeleted,
    invokeGAItemDeleted,
    invokeGASystemDeleted,
    invokeGAPromocodeApplied,
} from 'components/Analytics/GoogleAnalyticsTagging';
import { GACategory } from 'models/googleTracking';
import { moveQuoteToCartSuccess } from 'store/actions/quotes';
import { discountFromCartPrice, removeEmpty } from 'pages/cart/cart-utils';
import { isEmpty } from 'lodash';
import { sendGAAddToCart } from 'components/Analytics/GoogleAnalyticsEcommerce';
import { DOMEvent } from 'components/common/constants';

export const deleteCart$ = (action$: Observable<Action>, state$: Observable<RootState>) =>
    action$.pipe(
        ofType(deleteCart),
        withLatestFrom(state$.pipe(map(selectCart))),
        switchMap(([action, cart]) =>
            deleteCartService(cart.cartId!).pipe(
                switchMap((resp: AxiosResponse<Cart>) => {
                    if (action.payload.shouldRedirectToHome) {
                        //complete cart is deleted, hence shouldRedirectToHome is true.
                        // Since complete cart is deleted, pass empty selectedPackage & otherProductsQuoted to hubspot.
                        invokeGACartDeleted();
                        return [moveToHome(), deleteCartSuccess(resp.data)];
                    } else if (action.payload.shouldRedirectToCart && action.payload.cartItems) {
                        return [
                            createCart({
                                cartItems: action.payload.cartItems,
                                shouldBlockSaveForLaterApi: action.payload.shouldBlockSaveForLaterApi,
                                shouldRedirectToCart: action.payload.shouldRedirectToCart,
                                shippingAddress: action.payload.shippingAddress,
                                billingAddress: action.payload.billingAddress,
                            }),
                        ];
                    } else {
                        return [
                            addCartItem({
                                takeNumberOfSystemsFromWizard: true,
                                cartItems: action.payload.cartItems!,
                                shouldBlockSaveForLaterApi: action.payload.shouldBlockSaveForLaterApi,
                            }),
                        ];
                    }
                }),
                catchError((error) => {
                    return of(deleteCartError(error));
                })
            )
        )
    );

export const moveToHome$ = (action$: Observable<Action>, state$: Observable<RootState>, { history }: Dependencies) =>
    action$.pipe(
        ofType(moveToHome),
        tap(() => {
            history.push(AppRoute.Home);
        }),
        ignoreElements()
    );

export const addCartItem$ = (action$: Observable<Action>, state$: Observable<RootState>) =>
    action$.pipe(
        ofType(addCartItem),
        withLatestFrom(
            state$.pipe(map(selectCart)),
            state$.pipe(map(selectProductCriteriaState)),
        ),
        switchMap(([action, cart, productCriteria]) =>
            invokeAddCartItemApi(cart, action.payload, productCriteria).pipe(
                switchMap((resp) => {
                    //If set true, other API saveForLater & shippingAddr will not be called
                    if (action.payload.shouldShowSnackbarOnSuccess) {
                        const itemAdded = action.payload.cartItems[0];
                        showSnackbarSuccess(getSnackbarMsg(`01 ${itemAdded.name}`, ' has been added to your cart'));
                    }
                    if(action?.payload?.cartItems){
                        sendGAAddToCart(action?.payload?.cartItems[action?.payload?.cartItems.length -1]);
                    }
                    return [
                        createCartSuccess(resp.data),
                        ...(action.payload.shouldBlockSaveForLaterApi ? [] : [saveForLater(resp.data)]),
                    ];
                }),
                catchError((error) => {
                    showSnackbarError('Unable to add item to cart');
                    return of(addCartItemError(error));
                })
            )
        )
    );
export const addCartItemAndRedirectToCart$ = (action$: Observable<Action>, state$: Observable<RootState>) =>
    action$.pipe(
        ofType(addCartItemAndRedirectToCart),
        withLatestFrom(
            state$.pipe(map(selectCart)),
            state$.pipe(map(selectProductCriteriaState)),
        ),
        switchMap(([action, cart, productCriteria]) =>
            invokeAddCartItemApi(cart, action.payload, productCriteria).pipe(
                switchMap((resp) => {
                    const promoCode =
                        cart &&
                        resp.data &&
                        resp.data.prices &&
                        !discountFromCartPrice(resp.data.prices) &&
                        cart.appliedCoupons &&
                        cart.appliedCoupons.length > 0 &&
                        cart?.appliedCoupons[0].code;
                    const actionsArray = [
                        createCartSuccess(resp.data),
                        setAddOnCompabilityCheck(true),
                        ...(promoCode ? [applyCouponAction(promoCode)] : []),
                    ];
                    if (action.payload.shouldRedirectToCart) {
                        return [...actionsArray, redirectToCartPage()];
                    } else {
                        return actionsArray;
                    }
                })
            )
        )
    );
export const updateCartItem$ = (action$: Observable<Action>, state$: Observable<RootState>) =>
    action$.pipe(
        ofType(updateCartItem),
        withLatestFrom(state$.pipe(map(selectCart))),
        switchMap(([action, cart]) => {
            const cartItemPayload = {
                id: action.payload.id,
                sku: action.payload.sku,
                systemGroup: action.payload.systemGroup,
                itemType: action.payload.itemType,
                quantity: action.payload.quantity,
            };
            return updateCartService(cart.cartId!, cartItemPayload).pipe(
                switchMap((res: AxiosResponse<Cart>) => {
                    const oldItem = cart.items?.find((item: CartItem) => item.id === action.payload.id);
                    const updatedItem = res.data.items?.find((item: CartItem) => item.id === action.payload.id);
                    if (!updatedItem || (oldItem && updatedItem.quantity < oldItem.quantity)) {
                        showSnackbarSuccess(
                            getSnackbarMsg(`01 ${action.payload.name}`, ' has been removed from your cart')
                        );
                    } else {
                        showSnackbarSuccess(
                            getSnackbarMsg(`01 ${action.payload.name}`, ' has been added to your cart')
                        );
                    }
                    return [updateCartItemSuccess(res.data)];
                }),
                catchError((err) => of(updateCartItemFailure(err)))
            );
        })
    );

export const deleteMultipleItems$ = (action$: Observable<Action>, state$: Observable<RootState>) =>
    action$.pipe(
        ofType(deleteMultipleItems),
        withLatestFrom(state$.pipe(map(selectCart))),
        switchMap(([action, cart]) => {
            const idsToDelete: Number[] = [];
            const reArrangeCartItems = action.payload.reArrangeCartItems || false;
            action.payload.itemsToDelete.map((item) => idsToDelete.push(item.id));
            return deleteMultipleItemsInCart(cart!.cartId!, idsToDelete, reArrangeCartItems).pipe(
                switchMap((res: AxiosResponse<Cart>) => {
                    if (action.payload.itemsToDelete.length === 1) {
                        invokeGAItemDeleted();
                    } else if (action.payload.itemsToDelete.length > 1) {
                        invokeGASystemDeleted();
                    }
                    if (action.payload.isFromAddOnDeletePopup) {
                        return [deleteMultipleItemSuccess(res.data), setAddOnCompabilityCheck(false)];
                    }
                    return [deleteMultipleItemSuccess(res.data), setAddOnCompabilityCheck(true)];
                })
            );
        })
    );

export const deleteMainAndMakeIAQMain$ = (action$: Observable<Action>, state$: Observable<RootState>) =>
    action$.pipe(
        ofType(deleteMainAndMakeIAQMain),
        withLatestFrom(state$.pipe(map(selectCart))),
        switchMap(([action, cart]) => {
            const itemToDelete = cart.items?.filter((item) => item.systemGroup === action.payload);
            let idsToDelete: Number[] = [];
            let iaqToAdd = itemToDelete?.find((item) => item.itemType === ItemType.CrossSell);
            iaqToAdd!.itemType = ItemType.MainSystem;
            itemToDelete?.map((item) => idsToDelete.push(item.id));
            return deleteMultipleItemsInCart(cart.cartId!, idsToDelete, false).pipe(
                switchMap(() => {
                    invokeGAItemDeleted();
                    return [
                        addCartItemAndRedirectToCart({
                            cartItems: [iaqToAdd!],
                            shouldRedirectToCart: false,
                        }),
                    ];
                }),
                catchError((err) => {
                    return of(cartCommonFailureAction(err));
                })
            );
        })
    );

//Common epic for change/Remove IAQ, changing main product(of any one system) in cart page
export const changeOrRemoveProduct$ = (action$: Observable<Action>, state$: Observable<RootState>) =>
    action$.pipe(
        ofType(changeOrRemoveProduct),
        withLatestFrom(state$.pipe(map(selectCart))),
        switchMap(([action, cart]) => {
            return deleteItemInCart(cart.cartId!, action.payload.itemToRemove + '').pipe(
                switchMap(() => {
                    if (!action.payload.shouldRedirectToCartPage) {
                        sendGAAddToCart(action?.payload?.itemToAdd);
                        return [
                            addCartItemAndRedirectToCart({
                                cartItems: [action.payload.itemToAdd!],
                                shouldRedirectToCart: false,
                            }),
                        ];
                    }
                    sendGAAddToCart(action?.payload?.itemToAdd);
                    return [
                        addCartItemAndRedirectToCart({
                            cartItems: [action.payload.itemToAdd!],
                            shouldRedirectToCart: true,
                        }),
                    ];
                }),
                catchError((err) => of(changeOrRemoveProductFailure(err)))
            );
        })
    );

export const changeOrRemoveIAQ$ = (action$: Observable<Action>, state$: Observable<RootState>) =>
    action$.pipe(
        ofType(changeOrRemoveIAQ),
        withLatestFrom(state$.pipe(map(selectCart))),
        switchMap(([action, cart]) => {
            return deleteItemInCart(cart.cartId!, action.payload.itemToRemove + '').pipe(
                map((res: AxiosResponse<Cart>) => {
                    if (action.payload.isRemoveIAQ) {
                        return changeOrRemoveIAQSuccess(res.data);
                    } else {
                        sendGAAddToCart(action?.payload?.itemIAQToAdd);
                        return addCartItem({
                            cartItems: [action.payload.itemIAQToAdd!],
                            shouldBlockSaveForLaterApi: true,
                        });
                    }
                }),
                catchError((err) => of(changeOrIAQFailure(err)))
            );
        })
    );

export const scheduleEpic$ = (action$: Observable<Action>, state$: Observable<RootState>, { alert }: Dependencies) =>
    action$.pipe(
        ofType(scheduleInspection),
        withLatestFrom(state$.pipe(map(selectCart)), state$.pipe(map(selectMultipleOpenProjects))),
        switchMap(([action, cart, multipleProjects]) => {
            const searchCriteria = cart.contactInformation?.searchCriteria;
            const productCriteria = searchCriteria && productCriteriaPayload(searchCriteria);
            const scheduleRequest: ScheduleApiRequest = {
                schedules: action.payload,
                ...(productCriteria && { searchCriteria: productCriteria }),
                ...(multipleProjects && cart.instructions && { notes: cart.instructions }),
            };
            return scheduleInspectionApi(scheduleRequest, cart.cartId!).pipe(
                map((resp) =>
                    multipleProjects
                        ? getOrderandReset({
                              id: resp.data.order.id,
                          })
                        : scheduleInspectionSuccess(resp.data.status)
                ),
                catchError((error) => {
                    showSnackbarError('Unable to Schedule Inspection');
                    return of(scheduleInspectionError(error));
                })
            );
        })
    );

export const redirectToParticularRoute$ = (
    action$: Observable<Action>,
    state$: Observable<RootState>,
    { alert, history }: Dependencies
) =>
    action$.pipe(
        ofType(redirectToParticularPage),
        tap((action) => {
            if (!isFromRoute(action.payload)) {
                history.push(action.payload);
            }
        }),
        ignoreElements()
    );

export const redirectToSchedulePage$ = (
    action$: Observable<Action>,
    state$: Observable<RootState>,
    { alert, history }: Dependencies
) =>
    action$.pipe(
        ofType(redirectToSchedulePage),
        tap(() => {
            history.push(AppRoute.Schedule);
        }),
        ignoreElements()
    );
export const createCart$: Epic = (action$: Observable<Action>, state$: Observable<RootState>) =>
    action$.pipe(
        ofType(createCart),
        withLatestFrom(
            state$.pipe(map(selectCart)),
            state$.pipe(map(selectProductCriteriaState)),
            state$.pipe(map(selectFirstSystemCriteria))
        ),
        switchMap(([action, cart, productCriteria, selectedProduct]) => {
            const wizardSelectedOptions: WizardSelectedOptions = {
                numberOfSystems: productCriteria.numberOfHVAC || NumberOfHVAC.Multiple,
            };
            return createCartService(
                {
                    email: cart?.contactInformation?.email,
                    items: cartItemsPayload(action.payload.cartItems, selectedProduct.typeOfService),
                    contactInformation: {
                        ...cart?.contactInformation,
                        wizardSelectedOptions: { ...wizardSelectedOptions },
                        orderType:
                            productCriteria?.typeOfService === TypeOfService.EquipmentOnly ? 'equipmentOnly' : '',
                    },
                    ...(action.payload.shippingAddress && { shippingAddress: action.payload.shippingAddress }),
                    ...(action.payload.billingAddress && { billingAddress: action.payload.billingAddress }),
                },
                {
                    gaProductListName: productCriteria?.promotionCode ? 'Marketing Campaign' : 'Product Search',
                    gaEventCategory: GACategory.EVENT_CATEGORY_CART,
                }
            ).pipe(
                switchMap((resp) => {
                    if(resp?.data?.items){
                        sendGAAddToCart(resp.data.items[resp.data.items.length-1]);
                    }
                    return [
                        createCartSuccess(resp.data),
                        ...(action.payload.shouldBlockSaveForLaterApi ? [] : [saveForLater(resp.data)]),
                    ];
                }),
                catchError((error) => {
                    showSnackbarError('Unable to create cart');
                    return of(createCartError(error));
                })
            );
        })
    );

export const myCart$ = (action$: Observable<Action>) =>
    action$.pipe(
        ofType(myCartAction),
        exhaustMap((action) =>
            myCart().pipe(
                map((resp) =>
                    myCartSuccess({ cart: resp.data, route: action.payload.route, params: action.payload.params })
                ),
                catchError((error) => {
                    showSnackbarError('Unable to get cart');
                    return of(myCartError(error));
                })
            )
        )
    );

export const checkAndRedirectToRouteAfterLogin$ = (
    action$: Observable<Action>,
    state$: Observable<RootState>,
    { alert, history }: Dependencies
) =>
    action$.pipe(
        ofType(myOrderSuccess),
        tap((action) => {
            if (action.payload.route) {
                return history.push(action.payload.route);
            }
        }),
        ignoreElements()
    );
export const latestPendingOrder$ = (
    action$: Observable<Action>,
    state$: Observable<RootState>,
    { history }: Dependencies
) =>
    action$.pipe(
        ofType(myCartSuccess),
        withLatestFrom(state$.pipe(map(selectMultipleOpenProjects))),
        switchMap(([action, multipleProjects]) => {
            if (!multipleProjects) {
                return [myOrderAction({ route: action.payload.route })];
            } else if (action.payload.route === AppRoute.Billing && action.payload.params) {
                const orderIdQueryParam = new URLSearchParams(action.payload.params).get('order_id');
                const orderIdParam = orderIdQueryParam && Number(orderIdQueryParam);
                if (orderIdParam) {
                    return [getOrder({ id: orderIdParam, redirectToPayment: true })];
                }
            }
            return [];
        })
    );

export const saveForLater$ = (action$: Observable<Action>, state$: Observable<RootState>, { alert }: Dependencies) =>
    action$.pipe(
        ofType(saveForLater),
        withLatestFrom(state$.pipe(map(selectLoginState))),
        switchMap(([action, login]) =>
            saveForLaterService({ ...action.payload, email: login.email }).pipe(
                map(() => saveForLaterSuccess()),
                catchError(() => {
                    return EMPTY;
                })
            )
        )
    );

export const placeOrder$ = (action$: Observable<Action>, state$: Observable<RootState>) =>
    action$.pipe(
        ofType(placeOrder),
        withLatestFrom(state$.pipe(map(selectCart))),
        switchMap(([action, cart]) => {
            return placeOrderService(action.payload.cart.cartId as string, action.payload.paymentMethod).pipe(
                switchMap((resp) => {
                    return [
                        placeOrderSuccess(resp.data),
                        getOrder({
                            id: resp.data.id,
                            cartId: cart.cartId,
                            paymentMethod: action.payload.paymentMethod,
                            callGAPurchase: true,
                        }),
                    ];
                }),
                catchError((error) => {
                    let msg = 'Unable to place an order';
                    try {
                        if (error.response.data.error.message) {
                            msg = error.response.data.error.message;
                        }
                    } catch (e) {}
                    showSnackbarError(msg);
                    return of(placeOrderError(error));
                })
            );
        })
    );

    export const placeOrderForEquipmentOnly$ = (action$: Observable<Action>, state$: Observable<RootState>) =>
    action$.pipe(
        ofType(placeOrderForEquipmentOnly),
        withLatestFrom(state$.pipe(map(selectCart))),
        switchMap(([action, cart]) => {
            return placeOrderForEquipmentOnlyService(action.payload).pipe(
                switchMap((resp) => {
                    return [
                        getOrderandReset({
                            id: resp.data.order.id,
                        })
                    ];
                }),
                catchError((error) => {
                    let msg = 'Unable to place an order';
                    try {
                        if (error.response.data.error.message) {
                            msg = error.response.data.error.message;
                        }
                    } catch (e) {}
                    showSnackbarError(msg);
                    return of(placeOrderError(error));
                })
            );
        })
    );


export const mapShippingAddressToCart$ = (
    action$: Observable<Action>,
    state$: Observable<RootState>,
    { alert }: Dependencies
) =>
    action$.pipe(
        ofType(setShippingAddressToCart),
        withLatestFrom(state$.pipe(map(selectCart))),
        switchMap(([action, cart]) => {
            let address = cart?.shippingAddress!;
            if (action.payload.address) {
                address = action.payload.address;
            }
            return setShippingAddressToCartApi(address, cart.cartId!).pipe(
                switchMap((resp) => {
                    if (action.payload.pageToRedirect) {
                        return [
                            getAddress(),
                            setShippingAddressToCartSuccess(resp.data),
                            redirectToPage(action.payload.pageToRedirect),
                        ];
                    } else {
                        return [getAddress(), setShippingAddressToCartSuccess(resp.data)];
                    }
                }),
                catchError((err) => {
                    let msg = 'Unable to update Home Address';
                    try {
                        if (err.response.data.error.message) {
                            msg = err.response.data.error.message;
                        }
                    } catch (e) {}
                    showSnackbarError(msg);
                    return of(setShippingAddressToCartFailure(err));
                })
            );
        })
    );

export const mapBillingAddressToCart$ = (
    action$: Observable<Action>,
    state$: Observable<RootState>,
    { alert }: Dependencies
) =>
    action$.pipe(
        ofType(setBillingAddressToCart),
        withLatestFrom(state$.pipe(map(selectCart))),
        switchMap(([action, cart]) => {
            let address = cart?.billingAddress!;
            if (action.payload.address) {
                address = action.payload.address;
            }
            return setBillingAddressToCartApi(address, cart.cartId!, action.payload.useForShipping).pipe(
                switchMap((resp) => {
                    let pageToRedirect = action.payload.pageToRedirect;
                    // For EO Flow, When BillingAddress is captured on Click of Continue CTA, we should show contractor Popup Instead of a redirect
                    if(action.payload.invokeDOMEventOnBillingCatpure) {
                        document.dispatchEvent(
                            new CustomEvent(DOMEvent.EVENT_SHOW_CONTRACTOR_MODAL_AFTER_MANDATORY_BILLING_CAPTURE)
                        );
                        return [getAddress(), setBillingAddressToCartSuccess(resp.data)];
                    } else if (pageToRedirect) {
                        return [
                            getAddress(),
                            setBillingAddressToCartSuccess(resp.data),
                            redirectToParticularPage(pageToRedirect!),
                        ];
                    } else {
                        return [getAddress(), setBillingAddressToCartSuccess(resp.data)];
                    }
                }),
                catchError((err) => {
                    let msg = 'Unable to update Billing Address';
                    try {
                        if (err.response.data.error.message) {
                            msg = err.response.data.error.message;
                        }
                    } catch (e) {}
                    showSnackbarError(msg);
                    return of(setBillingAddressToCartFailure(err));
                })
            );
        })
    );
export const navigateToPaymentPage$ = (
    action$: Observable<Action>,
    state$: Observable<RootState>,
    { history }: Dependencies
) =>
    action$.pipe(
        ofType(redirectToPaymentPage),
        tap(() => history.push(AppRoute.Billing)),

        ignoreElements()
    );

export const navigateToCartPage$ = (
    action$: Observable<Action>,
    state$: Observable<RootState>,
    { history }: Dependencies
) =>
    action$.pipe(
        ofType(redirectToCartPage, moveQuoteToCartSuccess),
        tap(() => {
            history.push(AppRoute.CartPage);
        }),
        ignoreElements()
    );
export const navigateToWizard$ = (
    action$: Observable<Action>,
    state$: Observable<RootState>,
    { history }: Dependencies
) =>
    action$.pipe(
        ofType(redirectToWizardPage),
        tap(() => history.push(AppRoute.AboutYourHome, { isSecondSystem: true })),
        ignoreElements()
    );
export const applyPromoCoupon$ = (
    action$: Observable<Action>,
    state$: Observable<RootState>,
    { history }: Dependencies
) =>
    action$.pipe(
        ofType(applyCouponAction),
        withLatestFrom(state$.pipe(map(selectCart))),
        switchMap(([action, cart]) =>
            applyCouponService(action.payload, cart.cartId!).pipe(
                map((res: AxiosResponse<Cart>) => {
                    invokeGAPromocodeApplied();
                    return applyCouponSuccess(res.data);
                }),
                catchError((err) => {
                    let msg = 'Invalid Promo Code. Please try again.';
                    try {
                        if (err.response.data.error.message) {
                            msg = err.response.data.error.message;
                        }
                    } catch (e) {}
                    return of(applyCouponError(msg));
                })
            )
        )
    );

export default combineEpics(
    myCart$,
    checkAndRedirectToRouteAfterLogin$,
    createCart$,
    moveToHome$,
    updateCartItem$,
    changeOrRemoveProduct$,
    addCartItemAndRedirectToCart$,
    changeOrRemoveIAQ$,
    deleteMultipleItems$,
    deleteMainAndMakeIAQMain$,
    redirectToSchedulePage$,
    redirectToParticularRoute$,
    saveForLater$,
    placeOrder$,
    placeOrderForEquipmentOnly$,
    addCartItem$,
    deleteCart$,
    mapShippingAddressToCart$,
    mapBillingAddressToCart$,
    navigateToPaymentPage$,
    navigateToCartPage$,
    scheduleEpic$,
    applyPromoCoupon$,
    navigateToWizard$,
    latestPendingOrder$
);
function invokeAddCartItemApi(
    cart: Cart,
    payload: AddCartItemActionType,
    productCriteria: ProductCriteria,
) {
    let wizardSelectedOptions: WizardSelectedOptions = { numberOfSystems: NumberOfHVAC.Single };
    if (payload.takeNumberOfSystemsFromWizard) {
        wizardSelectedOptions = { numberOfSystems: productCriteria.numberOfHVAC || NumberOfHVAC.Multiple };
    } else {
        if (cart.contactInformation && cart.contactInformation.wizardSelectedOptions) {
            wizardSelectedOptions = cart.contactInformation.wizardSelectedOptions!;
        } else if (cart.items && cart.items.length > 0) {
            const itemMain = cart.items.filter((cartItem) => cartItem.itemType === ItemType.MainSystem);
            wizardSelectedOptions.numberOfSystems = itemMain.length === 2 ? NumberOfHVAC.Double : NumberOfHVAC.Single;
        }
    }

    let typeOfService = cart.contactInformation?.searchCriteria?.typeOfService || productCriteria?.typeOfService;
    

    return addCartItemService(
        {
            cartId: cart?.cartId,
            email: cart?.contactInformation?.email,
            items: cartItemsPayload(payload.cartItems, typeOfService),
            contactInformation: {
                ...cart?.contactInformation,
                wizardSelectedOptions: { ...wizardSelectedOptions },
                orderType: typeOfService === TypeOfService.EquipmentOnly ? 'equipmentOnly' : '',
            },
        },
        {
            gaProductListName: productCriteria?.promotionCode ? 'Marketing Campaign' : 'Product Search',
            gaEventCategory: GACategory.EVENT_CATEGORY_CART,
        }
    );
}
const cartItemsPayload = (items: any, typeOfService: any) => {
    const isEquipmentOnly = typeOfService === TypeOfService.EquipmentOnly;
    return items.map((item: any) => {
        return {
            sku: item.sku,
            systemGroup: item.systemGroup,
            itemType: item.itemType,
            quantity: item.quantity,
            ...(isEquipmentOnly && { optionId: item.optionId }),
            ...(isEquipmentOnly && { optionTypeId: item.optionTypeId }),
        };
    });
};
export function productCriteriaPayload(searchCriteria: SearchCriteria) {
    if (
        !isEmpty(searchCriteria.secondReplacement) &&
        searchCriteria.secondReplacement?.numberOfHVAC === NumberOfHVAC.Double
    ) {
        delete searchCriteria.secondReplacement.doubleSystemFlag;
        return {
            firstReplacement: removeEmpty(searchCriteria.firstReplacement),
            secondReplacement: removeEmpty(searchCriteria.secondReplacement),
        };
    } else if (searchCriteria.firstReplacement?.numberOfHVAC === NumberOfHVAC.Single) {
        return removeEmpty(searchCriteria.firstReplacement);
    } else {
        return removeEmpty(searchCriteria);
    }
}
