import * as React from 'react';
import { useTheme } from '@mui/material/styles';
import { getThemePrimaryLight, getThemePrimaryMain } from 'components/common/Utilities';
import styles from 'components/common/CommonStyle.module.css';

const HomeScheduleBgKc = (props: any) => {
    const theme = useTheme();
    const themePrimaryMain = getThemePrimaryMain(theme);
    const themePrimaryLight = getThemePrimaryLight(theme);
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={241} height={240.438} {...props} className={styles.svgCss}>
            <g data-name="Component 82 \u2013 4">
                <g data-name="Group 3384" opacity={0.3}>
                    <g data-name="Group 80" transform="translate(1 1)">
                        <g
                            data-name="Group 75"
                            opacity={0.7}
                            fill="none"
                            stroke={`${themePrimaryMain}`}
                            strokeWidth={2}
                        >
                            <circle
                                data-name="Ellipse 108"
                                cx={89.843}
                                cy={89.843}
                                r={89.843}
                                transform="translate(29.376 29.376)"
                                opacity={0.6}
                            />
                            <circle data-name="Ellipse 109" cx={119.219} cy={119.219} r={119.219} opacity={0.2} />
                            <circle
                                data-name="Ellipse 110"
                                cx={59.609}
                                cy={59.609}
                                r={59.609}
                                transform="translate(59.61 59.609)"
                                opacity={0.69}
                            />
                            <circle
                                data-name="Ellipse 111"
                                cx={28.518}
                                cy={28.518}
                                r={28.518}
                                transform="translate(90.701 90.701)"
                            />
                        </g>
                        <circle
                            data-name="Ellipse 112"
                            cx={15.531}
                            cy={15.531}
                            r={15.531}
                            transform="translate(41.307 38.508)"
                            fill={`${themePrimaryMain}`}
                        />
                        <circle
                            data-name="Ellipse 113"
                            cx={5.117}
                            cy={5.117}
                            r={5.117}
                            transform="translate(116.676 22.977)"
                            fill={`${themePrimaryMain}`}
                        />
                        <circle
                            data-name="Ellipse 114"
                            cx={5.117}
                            cy={5.117}
                            r={5.117}
                            transform="translate(178.828 12.743)"
                            fill={`${themePrimaryLight}`}
                        />
                        <circle
                            data-name="Ellipse 115"
                            cx={3.873}
                            cy={3.873}
                            r={3.873}
                            transform="translate(.815 81.934)"
                            fill={`${themePrimaryLight}`}
                        />
                        <circle
                            data-name="Ellipse 116"
                            cx={4.653}
                            cy={4.653}
                            r={4.653}
                            transform="translate(230.693 88.117)"
                            fill={`${themePrimaryLight}`}
                        />
                        <circle
                            data-name="Ellipse 117"
                            cx={11.333}
                            cy={11.333}
                            r={11.333}
                            transform="translate(157.765 76.784)"
                            fill={`${themePrimaryMain}`}
                        />
                        <g
                            data-name="Group 79"
                            transform="translate(50.424 47.53)"
                            fill="none"
                            stroke="#f6f6f6"
                            strokeLinecap="round"
                            strokeWidth={2}
                        >
                            <rect data-name="Rectangle 102" width={12.827} height={12.827} rx={2.324} />
                            <path data-name="Line 70" d="M.246 3.614h12.212" />
                            <g data-name="Group 76">
                                <path data-name="Line 71" d="M6.352 5.921h0" />
                                <path data-name="Line 72" d="M8.344 5.921h0" />
                                <path data-name="Line 73" d="M10.336 5.921h0" />
                            </g>
                            <g data-name="Group 77">
                                <path data-name="Line 74" d="M4.36 8.013h0" />
                                <path data-name="Line 75" d="M2.368 8.013h0" />
                                <path data-name="Line 76" d="M6.351 8.013h0" />
                                <path data-name="Line 77" d="M8.343 8.013h0" />
                                <path data-name="Line 78" d="M10.335 8.013h0" />
                            </g>
                            <g data-name="Group 78">
                                <path data-name="Line 79" d="M4.36 10.104h0" />
                                <path data-name="Line 80" d="M2.368 10.104h0" />
                                <path data-name="Line 81" d="M6.351 10.104h0" />
                            </g>
                        </g>
                        <circle
                            data-name="Ellipse 118"
                            cx={5.095}
                            cy={5.095}
                            r={5.095}
                            transform="translate(164.002 83.022)"
                            fill="none"
                            stroke="#f6f6f6"
                            strokeLinecap="round"
                            strokeWidth={2}
                        />
                        <path
                            data-name="Path 193"
                            d="M169.098 85.766v2.67h2.183"
                            fill="none"
                            stroke="#f6f6f6"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                        />
                        <circle
                            data-name="Ellipse 119"
                            cx={1.358}
                            cy={1.358}
                            r={1.358}
                            transform="translate(120.434 26.568)"
                            fill="none"
                            stroke="#f6f6f6"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                        />
                    </g>
                </g>
            </g>
        </svg>
    );
};
export default HomeScheduleBgKc;
