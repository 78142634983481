import { Observable, of } from 'rxjs';
import { combineEpics, Epic } from 'redux-observable';
import { Action } from 'ts-action';
import { ofType } from 'ts-action-operators';
import {
    getAddressListAction,
    getAddressListFailure,
    getAddressListSuccess,
    showChooseAddressPopupAction,
    showEditAddressPopupAction,
} from '../actions/addressActions';
import { catchError, switchMap } from 'rxjs/operators';
import { getAddressApi as getAddressService } from '../services/userProfileServices';
import { AxiosResponse } from 'axios';
import { UserAddressDetails } from '../../models/userProfile';
import { showSnackbarError } from 'components/common/Snackbar/SnackbarHelper';
const getAddress$: Epic = (action$: Observable<Action>) =>
    action$.pipe(
        ofType(getAddressListAction),
        switchMap((action) =>
            getAddressService().pipe(
                switchMap((res: AxiosResponse<UserAddressDetails>) => {
                    let addressList = res.data.addresses;
                    let autoOpenAddressPopup = action.payload; //payload false if called from properties page
                    const removedItemIndex = addressList.findIndex((addressItem) => addressItem.defaultBilling);
                    if (removedItemIndex !== -1) {
                        addressList = [...addressList.splice(removedItemIndex, 1), ...addressList];
                    }
                    const actionArray: Action[] = [getAddressListSuccess(addressList)];
                    if (autoOpenAddressPopup) {
                        if (addressList && addressList.length > 0) {
                            actionArray.push(showChooseAddressPopupAction());
                        } else {
                            actionArray.push(showEditAddressPopupAction());
                        }
                    }

                    return actionArray;
                })
            )
        ),
        catchError((err) => {
            showSnackbarError('Unable to get Addresses');
            return of(getAddressListFailure());
        })
    );

export default combineEpics(getAddress$);
