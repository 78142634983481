import { Box, Container, Theme, useTheme } from '@mui/material';
import WizardHeader from 'components/WizardHeader';
import LoadingOverlay from 'components/common/LoadingOverlay';
import { SXCssObject } from 'models/ThemeImages';
import React, { useEffect, useState } from 'react';
import { ThemeWithAdditionalData } from 'storeDetails/theme';
import friendsAndFamilyDesktop from 'assets/images/daikinFriendsAndFamily/friendsAndFamilyBanner_desktop.webp';
import friendsAndFamilyMobile from 'assets/images/daikinFriendsAndFamily/friendsAndFamilyBanner_mobile.webp';
import friendsAndFamilyTab from 'assets/images/daikinFriendsAndFamily/friendsAndFamilyBanner_tab.webp';
import Footer from 'components/Footer';
import { Heading } from 'components/Heading';
import { Paragraph } from 'components/Paragraph';
// import DaikinLogo from 'components/icons/DaikinLogo';
import { showSnackbarSuccess } from 'components/common/Snackbar/SnackbarHelper';
import { invokeDaikinFriendsAndFamilyFormSubmission } from 'components/Analytics/GoogleAnalyticsTagging';
import { useNavigate } from 'react-router-dom';
import { AppRoute } from 'models/route';
import MotiliLogo from '../../assets/images/Motili_Logo_Blue_Black.png'

interface Props {

}
const createCssObject = (theme: Theme): SXCssObject => ({
    contentRoot: {
        position: 'relative',
        top: '80px',
    },
    imageSection: {
        position: 'relative'
    },
    imageStyle: {
        width: '100%',
        objectFit: 'cover',
        objectPosition: 'right top',
        height: {
            xs: '420px',
            xl: '500px',
        },
        content: {
            xs: 'url("' + friendsAndFamilyMobile + '")',
            sm: 'url("' + friendsAndFamilyTab + '")',
            lg: 'url("' + friendsAndFamilyDesktop + '")',
        },
    },
    textOverlayOnImage: {
        position: 'absolute',
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        height: '100%',
        maxWidth: {xs: '100%', sm: '65%', md: '60%', lg: '50%'},
        pl: {
            xs: '16px', sm: '32px', md: '64px'
        },
        pr: {
            xs: '16px', sm: '0px'
        }
    },
    overlayTextStyle: {
        lineHeight: {
            xs: '1.75rem', sm: '2rem'
        }
    },
    hubspotRoot: {
        padding: {
            xs: '16px', sm: '32px', md: '64px'
        }
    },
    headingStyle: {
        pb: { xs: '24px', md: '32px', lg: '36px' }
    },
    formWrapper: {
        width: '100%',
        maxWidth: {
            xs: '400px',
            sm: 'inherit'
        },
    },
    formStyle: {
         width: '100%',
       
    }
});

export const MotiliFriendsAndFamilyProgram = (props: Props) => {
    // const {onFormSubmitted, onFormReady, onBeforeFormSubmit } = props;
    const portalId = process.env.REACT_APP_HUBSPOT_FRIENDS_AND_FAMILY_PORTAL_ID;
    const formId = process.env.REACT_APP_HUBSPOT_FRIENDS_AND_FAMILY_LEAD_INTAKE_FORM;
    const [isHubSpotFormLoading, setIsHubSpotFormLoading] = useState(true);
    const navigate = useNavigate();
    const theme = useTheme<ThemeWithAdditionalData>();
    const cssObject = createCssObject(theme)
    const onFormReady = () => {
        setIsHubSpotFormLoading(false);
    };

    const onBeforeFormSubmit = () => {
        setIsHubSpotFormLoading(true);
    };

    const onFormSubmitted = () => {
        showSnackbarSuccess('Lead intake is successful');
        invokeDaikinFriendsAndFamilyFormSubmission();
        setIsHubSpotFormLoading(false);
        navigate(AppRoute.Home);
    };   

    useEffect(() => {
        const script = document.createElement('script');
        script.src = 'https://js.hsforms.net/forms/embed/v2.js';
        document.body.appendChild(script);

        script.addEventListener('load', () => {
            // @ts-ignore
            if (window.hbspt) {
                // @ts-ignore
                window.hbspt.forms.create({
                    portalId: portalId,
                    formId: formId,
                    target: '#hubspotLeadIntakeFormElement',
                    onFormReady: function () {
                        onFormReady();
                    },
                    onBeforeFormSubmit: function () {
                        onBeforeFormSubmit();
                    },
                    onFormSubmitted: function () {
                        onFormSubmitted();
                    },
                });
            }
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Box>
            <WizardHeader isFixed={true} showDaikinLogo={true} showBackButton={false} showRestartButton={false} />
            <LoadingOverlay open={isHubSpotFormLoading} />
            <Box sx={cssObject.contentRoot}>
                <Box sx={cssObject.imageSection}>
                    <Box sx={cssObject.textOverlayOnImage}>
                        <Box sx={{ mb: '4px' }}>
                            {/* <DaikinLogo /> */}
                            <img src={MotiliLogo} height={'48px'} />
                        </Box>
                        <Paragraph semibold headingColor responsiveFontSize22 sx={cssObject.overlayTextStyle}>
                            Thank you for your interest in Motili’s HVAC replacement program for our friends and family.
                        </Paragraph>
                        <Paragraph semibold headingColor responsiveFontSize22 sx={cssObject.overlayTextStyle}>
                            Just fill out the form below to get started!
                        </Paragraph>
                    </Box>
                    <Box sx={cssObject.imageStyle} component={'img'}></Box>
                </Box>
                <Box sx={cssObject.hubspotRoot}>
                    <Container maxWidth="lg" sx={{ p: '0px !important', display: 'flex', justifyContent: 'center'  }}>
                        <Box sx={cssObject.formWrapper}>
                            <Box>
                                <Heading label={'Lead Intake Form'} sx={cssObject.headingStyle} />
                            </Box>
                            <Box id="hubspotLeadIntakeFormElement" sx={cssObject.formStyle} />
                        </Box>
                    </Container>
                </Box>
                <Footer />
            </Box>
        </Box>
    );
};
