import { combineEpics } from 'redux-observable';
import { Action } from 'ts-action';
import { RootState } from '../reducers';
import { autoUpdateServiceWorker$ } from './serviceWorkerEpic';
import { googleAnalytic$ } from './googleAnalyticEpic';
import store$ from './storeEpics';
import productSearchEpic$ from './productSearchEpic';
import hubSpotEpic$ from './hubspotEpics';
import cartEpic$ from './cartEpics';
import { getCountry$ } from './countryEpics';
import financialEpic$ from './financialEpics';
import orderEpics$ from './orderEpics';
import { getProductAttribute$ } from './productAttributesEpic';
import { getReviewRatings$ } from './reviewRatingEpics';
import loginEpics$ from './loginEpics';
import logoutEpics$ from './logoutEpics';
import changeProductEpics$ from './changeProductEpic';
import profileAddressEpics$ from './profileAddressEpic';
import quoteEpics$ from './quoteEpics';
import addressData$ from './addressEpic';

export const rootEpic = combineEpics<Action, Action, RootState>(
    autoUpdateServiceWorker$,
    googleAnalytic$,
    store$,
    loginEpics$,
    logoutEpics$,
    productSearchEpic$,
    cartEpic$,
    hubSpotEpic$,
    financialEpic$,
    getCountry$,
    orderEpics$,
    getProductAttribute$,
    getReviewRatings$,
    changeProductEpics$,
    profileAddressEpics$,
    quoteEpics$,
    addressData$
);
