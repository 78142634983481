import { SnackMessage } from './SnackbarAlert';
import { SnackbarParams } from './SnackbarModal';

export const showSnackbarError = (msg: React.ReactNode, snackbarParams?: SnackbarParams) => {
    const params = snackbarParams || {};
    SnackMessage.error(msg, params);
};

export const showSnackbarSuccess = (msg: React.ReactNode, snackbarParams?: SnackbarParams) => {
    const params = snackbarParams || {};
    SnackMessage.success(msg, params);
};

export const showSnackbarInfo = (msg: React.ReactNode, snackbarParams?: SnackbarParams) => {
    const params = snackbarParams || {};
    SnackMessage.info(msg, params);
};

export const showSnackbarWarning = (msg: React.ReactNode, snackbarParams?: SnackbarParams) => {
    const params = snackbarParams || {};

    SnackMessage.warning(msg, params);
};

export const closeSnack = (key: string) => {
    SnackMessage.close(key);
};
