import { SystemGroup } from 'models/cart';
import { createSelector } from 'reselect';
import { RootState } from '../reducers';

export const selectPreCart = (state: RootState) => state.selection;
export const selectPreCartProcessing = createSelector(selectPreCart, (selection) => selection.loading || false);
export const selectPreCartMain = createSelector(selectPreCart, (selection) => selection.items || []);
export const selectPreCartIAQ = createSelector(selectPreCart, (selection) => selection.itemsIAQ || []);
export const selectPreCartAddon = createSelector(selectPreCart, (selection) => selection.itemsAddOn || []);
export const selectPreCartItems = createSelector(
    selectPreCartMain,
    selectPreCartIAQ,
    selectPreCartAddon,
    (mainItems, IAQitems, addOns) => {
        return {
            ...(mainItems.length > 0 && {
                firstSystemMain: mainItems.find((item) => item.systemGroup === SystemGroup.SystemGroup1),
            }),
            ...(mainItems.length > 1 && {
                secondSystemMain: mainItems.find((item) => item.systemGroup === SystemGroup.SystemGroup2),
            }),
            ...(IAQitems.length > 0 && {
                firstSystemIAQ: IAQitems.find((item) => item.systemGroup === SystemGroup.SystemGroup1),
            }),
            ...(IAQitems.length > 0 && {
                secondSystemIAQ: IAQitems.find((item) => item.systemGroup === SystemGroup.SystemGroup2),
            }),
            addOnsList: addOns,
        };
    }
);
export const selectCombinedList = createSelector(selectPreCart, (selection) => {
    const mainItems = selection.items || [];
    const itemsAddOnWithQuantity = selection.itemsAddOn.filter((addOn) => addOn.quantity > 0);
    return [...mainItems, ...selection.itemsIAQ, ...itemsAddOnWithQuantity];
});

export const selectShippingAddressStatus = createSelector(
    selectPreCart,
    (selection) => selection.shippingAddressCaptured
);
export const selectFirstSystemCriteria = createSelector(selectPreCart, (selection) => selection.firstSystemCriteria);
