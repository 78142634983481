import * as React from 'react';
import { useTheme } from '@mui/material/styles';
import { getThemePrimaryMain } from 'components/common/Utilities';

export default function LoginCart(props: any) {
    const theme = useTheme();
    const themePrimaryMain = getThemePrimaryMain(theme);
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} {...props}>
            <path
                fill={`${themePrimaryMain}`}
                d="M4.777 4.118c-.631 0-1.194.007-1.757 0A.941.941 0 012 3.173a.929.929 0 011.017-.9q1.3-.011 2.606 0a.922.922 0 011.015 1.031c.01 1.028 0 2.056 0 3.084v.509h12.759c.9 0 1.3.526 1.048 1.382q-1.332 4.448-2.669 8.894c-.2.658-.462.849-1.173.849H5.918c-.795 0-1.142-.336-1.143-1.131V4.815zm1.888 4.649v7.372a2.321 2.321 0 00.271.033c2.966 0 5.931 0 8.9.009.3 0 .353-.153.42-.376q1-3.365 2.017-6.728a2.371 2.371 0 00.048-.311zM6.19 18.957a1.364 1.364 0 011.365 1.389 1.39 1.39 0 01-1.422 1.386 1.414 1.414 0 01-1.353-1.378 1.39 1.39 0 011.41-1.397zM15.419 18.955a1.388 1.388 0 011.415 1.394 1.39 1.39 0 01-2.779 0 1.385 1.385 0 011.364-1.394z"
            />
        </svg>
    );
}
