import React from 'react';
import { Theme } from '@mui/material/styles';
import ContentContainer from 'components/ContentContainer';
import { Box, Typography, useTheme } from '@mui/material';
import { Paragraph } from 'components/Paragraph';
import ProfileIcon from 'assets/icons/icon_profile.svg';
import { ProductReviews } from 'models/productReviews';
import ReviewIcon from 'components/icons/ReviewIcon';
import { SXCssObject } from 'models/ThemeImages';

const createCssObject = (theme: Theme): SXCssObject => ({
    root: {
        margin: {
            lg: '0px 88px 88px',
        },
        borderRadius: {
            lg: '18px',
        },
        padding: {
            xs: '60px 60px',
            lg: '80px 120px',
        },
        background: theme.palette.common.white,
    },
    headingStyle: {
        marginBottom: {
            sm: '30px',
            md: '50px',
        },
        textAlign: 'center',
    },
    profileImagesContainer: {
        display: 'flex',
        justifyContent: 'center',
    },
    profileImageWrapperStyle: {
        minWidth: '100px',
        minHeight: '100px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    selectedItemStyle: {
        width: '86px',
        height: '86px',
        cursor: 'pointer',
        transition: '200ms',
        WebkitTapHighlightColor: 'transparent',
    },
    nonSelectedItemStyle: {
        width: '60px',
        height: '60px',
        cursor: 'pointer',
        WebkitTapHighlightColor: 'transparent',
    },
    reviewImageContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    reviewCommentsIconStyle: {
        width: '30px',
        height: '30px',
        borderRadius: '50%',
        backgroundColor: theme.palette.primary.main,
        marginBottom: '-25px',
        marginTop: '20px',
    },
    reviewMsgContainer: {
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
    },
    reviewMsgSection: {
        maxWidth: '960px',
        boxShadow: '0px 8px 45px #DCDCDC8C',
        display: 'flex',
        marginTop: '10px',
        flexDirection: 'column',
        borderRadius: '18px',
        padding: {
            xs: '64px',
            sm: '32px',
            md: '64px',
        },
    },
    reviewDividerStyle: {
        margin: '20px 0px',
        borderTop: `1px solid ${theme.customProperties.borderDivider}`,
        borderBottom: '0px',
        opacity: '0.2',
    },
    reviewUserLocationTextStyle: {
        marginLeft: '16px',
    },
});
interface Props {
    reviewsList: ProductReviews[];
}
function CustomerReviewsLarge(props: Props) {
    const cssObject: SXCssObject = createCssObject(useTheme());
    const reviewsList = props.reviewsList;
    const listToShow = reviewsList.length > 5 ? reviewsList.slice(0, 5) : reviewsList;
    const defaultSelected = listToShow.length > 1 ? Math.floor(listToShow.length / 2) : 0;
    const [selectedItem, setSelectedItem] = React.useState(defaultSelected);
    const handleOnReviewImageClick = (index: number) => {
        setSelectedItem(index);
    };
    return (
        <ContentContainer disableGrid className={cssObject.root}>
            <Box sx={cssObject.headingStyle}>
                <Typography variant="h3">What our Customers say!</Typography>
            </Box>
            <Box>
                <Box sx={cssObject.profileImagesContainer}>
                    {listToShow.map((item: ProductReviews, index) => {
                        return (
                            <Box key={index}>
                                <Box key={index} sx={cssObject.profileImageWrapperStyle}>
                                    <Box
                                        component={'img'}
                                        onClick={() => handleOnReviewImageClick(index)}
                                        sx={[
                                            selectedItem === index && cssObject.selectedItemStyle,
                                            selectedItem !== index && cssObject.nonSelectedItemStyle,
                                        ]}
                                        src={ProfileIcon}
                                        alt={item.nickname}
                                    />
                                </Box>
                            </Box>
                        );
                    })}
                </Box>

                <Box sx={cssObject.reviewMsgContainer}>
                    <ReviewIcon className={cssObject.reviewCommentsIconStyle} />
                    <Box sx={cssObject.reviewMsgSection}>
                        <Paragraph align={'center'}>{listToShow[selectedItem].detail}</Paragraph>
                        <Box component={'hr'} sx={cssObject.reviewDividerStyle} />
                        <Box style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <Paragraph semibold>{listToShow[selectedItem].nickname}</Paragraph>
                            <Box sx={cssObject.reviewUserLocationTextStyle}>
                                <Paragraph fontSizeVerySmall>{listToShow[selectedItem].location}</Paragraph>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Box>
        </ContentContainer>
    );
}

export default CustomerReviewsLarge;
