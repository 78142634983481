import React from 'react';
import { Theme, useTheme } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import { Heading } from '../Heading';
import ContentContainer from '../ContentContainer';
import map from 'lodash/map';
import { ExternalLink } from 'components/Link';
import Container from '@mui/material/Container';
import CollapseIcon from 'components/icons/CollapseIcon';
import ArrowRightHome from 'components/icons/ArrowRightHome';
import { SXCssObject } from 'models/ThemeImages';

const createCssObject = (theme: Theme): SXCssObject => {
    return {
        root: {
            paddingTop: theme.spacing(5),
            paddingBottom: {
                xs: theme.spacing(2),
                sm: theme.spacing(5),
            },
        },
        additionalContainer: {
            paddingLeft: theme.spacing(2),
            paddingRight: theme.spacing(2),
        },
        additionalLink: {
            borderBottom: `2px solid ${theme.palette.divider}`,
            paddingBottom: theme.spacing(1),
            '&:hover': {
                borderBottom: `2px solid ${theme.palette.primary.contrastText}`,
            },
            padding: {
                xs: theme.spacing(1, 0),
                sm: 'inherit',
            },
        },
        contentHeading: {
            paddingBottom: theme.spacing(2),
            paddingRight: theme.spacing(2),
        },
        arrowRightAltIcon: {
            color: theme.palette.primary.main,
            width: '20px',
            height: '20px',
        },
    };
};

export default function ProTipAndAdditionalLinks(props: ProTipAndAdditionalLinksProps) {
    const cssObject: SXCssObject = createCssObject(useTheme());
    return (
        <ContentContainer background={'bg2'} disableGrid sx={cssObject.root}>
            <Container maxWidth={'lg'} disableGutters>
                <Grid item xs={12} container>
                    <Grid item sm={1} xs={1} />
                    <Grid item sm={10} xs={12} container>
                        {props.additionalLinks && (
                            <Grid
                                item
                                sm={props.proTip ? 6 : 12}
                                xs={12}
                                container
                                direction={'row'}
                                alignItems={'flex-start'}
                                justifyContent={'space-between'}
                                sx={cssObject.additionalContainer}
                            >
                                <Grid item sx={cssObject.contentHeading} xs={12} sm={12} md={4}>
                                    <CollapseIcon />
                                    <Heading
                                        isPopUp
                                        variant={'h3'}
                                        color={'primary'}
                                        label={props.additionalLinks?.headLine}
                                    />
                                </Grid>
                                <Grid item container direction={'column'} xs={12} sm={12} md={6}>
                                    {map(props.additionalLinks?.links, (link, index) => (
                                        <ExternalLink
                                            key={`addition-link-${index}`}
                                            id={`addition-link-${index}`}
                                            color={'primary'}
                                            href={link.url}
                                        >
                                            <Grid
                                                item
                                                key={`addition-link-grid-${index}`}
                                                sx={cssObject.additionalLink}
                                                container
                                                justifyContent={'space-between'}
                                            >
                                                <Grid item xs={10} sm={10}>
                                                    {link.text}
                                                </Grid>
                                                <ArrowRightHome />
                                            </Grid>
                                        </ExternalLink>
                                    ))}
                                </Grid>
                            </Grid>
                        )}
                    </Grid>
                    <Grid item sm={1} xs={1} />
                </Grid>
            </Container>
        </ContentContainer>
    );
}

export interface ProTipAndAdditionalLinksProps {
    proTip?: ProTip;
    additionalLinks?: AdditionalLinks;
}

interface ProTip {
    headLine: any;
    paragraph: any;
}

interface AdditionalLinks {
    headLine: any;
    links: Link[];
}

interface Link {
    text: string;
    url: string;
}
