import axios from 'axios';
import { ContactUsRequest } from 'models/contact';
import http, { getHeaders } from '../../index';
import config from '../../config';
import { SignUpResponse } from 'models/loginModel';
export const sendCustomerContactRequest = async (contactUsRequest: ContactUsRequest, url?: string) => {
    try {
        const axiosObj = axios.create({
            baseURL: config.apiHost,
            headers: url ? { ...getHeaders(), store: url } : getHeaders(),
            timeout: config.httpTimeout,
            withCredentials: true,
        });
        const result = await axiosObj.post<SignUpResponse>('/Customer/contact', contactUsRequest);
        return { success: true, data: result.data, error: false };
    } catch (e) {
        return { success: false, error: true };
    }
};

export const sendCustomerContactRequestFromProfile = (contactUsRequest: ContactUsRequest) =>
    http().post<SignUpResponse>('/Customer/contact', contactUsRequest);
