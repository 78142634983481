import React from 'react';
import { Theme } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import { Box, Dialog, DialogContent, useTheme } from '@mui/material';
import { SXCssObject } from 'models/ThemeImages';
import { Heading } from './Heading';
import { Paragraph } from './Paragraph';
import ContentContainer from './ContentContainer';
import PriorityHighRoundedIcon from '@mui/icons-material/PriorityHighRounded';

const createCssObject = (theme: Theme): SXCssObject => ({
    root: { padding: '15px 0px' },
    main: {
        display: 'flex',
        flexDirection: 'column',
    },
    modalContent: {
        overflow: 'hidden',
        px: 7,
        py: 4
    },
    modalTitleContainer: {
        padding: '10px 24px 24px',
        position: 'relative',
        marign: '0px',
    },
    modalPaper: {
        borderRadius: '4px',
        width: '500px',
    },
});

interface Props {
    isOpen: boolean;
    handleClose: (snackbarMessage?: string) => void;
}

function CookieDisablePopup(Props: Props) {
    const cssObject: SXCssObject = createCssObject(useTheme());
    return (
        <Dialog
            aria-describedby="alert-dialog-slide-description"
            open={Props.isOpen}
            maxWidth={'md'}
            disableEscapeKeyDown={true}
            PaperProps={{
                sx: cssObject.modalPaper,
            }}
        >
            <Box sx={cssObject.root}>
                <Box sx={cssObject.main}>
                    <DialogContent sx={cssObject.modalContent}>
                        <ContentContainer disableGrid background={'bg3'}>
                            <Container maxWidth={'lg'} disableGutters>
                                <Grid container spacing={4} justifyContent="center">
                                    <Grid item display={'flex'} alignItems={'center'} flexDirection={'column'}>
                                        <Box sx={{ width: '64px', height: '64px', borderRadius: '4px', backgroundColor: 'primary.light' }} display={'flex'} justifyContent={'center'} alignItems={'center'}>
                                            <PriorityHighRoundedIcon sx={{ fontSize: '40px' }} color='primary' />
                                        </Box>
                                        <br />
                                        <Heading align={'center'} label={'Enable browser cookies to access your cart'} />
                                        <br />
                                        <Paragraph align={'center'}>Your browser is currently set to block cookies. You need to allow cookies and refresh the page. To learn how to allow cookies, check the online help in your web browser.</Paragraph>
                                    </Grid>
                                </Grid>
                            </Container>
                        </ContentContainer>
                    </DialogContent>
                </Box>
            </Box>
        </Dialog>
    );
}
export default CookieDisablePopup;
