import { Box, Grid, Theme, useMediaQuery, useTheme } from '@mui/material';
import { Button } from 'components/Button';
import LoadingOverlay from 'components/common/LoadingOverlay';
import { extractErrorFromResponseOrShowDefaultMsg } from 'components/common/Utilities';
import { Heading } from 'components/Heading';
import { Address, AddressType } from 'models/cart';
import { transformPropertyObjForEdit } from 'pages/user/ShippingAddress/AddressUtils';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectLoginState } from 'store/selectors/loginSelector';
import { addAddress, deleteAddress, editAddress } from 'store/services/userProfileServices';
import ContentContainer from '../../components/ContentContainer';
import { NoPropertiesLayout } from './NoPropertiesLayout';
import { PropertyDetails } from './PropertyDetails';
import { PropertyMenusLabel } from './PropertyMenusList';
import Navigator from 'components/Navigator';
import { AddressContainer } from 'pages/user/ShippingAddress/AddressContainer';
import { addressState } from 'store/selectors/addressSelector';
import { getAddressListAction, showEditAddressPopupAction } from 'store/actions/addressActions';
import { setAddressToEdit } from 'store/actions/addressActions';
import { getSnackbarMsg } from 'components/common/CustomSnackMessageUI';
import { ConfirmationModal } from 'components/common/ConfirmationModal';
import { showSnackbarInfo, showSnackbarSuccess } from 'components/common/Snackbar/SnackbarHelper';
import { SXCssObject } from 'models/ThemeImages';
import { invokeGAProfilePropertyDeleted } from 'components/Analytics/GoogleAnalyticsTagging';

const createCssObject = (theme: Theme): SXCssObject => ({
    navContainer: {
        backgroundColor: theme.palette.common.white,
    },
    headingLayout: {
        paddingTop: {
            xs: theme.spacing(2.4),
            sm: theme.spacing(3.2),
            md: theme.spacing(4.8),
        },
        paddingBottom: {
            xs: theme.spacing(1.6),
            sm: theme.spacing(2),
            md: theme.spacing(4.8),
        },
    },
    headingStyle: {
        display: 'flex',
        alignItems: 'center',
    },
});

export function MyProperties() {
    const cssObject: SXCssObject = createCssObject(useTheme());
    const dispatch = useDispatch();
    const loginData = useSelector(selectLoginState);
    const [loader, setLoader] = useState<boolean>(false);

    const theme = useTheme();
    let mounted: any = useRef();
    const addressData = useSelector(addressState);
    const { addressList, loading, addressToEdit } = addressData;
    const [isDeleteAddressPopUpVisible, setIsDeleteAddressPopUpVisible] = useState<Address | null>();
    const isXs = useMediaQuery(theme.breakpoints.only('xs'));
    const showNoContentLayout = !loading && (!addressList || addressList.length <= 0);
    const getAddressData = () => {
        dispatch(getAddressListAction(false));
    };
    useEffect(() => {
        if (!mounted.current) {
            mounted.current = true;
            getAddressData();
        }
    });
    const onMenuClick = (address: Address, label: string) => {
        switch (label) {
            case PropertyMenusLabel.EDIT:
                openEditAddressPopup(address);
                return;
            case PropertyMenusLabel.DELETE:
                showDeleteConfirmationPopup(address);
                return;
        }
    };

    const handleAddProperty = async (address: Address) => {
        try {
            setLoader(true);
            await addAddress(address);
            setLoader(false);
            getAddressData();
        } catch (err) {
            setLoader(false);
            extractErrorFromResponseOrShowDefaultMsg(err, 'Unable to delete the Property');
        }
    };

    const handleEditProperty = async (address: Address) => {
        try {
            const id = address.id!;
            delete address.id;
            setLoader(true);
            await editAddress(id, address);
            showSnackbarSuccess(getSnackbarMsg(address.addressName!, ' property details Updated Successfully!'));
            setLoader(false);
            getAddressData();
        } catch (err) {
            setLoader(false);
            extractErrorFromResponseOrShowDefaultMsg(err, 'Unable to delete the Property');
        }
    };

    const openEditAddressPopup = (address: Address) => {
        dispatch(setAddressToEdit({ address }));
        dispatch(showEditAddressPopupAction());
    };

    const showDeleteConfirmationPopup = (address: Address) => {
        setIsDeleteAddressPopUpVisible(address);
    };

    const openAddAddressPopup = () => {
        dispatch(setAddressToEdit({}));
        dispatch(showEditAddressPopupAction());
    };

    const handleDeleteAddress = async (address: Address) => {
        setLoader(true);
        try {
            await deleteAddress(address.id!);
            invokeGAProfilePropertyDeleted();
            showSnackbarInfo(getSnackbarMsg(address.addressName!, ' deleted from your properties'));
            getAddressData();
            setLoader(false);
        } catch (err) {
            setLoader(false);
            extractErrorFromResponseOrShowDefaultMsg(err, 'Unable to delete the Property');
        }
    };

    return (
        <ContentContainer disableGrid>
            <ContentContainer disableGrid background={'bg3'}>
                <ContentContainer>
                    <Navigator color={'primary'} transparent={false} />
                </ContentContainer>
            </ContentContainer>
            <ContentContainer>
                <LoadingOverlay open={loading || loader} />
                <ConfirmationModal
                    open={isDeleteAddressPopUpVisible ? true : false}
                    handleClose={(shouldProceed?: boolean) => {
                        setIsDeleteAddressPopUpVisible(null);
                        if (shouldProceed) {
                            handleDeleteAddress(isDeleteAddressPopUpVisible!);
                        }
                    }}
                    confirmButtonText={'YES, DELETE'}
                    cancelButtonText={'NO, KEEP IT'}
                    confirmMsg={'Do you really want to remove your property'}
                />
                <AddressContainer
                    flow={AddressType.Properties}
                    isFirstTimeCapture={addressToEdit}
                    shouldShowChooseAddressModal={false}
                    hideBackButton={true}
                    onEditAddressSubmit={(address: Address) => {
                        if (address != null) {
                            address.id
                                ? handleEditProperty(address)
                                : handleAddProperty(transformPropertyObjForEdit(address, loginData));
                        }
                    }}
                />
                <Box>
                    <Grid container justifyContent={'space-between'} sx={cssObject.headingLayout}>
                        <Box sx={cssObject.headingStyle}>
                            <Heading label={'My Properties'} />
                        </Box>
                        {!showNoContentLayout && (
                            <Box>
                                <Button
                                    smallerPadding={isXs}
                                    color={'primary'}
                                    homePageLargeButton={!isXs}
                                    onClick={openAddAddressPopup}
                                >
                                    {isXs ? 'ADD NEW' : 'ADD NEW PROPERTY'}
                                </Button>
                            </Box>
                        )}
                    </Grid>
                </Box>
                <Box>
                    {showNoContentLayout && <NoPropertiesLayout onAddAddressClick={openAddAddressPopup} />}
                    <Grid container spacing={2} style={{ marginBottom: 32 }}>
                        {addressList &&
                            addressList.map((addressItem: Address, index: number) => {
                                return <PropertyDetails address={addressItem} onMenuClick={onMenuClick} key={index} />;
                            })}
                    </Grid>
                </Box>
            </ContentContainer>
        </ContentContainer>
    );
}
