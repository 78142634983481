import { Address } from 'models/cart';
import {
    addDefaultProfileBillingAddress,
    profileDetailsSuccess,
    getProfileAddresses,
    getProfileAddressesSuccess,
    performDefaultBillingAddressUpdate,
    performDefaultBillingAddressUpdateSuccess,
} from 'store/actions/addressActions';
import {
    loginFailure,
    loginSuccess,
    clearLoginData,
    logoutSuccess,
    performLogin,
    performLogout,
    performSignUp,
    signUpSuccess,
    signUpFailure,
    sendVerificationOtpSuccess,
    sendVerificationOtpFailure,
    sendVerificationOtp,
    verifyEnteredOtp,
    verifyEnteredOtpFailure,
    verifyEnteredOtpSuccess,
    sendLoginOtpSuccess,
    sendLoginOtp,
    sendLoginOtpFailure,
    emailDoesNotExist,
    emailAlreadyExist,
    logoutFailure,
} from 'store/actions/loginActions';
import { on, reducer } from 'ts-action';

interface State {
    firstname: string;
    lastname: string;
    email: string;
    phone: string;
    loading: boolean;
    isVerified: boolean;
    defaultProfileAddress: Address | undefined;
}

const initialState: State = {
    firstname: '',
    lastname: '',
    email: '',
    phone: '',
    loading: false,
    isVerified: false,
    defaultProfileAddress: {},
};
export const LoginReducer = reducer(
    initialState,
    on(performLogin, (state, action) => ({
        ...state,
        loading: true,
        error: null,
    })),
    on(loginSuccess, (state, action) => ({
        ...state,
        ...action.payload.loginResponse,
        loading: false,
        error: null,
    })),
    on(loginFailure, (state, action) => ({
        ...state,
        loading: false,
        error: null,
    })),
    on(performSignUp, (state, action) => ({
        ...state,
        loading: true,
        error: null,
    })),
    on(signUpSuccess, (state, action) => ({
        ...state,
        ...action.payload,
        isVerified: false,
        loading: false,
        error: null,
    })),
    on(signUpFailure, (state, action) => ({
        ...state,
        loading: false,
        error: null,
    })),
    on(emailDoesNotExist, (state, action) => ({
        ...state,
        loading: false,
        error: null,
    })),
    on(emailAlreadyExist, (state, action) => ({
        ...state,
        loading: false,
        error: null,
    })),
    on(performLogout, (state, action) => ({
        ...state,
        loading: true,
        error: null,
    })),
    on(logoutSuccess, (state, action) => ({
        ...initialState,
        loading: false,
        error: null,
    })),
    on(logoutFailure, (state, action) => ({
        ...state,
        loading: false,
        error: null,
    })),
    on(sendLoginOtp, (state, action) => ({
        ...state,
        loading: true,
        isLoginOtpSendSuccessfully: false,
        error: null,
    })),
    on(sendLoginOtpSuccess, (state, action) => ({
        ...state,
        isLoginOtpSendSuccessfully: true,
        loading: false,
        email: action.payload.email,
        error: null,
    })),
    on(sendLoginOtpFailure, (state, action) => ({
        ...state,
        loading: false,
        error: null,
    })),
    on(sendVerificationOtp, (state, action) => ({
        ...state,
        loading: true,
    })),
    on(sendVerificationOtpSuccess, (state, action) => ({
        ...state,
        loading: false,
    })),
    on(sendVerificationOtpFailure, (state, action) => ({
        ...state,
        loading: false,
    })),

    on(verifyEnteredOtp, (state, action) => ({
        ...state,
        loading: true,
    })),
    on(verifyEnteredOtpSuccess, (state, action) => ({
        ...state,
        isVerified: true,
        loading: false,
    })),
    on(verifyEnteredOtpFailure, (state, action) => ({
        ...state,
        loading: false,
        error: null,
    })),

    on(clearLoginData, (state, action) => ({
        ...initialState,
        loading: false,
        error: null,
    })),
    on(getProfileAddresses, (state) => ({
        ...state,
        loading: true,
    })),
    on(getProfileAddressesSuccess, (state, action) => ({
        ...state,
        defaultProfileAddress: action.payload.adresses.find((address) => address.defaultBilling),
        loading: false,
    })),
    on(performDefaultBillingAddressUpdate, (state) => ({
        ...state,
        loading: true,
    })),
    on(performDefaultBillingAddressUpdateSuccess, (state, action) => ({
        ...state,
        defaultProfileAddress: action.payload,
    })),
    on(addDefaultProfileBillingAddress, (state) => ({
        ...state,
        loading: true,
    })),
    on(profileDetailsSuccess, (state, action) => ({
        ...state,
        firstname: action.payload.firstname,
        lastname: action.payload.lastname,
        phone: action.payload.phone,
        loading: false,
    }))
);

export default LoginReducer;
