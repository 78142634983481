import React, { PropsWithChildren } from 'react';
import Grid from '@mui/material/Grid';
import { Paragraph } from './Paragraph';
import ContentContainer from './ContentContainer';
import Hidden from '@mui/material/Hidden';
import Typography from '@mui/material/Typography';

interface Props {
    id?: string;
    label?: string;
    headlineSize?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5';
    textSize?: 'normal' | 'small';
    color?: 'primary' | 'secondary';
    italic?: boolean;
    shouldHideEmptyGridAtBottom?: boolean;
}

export default function HeadlineParagraph(props: PropsWithChildren<Props>) {
    const {
        label,
        headlineSize = 'h1',
        color = 'primary',
        textSize = 'normal',
        italic = false,
        shouldHideEmptyGridAtBottom,
        children,
    } = props;
    return (
        <ContentContainer disableGrid>
            <Grid container direction={'column'} spacing={2}>
                {label && (
                    <Grid item>
                        <Typography variant={headlineSize}>{label}</Typography>
                    </Grid>
                )}
                {children && (
                    <Grid item>
                        <Paragraph color={color} size={textSize} italic={italic}>
                            {children}
                        </Paragraph>
                    </Grid>
                )}
                {!shouldHideEmptyGridAtBottom && (
                    <Hidden lgUp>
                        <Grid item />
                    </Hidden>
                )}
            </Grid>
        </ContentContainer>
    );
}
