import { useTheme } from '@mui/material/styles';
import { getThemePrimaryMain } from 'components/common/Utilities';
import * as React from 'react';

function SatisfactionImg2(props: any) {
    const theme = useTheme();
    const themePrimaryMain = getThemePrimaryMain(theme);
    return (
        <svg
            id="Component_56_1"
            data-name="Component 56 – 1"
            xmlns="http://www.w3.org/2000/svg"
            width="48"
            height="48"
            viewBox="0 0 48 48"
        >
            <g
                id="Rectangle_3934"
                data-name="Rectangle 3934"
                fill="#8d6161"
                stroke="#707070"
                strokeWidth="1"
                opacity="0"
            >
                <rect width="48" height="48" stroke="none" />
                <rect x="0.5" y="0.5" width="47" height="47" fill="none" />
            </g>
            <path
                id="Path_277"
                data-name="Path 277"
                d="M420.5,371.668a13.169,13.169,0,0,1,23.667-7.95"
                transform="translate(-409.932 -347.845)"
                fill="none"
                stroke={themePrimaryMain}
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
            />
            <path
                id="Path_278"
                data-name="Path 278"
                d="M447.462,374.5a13.169,13.169,0,0,1-23.429,8.255"
                transform="translate(-410.558 -350.676)"
                fill="none"
                stroke={themePrimaryMain}
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
            />
            <path
                id="Path_279"
                data-name="Path 279"
                d="M417.121,374.5l2.8,2.832,2.865-2.832"
                transform="translate(-409.384 -351.499)"
                fill="none"
                stroke={themePrimaryMain}
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
            />
            <path
                id="Path_280"
                data-name="Path 280"
                d="M454.783,375.332l-2.8-2.832-2.865,2.832"
                transform="translate(-414.998 -350.322)"
                fill="none"
                stroke={themePrimaryMain}
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
            />
            <circle
                id="Ellipse_140"
                data-name="Ellipse 140"
                cx="4.32"
                cy="4.32"
                r="4.32"
                transform="translate(19.415 19.504)"
                fill="none"
                stroke={themePrimaryMain}
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
            />
        </svg>
    );
}

export default SatisfactionImg2;
