import { on, reducer } from 'ts-action';
import { Quote } from '../../models/quotes';
import {
    downloadQuoteAction,
    generateQuoteForCart,
    generateQuoteForSelectionPage,
    reGenerateQuoteFromQuotationPage,
    myQuotesAction,
    myQuotesError,
    myQuotesSuccess,
    proceedToDownloadQuote,
    resetQuoteData,
} from '../actions/quotes';

interface MyQuotesState {
    loading: boolean;
    error: Error | null;
    myQuotes: Quote[];
}
const initialState: MyQuotesState = {
    loading: false,
    error: null,
    myQuotes: [],
};

export const quoteReducer = reducer(
    initialState,
    on(myQuotesAction, (state) => ({
        ...state,
        loading: true,
        error: null,
    })),
    on(resetQuoteData, (state, action) => ({
        ...initialState,
    })),
    on(myQuotesSuccess, (state, action) => ({
        ...state,
        myQuotes: action.payload,
        loading: false,
        error: null,
    })),
    on(myQuotesError, (state, action) => ({
        ...state,
        loading: false,
        error: action.payload,
    })),
    on(generateQuoteForCart, generateQuoteForSelectionPage, reGenerateQuoteFromQuotationPage, (state, action) => ({
        ...state,
        loading: true,
    })),
    on(downloadQuoteAction, (state, action) => ({
        ...state,
        loading: true,
    })),
    on(proceedToDownloadQuote, (state, action) => ({
        ...state,
        loading: false,
    }))
);

export default quoteReducer;
