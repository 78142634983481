import React, { Fragment } from 'react';
import { Props as MenuProps } from 'components/SecondaryNavigation';
import { Theme, useTheme } from '@mui/material/styles';
import SubPage from 'components/subpage/SubPage';
import Grid from '@mui/material/Grid';
import CardMedia from '@mui/material/CardMedia';
import ProTipAndAdditionalLinks from 'components/subpage/ProTipAndAdditionalLinks';
import { Heading } from 'components/Heading';
import { Paragraph } from 'components/Paragraph';
import { getSubPageMenus } from './menu-util';
import VideoOverlay from '../../components/VideoOverlay';
import VideoOverlayBackground from 'assets/images/knowledges/kc_video_background_2.png';
import ContentContainer from 'components/ContentContainer';
import PlayButton from 'components/icons/PlayButton';
import FurnacesImage from 'components/icons/FurnacesImage';
import TooltipIdeaIcon from 'components/icons/TooltipIdeaIcon';
import { getThemePrimaryMain } from 'components/common/Utilities';
import { SXCssObject } from 'models/ThemeImages';

function Furnaces() {
    const createCssObject = (theme: Theme): SXCssObject => {
        return {
            contentHeading: {
                paddingBottom: theme.spacing(2),
            },
            contentImage: {
                paddingTop: theme.spacing(2),
                backgroundColor: theme.customProperties.knowledgeCenterImageBackground,
            },
            image: {
                margin: 'auto',
                width: {
                    sm: '505px',
                },
            },
            contentInteractive: {
                alignItems: 'center',
                justifyContent: 'center',
                backgroundImage: `url(${VideoOverlayBackground})`,
                height: {
                    xs: theme.spacing(20),
                    sm: theme.spacing(34),
                },
                backgroundPosition: 'center',
                backgroundSize: 'contain',
                backgroundRepeat: 'no-repeat',
            },
            contentSecondaryHeading: {
                paddingTop: {
                    xs: theme.spacing(2),
                    sm: theme.spacing(4),
                },
                paddingBottom: theme.spacing(2),
            },
            playButton: {
                cursor: 'pointer',
                '&:hover': {
                    opacity: 0.9,
                },
                width: {
                    xs: '80px',
                    sm: 'inherit',
                },
                paddingTop: {
                    xs: theme.spacing(2),
                    sm: theme.spacing(3),
                },
            },
            contentInnerHeading: {
                py: theme.spacing(2),
            },

            tooltipContainer: {
                padding: {
                    xs: theme.spacing(2),
                    sm: '35px',
                },
                border: `1px solid ${theme.palette.primary.contrastText}`,
                borderRadius: '4px',
                margin: '40px 0px',
            },

            tooltipIcon: {
                marginRight: '10px',
            },
            toolTipHeading: {
                paddingBottom: theme.spacing(2),
                alignItems: 'center',
            },
            listPoint: {
                backgroundColor: getThemePrimaryMain(theme),
                borderRadius: '50%',
                padding: theme.spacing(0, 0.9, 0, 0.9),
                fontSize: '12px',
                color: theme.palette.common.white,
                marginRight: '10px',
                marginTop: '4px',
            },
            listPointContainer: {
                padding: '5% 5%',
            },
        };
    };
    const menu: MenuProps = {
        id: 'menu',
        label: 'More Knowledge Center Topic',
        color: 'primary',
        subMenus: getSubPageMenus('furnaces'),
    };
    const cssObject: SXCssObject = createCssObject(useTheme());
    const [openVdo, setOpenVdo] = React.useState(false);
    const handleCloseVdo = () => {
        setOpenVdo(false);
    };
    return (
        <SubPage
            menu={menu}
            content1={
                <Fragment>
                    <Grid item xs={12} sx={cssObject.contentHeading}>
                        <Heading variant={'h3'} isPopUp color={'primary'} label={'Furnaces'} />
                    </Grid>
                    <Grid item xs={12}>
                        <Paragraph responsiveFontSize16 align={'left'}>
                            Many homeowners do not know how their central gas heating system (Furnace) works. They just
                            know it is supposed to keep them warm when the outdoor temperatures drop! When it comes time
                            to upgrade or replace your current home heating system, it may be helpful to understand your
                            system before investing in new equipment.
                        </Paragraph>
                    </Grid>
                    <Grid item xs={12} sx={cssObject.contentInnerHeading}>
                        <Paragraph responsiveFontSize20 bold headingStyle>
                            Watch this video to learn how a furnace heats your home
                        </Paragraph>
                    </Grid>
                    <Grid item xs={12} container sx={cssObject.contentInteractive}>
                        <div>
                            <PlayButton
                                onClick={() => {
                                    setOpenVdo(true);
                                }}
                            />
                        </div>
                    </Grid>
                    <Grid item xs={12} sx={cssObject.contentImage}>
                        <CardMedia style={{ overflowY: 'auto' }}>
                            <FurnacesImage />
                        </CardMedia>
                        <Grid>
                            <Grid sx={cssObject.listPointContainer} container spacing={2}>
                                <Grid item container alignItems={'flex-start'}>
                                    <Grid item sx={cssObject.listPoint}>
                                        1
                                    </Grid>
                                    <Grid item xs={10} sm={11}>
                                        <Paragraph fontSizeMediumSmall>
                                            A furnaces become more efficient the exhaust vent will change from metal to
                                            PVC pipe. All 90% and higher efficiency furnaces require PVC pipe.
                                        </Paragraph>
                                    </Grid>
                                </Grid>
                                <Grid item container alignItems={'flex-start'}>
                                    <Grid item sx={cssObject.listPoint}>
                                        2
                                    </Grid>
                                    <Grid item xs={10} sm={11}>
                                        <Paragraph fontSizeMediumSmall>
                                            The furnace heat exchanger uses a gas heating system to create a cycle of
                                            increasing the temperature of cooler air
                                        </Paragraph>
                                    </Grid>
                                </Grid>
                                <Grid item container alignItems={'flex-start'}>
                                    <Grid item sx={cssObject.listPoint}>
                                        3
                                    </Grid>
                                    <Grid item xs={10} sm={11}>
                                        <Paragraph fontSizeMediumSmall>
                                            Furnace blowers can be single-stage, two-stage, or variable speed to
                                            maximize comfort and energy savings. The more “speed settings” or stages
                                            your furnace has typically means a higher overall comfort level for your
                                            family and greater energy savings.
                                        </Paragraph>
                                    </Grid>
                                </Grid>
                                <Grid item container alignItems={'flex-start'}>
                                    <Grid item sx={cssObject.listPoint}>
                                        4
                                    </Grid>
                                    <Grid item xs={10} sm={11}>
                                        <Paragraph fontSizeMediumSmall>
                                            Air filtration devices capture dust and other particles from the home and
                                            removes them Before the air is heated and sent back into your home
                                        </Paragraph>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <ContentContainer sx={cssObject.tooltipContainer} background={'wizardtip'}>
                        <Grid container>
                            <Grid item xs={12}>
                                <Grid container sx={cssObject.toolTipHeading}>
                                    <TooltipIdeaIcon sx={cssObject.tooltipIcon} />
                                    <Heading variant={'h3'} isPopUp={true} color={'primary'} label={'Pro Tip'} />
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                <Paragraph>
                                    The efficiency ratings represent how efficient the furnace is based on the cost of
                                    running the system. For example, a 90% rating indicates that 90 cents of every
                                    energy dollar you spend actually goes towards warming your home. Older furnaces may
                                    be 70% efficient, meaning 30 cents out every energy dollar you spend is lost.
                                </Paragraph>
                            </Grid>
                        </Grid>
                    </ContentContainer>
                    <VideoOverlay
                        open={openVdo}
                        onClose={handleCloseVdo}
                        heading={'Furnaces'}
                        src={
                            'https://res.cloudinary.com/motili/video/upload/v1594975985/ecomm/video/Goodman_HowItWorks_Furnace_Clean_1080p.mp4'
                        }
                    />
                </Fragment>
            }
            content2={
                <ProTipAndAdditionalLinks
                    additionalLinks={{
                        headLine: 'Want to learn more?  Check out these links',
                        links: [
                            {
                                text: 'Gas Furnace Features: A Comparison Guide',
                                url: 'https://www.goodmanmfg.com/resources/hvac-learning-center/before-you-buy/gas-furnace-features-comparison-guide',
                            },
                            {
                                text: 'Heat Pump and Gas Furnace: What’s the Difference?',
                                url: 'https://www.goodmanmfg.com/resources/hvac-learning-center/hvac-101/whats-the-difference-between-a-heat-pump-and-gas-furnace',
                            },
                            {
                                text: 'Gas Furnace Energy-Efficiency Ratings',
                                url: 'https://www.goodmanmfg.com/resources/hvac-learning-center/before-you-buy/what-s-the-difference-between-gas-furnace-energy-efficiency-ratings',
                            },
                            {
                                text: 'What is Variable Speed HVAC Technology?',
                                url: 'https://www.goodmanmfg.com/resources/hvac-learning-center/hvac-101/what-is-variable-speed-technology',
                            },
                            {
                                text: 'Goodman Customer Reviews',
                                url: 'https://www.goodmanmfg.com/resources/customer-reviews',
                            },
                        ],
                    }}
                />
            }
        />
    );
}

export default Furnaces;
