import { useTheme } from '@mui/material/styles';
import { getThemePrimaryMain } from 'components/common/Utilities';
import * as React from 'react';

const LocationIcon = (props: any) => {
    const theme = useTheme();
    const themePrimaryMain = getThemePrimaryMain(theme);
    return (
        <svg
            data-name="Component 50 \u2013 1"
            xmlns="http://www.w3.org/2000/svg"
            width={32}
            height={32}
            viewBox="0 0 32 32"
            {...props}
        >
            <g data-name="Group 3422" fill="#fff">
                <g data-name="Path 267" strokeLinecap="round" strokeLinejoin="round">
                    <path d="M15.886 26.481c-.365 0-.605-.17-.999-.471a19.844 19.844 0 0 1-.956-.789 30.269 30.269 0 0 1-2.766-2.748c-1.165-1.32-2.103-2.634-2.79-3.906-.88-1.634-1.343-3.2-1.375-4.656V13.89C7 8.987 10.988 5 15.89 5c4.9 0 8.888 3.988 8.888 8.89v.02c-.05 2.488-1.322 5.204-3.779 8.072-1.234 1.441-2.7 2.844-4.354 4.171l-.04.032-.238.295-.481.001Z" />
                    <path
                        d="M15.89 6A7.89 7.89 0 0 0 8 13.89c.123 5.716 7.81 11.687 7.89 11.59.078-.097 7.77-5.824 7.888-11.59A7.89 7.89 0 0 0 15.89 6m0-2c5.453 0 9.89 4.436 9.89 9.89l-.001.04c-.056 2.726-1.408 5.654-4.02 8.703-1.274 1.488-2.784 2.935-4.488 4.3l-.065.053a2.01 2.01 0 0 1-1.32.495c-.72 0-1.19-.36-1.605-.677-.206-.158-.548-.43-1.006-.829a31.286 31.286 0 0 1-2.86-2.84C9.2 21.758 8.219 20.38 7.497 19.042c-.958-1.775-1.46-3.494-1.496-5.11v-.043C6 8.436 10.436 4 15.89 4Z"
                        fill={`${themePrimaryMain}`}
                    />
                </g>
                <g
                    data-name="Ellipse 138"
                    transform="translate(13 10.517)"
                    stroke={`${themePrimaryMain}`}
                    strokeWidth={2}
                >
                    <circle cx={3} cy={3} r={3} stroke="none" />
                    <circle cx={3} cy={3} r={2} fill="none" />
                </g>
            </g>
        </svg>
    );
};
export default LocationIcon;
