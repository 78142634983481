import { AAHDR_DOMAIN } from 'components/common/constants';
import { AppConfig } from './models/appConfig';
import aahdrTheme, { aahdrContent, aahdrImages } from './storeDetails/aahdr';
import homeVestorsTheme, { homeVestorsContent, homeVestorsImages } from './storeDetails/homevestors';
interface StoreThemeObj {
    store: string;
    configData: any;
}
export const StoreThemeData: { [key: string]: StoreThemeObj } = {
    chicagoland: {
        store: 'chicago_airandheat_com',
        configData: { theme: aahdrTheme, images: aahdrImages, content: aahdrContent },
    },
    missouri: {
        store: 'missouri_airandheat_com',
        configData: { theme: aahdrTheme, images: aahdrImages, content: aahdrContent },
    },
    greaterdc: {
        store: 'greaterdc_airandheat_com',
        configData: { theme: aahdrTheme, images: aahdrImages, content: aahdrContent },
    },
    homevestors: {
        store: 'homevestors_airandheat_com',
        configData: { theme: homeVestorsTheme, images: homeVestorsImages, content: homeVestorsContent },
    },
    None: {
        store: 'NONE',
        configData: { theme: aahdrTheme, images: aahdrImages, content: aahdrContent },
    },
};

const getStoreFromUrl = (url: string) => {
    try {
        const region: string = url.split('.')[0];
        const storeCodeFromUrl = StoreThemeData[region].store || StoreThemeData.None.store;
        return storeCodeFromUrl;
    } catch (e) {
        return StoreThemeData.None.store;
    }
};

const storeCode = getStoreCode();
const theme = getTheme(storeCode);
const googleAnalyticId = getGoogleAnalyticId(storeCode);
const googleTagManagerId = getGoogleTagManagerId(storeCode);
const hotjarId = getHotjarId(storeCode);
const hotjarSv = getHotjarSv(storeCode);

const config: AppConfig = {
    appName: process.env.REACT_APP_NAME,
    appVersion: process.env.REACT_APP_VERSION,
    apiHost: getApiHost(),
    httpTimeout: 60000,
    storeCode,
    googleAnalyticId: googleAnalyticId,
    googleTagManagerId: googleTagManagerId,
    hotjarId: hotjarId,
    hotjarSv: hotjarSv,
    ...theme?.configData,
};

function getApiHost() {
    if (window.location.host && window.location.host.indexOf(AAHDR_DOMAIN) !== -1) {
        return process.env.REACT_APP_AIRANDHEATDONERIGHT_HOST;
    }
    return process.env.REACT_APP_MOTILI_HOST;
}

function getStoreCode() {
    const url = `${window.location.host}`.toLowerCase();
    const storeCodeFromUrl = url.replace(/\W/g, '_');
    const storeCodeFromMapping = getStoreFromUrl(url);
    return storeCodeFromMapping || storeCodeFromUrl;
}

export const isHomeVestors = () => storeCode === StoreThemeData.homevestors.store;

export const isStoreNone = () => {
    return config.storeCode === StoreThemeData.None.store;
};
function getTheme(storeCode: string) {
    const arr = Object.values<StoreThemeObj>(StoreThemeData);
    const output = arr.find((data: StoreThemeObj) => data.store === storeCode);
    return output;
}

function getGoogleAnalyticId(storeCode: string) {
    if (storeCode === StoreThemeData.None.store) {
        return '';
    }
    const storeRegion = storeCode.split('_')[0];
    return process.env['REACT_APP_GOOGLE_ANALYTIC_ID_' + storeRegion.toUpperCase()];
}

function getGoogleTagManagerId(storeCode: string) {
    if (storeCode === StoreThemeData.None.store) {
        return '';
    }
    const storeRegion = storeCode.split('_')[0];
    return process.env['REACT_APP_GOOGLE_TAG_MANAGER_ID_' + storeRegion.toUpperCase()];
}

function getHotjarId(storeCode: string) {
    if (storeCode === StoreThemeData.None.store) {
        return process.env.REACT_APP_HOTJAR_ID_AIRANDHEATDONERIGHT;
    }
    const storeRegion = storeCode.split('_')[0];
    return process.env['REACT_APP_HOTJAR_ID_' + storeRegion.toUpperCase()];
}

function getHotjarSv(storeCode: string) {
    if (storeCode === StoreThemeData.None.store) {
        return process.env.REACT_APP_HOTJAR_SV_AIRANDHEATDONERIGHT;
    }
    const storeRegion = storeCode.split('_')[0];
    return process.env['REACT_APP_HOTJAR_SV_' + storeRegion.toUpperCase()];
}

export default config;
