import { Theme, useTheme } from '@mui/material/styles';
import { Box, Dialog, DialogContent, Divider, Step, StepContent, StepLabel, Stepper } from '@mui/material';
import React from 'react';
import MuiDialogTitle from '@mui/material/DialogTitle';
import { SXCssObject } from 'models/ThemeImages';
import ContentContainer from 'components/ContentContainer';
import { Heading } from 'components/Heading';
import Close from 'components/icons/Close';
import { Paragraph } from 'components/Paragraph';
import EmailSmall from 'components/icons/EmailSmall';
import PhoneSmall from 'components/icons/PhoneSmall';
import WarrantyIcon from 'components/icons/WarrantyIcon';
import DownPaymentIcon from 'components/icons/DownPaymentIcon';
import QuotesIcon from 'components/icons/QuotesIcon';
import PermitsIcon from 'components/icons/PermitsIcon';
import EquipmentSelectionIcon from 'components/icons/EquipmentSelectionIcon';
import InspectionsIcon from 'components/icons/InspectionsIcon';
import DeliveryIcon from 'components/icons/DeliveryIcon';
import MuiLink from '@mui/material/Link';
import { useSelector } from 'react-redux';
import { selectSalesEmail } from 'store/selectors/storeSelector';

const createCssObject = (theme: Theme): SXCssObject => ({
    root: {
        padding: {
            xs: '10px 0px',
            sm: '18px 0px',
        },
    },
    modalContent: {
        margin: '0px 0px 30px',
        height: 'auto',
        padding: {
            xs: '8px 0px',
            sm: '0px 20px',
        },
    },
    alertIconStyle: {
        marginBottom: '24px',
    },
    modalPaper: {
        borderRadius: '4px',
        margin: '24px',
        p: {
            xs: 2,
        },
    },
    fullWidth: {
        width: '100%',
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1.6),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
        cursor: 'pointer',
    },
    modalTitleContainer: {
        padding: '10px 24px 32px',
        position: 'relative',
    },
    textMsg: {
        my: 1,
    },
    dividerStyle: {
        backgroundColor: theme.palette.primary.dark,
        height: '3px',
        border: 'none',
        margin: '12px 0px',
    },
    footerBox: {
        backgroundColor: theme.palette.primary.light,
        borderTop: `3px solid ${theme.palette.primary.dark}`,
        p: {
            xs: 1.2,
            sm: 2,
        },
        textAlign: 'center',
    },
});

interface Props {
    open: boolean;
    handleClose: () => void;
    isEquipmentOnly: boolean;
}

const EquipmentOnlyHeading = 'Hello and thank you for choosing Motili for your HVAC equipment needs!';

const EquipmentOnlySubHeading =
    'Please see below information which outlines a few specific items involved in our equipment only ordering process:';

const FullInstallationHeading = 'Hello and thank you for choosing Motili for your HVAC replacement needs!';

const FullInstallationSubHeading =
    'Please see below information which outlines a few specific items involved in our installation process:';

const EquipmentOnlySteps = [
    {
        label: 'Equipment Selection',
        description: `Our equipment offerings include standard split systems, packaged units, and Daikin mini-splits. We ask that you work with your contractor during the equipment selection process in order to ensure accuracy. (If incorrect items are ordered, all restocking fees and additional fees incurred will be charged to customer)`,
        icon: EquipmentSelectionIcon,
    },
    {
        label: 'Quotes',
        description: `Once proper equipment has been selected, the HomeVestors/Motili site will provide you with a quoted price. If rush delivery or any add-ons are requested, additional fees may apply.`,
        icon: QuotesIcon,
    },
    {
        label: 'Payment',
        description:
            'If a quote is approved, Motili requires payment prior to shipping the equipment order. Our Finance department will reach out to collect credit card information within 24 hours of approval.',
        icon: DownPaymentIcon,
    },
    {
        label: 'Delivery/Pick-Up',
        description:
            'Equipment delivery direct to your selected contractor’s business or residence is preferred. This allows the Motili team to source items from multiple locations if needed, while also providing a much simpler service for our customers. If pick-up is desired, a licensed contractor will need to be present for the equipment to be released from the local branch. Please let us know in advance the name of your contracting company and we will coordinate with the branch.',
        icon: DeliveryIcon,
    },
    {
        label: 'Warranty',
        description: `Once the equipment is received, you and your contractor will work together to ensure equipment is registered through Goodman, in order to qualify for any future warranty matters.`,
        icon: WarrantyIcon,
    },
];

const FullInstallationSteps = [
    {
        label: 'Quotes',
        description: `While we strive to give you the most accurate pricing upfront, our initial quotes are estimates. Items are subject to change due to scope of work and any other incidentals. Items that are not typically added to the initial estimate include duct work and modifications, electrical, plumbing, structural work, and any code related items required by the municipality.`,
        icon: QuotesIcon,
    },
    {
        label: 'Down Payment',
        description:
            'If a quote is approved, Motili requires a 50% down payment prior to scheduling the install. Our Finance department will reach out to collect credit card information for the down payment within 24 hours of approval. The remaining balance will be collected upon completion.',
        icon: DownPaymentIcon,
    },
    {
        label: 'Permits',
        description: `Motili requires that all contractors apply for the proper permits, prior to beginning installs. If the county does not require permits, proof of this will be provided. Once the permit has been issued, the install will begin. Please note that all utilities must be available (water, gas, electric) in order to complete start up procedures during install.`,
        icon: PermitsIcon,
    },
    {
        label: 'Inspections',
        description: `Motili requires that all installs must be inspected by a city official after completion. If any issues arise during inspection, you will be notified, and items will be corrected (if applicable to HVAC).`,
        icon: InspectionsIcon,
    },
    {
        label: 'Warranty',
        description: `Upon completion of the install, Motili will register your equipment through Goodman. This equipment only warranty will cover the current homeowner for up to 10 years. Please notify your Job Management Specialist if you are not going to own the property after the installation is completed.`,
        icon: WarrantyIcon,
    },
];

export function NextStepsModal(props: Props) {
    const steps = props.isEquipmentOnly ? EquipmentOnlySteps : FullInstallationSteps;
    const cssObject: SXCssObject = createCssObject(useTheme());
    const salesEmail: any = useSelector(selectSalesEmail);
    const theme = useTheme();

    const removeStr = (str = "" ) => {
        let result = str.split('@');
        return result[0].replace('.', " ");
    }


    const DialogTitle = (dialogProps: any) => {
        const { onClose } = dialogProps;
        return (
            <MuiDialogTitle sx={cssObject.modalTitleContainer}>
                <Box aria-label="close" sx={cssObject.closeButton} onClick={onClose}>
                    <Close />
                </Box>
            </MuiDialogTitle>
        );
    };

    return (
        <Dialog
            onClose={() => { }}
            open={props.open}
            maxWidth={'md'}
            fullWidth={true}
            scroll={'body'}
            disableEscapeKeyDown={true}
            aria-describedby="alert-dialog-slide-description"
            PaperProps={{
                sx: cssObject.modalPaper,
            }}
        >
            <DialogTitle id="delete-dialog-title" onClose={() => props.handleClose()}></DialogTitle>

            <DialogContent sx={cssObject.modalContent}>
                <ContentContainer disableGrid sx={cssObject.fullWidth}>
                    <Box>
                        <Heading label={'Next Steps'} isPopUp />
                        <Divider sx={cssObject.dividerStyle}></Divider>
                    </Box>
                    <Box sx={cssObject.textMsg}>
                        <br />
                        <Heading
                            sx={{ maxWidth: '595px' }}
                            label={props.isEquipmentOnly ? EquipmentOnlyHeading : FullInstallationHeading}
                            isPopUp
                        />
                        <Paragraph sx={{ py: 1 }}>
                            {props.isEquipmentOnly ? EquipmentOnlySubHeading : FullInstallationSubHeading}
                        </Paragraph>
                    </Box>
                    <Box sx={{ pb: 3 }}>
                        <Stepper orientation="vertical" sx={{ '& .MuiStepConnector-root': { ml: 2.4 } }}>
                            {steps.map((step) => (
                                <Step
                                    key={step.label}
                                    active={true}
                                    sx={{
                                        '& .MuiStepLabel-label': { pl: 2.5 },
                                        '& .MuiStepLabel-iconContainer': {
                                            background: theme.palette.secondary.main,
                                            padding: '12px',
                                            borderRadius: '50px',
                                            width: '44px',
                                            height: '44px',
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                        },
                                    }}
                                >
                                    <StepLabel StepIconComponent={step.icon}>
                                        <Paragraph responsiveFontSize18 bold headingColor>
                                            {step.label}
                                        </Paragraph>
                                    </StepLabel>
                                    <StepContent
                                        sx={{
                                            pl: '44px',
                                            ml: '24px',
                                        }}
                                    >
                                        <Paragraph>{step.description}</Paragraph>
                                    </StepContent>
                                </Step>
                            ))}
                        </Stepper>
                    </Box>
                    <Box sx={cssObject.footerBox}>
                        <br />
                        <Heading
                            variant="h4"
                            label={'Thank you for choosing Motili and we look forward to working with you soon!'}
                        />
                        <Paragraph sx={{ py: 1 }}>
                            Please feel free to email or call {removeStr(salesEmail)} with any questions on the
                            information above.
                        </Paragraph>
                        <Box
                            display={'flex'}
                            justifyContent={'center'}
                            sx={{ flexDirection: { xs: 'column', sm: 'row' }, flexWrap: 'wrap', alignItems: 'center' }}
                        >
                            <MuiLink
                                sx={{
                                    display: 'flex',
                                    fontSize: '14px',
                                    color: theme.palette.text.primary,
                                    alignItems: 'center',
                                    marginRight: '12px',
                                }}
                                underline={'none'}
                                target={'_blank'}
                                href={`mailto:${salesEmail}`}
                            >
                                <EmailSmall style={{ marginRight: '5px' }} /> {salesEmail}
                            </MuiLink>
                            <MuiLink
                                sx={{
                                    display: 'flex',
                                    fontSize: '14px',
                                    color: theme.palette.text.primary,
                                    alignItems: 'center',
                                    marginRight: '12px',
                                }}
                                underline={'none'}
                                target={'_blank'}
                                href={'tel:(303) 549-6121'}
                            >
                                <PhoneSmall style={{ marginRight: '5px' }} /> (303) 549-6121
                            </MuiLink>
                        </Box>
                    </Box>
                </ContentContainer>
            </DialogContent>
        </Dialog>
    );
}
