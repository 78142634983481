import { useTheme } from '@mui/material/styles';
import { getThemePrimaryMain } from 'components/common/Utilities';
import * as React from 'react';

function MyQuotesIcon(props: any) {
    const theme = useTheme();
    const themePrimaryMain = getThemePrimaryMain(theme);
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            style={{ marginLeft: 5, marginRight: -5 }}
        >
            <g id="Component_136_1" data-name="Component 136 – 1" transform="translate(1 1)">
                <line
                    id="Line_95"
                    data-name="Line 95"
                    x2="5.086"
                    transform="translate(4.414 9.707)"
                    fill="none"
                    stroke={`${themePrimaryMain}`}
                    strokeLinecap="round"
                    strokeWidth="2"
                />
                <line
                    id="Line_96"
                    data-name="Line 96"
                    x2="5.071"
                    transform="translate(4.414 13.055)"
                    fill="none"
                    stroke={`${themePrimaryMain}`}
                    strokeLinecap="round"
                    strokeWidth="2"
                />
                <g id="Group_711" data-name="Group 711" transform="translate(-6097 4299)">
                    <g id="Group_710" data-name="Group 710" transform="translate(5421.381 -4674.522)">
                        <g id="Rectangle_726" data-name="Rectangle 726">
                            <path
                                id="Path_91"
                                data-name="Path 91"
                                d="M689.619,380.841v10.638a1.033,1.033,0,0,1-1,1.064h-12a1.034,1.034,0,0,1-1-1.064V376.586a1.034,1.034,0,0,1,1-1.064h8Z"
                                transform="translate(0 0)"
                                fill="none"
                                stroke={`${themePrimaryMain}`}
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                            />
                        </g>
                        <path
                            id="Path_92"
                            data-name="Path 92"
                            d="M684.088,375.522v5.6h5.531"
                            fill="none"
                            stroke={`${themePrimaryMain}`}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                        />
                    </g>
                </g>
            </g>
        </svg>
    );
}

export default MyQuotesIcon;
