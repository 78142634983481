import React from 'react';

export const QuoteNoData = (props: any) => {
    return (
        <svg
            id="Component_180_3"
            data-name="Component 180 – 3"
            xmlns="http://www.w3.org/2000/svg"
            width="52"
            height="52"
            viewBox="0 0 52 52"
        >
            <g id="Group_3580" data-name="Group 3580">
                <g id="Rectangle_74" data-name="Rectangle 74" fill="#fff" stroke="#707070" strokeWidth="1" opacity="0">
                    <rect width="52" height="52" stroke="none" />
                    <rect x="0.5" y="0.5" width="51" height="51" fill="none" />
                </g>
            </g>
            <g id="Component_135_1" data-name="Component 135 – 1" transform="translate(11.331 8.457)">
                <path
                    id="Path_273"
                    data-name="Path 273"
                    d="M1.57,0H16.49"
                    transform="translate(5.414 20.999)"
                    fill="none"
                    stroke="#707070"
                    strokeLinecap="round"
                    strokeWidth="2"
                />
                <line
                    id="Line_96"
                    data-name="Line 96"
                    x2="15"
                    transform="translate(6.984 25.499)"
                    fill="none"
                    stroke="#707070"
                    strokeLinecap="round"
                    strokeWidth="2"
                />
                <g id="Group_711" data-name="Group 711" transform="translate(-6096 4300)">
                    <g id="Group_710" data-name="Group 710" transform="translate(6096 -4300)">
                        <g id="Group_3587" data-name="Group 3587" transform="translate(0 0)">
                            <g id="Rectangle_726" data-name="Rectangle 726">
                                <path
                                    id="Path_90"
                                    data-name="Path 90"
                                    d="M703.958,386.218v23.393H674.619V374.522h18.337"
                                    transform="translate(-674.619 -374.522)"
                                    fill="none"
                                />
                                <path
                                    id="Path_91"
                                    data-name="Path 91"
                                    d="M702.958,385.862v20.68A2.012,2.012,0,0,1,701,408.611H677.572a2.014,2.014,0,0,1-1.953-2.068V377.59a2.014,2.014,0,0,1,1.953-2.068h15.622Z"
                                    transform="translate(-674.619 -374.522)"
                                    fill="none"
                                    stroke="#707070"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="2"
                                />
                            </g>
                            <path
                                id="Path_92"
                                data-name="Path 92"
                                d="M684.088,375.522v9.753h9.366"
                                transform="translate(-666.281 -373.522)"
                                fill="none"
                                stroke="#707070"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                            />
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    );
};
