import { Action, isType } from 'ts-action';
import ReactGA from 'react-ga4';
import { searchProduct, searchSecondProduct, showProductSuccess } from '../../store/actions/productSearchActions';
import { ProductSearch } from '../../models/productSearch';
import { Cart, CartItem, PaymentMethod } from '../../models/cart';
import { ProductCriteria } from '../../models/productCriteria';
import { checkoutGACreditCard } from '../../store/actions/gaEcommerceActions';
import { Order } from 'models/order';
import { getPaymentMethodForGAPurchase } from 'components/Payment/PaymentUtils';
import { GAEvents } from 'models/googleTracking';
import { sendGAEvent } from './GoogleAnalyticsTagging';

export function trackAction(
    action: Action,
    cart: Cart,
    order: Order,
    productCriteria: ProductCriteria,
    productSearch: ProductSearch
) {
    if (isType(action, searchProduct)) {
        return sendGAEvent(
            GAEvents.ProductSearch,
            buildGASearchCriteria(productCriteria)
        );
    }
    if(isType(action, searchSecondProduct)){
        return sendGAEvent(
            GAEvents.ProductSearch,
            buildGASearchCriteria(productCriteria)
        );
    }
    // Enhance Ecommerce: tracking product impression
    if (isType(action, showProductSuccess)) {
        return sendGAProductsImpression(
            productSearch,
            productCriteria?.promotionCode ? 'Marketing Campaign' : 'Product Search'
        );
    }
    // Enhance Ecommerce: tracking checkout credit card
    if (isType(action, checkoutGACreditCard)) {
        return sendGACheckout(
            'CreditCard',
            productCriteria?.promotionCode ? 'Marketing Campaign' : 'Product Search'
        );
    }
}

function buildGASearchCriteria(productCriteria: ProductCriteria) {
    const _criteria: ProductCriteria = {};
    _criteria.promotionCode = productCriteria.promotionCode;

    _criteria.squareFootage = productCriteria.squareFootage;
    if (!productCriteria.homeSystem) {
        return _criteria;
    }
    _criteria.homeSystem = productCriteria.homeSystem;

    return _criteria;
}
export function sendGAProductsImpression(productsearch: ProductSearch, productListName: string) {
    if (productsearch && productsearch.products && productsearch.products.length > 0) {
        productsearch.products.forEach((p, index) => {
            ReactGA.event('addImpression', {
                id: p.sku,
                name: p.name,
                variant: p.productAttributes.level,
                list: productListName,
                position: index + 1,
            });
        });
    }
}

export function sendGAAddToCart(cartItem?: CartItem) {
    if (cartItem) {
            ReactGA.event('add_to_cart', {
                currency: 'USD',
                value: cartItem.price,
                variant: cartItem.productAttributes.level,
                item_id: cartItem.sku,
                item_name: cartItem.name,
                price: cartItem.price,
                quantity: 1,
            });
    }
}

export function sendGARemoveToCart(cartItem: CartItem, productListName: string) {
    ReactGA.event('remove_from_cart', {
        value: cartItem.price,
        item_id: cartItem.sku,
        item_name: cartItem.name,
        price: cartItem.price,
        quantity: 1,
    });
}

export function sendGACheckout(paymentType: string, productListName: string) {
        ReactGA.event('begin_checkout', {
            option: paymentType,
            list: productListName,
        });
}
export function sendGAPurchase(order: Order, cartId: string, paymentMethod: PaymentMethod, finalPayment?: boolean, hvFinalOrder?: boolean) {
    try {
        if (order && order.items && order.items.length > 0) {
            let orderID: any = order.id;
            if(finalPayment && hvFinalOrder){
                orderID = `${orderID}-2`;
            }
            const paymentType = getPaymentMethodForGAPurchase[paymentMethod.code];
            ReactGA.event('purchase', {
                currency: 'USD',
                transaction_id: orderID,
                revenue: paymentMethod.amount || order.totalPaid,
                value: paymentMethod.amount || order.totalPaid,
                tax: order.taxAmount,
                payment_type: paymentType,
                payment_method: paymentMethod.method,
                grandTotal: order.grandTotal
            });
        }
    } catch (err) {}
}
