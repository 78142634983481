import * as React from 'react';

export default function SalesMessageIcon() {
    return (
        <svg
            id="Component_4_5"
            data-name="Component 4 – 5"
            xmlns="http://www.w3.org/2000/svg"
            width="19"
            height="19"
            viewBox="0 0 19 19"
        >
            <g id="Rectangle_80" data-name="Rectangle 80" fill="#fff" stroke="#707070" strokeWidth="1" opacity="0">
                <rect width="19" height="19" stroke="none" />
                <rect x="0.5" y="0.5" width="18" height="18" fill="none" />
            </g>
            <g id="Group_75" data-name="Group 75" transform="translate(-0.361 3.158)">
                <g id="Group_74" data-name="Group 74" transform="translate(1.576 0.266)">
                    <g
                        id="Rectangle_77"
                        data-name="Rectangle 77"
                        transform="translate(-0.215 -0.424)"
                        fill="none"
                        stroke="#73ad49"
                        strokeWidth="1.5"
                    >
                        <rect width="17" height="13" rx="2" stroke="none" />
                        <rect x="0.75" y="0.75" width="15.5" height="11.5" rx="1.25" fill="none" />
                    </g>
                    <path
                        id="Path_26"
                        data-name="Path 26"
                        d="M-1282.364,18199.619l6.9,4.328,7.008-4.328"
                        transform="translate(1283.885 -18197.633)"
                        fill="none"
                        stroke="#73ad49"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="1.5"
                    />
                </g>
            </g>
        </svg>
    );
}
