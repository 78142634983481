import * as React from "react"
import { SVGProps } from "react"

const PhoneSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    data-name="Component 7 \u2013 2"
    xmlns="http://www.w3.org/2000/svg"
    width={19}
    height={19}
    {...props}
  >
    <g
      data-name="Group 3117"
      fill="none"
      stroke="#58b7af"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
    >
      <path
        data-name="Path 179"
        d="M13.218 8.064a1.554 1.554 0 0 1 .008 2.853"
      />
      <path data-name="Path 180" d="M13.513 4.657a5.2 5.2 0 0 1 .026 9.641" />
      <path
        data-name="Path 181"
        d="M7.587 11.963h.714a2.339 2.339 0 0 1 2.331 1.978 2.274 2.274 0 0 1-2.162 2.65H4.933a1.25 1.25 0 0 1-1.213-1.284V3.284A1.251 1.251 0 0 1 4.933 2H8.466a2.253 2.253 0 0 1 2.185 2.315h0a2.252 2.252 0 0 1-2.185 2.314h-.8a.646.646 0 0 0-.683.711v4.022a.56.56 0 0 0 .604.601Z"
      />
    </g>
  </svg>
)

export default PhoneSmall
