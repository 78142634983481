import React from 'react';
import { useField } from 'formik';
import WizardInputs, { Options } from './WizardInputs';

interface Props {
    id?: string;
    name: string;
    options: Options[];
    isForSchedulePage?: boolean;
    isForContactUsPage?: boolean;
}

export default function FormikWizardInput(props: Props) {
    const { id, name, isForSchedulePage, isForContactUsPage, ...other } = props;
    const [, meta, { setValue }] = useField(props);
    return (
        <WizardInputs
            id={id || `${name}-inputs`}
            isForSchedulePage={props.isForSchedulePage}
            isForContactUsPage={props.isForContactUsPage}
            value={meta.value}
            onChange={(v) => setValue(v || '')}
            {...other}
        />
    );
}
