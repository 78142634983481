import React, { useRef } from 'react';
import { Box, Checkbox, Dialog, DialogContent, Grid } from '@mui/material';
import { Theme, useTheme } from '@mui/material/styles';
import MuiDialogTitle from '@mui/material/DialogTitle';
import Close from 'components/icons/Close';
import { Button } from 'components/Button';
import SelectedAddress from 'components/icons/SelectedAddress';
import EditIcon from 'components/icons/EditIcon';
import AddAddressIcon from 'components/icons/AddAddressIcon';
import { Address, AddressType, Cart, CartStatus } from 'models/cart';
import ContentContainer from 'components/ContentContainer';
import { Paragraph } from 'components/Paragraph';
import { useSelector } from 'react-redux';
import { selectCart } from 'store/selectors/cart';
import { Heading } from 'components/Heading';
import { ThemeWithAdditionalData } from 'themes/theme';
import { SXCssObject } from 'models/ThemeImages';
import {
    invokeGACartPageAddNewHomeAddressDialog,
    invokeGAHomeAddressCatpure,
    invokeGABillingAddressCatpure,
    invokeGAHomeAddressUpdate,
    invokeGABillingAddressUpdate
} from 'components/Analytics/GoogleAnalyticsTagging';

const createCssObject = (theme: Theme): SXCssObject => ({
    root: {
        padding: {
            xs: '10px 0px',
            sm: '18px 0px',
        },
    },
    toggle: {
        width: '100%',
        backgroundColor: '#E9E9E9',
    },
    main: {
        display: 'flex',
        flexDirection: 'column',
        marginBottom: {
            md: theme.spacing(0),
        },
    },
    modalContent: {
        height: 'auto',
        padding: {
            xs: '0px 16px 8px',
            sm: '8px 24px',
        },
    },
    modalTitle: {
        paddingRight: {
            xs: '40px',
            sm: '60px',
        },
    },
    modalTitleContainer: {
        padding: {
            xs: '10px 16px',
            sm: '10px 24px',
        },
        position: 'relative',
        marign: '0px',
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1.6),
        top: theme.spacing(0.5),
        color: theme.palette.grey[500],
        cursor: 'pointer',
    },
    addressSectionRoot: {
        overflowX: 'hidden',
    },
    chooseAddressLabel: {
        margin: '5px 0px',
    },
    addressContainer: {
        overflowX: 'auto',
        display: 'flex',
        flexDirection: 'row',
        marginRight: {
            xs: '-16px',
            sm: '-20px',
        },
    },
    addressLayoutWrapper: {
        display: 'inline-block',
        WebkitTapHighlightColor: 'transparent',
        minWidth: {
            xs: '80%',
            sm: '321px',
        },
        marginRight: {
            xs: '5px',
            sm: '10px',
        },
    },
    addAddressSection: {
        display: 'inline-flex',
        alignItems: 'center',
        cursor: 'pointer',
    },
    addAddressLabel: {
        paddingLeft: '10px',
    },
    selectedAddressIconStyle: {
        float: 'right',
    },
    selectedAddressStyle: {
        border: `1px solid ${theme.palette.action.hover}`,
    },
    nonSelectedAddressStyle: {
        border: '1px solid #cccccc',
    },
    addressLayout: {
        position: 'relative',
        cursor: 'pointer',
        borderRadius: '4px',
        margin: '6px 6px 6px 0px',
        height: 'calc(100% - 12px)',
        '&:hover': {
            border: `1px solid ${theme.palette.action.hover}`,
        },
        padding: {
            xs: '12px 8px 6px 16px',
            sm: '20px 10px 10px 20px',
        },
    },

    userName: {
        marginTop: '1.5px',
        lineHeight: '19px',
    },
    addressContent: {
        width: '100%',
        paddingTop: '3px',
        whiteSpace: 'break-spaces',
        wordWrap: 'break-word',
        display: 'inline-flex',
    },
    addressTextStyle: {
        paddingBottom: '5px',
        marginRight: '40px',
    },
    addressEditSection: {
        position: 'absolute',
        bottom: '4px',
        right: '10px',
        marginLeft: {
            xs: '5px',
            sm: '20px',
        },
    },
    addressEditIconLayoutStyle: {
        cursor: 'pointer',
        padding: '5px',
        WebkitTapHighlightColor: 'transparent',
    },
    addressEditIconStyle: {
        width: {
            xs: '20px',
            sm: '24px',
        },
        height: {
            xs: '20px',
            sm: '24px',
        },
    },
    modalPaper: {
        borderRadius: '4px',
        margin: '24px',
        width: {
            xs: '80%',
            sm: '504px',
        },
        minWidth: {
            xs: '290px',
            sm: 'inherit',
        },
    },
    checkbox: {
        color: theme.palette.divider,
        '&.MuiFormControlLabel-root': {
            marginRight: '0px !important',
        },
        '&.Mui-checked': {
            color: theme.palette.action.hover,
        },
        justifyContent: 'end',
    },
    rootCheckboxStyle: {
        display: 'flex',
        justifyContent: 'left',
        flexDirection: 'row',
        alignItems: 'center',
        marginLeft: {
            xs: theme.spacing(-1.1),
            sm: 'inherit',
        },
    },
    checkboxParentStyle: {
        color: '#CCCCCC',
        paddingLeft: '0px',
        paddingRight: '0px',
        '&.MuiFormControlLabel-root': {
            marginRight: '0px !important',
        },
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
    },
    addressCheckBoxTextStyle: {
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
    },
});
interface Props {
    handleClose: (address: Address | null, goToAddAddress?: boolean, useSame?: boolean) => void;
    open: boolean;
    addressesList: Address[];
    flow: AddressType;
    isFirstTimeCapture: boolean;
    hideCloseButton?: boolean;
}
export function ChooseAddressModal(props: Props) {
    const userAddressList = props.addressesList;
    const cssObject: SXCssObject = createCssObject(useTheme());
    const theme = useTheme<ThemeWithAdditionalData>();
    const shippingAddressLabel = theme.content.label.shippingAddressLabel;
    const { flow } = props;
    const [selectedAddressIndex, setSelectedAddressIndex] = React.useState(-1);
    let mounted: any = useRef();
    const [isChecked, setIsChecked] = React.useState(false);
    const handleCheckboxChange = () => {
        setIsChecked(!isChecked);
    };
    const cart: Cart = useSelector(selectCart);
    const { status } = cart;
    const proceedToCart =
        !status || status === CartStatus.READY_TO_SCHEDULE || status === CartStatus.EQ_READY_FOR_PAYMENT;
    React.useEffect(() => {
        if (!mounted.current) {
            // do componentDidMount logic
            mounted.current = true;
        } else {
            // do componentDidUpdate logic
        }
    });

    const handleAddressClick = (choosenIndex: number) => {
        if (selectedAddressIndex === choosenIndex) {
            choosenIndex = -1;
        }
        setSelectedAddressIndex(choosenIndex);
    };

    const handleAddNewAddress = () => {
        invokeGACartPageAddNewHomeAddressDialog();
        props.handleClose(null, true, isChecked);
    };

    const handleAddressEdit = (e: React.MouseEvent<HTMLElement, MouseEvent>, addressItem: Address) => {
        e.stopPropagation();
        props.handleClose(addressItem, true, isChecked);
    };

    const handleSubmit = () => {
        handleGAEvent();
        props.handleClose(userAddressList[selectedAddressIndex], undefined, isChecked);
    };

    const handleGAEvent = () => {
        //RecommendationPageExistingAddressOptionSelected
        if (props.isFirstTimeCapture) {
            if (props.flow === AddressType.Home) {
                invokeGAHomeAddressCatpure();
            } else if (props.flow === AddressType.Billing) {
                invokeGABillingAddressCatpure();
            }
        } else {
            if (props.flow === AddressType.Home) {
                invokeGAHomeAddressUpdate();
            } else if (props.flow === AddressType.Billing) {
                invokeGABillingAddressUpdate();
            }
        }
    };

    const shouldBlockEdit = (currentAddressId: string | undefined) => {
        if (currentAddressId) {
            if (
                props.flow &&
                props.flow === AddressType.Home &&
                cart.billingAddress &&
                currentAddressId === cart.billingAddress.customerAddressId
            ) {
                return true;
            } else if (
                props.flow &&
                props.flow === AddressType.Billing &&
                cart.shippingAddress &&
                currentAddressId === cart.shippingAddress.customerAddressId
            ) {
                return true;
            }
        }
    };

    const DialogTitle = (dialogTitleProps: any) => {
        const { onClose } = dialogTitleProps;
        const addressLabel = props.flow !== AddressType.Home ? props.flow : shippingAddressLabel;
        return (
            <MuiDialogTitle sx={cssObject.modalTitleContainer}>
                <Box sx={cssObject.modalTitle}>
                    <Heading label={`Add / Select ${addressLabel} Address`} isPopUp />
                </Box>

                {!props.hideCloseButton && (
                    <Box aria-label="close" sx={cssObject.closeButton} onClick={onClose}>
                        <Close />
                    </Box>
                )}
            </MuiDialogTitle>
        );
    };
    return (
        <Dialog
            onClose={() => {}}
            open={props.open}
            maxWidth={'sm'}
            disableEscapeKeyDown={true}
            aria-describedby="alert-dialog-slide-description"
            PaperProps={{
                sx: cssObject.modalPaper,
            }}
        >
            <Box sx={cssObject.root}>
                <Box sx={cssObject.main}>
                    <DialogTitle
                        id="customized-dialog-title"
                        onClose={() => {
                            props.handleClose(null, false, isChecked);
                        }}
                    ></DialogTitle>

                    <DialogContent sx={cssObject.modalContent}>
                        <ContentContainer disableGrid>
                            <Box sx={cssObject.chooseAddressLabel}>
                                <Paragraph semibold headingColor size={'normal'}>
                                    Select existing address
                                </Paragraph>
                            </Box>
                        </ContentContainer>
                        <ContentContainer disableGrid sx={cssObject.addressContainer}>
                            {userAddressList &&
                                userAddressList.length > 0 &&
                                userAddressList.map((addressItem, index) => {
                                    const isShippingAndBillingAddressSame = shouldBlockEdit(addressItem.id);

                                    return (
                                        <Box
                                            key={index}
                                            sx={cssObject.addressLayoutWrapper}
                                            onClick={() => {
                                                handleAddressClick(index);
                                            }}
                                        >
                                            {selectedAddressIndex === index && (
                                                <Box component={'span'} sx={cssObject.selectedAddressIconStyle}>
                                                    {' '}
                                                    <SelectedAddress />{' '}
                                                </Box>
                                            )}
                                            <Box
                                                sx={[
                                                    cssObject.addressLayout,
                                                    selectedAddressIndex === index && cssObject.selectedAddressStyle,
                                                    selectedAddressIndex !== index && cssObject.nonSelectedAddressStyle,
                                                ]}
                                            >
                                                <Paragraph semibold headingColor>
                                                    {addressItem.addressName}
                                                </Paragraph>
                                                <Box sx={cssObject.userName}>
                                                    <Paragraph>
                                                        {addressItem.firstname + ' ' + addressItem.lastname}
                                                    </Paragraph>
                                                </Box>
                                                <ContentContainer disableGrid sx={cssObject.addressContent}>
                                                    <ContentContainer disableGrid sx={cssObject.addressTextStyle}>
                                                        <Paragraph size={'small'}>
                                                            {addressItem.address1 +
                                                                ', ' +
                                                                (addressItem.address2
                                                                    ? addressItem.address2 + ', '
                                                                    : '')}
                                                            <br />
                                                            {addressItem.city +
                                                                ', ' +
                                                                addressItem.region +
                                                                ', ' +
                                                                addressItem.postcode}
                                                        </Paragraph>
                                                    </ContentContainer>
                                                </ContentContainer>
                                                <Box sx={cssObject.addressEditSection}>
                                                    {!isShippingAndBillingAddressSame && (
                                                        <Box
                                                            sx={cssObject.addressEditIconLayoutStyle}
                                                            onClick={(e) => {
                                                                handleAddressEdit(e, addressItem);
                                                            }}
                                                        >
                                                            <EditIcon />
                                                        </Box>
                                                    )}
                                                </Box>
                                            </Box>
                                        </Box>
                                    );
                                })}
                        </ContentContainer>
                        <Grid
                            container
                            alignItems="center"
                            justifyContent="space-between"
                            spacing={1}
                            sx={{
                                padding: { xs: '15px 0px', sm: '13px 0px 25px' },
                            }}
                        >
                            <Grid item sx={cssObject.addAddressSection} onClick={handleAddNewAddress}>
                                <AddAddressIcon />
                                <Box sx={cssObject.addAddressLabel}>
                                    <Paragraph semibold semiLargeFont headingColor>
                                        ADD NEW ADDRESS
                                    </Paragraph>
                                </Box>
                            </Grid>
                            {proceedToCart && (
                                <Grid item>
                                    <Box sx={cssObject.rootCheckboxStyle}>
                                        <Box sx={cssObject.checkboxParentStyle}>
                                            <Checkbox
                                                id={'address-checkbox'}
                                                name={'conditions'}
                                                sx={cssObject.checkbox}
                                                checked={isChecked}
                                                onChange={handleCheckboxChange}
                                            />
                                        </Box>
                                        <Box>
                                            <Box component={'span'} sx={cssObject.addressCheckBoxTextStyle}>
                                                <Paragraph headingColor fontSizeVerySmall>
                                                    Same as{' '}
                                                    {flow === AddressType.Home ? 'Billing' : shippingAddressLabel}{' '}
                                                    Address
                                                </Paragraph>
                                            </Box>
                                        </Box>
                                    </Box>
                                </Grid>
                            )}
                        </Grid>
                        <Button
                            isFullWidth={true}
                            color="primary"
                            disabled={selectedAddressIndex === -1}
                            onClick={handleSubmit}
                        >
                            {props.isFirstTimeCapture ? 'Add to Cart' : 'Update Cart'}
                        </Button>
                    </DialogContent>
                </Box>
            </Box>
        </Dialog>
    );
}
