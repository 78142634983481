import React from "react";
import { Dialog, Theme, useTheme, DialogContent, Box } from "@mui/material";
import { SXCssObject } from "models/ThemeImages";
import ContentContainer from 'components/ContentContainer';
import { Button } from 'components/Button';
import { Heading } from 'components/Heading';
import ThumbUpIcon from "components/icons/ThumbUpIcon";
import { Paragraph } from "components/Paragraph";
import { Formik, FormikValues } from "formik";
import FormikTextField from "components/FormikTextField";
import { Close } from "@mui/icons-material";
import MuiDialogTitle from '@mui/material/DialogTitle';
import { contractorModalSchema } from "pages/wizard/wizard-schema";

interface Props {
    open: true;
    handleClose: () => void;
    handleSubmit: (contractorInfo: string) => void;
}

const createCssObject = (theme: Theme): SXCssObject => ({
    root: {
        padding: {
            xs: '10px 0px',
            sm: '18px 0px',
        },
    },
    textFieldContainerStyle: {
        mt: '12px',
        mb: '20px',
        padding: '12px',
        borderRadius: '4px',
        backgroundColor: theme.palette.background.default
    },
    buttonSection: {
        display: 'flex',
        justifyContent: 'center',
        py: '12px',
    },
    modalContent: {
        display: 'flex',
        justifyContent: 'center',
        height: 'auto',
        padding: {
            xs: '8px 12px',
            sm: '24px 20px',
        },
    },
    modalTitleContainer: {
        padding: '10px 24px 32px',
        position: 'relative',
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1.6),
        top: theme.spacing(1.6),
        color: theme.palette.grey[500],
        cursor: 'pointer',
        width: '36px',
        height: '36px',
        p: '4px'
    },
    alertIconStyle: {
        marginBottom: '24px',
        display: 'flex',
        justifyContent: 'center',
    },
    modalPaper: {
        borderRadius: '4px',
        width: {
            xs: '80%',
            sm: '504px',
        },
        minWidth: {
            xs: '290px',
            sm: 'inherit',
        },
    },
    fullWidth: {
        width: '100%',
    },
});

/**
 * This POPUP will only be Shown for EquipmentOnly Flow in Homevestors
 * @param props 
 * @returns 
 */

export default function CollectContractorInfoModal(props: Props) {
    const { open, handleClose, handleSubmit } = props;
    const theme = useTheme();
    const cssObject: SXCssObject = createCssObject(theme);
    const DialogTitle = (dialogProps: any) => {
        const { onClose } = dialogProps;
        return (
            <MuiDialogTitle sx={cssObject.modalTitleContainer}>
                <Box aria-label="close"  onClick={onClose}>
                    <Close sx={cssObject.closeButton} />
                </Box>
            </MuiDialogTitle>
        );
    };
    return (
        <Dialog
            onClose={() => {}}
            open={open}
            maxWidth={'sm'}
            disableEscapeKeyDown={true}
            aria-describedby="alert-dialog-collect-contractor-info"
            PaperProps={{
                sx: cssObject.modalPaper,
            }}
        >
            <DialogTitle id="collect-contractor-dialog-title" onClose={handleClose}></DialogTitle>
            <DialogContent sx={cssObject.modalContent}>
                <ContentContainer disableGrid sx={cssObject.fullWidth}>
                    <Box sx={cssObject.alertIconStyle}>
                        <ThumbUpIcon />
                    </Box>

                    <Heading align="center" label={'Thank You!'} isPopUp />
                    <Paragraph sx={{ mt: '16px', mb: '8px' }} align="center">
                        In order to avoid restocking fees, there are a few more technical questions that we need to ask.
                        Please provide contact information for the HVAC contractor who will installing your equipment.
                    </Paragraph>
                    <Formik
                        initialValues={{
                            contractorInfo: ''
                        }}
                        onSubmit={(values: FormikValues) => {
                            handleSubmit(values.contractorInfo);
                        }}
                        validateOnMount={true}
                        validationSchema={contractorModalSchema}
                    >
                        {({ submitForm, isValid }) => (
                            <>
                                <Box sx={cssObject.textFieldContainerStyle}>
                                    <FormikTextField
                                        id={`contractorInfo`}
                                        name={`contractorInfo`}
                                        type="text"
                                        multiLine={true}
                                        minRows={3}
                                        maxRows={12}
                                        placeholder="Contractor Name, Company, Email and Phone number"
                                    />
                                </Box>
                                <Box sx={cssObject.buttonSection}>
                                    <Button id={`Create Order`} isContrast onClick={submitForm} disabled={!isValid}>
                                        Create Order
                                    </Button>
                                </Box>
                            </>
                        )}
                    </Formik>
                </ContentContainer>
            </DialogContent>
        </Dialog>
    );
}