import React, { MouseEventHandler } from 'react';
import { Theme, useTheme } from '@mui/material/styles';
import { Button } from './Button';
import { Box, ClickAwayListener, Grid, Grow, MenuItem, MenuList, Paper, Popper } from '@mui/material';
import { useSelector } from 'react-redux';
import { Cart, ItemType } from 'models/cart';
import { selectCart, selectAddons } from 'store/selectors/cart';
import LoginCart from './icons/LoginCart';
import LoginProfileIcon from './icons/LoginProfileIcon';

import { Paragraph } from './Paragraph';
import ContentContainer from './ContentContainer';
import { getOrderStatus, showPayNow } from 'pages/order/order-utils';
import { OrderStatus } from 'models/order';
import { LoginMenuLabels, LoginMenu, LoginMenusList } from './Login/LoginMenus';
import { NavigationButton } from './NavigationButton';
import { SXCssObject } from 'models/ThemeImages';

const createCssObject = (theme: Theme): SXCssObject => ({
    profileRoot: {
        position: 'relative',
    },
    paperStyle: {
        backgroundColor: 'white',
        '&.MuiPaper-rounded': {
            borderRadius: '4px',
        },
        display: 'flex',
        justify: 'center',
        boxShadow: '0px 5px 31px #0000004F',
    },
    menuItemStyle: {
        paddingLeft: '10px',
        paddingRight: '10px',
        minWidth: '175px',
        borderRadius: '6px',
        margin: {
            xs: '5px',
            sm: '10px',
        },
        '&.MuiMenuItem-root:hover': {
            background: '#eeeeee 0% 0% no-repeat padding-box',
        },
    },
    menuItemIconStyle: {
        paddingRight: '10px',
        display: 'inline-flex',
        justifyContent: 'center',
    },
    profileIconStyle: {
        cursor: 'pointer',
        padding: '8px',
    },
    loginSectionStyle: {
        cursor: 'pointer',
        padding: '6px 8px',
        width: '40px',
        height: '40px',
        display: 'flex',
        alignItems: 'center',
        borderRadius: '50%',
    },
    whiteBackground: {
        backgroundColor: 'white',
    },
    greyBackground: {
        backgroundColor: '#F5F5F5',
    },
    cartSectionStyle: {
        width: '70px',
        cursor: 'pointer',
        height: '40px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: '20px',
        '&:hover': {
            backgroundColor: '#E1E1E1',
        },
    },
    cartSectionBlurStyle: {
        backgroundColor: 'white',
        opacity: '0.2',
        borderRadius: '20px',
        padding: '0px 12px',
        boxSizing: 'content-box',
        width: '40px',
        height: '40px',
        display: 'flex',
        alignItems: 'center',
    },
    cartItems: {
        fontSize: '0.875rem',
        paddingLeft: '5px',
    },
    tooltip: {
        position: 'relative',
        marginTop: '5px',
        left: '90%',
        '&::before': {
            backgroundColor: 'white',
            content: '""',
            display: 'block',
            position: 'absolute',
            width: 12,
            height: 12,
            top: -10,
            transform: 'rotate(45deg)',
            left: 'calc(50% - 6px)',
        },
    },
    orderStatusContainer: {
        display: 'flex',
    },
    cartStatusLabel: {
        justifyContent: {
            sm: 'flex-start',
        },
        display: 'flex',
    },
    cartStatusContent: {
        display: 'flex',
        alignItems: 'center',
    },
    button: {
        marginTop: '20px',
    },
    orderContainer: {
        border: '1px solid #E3E1E1',
        borderRadius: '4px',
        padding: '15px 10px',
        marginTop: '10px',
    },
    menuItemStyleLatestOrder: {
        cursor: 'default',
        '&.MuiMenuItem-root:hover': {
            background: 'none',
        },
    },
});

interface LoginBtnProps {
    id?: string;
    color?: 'primary' | 'secondary';
    onClick?: MouseEventHandler;
    sx?: string;
    isLargerScreen?: boolean;
    isTransparent?: boolean;
    isContrast?: boolean;
}

interface CartSectionProps {
    id?: string;
    onClick?: MouseEventHandler;
    sx?: string;
    color?: 'primary' | 'secondary';
    shouldBlur?: boolean; //set opacity to 0.2 when this flag is true
}
interface UserProfileProps {
    id?: string;
    onMenuClick: (menuLabel: string) => void;
    orderID?: string;
    orderStatus?: OrderStatus;
    color?: 'primary' | 'secondary';
    multipleOpenProjects?: boolean;
}
export function LoggedInSection(props: UserProfileProps) {
    const classes: SXCssObject = createCssObject(useTheme());
    const { orderID, orderStatus, onMenuClick, color = 'primary', multipleOpenProjects } = props;
    const [isProfileOptionsOpen, setProfileOptionsVisibility] = React.useState(false);
    const anchorRef = React.useRef<HTMLButtonElement>(null);
    const popperStyle: any = {
        position: 'absolute',
        top: 'auto',
        left: 'auto',
        right: '0px',
        paddingTop: '3px',
        zIndex: 3,
    };

    const handleProfileIconClick = () => {
        setProfileOptionsVisibility(!isProfileOptionsOpen);
    };
    const LatestPendingOrder = () => {
        return (
            <Grid>
                <Paragraph size={'normal'} bold>
                    My Order
                </Paragraph>
                <Grid sx={classes.orderContainer}>
                    <Grid container sx={classes.orderStatusContainer} justifyContent={'space-between'}>
                        <Grid item>
                            <Grid sx={classes.cartStatusLabel}>
                                <Paragraph fontSizeVerySmall>Order Number</Paragraph>
                            </Grid>
                            <ContentContainer disableGrid sx={classes.cartStatusContent}>
                                <Paragraph size={'normal'} semibold headingColor>
                                    {orderID}
                                </Paragraph>
                            </ContentContainer>
                        </Grid>
                        <Grid item>
                            <Grid sx={classes.cartStatusLabel}>
                                <Paragraph fontSizeVerySmall>Order Status</Paragraph>
                            </Grid>
                            <ContentContainer disableGrid sx={classes.cartStatusContent}>
                                <Paragraph size={'normal'} semibold headingColor>
                                    {orderStatus && getOrderStatus(orderStatus)}{' '}
                                </Paragraph>
                            </ContentContainer>
                        </Grid>
                    </Grid>
                    <Grid sx={classes.button} item container justifyContent={'center'}>
                        <Button
                            id={`Procced-to-Pay`}
                            isContrast
                            color="primary"
                            onClick={() => {
                                onMenuClick(LoginMenuLabels.MENU_PAY_NOW);
                            }}
                        >
                            PAY PENDING AMOUNT
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
        );
    };
    return (
        <Box sx={classes.profileRoot}>
            <Box ref={anchorRef}>
                <NavigationButton
                    id={'logged-in-menu'}
                    onClick={handleProfileIconClick}
                    color={color}
                    icon={<LoginProfileIcon />}
                />
            </Box>
            <Popper
                open={isProfileOptionsOpen}
                style={{ ...popperStyle }}
                anchorEl={anchorRef.current}
                placement={'bottom-end'}
                transition
                disablePortal
            >
                {({ TransitionProps, placement }) => (
                    <Grow {...TransitionProps} style={{ transformOrigin: 'center top' }}>
                        <Paper sx={classes.paperStyle}>
                            <Box sx={classes.tooltip} />
                            <ClickAwayListener
                                onClickAway={() => {
                                    setProfileOptionsVisibility(false);
                                }}
                            >
                                <MenuList id="menu-list-grow">
                                    {!multipleOpenProjects && orderStatus && showPayNow(orderStatus) && (
                                        <MenuItem key={'my_latest_order'} sx={classes.menuItemStyleLatestOrder}>
                                            <LatestPendingOrder />
                                        </MenuItem>
                                    )}
                                    {LoginMenusList.map((menuItem: LoginMenu, index) => (
                                        <MenuItem
                                            key={`loggedin_menu${index}`}
                                            sx={classes.menuItemStyle}
                                            onClick={() => onMenuClick(menuItem.label)}
                                        >
                                            <Box sx={classes.menuItemIconStyle}>
                                                <menuItem.icon />
                                            </Box>
                                            {menuItem.label}
                                        </MenuItem>
                                    ))}
                                </MenuList>
                            </ClickAwayListener>
                        </Paper>
                    </Grow>
                )}
            </Popper>
        </Box>
    );
}

export function LoginAndSignUpSection(props: LoginBtnProps) {
    const { isTransparent = false } = props;
    return (
        <Button isTransparent={isTransparent} isContrast color={'primary'} onClick={props.onClick}>
            LOGIN / SIGN UP
        </Button>
    );
}

export function HomePageCartSection(props: CartSectionProps) {
    const classes: SXCssObject = createCssObject(useTheme());
    const cart: Cart = useSelector(selectCart);
    const { items } = cart;
    const { color = 'primary' } = props;
    const itemMain = items?.filter((cartItem) => cartItem.itemType === ItemType.MainSystem);
    const itemIAQ = items?.filter((cartItem) => cartItem.itemType === ItemType.CrossSell);
    let cartItemNumber = (itemMain ? itemMain.length : 0) + (itemIAQ ? itemIAQ.length : 0);
    const addOnList = useSelector(selectAddons);
    addOnList?.map((addOn) => (cartItemNumber += addOn.quantity));

    return (
        <Box
            sx={[
                classes.cartSectionStyle,
                color === 'secondary' && classes.whiteBackground,
                color === 'primary' && classes.greyBackground,
            ]}
            onClick={props.onClick}
        >
            <LoginCart />
            {cart.items && cart.items?.length > 0 && (
                <Grid sx={classes.cartItems}>{'0' + cartItemNumber ? cartItemNumber : ''}</Grid>
            )}
        </Box>
    );
}
