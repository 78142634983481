import React, { PropsWithChildren } from 'react';
import { Theme, useTheme } from '@mui/material/styles';
import FormikWizardInputs from './FormikWizardInputs';
import { Options } from './WizardInputs';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/FormLabel';
import Avatar from '@mui/material/Avatar';
import { Box, Icon } from '@mui/material';
import { Grid } from '@mui/material';
import { SXCssObject } from 'models/ThemeImages';

const createCssObject = (theme: Theme, props: Props): SXCssObject => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
        width: `calc(100% + ${theme.spacing(2)}px)`,
    },
    image: {
        padding: theme.spacing(1),
        display: {
            xs: 'flex',
            sm: 'inherit',
        },
        justifyContent: {
            xs: 'center',
            sm: 'inherit',
        },
        flex: {
            xs: `0 0 100%`,
            sm: `0 0 100px`,
        },
        width: {
            xs: '100%',
            sm: 100 + theme.spacing(2),
        },
    },
    formControl: {
        padding: theme.spacing(1),
        flex: {
            xs: `0 0 100%`,
            sm: 1,
        },
        width: {
            xs: '100%',
            sm: 'inherit',
        },
    },
    img: {
        width: '100px',
        height: '100px',
    },
    wizardQuestionCard: {
        width: '100%',
        boxShadow: props.isBold ? '0px 5px 15px #5B5B5B17' : '0px 0px 0px',
        borderRadius: '4px',
    },
    label: {
        marginBottom: theme.spacing(2),
        color: theme.palette.text.primary,
        lineHeight: theme.spacing(0.2),
        fontWeight: props.isBold ? 500 : 'normal',
    },
    label_1: {
        fontSize: '0.875rem',
        marginBottom: theme.spacing(1),
        color: theme.palette.text.primary,
        fontWeight: props.isBold ? 500 : 'normal',
    },
});

interface Props {
    id: string;
    name: string;
    label: string;
    pic?: string;
    options: Options[];
    isBold?: boolean;
    isBoxShadow?: () => void;
}

export default function WizardSelectionSchedule(props: Props) {
    const cssObject: SXCssObject = createCssObject(useTheme(), props);

    const { id, label, pic, ...other } = props;
    return (
        <Grid sx={cssObject.wizardQuestionCard}>
            <FormControl fullWidth>
                {pic ? (
                    <InputLabel id={`${id}-label`} htmlFor={id} sx={cssObject.label}>
                        <Grid container>
                            <Grid item>
                                <Icon style={{ color: pic, fontSize: '14px', padding: '1.4px 0 0 0' }}>
                                    radio_button_unchecked
                                </Icon>{' '}
                            </Grid>
                            <Grid item xs={11}>
                                {label}
                            </Grid>
                        </Grid>
                    </InputLabel>
                ) : (
                    <InputLabel id={`${id}-label`} htmlFor={id} sx={cssObject.label_1}>
                        {label}
                    </InputLabel>
                )}
                <FormikWizardInputs isForSchedulePage={true} id={id} {...other} />
            </FormControl>
        </Grid>
    );
}

interface WizardFormProps {
    pic: string;
}

export function WizardForm(props: PropsWithChildren<WizardFormProps> & Props) {
    const cssObject: SXCssObject = createCssObject(useTheme(), props);
    const { pic, children } = props;
    return (
        <Box sx={cssObject.root}>
            <Box sx={cssObject.image}>
                <Avatar src={pic} sx={cssObject.img} />
            </Box>
            <Box sx={cssObject.formControl}>{children}</Box>
        </Box>
    );
}
