import * as React from 'react';
import { useTheme } from '@mui/material/styles';
import { getThemePrimaryMain } from 'components/common/Utilities';

export default function LogoutIcon(props: any) {
    const theme = useTheme();
    const themePrimaryMain = getThemePrimaryMain(theme);
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} {...props}>
            <g data-name="Group 62" fill="none" stroke={`${themePrimaryMain}`} strokeLinecap="round" strokeWidth={2}>
                <path data-name="Path 23" d="M11.134 5.184H5.929v13.632h5.205" strokeLinejoin="round" />
                <path data-name="Path 24" d="M15.847 9.34l3.018 2.681-3.018 2.639" strokeLinejoin="round" />
                <path data-name="Line 3" d="M16.98 12h-6.286" />
            </g>
        </svg>
    );
}
