import { useTheme } from '@mui/material/styles';
import { getThemePrimaryMain } from 'components/common/Utilities';
import * as React from 'react';

const MissionIconSmall = (props: any) => {
    const theme = useTheme();
    const themePrimaryMain = getThemePrimaryMain(theme);
    return (
        <svg
            data-name="Component 84 \u2013 4"
            xmlns="http://www.w3.org/2000/svg"
            width={'100%'}
            height={'100%'}
            viewBox="0 0 256 128"
            {...props}
        >
            <rect data-name="Rectangle 3827" width={256} height={128} rx={7} fill={`${themePrimaryMain}`} />
            <g data-name="Group 3425">
                <g data-name="Group 3424">
                    <g data-name="Group 3423" fill="none" stroke="#fff" strokeLinecap="round" strokeWidth={4}>
                        <path data-name="Path 268" d="M158.445 58.23a34.81 34.81 0 1 1-24.892-24.462" />
                        <path data-name="Path 269" d="M147.178 60.52a23.376 23.376 0 1 1-13.9-14.833" />
                        <path
                            data-name="Path 270"
                            d="M136.094 66.538q.042.483.042.978a11.289 11.289 0 1 1-11.288-11.289 11.411 11.411 0 0 1 1.669.122"
                        />
                        <path
                            data-name="Path 272"
                            d="m124.392 67.516 17-16.606 16.524-16.149a1.561 1.561 0 0 0 .47-1.117v-7.1a1.561 1.561 0 0 0-2.641-1.127L142.16 38.435a1.562 1.562 0 0 0-.48 1.082l-.291 10.231"
                        />
                        <path
                            data-name="Path 273"
                            d="m158.387 34.304 6.391 1.4a2.17 2.17 0 0 1 1 3.717l-12.3 11.3a2.17 2.17 0 0 1-1.545.57l-10.546-.377"
                        />
                    </g>
                </g>
            </g>
        </svg>
    );
};
export default MissionIconSmall;
