import React, { Fragment } from 'react';
import { Props as MenuProps } from 'components/SecondaryNavigation';
import { Theme, useTheme } from '@mui/material/styles';
import SubPage from 'components/subpage/SubPage';
import Grid from '@mui/material/Grid';
import { Heading } from 'components/Heading';
import { Paragraph } from 'components/Paragraph';
import { getSubPageMenus } from './menu-util';
import VideoOverlay from '../../components/VideoOverlay';
import VideoOverlayBackground from 'assets/images/video_overlay_bg.png';
import PlayButton from 'components/icons/PlayButton';
import { SXCssObject } from 'models/ThemeImages';

function OurProcess() {
    const createCssObject = (theme: Theme): SXCssObject => {
        return {
            contentHeading: {
                paddingBottom: theme.spacing(4),
            },
            contentInteractive: {
                alignItems: 'center',
                justifyContent: 'center',
                backgroundImage: `url(${VideoOverlayBackground})`,
                marginBottom: theme.spacing(1),
                height: {
                    xs: theme.spacing(20),
                    sm: theme.spacing(34),
                },
                backgroundPosition: 'center',
                backgroundSize: 'contain',
                backgroundRepeat: 'no-repeat',
            },
            contentSecondaryHeading: {
                paddingTop: {
                    xs: theme.spacing(2),
                    sm: theme.spacing(4),
                },
                paddingBottom: theme.spacing(2),
            },
            content: {
                paddingTop: theme.spacing(3),
            },
            playButton: {
                cursor: 'pointer',
                '&:hover': {
                    opacity: 0.9,
                },
                width: {
                    xs: '80px',
                    sm: 'inherit',
                },
                paddingTop: {
                    xs: theme.spacing(2),
                    sm: theme.spacing(3),
                },
            },
        };
    };

    const menu: MenuProps = {
        id: 'menu',
        label: 'More Knowledge Center Topic',
        color: 'primary',
        subMenus: getSubPageMenus('our_process'),
    };
    const cssObject: SXCssObject = createCssObject(useTheme());
    const [openVdo, setOpenVdo] = React.useState(false);
    const handleCloseVdo = () => {
        setOpenVdo(false);
    };
    return (
        <SubPage
            menu={menu}
            content1={
                <Fragment>
                    <Grid item xs={12} sx={cssObject.contentHeading}>
                        <Heading variant={'h3'} isPopUp={true} color={'primary'} label={'Our Process'} />
                    </Grid>
                    <Paragraph responsiveFontSize20 bold headingStyle>
                        Watch our brief video
                    </Paragraph>
                    <Grid item xs={12} container sx={cssObject.contentInteractive}>
                        <div>
                            <PlayButton
                                onClick={() => {
                                    setOpenVdo(true);
                                }}
                            />
                        </div>
                    </Grid>
                    <Grid item xs={12}>
                        <Paragraph responsiveFontSize16 align={'left'}>
                            We have simplified the entire homeowner experience with you in mind.
                        </Paragraph>
                    </Grid>
                    <Grid item xs={12} sx={cssObject.content}>
                        <Paragraph responsiveFontSize16 align={'left'}>
                            You have on-demand access to total installed pricing and scheduling by using our online
                            equipment selection process to identify options that will best fit your home.
                        </Paragraph>
                    </Grid>
                    <Grid item xs={12} sx={cssObject.content}>
                        <Paragraph responsiveFontSize16 align={'left'}>
                            Based on your needs, we will present several equipment options so you can select the one
                            that's right for your budget and preferences.
                        </Paragraph>
                    </Grid>
                    <Grid item xs={12} sx={cssObject.content}>
                        <Paragraph responsiveFontSize16 align={'left'}>
                            We never want surprises on installation day, so a technician will meet you at your home on a
                            day and time convenient to you, to conduct a Pre-Installation Inspection and ensure that the
                            equipment you selected is a great fit.
                        </Paragraph>
                    </Grid>
                    <Grid item xs={12} sx={cssObject.content}>
                        <Paragraph responsiveFontSize16 align={'left'}>
                            If any modification from your original selection is needed, a member of our team will reach
                            out to go over those changes and request approval before any work is started.
                        </Paragraph>
                    </Grid>
                    <Grid item xs={12} sx={cssObject.content}>
                        <Paragraph responsiveFontSize16 align={'left'}>
                            Once your equipment is confirmed, our customer service team will coordinate an installation
                            date that works for you.
                        </Paragraph>
                    </Grid>
                    <Grid item xs={12} sx={cssObject.content}>
                        <Paragraph responsiveFontSize16 align={'left'}>
                            And that's it! Just a few easy steps and you'll be enjoying your new Goodman system!
                        </Paragraph>
                    </Grid>
                    <VideoOverlay
                        open={openVdo}
                        onClose={handleCloseVdo}
                        heading={'Our Process'}
                        src={
                            'https://res.cloudinary.com/motili/video/upload/v1594975788/ecomm/video/AG_ITSEASY_FINAL_REV_1080.mp4'
                        }
                    />
                </Fragment>
            }
        />
    );
}

export default OurProcess;
