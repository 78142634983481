import { LoginValidationObject } from 'models/loginModel';
import * as Yup from 'yup';
import { validateDomain } from '../../components/Login/LoginUtils';
export enum EmailError {
    Invalid = 'Invalid email address',
    Required = 'Please enter your email',
    Domain = 'Please enter correct Domain email',
}

const nameFieldsYupObj = {
    firstname: Yup.string().required('Please enter your first name'),
    lastname: Yup.string().required('Please enter your last name'),
};
const emailFieldYupObj = {
    email: Yup.string().email('Invalid email address').required('Please enter your email'),
};
export const schemaEmailNameCapture = Yup.object({ ...nameFieldsYupObj, ...emailFieldYupObj });

export const loginEmailCapture = Yup.object(emailFieldYupObj);

export const checkDomainAndLoginSchema = (params: LoginValidationObject) => {
    const yupObject = emailFieldYupObj;
    yupObject.email = yupObject.email.test('EmailDomain-test', params.errorMsg, function validateEmailDomain(value) {
        return validateDomain(value as string, params);
    });
    return Yup.object(yupObject);
};
export const checkDomainAndSignUpSchema = (params: LoginValidationObject) => {
    const signUpYupObj = { ...nameFieldsYupObj, ...emailFieldYupObj };
    signUpYupObj.email = signUpYupObj.email.test(
        'EmailDomain-test',
        params.errorMsg,
        function validateEmailDomain(value) {
            return validateDomain(value as string, params);
        }
    );
    return Yup.object(signUpYupObj);
};
