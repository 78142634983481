import React from 'react';
import { Theme, useTheme } from '@mui/material/styles';
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import MenuItem from '@mui/material/MenuItem';
import FormHelperText from '@mui/material/FormHelperText';
import { SXCssObject } from 'models/ThemeImages';

const createCssObject = (theme: Theme): SXCssObject => ({
    formControl: {
        minWidth: 100,
    },
    label: {
        marginBottom: theme.spacing(1),
        color: theme.palette.text.primary,
        '&.Mui-focused': {
            color: theme.palette.text.primary,
        },
        '&.Mui-error': {
            color: theme.palette.error.main,
        },
    },
    select: {
        borderRadius: '8px',
        height: theme.spacing(4.8),
        '&.MuiOutlinedInput-root': {
            '&:hover fieldset': {
                borderColor: theme.palette.text.primary,
            },
            '& fieldset': {
                border: `1px solid ${theme.palette.divider}`,
            },
            '&.Mui-focused fieldset': {
                borderColor: theme.palette.text.primary,
                borderWidth: '1px',
            },
        },
        '& .MuiSelect-select:focus': {
            backgroundColor: 'inherit',
        },
    },
    menu: {
        '& .MuiPaper-rounded': {
            borderRadius: 1,
        },
        '& .MuiListItem-root.Mui-selected': {
            backgroundColor: theme.palette.action.selected,
        },
        '& .MuiListItem-root.Mui-selected:hover': {
            color: theme.palette.text.primary,
            backgroundColor: theme.palette.action.selected,
        },
        '& .MuiListItem-button:hover': {
            color: theme.palette.common.white,
            backgroundColor: theme.palette.action.hover,
        },
    },
    helperText: {
        color: theme.palette.text.primary,
    },
});

export default function SelectInput(props: Prop) {
    const classes = createCssObject(useTheme());
    const { id, label, value, options, helperText, error, enableErrorDisplay, onSelect, fullWidth, ...other } = props;
    const handleChange = (event: any) => {
        if (options?.length > 0) {
            const selectedItem = options.find((item: any) => item.id === event.target.value);
            if (selectedItem) {
                onSelect(selectedItem);
            }
        }
    };
    return (
        <FormControl
            variant={'outlined'}
            sx={classes.formControl}
            fullWidth={fullWidth}
            error={enableErrorDisplay && error}
        >
            {label && (
                <FormLabel id={`${id}-select-filled-label`} sx={classes.label}>
                    {label}
                </FormLabel>
            )}
            <Select
                id={`${id}-select-filled`}
                value={value?.id || ''}
                fullWidth={fullWidth}
                onChange={handleChange}
                sx={classes.select}
                MenuProps={{
                    sx: classes.menu,
                    id: `${id}-select-menu`,
                    MenuListProps: { id: `${id}-select-list` },
                    PaperProps: {
                        sx: {
                            '& .MuiMenuItem-root.Mui-selected': {
                                backgroundColor: `darkGrey !important`,
                            },
                            '& .MuiMenuItem-root:hover': {
                                backgroundColor: 'lightGrey',
                            },
                            '& .MuiMenuItem-root.Mui-selected:hover': {
                                backgroundColor: 'lightGrey',
                            },
                        },
                    },
                }}
                {...other}
            >
                {options?.length > 0 &&
                    options.map((item: any, index: number) => {
                        return (
                            <MenuItem key={index} value={item.id}>
                                {item.display}
                            </MenuItem>
                        );
                    })}
            </Select>
            {enableErrorDisplay && (
                <FormHelperText id={`${id}-helper`} sx={classes.helperText}>
                    {helperText}
                </FormHelperText>
            )}
        </FormControl>
    );
}

export interface Prop {
    id: string;
    options?: any;
    value?: any;
    label?: string;
    onSelect: (item: any) => void;
    error?: boolean;
    enableErrorDisplay?: boolean;
    helperText?: string;
    fullWidth?: boolean;
}
