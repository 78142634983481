import { combineReducers } from 'redux';
import serviceWorker from './serviceWorkerReducer';
import store from './storeReducer';
import productCriteria from './productCriteriaReducer';
import productSearch from './productSearchReducer';
import cart from './cartReducer';
import order from './orderReducer';
import country from './countryReducer';
import content from './contentReducer';
import selection from './selectionReducer';
import notification from './notificationReducer';
import myOrders from './myOrdersReducer';
import productAttributes from './productAttributesReducer';
import gaSelectedProduct from './gaEcommerceReducer';
import productReview from './productReviewReducer';
import addressData from './addressReducer';
import login from './loginReducer';
import quotes from './quoteReducer';
import { clearLoginData, logoutSuccess } from 'store/actions/loginActions';

const rootReducer = combineReducers({
    serviceWorker,
    store,
    login,
    country,
    productCriteria,
    productSearch,
    cart,
    order,
    content,
    productAttributes,
    productReview,
    gaSelectedProduct,
    selection,
    notification,
    myOrders,
    quotes,
    addressData,
});

export const appReducer = (state: any, action: any) => {
    if (action.type === logoutSuccess.type || action.type === clearLoginData.type) {
        const { serviceWorker, store, country, content, productReview, productAttributes } = state;
        state = { serviceWorker, store, country, content, productReview, productAttributes };
    }

    return rootReducer(state, action);
};

export type RootState = ReturnType<typeof appReducer>;
