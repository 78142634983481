import React, { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import Grid from '@mui/material/Grid';
import MenuDrawer from 'components/MenuDrawer';
import { AppRoute } from 'models/route';
import { Logo, WizardLogoNewWithLink } from './Logo';
import { selectLoginState } from 'store/selectors/loginSelector';
import { selectCart } from 'store/selectors/cart';
import { useDispatch, useSelector } from 'react-redux';
import { HomePageCartSection, LoggedInSection, LoginAndSignUpSection } from './LoginAndSignUpMenu';
import { useMediaQuery, useTheme } from '@mui/material';
import { performLogout } from 'store/actions/loginActions';
import LoginInHome from 'pages/user/LoginInHome';
import LoadingOverlay from './common/LoadingOverlay';
import { HomePageHeaderMenus } from './HomeMenus';
import { getOrder } from 'store/actions/orderActions';
import { selectOrder, selectOrderLoading } from 'store/selectors/order';
import { LoginMenuLabels } from './Login/LoginMenus';
import { isFromRoute } from './common/Utilities';
import { redirectToPage } from 'store/actions/cartActions';
import { showSnackbarError, showSnackbarSuccess } from './common/Snackbar/SnackbarHelper';
import { selectMultipleOpenProjects } from 'store/selectors/storeSelector';
import { invokeGAForPayNowonProfile } from './Analytics/GoogleAnalyticsTagging';
import Cookies from 'js-cookie';
import { IS_ALIVE_COOKIE } from './common/constants';
import CookieDisablePopup from './CookieDisablePopup';

interface Props {
    color?: 'primary' | 'secondary';
    transparent?: boolean;
    hideCart?: boolean;
}

export default function Navigator(props: Props) {
    const navigate = useNavigate();
    const { color = 'primary', hideCart = false } = props;
    let loginData = useSelector(selectLoginState);
    const latestOrderLoading = useSelector(selectOrderLoading);
    const multipleOpenProjects = useSelector(selectMultipleOpenProjects);
    const loginLoading = loginData.loading;
    let cartData = useSelector(selectCart);
    const order = useSelector(selectOrder);
    const isUserDetailAvailable = Cookies.get(IS_ALIVE_COOKIE) && loginData.email ? true : false;
    const [isLoginModalShown, setLoginModalVisibility] = React.useState(false);
    const dispatch = useDispatch();
    const [isCookieModalShown, setCookieModalVisibility] = React.useState(false);

    const handleLoginButtonClick = () => {
        setLoginModalVisibility(true);
    };
    const handleCartButtonClick = () => {
        if (cartData.cartId && cartData.items?.length !== 0) {
            navigate(AppRoute.CartPage);
        } else {
            showSnackbarError('Cart is Empty');
        }
    };

    const handleLogOutClick = () => {
        dispatch(performLogout());
    };
    const handleMyOrdersClick = () => {
        navigate(AppRoute.MyOrders);
    };
    const handlePropertyClick = () => {
        dispatch(redirectToPage(AppRoute.MyProperties));
    };
    const handleMyProfileClick = () => {
        navigate(AppRoute.MyProfile);
    };
    const handlePayNow = () => {
        order &&
            order.id &&
            !isFromRoute(AppRoute.Billing) &&
            dispatch(getOrder({ id: order.id, redirectToPayment: true }));
        invokeGAForPayNowonProfile();
    };

    const handleQuotesClick = () => {
        dispatch(redirectToPage(AppRoute.MyQuotes));
    };

    const onMenuClick = (label: string) => {
        switch (label) {
            case LoginMenuLabels.MENU_PAY_NOW:
                handlePayNow();
                return;
            case LoginMenuLabels.MENU_PROFILE:
                handleMyProfileClick();
                return;
            case LoginMenuLabels.MENU_ORDER:
                handleMyOrdersClick();
                return;
            case LoginMenuLabels.MENU_PROPERTIES:
                handlePropertyClick();
                return;
            case LoginMenuLabels.MENU_QUOTES:
                handleQuotesClick();
                return;
            case LoginMenuLabels.MENU_LOGOUT:
                handleLogOutClick();
                return;
        }
    };
    const theme = useTheme();
    const isXs = useMediaQuery(theme.breakpoints.only('xs'));
    const location: any = useLocation();
    const shouldRedirectAfterLogin = location.state && location.state.pageToRedirect;
    const isFromLogout = location.state && location.state.isFromLogout;
    useEffect(() => {
        if (shouldRedirectAfterLogin && !isFromLogout) {
            setLoginModalVisibility(true);
        }
        if (!navigator.cookieEnabled) {
            setCookieModalVisibility(true);
        }
    }, [isFromLogout, shouldRedirectAfterLogin]);
    useEffect(() => {
        if (isFromLogout) {
            setLoginModalVisibility(false);
            let newState = location.state;
            newState.isFromLogout = false;
            navigate({ ...location, newState }, { replace: true });
        }
    }, [isFromLogout, location, navigate]);
    return (
        <nav
            style={{
                width: '100%',
                height: '80px',
                display: 'flex',
                justifyContent: 'center',
            }}
        >
            <LoadingOverlay open={loginLoading || latestOrderLoading} />
            {isCookieModalShown && (
                <CookieDisablePopup isOpen={isCookieModalShown} handleClose={() => setCookieModalVisibility(false)} />
            )}
            {isLoginModalShown && !isCookieModalShown && (
                <LoginInHome
                    handleClose={(snackBarMsg?: string) => {
                        setLoginModalVisibility(false);
                        if (snackBarMsg) {
                            showSnackbarSuccess(snackBarMsg);
                        }
                        if (shouldRedirectAfterLogin) {
                            let state = { ...location.state };
                            delete state.pageToRedirect;
                            navigate({ ...location, state }, { replace: true });
                        }
                    }}
                    isOpen={isLoginModalShown}
                    loading={false}
                ></LoginInHome>
            )}
            <Grid container style={{ padding: 5 }}>
                {/* Hambuger Section */}
                <Grid item container spacing={1} alignItems={'center'} sx={{ display: { xs: 'flex', lg: 'none' } }}>
                    {!isXs && (
                        <Grid item sm={5} md={4}>
                            <WizardLogoNewWithLink
                                onClick={() => {
                                    navigate(AppRoute.Home);
                                }}
                                id={'nav-home'}
                                color={color}
                            />
                        </Grid>
                    )}
                    {isXs && (
                        <Grid item xs={2} sx={{ display: 'flex' }}>
                            <Logo
                                id={'nav-home'}
                                onClick={() => {
                                    navigate(AppRoute.Home);
                                }}
                            />
                        </Grid>
                    )}
                    <Grid item xs={10} sm={7} md={8}>
                        <Grid container spacing={isXs ? 2 : 3} alignItems={'center'} justifyContent={'flex-end'}>
                            <Grid item hidden={!isUserDetailAvailable || hideCart}>
                                <HomePageCartSection color={color} onClick={handleCartButtonClick} />
                            </Grid>

                            <Grid item hidden={!isUserDetailAvailable}>
                                <LoggedInSection
                                    id={'user-profile-section'}
                                    color={color}
                                    onMenuClick={onMenuClick}
                                    orderID={order.incrementId}
                                    orderStatus={order.status}
                                    multipleOpenProjects={multipleOpenProjects}
                                />
                            </Grid>

                            <Grid item>
                                <MenuDrawer
                                    color={color}
                                    onLogin={handleLoginButtonClick}
                                    isUserDetailAvailable={isUserDetailAvailable}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                {/* Header for larger viewports */}
                <Grid item container spacing={1} alignItems={'center'} sx={{ display: { xs: 'none', lg: 'flex' } }}>
                    <Grid item lg={3}>
                        <WizardLogoNewWithLink
                            id={'nav-home'}
                            onClick={() => {
                                navigate(AppRoute.Home);
                            }}
                        />
                    </Grid>
                    <Grid item container justifyContent={'flex-end'} lg={hideCart ? 8 : 7}>
                        <HomePageHeaderMenus />
                    </Grid>
                    <Grid item lg={hideCart ? 1 : 2}>
                        <Grid container spacing={4} justifyContent={'right'} alignItems={'flex-end'}>
                            {/* when not logged in */}
                            <Grid item hidden={isUserDetailAvailable}>
                                <LoginAndSignUpSection
                                    id={'login-section'}
                                    onClick={handleLoginButtonClick}
                                    isContrast={true}
                                />
                            </Grid>
                            {/* user is logged in */}
                            <Grid item hidden={!isUserDetailAvailable || hideCart}>
                                <HomePageCartSection color={color} onClick={handleCartButtonClick} />
                            </Grid>
                            <Grid item hidden={!isUserDetailAvailable}>
                                <LoggedInSection
                                    id={'user-profile-section'}
                                    color={color}
                                    onMenuClick={onMenuClick}
                                    orderID={order.incrementId}
                                    orderStatus={order.status}
                                    multipleOpenProjects={multipleOpenProjects}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </nav>
    );
}
