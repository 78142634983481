import { useTheme } from '@mui/material/styles';
import { getThemePrimaryMain } from 'components/common/Utilities';
import * as React from 'react';

function MyPropertiesIcon(props: any) {
    const theme = useTheme();
    const themePrimaryMain = getThemePrimaryMain(theme);
    return (
        <svg
            id="Component_30_2"
            data-name="Component 30 – 2"
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
        >
            <g id="Rectangle_74" data-name="Rectangle 74" fill="#fff" stroke="#707070" strokeWidth="1" opacity="0">
                <rect width="24" height="24" stroke="none" />
                <rect x="0.5" y="0.5" width="23" height="23" fill="none" />
            </g>
            <g id="Group_667" data-name="Group 667" transform="translate(-205.795 -5378.896)">
                <path
                    id="Union_23"
                    data-name="Union 23"
                    d="M3.95,16H1a1,1,0,0,1-1-1V1A1,1,0,0,1,1,0H5.5a1,1,0,0,1,1,1V3.974H11a2,2,0,0,1,2,2V14a2,2,0,0,1-2,2Z"
                    transform="translate(211.795 5383.896)"
                    fill="none"
                    stroke={`${themePrimaryMain}`}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                />
                <g
                    id="Rectangle_704"
                    data-name="Rectangle 704"
                    transform="translate(216.795 5392.896)"
                    fill="none"
                    stroke={`${themePrimaryMain}`}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                >
                    <rect width="3" height="6" stroke="none" />
                    <rect x="-1" y="-1" width="5" height="8" fill="none" />
                </g>
            </g>
        </svg>
    );
}

export default MyPropertiesIcon;
