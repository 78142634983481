import { AppRoute } from 'models/route';
import React from 'react';
import Home from '../pages/Home';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import ContactUs from 'pages/ContactUs';
import { useDispatch, useSelector } from 'react-redux';
import { selectLoginState } from 'store/selectors/loginSelector';
import Cookies from 'js-cookie';
import { IS_ALIVE_COOKIE } from 'components/common/constants';
import { PageViewRequest } from 'models/contact';
import { contactPageViewAction } from 'store/actions/hubspotActions';
import { clearLoginData } from 'store/actions/loginActions';
import { showSnackbarError } from 'components/common/Snackbar/SnackbarHelper';
import AirConditioners from 'pages/knowledge-center/AirConditioners';
import PackagedUnits from 'pages/knowledge-center/PackagedUnits';
import HeatPumps from 'pages/knowledge-center/HeatPumps';
import AirHandlers from 'pages/knowledge-center/AirHandlers';
import Furnaces from 'pages/knowledge-center/Furnaces';
import HowHVACWorks from 'pages/knowledge-center/HowHVACWorks';
import OurProcess from 'pages/knowledge-center/OurProcess';
import IndoorAirQuality from 'pages/knowledge-center/IndoorAirQuality';
import WhoWeAre from 'pages/WhoWeAre';
import SatisfactionPledge from 'pages/SatisfactionPledge';
import EnergyStar from 'pages/EnergyStar';
import MadeInUsa from 'pages/MadeInUsa';
import SecurityAndPrivacyPolicy from 'pages/SecurityAndPrivacyPolicy';
import TermsAndConditions from 'pages/TermsAndConditions';
import AboutYourHome from 'pages/wizard/AboutYourHome';
import EquipmentOptionContainer from 'pages/EquipmentListing/EquipmentOptionContainer';
import { MyProfile } from 'pages/profile/MyProfile';
import { MyProperties } from 'pages/Properties/MyProperties';
import { EditMyProfile } from 'pages/profile/EditMyProfile';
import EquipmentSelectionContainer from 'pages/EquipmentListing/EquipmentSelectionContainer';
import IAQOptionContainer from 'pages/EquipmentListing/IAQOptionContainer';
import CartPageContainer from 'pages/cart/CartPageContainer';
import { ScheduleInspectionContainer } from 'pages/schedule/ScheduleInspectionContainer';
import OrdersContainer from 'pages/order/OrdersContainer';
import MyQuotes from 'pages/MyQuotes/MyQuotes';
import CartPageAddOnModal from 'pages/cart/CartPageAddonModal';
import OrderConfirmationContainer from 'pages/confirmation/OrderConfirmationContainer';
import BillPaymentContainer from 'pages/payment/BillPaymentContainer';
import { isStoreNone } from 'config';
import Index from 'pages/airandheatdoneright/AirAndHeatDoneRight';
import { MotiliFriendsAndFamilyProgram } from 'pages/motili/MotiliFriendsAndFamilyProgram';

export const RoutesList = () => {
    const location = useLocation();
    const dispatch = useDispatch();
    let searchParams = '';
    if (location.search) {
        searchParams = location.search;
    }
    const PrivateRoute = ({ children }: any) => {
        let loginData = useSelector(selectLoginState);
        const isUserDetailsAvailable = loginData.firstname;
        const isLocalCookieAlive = Cookies.get(IS_ALIVE_COOKIE);
        let isAllowedToProceed: boolean = true;
        let PageViewReq: PageViewRequest = {
            email: loginData.email,
        };
        if (isLocalCookieAlive) {
            dispatch(contactPageViewAction(PageViewReq));
        }
        if (!isLocalCookieAlive && isUserDetailsAvailable) {
            //user has logged in/signed up. But his token is expired. Take him to home page. clear login data
            dispatch(clearLoginData());
            isAllowedToProceed = false;
            showSnackbarError('Your session expired');
        }
        return isUserDetailsAvailable && isAllowedToProceed ? (
            children
        ) : (
            <Navigate to={{ pathname: AppRoute.Home }} state={{ pageToRedirect: location.pathname }} />
        );
    };

    const OpenRoute = ({ children }: any) => {
        const isLocalCookieAlive = Cookies.get(IS_ALIVE_COOKIE);
        let loginData = useSelector(selectLoginState);
        const isUserDetailsAvailable = loginData.firstname;
        let PageViewReq: PageViewRequest = {
            email: loginData.email,
        };
        if (isLocalCookieAlive) {
            dispatch(contactPageViewAction(PageViewReq));
        }

        if (isUserDetailsAvailable && !isLocalCookieAlive) {
            //user has logged in/signed up. But his token is expired. clear login data. Retain on same page(since it is open route)
            dispatch(clearLoginData());
            showSnackbarError('Your session expired');
        }
        if (!navigator.cookieEnabled) {
            return <Navigate to={{ pathname: AppRoute.Home }} />;
        }
        return children;
    };

    const PrivateRouteOnlyForVerifiedUser = ({ children }: any) => {
        let loginData = useSelector(selectLoginState);
        const isUserDetailsAvailable = loginData.firstname;
        const isVerified = loginData.isVerified;
        const isLocalCookieAlive = Cookies.get(IS_ALIVE_COOKIE);
        let isAllowedToProceed: boolean = true;
        let PageViewReq: PageViewRequest = {
            email: loginData.email,
        };
        if (isLocalCookieAlive) {
            dispatch(contactPageViewAction(PageViewReq));
        }
        if (!isLocalCookieAlive && isUserDetailsAvailable) {
            //user has logged in/signed up. But his token is expired. Take him to home page. clear login data
            dispatch(clearLoginData());
            isAllowedToProceed = false;
            showSnackbarError('Your session expired');
        }
        return isVerified && isAllowedToProceed ? (
            children
        ) : (
            <Navigate
                to={{
                    pathname: AppRoute.Home,
                    search: location.search,
                }}
                state={{ pageToRedirect: location.pathname }}
            />
        );
    };
    return (
        <Routes>
            <Route path={AppRoute.Home} element={isStoreNone() ? <Index /> : <Home />} />
            <Route
                path={AppRoute.AirConditioners}
                element={
                    <OpenRoute>
                        {' '}
                        <AirConditioners />
                    </OpenRoute>
                }
            />
            <Route
                path={AppRoute.PackagedUnits}
                element={
                    <OpenRoute>
                        {' '}
                        <PackagedUnits />
                    </OpenRoute>
                }
            />
            <Route
                path={AppRoute.HeatPumps}
                element={
                    <OpenRoute>
                        {' '}
                        <HeatPumps />
                    </OpenRoute>
                }
            />
            <Route
                path={AppRoute.AirHandlers}
                element={
                    <OpenRoute>
                        {' '}
                        <AirHandlers />
                    </OpenRoute>
                }
            />
            <Route
                path={AppRoute.Furnaces}
                element={
                    <OpenRoute>
                        {' '}
                        <Furnaces />
                    </OpenRoute>
                }
            />
            <Route
                path={AppRoute.HowHvacWorks}
                element={
                    <OpenRoute>
                        {' '}
                        <HowHVACWorks />
                    </OpenRoute>
                }
            />
            <Route
                path={AppRoute.WhatIsOurProcess}
                element={
                    <OpenRoute>
                        {' '}
                        <OurProcess />
                    </OpenRoute>
                }
            />
            <Route
                path={AppRoute.IndoorAirQuality}
                element={
                    <OpenRoute>
                        {' '}
                        <IndoorAirQuality />
                    </OpenRoute>
                }
            />
            <Route
                path={AppRoute.WhoWeAre}
                element={
                    <OpenRoute>
                        {' '}
                        <WhoWeAre />
                    </OpenRoute>
                }
            />
            <Route
                path={AppRoute.SatisfactionPledge}
                element={
                    <OpenRoute>
                        {' '}
                        <SatisfactionPledge />
                    </OpenRoute>
                }
            />
            <Route
                path={AppRoute.ContactUs}
                element={
                    <OpenRoute>
                        {' '}
                        <ContactUs />
                    </OpenRoute>
                }
            />
            <Route
                path={AppRoute.EnergyStar}
                element={
                    <OpenRoute>
                        {' '}
                        <EnergyStar />
                    </OpenRoute>
                }
            />
            <Route
                path={AppRoute.MadeInUsa}
                element={
                    <OpenRoute>
                        {' '}
                        <MadeInUsa />
                    </OpenRoute>
                }
            />
            <Route
                path={AppRoute.SecurityAndPrivacyPolicy}
                element={
                    <OpenRoute>
                        {' '}
                        <SecurityAndPrivacyPolicy />
                    </OpenRoute>
                }
            />
            <Route
                path={AppRoute.TermsAndConditions}
                element={
                    <OpenRoute>
                        {' '}
                        <TermsAndConditions />
                    </OpenRoute>
                }
            />
            <Route
                path={AppRoute.AboutYourHome}
                element={
                    <OpenRoute>
                        {' '}
                        <AboutYourHome />
                    </OpenRoute>
                }
            />
            <Route
                path={AppRoute.EquipmentOptions}
                element={
                    <PrivateRoute>
                        <EquipmentOptionContainer />
                    </PrivateRoute>
                }
            />
            <Route
                path={AppRoute.MyProfile}
                element={
                    <PrivateRoute>
                        <MyProfile />
                    </PrivateRoute>
                }
            />
            <Route
                path={AppRoute.MyProperties}
                element={
                    <PrivateRoute>
                        <MyProperties />
                    </PrivateRoute>
                }
            />
            <Route
                path={AppRoute.EditMyProfile}
                element={
                    <PrivateRoute>
                        <EditMyProfile />
                    </PrivateRoute>
                }
            />
            <Route
                path={AppRoute.EquipmentSelection}
                element={
                    <PrivateRoute>
                        <EquipmentSelectionContainer />
                    </PrivateRoute>
                }
            />
            <Route
                path={AppRoute.IAQOptions}
                element={
                    <PrivateRoute>
                        <IAQOptionContainer />
                    </PrivateRoute>
                }
            />
            <Route
                path={AppRoute.CartPage}
                element={
                    <PrivateRoute>
                        <CartPageContainer />
                    </PrivateRoute>
                }
            />
            <Route
                path={AppRoute.Schedule}
                element={
                    <PrivateRoute>
                        <ScheduleInspectionContainer />
                    </PrivateRoute>
                }
            />
            <Route
                path={AppRoute.MyOrders}
                element={
                    <PrivateRoute>
                        <OrdersContainer />
                    </PrivateRoute>
                }
            />
            <Route
                path={AppRoute.MyQuotes}
                element={
                    <PrivateRoute>
                        <MyQuotes />
                    </PrivateRoute>
                }
            />
            <Route
                path={AppRoute.AddOnPage}
                element={
                    <PrivateRoute>
                        <CartPageAddOnModal />
                    </PrivateRoute>
                }
            />
            <Route
                path={AppRoute.Confirmation}
                element={
                    <PrivateRouteOnlyForVerifiedUser>
                        <OrderConfirmationContainer />
                    </PrivateRouteOnlyForVerifiedUser>
                }
            />
            <Route
                path={AppRoute.Billing}
                element={
                    <PrivateRouteOnlyForVerifiedUser>
                        <BillPaymentContainer />
                    </PrivateRouteOnlyForVerifiedUser>
                }
            />

            <Route
                path={AppRoute.MotiliFriendsAndFamily}
                element={
                    <OpenRoute>
                        <MotiliFriendsAndFamilyProgram />
                    </OpenRoute>
                }
            />
            <Route path="*" element={<Navigate to={AppRoute.Home + searchParams} />} />
        </Routes>
    );
};
