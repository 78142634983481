import React from 'react';
import { Box, useTheme } from '@mui/material';
import { SXCssObject } from '../../models/ThemeImages';
import { Theme } from '@mui/material/styles';
import TooltipIcon from '../../components/icons/TooltipIcon';
import { Paragraph } from 'components/Paragraph';

const createCssObject = (theme: Theme): SXCssObject => ({
    orderConfirmationInfo: {
        width: 'inherit',
    },
    heading: {
        textAlign: 'left',
        letterSpacing: '0px',
        color: theme.typography.h1.color,
        paddingBottom: '10px',
        display: 'flex',
        alignItems: 'center',
        fontSize: { xs: '16px', sm: '18px', md: '20px' },
    },
    headingText: {
        marginLeft: '10px',
        marginTop: '5px',
    },
});

export default function OrderConfirmationInfo() {
    const cssObject: SXCssObject = createCssObject(useTheme());

    return (
        <Box sx={cssObject.orderConfirmationInfo}>
            <Box sx={cssObject.heading}>
                <TooltipIcon />
                <Box sx={cssObject.headingText}>
                    <Paragraph responsiveFontSize20 bold headingStyle>
                        Order Confirmation
                    </Paragraph>
                </Box>
            </Box>
            <Box>
                <Paragraph size={'small'}>
                    After you place your order, a Motili representative will contact you to confirm everything with your
                    order. This gives us the opportunity to verify that the selected HVAC product will meet your needs
                    and determine what materials will need to be included for proper installation.
                </Paragraph>
            </Box>
        </Box>
    );
}
