import React, { useState } from 'react';
import { styled, Theme, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Divider from '@mui/material/Divider';
import { Paragraph } from 'components/Paragraph';
import Slider from '@mui/material/Slider';
import TripOriginIcon from '@mui/icons-material/TripOrigin';
import { CircularProgress, FormControl, InputAdornment, LinearProgress, OutlinedInput } from '@mui/material';
import InvalidAmountNotification from '../../components/InvalidAmountNotification';
import { InvalidAmountText } from 'components/common/constants';
import { calculateRoundedPercent, currencyFormat, percentageFormat } from '../../components/common/NumberUtil';
import { SXCssObject } from 'models/ThemeImages';
import { marks } from './Payment-helpers';

const createCssObject = (theme: Theme): SXCssObject => ({
    disabledInput: {
        borderRadius: '40px',
        height: '50px',
        backgroundColor: theme.palette.action.disabled,
        minWidth: {
            sm: '170px',
        },
    },

    tabPanelText: {
        fontSize: '1rem',
        fontWeight: theme.typography.fontWeightMedium,
    },
    progressBar: {
        border: `1px solid ${theme.palette.divider}`,
        padding: '20px',
        marginTop: '20px',
    },
    slider: {
        width: '50%',
        marginRight: '-4px',
        display: 'flex',
        alignItems: 'center',
    },
    downpayment: {
        backgroundColor: theme.palette.background.default,
        padding: '20px',
    },
    hollowRing: {
        color: theme.palette.primary.main,
        fontSize: '12px',
        mr: '10px',
        mt: '7px',
    },
    centerAlign: {
        display: 'flex',
    },
    downPaymentPrice: {
        mt: '5px',
        justifyContent: 'center',
    },
    markings: {
        fontSize: '0.75rem',
        mt: '-5px',
    },
    progress: {
        width: '100%',
        marginRight: '-4px',
    },
    progressInput: {
        pt: { xs: 2, sm: 1 },
    },
    divider: {
        height: '24px',
        ml: '5px',
        backgroundColor: theme.customProperties.divider,
    },
    downpaymentContainer: {
        pl: { xs: 0, sm: 3 },
        mt: { xs: 2, sm: 0 },
    },
    percentageText: {
        backgroundColor: theme.palette.secondary.main,
        borderRadius: '18px',
        width: '120px',
        height: '36px',
    },
    sliderContainer: {
        paddingTop: '30px',
    },
    pendingAmount: {
        paddingTop: '10px',
        display: 'flex',
    },
});

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 8,
    borderRadius: 6,
    width: '100%',
    '& .MuiLinearProgress-colorPrimary': {
        backgroundColor: theme.palette.primary.contrastText,
    },
}));
interface Props {
    grandTotal: number;
    finalInstallment?: number;
    amountAlert?: boolean;
    orderTotalDue?: number;
    orderTotalPaid?: number;
    initalPercent: number;
    onAmountChange: (partialPaymentPrice: number) => void;
}
const PartialPaymentContainer = (props: Props) => {
    const cssObject: SXCssObject = createCssObject(useTheme());
    const { finalInstallment, amountAlert, grandTotal, orderTotalDue, orderTotalPaid, onAmountChange, initalPercent } =
        props;
    //state of slider percent - when changed
    const [percentage, setPercentage] = useState(initalPercent);
    //state of input amount
    const [amount, setAmount] = useState(calculateRoundedPercent(grandTotal * initalPercent).toString());
    //logic when coupon code is applied/removed
    React.useEffect(() => {
        setAmount(calculateRoundedPercent(grandTotal * percentage).toString());
        onAmountChange(calculateRoundedPercent(grandTotal * percentage));
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [grandTotal]);
    const downPaymentPrice = currencyFormat.format(Number(amount));
    const pendingPayment = currencyFormat.format(grandTotal - Number(amount));

    //method-to handle input change
    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const enteredAmount = e.target.value.indexOf('.') === -1 ? e.target.value : ParseFloat(e.target.value, 2);
        setAmount(enteredAmount);
        setPercentage((Number(enteredAmount) * 100) / grandTotal);
        onAmountChange(Number(enteredAmount));
    };
    //method-to handle slider drag
    const handleSliderChange = (e: any, v: any) => {
        setPercentage(v);
        setAmount(calculateRoundedPercent(grandTotal * v).toString());
    };
    //method-to handle slider commit
    const paymentAmount = () => {
        onAmountChange(Number(amount));
    };

    const invalidNotificationText = () => {
        if (percentage < 50) {
            return InvalidAmountText.LowerLimit;
        } else if (percentage > 100) {
            return InvalidAmountText.HigherLimit;
        } else {
            return InvalidAmountText.InvalidAmt;
        }
    };
    const circularProgressValue = () => {
        if (percentage <= 100 && percentage >= 0) {
            return percentage;
        } else if (percentage > 100) {
            return 100;
        } else return 0;
    };
    function ParseFloat(str: string, val: number) {
        str = str.slice(0, str.indexOf('.') + val + 1);
        return str;
    }
    const AmountPieContainer = () => {
        return (
            <Grid xs={12} sm={6} sx={cssObject.downpaymentContainer}>
                <Grid container sx={cssObject.downpayment}>
                    <Grid item xs={7}>
                        <Grid item sx={cssObject.centerAlign}>
                            <TripOriginIcon sx={cssObject.hollowRing} />
                            <Paragraph fontSizeMediumSmall fontColor component="div">
                                Paid Down-payment
                                <Paragraph semibold>
                                    {finalInstallment
                                        ? orderTotalPaid && currencyFormat.format(orderTotalPaid)
                                        : downPaymentPrice}
                                </Paragraph>
                            </Paragraph>
                        </Grid>
                        <Grid item sx={cssObject.pendingAmount}>
                            <TripOriginIcon sx={cssObject.hollowRing} style={{ opacity: 0.3 }} />
                            <Paragraph fontSizeMediumSmall fontColor component="div">
                                Pending payment
                                <Paragraph semibold>
                                    {finalInstallment
                                        ? orderTotalDue && currencyFormat.format(orderTotalDue)
                                        : pendingPayment}
                                </Paragraph>
                            </Paragraph>
                        </Grid>
                    </Grid>
                    <Grid item xs={5} justifyContent={'center'} alignItems={'center'} sx={cssObject.centerAlign}>
                        <CircularProgress
                            variant="determinate"
                            value={finalInstallment ? 100 - finalInstallment : circularProgressValue()}
                            thickness={22}
                            size={'5rem'}
                            sx={{
                                '& .MuiCircularProgress-svg': {
                                    backgroundColor: 'primary.contrastText',
                                    borderRadius: '50%',
                                    border: '8px solid white',
                                },
                            }}
                        />
                    </Grid>
                </Grid>
            </Grid>
        );
    };

    //used for final payment-to show balance amount
    function ProgressBar() {
        return (
            <Grid container sx={cssObject.progressBar} alignItems={'center'} justifyContent={'space-between'}>
                {/* SliderContainer */}
                <Grid container xs={12} sm={6} justifyContent={'flex-end'} alignItems={'center'}>
                    <Grid xs={12} sm={5} lg={6} item justifyContent={'flex-start'}>
                        <Box component={'b'} sx={cssObject.tabPanelText}>
                            Pending payment
                        </Box>
                        <Paragraph fontSizeVerySmall>Proceed for final payment</Paragraph>
                    </Grid>
                    <Grid item xs={12} sm={7} lg={6} sx={cssObject.progressInput} justifyContent={'flex-end'}>
                        <FormControl variant="outlined" fullWidth>
                            <OutlinedInput
                                disabled={true}
                                fullWidth
                                sx={cssObject.disabledInput}
                                id="final-amount"
                                value={orderTotalDue}
                                startAdornment={
                                    <InputAdornment
                                        position="start"
                                        disableTypography
                                        sx={{
                                            '& .MuiInputAdornment-positionStart': {
                                                ml: '-3px',
                                                mr: '6px',
                                            },
                                        }}
                                    >
                                        $ <Divider orientation={'vertical'} sx={cssObject.divider} />
                                    </InputAdornment>
                                }
                                endAdornment={<PercentageIndicator />}
                                aria-describedby="outlined-amount-helper-text"
                                inputProps={{
                                    'aria-label': 'amount',
                                    sx: {
                                        fontWeight: 'fontWeightMedium',
                                    },
                                }}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sx={cssObject.sliderContainer}>
                        <BorderLinearProgress
                            variant={'determinate'}
                            value={finalInstallment && 100 - finalInstallment}
                        />
                    </Grid>
                    <Grid
                        item
                        container
                        justifyContent={'space-between'}
                        sx={cssObject.markings}
                        style={{ marginTop: '6px' }}
                        xs={12}
                    >
                        <Grid item>0%</Grid>
                        <Grid item>25%</Grid>
                        <Grid item>50%</Grid>
                        <Grid item>75%</Grid>
                        <Grid item>100%</Grid>
                    </Grid>
                </Grid>
                {/* Amount and Pie container */}
                <AmountPieContainer />
            </Grid>
        );
    }
    const PercentageIndicator = () => {
        return (
            <Grid container justifyContent={'center'} alignContent={'center'} sx={cssObject.percentageText}>
                <Paragraph semibold>
                    {finalInstallment ? percentageFormat(finalInstallment) : percentageFormat(percentage)}
                    {'%'}
                </Paragraph>
            </Grid>
        );
    };
    return finalInstallment ? (
        <ProgressBar />
    ) : (
        //Initial Partial payment container
        <Grid container sx={cssObject.progressBar} alignItems={'center'} justifyContent={'space-between'}>
            {/* SliderContainer */}
            <Grid container xs={12} sm={6} justifyContent={'flex-end'} alignItems={'center'}>
                <Grid xs={12} sm={5} lg={6} item justifyContent={'flex-start'}>
                    <Box component={'b'} sx={cssObject.tabPanelText}>
                        Select down-payment
                    </Box>
                    <Paragraph fontSizeVerySmall>Minimum 50%</Paragraph>
                </Grid>
                <Grid item xs={12} sm={7} lg={6} sx={cssObject.progressInput} justifyContent={'flex-end'}>
                    <FormControl variant="outlined" fullWidth>
                        <OutlinedInput
                            error={amountAlert}
                            fullWidth
                            sx={{
                                '& .MuiOutlinedInput-root': {
                                    borderRadius: '40px',
                                    height: '50px',
                                    minWidth: {
                                        sm: '170px',
                                    },
                                },
                                '& .MuiOutlinedInput-input': {
                                    fontWeight: 'fontWeightMedium',
                                    '&[type=number]': {
                                        MozAppearance: 'textfield',
                                    },
                                    '&::-webkit-outer-spin-button': {
                                        WebkitAppearance: 'none',
                                        margin: 0,
                                    },
                                    '&::-webkit-inner-spin-button': {
                                        WebkitAppearance: 'none',
                                        margin: 0,
                                    },
                                },
                                '& .Mui-error': {
                                    borderColor: 'red',
                                },
                            }}
                            id="outlined-adornment-amount"
                            value={amount}
                            onChange={handleInputChange}
                            startAdornment={
                                <InputAdornment
                                    position="start"
                                    disableTypography
                                    sx={{
                                        '& .MuiInputAdornment-positionStart': {
                                            ml: '-3px',
                                            mr: '6px',
                                        },
                                    }}
                                >
                                    $ <Divider orientation={'vertical'} sx={cssObject.divider} />
                                </InputAdornment>
                            }
                            endAdornment={<PercentageIndicator />}
                            aria-describedby="outlined-amount-helper-text"
                            inputProps={{
                                'aria-label': 'amount',
                            }}
                            type={'number'}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={12} sx={cssObject.sliderContainer}>
                    <Slider
                        aria-label="Custom marks"
                        defaultValue={50}
                        value={percentage}
                        onChangeCommitted={paymentAmount}
                        onChange={handleSliderChange}
                        valueLabelDisplay="off"
                        min={0}
                        max={100}
                        componentsProps={{
                            root: {
                                style: {
                                    width: '100%',
                                    zIndex: 10,
                                    display: 'flex',
                                    alignItems: 'center',
                                },
                            },
                            track: {
                                style: {
                                    height: '8px ',
                                    borderRadius: '6px',
                                },
                            },
                            rail: {
                                style: {
                                    height: '8px ',
                                    borderRadius: '6px',
                                },
                            },
                            markLabel: {
                                style: {
                                    color: 'customProperties.darkGrey',
                                    fontSize: '0.75rem',
                                },
                            },
                            mark: {
                                style: {
                                    display: 'none',
                                },
                            },
                        }}
                        marks={marks}
                    />
                </Grid>
            </Grid>
            {/* Amount and Pie container */}
            <AmountPieContainer />
            {amountAlert && (
                <Grid item xs={12} style={{ paddingTop: '20px' }}>
                    <InvalidAmountNotification isOpen={amountAlert} errorText={invalidNotificationText()} />
                </Grid>
            )}
        </Grid>
    );
};

export default PartialPaymentContainer;
