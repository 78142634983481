import React from 'react';
import { Theme, useTheme } from '@mui/material/styles';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import FormLabel from '@mui/material/FormLabel';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputMask from 'react-input-mask';
import CircularProgress from '@mui/material/CircularProgress';
import OtpErrorIcon from './icons/OtpErrorIcon';
import { Grid } from '@mui/material';
import { SXCssObject } from 'models/ThemeImages';

const createCssObject = (theme: Theme, props: Props): SXCssObject => ({
    root: {},
    label: {
        marginBottom: theme.spacing(1),
        color: theme.palette.text.primary,
        fontWeight: props.fontWeightIsMedium ? theme.typography.fontWeightMedium : theme.typography.fontWeightRegular,
        '&.Mui-focused': {
            color: theme.palette.text.primary,
        },
        '&.Mui-error': {
            color: theme.palette.error.main,
        },
    },
    input: {
        borderRadius: theme.spacing(0.8),
        height: theme.spacing(4.8),
        '& input': {
            padding: theme.spacing(1.2),
        },
        '&.Mui-error': {
            marginBottom: theme.spacing(0.4),
        },
        '&.MuiOutlinedInput-root': {
            '&:hover fieldset': {
                borderColor: theme.palette.text.primary,
            },
            '& fieldset': {
                border: `1px solid ${theme.palette.divider}`,
            },
            '&.Mui-focused fieldset': {
                borderColor: theme.palette.text.primary,
                borderWidth: '1px',
            },
            '&.Mui-disabled': {
                backgroundColor: theme.palette.action.disabled,
            },
            '&.Mui-error .MuiOutlinedInput-notchedOutline': {
                border: `1px solid ${theme.customProperties.primaryErrorBorder}`,
            },
        },
    },
    helperText: {
        color: theme.palette.text.primary,
        marginLeft: 6,
    },
});

interface TextMaskCustomProps {
    inputRef: (inputRef: HTMLInputElement | null) => void;
}

function TextMaskCustom(props: TextMaskCustomProps) {
    const { inputRef, ...other } = props;

    return (
        <InputMask
            {...other}
            // inputRef={(ref: any) => {
            //     inputRef(ref ? ref.inputElement : null);
            // }}
            mask={'999.999.9999'}
        />
    );
}

interface Props {
    id: string;
    type?: string;
    label?: string;
    fullWidth?: boolean;
    helperText?: string;
    error?: boolean;
    placeholder?: string;
    enableErrorDisplay?: boolean;
    validating?: boolean;
    isDisabled?: boolean;
    fontWeightIsMedium?: boolean;
}

export function PhoneField(props: Props) {
    const cssObject: SXCssObject = createCssObject(useTheme(), props);
    const {
        id,
        type = 'text',
        label,
        fullWidth,
        helperText,
        error,
        placeholder,
        enableErrorDisplay = false,
        validating,
        isDisabled,
        fontWeightIsMedium,
        ...other
    } = props;
    return (
        <FormControl id={`${id}-root`} fullWidth={fullWidth} sx={cssObject.root} error={enableErrorDisplay && error}>
            {label && (
                <FormLabel id={`${id}-label`} htmlFor={id} sx={cssObject.label}>
                    {label}
                </FormLabel>
            )}
            <OutlinedInput
                id={id}
                fullWidth={fullWidth}
                type={type}
                error={enableErrorDisplay && error}
                sx={cssObject.input}
                placeholder={placeholder}
                disabled={validating || isDisabled}
                inputComponent={TextMaskCustom as any}
                endAdornment={validating ? <CircularProgress size={20} /> : undefined}
                {...other}
            />
            {enableErrorDisplay && (
                <Grid container>
                    <OtpErrorIcon />
                    <FormHelperText id={`${id}-helper`} sx={cssObject.helperText}>
                        &nbsp;{helperText}
                    </FormHelperText>
                </Grid>
            )}
        </FormControl>
    );
}
