import React, { useEffect } from 'react';
import ContentContainer from 'components/ContentContainer';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import { Theme, useTheme } from '@mui/material/styles';
import { Heading } from 'components/Heading';
import { Paragraph } from 'components/Paragraph';
import { Container, useMediaQuery } from '@mui/material';
import Navigator from '../../components/Navigator';
import { useDispatch, useSelector } from 'react-redux';
import QuotesDetail from './QuotesDetail';
import { QuotesGridHeadingMenu } from './QuoteMenus';
import { NoQuotesLayout } from './NoQuotesLayout';
import { myQuotesAction, resetQuoteData } from 'store/actions/quotes';
import { selectQuotes } from 'store/selectors/quotes';
import LoadingOverlay from 'components/common/LoadingOverlay';
import { resetSelection } from 'store/actions/cartActions';
import { resetProductCriteria } from 'store/actions/productCriteriaActions';
import { AppRoute } from 'models/route';
import { useNavigate } from 'react-router-dom';
import { selectCartProcessing } from 'store/selectors/cart';
import { SXCssObject } from 'models/ThemeImages';
const createCssObject = (theme: Theme): SXCssObject => ({
    header: {
        pt: { xs: 2.5, sm: 4, md: 6 },
        pb: { xs: 1.5, sm: 2, md: 3 },
    },
    header3: {
        backgroundColor: '#EFEFEF',
        margin: '0px',
        maxWidth: '100%',
    },
    navContainer: {
        px: {
            xs: 0,
            sm: 'inherit',
        },
        backgroundColor: {
            xs: theme.palette.common.white,
        },
    },
});

function MyQuotes() {
    const cssObject: SXCssObject = createCssObject(useTheme());
    const theme = useTheme();
    const isUpMd = useMediaQuery(theme.breakpoints.up('md'));
    const quoteData = useSelector(selectQuotes);
    const myQuotes = quoteData.myQuotes;
    const loading = quoteData.loading;
    const cartLoading = useSelector(selectCartProcessing);

    const showNoContentLayout = !loading && (!myQuotes || myQuotes.length <= 0);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        dispatch(myQuotesAction());
        return () => {
            dispatch(resetQuoteData());
        };
    }, [dispatch]);

    return (
        <ContentContainer disableGrid background={'bg2'}>
            <LoadingOverlay open={loading || cartLoading} />
            <ContentContainer disableGrid background={'bg3'} sx={cssObject.navContainer}>
                <ContentContainer>
                    <Navigator color={'primary'} />
                </ContentContainer>
            </ContentContainer>
            <Divider />
            <ContentContainer disableGrid>
                <ContentContainer sx={cssObject.header}>
                    <Grid item xs={12} container alignItems={'center'}>
                        <Grid item xs={6}>
                            <Heading label={'My Quotes'} />
                        </Grid>
                    </Grid>
                </ContentContainer>
            </ContentContainer>
            {showNoContentLayout ? (
                <NoQuotesLayout
                    onButtonClick={() => {
                        dispatch(resetSelection());
                        dispatch(resetProductCriteria());
                        navigate(AppRoute.AboutYourHome);
                    }}
                />
            ) : (
                <div style={{ marginBottom: '100px' }}>
                    <ContentContainer>
                        {isUpMd && myQuotes.length > 0 && <QuotesHeading />}
                        {myQuotes &&
                            myQuotes.length > 0 &&
                            myQuotes.map((quote, index) => {
                                return <QuotesDetail quote={quote} key={index} />;
                            })}
                    </ContentContainer>
                </div>
            )}
        </ContentContainer>
    );
}

function QuotesHeading() {
    const cssObject: SXCssObject = createCssObject(useTheme());

    return (
        <Container maxWidth={false} id={`active-cart`} disableGutters>
            <Grid container sx={cssObject.header3} spacing={2}>
                <Grid item xs={2} style={{ paddingBottom: 15, paddingTop: 15 }}>
                    <Paragraph semibold headingColor>
                        {QuotesGridHeadingMenu.QUOTE_ID}
                    </Paragraph>
                </Grid>
                <Grid item xs={2} style={{ paddingBottom: 15, paddingTop: 15 }}>
                    <Paragraph semibold headingColor>
                        {QuotesGridHeadingMenu.QUOTE_PRODUCTS}
                    </Paragraph>
                </Grid>
                <Grid item xs={2} style={{ paddingBottom: 15, paddingTop: 15 }}>
                    <Paragraph semibold headingColor>
                        {QuotesGridHeadingMenu.QUOTE_PROPERTY}
                    </Paragraph>
                </Grid>
                <Grid item xs={2} container style={{ paddingBottom: 15, paddingTop: 15 }}>
                    <Paragraph semibold headingColor>
                        {QuotesGridHeadingMenu.EXPIRY_DATE}
                    </Paragraph>
                </Grid>
                <Grid item xs={1} style={{ paddingBottom: 15, paddingTop: 15 }}>
                    <Paragraph semibold headingColor>
                        {QuotesGridHeadingMenu.QUOTE_CURRENT_PRICE}
                    </Paragraph>
                </Grid>
                <Grid item xs={3} style={{ whiteSpace: 'nowrap', paddingBottom: 15, paddingTop: 15 }}>
                    <Paragraph semibold headingColor>
                        {QuotesGridHeadingMenu.QUOTE_ORIGINAL_PRICE}
                    </Paragraph>
                </Grid>
            </Grid>
        </Container>
    );
}

export default MyQuotes;
