import React from 'react';
import MenuItem from '@mui/material/MenuItem';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Box, ClickAwayListener, Grow, IconButton, MenuList, Paper, Popper, Theme, useTheme } from '@mui/material';
import { QuoteMenu } from './QuoteMenus';
import { SXCssObject } from 'models/ThemeImages';

const createCssObject = (theme: Theme): SXCssObject => ({
    menuItem: {
        height: '40px',
        fontSize: '14px',
        minWidth: '200px',
        borderBottom: `1px solid ${theme.customProperties.lightGrey}`,
        '&:hover': {
            fontWeight: theme.typography.fontWeightMedium,
            background: theme.customProperties.lightGrey,
        },
        '&:last-child': {
            borderBottom: 'none',
        },
    },
    iconMenu: {
        color: theme.customProperties.borderDivider,
    },
    caretTopStyle: {
        position: 'relative',
        marginTop: '6px',
        left: '182px',
        '&::before': {
            backgroundColor: theme.palette.common.white,
            content: '""',
            display: 'block',
            position: 'absolute',
            width: '12px',
            height: '12px',
            top: '-12px',
            transform: 'rotate(45deg)',
            left: 'calc(50% - 6px)',
        },
    },
    caretBottomStyle: {
        position: 'relative',
        marginTop: '-8px',
        left: '182px',
        '&::after': {
            backgroundColor: theme.palette.common.white,
            content: '""',
            display: 'block',
            position: 'absolute',
            width: '12px',
            height: '12px',
            bottom: '-6px',
            transform: 'rotate(45deg)',
            left: 'calc(50% - 6px)',
        },
    },
    paperStyle: {
        marginTop: '8px',
        marginBottom: '8px',
        borderRadius: '4px',
        display: 'flex',
        boxShadow: '0px 5px 31px #0000004F',
    },
});

interface Props {
    menuList: QuoteMenu[];
    handleMenuClick: (menu: QuoteMenu) => void;
}

export default function QuoteDropdown(props: Props) {
    const cssObject: SXCssObject = createCssObject(useTheme());
    const anchorRef = React.useRef<HTMLButtonElement>(null);
    const theme = useTheme();
    const [isOptionsOpen, setOptionsVisibility] = React.useState(false);

    const handleClick = (event: any) => {
        setOptionsVisibility(!isOptionsOpen);
    };

    const handleClose = () => {
        setOptionsVisibility(false);
    };

    return (
        <div>
            <IconButton size="small" ref={anchorRef} onClick={handleClick}>
                <MoreVertIcon sx={cssObject.iconMenu} style={{ color: theme.customProperties.borderDivider }} />
            </IconButton>
            <Popper
                anchorEl={anchorRef.current}
                placement="bottom-end"
                open={isOptionsOpen}
                style={{ zIndex: 2 }}
                transition
                disablePortal
            >
                {({ TransitionProps, placement }) => (
                    <Grow {...TransitionProps}>
                        <Paper sx={cssObject.paperStyle}>
                            <Box
                                sx={[
                                    placement === 'bottom-end' && cssObject.caretTopStyle,
                                    placement === 'top-end' && cssObject.caretBottomStyle,
                                ]}
                            />
                            <ClickAwayListener
                                onClickAway={() => {
                                    handleClose();
                                }}
                            >
                                <MenuList id="menu-list-quote">
                                    {props.menuList.map((item: QuoteMenu, key: number) => (
                                        <MenuItem
                                            key={key}
                                            sx={cssObject.menuItem}
                                            onClick={() => {
                                                props.handleMenuClick(item);
                                                handleClose();
                                            }}
                                        >
                                            <item.icon style={{ marginRight: 12 }} /> {item.label}
                                        </MenuItem>
                                    ))}
                                </MenuList>
                            </ClickAwayListener>
                        </Paper>
                    </Grow>
                )}
            </Popper>
        </div>
    );
}
