import React from 'react';
import { Theme, useTheme } from '@mui/material/styles';
// import { makeStyles } from '@mui/styles';
// import ProfileIcon from 'assets/icons/icon_profile.svg';
// import Measure from 'react-measure';
// import ReviewIcon from 'components/icons/ReviewIcon';
import { ProductReviews } from 'models/productReviews';
// import Carousel from 'react-multi-carousel';
// import 'react-multi-carousel/lib/styles.css';
import { Box, Typography } from '@mui/material';
// import { Paragraph } from 'components/Paragraph';
import { SXCssObject } from 'models/ThemeImages';
// const useStyles = makeStyles<Theme, any>((theme: Theme) => ({
//     dotsStyle: {
//         marginTop: (props) => props.dotsMarginTop,
//         '& li': {
//             '&.react-multi-carousel-dot--active': {
//                 '& button': {
//                     backgroundColor: theme.palette.primary.main,
//                     borderColor: theme.palette.primary.main,
//                 },
//             },
//         },
//     },
//     focusedSlide: {
//         marginBottom: (props) => props.dotsMarginTop,
//         '& li:first-of-type': {
//             '&.react-multi-carousel-item--active': {
//                 width: (props) => `${props.firstSlideWidth}px !important`,
//                 paddingLeft: (props) => `${props.firstSlideWidth * 0.33}px !important`,
//             },
//         },
//         '& li': {
//             display: 'flex',
//             justifyContent: 'center',
//         },
//     },
// }));
const createCssObject = (theme: Theme, props: any): SXCssObject => ({
    root: {
        backgroundColor: `${theme.palette.common.white}`,
        padding: '40px 0px',
        position: 'relative',
    },
    headingStyle: {
        marginBottom: '40px',
        textAlign: 'center',
    },
    profileIconStyle: {
        display: 'flex',
        justifyContent: 'center',
        width: '50px',
        height: '50px',
    },
    focusedProfileIconStyle: {
        width: '100px',
        height: '100px',
        transitionDuration: '300ms',
    },
    dividerStyle: {
        borderTop: `1px solid ${theme.customProperties.borderDivider}`,
        borderBottom: '0px',
    },
    reviewItemStyle: {
        display: 'flex',
        alignItems: 'center',
        position: 'relative',
        height: '100px',
        transitionDuration: '300ms',
    },

    commentsContainer: {
        width: '100%',
        padding: '30px 16px',
        // marginTop: -props.dotsMarginTop,
        transitionTimingFunction: 'linear',
    },
    commentsStyle: {
        display: 'flex',
        justifyContent: 'center',
    },
    focusedSlide: {
        // marginBottom: props.dotsMarginTop,
        '& li:first-of-type': {
            '&.react-multi-carousel-item--active': {
                width: `${props.firstSlideWidth}px !important`,
                paddingLeft: `${props.firstSlideWidth * 0.33}px !important`,
            },
        },
        '& li': {
            display: 'flex',
            justifyContent: 'center',
        },
    },
});

const MAX_ITEMS: number = 5;

function CustomerReviews(props: any) {
    const itemsList: ProductReviews[] =
        props.reviewsList.length > MAX_ITEMS ? props.reviewsList.slice(0, MAX_ITEMS) : props.reviewsList;
    const firstSlideWidth = itemsList.length > 1 ? Math.round(window.innerWidth * 0.75) : window.innerWidth;
    // const [dotsMarginTop, setDotsMarginTop] = React.useState<number>(0);
    // const classes = useStyles({ ...props, firstSlideWidth: firstSlideWidth, dotsMarginTop: dotsMarginTop });
    const cssObject: SXCssObject = createCssObject(useTheme(), {
        ...props,
        firstSlideWidth: firstSlideWidth,
        // dotsMarginTop: dotsMarginTop,
    });
    // const [focusedSlide, setFocusedSlide] = React.useState(0);

    // const responsive = {
    //     mobile: {
    //         breakpoint: { max: 600, min: 0 },
    //         items: 1,
    //     },
    // };
    return (
        <Box sx={cssObject.root}>
            <Box sx={cssObject.headingStyle}>
                <Typography variant="h4">What Our Customers Say!</Typography>
            </Box>
            <section>
                {/* <Carousel
                    itemClass="image-item"
                    centerMode={true}
                    showDots={true}
                    infinite={true}
                    deviceType={'mobile'}
                    arrows={false}
                    transitionDuration={0}
                    autoPlay={true}
                    autoPlaySpeed={3000}
                    // dotListClass={classes.dotsStyle}
                    responsive={responsive}
                    // sliderClass={classes.focusedSlide}
                    afterChange={(previousSlide, { currentSlide }) => {
                        let correctIndex = (currentSlide - 2) % MAX_ITEMS;
                        if (correctIndex === -1) {
                            correctIndex = MAX_ITEMS - 1;
                        }
                        if (correctIndex >= 0) {
                            setFocusedSlide(correctIndex);
                        }
                    }}
                >
                    {itemsList.map((item, index) => {
                        return (
                            <Box key={index} sx={cssObject.reviewItemStyle}>
                                <Box
                                    component={'img'}
                                    draggable={false}
                                    alt="Profile"
                                    sx={[
                                        cssObject.profileIconStyle,
                                        focusedSlide === index && cssObject.focusedProfileIconStyle,
                                    ]}
                                    src={ProfileIcon}
                                />
                            </Box>
                        );
                    })}
                </Carousel> */}
                {/* <Measure
                    bounds
                    onResize={(contentRect) => {
                        const height = contentRect && contentRect.bounds ? contentRect.bounds.height + 30 : 30;
                        setDotsMarginTop(height);
                    }}
                >
                    {({ measureRef }) => (
                        <Box ref={measureRef} sx={cssObject.commentsContainer}>
                            <Box sx={cssObject.commentsStyle}>
                                <ReviewIcon style={{ marginBottom: '20px' }} />
                            </Box>

                            <Paragraph align={'center'}>{itemsList[focusedSlide].detail}</Paragraph>
                            <Box component={'hr'} sx={[cssObject.dividerStyle, { m: '24px 0px' }]} />

                            <Box style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                                <Paragraph semibold size={'small'} align={'center'}>
                                    {itemsList[focusedSlide].nickname}
                                </Paragraph>
                                <Paragraph fontSizeVerySmall align={'center'}>
                                    {itemsList[focusedSlide].location}{' '}
                                </Paragraph>
                            </Box>
                        </Box>
                    )}
                </Measure> */}
            </section>
            <Box sx={{ width: '100%', p: '0px 16px', position: 'absolute', bottom: '-24px' }}>
                <Box component={'hr'} sx={cssObject.dividerStyle} />
            </Box>
        </Box>
    );
}

export default CustomerReviews;
