import { isEmpty, pickBy, get, sumBy } from 'lodash';
import { CartPrice, CartStatus } from '../../models/cart';

export const getCartStatusText = (cartStatus?: CartStatus) => {
    if (!cartStatus || cartStatus === CartStatus.READY_TO_SCHEDULE) {
        return 'Ready To Schedule';
    } else if (cartStatus === CartStatus.PENDING_INSPECTION) {
        return 'Pending Inspection';
    } else {
        return 'Ready For Payment';
    }
};

/*  Cart will be open for modification(Add/Edit/Delete Items, Modify Shipping address, etc) only when :
        Status is yet to be scheduled,
        EO Flow
*/
export const isCartOpenForModification = (cartStatus?: CartStatus) => {
    return !cartStatus || cartStatus === CartStatus.READY_TO_SCHEDULE || cartStatus === CartStatus.EQ_READY_FOR_PAYMENT;
};


export const isCartStatusReadyToSchedule = (cartStatus?: CartStatus) => {
    return !cartStatus || cartStatus === CartStatus.READY_TO_SCHEDULE;
};

export const isCartReadyForPayment = (cartStatus?: CartStatus) => {
    return cartStatus === CartStatus.READY_FOR_PAYMENT || cartStatus === CartStatus.EQ_READY_FOR_PAYMENT;
};

export const taxFromCartPrice = (cartPrices: CartPrice) => {
    const apppliedTaxesArray = get(cartPrices, 'appliedTaxes', []);
    const apppliedTaxes = sumBy(apppliedTaxesArray, 'amount') || 0;
    return apppliedTaxes || cartPrices.subtotalIncludingTax - cartPrices.subtotalExcludingTax;
};
export const discountFromCartPrice = (cartPrices: CartPrice) =>
    cartPrices?.discounts && cartPrices?.discounts.length > 0 && cartPrices?.discounts[0].amount;
export const removeEmpty = (obj: any) => pickBy(obj, (val) => !isEmpty(val));
