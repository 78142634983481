import {
    hideCartNotification,
    hideOrderNotification,
    showCartNotification,
    showOrderNotification,
    hideNotification,
} from 'store/actions/notificationAction';
import { reducer, on } from 'ts-action';
interface NotificationState {
    cartNotification: boolean;
    pendingOrderNotification: boolean;
}
const initialState: NotificationState = {
    cartNotification: false,
    pendingOrderNotification: false,
};

export const notificationReducer = reducer(
    initialState,
    on(showCartNotification, (state) => ({
        ...state,
        cartNotification: true,
    })),
    on(showOrderNotification, (state) => ({
        ...state,
        pendingOrderNotification: true,
    })),
    on(hideCartNotification, (state) => ({
        ...state,
        cartNotification: false,
    })),
    on(hideOrderNotification, (state) => ({
        ...state,
        pendingOrderNotification: false,
    })),
    on(hideNotification, (state) => ({ ...initialState }))
);

export default notificationReducer;
