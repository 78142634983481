import { useTheme } from '@mui/material/styles';
import { getThemePrimaryMain } from 'components/common/Utilities';
import * as React from 'react';

function SatisfactionImg3(props: any) {
    const theme = useTheme();
    const themePrimaryMain = getThemePrimaryMain(theme);
    return (
        <svg
            id="Component_57_1"
            data-name="Component 57 – 1"
            xmlns="http://www.w3.org/2000/svg"
            width="48"
            height="48"
            viewBox="0 0 48 48"
        >
            <g
                id="Rectangle_3935"
                data-name="Rectangle 3935"
                fill="#8d6161"
                stroke="#707070"
                strokeWidth="1"
                opacity="0"
            >
                <rect width="48" height="48" stroke="none" />
                <rect x="0.5" y="0.5" width="47" height="47" fill="none" />
            </g>
            <rect
                id="Rectangle_3832"
                data-name="Rectangle 3832"
                width="27.888"
                height="18"
                rx="3"
                transform="translate(10.056 14.5)"
                fill="none"
                stroke={themePrimaryMain}
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
            />
            <path
                id="Path_285"
                data-name="Path 285"
                d="M0,0H0Z"
                transform="matrix(0.761, -0.648, 0.648, 0.761, 30.507, 20.5)"
                fill="none"
                stroke={themePrimaryMain}
                strokeLinecap="round"
                strokeWidth="2"
            />
            <path
                id="Path_286"
                data-name="Path 286"
                d="M0,0H0Z"
                transform="matrix(0.761, -0.648, 0.648, 0.761, 32.133, 20.5)"
                fill="none"
                stroke={themePrimaryMain}
                strokeLinecap="round"
                strokeWidth="2"
            />
            <line
                id="Line_121"
                data-name="Line 121"
                x2="26.888"
                transform="translate(11.056 25.5)"
                fill="none"
                stroke={themePrimaryMain}
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
            />
        </svg>
    );
}

export default SatisfactionImg3;
