import { useTheme } from '@mui/material/styles';
import { getThemePrimaryLight, getThemePrimaryMain } from 'components/common/Utilities';
import * as React from 'react';

export default function SalesSupport(props: any) {
    const theme = useTheme();
    const themePrimaryMain = getThemePrimaryMain(theme);
    const themePrimaryLight = getThemePrimaryLight(theme);

    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={72} height={72} {...props}>
            <g transform="translate(-581.744 -358.188)">
                <rect
                    fill={`${themePrimaryLight}`}
                    width={15.088}
                    height={17.465}
                    rx={7.544}
                    transform="translate(611.647 387.124)"
                />
                <path
                    fill={`${themePrimaryLight}`}
                    d="M614.206 414.345s-2.956-1.809-1.28-4.015 3.038-4.257 3.038-4.257l8.3-.993s1.235 4.412 1.367 4.632 2.027 1.942 2.027 1.942l-.924 3.058-6.441.6"
                />
                <path
                    fill={`${themePrimaryMain}`}
                    d="M617 407.624a12.161 12.161 0 0 1-12.147-12.147v-4.47a12.147 12.147 0 1 1 24.294 0v4.47A12.161 12.161 0 0 1 617 407.624Zm0-25.765a9.158 9.158 0 0 0-9.147 9.148v4.47a9.147 9.147 0 1 0 18.294 0v-4.47a9.158 9.158 0 0 0-9.147-9.148Z"
                />
                <path
                    fill={`${themePrimaryMain}`}
                    d="M634.009 391.89a1.5 1.5 0 0 1-1.5-1.5v-.3a14.949 14.949 0 0 0-14.932-14.932h-1.154a14.949 14.949 0 0 0-14.932 14.932v.3a1.5 1.5 0 0 1-3 0v-.3a17.953 17.953 0 0 1 17.932-17.932h1.154a17.953 17.953 0 0 1 17.932 17.932v.3a1.5 1.5 0 0 1-1.5 1.5ZM624.453 416.212h-14.588a4.687 4.687 0 0 1-3.6-7.688l4.081-4.895a1.5 1.5 0 1 1 2.3 1.922l-4.08 4.894a1.687 1.687 0 0 0 1.3 2.767h14.588a1.688 1.688 0 0 0 1.292-2.773l-3.331-3.963a1.5 1.5 0 1 1 2.3-1.93l3.332 3.964a4.687 4.687 0 0 1-3.589 7.7Z"
                />
                <rect
                    fill={`${themePrimaryMain}`}
                    width={5.735}
                    height={8.956}
                    rx={2.868}
                    transform="translate(595.765 387.874)"
                />
                <rect
                    fill={`${themePrimaryMain}`}
                    width={5.735}
                    height={8.956}
                    rx={2.868}
                    transform="translate(632.559 387.874)"
                />
                <path
                    fill={`${themePrimaryMain}`}
                    d="M619.631 400.051a1.5 1.5 0 0 1-.1-3c2.418-.166 4.2-.913 5.3-2.221a6.348 6.348 0 0 0 1.31-4.282 1.5 1.5 0 0 1 2.981-.333 9.33 9.33 0 0 1-1.965 6.51c-1.645 1.977-4.144 3.093-7.426 3.318a.466.466 0 0 1-.1.008ZM592.016 413.09a1.5 1.5 0 0 1-1.214-2.379l4.9-6.774a1.5 1.5 0 0 1 1-.606l5.389-.791a1.5 1.5 0 1 1 .435 2.969l-4.761.7-4.528 6.261a1.5 1.5 0 0 1-1.221.62ZM638.966 385.646a1.5 1.5 0 0 1-1.009-2.611l5.328-4.844a1.5 1.5 0 1 1 2.018 2.22l-5.329 4.844a1.49 1.49 0 0 1-1.008.391Z"
                />
                <path
                    fill={`${themePrimaryMain}`}
                    d="M646.471 384.686a1.5 1.5 0 0 1-1.5-1.5v-4.65h-4.651a1.5 1.5 0 0 1 0-3h6.151a1.5 1.5 0 0 1 1.5 1.5v6.15a1.5 1.5 0 0 1-1.5 1.5Z"
                />
            </g>
        </svg>
    );
}
