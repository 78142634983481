import React, { useState } from 'react';
import { useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Divider from '@mui/material/Divider';
import { Button } from 'components/Button';
import LoadingOverlay from 'components/common/LoadingOverlay';
import { PaymentConstants } from 'models/financial';
import { useDispatch, useSelector } from 'react-redux';
import { selectCart } from 'store/selectors/cart';
import { Cart, PaymentMethod } from 'models/cart';
import { selectOrder } from 'store/selectors/order';
import { Order } from 'models/order';
import { checkoutGACreditCard } from 'store/actions/gaEcommerceActions';
import { Braintree } from './creditcard/index';
import { HostedFieldsEvent } from 'braintree-web';
import { showBraintreeErrorMsg } from 'components/PaymentUtils';

interface Props {
    brainTreeClientToken?: string;
    amountAlert: boolean;
    placeOrderFunction: (cart: Cart, paymentMethod: PaymentMethod) => void;
    placeFinalOrderFunction: (order: Order, paymentMethod: PaymentMethod, hvFinalOrder?: boolean) => void;
    isOrderBased: boolean;
    amountToPay: number;
    finalInstallment: number;
}
const CreditCard = (props: Props) => {
    const theme = useTheme();
    const [brainTreeReady, setBrainTreeReady] = useState<boolean>(false);
    const [isCreditCardFieldsValid, setCreditCardFieldsValid] = useState(false);
    const {
        brainTreeClientToken,
        amountAlert,
        placeOrderFunction,
        placeFinalOrderFunction,
        isOrderBased,
        amountToPay,
        finalInstallment,
    } = props;
    const cart: Cart = useSelector(selectCart);
    const order = useSelector(selectOrder);
    const [tokenizer, setTokenizeFunc] = useState<any>(null);
    const [requestNonce, setRequestNonce] = useState<boolean>(false);
    const dispatch = useDispatch();
    const handleContinue = async () => {
        try {
            setRequestNonce(true);
            const token = await tokenizer.ref().catch(() => {
                setRequestNonce(false);
            });
            setRequestNonce(false);
            if (token && token.nonce) {
                const paymentMethod = {
                    method: amountToPay ? PaymentConstants.PARTIAL_PAYMENT : PaymentConstants.BRAINTREE,
                    ...(amountToPay && {
                        amount: `${amountToPay}`,
                    }),
                    code: PaymentConstants.BRAINTREE,
                    additionalData: {
                        payment_method_nonce: token.nonce,
                    },
                };
                //method for final (neither first/ nor full) payment-uses Order
                //also for any payment for multipleProjects
                if (isOrderBased) {
                    finalInstallment
                        ? placeFinalOrderFunction(order, paymentMethod, true)
                        : placeFinalOrderFunction(order, paymentMethod);
                }
                //method for first/full payment-uses Cart
                else {
                    placeOrderFunction(cart, paymentMethod);
                }
                dispatch(checkoutGACreditCard());
            }
        } catch (err) {
            // show error
            console.error(err);
        }
    };
    return (
        <Grid container spacing={1}>
            <LoadingOverlay open={!brainTreeReady || requestNonce} />
            <Grid item xs={12}>
                <Typography sx={{ mt: { xs: 2, sm: 3.7 } }} id={'card-info-label'}>
                    <Box component={'b'}>Enter Card Details:</Box>
                </Typography>
                <Divider sx={{ mt: '6px', mb: { xs: 2, sm: 3 } }} />

                <Grid item xs={12}>
                    {brainTreeClientToken && (
                        <Braintree
                            classname={brainTreeReady ? '' : 'disabled'}
                            authorization={brainTreeClientToken}
                            onAuthorizationSuccess={() => {
                                setBrainTreeReady(true);
                            }}
                            getTokenRef={(ref: any) => {
                                setTokenizeFunc({ ref });
                            }}
                            onValidityChange={(event: HostedFieldsEvent) => {
                                let isCreditCardFieldsValid = Object.keys(event.fields).every(function (key) {
                                    // @ts-ignore
                                    return event.fields[key].isValid;
                                });
                                setCreditCardFieldsValid(isCreditCardFieldsValid);
                            }}
                            onError={(err) => {
                                showBraintreeErrorMsg(err);
                                setBrainTreeReady(true);
                            }}
                            styles={{
                                input: {
                                    'font-size': '14px',
                                    'font-family': `${theme.typography.body1.fontFamily}, Roboto, Helvetica, Arial, Sans-serif`,
                                    'font-weight': theme.typography.body1.fontWeight,
                                    color: theme.palette.text.primary,
                                },
                                ':focus': {
                                    color: theme.palette.text.primary,
                                },
                                '.valid': {
                                    color: theme.palette.success.main,
                                },
                                '.invalid': {
                                    color: theme.palette.error.main,
                                },
                            }}
                        />
                    )}
                </Grid>
                <Grid
                    item
                    xs={12}
                    sm={4}
                    md={5}
                    lg={4}
                    sx={{
                        m: {
                            xs: '30px 10px 10px 10px',
                            sm: '24px 24px 24px 0px',
                            md: '32px 24px 10px 0px',
                        },
                    }}
                >
                    <Button
                        isFullWidth={true}
                        id={`payment-next-btn`}
                        color={'primary'}
                        disabled={!tokenizer || amountAlert || !isCreditCardFieldsValid}
                        onClick={handleContinue}
                    >
                        Continue
                    </Button>
                </Grid>
            </Grid>
        </Grid>
    );
};
export default CreditCard;
