import { on, reducer } from 'ts-action';
import {
    setMainSelection,
    setIAQSelection,
    setAddonSelection,
    resetAddonSelection,
    resetSelection,
    resetIAQSelection,
    shippingAddressCaptured,
} from '../actions/cartActions';
import { Cart, CartItem, SystemGroup } from '../../models/cart';
import { ProductCriteria } from 'models/productCriteria';
import { setProductCriteria } from 'store/actions/productCriteriaActions';

export interface PreCart extends Cart {
    skipSelection: boolean;
    itemsIAQ: CartItem[];
    itemsAddOn: CartItem[];
    shippingAddressCaptured: boolean;
    firstSystemCriteria: ProductCriteria;
}
const initialState: PreCart = {
    skipSelection: false,
    itemsIAQ: [],
    itemsAddOn: [],
    shippingAddressCaptured: false,
    firstSystemCriteria: {},
};
export const selectionReducer = reducer(
    initialState,
    on(setMainSelection, (state, action) => ({
        ...state,
        items: action.payload,
        loading: false,
    })),
    on(setIAQSelection, (state, action) => {
        const existingArr = state.itemsIAQ;
        let systemToRemove: CartItem[];
        if (action.payload.systemGroupToSetOrChange === SystemGroup.SystemGroup1) {
            systemToRemove = existingArr.filter((item) => item.systemGroup === SystemGroup.SystemGroup1);
        } else {
            systemToRemove = existingArr.filter((item) => item.systemGroup === SystemGroup.SystemGroup2);
        }
        const removedIaqList = existingArr.filter((item) => !systemToRemove.includes(item));

        return {
            ...state,
            itemsIAQ: [...removedIaqList, action.payload.newIAQ],
            loading: false,
        };
    }),
    on(setAddonSelection, (state, action) => {
        if (action.payload.shouldReplace) {
            return { ...state, itemsAddOn: [...action.payload.addOnsList], loading: false };
        }
        return { ...state, itemsAddOn: [...state.itemsAddOn, ...action.payload.addOnsList], loading: false };
    }),
    on(resetAddonSelection, (state, action) => ({
        ...state,
        itemsAddOn: [],
        loading: false,
    })),
    on(resetIAQSelection, (state, action) => {
        const existingArr = state.itemsIAQ;
        let systemToRemove: CartItem[];
        if (action.payload.systemGroupToReset === SystemGroup.SystemGroup1) {
            systemToRemove = existingArr.filter((item) => item.systemGroup === SystemGroup.SystemGroup1);
        } else {
            systemToRemove = existingArr.filter((item) => item.systemGroup === SystemGroup.SystemGroup2);
        }
        const removedIaqList = existingArr.filter((item) => !systemToRemove.includes(item));

        return {
            ...state,
            itemsIAQ: [...removedIaqList],
            loading: false,
        };
    }),
    on(resetSelection, () => ({
        ...initialState,
    })),
    on(shippingAddressCaptured, (state) => ({
        ...state,
        shippingAddressCaptured: true,
    })),
    on(setProductCriteria, (state, action) => ({
        ...state,
        ...((!state.items || state.items.length === 0) && { firstSystemCriteria: action.payload }),
    }))
);

export default selectionReducer;
