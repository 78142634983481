//Format Two Decimals
export const formatTwoDecimals = (value: number) => {
    return Math.round(value * 100) / 100;
};
export const percentageFormat = (value: number) => {
    if (value > 99.99 && value < 100) {
        return '> 99.99';
    } else if (value < 0.01 && value > 0) {
        return '< 0.01';
    } else {
        return formatTwoDecimals(value);
    }
};
export const calculateRoundedPercent = (value: number) => {
    return Math.round(Number(value.toPrecision(15))) / 100;
};
export const formatFourDecimals = (value: number) => Math.round(value * 10000) / 10000;

export const defaultTaxCalculation = (value: number) => {
    return value * 0.0825;
};
export const financialPriceCalc = (value: number) => {
    return value * 0.0137026;
};
export const currencyFormat = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' });
export const currencyFormatNoDigit = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    maximumFractionDigits: 0,
});
