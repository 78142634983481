import { PaymentMethod } from 'models/cart';
import { Order } from 'models/order';
import http from '../../index';

export const getOrder = (orderId: number) => http().get<Order>(`/orders/${orderId}`);

export const getMyOrders = () => http().get<Order[]>(`/Customer/orders`);
export const latestOrder = () => http().get<Order>(`/Customer/latestOrder`);
export const placeFinalOrder = (orderId: string, paymentMethod?: PaymentMethod) =>
    http().post(`Orders/${orderId}/payment`, paymentMethod); 

export const placeFinalOrderEquipmentOnly = (orderId: string, paymentMethod?: PaymentMethod) =>
http().post(`Orders/${orderId}/equipmentOnlyPayment`, paymentMethod);
