import { useTheme } from '@mui/material/styles';
import { getThemePrimaryMain } from 'components/common/Utilities';
import * as React from 'react';

function SatisfactionImg4(props: any) {
    const theme = useTheme();
    const themePrimaryMain = getThemePrimaryMain(theme);
    return (
        <svg
            id="Component_58_1"
            data-name="Component 58 – 1"
            xmlns="http://www.w3.org/2000/svg"
            width="48"
            height="48"
            viewBox="0 0 48 48"
        >
            <g
                id="Rectangle_3936"
                data-name="Rectangle 3936"
                fill="#8d6161"
                stroke="#707070"
                strokeWidth="1"
                opacity="0"
            >
                <rect width="48" height="48" stroke="none" />
                <rect x="0.5" y="0.5" width="47" height="47" fill="none" />
            </g>
            <path
                id="Path_281"
                data-name="Path 281"
                d="M132.175,352.474v7.276a1.49,1.49,0,0,0,1.264,1.472l13.921,2.138a3.3,3.3,0,0,0,2.485-.625l11.146-8.383a1.778,1.778,0,0,0,.456-2.336h0a1.778,1.778,0,0,0-2.333-.669l-9.486,4.839-6.6-2.35,9.846-3.114a1.384,1.384,0,0,0,.953-1.515h0a1.384,1.384,0,0,0-1.37-1.188H139.1a1.993,1.993,0,0,0-.972.253l-5.193,2.9A1.49,1.49,0,0,0,132.175,352.474Z"
                transform="translate(-122.119 -324.772)"
                fill="none"
                stroke={themePrimaryMain}
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
            />
            <path
                id="Path_282"
                data-name="Path 282"
                d="M162.926,326.616a2.853,2.853,0,0,0-5.594-.791,2.853,2.853,0,0,0-5.594.791c0,3.077,3.907,6.433,5.482,6.433"
                transform="translate(-131.892 -314.586)"
                fill="none"
                stroke={themePrimaryMain}
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
            />
            <path
                id="Path_283"
                data-name="Path 283"
                d="M162.692,342.319"
                transform="translate(-137.364 -321.924)"
                fill="none"
                stroke={themePrimaryMain}
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
            />
            <path
                id="Path_284"
                data-name="Path 284"
                d="M162.692,335.9c1.576,0,5.706-3.357,5.706-6.433"
                transform="translate(-137.364 -317.434)"
                fill="none"
                stroke={themePrimaryMain}
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
            />
        </svg>
    );
}

export default SatisfactionImg4;
