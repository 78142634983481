import { useTheme } from '@mui/material/styles';
import { getThemePrimaryMain } from 'components/common/Utilities';
import * as React from 'react';

function SatisfactionImg1(props: any) {
    const theme = useTheme();
    const themePrimaryMain = getThemePrimaryMain(theme);
    return (
        <svg
            id="Component_55_1"
            data-name="Component 55 – 1"
            xmlns="http://www.w3.org/2000/svg"
            width="48"
            height="48"
            viewBox="0 0 48 48"
        >
            <g id="Rectangle_3933" data-name="Rectangle 3933" fill="#fff" stroke="#707070" strokeWidth="1" opacity="0">
                <rect width="48" height="48" stroke="none" />
                <rect x="0.5" y="0.5" width="47" height="47" fill="none" />
            </g>
            <path
                id="Path_275"
                data-name="Path 275"
                d="M434.581,283.857h-8.852a3.229,3.229,0,0,1-3.229-3.229v-16.9a3.229,3.229,0,0,1,3.229-3.229h16.9a3.229,3.229,0,0,1,3.229,3.229v8.439"
                transform="translate(-411.965 -247.781)"
                fill="none"
                stroke={themePrimaryMain}
                strokeLinecap="round"
                strokeWidth="2"
            />
            <circle
                id="Ellipse_139"
                data-name="Ellipse 139"
                cx="8.054"
                cy="8.054"
                r="8.054"
                transform="translate(22.616 24.8)"
                fill="none"
                stroke={themePrimaryMain}
                strokeLinecap="round"
                strokeWidth="2"
            />
            <path
                id="Path_276"
                data-name="Path 276"
                d="M447.5,281.85v3.626l2.133,2.133"
                transform="translate(-416.83 -251.936)"
                fill="none"
                stroke={themePrimaryMain}
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
            />
            <line
                id="Line_118"
                data-name="Line 118"
                y1="2.837"
                transform="translate(15.994 9.882)"
                fill="none"
                stroke={themePrimaryMain}
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
            />
            <line
                id="Line_119"
                data-name="Line 119"
                y1="2.837"
                transform="translate(28.88 9.882)"
                fill="none"
                stroke={themePrimaryMain}
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
            />
            <line
                id="Line_120"
                data-name="Line 120"
                x2="19.306"
                transform="translate(10.535 19.914)"
                fill="none"
                stroke={themePrimaryMain}
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
            />
        </svg>
    );
}

export default SatisfactionImg1;
