import { useTheme } from '@mui/material/styles';
import { getThemePrimaryLight, getThemePrimaryMain } from 'components/common/Utilities';
import * as React from 'react';

const ArrowDown3 = (props: any) => {
    const theme = useTheme();
    const themePrimaryMain = getThemePrimaryMain(theme);
    const themePrimaryLight = getThemePrimaryLight(theme);
    return (
        <svg baseProfile="tiny-ps" xmlns="http://www.w3.org/2000/svg" width={15} height={15} {...props}>
            <path
                d="M7.5 15A7.49 7.49 0 0 1 0 7.5C0 3.35 3.35 0 7.5 0S15 3.35 15 7.5 11.65 15 7.5 15z"
                style={{
                    fill: `${themePrimaryLight}`,
                }}
            />
            <path
                d="M10 6.46 7.42 9.3 4.83 6.46"
                style={{
                    fill: 'none',
                    stroke: `${themePrimaryMain}`,
                    strokeLinecap: 'round',
                    strokeLinejoin: 'round',
                    strokeWidth: 1.3,
                }}
            />
        </svg>
    );
};

export default ArrowDown3;
