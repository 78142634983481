import * as React from 'react';
import { useTheme } from '@mui/material/styles';
import { getThemePrimaryLight, getThemePrimaryMain } from 'components/common/Utilities';

export default function CustomerSupport(props: any) {
    const theme = useTheme();
    const themePrimaryMain = getThemePrimaryMain(theme);
    const themePrimaryLight = getThemePrimaryLight(theme);
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={72} height={72} {...props}>
            <g transform="translate(-225.913 -358.381)">
                <rect
                    width={28.517}
                    height={35.914}
                    rx={14.259}
                    transform="translate(252.189 379.118)"
                    fill={`${themePrimaryLight}`}
                />
                <path
                    fill={`${themePrimaryMain}`}
                    d="M279.086 394.965a1.5 1.5 0 0 1-1.5-1.5v-3.374a15.233 15.233 0 1 0-30.466 0v3.374a1.5 1.5 0 0 1-3 0v-3.374a18.233 18.233 0 1 1 36.466 0v3.374a1.5 1.5 0 0 1-1.5 1.5Z"
                />
                <path
                    fill={`${themePrimaryMain}`}
                    d="M267.276 413.221a1.5 1.5 0 0 1-.44-2.934 15.155 15.155 0 0 0 10.75-14.563v-3.374a1.5 1.5 0 0 1 3 0v3.374a18.138 18.138 0 0 1-12.868 17.43 1.51 1.51 0 0 1-.442.067ZM248.428 400.255a4.313 4.313 0 0 1-4.308-4.308v-2.384a4.308 4.308 0 1 1 8.615 0v2.384a4.313 4.313 0 0 1-4.307 4.308Zm0-8a1.31 1.31 0 0 0-1.308 1.308v2.384a1.308 1.308 0 1 0 2.615 0v-2.384a1.309 1.309 0 0 0-1.307-1.308Z"
                />
                <path
                    fill={`${themePrimaryMain}`}
                    d="M276.278 400.255a4.313 4.313 0 0 1-4.307-4.308v-2.384a4.307 4.307 0 1 1 8.615 0v2.384a4.313 4.313 0 0 1-4.308 4.308Zm0-8a1.309 1.309 0 0 0-1.307 1.308v2.384a1.307 1.307 0 1 0 2.615 0v-2.384a1.309 1.309 0 0 0-1.308-1.308ZM262.43 416.9a5.063 5.063 0 1 1 5.063-5.062 5.068 5.068 0 0 1-5.063 5.062Zm0-7.125a2.063 2.063 0 1 0 2.063 2.063 2.065 2.065 0 0 0-2.063-2.059Z"
                />
            </g>
        </svg>
    );
}
