import { useTheme } from '@mui/material/styles';
import { getThemePrimaryMain } from 'components/common/Utilities';
import * as React from 'react';

function NoHiddenFee(props: any) {
    const theme = useTheme();
    const themePrimaryMain = getThemePrimaryMain(theme);
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={40} height={40} {...props}>
            <path
                fill={`${themePrimaryMain}`}
                d="M19.896 25.029a9.822 9.822 0 01-7.861-3.978.749.749 0 010-.891 9.821 9.821 0 017.861-3.98 9.692 9.692 0 017.861 3.98.748.748 0 010 .891 9.692 9.692 0 01-7.861 3.978zm-6.3-4.424a8.253 8.253 0 0012.6 0 8.251 8.251 0 00-12.6 0z"
            />
            <path
                fill={`${themePrimaryMain}`}
                d="M19.896 22.496a1.888 1.888 0 111.889-1.888 1.891 1.891 0 01-1.889 1.888zm0-2.276a.388.388 0 10.389.388.389.389 0 00-.389-.391z"
            />
            <path
                fill={`${themePrimaryMain}`}
                d="M16.114 27.511a.749.749 0 01-.566-1.242l10.276-11.821a.75.75 0 011.133.984L16.68 27.254a.746.746 0 01-.566.257z"
            />
            <path
                fill={`${themePrimaryMain}`}
                d="M19.985 33.018a12.509 12.509 0 1112.51-12.509 12.525 12.525 0 01-12.51 12.509zm0-23.518a11.009 11.009 0 1011.01 11.009 11.022 11.022 0 00-11.01-11.013z"
            />
        </svg>
    );
}

export default NoHiddenFee;
