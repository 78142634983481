import { useTheme } from '@mui/material/styles';
import { getThemePrimaryMain } from 'components/common/Utilities';
import iaqimage from 'assets/images/iaq-new.webp';

import * as React from 'react';
function Image(props: any) {
    const theme = useTheme();
    const themePrimaryMain = getThemePrimaryMain(theme);
    return (
        <svg
            data-name="Component 41 \u2013 1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            width={262}
            height={262}
            viewBox="0 0 262 262"
            {...props}
        >
            <defs>
                <pattern id="prefix__a" preserveAspectRatio="none" width="100%" height="100%" viewBox="0 0 818 989">
                    <image width={818} height={989} href={iaqimage} />
                </pattern>
            </defs>
            <g data-name="Group 3449" transform="translate(14.463 3)">
                <ellipse
                    data-name="Ellipse 35"
                    cx={27.5}
                    cy={27}
                    rx={27.5}
                    ry={27}
                    transform="translate(172 39.002)"
                    fill={`${themePrimaryMain}`}
                    opacity={0.4}
                />
                <circle
                    data-name="Ellipse 37"
                    cx={14}
                    cy={14}
                    r={14}
                    transform="translate(0 82.004)"
                    fill={`${themePrimaryMain}`}
                    opacity={0.3}
                />
                <circle
                    data-name="Ellipse 38"
                    cx={3.5}
                    cy={3.5}
                    r={3.5}
                    transform="translate(201 110.004)"
                    fill={`${themePrimaryMain}`}
                    opacity={0.3}
                />
                <path transform="translate(7)" fill="url(#prefix__a)" d="M0 0h188.85v228.818H0z" />
            </g>
        </svg>
    );
}
export default Image;
