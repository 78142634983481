import { RelatedProduct } from 'models/product';
import React from 'react';
import { Theme } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import { Paragraph } from './Paragraph';
import { useTheme } from '@mui/material';
import Counter from './Counter';
import SelectedAddress from './icons/SelectedAddress';
import { Box, CardMedia, useMediaQuery } from '@mui/material';
import striptags from 'striptags';
import { Button } from './Button';
import { SXCssObject } from 'models/ThemeImages';
import { invokeGAForAddonDec, invokeGAForAddonInc } from './Analytics/GoogleAnalyticsTagging';
const createCssObject = (theme: Theme): SXCssObject => ({
    addOnPriceStyle: {
        paddingTop: {
            xs: '4px',
            sm: '8px',
        },
    },
    contentWrapper: {
        padding: '16px',
        border: '1px solid #D4D4D4',
        borderRadius: '4px',
        margin: '9px 9px 0px 0px',
        height: 'calc(100% - 12px)',
        alignItems: 'center',
        '&:hover': {
            border: `1px solid ${theme.palette.action.hover} !important`,
        },
        backgroundColor: theme.palette.common.white,
    },
    productDetailLabel: {
        paddingLeft: {
            xs: theme.spacing(0),
            sm: theme.spacing(1),
            lg: 'inherit',
        },
    },
    img: {
        objectFit: 'contain',
        border: '1px solid #D4D4D4',
        borderRadius: '4px',
    },
    selectedAddOnIconStyle: {
        float: 'right',
    },
    readMoreText: {
        color: theme.palette.primary.main,
        cursor: 'pointer',
        paddingLeft: theme.spacing(0.3),
        '&:hover': {
            color: `${theme.palette.primary.main} !important`,
        },
    },
    imageAndName: {
        display: 'flex',
        alignItems: 'center',
    },
    selectionStyle: {
        border: `1px solid ${theme.palette.action.hover} !important`,
    },
});

interface Props {
    addOnListItem: RelatedProduct;
    width?: 'xs' | 'sm' | 'md' | 'lg' | 'xl';
    index?: number;
    onQuantityChange: (quantity: number, sku: string) => void;
    onAddToCart: (sku: string) => void;
}

function AddOnBoxComponent(props: Props) {
    const cssObject: SXCssObject = createCssObject(useTheme());
    const theme = useTheme();
    const [showFullText, setShowFullText] = React.useState(false);
    const { description, name, price, sku, quantity, image } = props.addOnListItem;
    const isXs = useMediaQuery(theme.breakpoints.only('xs'));
    const isSm = useMediaQuery(theme.breakpoints.down('lg'));
    const getTextBasedOnSize = () => {
        if (isXs) {
            return descriptionContent(50);
        } else if (isSm) {
            return descriptionContent(75);
        } else {
            return descriptionContent(100);
        }
    };

    const toggleFullText = () => {
        setShowFullText(!showFullText);
    };
    const descriptionContent = (trimLength: number) => (
        <Grid sx={cssObject.productDetailLabel}>
            <Paragraph fontSizeVerySmall={true} size={'small'} id={`description-level-${sku}`}>
                {trimLength ? striptags(description).slice(0, trimLength) + '  ' : striptags(description)}
                {!!striptags(description) && (
                    <Paragraph semibold component="span">
                        <Box component={'span'} onClick={toggleFullText} sx={cssObject.readMoreText}>
                            {showFullText ? 'Read less' : 'Read more'}
                        </Box>
                    </Paragraph>
                )}
            </Paragraph>
        </Grid>
    );

    const handleCounterChange = (addOnQuantity: number, sku: string) => {
        if (addOnQuantity > quantity) {
            invokeGAForAddonInc(sku);
        } else {
            invokeGAForAddonDec(sku);
        }
        props.onQuantityChange(addOnQuantity, sku);
    };

    const handleAddToCart = (sku: string) => {
        props.onAddToCart(sku);
    };
    return (
        <Grid item xs={12} sm={6} lg={4}>
            <Grid container>
                <Box>
                    {quantity > 0 && (
                        <Box component={'span'} sx={cssObject.selectedAddOnIconStyle}>
                            {' '}
                            <SelectedAddress />{' '}
                        </Box>
                    )}
                    <Grid item sx={[cssObject.contentWrapper, quantity > 0 && cssObject.selectionStyle]}>
                        <Grid item xs={12} container spacing={1} sx={cssObject.imageAndName}>
                            <Grid item xs={4}>
                                <CardMedia image={image} component={'img'} sx={cssObject.img} />
                            </Grid>
                            <Grid item xs={8}>
                                <Paragraph headingColor semibold>
                                    {name}
                                </Paragraph>
                                <Box sx={cssObject.addOnPriceStyle}>
                                    <Paragraph semibold semiLargeFont headingColor>
                                        ${price}
                                    </Paragraph>
                                </Box>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} style={{ paddingTop: '10px' }}>
                            <div>{showFullText ? descriptionContent(0) : getTextBasedOnSize()}</div>
                        </Grid>

                        <Grid item xs={12} style={{ margin: '16px 0px 8px' }}>
                            {quantity === 0 ? (
                                <Button isContrast onClick={() => handleAddToCart(sku)}>
                                    Add to Cart
                                </Button>
                            ) : (
                                <Counter
                                    quantity={quantity}
                                    onCounterChange={(newQuantity: number) => handleCounterChange(newQuantity, sku)}
                                />
                            )}
                        </Grid>
                    </Grid>
                </Box>
            </Grid>
        </Grid>
    );
}

export default AddOnBoxComponent;
