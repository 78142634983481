import React from 'react';
import sanitizeHtml from 'sanitize-html';
import Grid from '@mui/material/Grid';
import { Button } from 'components/Button';
import ContentContainer from 'components/ContentContainer';
import { useNavigate } from 'react-router-dom';
import { AppRoute } from 'models/route';
import { Paragraph } from '../Paragraph';
import Loading from '../common/Loading';
import { useDispatch, useSelector } from 'react-redux';
import { selectContent, selectContentLoading, selectPromotionCodeBlockId } from '../../store/selectors/storeSelector';
import Container from '@mui/material/Container';
import { setPromotionCode } from '../../store/actions/productCriteriaActions';
import { resetSelection } from 'store/actions/cartActions';
import { resetProductCriteria } from 'store/actions/productCriteriaActions';
import { Box, Theme, useMediaQuery, useTheme } from '@mui/material';
import { Typography } from '@mui/material';
import HomeScheduleBg from 'components/icons/HomeScheduleBg';
import { SXCssObject } from 'models/ThemeImages';

const PromotionContent: React.FC = () => {
    const navigate = useNavigate();

    const dispatch = useDispatch();
    const loading = useSelector(selectContentLoading);
    const contentBlock = useSelector(selectContent);
    const promotionCode = useSelector(selectPromotionCodeBlockId);
    const cssObject = createCssObject(useTheme());
    const theme = useTheme();
    const downXs = useMediaQuery(theme.breakpoints.only('xs'));
    const downSm = useMediaQuery(theme.breakpoints.down('md'));
    const isXs = useMediaQuery('(max-width:599px)');

    return (
        <ContentContainer disableGrid>
            <Container maxWidth={'lg'} disableGutters>
                <ContentContainer sx={cssObject.rootPadding}>
                    {loading && <Loading />}
                    <Box sx={cssObject.headingStyle}>
                        <Typography
                            variant={downXs ? 'h4' : downSm ? 'h3' : 'h2'}
                            sx={cssObject.centerContent}
                            align="center"
                        >
                            Schedule Your Installation{' '}
                        </Typography>
                    </Box>
                    {contentBlock?.content && (
                        <Grid container spacing={4} justifyContent={'center'}>
                            <Grid item xs={12} sm={9} lg={9} sx={cssObject.content}>
                                <Paragraph responsiveFontSize18 align={'center'}>
                                    <SanitizeHTML html={contentBlock.content} />
                                </Paragraph>
                            </Grid>
                            <Grid item xs={12} sx={cssObject.centerContent}>
                                <Button
                                    id={'sub-content-get-started-btn'}
                                    color={'primary'}
                                    isFullWidth={isXs}
                                    isContrast
                                    homePageLargeButton
                                    onClick={() => {
                                        dispatch(setPromotionCode({ promotionCode: promotionCode }));
                                        dispatch(resetSelection());
                                        dispatch(resetProductCriteria());
                                        navigate(AppRoute.AboutYourHome);
                                    }}
                                >
                                    Let's Get Started
                                </Button>
                            </Grid>
                        </Grid>
                    )}
                    <Box sx={cssObject.scheduleWrapper}>
                        <HomeScheduleBg className={cssObject.scheduleIconBg} />
                    </Box>
                </ContentContainer>
            </Container>
        </ContentContainer>
    );
};

interface SanitizeProps {
    html: string;
    options?: any;
}

const sanitize = (dirty: string, options: any) => ({
    __html: sanitizeHtml(dirty, options),
});

const createCssObject = (theme: Theme): SXCssObject => ({
    rootPadding: {
        paddingTop: theme.spacing(2),
        px: theme.spacing(2),
        overflow: 'hidden',
        position: {
            xs: 'relative',
            sm: 'inherit',
        },
    },
    headingStyle: {
        marginBottom: {
            xs: '12px',
            sm: '16px',
        },
    },
    scheduleWrapper: {
        display: 'flex',
        justifyContent: 'flex-end',
        width: {
            xs: '65%',
            sm: '42%',
        },
        margin: {
            xs: '-25% 0% -5px -35%',
            sm: '-7% 0px -5px auto',
        },
    },
    scheduleIconBg: {
        objectFit: {
            sm: 'cover',
            md: 'contain',
        },
        objectPosition: {
            sm: 'left',
            md: 'right',
        },
        margin: {
            xs: '-30% 0% -5px -53%',
            sm: '-9% -40px 0px 0%',
            md: '-5% 0px -5px -4%',
        },
        width: {
            xs: '100%',
            sm: '50%',
            md: '100%',
            lg: 652,
        },
        height: {
            xs: '100px',
            sm: '190px',
            md: '200px',
            lg: 262,
        },
        opacity: {
            xs: 1,
            sm: 0.2,
            md: 1,
        },
    },
    centerContent: {
        display: 'flex',
        justifyContent: 'center',
        paddingBottom: theme.spacing(2),
    },
    content: {
        '& p': {
            margin: '0px 10px',
        },
    },
});

const SanitizeHTML: React.FC<SanitizeProps> = ({ html, options }) => {
    const cssObject = createCssObject(useTheme());
    return <Box component={'span'} dangerouslySetInnerHTML={sanitize(html, options)} sx={cssObject.content} />;
};

export default PromotionContent;
