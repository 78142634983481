import { useTheme } from '@mui/material/styles';
import { getThemePrimaryMain } from 'components/common/Utilities';
import * as React from 'react';

const PlayButton = (props: any) => {
    const theme = useTheme();
    const themePrimaryMain = getThemePrimaryMain(theme);

    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={68} height={68} {...props}>
            <g data-name="Group 3481">
                <g data-name="Component 98 \u2013 1">
                    <g data-name="Group 3457" transform="translate(1 1)">
                        <circle data-name="Ellipse 210" cx={33} cy={33} r={33} fill="#fff" />
                        <path
                            data-name="Polygon 1"
                            d="M39.848 32.684a1 1 0 0 1 0 1.632l-9.27 6.566A1 1 0 0 1 29 40.066V26.934a1 1 0 0 1 1.578-.816Z"
                            fill={`${themePrimaryMain}`}
                        />
                        <g
                            data-name="Ellipse 211"
                            transform="translate(9 9)"
                            fill="none"
                            stroke={`${themePrimaryMain}`}
                        >
                            <circle cx={24} cy={24} r={24} stroke="none" />
                            <circle cx={24} cy={24} r={23.5} />
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    );
};
export default PlayButton;
