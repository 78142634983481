import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import EquipmentOptions from './EquipmentOptions';
import { Cart, CartItem, ItemType, SystemGroup } from '../../models/cart';
import {
    isCartForEquipmentOnly,
    selectCart,
    selectCartProcessing,
    selectFirstSystemMain,
    selectProceedToCart,
    selectSecondSystemMain,
} from '../../store/selectors/cart';
import {
    addCartItem,
    changeOrRemoveProduct,
    createCart,
    deleteCart,
    redirectToCartPage,
    setAddonSelection,
    setMainSelection,
} from '../../store/actions/cartActions';
import { selectProductLoading, selectProducts, selectSecondSysProducts } from '../../store/selectors/productSearch';
import { selectProductCriteriaState } from '../../store/selectors/productCriteria';
import { AppRoute } from 'models/route';
import { Navigate, useNavigate, useLocation } from 'react-router-dom';
import { selectPreCartMain, selectPreCartAddon } from 'store/selectors/selection';
import { Product, RelatedProduct } from 'models/product';
import { addressState } from 'store/selectors/addressSelector';
import {
    invokeGAContinueRecommendationsPage,
    invokeGAShopIAQRecommendationsPage,
} from 'components/Analytics/GoogleAnalyticsTagging';
import { TypeOfService } from 'models/productCriteria';

function EquipmentOptionContainer() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const criteria = useSelector(selectProductCriteriaState);
    const products = useSelector(selectProducts);
    const productsSecondSystem = useSelector(selectSecondSysProducts);
    const loading = useSelector(selectCartProcessing);
    const addressData = useSelector(addressState);
    const preCartMain: CartItem[] = useSelector(selectPreCartMain);
    const preCartAddOn: CartItem[] = useSelector(selectPreCartAddon);
    const isproductSearchLoading: boolean = useSelector(selectProductLoading);
    const location: any = useLocation();
    const cart: Cart = useSelector(selectCart);
    const firstSystemMain = useSelector(selectFirstSystemMain);
    const secondSystemMain = useSelector(selectSecondSystemMain);
    const isEquipmentOnlyCart = useSelector(isCartForEquipmentOnly);

    const { items: cartItems } = cart;
    const proceedToCart = useSelector(selectProceedToCart);
    if ((!products || products.length <= 0) && (!productsSecondSystem || productsSecondSystem.length <= 0)) {
        return <Navigate to={AppRoute.Home} />;
    }
    const secondSystem = location.state && location.state.isSecondSystem;
    const changingSystemGroup = location.state && location.state.changingSystemGroup;
    const onBackSystemGroup = location.state && location.state.onBackSystemGroup;

    const isByClickingChangeinSelection = location.state && location.state.isByClickingChangeinSelection;
    const isAddSystemFromCartFlow = location.state && location.state.isAddSystemFromCartPage;
    const isByClickingChangeInCartPage = location.state && location.state && location.state.isByClickingChange;
    const isByClickingEditInWizardPage = location.state && location.state && location.state.fromEditInWizard;
    const cartItemMain = cartItems?.find(
        (cartItem) => cartItem.itemType === ItemType.MainSystem && cartItem.systemGroup === onBackSystemGroup
    );
    const preCartItemMain = preCartMain?.find(
        (cartItem) => cartItem.itemType === ItemType.MainSystem && cartItem.systemGroup === onBackSystemGroup
    );
    const sku = proceedToCart ? cartItemMain && cartItemMain.sku : preCartItemMain && preCartItemMain.sku;

    /**
    * IF cartMainSku is true, 
    *      then user has come to this page, by clicking change in Cart
    * ELSE
    *      user has come through wizard.
    * */

    const isEquipmentOnly = isByClickingChangeInCartPage ? (isEquipmentOnlyCart) : (criteria.typeOfService === TypeOfService.EquipmentOnly);

    const replaceOrSetMain = (itemMain: CartItem) => {
        let precartMainSysList = preCartMain;
        let oldSys: CartItem[], filteredIAQItems: CartItem[];
        if (changingSystemGroup === SystemGroup.SystemGroup1 || !secondSystem) {
            oldSys = precartMainSysList.filter((item) => item.systemGroup === SystemGroup.SystemGroup1);
        } else {
            oldSys = precartMainSysList.filter((item) => item.systemGroup === SystemGroup.SystemGroup2);
        }
        filteredIAQItems = precartMainSysList.filter((item) => !oldSys.includes(item));
        let newMainSelection = [...filteredIAQItems, itemMain];
        dispatch(setMainSelection(newMainSelection));
        return newMainSelection;
    };
    const setMainItem = (product: Product) => {
        return {
            categories: product.categories || [],
            name: product.name,
            sku: product.sku,
            optionId: product.options?.optionId,
            optionTypeId: product.options?.optionTypeId,
            quantity: 1,
            id: product.id,
            description: product.description,
            image: product.image,
            price: product.price,
            thumbnail: product.thumbnail,
            totalPrice: product.price,
            productAttributes: product.productAttributes,
            relatedProducts: product.relatedProducts,
            systemGroup:
                changingSystemGroup === SystemGroup.SystemGroup2 || secondSystem
                    ? SystemGroup.SystemGroup2
                    : SystemGroup.SystemGroup1,
            itemType: ItemType.MainSystem,
        };
    };

    const getUniqueAddOnsFromProduct = (addOnRelatedProducts: RelatedProduct[]) => {
        const uniqueValuesSet = new Set();
        const uniqueAddons = addOnRelatedProducts?.filter((obj) => {
            const isPresentInSet = uniqueValuesSet.has(obj.sku);
            uniqueValuesSet.add(obj.sku);
            return !isPresentInSet;
        });
        const nonIAQAddons =
            uniqueAddons &&
            uniqueAddons.filter((uniqueAddon) => {
                return uniqueAddon && uniqueAddon.sku && uniqueAddon.sku.indexOf('IAQ') === -1;
            });
        return nonIAQAddons;
    };
    const handleOnSelectProduct = (product: Product, isByClickingChangeinSelection: boolean) => {
        const selectedMainItem = setMainItem(product);
        const updatedPreCartMain = replaceOrSetMain(selectedMainItem);
        if (isByClickingChangeinSelection || secondSystem) {
            //change in selection & wizard second system flows.
            let combinedRelatedProducts: RelatedProduct[] = [];
            updatedPreCartMain.forEach((mainProduct) => {
                mainProduct.relatedProducts.forEach((item) => {
                    const isAddonInSelectionReducer = preCartAddOn.find((addOn) => addOn.sku === item.sku);
                    item.quantity = (isAddonInSelectionReducer && isAddonInSelectionReducer.quantity) || 0;
                    combinedRelatedProducts.push(item);
                });
            });
            let nonIAQAddons = getUniqueAddOnsFromProduct(combinedRelatedProducts);
            dispatch(setAddonSelection({ addOnsList: [...nonIAQAddons], shouldReplace: true }));
        } else {
            //wizard flow
            const nonIaqAddOns = getUniqueAddOnsFromProduct(product.relatedProducts);
            nonIaqAddOns.forEach((item) => {
                item.quantity = 0;
            });
            dispatch(setAddonSelection({ addOnsList: [...nonIaqAddOns], shouldReplace: true }));
        }
    };
    const addOrReplaceSystemInCart = (selectedMainItem: CartItem) => {
        if (secondSystem) {
            const secondSystemAlreadyInCart = secondSystemMain;
            secondSystemAlreadyInCart
                ? replaceSystemInCart(secondSystemAlreadyInCart.id, selectedMainItem)
                : dispatch(addCartItem({ cartItems: [selectedMainItem], shouldBlockSaveForLaterApi: true }));
        } else {
            if (isAddSystemFromCartFlow) {
                selectedMainItem.systemGroup = SystemGroup.SystemGroup2;
                dispatch(addCartItem({ cartItems: [selectedMainItem], shouldBlockSaveForLaterApi: true }));
            }
            dispatch(deleteCart({ cartItems: [selectedMainItem], shouldBlockSaveForLaterApi: false }));
        }
    };

    const replaceSystemInCart = (itemToRemove: number, itemToAdd: CartItem) => {
        dispatch(
            changeOrRemoveProduct({
                itemToRemove: itemToRemove,
                itemToAdd: itemToAdd,
                isRemoveItemOnly: false,
                shouldRedirectToCartPage: isByClickingChangeInCartPage,
            })
        );
    };
    const shouldShowSecondSysProducts =
        (changingSystemGroup && changingSystemGroup === SystemGroup.SystemGroup2) || secondSystem;
    return (
        <EquipmentOptions
            //based on the flag we will send the respective products as props
            products={shouldShowSecondSysProducts ? productsSecondSystem : products}
            isSecondSystemFlow={secondSystem}
            criteria={criteria}
            isEquipmentOnly={isEquipmentOnly}
            loading={loading || isproductSearchLoading || addressData.loading} //loading will be true when cart api is in progress or product search in progress
            cartMainSku={isByClickingChangeInCartPage}
            selectionMainSku={isByClickingChangeinSelection}
            editMainProductSku={isByClickingEditInWizardPage}
            mainSku={sku}
            onSelectProduct={(product: Product) => {
                invokeGAContinueRecommendationsPage();
                const selectedMainItem = setMainItem(product);
                handleOnSelectProduct(product, isByClickingChangeinSelection);
                if (isByClickingChangeInCartPage) {
                    //Change click in cart - SKIP Selection Page--Directly taking to cart page
                    handleContinueClickForChangeScenario(product);
                    return;
                } else if (isByClickingEditInWizardPage) {
                    handleEditClick(product);
                    return;
                } else {
                    //Wizard flow
                    handleWizardFlow(selectedMainItem);
                }
            }}
            onContinue={() => {
                if (isByClickingChangeInCartPage) {
                    dispatch(redirectToCartPage);
                } else if (isByClickingChangeinSelection) {
                    navigate(AppRoute.EquipmentSelection, {
                        state: { isSecondSystem: secondSystem || changingSystemGroup === SystemGroup.SystemGroup2 },
                    });
                } else {
                    //Wizard flow
                    navigate(AppRoute.EquipmentSelection, { state: { isSecondSystem: secondSystem } });
                }
            }}
            onSelectIAQ={() => {
                invokeGAShopIAQRecommendationsPage();
                if (isByClickingChangeInCartPage) {
                    navigate(AppRoute.IAQOptions, {
                        state: {
                            systemGroup:
                                secondSystem || isAddSystemFromCartFlow
                                    ? SystemGroup.SystemGroup2
                                    : SystemGroup.SystemGroup1,
                            isSecondSystem: secondSystem,
                            isByClickingChangeInCartPage: isByClickingChangeInCartPage,
                            isFullSystem: 'isFullSystem'
                        },
                    });
                } else if (isByClickingChangeinSelection) {
                    navigate(AppRoute.IAQOptions, {
                        state: {
                            systemGroup:
                                secondSystem || isAddSystemFromCartFlow
                                    ? SystemGroup.SystemGroup2
                                    : SystemGroup.SystemGroup1,
                            isSecondSystem: secondSystem,
                            isByClickingChangeinSelection: isByClickingChangeinSelection,
                            isFullSystem: 'isFullSystem'
                        },
                    });
                } else {
                    //Wizard flow
                    navigate(AppRoute.IAQOptions, {
                        state: {
                            systemGroup:
                                secondSystem || isAddSystemFromCartFlow
                                    ? SystemGroup.SystemGroup2
                                    : SystemGroup.SystemGroup1,
                            isSecondSystem: secondSystem,
                            isFullSystem: 'isFullSystem'
                        },
                    });
                }
            }}
        />
    );

    function handleWizardFlow(selectedMainItem: any) {
        if (proceedToCart) {
            if (!cart.cartId) {
                dispatch(createCart({ cartItems: [selectedMainItem], shouldBlockSaveForLaterApi: false }));
            } else {
                addOrReplaceSystemInCart(selectedMainItem);
            }
        }
    }

    function handleContinueClickForChangeScenario(product: any) {
        const selectedMainItem = setMainItem(product);
        replaceOrSetMain(selectedMainItem);
        const itemToDelete = cartItems?.find(
            (item) => item.systemGroup === changingSystemGroup && item.itemType === ItemType.MainSystem
        );
        dispatch(
            changeOrRemoveProduct({
                itemToRemove: itemToDelete!.id,
                itemToAdd: selectedMainItem,
                isRemoveItemOnly: false,
                shouldRedirectToCartPage: false,
            })
        );
    }
    function handleEditClick(product: any) {
        const selectedMainItem = setMainItem(product);
        replaceOrSetMain(selectedMainItem);
        const itemToDelete = firstSystemMain;
        dispatch(
            changeOrRemoveProduct({
                itemToRemove: itemToDelete!.id,
                itemToAdd: selectedMainItem,
                isRemoveItemOnly: false,
                shouldRedirectToCartPage: false,
            })
        );
    }
}

export default EquipmentOptionContainer;
