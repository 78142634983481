import React from 'react';
import { Theme } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import { Paragraph } from 'components/Paragraph';
import { Button } from 'components/Button';
import ArrowRightAltIcon from '../../assets/icons/icon_right.svg';
import { Box, useMediaQuery, useTheme } from '@mui/material';
import { SXCssObject } from 'models/ThemeImages';

const createCssObject = (theme: Theme): SXCssObject => ({
    heading: {
        paddingBottom: theme.spacing(2),
    },
    heading1: {
        borderRadius: '8px',
        marginBottom: '10px',
        display: 'inline',
        color: theme.typography.h1.color,
        boxDecorationBreak: 'clone',
        WebkitBoxDecorationBreak: 'clone',
        fontFamily: theme.typography.h1.fontFamily, // variable
        fontWeight: 700, // variable
        letterSpacing: 0,
        textTransform: 'inherit',
        fontSize: {
            xs: '1.875rem',
            sm: '2.5rem',
            md: '3.125rem',
        },
        lineHeight: {
            xs: '2.5rem',
            sm: '3.125rem',
            md: '3.438rem',
        },
    },
    description: {
        color: '#686868',
        paddingBottom: {
            sm: theme.spacing(4),
        },
        maxWidth: {
            md: '356px',
        },
    },
    arrowRightAltIcon: {
        width: '20px',
        height: '20px',
        margin: '0px -5px 0px 8px',
        position: 'relative',
        right: '-20px',
    },
    alignContent: {
        marginTop: {
            xs: theme.spacing(2),
            sm: 'inherit',
        },
    },
    buttonLabel: {
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%',
        alignItems: 'center',
    },
    infoIconStyle: {
        border: '1px solid #77A74A',
        borderRadius: '20px',
        marginLeft: '-50px',
        padding: '5px',
        width: '36px',
        cursor: 'pointer',
    },
});
interface Props {
    buttonText: string;
    onClick: () => void;
}
function HeadLine(props: Props) {
    const cssObject: SXCssObject = createCssObject(useTheme());
    const isXs = useMediaQuery('(max-width:599px)');

    return (
        <Box>
            <Grid container>
                <Grid item container xs={12} sm={10} md={9}>
                    <Grid item xs={12} sx={cssObject.heading}>
                        <Box component={'span'} sx={cssObject.heading1}>
                            Looking for a new <br /> HVAC system?
                        </Box>
                    </Grid>
                    <Grid item xs={10} lg={8} xl={6} sx={cssObject.description}>
                        <Paragraph id={'head-line-paragraph'} responsiveFontSize18>
                            We’ve made it simple for you to get the best total installed price available and it only
                            takes a few minutes!
                        </Paragraph>
                    </Grid>
                    <Grid xs={12} item sx={cssObject.alignContent}>
                        <Button
                            id={'head-line-get-started-btn'}
                            color={'primary'}
                            isFullWidth={isXs}
                            homePageLargeButton
                            onClick={props.onClick}
                        >
                            <Box component={'span'} sx={cssObject.buttonLabel}>
                                {props.buttonText}
                                <Box
                                    component={'img'}
                                    src={ArrowRightAltIcon}
                                    alt="right arrow"
                                    sx={cssObject.arrowRightAltIcon}
                                />
                                {/* <ArrowRightHome /> */}
                            </Box>
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
        </Box>
    );
}

export default HeadLine;
