import React from 'react';
import homeMarqueeImage from '../../assets/images/airandheatdoneright/aahdr_marquee-image.webp';
import homeMarqueeImageMobile from '../../assets/images/airandheatdoneright/aahdr_marquee-image_mobile.webp';
import homeMarqueeImageTab from '../../assets/images/airandheatdoneright/aahdr_marquee-image_tab.webp';

import ContentContainer from 'components/ContentContainer';
import StoreNavigator from 'components/airandheatdoneright/StoreNavigator';
import HeadLine from 'components/airandheatdoneright/HeadLine';
import Footer from 'components/Footer';
import NavigateSite from 'components/airandheatdoneright/NavigateSite';
import { ThemeWithAdditionalData } from '../../themes/theme';
import Grid from '@mui/material/Grid';
import { Box, useMediaQuery, useTheme } from '@mui/material';
import { useLocation } from 'react-router-dom';
import { SXCssObject } from 'models/ThemeImages';
const createCssObject = (theme: ThemeWithAdditionalData): SXCssObject => ({
    root: {
        overflowX: 'hidden',
    },
    headingTextContainer: {
        position: {
            xs: 'relative',
            sm: 'inherit',
        },
        content: {
            xs: "''",
            sm: 'inherit',
        },
        background: {
            xs: theme.palette.common.white,
            sm: 'inherit',
        },
        width: {
            xs: '100%',
            sm: 'inherit',
        },
        height: {
            xs: '168px',
            sm: 'inherit',
        },
        zIndex: {
            xs: -1,
            sm: 'inherit',
        },
    },
    marquee: {
        backgroundRepeat: 'no-repeat',
        height: {
            xs: '520px',
            sm: 'calc((100vw - 20px) * 0.9)',
            md: '700px',
            lg: '1100px',
            xl: '1200px',
        },
        
        background: {
            xs: `transparent url(${homeMarqueeImageMobile}) 0% 0% no-repeat`,
            sm: `transparent url(${homeMarqueeImageTab}) 0% 0% no-repeat`,
            md: `transparent url(${homeMarqueeImage}) 0% 0% no-repeat`,
        },
        backgroundPosition: {
            xs: 'bottom',
            sm: 'top',
            md: 'right bottom',
        },
        backgroundSize: {
            xs: 'cover !important',
            sm: '100% 100% !important',
            md: 'cover !important',
        },
    },
    arrowRightAltIcon: {
        color: '#73AD49',
        width: '20px',
        height: '20px',
        margin: '2px -5px 0px 5px',
    },
    heading: {
        paddingBottom: theme.spacing(2),
    },
    headline: {
        position: 'absolute',
        top: '80px',
        paddingTop: {
            xs: '20px',
            md: '40px',
            lg: '80px',
        },
        paddingBottom: {
            xs: '20px',
            md: 'inherit',
        },
    },
    headerContainer: {
        borderRadius: {
            xs: '0px 0px 15px 15px',
            sm: 'inherit',
        },
        boxShadow: {
            xs: '0px 8px 18px #0000000D',
            sm: 'inherit',
        },
    },
});

export function Index(props: any) {
    const cssObject = createCssObject(useTheme());
    const theme = useTheme();
    const isXs = useMediaQuery(theme.breakpoints.only('xs'));
    const navigateSiteRef = React.useRef<HTMLInputElement>(null);
    const handleRegionScroll = () => {
        navigateSiteRef.current &&
            window.scroll({
                top: isXs ? navigateSiteRef.current.offsetTop - 140 : navigateSiteRef.current.offsetTop,
                behavior: 'smooth',
            });
    };
    const location: any = useLocation();
    const scrollTo: boolean = location.state && location.state.scrollToStores;
    return (
        <Box sx={cssObject.root}>
            <ContentContainer disableGrid>
                {isXs && (
                    <ContentContainer background={'bg3'}>
                        <StoreNavigator color={'primary'} />
                    </ContentContainer>
                )}
                <Box>
                    <Box sx={cssObject.headingTextContainer} />
                    <ContentContainer sx={cssObject.headline}>
                        <HeadLine onClick={handleRegionScroll} buttonText={'select your region'} />
                    </ContentContainer>
                    <Box sx={cssObject.marquee}>
                        {!isXs && (
                            <ContentContainer>
                                <StoreNavigator color={'primary'} />
                            </ContentContainer>
                        )}
                    </Box>
                </Box>
            </ContentContainer>
            <Grid ref={navigateSiteRef}>
                <NavigateSite scrollTo={scrollTo} />
            </Grid>
            <Footer />
        </Box>
    );
}

export default Index;
