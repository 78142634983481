import React, { Fragment, useRef } from 'react';
import { Theme } from '@mui/material/styles';
import SubPage from 'components/subpage/SubPage';
import Grid from '@mui/material/Grid';
import { Heading } from 'components/Heading';
import { Paragraph } from 'components/Paragraph';
import { Menu } from 'components/SecondaryNavigation';
import { Props as MenuProps } from 'components/SecondaryNavigation';
import { useMediaQuery, useTheme } from '@mui/material';
import Green from 'components/icons/Green';
import Grey from 'components/icons/Grey';
import { CommonLink } from 'components/Link';
import { AppRoute } from 'models/route';
import { SXCssObject } from 'models/ThemeImages';

function TermAndConditions() {
    const createCssObject = (theme: Theme): SXCssObject => {
        return {
            contentHeading: {
                paddingBottom: theme.spacing(1),
            },
            contentSecondaryHeading: {
                paddingTop: theme.spacing(4),
            },
            contentImage: {
                paddingTop: theme.spacing(2),
            },
            content: {
                paddingTop: theme.spacing(2),
            },
            disclaimer: {
                marginTop: theme.spacing(3),
                borderTop: `3px solid ${theme.palette.primary.main}`,
                borderBottom: `3px solid ${theme.palette.primary.main}`,
            },
        };
    };
    const [id, setId] = React.useState('introduction');
    const theme = useTheme();
    const smDown = useMediaQuery(theme.breakpoints.down('md'));
    const xsDown = useMediaQuery(theme.breakpoints.only('xs'));

    function getTermsMenus(selectedMenu?: string) {
        const menus: Menu[] = [
            {
                selected: false,
                id: 'introduction',
                label: `INTRODUCTION`,
                color: 'primary',
                ref: introRef,
            },
            {
                selected: false,
                id: 'access',
                label: `ACCESSING & USING THE SITES`,
                color: 'primary',
                ref: accessRef,
            },
            {
                selected: false,
                id: `disclaimers`,
                color: 'primary',
                label: `DISCLAIMERS`,
                ref: disclaimerRef,
            },
            {
                selected: false,
                id: 'content',
                label: `OUR CONTENT`,
                color: 'primary',
                ref: contentRef,
            },
            {
                selected: false,
                id: 'submission',
                label: `Your Submissions`,
                color: 'primary',
                ref: submissionRef,
            },
            {
                selected: true,
                id: 'copyright',
                label: `DIGITAL MILLENNIUM COPYRIGHT ACT`,
                color: 'primary',
                ref: copyRightRef,
            },
            {
                selected: false,
                id: 'third_party',
                label: 'THIRD-party CONTENT, LINKS',
                color: 'primary',
                ref: thirdPartyRef,
            },
            {
                selected: false,
                id: 'privacy',
                label: 'Privacy',
                color: 'primary',
                ref: privacyRef,
            },
            {
                selected: false,
                id: 'site_access',
                label: 'Site Access and Termination',
                color: 'primary',
                ref: siteAccessRef,
            },
            {
                selected: false,
                id: 'about',
                label: 'About these Terms',
                color: 'primary',
                ref: aboutRef,
            },
        ];
        return menus.map((m) => ({
            ...m,
            selected: m.id === selectedMenu,
        }));
    }
    const cssObject: SXCssObject = createCssObject(useTheme());
    const introRef = useRef<HTMLDivElement>(null);
    const accessRef = useRef<HTMLDivElement>(null);
    const disclaimerRef = useRef<HTMLDivElement>(null);
    const contentRef = useRef<HTMLDivElement>(null);
    const submissionRef = useRef<HTMLDivElement>(null);
    const copyRightRef = useRef<HTMLDivElement>(null);
    const thirdPartyRef = useRef<HTMLDivElement>(null);
    const privacyRef = useRef<HTMLDivElement>(null);
    const siteAccessRef = useRef<HTMLDivElement>(null);
    const aboutRef = useRef<HTMLDivElement>(null);

    function menu(id: string): MenuProps {
        return {
            id: 'menu',
            label: 'Terms and conditions',
            color: 'primary',
            subMenus: getTermsMenus(id),
        };
    }
    const handleSelect = (id: string, ref: React.RefObject<HTMLDivElement>) => {
        setId(id);
        //ref && ref.current && ref.current.scrollIntoView({ behavior: 'smooth' });
        if (ref.current) {
            if (xsDown) {
                window.scroll({
                    top: ref.current.offsetTop - 450,
                    behavior: 'smooth',
                });
            } else if (smDown) {
                window.scroll({
                    top: ref.current.offsetTop - 400,
                    behavior: 'smooth',
                });
            } else {
                ref.current && ref.current.scrollIntoView({ behavior: 'smooth' });
            }
        }
        //ref && ref.current && ref.current.scrollIntoView();
    };

    return (
        <SubPage
            menu={menu(id)}
            onSelect={handleSelect}
            content1={
                <Fragment>
                    <Grid item xs={12} sx={cssObject.contentHeading} ref={introRef}>
                        <Heading isPopUp color={'primary'} label={'Terms Of Use Of This Website'} />
                    </Grid>
                    <Grid item xs={12}>
                        <Paragraph>Last Revised: November 12, 2021</Paragraph>
                    </Grid>
                    <Grid item xs={12} sx={cssObject.content}>
                        <Paragraph align={'left'} size={'normal'}>
                            Thank you for your interest in our products and services. We hope you’ll find our websites
                            informative and helpful.
                        </Paragraph>
                    </Grid>
                    <Grid item xs={12} sx={cssObject.disclaimer}>
                        <br />
                        <Paragraph semibold size={'normal'}>
                            CAREFULLY REVIEW THE FOLLOWING TERMS OF USE THAT, AMONG OTHER THINGS, INCLUDE IN SECTION 2
                            LIMITATIONS ON OUR LIABILITY, A CLASS-ACTION WAIVER, AND OTHER TERMS THAT WILL AFFECT HOW
                            DISPUTES BETWEEN YOU AND US ARE RESOLVED.
                        </Paragraph>
                        <br />
                    </Grid>

                    <Grid item xs={12} sx={cssObject.content}>
                        <Paragraph align={'left'} size={'normal'}>
                            These Terms of Use (“Terms”) apply to the websites located at
                            i)https://airandheatdoneright.com, ii) any of our associated websites that may be linked to
                            them, and iii) any content, information, data, results, or services provided on or by those
                            websites (collectively the “Sites”). These Terms, which govern your use of the Sites, are a
                            binding legal agreement between you and any entity you represent (collectively “you” or
                            “your” or “yourself”), on the one hand, and Air and Heat Done Right (collectively “us” or
                            “we” or “our”), on the other hand.
                        </Paragraph>
                    </Grid>
                    <Grid item xs={12} sx={cssObject.content}>
                        <Paragraph align={'left'} size={'normal'}>
                            Some of the Sites may have additional posted terms and conditions that govern your use of
                            those Sites or may require you to enter into a written agreement (e.g., an end-user license
                            agreement) with us. Any terms in a separate written agreement or additional posted terms
                            associated with a Site will become a part of these Terms and, thus, part of your agreement
                            with us. To the extent there is a conflict, terms of the separate written agreement control,
                            then additional posted terms, and then these Terms.
                        </Paragraph>
                    </Grid>
                    <Grid item xs={12} sx={cssObject.disclaimer}>
                        <br />
                        <Paragraph semibold size={'normal'}>
                            BY ACCESSING OR USING THE SITES, YOU AGREE TO BE BOUND BY THESE TERMS —WITHOUT LIMITATION OR
                            QUALIFICATION. IF YOU DO NOT AGREE, YOU MUST NOT ACCESS OR USE THE SITES. AND BY AGREEING TO
                            THE TERMS, YOU REPRESENT AND WARRANT THAT YOU HAVE THE CAPACITY AND AUTHORITY TO BIND
                            YOURSELF AND ANY ENTITY THAT YOU MAY REPRESENT.
                        </Paragraph>
                        <br />
                    </Grid>
                    <Grid item xs={12} sx={cssObject.content}>
                        <Paragraph align={'left'} size={'normal'}>
                            We also reserve the right, at our sole discretion, to modify these Terms at any time. You
                            are responsible for checking these Terms for modifications. And your continued use of the
                            Sites after we post any modification means that you agreed to the Terms as modified.
                        </Paragraph>
                    </Grid>
                    <Grid item xs={12} sx={cssObject.content}>
                        <Paragraph align={'left'} size={'normal'}>
                            Subject to these Terms and if you comply with them, we grant you a personal, revocable, and
                            non-exclusive limited license to access and use the Sites.
                        </Paragraph>
                    </Grid>

                    {/* Accessing and Using the Sites Begin */}
                    <Grid item xs={12} sx={cssObject.contentSecondaryHeading} ref={accessRef}>
                        <Heading isPopUp color={'primary'} label={'Accessing And Using The Sites'} />
                    </Grid>
                    <Grid item xs={12} sx={cssObject.content}>
                        <Paragraph align={'left'} size={'normal'}>
                            The Sites are provided to you so that you may obtain information about or interact with our
                            products and services. You will only access and use these Sites for those purposes and not
                            for any other purpose, including any unlawful or malicious purpose. For example, you will
                            not:
                        </Paragraph>
                        <br />
                    </Grid>
                    <Grid item xs={12}>
                        {customBulletParagraph('Attempt to gain unauthorized access to the Sites,')}
                        <br />
                        {customBulletParagraph(
                            'Submit false or misleading information to us (including representing that you are somebody that you are not or have authority that you do not),'
                        )}
                        <br />{' '}
                        {customBulletParagraph('Use automated systems to monitor or copy content from the Sites,')}
                        <br />{' '}
                        {customBulletParagraph(
                            'Interfere with the normal operations of the Sites, such as by disrupting, disabling, overburdening, or otherwise interfering with the operation of the Sites, its services, or servers,'
                        )}
                        <br />{' '}
                        {customBulletParagraph('Upload infringing, disparaging, malicious, or inappropriate content,')}
                        <br />{' '}
                        {customBulletParagraph(
                            'Upload, transmit, or distribute any computer virus, malware, spyware, or any other maliciousor inappropriate computer code,'
                        )}
                        <br />
                        {customBulletParagraph('Interfere with the access or use of the Sites by others, or')}
                        <br />
                        {customBulletParagraph(
                            'Consume a disproportionate or unreasonable amount of the Sites’ resources.'
                        )}
                    </Grid>
                    <Grid item xs={12} sx={cssObject.content}>
                        <Paragraph align={'left'} size={'normal'}>
                            While the Sites may be globally accessible through the internet, the Sites are intended for
                            our customers and end-users in the United States. Products or services mentioned on the
                            Sites may not be available to you, or may be prohibited by law, in your location. If you
                            choose to access the Sites from outside the United States, you do so at your own risk and
                            are solely responsible for complying with all applicable laws in your location.
                        </Paragraph>
                    </Grid>
                    <Grid item xs={12} sx={cssObject.content}>
                        <Paragraph align={'left'} size={'normal'}>
                            The Sites are intended for those who are 18 (or the equivalent minimum age of majority in
                            your jurisdiction) years of age or older. If you are between the ages of 13 (or the
                            equivalent minimum age in your jurisdiction) and 18 (or the equivalent minimum age of
                            majority in your jurisdiction), you agree you will only use the Sites under the direct
                            supervision of a parent or legal guardian who agrees to be bound by the Terms themselves and
                            on your behalf. If you are younger than 13 years old (or the equivalent minimum age in your
                            jurisdiction), you must not use the Sites, and any such use is an express violation of these
                            Terms.
                        </Paragraph>
                    </Grid>
                    <Grid item xs={12} sx={cssObject.content}>
                        <Paragraph align={'left'} size={'normal'}>
                            You agree to follow any policies or guidelines associated with any of the Sites.
                        </Paragraph>
                    </Grid>
                    {/* Accessing and Using the Sites End */}

                    {/* Disclaimers, Limitation of Liability, Class-Action Waiver, and Indemnity Begin */}
                    <Grid item xs={12} sx={cssObject.contentSecondaryHeading} ref={disclaimerRef}>
                        <Heading
                            isPopUp
                            color={'primary'}
                            label={'Disclaimers, Limitation Of Liability, Class-Action Waiver, And Indemnity'}
                        />
                    </Grid>
                    <Grid item xs={12} sx={cssObject.disclaimer}>
                        <br />
                        <Paragraph semibold size={'normal'}>
                            PLEASE READ THIS SECTION 2 CAREFULLY, AS IT ADDRESSES DISCLAIMERS, LIMITATIONS OF LIABILITY,
                            CLASS-ACTION WAIVERS, AND INDEMNITY.
                        </Paragraph>
                        <br />
                        <Grid item xs={12} sx={cssObject.content}>
                            <Paragraph align={'left'} size={'normal'}>
                                <b>Disclaimers</b> You access and use the Sites at your own risk, and you agree we are
                                not liable in any way for your access or use of the Sites.
                            </Paragraph>
                        </Grid>

                        <Grid item xs={12} sx={cssObject.disclaimer} style={{ borderBottom: 'none' }}>
                            <br />
                            <Paragraph size={'normal'} semibold>
                                TO THE MAXIMUM EXTENT POSSIBLE UNDER APPLICABLE LAW, ALL OF THE SITES ARE PROVIDED
                                AS-IS, ASAVAILABLE, WITH ALL FAULTS, AND WITHOUT ANY WARRANTY OF ANY KIND—EXPRESS,
                                IMPLIED, STATUTORY, OR OTHERWISE—INCLUDING, BUT NOT LIMITED TO, ANY WARRANTY OF
                                MERCHANTABILITY, OF FITNESS FOR A PARTICULAR PURPOSE, OF TITLE, OF ACCURACY, OR OF
                                NON-INFRINGEMENT. INDEED, WE SPECIFICALLY DISCLAIM ANY WARRANTIES AND REPRESENTATIONS OF
                                EVERY KIND.
                            </Paragraph>
                        </Grid>
                        <br />
                    </Grid>

                    <Grid item xs={12} sx={cssObject.content}>
                        <Paragraph align={'left'} size={'normal'}>
                            That means that we, as examples and not limitations, do not warrant (and specifically
                            disclaim) that the Sites or your use of the Sites will
                        </Paragraph>
                        <br />
                    </Grid>
                    <Grid item xs={12}>
                        {customBulletParagraph('Be timely or uninterrupted,')}
                        <br />
                        {customBulletParagraph(' Provide any specific results,')}
                        <br />{' '}
                        {customBulletParagraph(
                            ' Be free from any defects, viruses (or other malicious code), corruption, hacking, content losses, errors, or omissions,'
                        )}
                        <br /> {customBulletParagraph(' Be accurate, complete, up-to-date, or useful,')}
                        <br />{' '}
                        {customBulletParagraph(
                            ' Be corrected to address any defects, deficiencies, inaccuracies, or errors on the Sites, orInterfere with the access or use of the Sites by others, or'
                        )}
                        <br />{' '}
                        {customBulletParagraph(
                            ' Be secure. Any reliance you place on or from the Sites is strictly at your own risk.'
                        )}
                        <br />
                    </Grid>
                    <Grid item xs={12} sx={cssObject.content}>
                        <Paragraph align={'left'} size={'normal'}>
                            And you agree that no website—including the Sites, and transmissions to or from it—is ever
                            completely private or secure. You agree that your submission of any information to us is at
                            your sole risk, and you hereby release us from all liability to you for any loss or
                            liability relating to such information in any way.
                        </Paragraph>
                    </Grid>
                    <Grid item xs={12} sx={cssObject.content}>
                        <Paragraph align={'left'} size={'normal'}>
                            <b>Limitations on Disclaimers:</b> Some jurisdictions do not allow the exclusion of implied
                            warranties or other disclaimers of warranty. To the extent such laws apply to you, the above
                            provided exclusions of implied warranties or other disclaimers of warranty will not apply
                            solely to the extent prohibited by applicable law.
                        </Paragraph>
                    </Grid>
                    <Grid item xs={12} sx={cssObject.content}>
                        <Paragraph align={'left'} size={'normal'}>
                            <b>Physical Product Warranties:</b> Nothing in these Terms is intended to affect any
                            individual product warranties for a particular physical product.
                        </Paragraph>
                    </Grid>
                    {/* Disclaimers End */}

                    {/* Limitations of Liability Begin */}
                    <Grid item xs={12} sx={cssObject.disclaimer}>
                        <br />
                        <Paragraph semibold size={'normal'}>
                            LIMITATIONS OF LIABILITY NOTHING IN THESE TERMS, INCLUDING THIS LIMITATION OF LIABILITY
                            SECTION, LIMITS OR EXCLUDES (OR WILL BE CONSTRUED OR INTERPRETED TO EXCLUDE OR LIMIT) ANY
                            LIABILITY THAT CANNOT BE SO LIMITED OR EXCLUDED UNDER APPLICABLE LAW.
                        </Paragraph>
                        <br />
                    </Grid>
                    <Grid item xs={12} sx={cssObject.content}>
                        <Paragraph align={'left'} size={'normal'}>
                            In no case will we (including company officers, agents, directors, affiliates, suppliers,
                            dealers, distributors, contractors, employees, and licensors) be liable to you or any third
                            party for—whether arising out of contract, tort, products or strict liability, or any other
                            form of action—any direct, indirect, incidental, punitive, special, or consequential damages
                            arising from your use of any of the Sites, even if we knew or should have known, or have
                            been advised, of such possibilities. This limitation applies, but is not limited, to any
                            claim
                        </Paragraph>
                        <br />
                    </Grid>

                    <Grid item xs={12}>
                        {customBulletParagraph(
                            'Based on any errors or omissions in any content, any virus, or any defect;Provide any specific results,'
                        )}
                        <br />
                        {customBulletParagraph(
                            ' Based on any negligence of any kind or any degree on our or our officers’, agents’, directors’, affiliates’, suppliers’, dealers’, distributors’, contractors’, employees’, or licensors’, part; Be accurate, complete, up-to-date, or useful, '
                        )}
                        <br />
                        {customBulletParagraph(
                            'For any loss or damage of any kind incurred as a result of the use of the Sites, even if we were advised of its possibility; or '
                        )}
                        <br />
                        {customBulletParagraph(
                            'For any direct, indirect, incidental, punitive, special, or consequential damages (e.g., lost profits) arising from your use of any of the Sites.'
                        )}
                        <br />
                    </Grid>
                    <Grid item xs={12} sx={cssObject.content}>
                        <Paragraph align={'left'} size={'normal'} bold>
                            You agree to release us from liability for any such claims.
                        </Paragraph>
                    </Grid>
                    <Grid item xs={12} sx={cssObject.content}>
                        <Paragraph align={'left'} size={'normal'}>
                            If, for some reason, it is determined by a court of competent jurisdiction that the above
                            limitations do not apply and that you have a basis for recovering damages—under any theory
                            and of any kind—because of your use or access of the Sites, you agree that your exclusive
                            cumulative remedy for all claims is to recover from us i) all monies you have paid to us in
                            the previous 6 months for use of or access to the Sites or ii) if you have paid no monies to
                            us in the previous 6 months for use of or access to the Sites, ten U.S. dollars ($10.00).
                        </Paragraph>
                    </Grid>
                    <Grid item xs={12} sx={cssObject.content}>
                        <Paragraph align={'left'} size={'normal'}>
                            This Section 2 will survive and be given full effect even if it does not fully compensate
                            you for your losses, fails for its essential purpose, or is otherwise deemed unenforceable.
                        </Paragraph>
                    </Grid>
                    <Grid item xs={12} sx={cssObject.content}>
                        <Paragraph align={'left'} size={'normal'}>
                            Limitation of Liability: Some jurisdictions do not allow limitations on liability. For
                            example, some jurisdictions do not allow for limitations on liability for personal injury
                            caused solely by gross negligence or willful misconduct. To the extent such laws apply to
                            you, the above provided limitations of liability will not apply solely to the extent
                            prohibited by applicable law.
                        </Paragraph>
                    </Grid>
                    {/* Limitations of Liability End */}

                    {/* Indemnity Begin */}
                    <Grid item xs={12} sx={cssObject.content}>
                        <Paragraph align={'left'} size={'normal'}>
                            <b>Indemnity</b> You agree, to the maximum extent permitted by law, to indemnify and hold
                            harmless us (including [airandheatdoneright.com]’s officers, agents, directors, affiliates,
                            suppliers, dealers, distributors, contractors, employees, and licensors) from and against
                            any claims, losses, liabilities, damages, costs, and expenses (including reasonable
                            attorneys’ fees and costs) resulting from any violation of these Terms or any activity
                            related to your account (including negligent or wrongful conduct) by you or any other person
                            accessing the website using your account.
                        </Paragraph>
                    </Grid>
                    {/* Indemnity End */}

                    {/* Class-Action Waiver Begin */}

                    <Grid item xs={12} sx={cssObject.content}>
                        <Paragraph align={'left'} size={'normal'}>
                            <b>Class-Action Waiver</b> Our hope is that there is never a dispute between you and us. But
                            if there is, you agree you will bring any claims that you may have arising out of or based
                            on the Sites or these Terms on an individual basis and that you will not join your claim
                            with any claim of another person or entity, whether through participation in a class-action
                            proceeding or otherwise. To the fullest extent permitted by applicable law, no proceeding
                            brought under these Terms shall be joined to another proceeding involving any other party
                            subject to these Terms, whether through class-action proceedings or otherwise.
                        </Paragraph>
                    </Grid>
                    {/* Class-Action Waiver End */}

                    {/* Our Content Begin */}
                    <Grid item xs={12} sx={cssObject.contentSecondaryHeading} ref={contentRef}>
                        <Heading isPopUp color={'primary'} label={'Our Content'} />
                    </Grid>
                    <Grid item xs={12} sx={cssObject.content}>
                        <Paragraph align={'left'} size={'normal'}>
                            All content—such as images, text, trademarks, logos, artworks, designs, or code—on the Sites
                            is owned by or licensed to us and is protected by copyright, trademark, patent, or other
                            intellectual property laws. Using or accessing our Sites does not grant you any ownership of
                            or licenses to this content. You may not use content from our Sites without obtaining
                            permission from the content’s owner or without having permission under the law. And if any
                            of the content carries a legal notice, you may not alter, remove, or obscure that notice.
                        </Paragraph>
                    </Grid>
                    <Grid item xs={12} sx={cssObject.content}>
                        <Paragraph align={'left'} size={'normal'}>
                            Many of the Sites display trademarks, service marks, logos, or other designations of source
                            (collectively “Marks”). All Marks are registered or unregistered marks of Goodman, its
                            corporate affiliates, or its licensors or content providers. All Marks are the exclusive
                            property of their respective owners. And your access and use of the Sites does not grant
                            you—by implications or otherwise—any license, right to use, or ownership interest in any
                            Marks.
                        </Paragraph>
                    </Grid>
                    <Grid item xs={12} sx={cssObject.content}>
                        <Paragraph align={'left'} size={'normal'}>
                            We may change, remove, or add to any content on our Sites without notification to you.
                        </Paragraph>
                    </Grid>
                    {/* Our Content End */}

                    {/* Your Submissions Begin */}
                    <Grid item xs={12} sx={cssObject.contentSecondaryHeading} ref={submissionRef}>
                        <Heading isPopUp color={'primary'} label={'Your Submissions'} />
                    </Grid>
                    <Grid item xs={12} sx={cssObject.content}>
                        <Paragraph align={'left'} size={'normal'}>
                            We encourage you to submit your comments and questions to us. We try to respond to as many
                            of your submissions as we can. But please carefully review the following before making any
                            submission to us.
                        </Paragraph>
                    </Grid>
                    <Grid item xs={12} sx={cssObject.content}>
                        <Paragraph align={'left'} size={'normal'}>
                            In certain instances, you may be able to submit (e.g., upload) content to or through the
                            Sites. You will not submit any content to the Sites that
                        </Paragraph>
                        <br />
                    </Grid>
                    <Grid item xs={12}>
                        {customBulletParagraph(
                            'Is inappropriate, harmful, malicious, obscene, abusive, or defamatory; '
                        )}
                        <br />
                        {customBulletParagraph(
                            ' Infringes the patent, trademark, trade secret, copyright, or other intellectual property or other right of another;'
                        )}
                        <br />
                        {customBulletParagraph(
                            ' Violates the legal rights of others or gives rise to any civil or criminal liability under applicable laws or regulations; or '
                        )}
                        <br />
                        {customBulletParagraph(
                            'Otherwise violates these Terms. And you will not submit any content without the express authorization of the owner of such content. We may remove or reject any content you submit.'
                        )}
                        <br />
                    </Grid>
                    <Grid item xs={12} sx={cssObject.content}>
                        <Paragraph align={'left'} size={'normal'}>
                            If you choose to provide any content to us, we will not treat it as confidential or
                            proprietary, and you hereby grant (upon your submission) to us (and our agents, corporate
                            affiliates, and service providers, and each of our respective licensees, successors, and
                            assigns) a worldwide, irrevocable, perpetual, non-exclusive, fully transferable,
                            sublicensable, and royalty-free license to use, reproduce, display, distribute, or otherwise
                            make derivatives works of any content that you submitted. You are solely responsible for any
                            content you submit, and you agree to indemnify, hold harmless, and defend us against any
                            third-party claims, actions, allegations, or liabilities based on the content you submitted.
                        </Paragraph>
                    </Grid>
                    <Grid item xs={12} sx={cssObject.content}>
                        <Paragraph align={'left'} size={'normal'}>
                            We also appreciate that many of you are innovative and have ideas to improve the HVAC-R
                            industry. But, as a leader in the industry, we are working on numerous research and
                            development projects—some of which may be similar to yours. Accordingly, do not send us new
                            ideas for products or services before first contacting our Legal department (
                            <CommonLink
                                fontColor={theme.palette.primary.main}
                                href={'mailto:IP@DaikinComfort.com'}
                                label={'IP@DaikinComfort.com'}
                            />
                            ) for express instruction on how to do so. If you choose to submit your ideas without
                            following our instructions, whether through the Sites or otherwise, you agree that we may
                            treat your submissions as non-confidential and are free to use, distribute, or otherwise
                            incorporate your submitted ideas without any compensation or consideration to you. Of
                            course, we will respect intellectual property rights provided by a valid, enforceable
                            patent.
                        </Paragraph>
                    </Grid>
                    {/* Your Submissions End */}

                    {/* Digital Millennium Copyright Act Begin */}
                    <Grid item xs={12} sx={cssObject.contentSecondaryHeading} ref={copyRightRef}>
                        <Heading isPopUp color={'primary'} label={'Digital Millennium Copyright Act'} />
                    </Grid>
                    <Grid item xs={12} sx={cssObject.content}>
                        <Paragraph align={'left'} size={'normal'}>
                            We respect the intellectual property rights of others, and we ask visitors to the Sites to
                            do the same. If you believe that materials accessible on or from the Sites infringe your
                            copyright, you may, pursuant to the Digital Millennium Copyright Act, request removal of
                            those materials by submitting written notification to our copyright agent (as designated
                            below).
                        </Paragraph>
                    </Grid>
                    <Grid item xs={12} sx={cssObject.content}>
                        <Paragraph align={'left'} size={'normal'}>
                            Your notice to us must at least provide the following information:
                        </Paragraph>
                        <br />
                    </Grid>
                    <Grid item xs={12}>
                        {customBulletParagraph(
                            'The physical or electronic signature of the person authorized to act on behalf of the owner of the copyright interest; '
                        )}
                        <br />
                        {customBulletParagraph(' A description of the copyrighted work you claim has been infringed; ')}
                        <br />
                        {customBulletParagraph(
                            'A description of where the material that you claim is infringing is located on the Sites; '
                        )}
                        <br />
                        {customBulletParagraph('Your address, telephone number, and e-mail address; ')}
                        <br />
                        {customBulletParagraph(
                            'A statement by you that you have a good-faith belief that the disputed use is not authorized by the copyright owner, its agent, or the law; and '
                        )}
                        <br />
                        {customBulletParagraph(
                            'A statement by you, made under the penalty of perjury, that the above information in your notice to us is accurate and that you are the copyright owner or authorized to act on the copyright owner’s behalf.'
                        )}
                        <br />
                    </Grid>
                    <Grid item xs={12} sx={cssObject.content}>
                        <Paragraph align={'left'} size={'normal'} bold>
                            Our Copyright Agent for allegations of copyright infringement is:
                        </Paragraph>
                    </Grid>
                    <Grid item xs={12} sx={cssObject.content}>
                        <Paragraph align={'left'} size={'normal'}>
                            Mr. Manish Vyas
                            <br /> Designated DMCA Copyright Agent, Legal
                            <br /> Daikin Texas Technology Park
                            <br /> 19001 Kermier Rd,
                            <br />
                            Waller, TX 77484
                            <br />{' '}
                            <CommonLink
                                fontColor={theme.palette.primary.main}
                                href={'mailto:IP@DaikinComfort.com'}
                                label={'IP@DaikinComfort.com'}
                            />
                        </Paragraph>
                    </Grid>
                    <Grid item xs={12} sx={cssObject.content}>
                        <Paragraph align={'left'} size={'normal'}>
                            We may remove or disable access to materials residing on our Sites that is claimed to be
                            infringing, and we have the right to suspend or terminate access to or use of our Sites for
                            those who infringe another’s rights.
                        </Paragraph>
                    </Grid>
                    {/* Digital Millennium Copyright Act End */}

                    {/* Third-Party Content, Links Begin */}
                    <Grid item xs={12} sx={cssObject.contentSecondaryHeading} ref={thirdPartyRef}>
                        <Heading isPopUp color={'primary'} label={'Third-Party Content, Links '} />
                    </Grid>
                    <Grid item xs={12} sx={cssObject.content}>
                        <Paragraph align={'left'} size={'normal'}>
                            Some of the Sites may link to third-party services or resources or may contain third-party
                            content, some of which may be provided by other members of the Daikin Group. These Terms do
                            not address the policies or practices of those third parties, including other members of the
                            Daikin Group, and do not grant you any rights in or to any products or services provided by
                            those third parties. Your access to and use of any products or services provided by such
                            third parties are governed by your separate agreement with them (including their own terms
                            of service and privacy policies). We provide their links and content as a convenience. We do
                            not necessarily sponsor, endorse, or otherwise approve their materials, products, and
                            services. And we make no representations or warranties regarding, for example, the accuracy,
                            suitability, or propriety of the same. You will use or access any third-party content or
                            links at your own risk, and you agree we are not liable in any way for your access or use of
                            such.
                        </Paragraph>
                    </Grid>
                    <Grid item xs={12} sx={cssObject.content}>
                        <Paragraph align={'left'} size={'normal'}>
                            In some instances, comments from users of the Sites or of our products or services may be
                            posted on the Sites, or in other documents. These comments are the ideas or opinions of
                            users of the Sites or the users of our products or services, and do not necessarily
                            represent our ideas or opinions.
                        </Paragraph>
                    </Grid>
                    {/* Third-Party Content, Links End */}

                    {/* Privacy Start */}
                    <Grid item xs={12} sx={cssObject.contentSecondaryHeading} ref={privacyRef}>
                        <Heading isPopUp color={'primary'} label={'Privacy'} />
                    </Grid>
                    <Grid item xs={12} sx={cssObject.content}>
                        <Paragraph align={'left'} size={'normal'}>
                            Your privacy is important to us. Our Privacy Policy is{' '}
                            <CommonLink
                                fontColor={theme.palette.primary.main}
                                href={AppRoute.SecurityAndPrivacyPolicy}
                                label={'here'}
                            />
                            , and it applies to the Sites and is incorporated into these Terms by reference. If you are
                            in a jurisdiction where data collection and processing require consent, by agreeing to these
                            Terms, you are, to the extent permitted by applicable law, providing your consent to the
                            collection and use of your data in accordance with our Privacy Policy. To the extent there
                            is conflict between these Terms and the Privacy Policy, the Privacy Policy controls.
                        </Paragraph>
                    </Grid>
                    {/* Privacy End */}

                    {/* Site Access and Termination Begin */}
                    <Grid item xs={12} sx={cssObject.contentSecondaryHeading} ref={siteAccessRef}>
                        <Heading isPopUp color={'primary'} label={'Site Access and Termination '} />
                    </Grid>
                    <Grid item xs={12} sx={cssObject.content}>
                        <Paragraph align={'left'} size={'normal'}>
                            We may remove the Sites, temporarily or permanently. And we may limit or disable your access
                            to the Sites without notice to you. If we believe you have violated these Terms, we may
                            immediately suspend or terminate your access to and ability to use the Sites.
                        </Paragraph>
                    </Grid>
                    {/* Site Access and Termination End */}

                    {/* About these Terms Begin */}
                    <Grid item xs={12} sx={cssObject.contentSecondaryHeading} ref={aboutRef}>
                        <Heading isPopUp color={'primary'} label={'About these Terms'} />
                    </Grid>
                    <Grid item xs={12} sx={cssObject.content}>
                        <Paragraph align={'left'} size={'normal'}>
                            You agree that any dispute arising out of or based on the Sites or these Terms will be
                            governed by the laws of the State of Texas, without regard to Texas’s conflict-of-laws
                            principles. And all such disputes and claims will be exclusively brought in the state or
                            federal courts located in Harris County, Texas—with you and us agreeing to the personal
                            jurisdiction of such state and federal courts and to waive any claims regarding the lack of
                            convenience of such courts.
                        </Paragraph>
                    </Grid>
                    <Grid item xs={12} sx={cssObject.content}>
                        <Paragraph align={'left'} size={'normal'}>
                            These Terms, which incorporate our Privacy Policy, constitute the entire agreement between
                            you and us in relation to the Sites, and—except for a separate written agreement between you
                            and us or additional terms on a specific Site—these Terms supersede any prior agreement
                            between you and us regarding the Sites, including prior versions of these Terms.
                        </Paragraph>
                    </Grid>
                    <Grid item xs={12} sx={cssObject.content}>
                        <Paragraph align={'left'} size={'normal'}>
                            Our failure to enforce—or any delay in our enforcing—any provision of these Terms will not
                            be deemed a waiver of our rights to enforce them.
                        </Paragraph>
                    </Grid>
                    <Grid item xs={12} sx={cssObject.content}>
                        <Paragraph align={'left'} size={'normal'}>
                            If any provision of these Terms is found to be invalid or unenforceable, the invalidity or
                            unenforceability of such provision shall not affect the other provisions of the Terms, and
                            all provisions not affected by such invalidity or unenforceability shall remain in full
                            force and effect. In such cases, you agree that we may substitute for each invalid or
                            unenforceable provision a valid or enforceable provision which achieves to the greatest
                            extent possible the objectives and intentions of the invalid or unenforceable provision.
                        </Paragraph>
                    </Grid>
                    <Grid item xs={12} sx={cssObject.content}>
                        <Paragraph align={'left'} size={'normal'}>
                            If you have any questions or comments about these Terms, please feel free to submit them to
                            us by emailing{' '}
                            <CommonLink
                                fontColor={theme.palette.primary.main}
                                href={'mailto:IP@DaikinComfort.com'}
                                label={'IP@DaikinComfort.com'}
                            />
                            .
                        </Paragraph>
                    </Grid>

                    {/* About these Terms End */}
                </Fragment>
            }
        />
    );
}
export function customBulletParagraph(text: any, subBullet?: boolean) {
    return (
        <Grid container style={{ paddingLeft: subBullet ? '25px' : '' }}>
            <Grid item style={{ marginTop: '4px', display: 'flex', alignItems: 'flex-start' }}>
                {subBullet ? Grey() : Green()}
            </Grid>
            <Grid item xs={11} style={{ paddingLeft: '10px' }}>
                <Paragraph size={'normal'}>{text}</Paragraph>
            </Grid>
        </Grid>
    );
}
export default TermAndConditions;
