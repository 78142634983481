import React from 'react';
import { Paragraph } from '../../components/Paragraph';
import { Box, Button as MuiButton } from '@mui/material';
import { useSelector } from 'react-redux';
import { selectCartInstructions } from 'store/selectors/cart';
import NotesDrawer from './InstructionsDrawer';
import AddInstructionIcon from 'components/icons/AddInstructionIcon';
import { Button } from 'components/Button';

type Anchor = 'right';

const MinimisedInstructionsDrawer = () => {
    const instructionsData = useSelector(selectCartInstructions);

    const [drawerState, setDrawerState] = React.useState({
        right: false,
    });

    const toggleDrawer = (anchor: Anchor, open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
        if (
            event.type === 'keydown' &&
            ((event as React.KeyboardEvent).key === 'Tab' || (event as React.KeyboardEvent).key === 'Shift')
        ) {
            return;
        }
        setDrawerState({ ...drawerState, [anchor]: open });
    };
    return (
        <>
            <NotesDrawer toggleDrawer={toggleDrawer('right', false)} open={drawerState.right} />
            {instructionsData ? (
                <Box>
                    <Paragraph semibold>Access Instructions:</Paragraph>
                    <Box display={'flex'} flexDirection={'row'} alignItems={'center'}>
                        <Paragraph noWrap sx={{ width: '150px' }}>
                            {instructionsData}
                        </Paragraph>
                        <MuiButton
                            variant="text"
                            color="primary"
                            size="small"
                            onClick={toggleDrawer('right', true)}
                            component="span"
                            sx={{
                                textTransform: 'capitalize',
                                minWidth: '60px',
                                float: 'right',
                                fontSize: '14px',
                            }}
                        >
                            Edit
                        </MuiButton>
                    </Box>
                </Box>
            ) : (
                <Button
                    id={`add-instructions-btn`}
                    filledButton
                    onClick={toggleDrawer('right', true)}
                    sx={{ textTransform: 'capitalize', float: 'right', fontSize: '14px' }}
                >
                    <AddInstructionIcon /> &nbsp; Add Access Instructions
                </Button>
            )}
        </>
    );
};
export default MinimisedInstructionsDrawer;
