import * as React from "react"
import { SVGProps } from "react"

const EmailSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    data-name="Component 36 \u2013 1"
    xmlns="http://www.w3.org/2000/svg"
    width={19}
    height={19}
    {...props}
  >
    <g data-name="Component 4 \u2013 4">
      <g data-name="Group 75">
        <g data-name="Group 74" fill="none" stroke="#58b7af" strokeWidth={1.5}>
          <g data-name="Rectangle 77" transform="translate(1 3)">
            <rect width={17} height={13} rx={2} stroke="none" />
            <rect x={0.75} y={0.75} width={15.5} height={11.5} rx={1.25} />
          </g>
          <path
            data-name="Path 26"
            d="m2.736 5.41 6.9 4.328 7.008-4.328"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
      </g>
    </g>
  </svg>
)

export default EmailSmall
