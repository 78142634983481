import React, { useState } from 'react';
import { useTheme } from '@mui/material/styles';
import { useDispatch, useSelector } from 'react-redux';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { Paragraph } from 'components/Paragraph';
import { ThemeWithAdditionalData } from '../../themes/theme';
import ContentContainer from 'components/ContentContainer';
import { Cart } from 'models/cart';
import { selectCart } from 'store/selectors/cart';
import { applyCouponAction, removeCouponSuccess, resetCouponErr } from 'store/actions/cartActions';
import { deleteCoupon } from 'store/services/cartServices';
import { Button } from 'components/Button';
import { Formik } from 'formik';
import FormikTextField from 'components/FormikTextField';
import DeleteIcon from 'components/icons/DeleteIcon';
import OtpErrorIcon from 'components/icons/OtpErrorIcon';
import GiftIcon from 'components/icons/GiftIcon';
import { showSnackbarError } from 'components/common/Snackbar/SnackbarHelper';
import { SXCssObject } from 'models/ThemeImages';
import { get } from 'lodash';
import { invokeGAPromocodeRemoved } from 'components/Analytics/GoogleAnalyticsTagging';
import { PROMO_CODE_REMOVE } from 'components/common/constants';

interface FormValues {
    coupon: string;
}
interface Props {
    onClick: (loaderState: boolean) => void;
}
const createCssObject = (theme: ThemeWithAdditionalData): SXCssObject => ({
    icons: {
        marginRight: {
            xs: '10px',
            sm: '16px',
        },
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
    },

    centerColumn: {
        display: 'flex',
        justifyContent: 'center',
        fontSize: '20px',
        flexDirection: 'column',
    },
    tabheadingText: {
        fontSize: { xs: '16px', sm: '18px', md: '20px' },
    },
    tabSubheading1: {
        fontSize: '12px',
        color: theme.customProperties.borderDivider,
    },

    promoContainerStyle: {
        flexDirection: {
            md: 'column',
        },
    },
    promoCodeApplyBtnStyle: {
        display: 'flex',
        alignItems: 'center',
    },
    promoCodeDelBtnStyle: {
        display: 'flex',
        justifyContent: 'flex-end',
    },
    promoErrLayoutStyle: {
        display: 'flex',
        marginTop: '12px',
    },
    promoErrIconStyle: {
        flexDirection: 'column',
        display: 'flex',
        marginRight: '8px',
    },
    promoErrorTextStyle: {
        fontSize: '12px',
        paddingLeft: '5px',
        display: 'flex',
        justifyContent: 'center',
    },
    enteredPromoCode: {
        textAlign: 'left',
        fontSize: '16px',
        fontWeight: theme.typography.fontWeightMedium,
        color: '#424242',
        padding: '12px 0px 4px 12px',
    },

    promoCodeWrapperStyle: {
        display: 'inline-flex',
        padding: {
            xs: '0px',
            sm: '16px 22px',
        },
    },
    currencyIconStyle: {
        display: 'flex',
        justifyContent: 'center',
        mr: { xs: 1, sm: 1.6 },
        color: 'white',
        border: `1px solid ${theme.palette.primary.contrastText}`,
        borderRadius: '4px',
        background: theme.palette.common.white,
    },
    couponInput: {
        p: { xs: '16px 0px 16px 0px', sm: '16px 22px 16px 0px', md: '0px 22px 16px 22px' },
    },
    couponMessage: {
        fontSize: '12px',
    },
    deleteIconWrapper: {
        display: 'flex',
        cursor: 'pointer',
        alignItems: 'center',
    },
    deleteImg: {
        '& svg': {
            padding: '8px',
            height: '40px',
            width: '40px',
        },
    },
    promoCodeContent: {
        marginBottom: {
            sm: '24px',
        },
    },
    divider: {
        width: '94%',
        height: '10px',
        margin: '0px auto',
        borderBottom: `2px solid ${theme.palette.primary.contrastText}`,
    },

    promoCodeFormContainer: {
        padding: '0px 5px',
    },
    promoCodeFormBorder: {
        border: '1px solid #DEDEDE',
    },
    promoCodeFormErrBorder: {
        border: '1px solid #F86767',
    },
});
function PromoCodeContent(props: Props) {
    const { onClick } = props;
    const cssObject: SXCssObject = createCssObject(useTheme());
    const [applyPromoErr, setApplyPromoErr] = useState<string>('');
    const cart: Cart = useSelector(selectCart);
    const dispatch = useDispatch();
    const { appliedCoupons, prices } = cart;
    const discount = get(prices, 'discounts[0].amount', undefined);

    React.useEffect(() => {
        if (cart.isErrorInCoupon) {
            setApplyPromoErr(cart.isErrorInCoupon!);
            dispatch(resetCouponErr());
        }
    }, [cart.isErrorInCoupon, dispatch]);

    const handlePromoCodeChange = () => {
        if (applyPromoErr) {
            setApplyPromoErr('');
        }
    };
    const handleApplyPromoCode = (values: FormValues) => {
        dispatch(applyCouponAction(values.coupon));
    };
    const handleDeleteCoupon = () => {
        onClick(true);
        deleteCoupon(cart.cartId!).subscribe(
            (removeCouponSuccessResp) => {
                if (removeCouponSuccessResp.data && !removeCouponSuccessResp.data.appliedCoupons) {
                    dispatch(removeCouponSuccess(removeCouponSuccessResp.data));
                    invokeGAPromocodeRemoved();
                    onClick(false);
                } else {
                    showSnackbarError(PROMO_CODE_REMOVE);
                    onClick(false);
                }
            },
            (err) => {
                let msg;
                try {
                    if (err.response.data.error.message) {
                        msg = err.response.data.error.message;
                    }
                } catch (e) {}
                showSnackbarError(msg);
                onClick(false);
            }
        );
    };
    return (
        <ContentContainer disableGrid background={'bg3'}>
            <Grid container sx={cssObject.promoContainerStyle}>
                <Grid item xs={12} sm={6} md={12} sx={cssObject.promoCodeWrapperStyle}>
                    <Box sx={cssObject.currencyIconStyle}>
                        <GiftIcon />
                    </Box>
                    <Box sx={cssObject.centerColumn}>
                        <Box sx={cssObject.tabheadingText}>
                            <Paragraph semiLargeFont bold headingStyle>
                                Add Promo Code
                            </Paragraph>
                        </Box>
                        <Box sx={cssObject.tabSubheading1}>
                            <Paragraph fontSizeVerySmall>Already sent to email</Paragraph>
                        </Box>
                    </Box>
                </Grid>
                <Grid item xs={12} sm={6} md={12} sx={cssObject.couponInput}>
                    <Formik
                        initialValues={{
                            coupon:
                                (cart.appliedCoupons &&
                                    cart.appliedCoupons.length > 0 &&
                                    cart.appliedCoupons[0].code) ||
                                '',
                        }}
                        onSubmit={handleApplyPromoCode}
                    >
                        {({ submitForm, values, setFieldValue }) => (
                            <Grid container>
                                <Grid
                                    item
                                    container
                                    sx={[
                                        cssObject.promoCodeFormContainer,
                                        applyPromoErr
                                            ? cssObject.promoCodeFormErrBorder
                                            : cssObject.promoCodeFormBorder,
                                    ]}
                                >
                                    <Grid item xs={8} lg={8}>
                                        {appliedCoupons && appliedCoupons.length > 0 ? (
                                            <Grid sx={cssObject.enteredPromoCode}>
                                                {appliedCoupons[0].code}
                                                <br />
                                                {discount && discount !== 0 && !cart.couponApplyError ? (
                                                    <Grid sx={cssObject.couponMessage}>
                                                        You received ${discount || 0} off!
                                                    </Grid>
                                                ) : (
                                                    <Grid sx={cssObject.couponMessage}>
                                                        Coupon has expired. Please remove this coupon to add a new one
                                                    </Grid>
                                                )}
                                            </Grid>
                                        ) : (
                                            <FormikTextField
                                                id="coupon"
                                                isTransparentBorderForInputField={true}
                                                name="coupon"
                                                label=""
                                                type="coupon"
                                                onChange={handlePromoCodeChange}
                                                placeholder="ADD CODE"
                                                shouldDisableInputField={discount && discount !== 0 ? true : false}
                                            />
                                        )}
                                    </Grid>
                                    {(!appliedCoupons || (appliedCoupons && appliedCoupons.length === 0)) && (
                                        <Grid item xs={4} lg={4} sx={cssObject.promoCodeApplyBtnStyle}>
                                            <Button
                                                isFullWidth={true}
                                                color="primary"
                                                disabled={values.coupon === '' || applyPromoErr !== ''}
                                                onClick={submitForm}
                                            >
                                                APPLY
                                            </Button>
                                        </Grid>
                                    )}

                                    {appliedCoupons && appliedCoupons.length > 0 && (
                                        <Grid item xs={4} lg={3} sx={cssObject.promoCodeDelBtnStyle}>
                                            <Box
                                                sx={cssObject.deleteIconWrapper}
                                                onClick={() => {
                                                    handleDeleteCoupon();
                                                    setFieldValue('coupon', '');
                                                }}
                                            >
                                                <Box component={'span'} sx={cssObject.deleteImg}>
                                                    <DeleteIcon />
                                                </Box>
                                            </Box>
                                        </Grid>
                                    )}
                                </Grid>
                                {applyPromoErr && (
                                    <Grid item xs={12} md={12}>
                                        <Box sx={cssObject.promoErrLayoutStyle}>
                                            <Box component={'span'} sx={cssObject.promoErrIconStyle}>
                                                <OtpErrorIcon />
                                            </Box>
                                            <Box component={'span'} sx={cssObject.promoErrorTextStyle}>
                                                {applyPromoErr}
                                            </Box>
                                        </Box>
                                    </Grid>
                                )}
                            </Grid>
                        )}
                    </Formik>
                </Grid>
            </Grid>
        </ContentContainer>
    );
}
export default PromoCodeContent;
