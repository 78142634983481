import React, { useEffect, useRef } from 'react';
import ContentContainer from 'components/ContentContainer';
import Navigator from 'components/Navigator';
import Footer from 'components/Footer';
import HowThisWork from 'components/home/HowThisWork';
import HomeWarranty from 'components/home/HomeWarranty';
import { ThemeWithAdditionalData } from '../themes/theme';
import PromotionContent from '../components/home/PromotionContent';
import CustomerReviews from 'components/home/CustomerReviews';
import { useDispatch, useSelector } from 'react-redux';
import { ProductReviews } from 'models/productReviews';
import { selectProductReviewState } from 'store/selectors/productReview';
import { productReview } from 'store/actions/productReviewActions';
import { selectLoginState } from 'store/selectors/loginSelector';
import { contactPageViewAction } from 'store/actions/hubspotActions';
import { PageViewRequest } from 'models/contact';
import Cookies from 'js-cookie';
import { IS_ALIVE_COOKIE } from 'components/common/constants';
import LoadingOverlay from 'components/common/LoadingOverlay';
import { selectCartProcessing } from 'store/selectors/cart';
import HeadLine from 'components/airandheatdoneright/HeadLine';
import { useNavigate } from 'react-router-dom';
import { resetSelection } from 'store/actions/cartActions';
import { resetProductCriteria } from 'store/actions/productCriteriaActions';
import { AppRoute } from 'models/route';
import CustomerReviewsLarge from 'components/home/CustomerReviewsLarge';
import { Box, Theme, useMediaQuery } from '@mui/material';
import { selectOrderLoading } from 'store/selectors/order';
import { myOrderAction } from 'store/actions/orderActions';
import { useTheme } from '@mui/material/styles';
import Carousel from 'react-material-ui-carousel';
import { ImageObjects, SXCssObject } from 'models/ThemeImages';
import { selectMultipleOpenProjects } from 'store/selectors/storeSelector';
import styles from 'components/common/CommonStyle.module.css';

const createCssObject = (theme: Theme): SXCssObject => ({
    whiteBg: {
        backgroundColor: theme.palette.common.white,
        width: '100%',
    },
    marquee: {
        position: 'relative',
        height: 'auto',
        display: 'block',
        width: '100%',
        '&::before': {
            content: {
                xs: "''",
                sm: 'inherit',
            },
            background: {
                xs: theme.palette.common.white,
                sm: 'inherit',
            },
            width: {
                xs: '100%',
                sm: 'inherit',
            },
            position: {
                xs: 'absolute',
                sm: 'inherit',
            },
            height: {
                xs: '400px',
                sm: 'inherit',
            },
            zIndex: {
                xs: -1,
                sm: 'inherit',
            },
        },
    },
    headingRoot: {
        zIndex: 2,
        position: {
            sm: 'absolute',
        },
        width: {
            sm: '100%',
        },
    },
    headingContent: {
        padding: {
            xs: '10px 0px 20px 0px',
            sm: '24px 0px 20px 0px',
            md: '60px 0px',
            lg: '100px 0px',
        },
    },
    bannerImage: {
        position: 'relative',
        zIndex: -1,
        objectPosition: 'bottom center',
        objectFit: 'cover',
        height: {
            xs: '350px',
            sm: '520px',
            md: '660px',
            lg: '840px',
            xl: '1050px',
        },
    },
});
export function Home() {
    const cssObject: SXCssObject = createCssObject(useTheme());
    const dispatch = useDispatch();
    const theme = useTheme<ThemeWithAdditionalData>();
    const itemsList: ProductReviews[] = useSelector(selectProductReviewState);
    let loginData = useSelector(selectLoginState);
    let cartLoading = useSelector(selectCartProcessing);
    const latestOrderLoading = useSelector(selectOrderLoading);
    const multipleOpenProjects = useSelector(selectMultipleOpenProjects);

    let PageViewReq: PageViewRequest = {
        email: loginData.email,
    };
    const navigate = useNavigate();
    const isSmAbove = useMediaQuery(theme.breakpoints.up('sm'));
    const xsDown = useMediaQuery(theme.breakpoints.only('xs'));
    const imageArray: ImageObjects[] = isSmAbove ? theme.images.homePage.heroDesktop : theme.images.homePage.heroSmall;
    let mounted: any = useRef();

    useEffect(() => {
        if (!mounted.current) {
            mounted.current = true;
            const isLocalCookieAlive = Cookies.get(IS_ALIVE_COOKIE);
            if (isLocalCookieAlive && !multipleOpenProjects && loginData.isVerified) {
                //api to get latest order
                dispatch(myOrderAction({}));
            }
            if (isLocalCookieAlive) {
                dispatch(contactPageViewAction(PageViewReq));
            }
            dispatch(productReview());
        }
    });

    return (
        <div>
            <Box sx={cssObject.whiteBg}>
                <LoadingOverlay open={cartLoading || latestOrderLoading} />
                <ContentContainer>
                    <Navigator color={'primary'} transparent={false} />
                </ContentContainer>
            </Box>
            <Box sx={cssObject.marquee}>
                <div style={{ position: 'relative' }}>
                    <ContentContainer sx={cssObject.headingRoot}>
                        <ContentContainer sx={cssObject.headingContent} disableGrid>
                            <HeadLine
                                onClick={() => {
                                    dispatch(resetSelection());
                                    dispatch(resetProductCriteria());
                                    navigate(AppRoute.AboutYourHome);
                                }}
                                buttonText={"Let's Get Started"}
                            />
                        </ContentContainer>
                    </ContentContainer>
                    <Carousel
                        navButtonsAlwaysInvisible={true}
                        indicators={false}
                        autoPlay={true}
                        interval={4000}
                        className={styles.carouselItemStyle}
                        animation={'fade'}
                    >
                        {imageArray.map((item: ImageObjects, index: number) => {
                            return (
                                <Box
                                    component={'img'}
                                    sx={cssObject.bannerImage}
                                    src={item.imageUrl}
                                    key={index}
                                    width={'100%'}
                                    alt={'Banner'}
                                />
                            );
                        })}
                    </Carousel>
                </div>
            </Box>
            <PromotionContent />
            <HowThisWork />
            <HomeWarranty />
            {itemsList.length > 0 &&
                (xsDown ? (
                    <CustomerReviews reviewsList={itemsList} />
                ) : (
                    <CustomerReviewsLarge reviewsList={itemsList} />
                ))}
            <Footer />
        </div>
    );
}

export default Home;
