import React from 'react';
import { Theme, useTheme } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import ContentContainer from 'components/ContentContainer';
import { FooterLink, ExternalLink } from 'components/Link';
import Facebook from 'assets/icons/icon_facebook_new.png';
import Instagram from 'assets/icons/icon_instagram_new.png';
import { AppRoute } from '../models/route';
import { Box, useMediaQuery } from '@mui/material';
import { Paragraph } from './Paragraph';
import CollapseIcon from './icons/CollapseIcon';
import ExpandIcon from './icons/ExpandIcon';
import { SXCssObject } from 'models/ThemeImages';
import { useSelector } from 'react-redux';
import { selectMultipleOpenProjects } from 'store/selectors/storeSelector';

const createCssObject = (theme: Theme): SXCssObject => ({
    root: {
        px: {
            xs: '20px',
            sm: '10px',
        },
        paddingTop: {
            xs: '35px',
            sm: 'inherit',
        },
    },
    credit: {
        paddingTop: theme.spacing(0.5),
    },
    links: {
        marginTop: theme.spacing(-1),
        '& .MuiGrid-item': {
            padding: theme.spacing(0.5),
        },
    },
    footerContainer: {
        textAlign: {
            xs: 'center',
            sm: 'inherit',
        },
        flexDirection: {
            xs: 'column',
            sm: 'inherit',
        },
        paddingLeft: {
            xs: '20px',
            sm: 'inherit',
        },
        paddingTop: {
            xs: 'inherit',
        },
    },
    reviewDividerStyle: {
        borderTop: `1px solid ${theme.customProperties.borderDivider}`,
        borderBottom: '0px',
        opacity: '0.2',
        marginTop: '25px',
        marginBottom: '25px',
        maxWidth: {
            md: '770px',
        },
    },
    minMaxIcon: { display: 'flex', justifyContent: 'end' },
    socialMedia: {
        padding: '30px 0px',
    },
});

function Footer(props: any) {
    const cssObject: SXCssObject = createCssObject(useTheme());
    const theme = useTheme();
    const isSm = useMediaQuery(theme.breakpoints.only('sm'));
    const isXs = useMediaQuery(theme.breakpoints.only('xs'), { noSsr: true });
    const [isQuickLinkExpanded, setQuickLinkExpanded] = React.useState(!isXs);
    const [isCompanyLinkExpanded, setCompanyLinkExpanded] = React.useState(!isXs);
    const multipleOpenProjects = useSelector(selectMultipleOpenProjects);

    const toggleQuickLinkExpanded = (currentState: boolean) => {
        setQuickLinkExpanded(!currentState);
    };
    const toggleCompanyLinkExpanded = (currentState: boolean) => {
        setCompanyLinkExpanded(!currentState);
    };
    return (
        <ContentContainer disableGrid background={'footerWhite'} size={'footer2'} sx={cssObject.root}>
            {isXs && (
                <Grid container justifyContent={'space-between'} sx={{ marginBottom: '20px' }}>
                    <Grid item>
                        <Paragraph bold>QUICK LINKS</Paragraph>
                    </Grid>
                    <Grid item onClick={() => toggleQuickLinkExpanded(isQuickLinkExpanded)} sx={cssObject.minMaxIcon}>
                        {isQuickLinkExpanded ? <CollapseIcon /> : <ExpandIcon />}
                    </Grid>
                </Grid>
            )}
            {(isQuickLinkExpanded || !isXs) && (
                <Grid
                    justifyContent={isSm ? 'space-evenly' : isXs ? 'flex-start' : 'center'}
                    flexDirection={isXs ? 'column' : 'row'}
                    container
                    spacing={isSm ? 1 : isXs ? 3 : 5}
                    item
                    xs={12}
                    alignItems={isXs ? 'flex-start' : 'center'}
                >
                    <Grid item>
                        <FooterLink
                            id={'motili-friends-and-family'}
                            color={'primary'}
                            href={AppRoute.MotiliFriendsAndFamily}
                            label={'Motili Friends and Family'}
                        />
                    </Grid>
                    <Grid item>
                        <FooterLink
                            id={'footer-link-who-we-are'}
                            color={'primary'}
                            href={AppRoute.WhoWeAre}
                            label={'Who We Are'}
                        />
                    </Grid>
                    <Grid item>
                        <FooterLink
                            id={'footer-link-how-hvac-works'}
                            color={'primary'}
                            href={AppRoute.HowHvacWorks}
                            label={'How HVAC works'}
                        />
                    </Grid>


                    <Grid item>
                        <FooterLink
                            id={'footer-link-our-Satisfaction-pledge'}
                            color={'primary'}
                            href={AppRoute.SatisfactionPledge}
                            label={'Our Satisfaction Pledge'}
                        />
                    </Grid>
                    {!isXs && (
                        <Grid item sx={{ pt: isSm ? '10px' : '' }}>
                            <Grid container spacing={1}>
                                <Grid item>
                                    <ExternalLink
                                        id={'external-link-facebook'}
                                        variant={'subtitle2'}
                                        color={'secondary'}
                                        href={'https://www.facebook.com/AirAndHeatDoneRight/'}
                                    >
                                        <img width="32" height="32" src={Facebook} alt="facebook" />{' '}
                                    </ExternalLink>
                                </Grid>
                                <Grid item>
                                    <ExternalLink
                                        id={'external-link-instagram'}
                                        variant={'subtitle2'}
                                        color={'secondary'}
                                        href={'https://www.instagram.com/airandheatdoneright/'}
                                    >
                                        <img width="32" height="32" src={Instagram} alt="instagram" />
                                    </ExternalLink>
                                </Grid>
                            </Grid>
                        </Grid>
                    )}
                </Grid>
            )}
            <Box component={'hr'} sx={cssObject.reviewDividerStyle} />
            {isXs && (
                <Grid container justifyContent={'space-between'}>
                    <Grid item>
                        <Paragraph bold>COMPANY</Paragraph>
                    </Grid>
                    <Grid
                        item
                        onClick={() => toggleCompanyLinkExpanded(isCompanyLinkExpanded)}
                        sx={cssObject.minMaxIcon}
                    >
                        {isCompanyLinkExpanded ? <CollapseIcon /> : <ExpandIcon />}
                    </Grid>
                </Grid>
            )}
            {(isCompanyLinkExpanded || !isXs) && (
                <Grid
                    justifyContent={isSm ? 'space-evenly' : isXs ? 'flex-start' : 'center'}
                    container
                    flexDirection={isXs ? 'column' : 'row'}
                    spacing={isSm ? 1 : isXs ? 3 : 7}
                    item
                    xs={12}
                    alignItems={isXs ? 'flex-start' : 'center'}
                    sx={{pt:'20px', pb: '30px' }}
                >
                    <Grid item>
                        <FooterLink
                            id={'footer-link-energy-star'}
                            color={'primary'}
                            href={AppRoute.EnergyStar}
                            label={'ENERGY STAR'}
                        />
                    </Grid>
                    <Grid item>
                        <FooterLink
                            id={'footer-link-made-in-usa'}
                            color={'primary'}
                            href={AppRoute.MadeInUsa}
                            label={'MADE IN USA'}
                        />
                    </Grid>
                    <Grid item>
                        <FooterLink
                            id={'footer-link-contact-us'}
                            color={'primary'}
                            href={AppRoute.ContactUs}
                            label={'Contact Us'}
                        />
                    </Grid>
                    <Grid item>
                        <FooterLink
                            id={'footer-link-privacy-policy'}
                            color={'primary'}
                            href={
                                multipleOpenProjects
                                    ? 'https://daikincomfort.com/privacy-policy'
                                    : AppRoute.SecurityAndPrivacyPolicy
                            }
                            label={'PRIVACY POLICY'}
                        />
                    </Grid>
                    <Grid item>
                        <FooterLink
                            id={'footer-link-terms-and-conditions'}
                            color={'primary'}
                            href={
                                multipleOpenProjects ? 'https://daikincomfort.com/terms' : AppRoute.TermsAndConditions
                            }
                            label={'TERMS & CONDITIONS'}
                        />
                    </Grid>
                </Grid>
            )}
            {isXs && (
                <>
                    <Box component={'hr'} sx={cssObject.reviewDividerStyle} />
                    <Grid container justifyContent={'center'} sx={{ p: '25px 0' }}>
                        <Grid item sx={{ mr: '5px' }}>
                            <ExternalLink
                                id={'external-link-facebook'}
                                variant={'subtitle2'}
                                color={'secondary'}
                                href={'https://www.facebook.com/AirAndHeatDoneRight/'}
                            >
                                <img width="32" height="32" src={Facebook} alt="facebook" />{' '}
                            </ExternalLink>
                        </Grid>
                        <Grid item sx={{ ml: '5px' }}>
                            <ExternalLink
                                id={'external-link-instagram'}
                                variant={'subtitle2'}
                                color={'secondary'}
                                href={'https://www.instagram.com/airandheatdoneright/'}
                            >
                                <img width="32" height="32" src={Instagram} alt="instagram" />
                            </ExternalLink>
                        </Grid>
                    </Grid>
                </>
            )}
        </ContentContainer>
    );
}

export default Footer;
