import React from 'react';
import { Theme, useTheme } from '@mui/material/styles';
import Checkbox from '@mui/material/Checkbox';
import FormControl from '@mui/material/FormControl';
import { SXCssObject } from 'models/ThemeImages';

const createCssObject = (theme: Theme): SXCssObject => ({
    checkbox: {
        '&.MuiCheckbox-root': {
            color: theme.palette.divider,
        },
        '&.Mui-checked': {
            color: theme.palette.action.hover,
        },
    },
});

interface Props {
    id: string;
    label?: any;
    checked: boolean;
    onChange: () => void;
    error?: boolean;
    enableErrorDisplay?: boolean;
    helperText?: string;
    fullWidth?: boolean;
}
export function CheckBox(props: Props) {
    const cssObject = createCssObject(useTheme());
    const { id, checked, onChange } = props;
    return (
        <FormControl id={`${id}-label`}>
            <Checkbox
                id={`${id}-check-box`}
                color={'primary'}
                checked={checked}
                onChange={onChange}
                sx={cssObject.checkbox}
            />
        </FormControl>
    );
}
