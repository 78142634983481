import { useTheme } from '@mui/material/styles';
import { getThemePrimaryMain } from 'components/common/Utilities';
import * as React from 'react';

function OrderIcon(props: any) {
    const theme = useTheme();
    let color = getThemePrimaryMain(theme);
    if (props.color) {
        //Color can be passed in props dynamically; if color is not passed, use primary.main
        color = props.color;
    }
    return (
        <svg
            id="Component_32_125"
            data-name="Component 32 – 125"
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
        >
            <g id="Rectangle_74" data-name="Rectangle 74" fill="#fff" stroke="#707070" strokeWidth="1" opacity="0">
                <rect width="24" height="24" stroke="none" />
                <rect x="0.5" y="0.5" width="23" height="23" fill="none" />
            </g>
            <g id="Group_666" data-name="Group 666" transform="translate(4.709 4.544)">
                <path
                    id="Path_84"
                    data-name="Path 84"
                    d="M461.056,405.328l-5.811,2.9a1.477,1.477,0,0,1-1.321,0l-5.81-2.9a1.481,1.481,0,0,1-.82-1.324v-7.158a1.478,1.478,0,0,1,.82-1.324l5.81-2.9a1.483,1.483,0,0,1,1.321,0l5.811,2.9a1.478,1.478,0,0,1,.82,1.324V404A1.481,1.481,0,0,1,461.056,405.328Z"
                    transform="translate(-447.294 -392.469)"
                    fill="none"
                    stroke={`${color}`}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="1.9"
                />
                <path
                    id="Path_85"
                    data-name="Path 85"
                    d="M448.065,396.538l6.62,3.054,6.62-3.054"
                    transform="translate(-447.395 -392.659)"
                    fill="none"
                    stroke={`${color}`}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="1.9"
                />
                <line
                    id="Line_86"
                    data-name="Line 86"
                    y2="7.757"
                    transform="translate(7.291 7.72)"
                    fill="none"
                    stroke={`${color}`}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="1.9"
                />
            </g>
        </svg>
    );
}

export default OrderIcon;
