import { reducer, on } from 'ts-action';
import {
    getProductAttribute,
    getProductAttributeError,
    getProductAttributeSuccess,
} from '../actions/productAttributeActions';
import { ProductAttribute, ProductAttributeId } from '../../models/productAttributes';

export type ProductAttributesState = {
    [id in ProductAttributeId]?: ProductAttribute;
} & {
    loading: boolean;
};

const initialState: ProductAttributesState = {
    loading: false,
};

export const productAttributesReducer = reducer(
    initialState,
    on(getProductAttribute, (state) => ({
        ...state,
        loading: true,
    })),
    on(getProductAttributeSuccess, (state, action) => ({
        ...state,
        [action.payload.id]: action.payload.value,
        loading: false,
    })),
    on(getProductAttributeError, (state, action) => ({
        ...state,
        error: action.payload,
        loading: false,
    }))
);

export default productAttributesReducer;
