import React, { MouseEventHandler } from 'react';
import { Theme, useTheme } from '@mui/material/styles';
import Avatar from '@mui/material/Avatar';
import { ThemeWithAdditionalData } from 'themes/theme';
import { SXCssObject, SXCssValueType } from 'models/ThemeImages';
import { Box } from '@mui/material';

const createCssObject = (theme: Theme): SXCssObject => ({
    root: {
        width: theme.spacing(5),
        height: theme.spacing(5),
        backgroundColor: 'transparent',
        '&>svg': {
            flex: 1,
        },
    },
    linkStyle: {
        display: 'inline-flex',
        alignItems: 'center',
        WebkitTapHighlightColor: 'transparent',
        cursor: 'pointer',
    },
});
interface Props {
    id?: string;
    color?: 'primary' | 'secondary';
    onClick?: MouseEventHandler;
    sx?: SXCssValueType;
}

export function Logo(props: Props) {
    const cssObject = createCssObject(useTheme());
    const theme = useTheme<ThemeWithAdditionalData>();
    const { sx, ...other } = props;
    return (
        <Avatar alt="Logo" sx={[cssObject.root, cssObject.linkStyle, sx !== undefined && sx]} {...other}>
            <img src={theme.images.logoMobile} alt={'Logo'} onClick={props.onClick} />
        </Avatar>
    );
}

export function WizardLogoNewWithLink(props: Props) {
    const { id } = props;
    const theme = useTheme<ThemeWithAdditionalData>();
    const cssObject = createCssObject(useTheme());
    return (
        <Box id={id} sx={cssObject.linkStyle} onClick={props.onClick}>
            <img src={theme.images.logoDesktop} height={'48px'} alt="AAHDRLogo" />
        </Box>
    );
}
