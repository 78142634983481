import React from 'react';

function EquipmentSelection() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" data-name="Component 39 – 1" viewBox="0 0 24 24">
            <rect
                width="20.789"
                height="12.44"
                fill="none"
                stroke="#1dbaaf"
                strokeWidth="1.5"
                data-name="Rectangle 697"
                rx="2"
                transform="translate(1.5 2)"
            ></rect>
            <path
                fill="none"
                stroke="#1dbaaf"
                strokeWidth="1.5"
                d="M4.38 14.441l1.293-2.345a1.76 1.76 0 011.542-.911h10.834a1.762 1.762 0 011.56.945l1.19 2.273"
                data-name="Path 76"
            ></path>
            <path
                fill="none"
                stroke="#1dbaaf"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.5"
                d="M0 0L0 2.838"
                data-name="Line 67"
                transform="translate(6.304 17.13)"
            ></path>
            <path
                fill="none"
                stroke="#1dbaaf"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.5"
                d="M0 0L0 4.731"
                data-name="Line 68"
                transform="translate(9.447 17.13)"
            ></path>
            <path
                fill="none"
                stroke="#1dbaaf"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.5"
                d="M0 0L0 2.838"
                data-name="Line 69"
                transform="translate(12.589 17.13)"
            ></path>
            <path
                fill="none"
                stroke="#1dbaaf"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.5"
                d="M0 0L0 4.731"
                data-name="Line 70"
                transform="translate(15.732 17.13)"
            ></path>
            <path
                fill="none"
                stroke="#1dbaaf"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.5"
                d="M0 0L0 2.838"
                data-name="Line 71"
                transform="translate(18.874 17.13)"
            ></path>
            <circle
                cx="0.671"
                cy="0.671"
                r="0.671"
                fill="none"
                stroke="#1dbaaf"
                strokeWidth="1.5"
                data-name="Ellipse 34"
                transform="translate(4.691 4.425)"
            ></circle>
        </svg>
    );
}

export default EquipmentSelection;
