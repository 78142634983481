export enum GACategory {
    EVENT_CATEGORY_CART = 'Cart Behavior',
    EVENT_CATEGORY_PROFILE = 'Profile Behavior',
    EVENT_CATEGORY_SEARCH = 'Search Behavior',
    LOGIN_BEHAVIOUR = 'Login Behavior',
    PRE_CART_BEHAVIOUR = 'PreCart Behavior',
    PAYMENT_BEHAVIOUR = 'Payment Behavior',
    EVENT_CATEGORY_ORDER = 'Purchase Behavior',
    EVENT_BEHAVIOUR = 'Static Page Behavior',
}
export enum GAEvents {
    Logout = 'logout',
    AddToCart = 'add_to_cart',
    RemoveFromCart = 'remove_from_cart',
    ProductSearch = 'product_search',
    CartAddonIncreased = 'CartAddonIncreased',
    CartAddonDecreased = 'CartAddonDecreased',
    PayNowClickedProfileMenu = 'PayNow_Clicked_ProfileMenu',
    ContactPageSubmitLoggedIn = 'ContactPageSubmitExistingUserLoggedIn',
    ContactPageSignUp = 'ContactPageSignUp',
    ContactPageSubmitNotLoggedIn = 'ContactPageSubmitExistingUserNotLoggedIn',
    CartGenerateQuote = 'CartGenerateQuote',
    SelectionGenerateQuote = 'SelectionGenerateQuote',
    PaypalComplete = 'PaypalComplete',
    CreditCardComplete = 'CreditCardComplete',
    PaypalPartial = 'PaypalPartial',
    CreditCardPartial = 'CreditCardPartial',
    PaypalFinal = 'PaypalFinal',
    CreditCardFinal = 'CreditCardFinal',
    EditFirstSystem = 'EditFirstSystem',
    ProfileReGenerateQuote = 'ProfileReGenerateQuote',
    ProfileDeleteQuote = 'ProfileDeleteQuote',
    ProfileDownloadQuote = 'ProfileDownloadQuote',
    ProfileMoveQuoteToCart = 'ProfileMoveQuoteToCart',
    SystemGroupDeletePopup = 'SystemGroupDeletePopup',
    ItemDeletePopup = 'ItemDeletePopup',
    CartDeleteIconClicked = 'CartDeleteIconClicked',
    AddAnotherSystemFromCart = 'AddAnotherSystemFromCart',
    CartChangeClicked = 'CartChangeClicked',
    ContinueRecommendationsPage = 'ContinueRecommendationsPage',
    ShopIAQRecommendationsPage = 'ShopIAQRecommendationsPage',
    CartIAQRemoved = 'CartIAQRemoved',
    PayNowClickedMyOrdersPage = 'PayNowClickedMyOrdersPage',
    CartPageAddNewHomeAddressDialog = 'CartPageAddNewHomeAddressDialog',
    HomeAddressCatpure = 'HomeAddressCapture',
    HomeAddressUpdate = 'HomeAddressUpdate',
    BillingAddressCatpure = 'BillingAddressCapture',
    BillingAddressUpdate = 'BillingAddressUpdate',
    CartDeleted = 'CartDeleted',
    ItemDeleted = 'ItemDeleted',
    SystemDeleted = 'SystemDeleted',
    PromocodeApplied = 'PromocodeApplied',
    WizardPageLogin = 'WizardPageLogin',
    WizardPageSignUp = 'WizardPageSignUp',
    ProfilePropertyAdded = 'ProfilePropertyAdded',
    ProfilePropertyUpdated = 'ProfilePropertyUpdated',
    ProfilePropertyDeleted = 'ProfilePropertyDeleted',
    HomePageLogin = 'HomePageLogin',
    HomePageSignUp = 'HomePageSignUp',
    CheckoutPageVerification = 'CheckoutPageVerification',
    AddProfileDetails = 'AddProfileDetails',
    UpdateProfile = 'UpdateProfile',
    PromocodeRemoved = 'PromocodeRemoved',
    DaikinFriendsAndFamilyFormSubmitted = 'DaikinFriendsAndFamilyFormSubmitted'
}
export enum GAPageView {
    HomePageLoginDialog = 'HomePageLoginDialog',
    NoProductPopup = 'NoProductPopup',
    CheckoutVerificationDialog = 'CheckoutVerificationDialog',
    AddonCompatibilityPopup = 'AddonCompatibilityPopup',
    DeleteConfirmationDialog = 'DeleteConfirmationDialog',
    QuotationSorryDialog = 'QuotationSorryDialog',
    QuotationMoveToCartDialog = 'QuotationMoveToCartDialog',
    QuotationContactSalesDialog = 'QuotationContactSalesDialog',
    ScheduleAcknowledgementDialog = 'ScheduleAcknowledgementDialog',
    ChooseShippingAddressDialog = 'ChooseShippingAddressDialog',
    ChooseBillingAddressDialog = 'ChooseBillingAddressDialog',
    AddOrEditHomeAddressDialog = 'AddOrEditHomeAddressDialog',
    AddOrEditBillingAddressDialog = 'AddOrEditBillingAddressDialog',
    AddOrEditPropertiesDialog = 'AddOrEditPropertiesDialog',
    WizardPageLoginDialog = 'WizardPageLoginDialog',
}
