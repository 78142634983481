import {
    Box,
    Card,
    CardContent,
    CardHeader,
    Grid,
    IconButton,
    ClickAwayListener,
    Grow,
    Popper,
    Theme,
    useTheme,
    MenuList,
    Paper,
    MenuItem,
} from '@mui/material';
import React from 'react';
import { Address } from '../../models/cart';
import { MoreVert } from '@mui/icons-material';
import { Paragraph } from 'components/Paragraph';
import { PropertyMenus, PropertyMenu } from './PropertyMenusList';
import { getThemePrimaryMain } from 'components/common/Utilities';
import { SXCssObject } from 'models/ThemeImages';
interface Props {
    address: Address;
    onMenuClick: (address: Address, key: string) => void;
}

const createCssObject = (theme: Theme): SXCssObject => ({
    menuLayout: {
        position: 'relative',
    },
    menuOverflowIconStyle: {
        padding: '6px',
        cursor: 'pointer',
    },
    cardContentStyle: {
        paddingTop: 0,
        paddingBottom: '16px',
    },
    menuPaperStyle: {
        overflow: 'visible',
        borderRadius: '4px',
        maxWidth: '180px',
        position: 'relative',
        marginTop: '12px',
        boxShadow: '0px 5px 31px #0000004F',
        '&:before': {
            content: '""',
            display: 'block',
            position: 'absolute',
            right: '-4px',
            top: '12px',
            width: '12px',
            height: '12px',
            backgroundColor: theme.palette.common.white,
            transform: 'translateY(-50%) rotate(45deg)',
            zIndex: '3px',
        },
    },
    menuItemStyle: {
        display: 'flex',
        alignItems: 'center',
        zIndex: 4,
        width: '150px',
        paddingLeft: '8px',
        paddingRight: '8px',
        margin: '0px 8px',
        '&:hover': {
            backgroundColor: theme.customProperties.lightGrey,
        },
    },
    menuItemIconStyle: {
        paddingRight: '10px',
        display: 'flex',
        alignItems: 'center',
    },
});

export function PropertyDetails(props: Props) {
    let { address, onMenuClick } = props;
    const cssObject = createCssObject(useTheme());
    const isDefaultBillingAddress = address.defaultBilling;

    const theme = useTheme();
    const [isOptionsOpen, setOptionsVisibility] = React.useState(false);
    const anchorRef = React.useRef<HTMLButtonElement>(null);

    const handleClick = () => {
        setOptionsVisibility(!isOptionsOpen);
    };

    const handleClose = () => {
        setOptionsVisibility(false);
    };

    //If the address is defaultBillingAddress, then hide 'Delete' menu
    const checkAndRemoveDeleteInMenu = () => {
        if (isDefaultBillingAddress) {
            return [{ ...PropertyMenus[0] }];
        }
        return PropertyMenus;
    };

    return (
        <Grid item xs={12} sm={6} md={4}>
            <Card
                sx={{
                    borderRadius: '4px',
                    backgroundColor: 'common.white',
                    boxShadow: 'none',
                    overflow: 'visible',
                }}
            >
                <CardHeader
                    sx={{
                        pb: '0px',
                    }}
                    action={
                        <Box sx={cssObject.menuLayout}>
                            <IconButton
                                ref={anchorRef}
                                aria-label="settings"
                                sx={cssObject.menuOverflowIconStyle}
                                onClick={handleClick}
                            >
                                <MoreVert style={{ color: theme.customProperties.borderDivider }} />
                            </IconButton>
                            <Popper
                                anchorEl={anchorRef.current}
                                placement="left-start"
                                open={isOptionsOpen}
                                transition
                                disablePortal
                            >
                                {({ TransitionProps }) => (
                                    <Grow {...TransitionProps}>
                                        <Paper sx={cssObject.menuPaperStyle}>
                                            <ClickAwayListener
                                                onClickAway={() => {
                                                    handleClose();
                                                }}
                                            >
                                                <MenuList id="menu-list-quote">
                                                    {checkAndRemoveDeleteInMenu().map(
                                                        (menuItem: PropertyMenu, index: number) => {
                                                            return (
                                                                <MenuItem
                                                                    key={index}
                                                                    sx={cssObject.menuItemStyle}
                                                                    onClick={() => {
                                                                        onMenuClick(address, menuItem.label);
                                                                        handleClose();
                                                                    }}
                                                                >
                                                                    <Box sx={cssObject.menuItemIconStyle}>
                                                                        <menuItem.icon
                                                                            color={getThemePrimaryMain(theme)}
                                                                        />
                                                                    </Box>
                                                                    {menuItem.label}
                                                                </MenuItem>
                                                            );
                                                        }
                                                    )}
                                                </MenuList>
                                            </ClickAwayListener>
                                        </Paper>
                                    </Grow>
                                )}
                            </Popper>
                        </Box>
                    }
                    title={
                        <Paragraph semibold headingColor>
                            {address.addressName}
                        </Paragraph>
                    }
                ></CardHeader>
                <CardContent sx={cssObject.cardContentStyle}>
                    <Box>
                        <Paragraph>{address.firstname + ' ' + address.lastname}</Paragraph>
                        <Paragraph size={'small'}>
                            {address.address1 + ', ' + (address.address2 ? address.address2 + ', ' : '')}
                            <br />
                            {address.city + ', ' + address.region + ', ' + address.postcode}
                        </Paragraph>
                    </Box>
                </CardContent>
            </Card>
        </Grid>
    );
}
