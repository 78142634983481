import { on, reducer } from 'ts-action';
import { Cart, CartStatus, SystemGroup } from '../../models/cart';
import { Product } from '../../models/product';

import {
    setCart,
    createCart,
    createCartSuccess,
    createCartError,
    placeOrder,
    placeOrderForEquipmentOnly,
    placeOrderSuccess,
    placeOrderError,
    resetCart,
    deleteCart,
    addCartItem,
    addCartItemAndRedirectToWizard,
    addCartItemAndRedirectToCart,
    getCart,
    myCartSuccess,
    myCartError,
    deleteCartError,
    deleteMultipleItems,
    deleteMultipleItemSuccess,
    setAddOnCompabilityCheck,
    addCartItemError,
    setBillingAddressToCartSuccess,
    setBillingAddressToCartFailure,
    setBillingAddressToCart,
    scheduleInspection,
    scheduleInspectionSuccess,
    scheduleInspectionError,
    myCartAction,
    deleteCartSuccess,
    applyCouponSuccess,
    applyCouponError,
    resetCouponErr,
    removeCoupon,
    removeCouponSuccess,
    removeCouponError,
    cartProgress,
    updateCartItemFailure,
    updateCartItem,
    updateCartItemSuccess,
    changeOrRemoveIAQ,
    changeOrRemoveIAQSuccess,
    applyCouponAction,
    changeOrRemoveProduct,
    changeOrRemoveProductSuccess,
    changeOrRemoveProductFailure,
    deleteMainAndMakeIAQMain,
    cartCommonFailureAction,
    addInstructons,
} from '../actions/cartActions';
import { searchProductSuccess, searchSecondProductSuccess } from '../actions/productSearchActions';
import { createContact, contactInprogressSuccess } from '../actions/hubspotActions';
import {
    setShippingAddressToCart,
    setShippingAddressToCartFailure,
    setShippingAddressToCartSuccess,
} from 'store/actions/cartActions';
import { getOrderandReset, placeFinalOrder, placeFinalOrderEquipmentOnly } from 'store/actions/orderActions';
import { moveQuoteToCart, moveQuoteToCartSuccess, moveQuoteToCartError } from 'store/actions/quotes';
import { setProductCriteria } from 'store/actions/productCriteriaActions';
import { NumberOfHVAC } from 'models/productCriteria';
const initialState: Cart = {};
export const cartReducer = reducer(
    initialState,
    on(setCart, (state, action) => ({
        ...state,
        ...action.payload,
    })),
    on(createCart, (state, action) => ({
        ...state,
        loading: true,
        error: null,
    })),
    on(deleteCart, (state, action) => ({
        ...state,
        appliedCoupons: [],
        instructions: '',
        loading: true,
        error: null,
    })),
    on(addCartItem, (state, action) => ({
        ...state,
        loading: true,
        error: null,
    })),
    on(addCartItemAndRedirectToCart, (state, action) => ({
        ...state,
        loading: true,
        error: null,
    })),
    on(addCartItemAndRedirectToWizard, (state, action) => ({
        ...state,
        loading: true,
        error: null,
    })),
    on(getCart, (state, action) => ({
        ...state,
        loading: true,
        error: null,
    })),

    on(createCartSuccess, (state, action) => ({
        ...state,
        ...action.payload,
        error: null,
        loading: false,
    })),
    on(myCartAction, (state, action) => ({
        ...state,
        loading: true,
        error: null,
    })),
    on(myCartSuccess, (state, action) => ({
        ...state,
        ...action.payload.cart,
        loading: false,
    })),
    on(moveQuoteToCart, (state, action) => ({
        ...state,
        appliedCoupons: action.payload?.appliedCoupons,
        couponApplyError: '',
        loading: true,
        error: null,
    })),
    on(moveQuoteToCartSuccess, (state, action) => ({
        ...state,
        ...action.payload,
        loading: false,
    })),
    on(moveQuoteToCartError, (state, action) => ({
        ...state,
        error: action.payload,
        loading: false,
    })),
    on(getOrderandReset, (state, action) => ({
        ...state,
        loading: false,
    })),
    on(createCartError, (state, action) => ({
        ...state,
        error: action.payload,
        loading: false,
    })),
    on(deleteCartSuccess, (state, action) => ({
        ...state,
        ...action.payload,
        status: action.payload.status ? action.payload.status : CartStatus.READY_TO_SCHEDULE,
        loading: false,
    })),
    on(deleteCartError, (state, action) => ({
        ...state,
        error: action.payload,
        loading: false,
    })),
    on(deleteMultipleItems, (state, action) => ({
        ...state,
        loading: true,
        error: null,
    })),
    on(deleteMultipleItemSuccess, (state, action) => ({
        ...state,
        loading: false,
        ...action.payload,
        status: action.payload.status ? action.payload.status : CartStatus.READY_TO_SCHEDULE,
    })),
    on(setAddOnCompabilityCheck, (state, action) => ({
        ...state,
        shouldCheckForAddOnCompatibility: action.payload,
    })),
    on(addCartItemError, (state, action) => ({
        ...state,
        error: action.payload,
        loading: false,
    })),
    on(myCartError, (state, action) => ({
        ...state,
        error: action.payload,
        loading: false,
    })),

    on(placeOrder, (state, action) => ({
        ...state,
        ...action.payload.cart,
        paymentMethod: action.payload.paymentMethod,
        loading: true,
        error: null,
    })),
    on(placeOrderForEquipmentOnly, (state, action) => ({
        ...state,
        ...action.payload.cart,
        paymentMethod: action.payload.paymentMethod,
        loading: true,
        error: null,
    })),
    //action to make payment API call-for final payment
    on(placeFinalOrder, (state, action) => ({
        ...state,
        ...action.payload,
        loading: true,
        error: null,
    })),
    on(placeFinalOrderEquipmentOnly, (state, action) => ({
        ...state,
        ...action.payload,
        loading: true,
        error: null,
    })),
    on(placeOrderSuccess, (state, action) => ({
        ...state,
        ...action.payload,
        loading: false,
        error: null,
    })),
    on(placeOrderError, (state, action) => ({
        ...state,
        error: action.payload,
        loading: false,
    })),
    on(resetCart, (state, action) => ({
        ...initialState,
    })),
    on(searchProductSuccess, (state, action) => {
        delete state.contactInformation?.quotedOptionsB;
        return {
            ...state,
            contactInformation: {
                ...state.contactInformation,
                quotedOptions: {
                    quotedOption1: productString(
                        action.payload.products?.find((p) => p.productAttributes?.level === 'Bronze')
                    ),
                    quotedOption2: productString(
                        action.payload.products?.find((p) => p.productAttributes?.level === 'Silver')
                    ),
                    quotedOption3: productString(
                        action.payload.products?.find((p) => p.productAttributes?.level === 'Gold')
                    ),
                    quotedOption4: productString(
                        action.payload.products?.find((p) => p.productAttributes?.level === 'Platinum')
                    ),
                },
            },
        };
    }),
    on(setProductCriteria, (state, action) => {
        return {
            ...state,
            contactInformation: {
                ...state.contactInformation,
                searchCriteria: {
                    ...(state.contactInformation?.wizardSelectedOptions?.numberOfSystems === NumberOfHVAC.Double &&
                    action.payload.numberOfHVAC === NumberOfHVAC.Double &&
                    !state?.items?.find((item) => item.systemGroup === SystemGroup.SystemGroup2) &&
                    state?.items &&
                    state?.items?.length > 0
                        ? {
                              firstReplacement: { ...state?.contactInformation?.searchCriteria },
                              secondReplacement: action.payload,
                          }
                        : {
                              ...action.payload,
                          }),
                },
            },
        };
    }),
    on(searchSecondProductSuccess, (state, action) => ({
        ...state,
        contactInformation: {
            ...state.contactInformation,
            quotedOptionsB: {
                quotedOption1: productString(
                    action.payload.productsSystemTwo?.find((p) => p.productAttributes?.level === 'Bronze')
                ),
                quotedOption2: productString(
                    action.payload.productsSystemTwo?.find((p) => p.productAttributes?.level === 'Silver')
                ),
                quotedOption3: productString(
                    action.payload.productsSystemTwo?.find((p) => p.productAttributes?.level === 'Gold')
                ),
                quotedOption4: productString(
                    action.payload.productsSystemTwo?.find((p) => p.productAttributes?.level === 'Platinum')
                ),
            },
        },
    })),
    on(setShippingAddressToCart, (state, action) => ({
        ...state,
        loading: true,
    })),
    on(setShippingAddressToCartSuccess, (state, action) => ({
        ...state,
        ...action.payload,
        loading: false,
    })),
    on(setShippingAddressToCartFailure, (state, action) => ({
        ...state,
        loading: false,
    })),
    on(setBillingAddressToCart, (state, action) => ({
        ...state,
        loading: true,
    })),
    on(setBillingAddressToCartSuccess, (state, action) => ({
        ...state,
        ...action.payload,
        loading: false,
    })),
    on(setBillingAddressToCartFailure, (state, action) => ({
        ...state,
        loading: false,
    })),
    on(createContact, (state, action) => ({
        ...state,
        contactInformation: {
            ...state.contactInformation,
            ...action.payload,
        },
    })),
    on(contactInprogressSuccess, (state, action) => ({
        ...state,
        contactInformation: {
            ...action.payload,
        },
    })),
    on(scheduleInspection, (state, action) => ({
        ...state,
        loading: true,
    })),
    on(scheduleInspectionSuccess, (state, action) => ({
        ...state,
        status: action.payload,
        loading: false,
    })),
    on(scheduleInspectionError, (state, action) => ({
        ...state,
        loading: false,
    })),
    on(applyCouponAction, (state, action) => ({
        ...state,
        loading: true,
        error: null,
        isErrorInCoupon: '',
    })),
    on(applyCouponSuccess, (state, action) => ({
        ...state,
        appliedCoupons: action.payload.appliedCoupons,
        prices: action.payload.prices,
        loading: false,
        isErrorInCoupon: '',
    })),
    on(applyCouponError, (state, action) => ({
        ...state,
        isErrorInCoupon: action.payload,
        loading: false,
    })),
    on(removeCoupon, (state, action) => ({
        ...state,
        loading: true,
        error: null,
    })),
    on(removeCouponSuccess, (state, action) => ({
        ...state,
        appliedCoupons: action.payload.appliedCoupons,
        prices: action.payload.prices,
        loading: false,
        error: null,
    })),
    on(removeCouponError, (state, action) => ({
        ...state,
        error: action.payload,
        loading: false,
    })),
    on(cartProgress, (state, action) => ({
        ...state,
        loading: action.payload.loading,
    })),
    on(updateCartItem, (state, action) => ({
        ...state,
        loading: true,
    })),
    on(updateCartItemSuccess, (state, action) => ({
        ...state,
        loading: false,
        error: null,
        ...action.payload,
    })),
    on(updateCartItemFailure, (state, action) => ({
        ...state,
        loading: false,
        error: action.payload,
    })),
    on(changeOrRemoveIAQ, (state, action) => ({
        ...state,
        loading: true,
    })),
    on(changeOrRemoveIAQSuccess, (state, action) => ({
        ...state,
        loading: false,
        error: null,
        ...action.payload,
    })),
    on(changeOrRemoveProduct, (state, action) => ({
        ...state,
        loading: true,
    })),
    on(changeOrRemoveProductSuccess, (state, action) => ({
        ...state,
        loading: false,
        error: null,
        ...action.payload,
    })),
    on(changeOrRemoveProductFailure, (state, action) => ({
        ...state,
        loading: false,
        error: action.payload,
    })),
    on(deleteMainAndMakeIAQMain, (state, action) => ({
        ...state,
        loading: true,
        error: null,
    })),
    on(resetCouponErr, (state, action) => ({
        ...state,
        isErrorInCoupon: '',
    })),
    on(cartCommonFailureAction, (state, action) => ({
        ...state,
        loading: false,
        error: action.payload,
    })),
    on(addInstructons, (state, action) => ({
        ...state,
        instructions: action.payload,
    }))
);

function productString(p: Product | undefined) {
    return p ? `${p.name} - $${p.price}` : undefined;
}

export default cartReducer;
