import { Container, useTheme } from '@mui/material';
import React from 'react';
import Grid from '@mui/material/Grid';
import { Box } from '@mui/material';
import ContentContainer from 'components/ContentContainer';
import { Theme } from '@mui/material/styles';
import { Paragraph } from 'components/Paragraph';
import { Heading } from 'components/Heading';
import { SXCssObject } from 'models/ThemeImages';

const createCssObject = (theme: Theme): SXCssObject => ({
    root: {
        padding: {
            xs: '40px 16px',
            sm: '40px 40px',
            md: '80px 0px',
            lg: '120px 0px',
        },
    },
    warrantyInfoLabel: {
        backgroundColor: theme.palette.primary.light,
        padding: '8px 16px',
        marginBottom: '16px',
        color: theme.palette.primary.main,
        fontWeight: theme.typography.fontWeightMedium,
        fontSize: '1.125rem',
    },
    warrantyInfoWrapper: {
        width: '100%',
        borderRadius: '18px',
        background: theme.palette.common.white,
        display: 'flex',
        alignItems: 'center',
        textAlign: 'center',
        flexDirection: 'column',
        padding: { xs: '18px', sm: '24px' },
    },
    headingStyle: {
        marginBottom: {
            xs: '20px',
            sm: '30px',
            md: '50px',
        },
        textAlign: 'center',
    },
    descriptionStyle: {
        width: {
            sm: '66%',
        },
        marginLeft: {
            sm: '17%',
        },
        marginTop: {
            xs: '16px',
            sm: '20px',
        },
    },
    dividerStyle: {
        width: '10%',
        verticalAlign: 'middle',
        margin: '0px 4px',
        border: 'none',
        borderTop: '1px solid #CECECE',
        display: {
            xs: 'none',
            sm: 'inline-block',
        },
        opacity: '0.4',
    },
    warantyIncludesText: {
        margin: {
            xs: '0px 12px',
            sm: 'inherit',
        },
    },
});
function HomeWarranty() {
    const cssObject: SXCssObject = createCssObject(useTheme());
    return (
        <ContentContainer disableGrid sx={cssObject.root}>
            <Container maxWidth={'lg'} disableGutters>
                <Box sx={cssObject.headingStyle}>
                    <Heading label={'Industry leading warranty'} />
                    <Box sx={cssObject.descriptionStyle}>
                        <Paragraph responsiveFontSize18>
                            Be comfortable and 100% confident in your new installed HVAC system with our parts and labor
                            warranties.
                        </Paragraph>
                    </Box>
                </Box>

                <Grid container justifyContent={'center'}>
                    <Grid
                        item
                        xs={12}
                        sm={10}
                        md={8}
                        style={{ marginBottom: '24px' }}
                        container
                        justifyContent={'center'}
                        alignItems={'center'}
                    >
                        <Box component={'hr'} sx={cssObject.dividerStyle} />
                        <Paragraph size={'normal'} semibold color={'primary'} align={'center'} headingColor>
                            <Box component={'span'} sx={cssObject.warantyIncludesText}>
                                {' '}
                                All of our installed HVAC systems come with{' '}
                            </Box>
                        </Paragraph>
                        <Box component={'hr'} sx={cssObject.dividerStyle} />
                    </Grid>

                    <Grid item container spacing={2} xs={12} md={9} lg={8} style={{ marginTop: '8px' }}>
                        <Grid item xs={12} sm={4} style={{ display: 'flex' }}>
                            <Box sx={cssObject.warrantyInfoWrapper}>
                                <Box component={'span'} sx={cssObject.warrantyInfoLabel}>
                                    10-YEAR
                                </Box>
                                <Paragraph>Limited Parts Warranty</Paragraph>
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={4} style={{ display: 'flex' }}>
                            <Box sx={cssObject.warrantyInfoWrapper}>
                                <Box component={'span'} sx={cssObject.warrantyInfoLabel}>
                                    1-YEAR
                                </Box>
                                <Paragraph>Full labour Warranty</Paragraph>
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={4} style={{ display: 'flex' }}>
                            <Box sx={cssObject.warrantyInfoWrapper}>
                                <Box component={'span'} sx={cssObject.warrantyInfoLabel}>
                                    UPTO 10-YEAR
                                </Box>
                                <Paragraph>Extended Labor Warranty</Paragraph>
                            </Box>
                        </Grid>
                    </Grid>
                </Grid>
            </Container>
        </ContentContainer>
    );
}

export default HomeWarranty;
