import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import IAQOptions from './IAQOptions';
import { Cart, CartItem, ItemType, SystemGroup } from '../../models/cart';
import { selectCart, selectCartProcessing, selectProceedToCart } from '../../store/selectors/cart';
import {
    addCartItem,
    changeOrRemoveIAQ,
    changeOrRemoveProduct,
    resetIAQSelection,
    setIAQSelection,
} from '../../store/actions/cartActions';
import { selectProductLoading, selectProductsIAQ } from '../../store/selectors/productSearch';
import { selectProductCriteriaState } from '../../store/selectors/productCriteria';
import { Navigate, useNavigate, useLocation } from 'react-router-dom';
import { selectPreCartIAQ } from 'store/selectors/selection';
import { Product } from 'models/product';
import { AppRoute } from 'models/route';
import { addressState } from 'store/selectors/addressSelector';

function IAQOptionContainer() {
    const dispatch = useDispatch();
    const criteria = useSelector(selectProductCriteriaState);
    const productsIAQ = useSelector(selectProductsIAQ);
    const loading = useSelector(selectCartProcessing);
    const addressData = useSelector(addressState);
    const preCartItemsIAQ = useSelector(selectPreCartIAQ);
    const isproductSearchLoading: boolean = useSelector(selectProductLoading);
    const systemGroupRouteFlag: any = useLocation();
    const systemGroup = systemGroupRouteFlag.state && systemGroupRouteFlag.state.systemGroup;
    const secondSystem = systemGroup === SystemGroup.SystemGroup2 ? true : false;
    const cart: Cart = useSelector(selectCart);
    const { items: cartItems } = cart;
    const location: any = useLocation();
    const navigate = useNavigate();
    const proceedToCart = useSelector(selectProceedToCart);
    if (!location?.state?.isFullSystem) {
        return <Navigate to={AppRoute.Home} />;
    }
    if (!productsIAQ || productsIAQ.length <= 0) {
        return <Navigate to={AppRoute.Home} />;
    }
    const cartItemIAQ = cartItems?.find(
        (cartItem) => cartItem.itemType === ItemType.CrossSell && cartItem.systemGroup === systemGroup
    );
    const preCartItemIAQ = preCartItemsIAQ?.find(
        (cartItem) => cartItem.itemType === ItemType.CrossSell && cartItem.systemGroup === systemGroup
    );
    const sku = proceedToCart ? cartItemIAQ && cartItemIAQ.sku : preCartItemIAQ && preCartItemIAQ.sku;
    const isByClickingChangeInCartPage =
        location.state && location.state && location.state.isByClickingChangeInCartPage;
    const isByClickingChangeinSelection = location.state && location.state.isByClickingChangeinSelection;

    const setIAQItem = (product: Product) => {
        return {
            categories: product.categories || [],
            name: product.name,
            sku: product.sku,
            quantity: 1,
            id: product.id,
            description: product.description,
            image: product.image,
            price: product.price,
            thumbnail: product.thumbnail,
            totalPrice: product.price,
            productAttributes: product.productAttributes,
            relatedProducts: product.relatedProducts,
            systemGroup: systemGroup,
            itemType: ItemType.CrossSell,
        };
    };

    const handleOnSelectProduct = (product?: CartItem) => {
        if (product) {
            dispatch(
                setIAQSelection({
                    newIAQ: product,
                    systemGroupToSetOrChange:
                        systemGroup === SystemGroup.SystemGroup2 ? SystemGroup.SystemGroup2 : SystemGroup.SystemGroup1,
                })
            );
        } else {
            dispatch(resetIAQSelection({ systemGroupToReset: systemGroup }));
        }
    };
    function handleWizardFlow(selectedIAQItem?: CartItem) {
        const itemToDelete = cartItems?.find(
            (item) => item.systemGroup === systemGroup && item.itemType === ItemType.CrossSell
        );
        if (selectedIAQItem) {
            itemToDelete
                ? dispatch(
                    changeOrRemoveProduct({
                        itemToRemove: itemToDelete!.id,
                        itemToAdd: selectedIAQItem,
                        isRemoveItemOnly: false,
                        shouldRedirectToCartPage: false,
                    })
                )
                : dispatch(addCartItem({ cartItems: [selectedIAQItem], shouldBlockSaveForLaterApi: true }));
        } else {
            itemToDelete &&
                dispatch(
                    changeOrRemoveIAQ({
                        isRemoveIAQ: true,
                        itemToRemove: itemToDelete!.id,
                    })
                );
        }
    }
    function handleBack() {
        if (isByClickingChangeInCartPage) {
            navigate(AppRoute.CartPage);
        } else if (isByClickingChangeinSelection) {
            navigate(AppRoute.EquipmentSelection);
        } else {
            navigate(AppRoute.EquipmentOptions, {
                state: {
                    isSecondSystem: secondSystem,
                    onBackSystemGroup: secondSystem ? SystemGroup.SystemGroup2 : SystemGroup.SystemGroup1,
                },
            });
        }
    }
    return (
        <IAQOptions
            products={productsIAQ}
            criteria={criteria}
            loading={loading || isproductSearchLoading || addressData.loading} //loading will be true when cart api is in progress or product search in progress
            secondSystem={secondSystem}
            iaqSKU={sku}
            cartMainSku={isByClickingChangeInCartPage}
            selectionMainSku={isByClickingChangeinSelection}
            onSelectProduct={(productIAQ) => {
                const selectedIAQItem = productIAQ && setIAQItem(productIAQ);
                handleOnSelectProduct(selectedIAQItem);
                proceedToCart && handleWizardFlow(selectedIAQItem);
            }}
            onBack={handleBack}
        />
    );
}

export default IAQOptionContainer;
