import * as React from 'react';
import { SVGProps } from 'react';
import { useTheme } from '@mui/material/styles';
import { getThemeSvgPrimaryMain } from 'components/common/Utilities';

const BackArrowIcon = (props: SVGProps<SVGSVGElement>) => {
    const theme = useTheme();
    const themePrimaryMain = getThemeSvgPrimaryMain(theme);
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} {...props}>
            <g data-name="Component 4 \u2013 51">
                <g data-name="Group 3112" fill="none" stroke={themePrimaryMain} strokeLinecap="round" strokeWidth={2}>
                    <path data-name="Path 241" d="m8.483 16.047-4.135-4.019 4.135-4.075" strokeLinejoin="round" />
                    <path data-name="Line 97" d="M4.467 12h14.896" />
                </g>
            </g>
        </svg>
    );
};

export default BackArrowIcon;
