import { Address } from 'models/cart';
import { AddressValidationFields, UserAddressDetails, ValidateAddressResponse } from 'models/userProfile';

import http, { getAsyncAwaitHttpObj } from '../../index';

export const getAddressApi = () => http().get<UserAddressDetails>('/Customer/me');

export const updateDefaultBillingAddress = (newAddress: Address, addressID: string) =>
    http().patch<Address>(`/Customer/address/${addressID}`, newAddress);

export const createDefaultProfileBillingAddress = (newAddress: Address) =>
    http().post<Address>(`/Customer/address`, newAddress);

export const deleteAddress = (id: string) => getAsyncAwaitHttpObj().delete<boolean>(`/Customer/address/${id}`);

export const addAddress = (address: Address) => getAsyncAwaitHttpObj().post<boolean>(`/Customer/address`, address);

export const editAddress = (id: string, address: Address) =>
    getAsyncAwaitHttpObj().patch<boolean>(`/Customer/address/${id}`, address);

export const addressValidate = (ValidateAddresses: AddressValidationFields[]) => {
    return http().post<ValidateAddressResponse[]>('/Customer/addresses/cityStateZipCode/validate', ValidateAddresses);
};
