import http, { getAsyncAwaitHttpObj } from '../../index';
import { Address, Cart, PaymentMethod, CartPayload, ItemPayload, ScheduleApiRequest } from '../../models/cart';
import { BrainTree } from '../../models/financial';
import find from 'lodash/find';
import reduce from 'lodash/reduce';
import config from '../../config';
import Cookies from 'js-cookie';

export const getCart = (cartId: string) => http().get<Cart>(`/cart/${cartId}`);
export const myCart = () => http().get<Cart>(`/carts/mine`);

export const deleteCart = (cartId: string) => http().delete<Cart>(`/Carts/${cartId}/items`);

export const deleteItemInCart = (cartId: string, itemId: string) =>
    http().delete<Cart>(`/Carts/${cartId}/items/${itemId}`);

export const deleteMultipleItemsInCart = (cartId: string, itemIds: Number[], reArrangeCartItems: boolean) =>
    http().delete<Cart>(`/Carts/${cartId}/multipleItems?reArrangeCartItems=${reArrangeCartItems}`, { data: itemIds });
export const addItem = (cart: CartPayload, gaParams?: any) => {
    const gaConfig = getGAConfigParams(gaParams);
    return http().post<Cart>(`/Carts/${cart.cartId}/items`, cart, gaConfig);
};
export const createCart = (cart: CartPayload, gaParams?: any) => {
    const gaConfig = getGAConfigParams(gaParams);
    return http().post<Cart>('/carts', cart, gaConfig);
};
export const saveForLater = (cart: Cart) => http().patch<Cart>(`/carts/${cart.cartId}/saveForLater`, cart);

export const placeOrder = (cartId: string, paymentMethod?: PaymentMethod) =>
    http().post(`/carts/${cartId}/placeOrder`, paymentMethod);

export const placeOrderForEquipmentOnly = (payload: {cart: Cart, paymentMethod?: PaymentMethod, contractorInfo: string} ) =>
    http().post(`/carts/${payload.cart.cartId}/placeOrderForEquipmentOnly`, {
        paymentMethod: payload.paymentMethod,
        contractorInfo: payload.contractorInfo
    });


export const updateCart = (cartId: string, cart: Cart) => http().patch<Cart>(`/carts/${cartId}`, cart);

export const setShippingAddressToCartApi = (req: Address, cartId: string) =>
    http().post<Cart>(`/Carts/${cartId}/shippingAddress`, req);

export const setBillingAddressToCartApi = (req: Address, cartId: string, useForShipping?: boolean) =>
    http().post<Cart>(`/Carts/${cartId}/billingAddress`, req, {
        params: { useForShipping },
    });

export const scheduleInspectionApi = (scheduleReq: ScheduleApiRequest, cartId: string) =>
    http().post(`/Carts/${cartId}/schedules`, scheduleReq);

export const getQuotes = () => http().get('/quotations');

export const applyCoupon = (coupon: string, cartId: string) =>
    http().post<Cart>(`/Carts/${cartId}/applyCoupon`, { couponCode: coupon });
export const deleteCoupon = (cartId: string) => http().delete<Cart>(`/Carts/${cartId}/removeCoupon`);

export const updateCartItem = (cartId: string, item: ItemPayload) =>
    http().patch<Cart>(`/Carts/${cartId}/items/${item.id}`, { ...item });
export const setupCheckOutAsync = (paymentMethod: PaymentMethod) =>
    getAsyncAwaitHttpObj().post<BrainTree>(`/carts/setupCheckout`, paymentMethod);
function getGAConfigParams(gaParams: any) {
    let httpConfig: any = undefined;
    try {
        // @ts-ignore
        window.ga(() => {
            // @ts-ignore
            const gaTracker = find(window?.ga?.getAll(), (t) => t.get('trackingId') === config.googleAnalyticId);
            const gaClientId = gaTracker ? gaTracker.get('clientId') : undefined;
            const gaGid = reduce(
                Cookies.get('_gid')?.split('.'),
                (result, value, key) => {
                    if (key === 2) {
                        result = value;
                    } else if (key > 2) {
                        result = `${result}.${value}`;
                    }
                    return result;
                },
                ''
            );
            httpConfig = gaClientId
                ? {
                      headers: {
                          ...gaParams,
                          gaClientId,
                          gaGid,
                      },
                  }
                : undefined;
        });
    } catch (e) {}
    return httpConfig;
}
