import * as React from 'react';
import { useTheme } from '@mui/material/styles';
import { getThemePrimaryMain } from 'components/common/Utilities';

export default function CurrencyIcon(props: any) {
    const theme = useTheme();
    const themePrimaryMain = getThemePrimaryMain(theme);
    return (
        <svg viewBox="0 0 50 50" data-name="Component 79 \u2013 1" xmlns="http://www.w3.org/2000/svg" {...props}>
            <rect data-name="Rectangle 3668" width={49} height={49} rx={3} fill={`${themePrimaryMain}`} />
            <path
                data-name="Duis dolor odio, vol"
                d="M31.95 27.67a4.824 4.824 0 01-1.443 3.549 6.985 6.985 0 01-3.887 1.859v2.912H23.5v-2.808a12.241 12.241 0 01-3.159-.65 8.923 8.923 0 01-2.535-1.352l1.3-2.886a9.5 9.5 0 005.538 1.794 4.25 4.25 0 002.431-.588 1.892 1.892 0 00.845-1.651 1.362 1.362 0 00-.468-1.066 3.454 3.454 0 00-1.183-.663q-.715-.247-2.041-.585a21.85 21.85 0 01-3.172-.985 5.521 5.521 0 01-2.145-1.6 4.35 4.35 0 01-.9-2.873 4.878 4.878 0 011.5-3.575 7.059 7.059 0 013.913-1.911V11.68h3.12v2.834a12.81 12.81 0 012.834.715 6.787 6.787 0 012.158 1.261l-1.3 2.886a8.893 8.893 0 00-5.1-1.794 3.818 3.818 0 00-2.249.6 1.925 1.925 0 00-.845 1.664 1.575 1.575 0 00.494 1.2 3.393 3.393 0 001.248.728q.754.26 2.106.6a23.911 23.911 0 013.094.962 5.278 5.278 0 012.093 1.534 4.235 4.235 0 01.873 2.8z"
                fill="#f5f5f5"
            />
        </svg>
    );
}
