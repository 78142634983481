import React, { Fragment } from 'react';
import { Theme, useTheme } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import { Heading } from 'components/Heading';
import { Paragraph } from 'components/Paragraph';
import Image from 'assets/images/energystar/energy-star_story_image.webp';
import CardMedia from '@mui/material/CardMedia';
import Link from '@mui/material/Link';
import StaticSubPage from 'components/subpage/StaticSubPage';
import { useMediaQuery } from '@mui/material';
import ArrowRight from 'components/icons/ArrowRight';
import { SXCssObject } from 'models/ThemeImages';
function EnergyStar() {
    const createCssObject = (theme: Theme): SXCssObject => {
        return {
            contentHeading: {
                paddingBottom: theme.spacing(1),
            },
            contentSecondaryHeading: {
                paddingTop: theme.spacing(4),
            },
            contentImage: {
                paddingTop: theme.spacing(2),
                paddingBottom: {
                    xs: '25px',
                    sm: theme.spacing(5),
                },
            },
            content: {
                paddingTop: theme.spacing(2),
            },
        };
    };
    const cssObject = createCssObject(useTheme());
    const theme = useTheme();
    const isXs = useMediaQuery(theme.breakpoints.only('xs'));
    return (
        <StaticSubPage
            content1={
                <Fragment>
                    <Grid item xs={12} sx={cssObject.contentHeading}>
                        <Heading variant={'h1'} color={'primary'} label={'Energy Star'} />
                    </Grid>
                    <Grid item xs={12} sx={cssObject.contentImage}>
                        <CardMedia component={'img'} image={Image} title={'Energy Star'} />
                    </Grid>
                    <Grid item xs={12} sx={cssObject.content}>
                        <Paragraph align={'justify'} size={isXs ? 'small' : 'normal'}>
                            Energy Star is a voluntary US Environmental Protection Agency program that provides guidance
                            to homeowners and businesses on the energy efficiency of the products that homeowners and
                            businesses purchase.
                        </Paragraph>
                    </Grid>
                    <Grid item xs={12} sx={cssObject.content}>
                        <Paragraph align={'justify'} size={isXs ? 'small' : 'normal'}>
                            Goodman has worked with the ENERGY STAR program to denote a number of its high-efficiency
                            systems for furnaces, central air conditioners, and air-sourced heat pumps. Additional
                            information explaining why a more efficient unit can result in improved home comfort is
                            <Link href={'https://www.goodmanmfg.com/resources/energy-star'} target={'_blank'}>
                                {' located at.'}
                            </Link>
                        </Paragraph>
                    </Grid>
                    <Grid item xs={12} sx={cssObject.content}>
                        <Paragraph align={'justify'}>
                            <ArrowRight />
                            <Link
                                href={'https://www.goodmanmfg.com/resources/new-efficiency-standards'}
                                target={'_blank'}
                            >
                                {' Regional Energy Efficiency Standards.'}
                            </Link>
                        </Paragraph>
                    </Grid>
                </Fragment>
            }
        />
    );
}

export default EnergyStar;
