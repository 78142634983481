import { useTheme } from '@mui/material/styles';
import { getThemePrimaryMain } from 'components/common/Utilities';
import * as React from 'react';

const ArrowToggleUp = (props: any) => {
    const theme = useTheme();
    const themePrimaryMain = getThemePrimaryMain(theme);
    return (
        <svg baseProfile="tiny-ps" xmlns="http://www.w3.org/2000/svg" width={24} height={24} {...props}>
            <path fill="#e7efe2" d="M12 24C5.36 24 0 18.64 0 12S5.36 0 12 0s12 5.36 12 12-5.36 12-12 12z" />
            <path
                d="M8 14.54 12.13 10l4.15 4.54"
                stroke={`${themePrimaryMain}`}
                style={{
                    fill: 'none',
                    strokeLinecap: 'round',
                    strokeLinejoin: 'round',
                    strokeWidth: 2,
                }}
            />
        </svg>
    );
};
export default ArrowToggleUp;
