import React, { useState } from 'react';
import ContentContainer from '../../components/ContentContainer';
import Grid from '@mui/material/Grid';
import { Theme, useTheme } from '@mui/material/styles';
import { Heading } from '../../components/Heading';
import Box from '@mui/material/Box';
import { Button } from '../../components/Button';
import { Address, CartItem, CartPrice, CartStatus, WizardSelectedOptions } from '../../models/cart';
import { Paragraph } from '../../components/Paragraph';
import { ThemeWithAdditionalData } from '../../themes/theme';
import useMediaQuery from '@mui/material/useMediaQuery';
import LoadingOverlay from '../../components/common/LoadingOverlay';
import Login from 'pages/user/Login';
import { useDispatch, useSelector } from 'react-redux';
import { selectLoginState } from 'store/selectors/loginSelector';
import { sendVerificationOtpToEmail } from 'store/services/loginServices';
import Navigator from '../../components/Navigator';
import { DOMEvent, LoginMessages, SCHEDULE_TOOLTIP } from 'components/common/constants';
import { ButtonWithIcon } from 'components/ButtonWithIcon';
import CartPageTabsSection from './CartPageTabsSection';
import AddressLayoutForCartAndBilling from 'components/AddressLayoutForCartAndBilling';
import { generateQuoteForCart } from 'store/actions/quotes';
import { getCartStatusText, isCartStatusReadyToSchedule } from './cart-utils';
import { showSnackbarError, showSnackbarSuccess } from 'components/common/Snackbar/SnackbarHelper';
import { SXCssObject } from 'models/ThemeImages';
import { placeOrderForEquipmentOnly, redirectToSchedulePage } from 'store/actions/cartActions';
import { resetOrder } from 'store/actions/orderActions';
import { invokeGACartGenerateQuote } from 'components/Analytics/GoogleAnalyticsTagging';
import { selectMultipleOpenProjects } from 'store/selectors/storeSelector';
import { isCartForEquipmentOnly, selectCart } from 'store/selectors/cart';
import CollectContractorInfoModal from './CollectContractorInfoModal';
import { PaymentConstants } from 'models/financial';

const createCssObject = (theme: Theme): SXCssObject => ({
    cartContainer: {
        px: {
            xs: '0px',
            sm: 'inherit',
        },
        backgroundColor: {
            xs: theme.palette.common.white,
            sm: 'inherit',
        },
    },
    cartContainerBg: {
        backgroundColor: {
            xs: theme.palette.common.white,
        },
    },
    header: {
        paddingBottom: {
            xs: theme.spacing(1.5),
            sm: theme.spacing(2),
        },
        px: {
            xs: '0px',
            sm: 'inherit',
        },
        backgroundColor: {
            xs: theme.palette.common.white,
            sm: 'inherit',
        },
        paddingTop: {
            sm: theme.spacing(4),
            md: theme.spacing(5),
        },
    },
    headerText: {
        display: 'flex',
        flexDirection: 'column',
        padding: {
            xs: '12px 16px',
            sm: 'inherit',
        },
    },
    footer: {
        paddingTop: theme.spacing(3),
        paddingBottom: theme.spacing(3),
    },
    nav: {
        paddingTop: theme.spacing(3),
        paddingBottom: theme.spacing(3),
    },
    selectedText: {
        paddingBottom: theme.spacing(1),
        padding: {
            sm: theme.spacing(1.6, 0),
        },
    },
    divider: {
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),
    },
    dividerStyle: {
        display: {
            sm: 'none',
        },
        width: {
            xs: '100%',
            sm: 'inherit',
        },
        border: {
            xs: 'none',
            sm: 'inherit',
        },
        borderBottom: {
            xs: `1px solid ${theme.customProperties.borderDivider}`,
            sm: 'inherit',
        },
        opacity: {
            xs: '0.4',
            sm: 'inherit',
        },
    },
    footerStyle: {
        marginTop: {
            xs: '-35px',
            sm: 'inherit',
        },
    },
    greatChoiceText: {
        marginBottom: '5px',
    },
    addressContent: {
        marginTop: {
            xs: '4px',
            sm: '32px',
        },
    },
    cartStatusContainer: {
        display: 'flex',
        justifyContent: {
            xs: 'flex-start',
            md: 'flex-end',
        },
        padding: {
            xs: '16px',
            md: '0px',
        },
        backgroundColor: {
            xs: theme.palette.background.default,
        },
        borderRadius: {
            xs: '4px',
        },
        width: {
            xs: '100%',
        },
    },
    cartStatusSection: {
        padding: {
            xs: '8px 16px 20px',
            sm: 'inherit',
        },
        justifyContent: {
            md: 'flex-end',
        },
    },
    cartStatusLabel: {
        paddingTop: {
            sm: '36px',
            md: 'inherit',
        },
        justifyContent: {
            md: 'flex-end',
        },
        display: 'flex',
        marginBottom: '5px', //reduced 5px because editicon has padding
    },
    cartStatusContent: {
        display: 'flex',
        alignItems: 'center',
        marginTop: {
            sm: '8px',
        },
    },
    cartStatusIconStyle: {
        border: '3px solid #DC5226',
        width: '14px',
        height: '14px',
        borderRadius: '50%',
        marginRight: '8px',
    },
    submitBtnSection: {
        backgroundColor: theme.palette.common.white,
        borderBottom: `1px solid ${theme.palette.divider}`,
        paddingTop: '16px',
        paddingBottom: '12px',
        marginTop: {
            xs: '0px',
            md: '16px',
        },
    },
    submitBtns: {
        width: {
            xs: '100%',
            sm: '50%',
            md: 'auto',
        },
        padding: '5px',
    },
    submitBtnsTop: {
        width: {
            xs: '100%',
            sm: '50%',
            md: 'auto',
        },
        padding: '5px',
        mt: 2,
        display: 'flex',
        justifyContent: 'flex-end',
    },
});

interface Prop {
    cartId: string;
    cartItems: CartItem[];
    cartPrices: CartPrice;
    shippingAddress: Address | undefined;
    billingAddress: Address | undefined;
    onClickContinueToPaymentPage: () => void;
    loading: boolean;
    cartStatus: CartStatus | undefined;
    shouldCheckForAddOnCompatibility: boolean | undefined;
    wizardSelectedOptions?: WizardSelectedOptions;
}

function CartPage(props: Prop) {
    const cssObject: SXCssObject = createCssObject(useTheme());
    const theme = useTheme<ThemeWithAdditionalData>();
    const isSmDown = useMediaQuery(theme.breakpoints.down('md'));
    const multipleOpenProjects = useSelector(selectMultipleOpenProjects);
    const {
        cartItems,
        loading,
        onClickContinueToPaymentPage,
        shippingAddress,
        billingAddress,
        cartStatus,
        shouldCheckForAddOnCompatibility,
    } = props;

    const cartData = useSelector(selectCart);

    const isEquipmentOnly = useSelector(isCartForEquipmentOnly);
    const isCartSchedulable = React.useMemo(() => isCartStatusReadyToSchedule(cartData.status), [cartData.status]);
    const [showContractorPopUpForEO, setShowContractorPopUpForEO] = useState(false);

    const addressLayoutSiteRef = React.useRef<any>(null);
    let loginData = useSelector(selectLoginState);
    const [isLoginModalShown, setSignUpModalVisibility] = React.useState(false);
    const isVerified = loginData.isVerified;
    let [loader, setLoader] = React.useState(false);
    const dispatch = useDispatch();

    React.useEffect(() => {
        if (isEquipmentOnly) {
            dispatch(resetOrder());
        }
    }, [isEquipmentOnly, dispatch]);

    /** 
        DOMEvent.EVENT_SHOW_CONTRACTOR_MODAL_AFTER_MANDATORY_BILLING_CAPTURE 
          1. If billing address is not captured when submit CTA("Continue" Btn) is clicked for EO Flow, 
              add a DOM event that listens to Successful updation of billing address in cart Epic.
          2. This is because, since we use Ref's , we will not be able to differentiate whether user Explicitly sets billing address
              or he is mandatory asked during submit.
          3. The above event will be unregistered, when either cart page is unmounted or when user volutarily sets/changes billing address.
    **/

    const listenerForShowingContractorModal = () => {
        setShowContractorPopUpForEO(true);
    };

    const removeEventListenerForContractorModal = () => {
        try {
            document.removeEventListener(
                DOMEvent.EVENT_SHOW_CONTRACTOR_MODAL_AFTER_MANDATORY_BILLING_CAPTURE,
                listenerForShowingContractorModal
            );
        } catch (e) {}
    };

    React.useEffect(() => {
        return () => {
            removeEventListenerForContractorModal();
        };
    }, []);

    const handleSubmitBtnClick = () => {
        if (isCartSchedulable || (cartStatus === CartStatus.EQ_READY_FOR_PAYMENT && isEquipmentOnly)) {
            checkForLoginAndContinue();
        } else if (cartStatus === CartStatus.PENDING_INSPECTION) {
            //Button will be disabled. So click action is not possible.
        } else {
            onClickContinueToPaymentPage();
        }
    };

    const handleGenerateQuote = () => {
        invokeGACartGenerateQuote();
        dispatch(generateQuoteForCart());
    };

    const getSubmitButtonText = () => {
        if (isCartSchedulable) {
            return 'Schedule';
        } else if (cartStatus === CartStatus.EQ_READY_FOR_PAYMENT) {
            return 'Continue';
        } else {
            return 'Proceed to Purchase';
        }
    };

    const checkForLoginAndContinue = () => {
        if (isVerified) {
            return checkForBillingAddressAndContinue();
        } else {
            setLoader(true);
            sendVerificationOtpToEmail().subscribe(
                (otpRes) => {
                    setLoader(false);
                    setSignUpModalVisibility(true);
                },
                (err) => {
                    setLoader(false);
                    let msg = LoginMessages.ACTIVATION_SEND_FAILURE;
                    try {
                        if (err.response.data.error.message) {
                            msg = err.response.data.error.message;
                        }
                    } catch (e) {}
                    showSnackbarError(msg);
                }
            );
        }
    };

    const checkForBillingAddressAndContinue = () => {
        if (billingAddress && billingAddress.firstname) {
            if (cartStatus === CartStatus.EQ_READY_FOR_PAYMENT) {
                setShowContractorPopUpForEO(true);
            } else {
                dispatch(redirectToSchedulePage());
            }
        } else {
            if (addressLayoutSiteRef.current) {
                if (cartStatus === CartStatus.EQ_READY_FOR_PAYMENT) {
                    document.addEventListener(
                        DOMEvent.EVENT_SHOW_CONTRACTOR_MODAL_AFTER_MANDATORY_BILLING_CAPTURE,
                        listenerForShowingContractorModal
                    );
                }
                addressLayoutSiteRef.current.getBillingAddressAndRedirectToRoute();
            }
        }
    };

    const handleLoginModalSubmit = (snackBarMessage?: string) => {
        setSignUpModalVisibility(false);
        if (snackBarMessage) {
            showSnackbarSuccess(snackBarMessage);
            checkForBillingAddressAndContinue();
        }
    };

    return (
        <ContentContainer disableGrid background={'bg2'}>
            {isLoginModalShown && (
                <Login
                    handleClose={handleLoginModalSubmit}
                    headingContent={"Activate your email. It's time to finish up"}
                    isSignUpOtpFlow={true}
                    isOpen={isLoginModalShown}
                    email={loginData.email}
                    loading={false}
                ></Login>
            )}
            {showContractorPopUpForEO && (
                <CollectContractorInfoModal
                    open={showContractorPopUpForEO}
                    handleClose={() => {
                        setShowContractorPopUpForEO(false);
                    }}
                    handleSubmit={(contractorInfo: string) => {
                        setShowContractorPopUpForEO(false);
                        dispatch(
                            placeOrderForEquipmentOnly({
                                cart: cartData,
                                paymentMethod: {
                                    method: PaymentConstants.OFFLINE_PAYMENT,
                                    code: PaymentConstants.OFFLINE_PAYMENT,
                                },
                                contractorInfo: contractorInfo,
                            })
                        );
                    }}
                />
            )}
            <LoadingOverlay open={loading || loader} />
            <ContentContainer disableGrid background={'bg3'} sx={cssObject.cartContainerBg}>
                <ContentContainer>
                    <Navigator color={'primary'} />
                </ContentContainer>
            </ContentContainer>
            <ContentContainer sx={cssObject.header}>
                <ContentContainer disableGrid>
                    <Grid container>
                        <Grid item xs={12} md={8} sx={cssObject.headerText}>
                            <Box sx={cssObject.greatChoiceText}>
                                <Heading label={'Great choice! Time to finish up.'} />
                            </Box>
                            <Paragraph size={'normal'} align={'justify'}>
                                {isEquipmentOnly
                                    ? ` As the final step, one of our representatives will reach out to you. We will confirm you have the right system, answer your questions, and learn about any special circumstances before preparing your order to ship.`
                                    : ` The final step is to book your home inspection. We confirm you have the right system,
                                answer your questions, and learn about any special circumstances. No worries if you need
                                to change something later.`}
                            </Paragraph>
                        </Grid>
                        <Grid item xs={12} sm={12} md={4} sx={cssObject.cartStatusSection}>
                            {!(cartStatus === CartStatus.EQ_READY_FOR_PAYMENT) && (
                                <Box sx={cssObject.cartStatusContainer}>
                                    <Box>
                                        <Box sx={cssObject.cartStatusLabel}>
                                            <Paragraph>Cart Status</Paragraph>
                                        </Box>
                                        <ContentContainer disableGrid sx={cssObject.cartStatusContent}>
                                            <ContentContainer
                                                disableGrid
                                                sx={cssObject.cartStatusIconStyle}
                                            ></ContentContainer>
                                            <Paragraph largeFont semibold headingColor>
                                                {getCartStatusText(cartStatus)}
                                            </Paragraph>
                                        </ContentContainer>
                                    </Box>
                                </Box>
                            )}
                            {multipleOpenProjects && (
                                <Box sx={cssObject.submitBtnsTop}>
                                    <ButtonWithIcon
                                        id={`cart-continue-withtooltip-btn`}
                                        color={'primary'}
                                        isFullWidth={isSmDown ? true : false}
                                        onClick={handleSubmitBtnClick}
                                        toolTipText={SCHEDULE_TOOLTIP}
                                        disabled={cartStatus === CartStatus.PENDING_INSPECTION}
                                        hideInfoIcon={!isCartSchedulable}
                                    >
                                        {getSubmitButtonText()}
                                    </ButtonWithIcon>
                                </Box>
                            )}
                        </Grid>
                    </Grid>
                    <Box component={'hr'} sx={cssObject.dividerStyle} />
                    <Box sx={cssObject.addressContent}>
                        <AddressLayoutForCartAndBilling
                            ref={addressLayoutSiteRef}
                            shippingAddress={shippingAddress}
                            billingAddress={billingAddress}
                            removeEventListenerForBilling={removeEventListenerForContractorModal}
                        />
                    </Box>
                </ContentContainer>
            </ContentContainer>

            <ContentContainer sx={cssObject.cartContainer}>
                <ContentContainer disableGrid>
                    <Grid container sx={cssObject.selectedText}>
                        <Grid item xs={12} container sx={cssObject.headerText}>
                            <Heading label={'Your Cart'} />
                        </Grid>
                    </Grid>
                </ContentContainer>
                <CartPageTabsSection
                    cartStatus={cartStatus}
                    cartItems={cartItems}
                    wizardSelectedOptions={props.wizardSelectedOptions}
                    shouldCheckForAddOnCompatibility={shouldCheckForAddOnCompatibility}
                    cartPrices={props.cartPrices}
                    shippingAddress={shippingAddress}
                    onLoader={(loaderState) => {
                        setLoader(loaderState);
                    }}
                />
            </ContentContainer>
            <ContentContainer sx={cssObject.submitBtnSection}>
                <Grid container justifyContent={'flex-end'}>
                    <Grid item container xs={12} justifyContent={'flex-end'} style={{ display: 'flex' }}>
                        <Grid
                            item
                            sx={[
                                cssObject.submitBtns,
                                {
                                    display: { xs: 'none', sm: 'block' },
                                    p: '5px',
                                },
                            ]}
                            justifyContent={'flex-end'}
                        >
                            <Button
                                id={`cart-continue-btn`}
                                color={'primary'}
                                isContrast={true}
                                isFullWidth={isSmDown ? true : false}
                                onClick={handleGenerateQuote}
                            >
                                Generate Quote
                            </Button>
                        </Grid>
                        <Grid item sx={cssObject.submitBtns} justifyContent={'flex-end'}>
                            <ButtonWithIcon
                                id={`cart-continue-withtooltip-btn`}
                                color={'primary'}
                                isFullWidth={isSmDown ? true : false}
                                onClick={handleSubmitBtnClick}
                                toolTipText={SCHEDULE_TOOLTIP}
                                disabled={cartStatus === CartStatus.PENDING_INSPECTION}
                                hideInfoIcon={!isCartSchedulable}
                            >
                                {getSubmitButtonText()}
                            </ButtonWithIcon>
                        </Grid>
                        <Grid
                            item
                            sx={[cssObject.submitBtns, { display: { xs: 'block', sm: 'none' } }]}
                            justifyContent={'flex-end'}
                        >
                            <Button
                                id={`cart-quote-btn`}
                                color={'primary'}
                                isContrast={true}
                                isFullWidth={isSmDown ? true : false}
                                onClick={handleGenerateQuote}
                            >
                                Generate Quote
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
                <Box component={'hr'} sx={cssObject.dividerStyle} style={{ paddingTop: '12px', marginTop: '10px' }} />
            </ContentContainer>
        </ContentContainer>
    );
}

export default CartPage;
