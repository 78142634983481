import { Box, Dialog, DialogContent, DialogTitle, Grid, useMediaQuery, useTheme } from '@mui/material';
import React from 'react';
import { Theme } from '@mui/material/styles';
import { Close } from '@mui/icons-material';
import { Button } from 'components/Button';
import { AppRoute } from 'models/route';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { navigateThankyouPage } from 'store/actions/navigateActions';
import HeadlineParagraph from './HeadlineParagraph';
import { Paragraph } from './Paragraph';
import AlertIconMedium from './icons/AlertIconMedium';
import CallIcon from './icons/CallIcon';
import MailIcon from './icons/MailIcon';
import { CommonLink } from './Link';
import { ThemeWithAdditionalData } from 'themes/theme';
import { SXCssObject } from 'models/ThemeImages';
import { selectMultipleOpenProjects } from 'store/selectors/storeSelector';

const createCssObject = (theme: Theme): SXCssObject => ({
    container: {
        padding: '0px 17px 16px 17px',
    },
    b: {
        fontWeight: theme.typography.fontWeightMedium,
    },
    hr: {
        border: `1px solid ${theme.palette.divider}`,
        backgroundColor: theme.palette.divider,
    },
    subTitle: {
        padding: {
            sm: '0px 60px',
        },
    },
    modalTitleContainer: {
        position: 'relative',
        paddingTop: {
            xs: '0px',
            sm: '10px',
        },
    },
    modalPaper: {
        borderRadius: '4px',
        maxWidth: {
            sm: '668px',
        },
        minWidth: {
            xs: '92%',
            md: 'inherit',
        },
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(0.5),
        top: theme.spacing(0.5),
        cursor: 'pointer',
    },
    buttonContainer: {
        padding: '5px 0px',
        justifyContent: {
            xs: 'center',
            sm: 'space-between',
        },
        alignItems: 'center'
    },
    Button: {
        textAlign: 'right',
        width: {
            xs: '100%',
            sm: 'auto',
        },
    },
});

interface Props {
    open: boolean;
    isDoubleSystem: boolean;
}
function NoProductPopup(props: Props) {
    const cssObject: SXCssObject = createCssObject(useTheme());
    const theme = useTheme<ThemeWithAdditionalData>();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const multipleOpenProjects = useSelector(selectMultipleOpenProjects);
    const downXs = useMediaQuery(theme.breakpoints.only('xs'));
    React.useEffect(() => {
    }, []);
    const onClose = () => {
        if (props.isDoubleSystem) {
            navigate(AppRoute.CartPage);
        } else {
            navigate(AppRoute.Home);
        }
        dispatch(navigateThankyouPage({ isNoProduct: false }));
    };
    return (
        <Dialog
            open={props.open}
            maxWidth={'xs'}
            disableEscapeKeyDown={true}
            PaperProps={{
                sx: cssObject.modalPaper,
            }}
        >
            <Box>
                <DialogTitle
                    sx={{ '& .MuiDialogTitle-root': { p: '16px' } }}
                >
                    {downXs === true ? (
                        <Grid container>
                            <Grid item xs={2}>
                                <AlertIconMedium style={{ width: 64, height: 64 }} />
                            </Grid>
                            <Grid item xs={9} sx={cssObject.modalTitleContainer}>
                                <HeadlineParagraph label={"We're Sorry!"} headlineSize={'h2'} />
                            </Grid>
                            <Grid item>
                                <Box sx={cssObject.closeButton} onClick={onClose}>
                                    <Close />
                                </Box>
                            </Grid>
                        </Grid>
                    ) : (
                        <Grid>
                            <Grid container justifyContent={'flex-end'}>
                                <Box style={{ cursor: 'pointer' }} onClick={onClose}>
                                    <Close />
                                </Box>
                            </Grid>
                            <Grid container justifyContent={'center'}>
                                <AlertIconMedium style={{ width: 64, height: 64 }} />
                            </Grid>
                            <Grid container justifyContent={'center'} sx={cssObject.modalTitleContainer}>
                                <HeadlineParagraph label={"We're Sorry!"} headlineSize={'h2'} />
                            </Grid>
                        </Grid>
                    )}
                </DialogTitle>
                <DialogContent sx={cssObject.container}>
                    <Grid sx={cssObject.subTitle}>
                        <Paragraph size={'normal'} align={'center'}>
                            Some HVAC projects require a more personal touch to quote properly. One of our friendly HVAC
                            specialists will reach out to you within one business day to help select the system that's
                            right for you.
                        </Paragraph>
                    </Grid>
                    <br />
                    <Grid justifyContent={'center'} sx={cssObject.subTitle}>
                        <Paragraph size={'normal'} align={'center'}>
                            Also, feel free to contact us directly at
                        </Paragraph>
                        <Grid justifyContent={'center'} alignItems={'center'} container>
                            <CallIcon /> &nbsp;
                            <CommonLink
                                id={'contact-page-tel'}
                                fontColor={theme.palette.primary.main}
                                href={'tel:8333717706'}
                                label={'(833) 371-7706'}
                            />
                        </Grid>
                        <Paragraph size={'normal'} align={'center'}>
                            or via email at
                        </Paragraph>
                        <Grid justifyContent={'center'} alignItems={'center'} container>
                            <MailIcon /> &nbsp;

                            <CommonLink
                                fontColor={theme.palette.primary.main}
                                href={multipleOpenProjects ? 'mailto:Steve.Sparks@motili.com' : 'mailto:sales@airandheatdoneright.com'}
                                label={multipleOpenProjects ? 'Steve.Sparks@motili.com' : 'sales@airandheatdoneright.com'}
                            />
                        </Grid>
                    </Grid>
                    <br />
                    {props.isDoubleSystem && (
                        <Grid sx={cssObject.subTitle}>
                            <Paragraph size={'normal'} align={'center'}>
                                Return to your cart to schedule the inspection for you first system!
                            </Paragraph>
                        </Grid>
                    )}
                    <Box component={'hr'} sx={cssObject.hr} />
                    <Grid container sx={cssObject.buttonContainer} spacing={1}>
                        <Grid item xs={12} sm={6} sx={{ textAlign: { xs: 'center', sm: 'left' } }}>
                            <img src={theme.images.logoDesktop} height={'48px'} alt={'Logo'} />
                        </Grid>
                        <Grid item sx={cssObject.Button} xs={12} sm={6}>
                            <Button
                                isContrast
                                isSmallerFontForSmallerDevice
                                disabled={false}
                                onClick={onClose}
                            >
                                {props.isDoubleSystem ? 'GO TO CART' : 'RETURN TO HOME PAGE'}
                            </Button>
                        </Grid>
                    </Grid>
                </DialogContent>
            </Box>
        </Dialog>
    );
}

export default NoProductPopup;
