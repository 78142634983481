import { on, reducer } from 'ts-action';
import {
    resetProductCriteria,
    setProductCriteria,
    setPromotionCode,
    setDoubleSystemFlag,
    clearWizarQuestionData,
} from '../actions/productCriteriaActions';
import { ProductCriteria } from '../../models/productCriteria';

const initialState: ProductCriteria = {};

export const productCriteriaReducer = reducer(
    initialState,
    on(setPromotionCode, (state, action) => ({
        ...state,
        ...action.payload,
    })),
    on(setProductCriteria, (state, action) => ({
        ...state,
        ...action.payload,
    })),
    on(resetProductCriteria, () => ({
        ...initialState,
    })),
    on(setDoubleSystemFlag, (state, action) => ({
        ...state,
        ...action.payload,
    })),
    on(clearWizarQuestionData, (state, action) => ({
        ...initialState,
        typeOfService: state.typeOfService,
        ...action.payload,
    }))
);

export default productCriteriaReducer;
