import React from 'react';
import { Theme } from '@mui/material/styles';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import ContentContainer from '../../components/ContentContainer';
import { Paragraph } from '../../components/Paragraph';
import { Box, Hidden, useMediaQuery, useTheme } from '@mui/material';
import { ItemType } from 'models/cart';
import { useDispatch } from 'react-redux';
import { OrderAddress, OrderProduct, OrderStatus } from 'models/order';
import { Button } from '../../components/Button';
import { getMyOrder, getOrder } from 'store/actions/orderActions';
import { getOrderStatus, getOrderStatusDescription, showPayNow } from './order-utils';
import CollapseIcon from 'components/icons/CollapseIcon';
import ExpandIcon from 'components/icons/ExpandIcon';
import OrderIcon from 'components/icons/OrderIcon';
import ArrowDown3 from 'components/icons/ArrowDown3';
import { getThemePrimaryLight } from 'components/common/Utilities';
import { get } from 'lodash';
import { SXCssObject } from 'models/ThemeImages';
import { currencyFormat } from 'components/common/NumberUtil';
import { invokeGAPayNowClickedMyOrdersPage } from 'components/Analytics/GoogleAnalyticsTagging';
import { format } from 'date-fns';
const createCssObject = (theme: Theme): SXCssObject => ({
    root: {
        padding: '0px',
        px: '0 !important',
        borderBottom: `1px solid ${theme.customProperties.border}`,
    },

    paymentDate: {
        textAlign: 'left',
        marginTop: {
            xs: theme.spacing(0.5),
            md: 'inherit',
        },
        paddingLeft: {
            xs: theme.spacing(0),
            md: 'inherit',
        },
    },
    orderNumber: {
        padding: theme.spacing(1),
        backgroundColor: getThemePrimaryLight(theme),
        display: 'inline-flex',
        textAlign: {
            xs: 'left',
            md: 'inherit',
        },
        marginTop: {
            xs: theme.spacing(1),
            md: 'inherit',
        },
    },
    orderPrice: {
        textAlign: {
            xs: 'left',
            md: 'inherit',
        },
        marginTop: {
            xs: theme.spacing(0.5),
            md: 'inherit',
        },
        paddingLeft: {
            xs: theme.spacing(0),
            md: 'inherit',
        },
    },

    orderDetail: {
        paddingLeft: theme.spacing(1.5),
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
        p: { xs: 2, sm: 3, md: '20px 0px 20px 15px' },
    },
    cartStatusSection: {
        padding: {
            xs: '8px 16px 20px',
            sm: 'inherit',
        },
        justifyContent: {
            md: 'flex-start',
        },
    },

    cartStatusContent: {
        display: 'flex',
        alignItems: 'center',
        marginBottom: '10px',
        marginTop: {
            xs: theme.spacing(0.5),
            md: 'inherit',
        },
    },
    cartStatusIconStyle: {
        border: `3px solid ${theme.customProperties.commonOrange}`,
        width: '14px',
        height: '14px',
        borderRadius: '50%',
        marginRight: '8px',
    },
    reverseArrowIcon: {
        transform: 'rotate(180deg)',
        width: '15px',
        cursor: 'pointer',
    },
    productDetail: {
        mt: {
            xs: 1,
            md: 'inherit',
        },
    },

    groupSystem: {
        marginTop: '5px',
        paddingTop: '5px',
        borderTop: `1px solid ${theme.customProperties.border}`,
        width: 'fit-content',
    },
    arrowIcon: {
        width: '15px',
        cursor: 'pointer',
    },
    payButton: {
        marginTop: '10px',
        width: {
            md: '70%',
        },
    },
});

interface Props {
    id: number;
    orderID: string;
    items: OrderProduct[];
    paymentDate: string;
    property: OrderAddress;
    totalPrice: number;
    orderStatus: OrderStatus;
    totalDue?: number;
}

function OrderDetail(props: Props) {
    const { id, orderID, items, property, paymentDate, totalPrice, orderStatus, totalDue } = props;
    const classes: SXCssObject = createCssObject(useTheme());
    const theme = useTheme();
    const isDownSm = useMediaQuery(theme.breakpoints.down('md'), { noSsr: true });
    const isXs = useMediaQuery(theme.breakpoints.only('xs'));
    const [open, setOpen] = React.useState(false);
    const [isExpanded, setExpanded] = React.useState(!isDownSm);
    const dispatch = useDispatch();

    const orderNumberLabel = (
        <Grid container style={{ flex: '0 1 auto' }} justifyContent={'space-between'}>
            <Grid item>
                <Paragraph bold>Order Number</Paragraph>
            </Grid>
            {(!isExpanded || isXs) && (
                <Hidden mdUp>
                    <Grid item onClick={() => toggleExpanded(isExpanded)} style={{ display: 'flex' }}>
                        {isExpanded ? <CollapseIcon /> : <ExpandIcon />}
                    </Grid>
                </Hidden>
            )}
        </Grid>
    );

    const productsLabel = (
        <Grid container justifyContent={'space-between'}>
            <Grid item>
                <Paragraph bold>Product’s Purchased</Paragraph>
            </Grid>
            {isExpanded && (
                <Hidden only={['xl', 'lg', 'md', 'xs']}>
                    <Grid item onClick={() => toggleExpanded(isExpanded)} style={{ display: 'flex' }}>
                        {isExpanded ? <CollapseIcon /> : <ExpandIcon />}
                    </Grid>
                </Hidden>
            )}
        </Grid>
    );
    const paymentDateLabel = <Paragraph bold>Payment Date</Paragraph>;
    const propertyLabel = <Paragraph bold>Property</Paragraph>;
    const priceLabel = <Paragraph bold>Total Price</Paragraph>;
    const orderStatusLabel = <Paragraph bold>Order Status</Paragraph>;
    const systemPrice = (cartPrice: number) => {
        return (
            <div>
                <Paragraph semibold>{currencyFormat.format(cartPrice)}</Paragraph>
            </div>
        );
    };
    const address = (addressItem: OrderAddress) => {
        return (
            <div>
                <Paragraph>
                    {addressItem.addressName}:{' '}
                    {addressItem.address1 + ', ' + (addressItem.address2 ? addressItem.address2 + ', ' : '')}{' '}
                </Paragraph>
                <Paragraph>{addressItem.city + ', ' + addressItem.region + ', ' + addressItem.postalCode}</Paragraph>
            </div>
        );
    };
    const toggleExpanded = (currentState: boolean) => {
        setExpanded(!currentState);
    };
    const UpArrow = () => {
        return (
            <Grid item sx={{ mt: '2px' }}>
                <ArrowDown3 style={classes.reverseArrowIcon} onClick={() => setOpen(!open)} />
            </Grid>
        );
    };
    const orderDate = paymentDate.slice(0, 10);
    var longDateStr = format(new Date(orderDate), 'dd MMM yyyy');
    const handlePayNow = () => {
        id && dispatch(getOrder({ id: id, redirectToPayment: true }));
        invokeGAPayNowClickedMyOrdersPage();
    };
    const PayNowButton = () => {
        return (
            <Button id={`Pay-now`} color="primary" onClick={handlePayNow} isFullWidth>
                Pay Now
            </Button>
        );
    };
    return (
        <ContentContainer disableGrid>
            <Container maxWidth={false} sx={classes.root} id={`active-cart`}>
                <ContentContainer disableGrid background={'bg3'} sx={classes.orderDetail}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={isExpanded ? 6 : 12} md={2}>
                            {isDownSm && orderNumberLabel}
                            <Grid item sx={classes.orderNumber}>
                                <Grid
                                    container
                                    alignItems={'center'}
                                    onClick={() => {
                                        dispatch(getMyOrder({ id: id }));
                                    }}
                                    style={{ cursor: 'pointer' }}
                                >
                                    <Grid item style={{ display: 'flex', alignItems: 'center', marginRight: '5px' }}>
                                        <OrderIcon />
                                    </Grid>
                                    <Paragraph semibold fontSizeMediumSmall>
                                        {orderID}
                                    </Paragraph>
                                </Grid>
                            </Grid>
                        </Grid>
                        {items && isExpanded && (
                            <Grid item xs={12} sm={6} md={2} justifyContent={'center'}>
                                {isDownSm && productsLabel}
                                {open ? (
                                    items &&
                                    items.map((item, index) => {
                                        if (item.itemType === ItemType.MainSystem) {
                                            return (
                                                <Box sx={[classes.productDetail, index !== 0 && classes.groupSystem]}>
                                                    <Paragraph semibold>{`${get(
                                                        item,
                                                        'product.productAttributes.level',
                                                        ''
                                                    )} HVAC`}
                                                    {item?.sku?.includes('-EO') ? '-Equipment only' : ''}
                                                    </Paragraph>

                                                    <Paragraph fontSizeVerySmall>
                                                        {get(item, 'product.productAttributes.systemType', '')}
                                                        {open && index === items.length - 1 && <UpArrow />}
                                                    </Paragraph>
                                                </Box>
                                            );
                                        } else if (item.itemType === ItemType.CrossSell) {
                                            return (
                                                <Grid container>
                                                    <Paragraph semibold>
                                                        {`${get(item, 'product.productAttributes.level', '')} IAQ HVAC`}
                                                        {open && index === items.length - 1 && <UpArrow />}
                                                    </Paragraph>
                                                </Grid>
                                            );
                                        } else {
                                            return (
                                                <Grid container sx={[index !== 0 && classes.groupSystem]}>
                                                    <Paragraph semibold>
                                                        {item.qtyOrdered > 1
                                                            ? item.qtyOrdered + ' x ' + item.name
                                                            : item.name}
                                                        {open && index === items.length - 1 && <UpArrow />}
                                                    </Paragraph>
                                                </Grid>
                                            );
                                        }
                                    })
                                ) : (
                                    <Box sx={classes.productDetail}>
                                        <Paragraph semibold>
                                            {`${get(items[0], 'product.productAttributes.level', '')} HVAC`}
                                            {items[0].sku.includes('-EO') ? '-Equipment only' : ''}
                                        </Paragraph>
                                        <Paragraph fontSizeVerySmall>
                                            {get(items[0], 'product.productAttributes.systemType', '')}
                                        </Paragraph>
                                    </Box>
                                )}

                                {!open && items.length > 1 && (
                                    <Grid container style={{ fontSize: '10px' }}>
                                        <Grid item>{`+${items.length - 1} more system(s) added`}</Grid>
                                        <Grid item sx={{ ml: 0.5, mt: 0.5 }}>
                                            <ArrowDown3 style={classes.arrowIcon} onClick={() => setOpen(!open)} />
                                        </Grid>
                                    </Grid>
                                )}
                            </Grid>
                        )}

                        {isExpanded && (
                            <Grid item xs={12} sm={6} md={2}>
                                {isDownSm && paymentDateLabel}
                                <Grid container direction={'column'} sx={classes.paymentDate}>
                                    {longDateStr}
                                </Grid>
                            </Grid>
                        )}

                        {isExpanded && (
                            <Grid item xs={12} sm={6} md={3} container>
                                {isDownSm && propertyLabel}
                                <Grid item container direction={'column'} sx={classes.paymentDate}>
                                    {address(property)}
                                </Grid>
                            </Grid>
                        )}

                        {isExpanded && (
                            <Grid item xs={12} sm={6} md={1} container>
                                {isDownSm && priceLabel}
                                <Grid item container direction={'column'} sx={classes.orderPrice}>
                                    {systemPrice(totalPrice)}
                                </Grid>
                            </Grid>
                        )}

                        {isExpanded && (
                            <Grid item xs={12} sm={6} md={2} sx={classes.cartStatusSection}>
                                {isDownSm && orderStatusLabel}
                                <Box>
                                    <Box>
                                        <ContentContainer disableGrid sx={classes.cartStatusContent}>
                                            <ContentContainer
                                                disableGrid
                                                sx={classes.cartStatusIconStyle}
                                            ></ContentContainer>
                                            <Paragraph semibold headingColor>
                                                {orderStatus && getOrderStatus(orderStatus)}{' '}
                                            </Paragraph>
                                        </ContentContainer>
                                        {orderStatus && getOrderStatusDescription(orderStatus) && (
                                            <>
                                                <Paragraph fontSizeVerySmall>
                                                    {getOrderStatusDescription(orderStatus)}
                                                </Paragraph>
                                                <Paragraph fontSizeVerySmall semibold>
                                                    {totalDue ? currencyFormat.format(totalDue) : ''}
                                                </Paragraph>
                                            </>
                                        )}

                                        {showPayNow(orderStatus) && (
                                            <Grid sx={classes.payButton}>
                                                {' '}
                                                <PayNowButton />{' '}
                                            </Grid>
                                        )}
                                    </Box>
                                </Box>
                            </Grid>
                        )}
                    </Grid>
                </ContentContainer>
            </Container>
        </ContentContainer>
    );
}

export default OrderDetail;
