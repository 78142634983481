import * as React from 'react';
import Drawer from '@mui/material/Drawer';
import { Box, Grid } from '@mui/material';
import { Heading } from 'components/Heading';
import { Form, Formik, FormikValues } from 'formik';
import FormikTextField from 'components/FormikTextField';
import { Button } from 'components/Button';
import { useDispatch, useSelector } from 'react-redux';
import { showSnackbarSuccess } from 'components/common/Snackbar/SnackbarHelper';
import { addInstructons } from 'store/actions/cartActions';
import { selectCartInstructions } from 'store/selectors/cart';
import { Paragraph } from 'components/Paragraph';
import { AddInstructionsValidationSchema } from 'pages/wizard/wizard-schema';

interface Props {
    open?: boolean;
    toggleDrawer?: any;
}
export default function NotesDrawer(props: Props) {
    const { toggleDrawer, open } = props;
    const dispatch = useDispatch();
    const instructionsData = useSelector(selectCartInstructions);

    const handleSubmit = (values: FormikValues) => {
        if (values) {
            showSnackbarSuccess(
                instructionsData
                    ? `ACCESS INSTRUCTIONS UPDATED SUCCESSFULLY!`
                    : `ACCESS INSTRUCTIONS ADDED SUCCESSFULLY!`
            );
            dispatch(addInstructons(values.addInstructions));
            toggleDrawer('right', false);
        }
    };

    return (
        <React.Fragment>
            <Drawer
                anchor={'right'}
                open={open}
                PaperProps={{
                    sx: { width: { xs: '95%', sm: '75%', md: '50%' }, maxWidth: '550px', padding: '35px' },
                }}
                onClose={toggleDrawer}
            >
                <br />
                <Heading label={ instructionsData ? 'Edit Access Instructions' : 'Add Access Instructions' } />
                <Paragraph responsiveFontSize16>Any special instructions for accessing the property</Paragraph>
                <Formik
                    initialValues={{
                        addInstructions: instructionsData,
                    }}
                    onSubmit={(values) => {
                        handleSubmit(values);
                    }}
                    validationSchema={AddInstructionsValidationSchema}
                    validateOnMount={true}
                    enableReinitialize={true}
                >
                    {({ submitForm, isValid }) => (
                        <Form>
                            <Box sx={{ pt: '15px', pb: '30px' }}>
                                <FormikTextField
                                    id="add-instructions"
                                    name="addInstructions"
                                    type="text"
                                    multiLine={true}
                                    maxRows={32}
                                    minRows={12}
                                    placeholder="Lockbox code, primary contact information, directions, etc."
                                />
                            </Box>
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={6}>
                                    <Button
                                        outlinedWithBorder
                                        isFullWidth={true}
                                        color={'primary'}
                                        onClick={toggleDrawer}
                                    >
                                        CANCEL
                                    </Button>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <Button
                                        largeButton
                                        isFullWidth={true}
                                        color={'primary'}
                                        onClick={submitForm}
                                        disabled={!isValid}
                                    >
                                        {instructionsData ? 'UPDATE' : 'SAVE'}
                                    </Button>
                                </Grid>
                            </Grid>
                        </Form>
                    )}
                </Formik>
            </Drawer>
        </React.Fragment>
    );
}
