import React from 'react';
import { Theme, useTheme } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import { Button } from 'components/Button';
import Hidden from '@mui/material/Hidden';
import ContentContainer from 'components/ContentContainer';
import { AppRoute } from 'models/route';
import { Box, CardMedia, useMediaQuery } from '@mui/material';
import { Paragraph } from './Paragraph';
import { Cart, CartItem, ItemType, SystemGroup } from 'models/cart';
import striptags from 'striptags';
import Counter from './Counter';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import SelectedAddress from './icons/SelectedAddress';
import { selectPreCartAddon, selectPreCartMain } from 'store/selectors/selection';
import { setAddonSelection, addCartItem, updateCartItem } from 'store/actions/cartActions';
import { selectCart, selectProceedToCart } from 'store/selectors/cart';
import HeadlineParagraph from './HeadlineParagraph';
import { SXCssObject } from 'models/ThemeImages';

const createCssObject = (theme: Theme): SXCssObject => ({
    root: {},
    addOnLayoutWrapper: {
        width: '100%',
        overflowX: 'auto',
        marginTop: '16px ',
        display: 'flex',
        flexDirection: {
            xs: 'column',
            sm: 'row',
        },
    },
    addOnItemParent: {
        paddingTop: '4px',
        width: {
            xs: '100%',
            sm: '48%',
        },
        '&:not(:last-child)': {
            paddingBottom: {
                xs: '16px',
                sm: 'inherit',
            },
        },
        minWidth: {
            sm: '48%',
        },
        marginRight: {
            sm: '15px',
        },
        display: 'inline-block',
    },
    contentWrapper: {
        borderRadius: '4px',
        alignItems: 'center',
        '&:hover': {
            border: {
                sm: `1px solid ${theme.palette.action.hover}`,
            },
        },
        border: {
            sm: '1px solid #D4D4D4',
        },
        padding: {
            sm: '16px',
        },
        margin: {
            sm: '09px 09px 09px 0px',
        },
        height: {
            sm: 'calc(100% - 19px)',
        },
    },
    selectedAddOnStyle: {
        border: `1px solid ${theme.palette.action.hover} !important`,
    },
    img: {
        objectFit: 'contain',
        border: '1px solid #D4D4D4',
        borderRadius: '4px',
        height: {
            xs: '96px',
            sm: 'inherit',
        },
    },
    imageNameAndPrice: {
        paddingTop: {
            xs: '12px',
            sm: 'inherit',
        },
        paddingLeft: {
            sm: '12px',
        },
    },
    selectedIconStyle: {
        float: 'right',
    },
    AddOnDetails: {
        p: { xs: '16px 16px 0px', sm: '20px 20px 12px' },
        backgroundColor: theme.palette.common.white,
    },
    readMoreText: {
        color: theme.palette.primary.main,
        cursor: 'pointer',
        paddingLeft: theme.spacing(0.3),
        '&:hover': {
            color: `${theme.palette.primary.main} !important`,
        },
    },
    productDetailLabel: {
        paddingLeft: {
            xs: theme.spacing(0),
            sm: theme.spacing(1),
            md: 'inherit',
        },
        paddingTop: {
            xs: theme.spacing(1),
            sm: 'inherit',
        },
    },
    fullWidth: {
        width: '100%',
    },
    imageAndName: {
        display: 'flex',
        alignItems: 'center',
    },
    dividerStyle: {
        border: 'none',
        borderBottom: `1px solid ${theme.customProperties.borderDivider}`,
        opacity: 0.4,
        margin: '16px 0px 4px',
        display: {
            sm: 'none',
        },
    },
});
interface Props {
    addOnList?: CartItem[];
    width?: 'xs' | 'sm' | 'md' | 'lg' | 'xl';
    shouldEnableChanges?: boolean;
    isInteractiveTile?: boolean;
    totalAddOnsAvailable?: number;
}
function AddOnProducts(props: Props) {
    const cssObject: SXCssObject = createCssObject(useTheme());
    const { shouldEnableChanges, isInteractiveTile } = props;

    const addOnList = (() => {
        if (props.isInteractiveTile) {
            const addOnArr: any[] = [];
            props.addOnList &&
                props.addOnList.forEach((item) => {
                    for (let i = 0; i < item.quantity; i++) {
                        addOnArr.push(item);
                    }
                });
            return addOnArr;
        } else if (props.addOnList) {
            return [...props.addOnList];
        } else return [];
    })();

    const navigate = useNavigate();

    const canShowtotalAddsAvailable = addOnList.length === 0 && props.totalAddOnsAvailable! > 0;
    return (
        <Grid container sx={cssObject.AddOnDetails}>
            <ContentContainer disableGrid sx={cssObject.fullWidth}>
                <ContentContainer disableGrid sx={cssObject.fullWidth}>
                    <ContentContainer disableGrid>
                        <ContentContainer disableGrid>
                            <Grid>
                                <Grid container spacing={1}>
                                    <Grid item xs={12} sm={isInteractiveTile ? 4 : 12}>
                                        <HeadlineParagraph
                                            headlineSize={'h4'}
                                            label={'Other Products'}
                                            shouldHideEmptyGridAtBottom
                                        />
                                        {addOnList.length > 0 && (
                                            <Paragraph fontSizeVerySmall={true} size={'small'}>
                                                {addOnList.length <= 9 ? '0' : ''}
                                                {addOnList.length}
                                                {isInteractiveTile && ' Added'}
                                                {!isInteractiveTile &&
                                                    (addOnList.length > 1
                                                        ? ' Products available for you to choose from'
                                                        : ' Product available for you to choose from')}
                                            </Paragraph>
                                        )}
                                    </Grid>
                                    {shouldEnableChanges && isInteractiveTile && (
                                        <Grid item xs={12} sm={4}>
                                            <Paragraph fontSizeVerySmall={true} size={'small'}>
                                                {canShowtotalAddsAvailable &&
                                                    (props.totalAddOnsAvailable! > 1
                                                        ? `${props.totalAddOnsAvailable} products available for you to choose from`
                                                        : `${props.totalAddOnsAvailable} product available for you to choose from`)}
                                            </Paragraph>
                                        </Grid>
                                    )}

                                    {shouldEnableChanges && isInteractiveTile && (
                                        <Hidden only={['xs']}>
                                            <Grid item sm={4} style={{ display: 'flex' }} justifyContent="flex-end">
                                                <Button
                                                    // id={`${componentId}-select-btn`}
                                                    color="primary"
                                                    isContrast={true}
                                                    onClick={(e) => {
                                                        navigate(AppRoute.AddOnPage);
                                                    }}
                                                >
                                                    {addOnList.length > 0 ? 'CHANGE' : 'ADD PRODUCTS'}
                                                </Button>
                                            </Grid>
                                        </Hidden>
                                    )}
                                </Grid>
                                <ContentContainer disableGrid>
                                    {addOnList && addOnList.length > 0 && (
                                        <Box sx={cssObject.addOnLayoutWrapper}>
                                            {addOnList.map((addOnListItem, index) => {
                                                return (
                                                    <AddOnItem
                                                        isInteractiveTile={props.isInteractiveTile}
                                                        addOnListItem={addOnListItem}
                                                        sku={addOnListItem.sku}
                                                        index={addOnListItem.sku}
                                                        key={index}
                                                    />
                                                );
                                            })}
                                        </Box>
                                    )}
                                </ContentContainer>
                                {shouldEnableChanges && isInteractiveTile && (
                                    <Hidden smUp>
                                        <Grid
                                            item
                                            xs={12}
                                            style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '15px' }}
                                        >
                                            <Button
                                                // id={`${componentId}-select-btn`}
                                                color="primary"
                                                isFullWidth={true}
                                                isContrast={true}
                                                onClick={(e) => {
                                                    navigate(AppRoute.AddOnPage);
                                                }}
                                            >
                                                {addOnList.length > 0 ? 'CHANGE' : 'ADD PRODUCTS'}
                                            </Button>
                                        </Grid>
                                    </Hidden>
                                )}
                            </Grid>
                            <Box component={'hr'} sx={cssObject.dividerStyle} />
                        </ContentContainer>
                    </ContentContainer>
                </ContentContainer>
            </ContentContainer>
        </Grid>
    );
}

interface AddOnItemProps {
    index: number;
    addOnListItem: any;
    sku: string;
    isInteractiveTile?: boolean;
}
const AddOnItem = (props: AddOnItemProps) => {
    const cssObject: SXCssObject = createCssObject(useTheme());
    const theme = useTheme();
    const { index, addOnListItem } = props;
    const isSm = useMediaQuery(theme.breakpoints.down('md'));
    const isDownOfXs = useMediaQuery(theme.breakpoints.only('xs'));
    const preCartItemsMain = useSelector(selectPreCartMain);
    const preCartItemsAddons = useSelector(selectPreCartAddon);
    const dispatch = useDispatch();
    const cart: Cart = useSelector(selectCart);
    const [showFullText, setShowFullText] = React.useState(false);
    const proceedToCart = useSelector(selectProceedToCart);

    const getTextBasedOnSize = (text: string, index: number) => {
        if (isDownOfXs) {
            return description(50, text, index);
        } else if (isSm) {
            return description(75, text, index);
        } else {
            return description(100, text, index);
        }
    };

    const toggleFullText = () => {
        setShowFullText(!showFullText);
    };
    const description = (trimLength: number, description: string, index: number) => (
        <Grid sx={cssObject.productDetailLabel}>
            <Paragraph fontSizeVerySmall={true} size={'small'} id={`description-level-${index}`}>
                {trimLength ? striptags(description).slice(0, trimLength) + '  ' : striptags(description)}
                {!!striptags(description) && (
                    <Paragraph semibold component="span">
                        <Box component={'span'} onClick={toggleFullText} sx={cssObject.readMoreText}>
                            {showFullText ? 'Read less' : 'Read more'}
                        </Box>
                    </Paragraph>
                )}
            </Paragraph>
        </Grid>
    );

    const handleCounterChange = (addOnQuantity: number) => {
        if (proceedToCart) {
            const addOnItemInCart: any = cart?.items?.find((item) => item.sku === addOnListItem.sku);
            if (addOnItemInCart) {
                addOnItemInCart.quantity = addOnQuantity;
                dispatch(updateCartItem(addOnItemInCart));
            } else {
                let singleAddOn = addOnListItem;
                singleAddOn.quantity = 1;
                singleAddOn.systemGroup = SystemGroup.AddOn;
                singleAddOn.itemType = ItemType.AddOn;
                dispatch(addCartItem({ cartItems: [{ ...singleAddOn }], shouldBlockSaveForLaterApi: true }));
            }
        }
        if (preCartItemsMain && preCartItemsMain.length > 0) {
            preCartItemsAddons.forEach((relatedProduct) => {
                if (relatedProduct.sku === props.sku) {
                    relatedProduct.quantity = addOnQuantity;
                }
                relatedProduct.itemType = ItemType.AddOn;
                relatedProduct.systemGroup = SystemGroup.AddOn;
            });
            dispatch(setAddonSelection({ addOnsList: [...preCartItemsAddons], shouldReplace: true }));
        }
    };

    const handleProductLevelAddToCartClick = (addOnListItem: any) => {
        handleCounterChange(1);
    };

    return (
        <Box sx={cssObject.addOnItemParent}>
            {addOnListItem.quantity > 0 && !props.isInteractiveTile && (
                <Box component={'span'} sx={cssObject.selectedIconStyle}>
                    {' '}
                    <SelectedAddress />{' '}
                </Box>
            )}
            <Box
                sx={[
                    cssObject.contentWrapper,
                    addOnListItem.quantity > 0 && !props.isInteractiveTile && cssObject.selectedAddOnStyle,
                ]}
            >
                <Grid item xs={12} container spacing={isDownOfXs ? 0 : 1} sx={cssObject.imageAndName}>
                    <Grid item xs={12} sm={4}>
                        <CardMedia image={addOnListItem.image} component={'img'} sx={cssObject.img} />
                    </Grid>
                    <Grid item xs={12} sm={8} sx={cssObject.imageNameAndPrice}>
                        <Paragraph semibold headingColor>
                            {addOnListItem.name}
                        </Paragraph>
                        <Paragraph semibold semiLargeFont headingColor>
                            ${addOnListItem.price}
                        </Paragraph>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    {
                        <div style={{ paddingTop: isSm ? '0px' : '10px' }}>
                            {showFullText
                                ? description(0, addOnListItem.description, index)
                                : getTextBasedOnSize(addOnListItem.description, index)}
                        </div>
                    }

                    {/* <SanitizeHTML html={props.description} /> */}
                </Grid>
                {!props.isInteractiveTile && (
                    <Grid style={{ margin: '16px 0px 8px' }}>
                        {addOnListItem.quantity === 0 ? (
                            <Button
                                isFullWidth={isDownOfXs}
                                isContrast
                                onClick={() => handleProductLevelAddToCartClick(addOnListItem)}
                            >
                                Add to Cart
                            </Button>
                        ) : (
                            <Grid item xs={12}>
                                <Counter quantity={addOnListItem.quantity} onCounterChange={handleCounterChange} />
                            </Grid>
                        )}
                    </Grid>
                )}
            </Box>
        </Box>
    );
};
export default AddOnProducts;
