import React from 'react';
import { Box, Dialog, DialogContent, Grid, useMediaQuery } from '@mui/material';
import { Theme, useTheme } from '@mui/material/styles';
import MuiDialogTitle from '@mui/material/DialogTitle';
import { Heading } from 'components/Heading';
import Close from 'components/icons/Close';
import ContentContainer from 'components/ContentContainer';
import { Paragraph } from 'components/Paragraph';
import { Button } from 'components/Button';
import { SXCssObject } from 'models/ThemeImages';
import {
    AddressInput,
    AddressValidationFields,
    AddressValidationModalData,
    AddressValidationStatus,
} from 'models/userProfile';
import SelectedAddress from 'components/icons/SelectedAddress';
import { Address } from 'models/cart';
import OtpErrorIcon from 'components/icons/OtpErrorIcon';

const createCssObject = (theme: Theme): SXCssObject => ({
    root: {
        p: {
            xs: '10px 0px',
            sm: '18px 0px',
        },
        background: theme.palette.background.paper,
    },
    main: {
        display: 'flex',
        flexDirection: 'column',
        marginBottom: {
            md: theme.spacing(0),
        },
    },
    modalContent: {
        height: 'auto',
        padding: {
            xs: '0px 16px 8px',
            sm: '8px 24px',
        },
    },
    modalTitle: {
        paddingRight: {
            xs: '40px',
            sm: '60px',
        },
    },
    modalTitleContainer: {
        padding: {
            xs: '10px 16px',
            sm: '10px 24px',
        },
        position: 'relative',
        marign: '0px',
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1.6),
        top: theme.spacing(0.5),
        color: theme.palette.grey[500],
        cursor: 'pointer',
    },
    modalPaper: {
        '& .MuiPaper-root': {
            borderRadius: '4px',
            margin: '24px',
            width: {
                xs: '80%',
                sm: '504px',
            },
            minWidth: {
                xs: '290px',
                sm: 'inherit',
            },
        },
    },
    modalSubTitle: {
        lineHeight: { xs: '16px' },
        fontSize: { xs: '12px', sm: '14px' },
    },
    toggleButtonStyle: {
        border: `1px solid ${theme.palette.divider}`,
        borderRadius: '4px',
        position: 'relative',
        padding: 2,
        margin: theme.spacing(1, 0),
        cursor: 'pointer',
    },
    toggleButtonStyleNew: {
        position: 'relative',
        padding: '0px 5px 10px 5px',
        margin: theme.spacing(0, 0, 1.5, 0),
    },
    selectedToggleStyle: {
        border: `1px solid ${theme.palette.action.hover}`,
    },
    unableTxt: {
        mt: '12px',
        mb: '12px',
    },
    addressEditIcon: {
        position: 'absolute',
        top: '10px',
        right: '10px',
    },
});
interface Props {
    handleClose: () => void;
    handleEdit: (addObj: Address) => void;
    handleSubmit: (addObj: Address, useSame?: boolean) => void;
    open: boolean;
    addressData: AddressValidationModalData[];
    hideCloseButton?: boolean;
}

export function AddressValidationModal(props: Props) {
    const theme = useTheme();
    const cssObject = createCssObject(theme);
    const isXs = useMediaQuery(theme.breakpoints.only('xs'));
    const { open, handleClose, handleSubmit, addressData, handleEdit } = props;
    const {
        addressEntered: firstAddressEntered,
        suggestedAddress: firstAddressSuggested,
        flow: firstAddressFlow,
        useSame: firstAddressUseSameFlag,
        reason: firstAddressReason,
    } = addressData[0];
    const addressDataArr = addressData.length > 0 && firstAddressFlow;

    const disableProceed = addressData.findIndex(
        (addressItem) => addressItem.status === AddressValidationStatus.Invalid
    );
    const title = firstAddressFlow ? `Please validate your ${firstAddressFlow} address` : '';
    const getEnteredAddress = (addressInput: Address): AddressInput => {
        return {
            postcode: addressInput.postcode || '',
            city: addressInput.city || '',
            region: addressInput.region || '',
            addressLine1: addressInput.address1,
            addressLine2: addressInput.address2,
        };
    };
    const validAddress = () => {
        return {
            ...firstAddressEntered,
            city: firstAddressSuggested?.city,
            postcode: firstAddressSuggested?.postcode,
            region: firstAddressSuggested?.region,
        };
    };
    const DialogTitle = () => {
        return (
            <MuiDialogTitle sx={cssObject.modalTitleContainer}>
                <Box sx={cssObject.modalTitle}>
                    <Heading isPopUp label={`Confirm Your Address`} />
                </Box>
                {!props.hideCloseButton && (
                    <Box aria-label="close" sx={cssObject.closeButton} onClick={handleClose}>
                        <Close />
                    </Box>
                )}
                <Paragraph sx={cssObject.modalSubTitle}>{title}</Paragraph>
            </MuiDialogTitle>
        );
    };
    interface CustomTabPanelProps {
        enteredAddress: AddressInput;
        suggestedAddress?: AddressValidationFields;
        reason?: string;
    }
    const CustomTabPanel = (props: CustomTabPanelProps) => {
        const { enteredAddress, suggestedAddress, reason } = props;
        return (
            <>
                {' '}
                <Box sx={[cssObject.toggleButtonStyleNew]}>
                    <Paragraph component={'span'} responsiveFontSize20 semibold headingColor>
                        Address Entered
                    </Paragraph>
                    <Paragraph semibold>
                        <Box component={'span'}>{enteredAddress.addressLine1}</Box>
                    </Paragraph>
                    {enteredAddress.addressLine2 && (
                        <Paragraph semibold>
                            <Box component={'span'}>{enteredAddress.addressLine2}</Box>
                        </Paragraph>
                    )}
                    <Paragraph semibold>
                        {enteredAddress.city + ', ' + enteredAddress.postcode + ', ' + enteredAddress.region}
                    </Paragraph>
                </Box>
                <Box
                    sx={[
                        cssObject.toggleButtonStyle,
                        suggestedAddress ? cssObject.selectedToggleStyle : { cursor: 'default' },
                    ]}
                >
                    {suggestedAddress && (
                        <Box component={'span'} sx={cssObject.addressEditIcon}>
                            <SelectedAddress />
                        </Box>
                    )}
                    {suggestedAddress && (
                        <Paragraph component={'span'} responsiveFontSize20 semibold headingColor>
                            Suggested Valid Address
                        </Paragraph>
                    )}
                    {suggestedAddress ? (
                        <>
                            <Paragraph semibold>
                                <Box component={'span'}>{enteredAddress.addressLine1}</Box>
                            </Paragraph>
                            {enteredAddress.addressLine2 && (
                                <Paragraph semibold>
                                    <Box component={'span'}>{enteredAddress.addressLine2}</Box>
                                </Paragraph>
                            )}
                            <Paragraph semibold>
                                {suggestedAddress.city +
                                    ', ' +
                                    suggestedAddress.postcode +
                                    ', ' +
                                    suggestedAddress.region}
                            </Paragraph>
                        </>
                    ) : (
                        <Box display={'flex'} sx={cssObject.unableTxt} alignItems={'center'}>
                            <OtpErrorIcon />
                            &nbsp; &nbsp;{' '}
                            <Paragraph semibold>{reason || 'System unable to suggest any valid address'}</Paragraph>
                        </Box>
                    )}
                </Box>
            </>
        );
    };

    return (
        <Dialog
            onClose={() => {}}
            open={open}
            maxWidth={'sm'}
            disableEscapeKeyDown={true}
            aria-describedby="alert-dialog-slide-description"
            sx={cssObject.modalPaper}
        >
            <Box sx={cssObject.root}>
                <Box sx={cssObject.main}>
                    <DialogTitle></DialogTitle>
                    <DialogContent sx={cssObject.modalContent}>
                        <ContentContainer disableGrid>
                            {addressDataArr && (
                                <Box sx={{ mt: 2, mb: 4 }}>
                                    <CustomTabPanel
                                        enteredAddress={getEnteredAddress(firstAddressEntered)}
                                        suggestedAddress={firstAddressSuggested}
                                        reason={firstAddressReason || ''}
                                    />
                                </Box>
                            )}
                        </ContentContainer>
                        <Grid
                            container
                            spacing={1}
                            flexDirection={isXs ? 'column-reverse' : 'row'}
                            justifyContent={'space-between'}
                        >
                            <Grid item xs={12} sm={6}>
                                <Button
                                    id={'modal-edit-address'}
                                    outlinedWithBorder
                                    color={'primary'}
                                    isFullWidth={true}
                                    onClick={() => handleEdit(firstAddressEntered)}
                                >
                                    EDIT ADDRESS
                                </Button>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Button
                                    id={'modal-update-address'}
                                    color={'primary'}
                                    isFullWidth={true}
                                    onClick={() => handleSubmit(validAddress(), firstAddressUseSameFlag)}
                                    disabled={disableProceed !== -1}
                                >
                                    PROCEED
                                </Button>
                            </Grid>
                        </Grid>
                    </DialogContent>
                </Box>
            </Box>
        </Dialog>
    );
}
