import { Region } from 'models/country';
import { Address } from '../../../models/cart';

export const transformAddressObjForEdit = (address: Address, loginData: any) => {
    if (address.id) {
        //Existing address is edited. pass 'id' as 'customerAddressId' which is expected in API
        address.customerAddressId = address.id;
        delete address.id;
    } else {
        address.telephone = loginData.phone.replace(/\./g, '');
        address.country = 'US';
    }
    address.saveInAddressBook = true;
    return address;
};

export const transformPropertyObjForEdit = (address: Address, loginData: any) => {
    if (!address.id) {
        //New address. Add country, telephone
        address.telephone = loginData.phone.replace(/\./g, '');
        address.country = 'US';
    }
    return address;
};
export const getRegion = (state: string, regions?: [Region]) =>
    regions && regions.length > 0
        ? regions?.find((region) => region.region === state || region.display === state)
        : {
              id: 1,
              region: state,
              display: state,
          };
