import React from 'react';
import Backdrop from '@mui/material/Backdrop';
import LoadingInternal from '../icons/Loading';

interface Props {
    open: boolean;
}
export default function LoadingOverlay(props: Props) {
    return (
        <Backdrop sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }} open={props.open}>
            <LoadingInternal />
        </Backdrop>
    );
}
