import React from 'react';
import { Theme, useTheme } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import { Button } from 'components/Button';
import ContentContainer from 'components/ContentContainer';
import { Paragraph } from '../Paragraph';
import { ThemeWithAdditionalData } from '../../themes/theme';
import useMediaQuery from '@mui/material/useMediaQuery';
import HomeHow1 from 'assets/icons/icon_home_how_1.svg';
import HomeHow2 from 'assets/icons/icon_home_how_2.svg';
import HomeHow3 from 'assets/icons/icon_home_how_3.svg';
import ArrowDown from 'components/icons/ArrowDown2';

import HomeHow4 from 'assets/icons/icon_home_how_4.svg';
import { Box, Container, Typography } from '@mui/material';
import VideoOverlay from 'components/VideoOverlay';
import { getThemePrimaryLight } from 'components/common/Utilities';
import { SXCssObject } from 'models/ThemeImages';

const createCssObject = (theme: Theme): SXCssObject => ({
    root: {
        margin: {
            lg: '0px 88px',
        },
        borderRadius: {
            lg: '18px',
        },
        padding: {
            xs: '40px 20px',
            sm: '60px 40px',
            lg: '80px',
        },
        background: theme.palette.common.white,
    },
    headingStyle: {
        marginBottom: {
            xs: '20px',
            sm: '30px',
            md: '50px',
        },
        textAlign: 'center',
    },
    processItemWrapper: {
        display: 'flex',
        flexDirection: {
            xs: 'column',
            sm: 'inherit',
        },
        alignItems: {
            xs: 'center',
            sm: 'inherit',
        },
        width: {
            xs: '100%',
            sm: 'inherit',
        },
    },
    processWrapper: {
        borderRadius: '18px',
        width: '100%',
        boxShadow: '0px 8px 52px #0000000F',
        padding: {
            xs: '18px 16px',
            sm: '24px 28px',
            md: '20px 50px 30px',
        },
    },
    processHeadingImage: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: {
            xs: 'center',
            sm: 'inherit',
        },
        marginBottom: {
            sm: '12px',
            md: '20px',
        },
    },
    processDescription: {
        display: {
            sm: 'flex',
        },
        flexDirection: {
            sm: 'column',
        },
        alignItems: {
            sm: 'center',
        },
        textAlign: {
            sm: 'center',
        },
    },
    processImageStyle: {
        width: {
            xs: '56px',
            sm: '88px',
            lg: '108px',
        },
        height: {
            xs: '56px',
            sm: '88px',
            lg: '108px',
        },
    },
    dividerLineStyle: {
        backgroundColor: theme.palette.primary.main,
        width: '16px',
        height: '2px',
        display: 'flex',
        marginBottom: {
            xs: '8px',
            sm: '16px',
        },
    },
    connectorArrowWrapper: {
        display: 'flex',
        transform: {
            xs: 'rotate(90deg)',
            sm: 'inherit',
        },
        margin: {
            xs: '-3px 50% -3px 0px',
            sm: '0px -4px',
            lg: '0px -10px',
        },
        alignItems: {
            xs: 'left',
            sm: 'center',
        },
    },
    connectorArrowStyle: {
        alignItems: 'center',
        width: {
            xs: '18px',
            md: '24px',
            lg: '34px',
        },
        height: {
            xs: '18px',
            md: '24px',
            lg: '34px',
        },
        padding: {
            xs: '2px',
            sm: '1px',
            md: '3px',
            lg: '8px',
        },
        display: 'flex',
        '& svg': {
            width: '100%',
        },
        background: getThemePrimaryLight(theme),
        borderRadius: '50%',
    },
    submitBtnWrapper: {
        display: 'flex',
        justifyContent: 'center',
        marginTop: {
            xs: '20px',
            sm: '40px',
        },
    },
});

function HowThisWork(props: any) {
    const cssObject: SXCssObject = createCssObject(useTheme());
    const theme = useTheme<ThemeWithAdditionalData>();
    const downXs = useMediaQuery(theme.breakpoints.only('xs'));
    const downSm = useMediaQuery(theme.breakpoints.down('md'));
    const [openVdo, setOpenVdo] = React.useState(false);
    const handleCloseVdo = () => {
        setOpenVdo(false);
    };
    return (
        <ContentContainer disableGrid sx={cssObject.root}>
            <Container maxWidth={'lg'} disableGutters>
                <Box sx={cssObject.headingStyle}>
                    <Typography
                        variant={downXs ? 'h4' : downSm ? 'h3' : 'h2'}
                        align={'center'}
                        style={{ marginBottom: '12px' }}
                    >
                        Revolutionizing the Way to Purchase
                    </Typography>
                    <Paragraph responsiveFontSize18>
                        Take these four easy steps today to get your quote and schedule your pre-installation inspection{' '}
                    </Paragraph>
                </Box>
                <Grid container>
                    <Grid item container xs={12} sm={3}>
                        <Box sx={cssObject.processItemWrapper}>
                            <Box sx={cssObject.processWrapper}>
                                <Grid container alignItems={'center'}>
                                    <Grid item xs={4} sm={12} sx={cssObject.processHeadingImage}>
                                        <Box
                                            component={'img'}
                                            sx={cssObject.processImageStyle}
                                            src={HomeHow1}
                                            alt={'step 1'}
                                        />
                                    </Grid>
                                    <Grid item xs={8} sm={12} sx={cssObject.processDescription}>
                                        <Paragraph semibold headingColor>
                                            STEP 01
                                        </Paragraph>
                                        <Box component={'span'} sx={cssObject.dividerLineStyle} />
                                        <Paragraph fontSizeVerySmall>
                                            Answer 5 questions about your home and current HVAC system
                                        </Paragraph>
                                    </Grid>
                                </Grid>
                            </Box>
                            <Box sx={cssObject.connectorArrowWrapper}>
                                <Box component={'span'} sx={cssObject.connectorArrowStyle}>
                                    <ArrowDown />
                                </Box>
                            </Box>
                        </Box>
                    </Grid>
                    <Grid item container xs={12} sm={3}>
                        <Box sx={cssObject.processItemWrapper}>
                            <Box sx={cssObject.processWrapper}>
                                <Grid container alignItems={'center'}>
                                    <Grid item xs={4} sm={12} sx={cssObject.processHeadingImage}>
                                        <Box
                                            component={'img'}
                                            sx={cssObject.processImageStyle}
                                            src={HomeHow2}
                                            alt={'step 2'}
                                        />
                                    </Grid>
                                    <Grid item xs={8} sm={12} sx={cssObject.processDescription}>
                                        <Paragraph semibold headingColor>
                                            STEP 02
                                        </Paragraph>
                                        <Box component={'span'} sx={cssObject.dividerLineStyle} />
                                        <Paragraph fontSizeVerySmall>
                                            Receive and review up to four HVAC system options
                                        </Paragraph>
                                    </Grid>
                                </Grid>
                            </Box>
                            <Box sx={cssObject.connectorArrowWrapper}>
                                <Box component={'span'} sx={cssObject.connectorArrowStyle}>
                                    <ArrowDown />
                                </Box>
                            </Box>
                        </Box>
                    </Grid>
                    <Grid item container xs={12} sm={3}>
                        <Box sx={cssObject.processItemWrapper}>
                            <Box sx={cssObject.processWrapper}>
                                <Grid container alignItems={'center'}>
                                    <Grid item xs={4} sm={12} sx={cssObject.processHeadingImage}>
                                        <Box
                                            component={'img'}
                                            sx={cssObject.processImageStyle}
                                            src={HomeHow3}
                                            alt={'step 3'}
                                        />
                                    </Grid>
                                    <Grid item xs={8} sm={12} sx={cssObject.processDescription}>
                                        <Paragraph semibold headingColor>
                                            STEP 03
                                        </Paragraph>
                                        <Box component={'span'} sx={cssObject.dividerLineStyle} />
                                        <Paragraph fontSizeVerySmall>
                                            Select the system that matches your needs and budget
                                        </Paragraph>
                                    </Grid>
                                </Grid>
                            </Box>
                            <Box sx={cssObject.connectorArrowWrapper}>
                                <Box component={'span'} sx={cssObject.connectorArrowStyle}>
                                    <ArrowDown />
                                </Box>
                            </Box>
                        </Box>
                    </Grid>
                    <Grid item container xs={12} sm={3}>
                        <Box sx={cssObject.processItemWrapper}>
                            <Box sx={cssObject.processWrapper}>
                                <Grid container alignItems={'center'}>
                                    <Grid item xs={4} sm={12} sx={cssObject.processHeadingImage}>
                                        <Box
                                            component={'img'}
                                            sx={cssObject.processImageStyle}
                                            src={HomeHow4}
                                            alt={'step 4'}
                                        />
                                    </Grid>
                                    <Grid item xs={8} sm={12} sx={cssObject.processDescription}>
                                        <Paragraph semibold headingColor>
                                            STEP 04
                                        </Paragraph>
                                        <Box component={'span'} sx={cssObject.dividerLineStyle} />
                                        <Paragraph fontSizeVerySmall>
                                            Schedule your preferred pre-installation inspection date and time
                                        </Paragraph>
                                    </Grid>
                                </Grid>
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
                <Box style={{ display: 'flex', justifyContent: 'center' }}>
                    <Grid item  sx={cssObject.submitBtnWrapper}>
                        <Button
                            id={'how-this-work-kc-btn'}
                            color={'primary'}
                            isContrast
                            isFullWidth={downXs ? true : false}
                            homePageLargeButton
                            onClick={() => {
                                setOpenVdo(true);
                            }}
                        >
                            Explore Our Simple Process
                        </Button>
                    </Grid>
                </Box>
                <VideoOverlay
                    open={openVdo}
                    onClose={handleCloseVdo}
                    heading={'Our Process'}
                    paragraph={'Learn what to expect next'}
                    src={
                        'https://res.cloudinary.com/motili/video/upload/v1594975788/ecomm/video/AG_ITSEASY_FINAL_REV_1080.mp4'
                    }
                />
            </Container>
        </ContentContainer>
    );
}

export default HowThisWork;
