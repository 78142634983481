import { useTheme } from '@mui/material/styles';
import { getThemePrimaryMain } from 'components/common/Utilities';
import * as React from 'react';

function ArrowRightHome(props: any) {
    const theme = useTheme();
    const themePrimaryMain = getThemePrimaryMain(theme);
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} {...props}>
            <g stroke={themePrimaryMain} strokeLinecap="round" strokeWidth={3} fill="none">
                <path d="M15.517 7.953l4.135 4.019-4.135 4.075" strokeLinejoin="round" />
                <path d="M19.533 12H4.637" />
            </g>
        </svg>
    );
}

export default ArrowRightHome;
