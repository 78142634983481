import React from 'react';
import { Theme } from '@mui/material/styles';
import { Box, CardMedia, Dialog, DialogContent, Grid, useTheme } from '@mui/material';
import ContentContainer from 'components/ContentContainer';
import AlertIcon from 'components/icons/AlertIcon';
import { Button } from 'components/Button';
import { Heading } from 'components/Heading';
import { CartItem } from 'models/cart';
import { Paragraph } from 'components/Paragraph';
import { getComponent } from 'components/icons/Level';
import { SXCssObject } from 'models/ThemeImages';
import { currencyFormat } from 'components/common/NumberUtil';

const createCssObject = (theme: Theme): SXCssObject => ({
    root: {
        padding: {
            xs: '10px 0px',
            sm: '18px 0px',
        },
    },
    modalContent: {
        display: 'flex',
        justifyContent: 'center',
        margin: '10px 0px 30px',
        height: 'auto',
        padding: {
            xs: '8px 12px',
            sm: '24px 20px',
        },
    },
    alertIconStyle: {
        marginBottom: '24px',
        display: 'flex',
        justifyContent: 'center',
    },
    centerContent: {
        display: 'flex',
        justifyContent: 'center',
        paddingBottom: '5px',
    },
    buttonStyle: {
        marginTop: '24px',
        display: 'flex',
        justifyContent: 'center',
    },
    modalPaper: {
        borderRadius: '4px',
        margin: '24px',
        width: {
            xs: '80%',
            sm: '504px',
        },
        minWidth: {
            xs: '290px',
            sm: 'inherit',
        },
    },
    fullWidth: {
        width: '100%',
    },

    img: {
        objectFit: 'contain',
        border: '1px solid #D4D4D4',
        borderRadius: '4px',
        width: '100%',
        height: '100px',
    },
});

interface Props {
    open: boolean;
    itemIAQ: CartItem;
    handleClose: (shouldProceed?: boolean) => void;
}

export function DeleteIAQModal(props: Props) {
    const cssObject: SXCssObject = createCssObject(useTheme());
    const { itemIAQ } = props;
    const theme = useTheme();
    const Component = itemIAQ && getComponent(itemIAQ.productAttributes.level);
    if (!Component) return null;

    const systemPrice = (
        <Paragraph semibold responsiveFontSize22 headingColor>
            {currencyFormat.format(itemIAQ.price)}
        </Paragraph>
    );
    const systemPriceLabel = <Paragraph fontSizeVerySmall={true}>System Price</Paragraph>;

    return (
        <Dialog
            onClose={() => {}}
            open={props.open}
            maxWidth={'sm'}
            disableEscapeKeyDown={true}
            aria-describedby="alert-dialog-slide-description"
            PaperProps={{
                sx: cssObject.modalPaper,
            }}
        >
            <DialogContent sx={cssObject.modalContent}>
                <ContentContainer disableGrid sx={cssObject.fullWidth}>
                    <Box sx={cssObject.alertIconStyle}>
                        <AlertIcon />
                    </Box>
                    <Box sx={cssObject.centerContent}>
                        <Heading label={'Keep Related Products?'} isPopUp />
                    </Box>
                    <Box style={{ margin: '0px 10px', textAlign: 'center', display: 'flex', justifyContent: 'center' }}>
                        <Paragraph>
                            The HVAC system you just removed from your cart also supported the Indoor Air Quality (IAQ)
                            package below. What do you want to do?
                        </Paragraph>
                    </Box>
                    <Box style={{ border: `1px solid ${theme.customProperties.border}`, marginTop: '24px' }}>
                        <Grid container>
                            <Grid
                                container
                                item
                                xs={12}
                                alignItems={'center'}
                                style={{
                                    padding: '16px 12px',
                                    marginBottom: '12px',
                                    backgroundColor: theme.customProperties.border,
                                }}
                            >
                                <Component />
                                <Box style={{ marginLeft: '6px' }}>
                                    <Paragraph
                                        responsiveFontSize22
                                        headingStyle
                                        bold
                                    >{`IAQ ${itemIAQ.productAttributes.level} Package`}</Paragraph>
                                </Box>
                            </Grid>
                            <Grid item xs={7} sm={6} style={{ padding: '12px' }}>
                                <CardMedia image={props.itemIAQ.image} component={'img'} sx={cssObject.img} />
                            </Grid>
                            <Grid
                                item
                                container
                                xs={5}
                                sm={6}
                                style={{ padding: '12px 0px' }}
                                direction={'column'}
                                justifyContent={'space-evenly'}
                            >
                                <Grid item>
                                    {systemPriceLabel}
                                    {systemPrice}
                                </Grid>
                            </Grid>
                        </Grid>
                    </Box>
                    <Box sx={cssObject.buttonStyle}>
                        <Grid item xs={12}>
                            <Grid container spacing={1}>
                                <Grid item xs={12} sm={6} sx={cssObject.previousBtnStyle}>
                                    <Button
                                        id={`iaq-del-keep-it`}
                                        isContrast
                                        onClick={() => props.handleClose(false)}
                                        isFullWidth={true}
                                    >
                                        KEEP IT FOR NOW
                                    </Button>
                                </Grid>
                                <Grid item xs={12} sm={6} sx={cssObject.nextBtnStyle}>
                                    <Button
                                        isFullWidth={true}
                                        id={`iaq-del-confirm-it`}
                                        color={'primary'}
                                        disabled={false}
                                        onClick={() => props.handleClose(true)}
                                    >
                                        REMOVE ITEM
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Box>
                </ContentContainer>
            </DialogContent>
        </Dialog>
    );
}
