import React, { PropsWithChildren } from 'react';
import { FormikProps, withFormik } from 'formik';
import { styled, Theme, useTheme } from '@mui/material/styles';
import Divider from '@mui/material/Divider';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import ContentContainer from '../../components/ContentContainer';
import WizardHeader from '../../components/WizardHeader';
import FirstSystemInfo from '../wizard/FirstSystemInfo';
import WizardSelection from '../../components/WizardSelection';
import HeadlineParagraph from '../../components/HeadlineParagraph';
import LoadingOverlay from '../../components/common/LoadingOverlay';
import {
    SquareFootage,
    ProductCriteria,
    SplitOrPackage,
    NumberOfHVAC,
    HomeSystem,
    SplitReplacementType,
    PackageReplacementType,
    PartialSystemType,
    DuctlessFirstSquareFootage,
    DuctlessSecondSquareFootage,
    DuctlessNumberOfRooms,
    FurnaceEfficiency,
    TypeOfService,
} from '../../models/productCriteria';
import { Options } from '../../components/WizardInputs';
import { schemaAboutYouHome } from './wizard-schema';
import WizardCriteriaFooterNew from 'components/WizardCriteriaFooterNew';
import { Paragraph } from 'components/Paragraph';
import { Box, Button, ClickAwayListener, Icon, LinearProgress, useMediaQuery } from '@mui/material';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import Shield from 'components/icons/Shield';
import WizardNotification from 'components/WizardNotification';
import InfoIcon from 'components/icons/InfoToolTip';
import { useSelector } from 'react-redux';
import NoProductPopup from 'components/NoProductPopup';
import { selectNoProduct } from 'store/selectors/productSearch';
import { Button as CustomButton } from 'components/Button';
import TooltipIcon from 'components/icons/TooltipIcon';
import { SXCssObject } from 'models/ThemeImages';
import { selectMultipleOpenProjects } from 'store/selectors/storeSelector';
import { isHomeVestors } from '../../config';

const createCssObject = (theme: Theme): SXCssObject => ({
    root: {
        backgroundColor: theme.palette.background.default,
    },
    main: {
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: theme.palette.background.default,
        minHeight: `calc(100vh - ${theme.spacing(38)})`,
    },
    wizard: {
        paddingTop: '130px !important',
        px: '15px',
        paddingBottom: {
            md: '150px',
        },
        minHeight: {
            xs: `calc(100vh - ${theme.spacing(20)})`,
            md: `calc(100vh - ${theme.spacing(1)})`,
            lg: `calc(100vh - ${theme.spacing(20)})`,
        },
        display: ' flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
    },
    actions: {
        position: {
            sm: 'fixed',
        },
        left: {
            sm: 0,
        },
        bottom: {
            sm: 0,
        },
        width: {
            sm: '100%',
        },
        padding: {
            sm: 0,
        },
        height: {
            sm: '80px',
            lg: 'auto',
        },
        backgroundColor: 'white',
    },
    helpFooter: {
        padding: theme.spacing(1, 0, 1, 0),
        paddingRight: {
            xs: theme.spacing(0),
            lg: 'inherit',
        },
    },
    footerButton: {
        paddingLeft: {
            md: theme.spacing(10),
            lg: 'inherit',
        },
    },

    paragraphPadding: {
        paddingBottom: {
            xs: theme.spacing(0.5),
            sm: theme.spacing(1.5),
        },
    },
    fix: {
        position: 'fixed',
        maxWidth: '500px'
    },
    wizardQuestionCard: {
        backgroundColor: theme.palette.common.white,
        px: theme.spacing(0),
        py: theme.spacing(1.5),
        borderRadius: '4px'
    },
    subHeadingPadding: {
        paddingTop: theme.spacing(1.5),
        paddingBottom: theme.spacing(2),
        paddingRight: {
            lg: theme.spacing(1),
        },
    },
    tooltipIconButton: {
        minWidth: theme.spacing(0.5),
        paddingBottom: theme.spacing(0.8),
        paddingLeft: theme.spacing(0.5),
    },
    boxShadow: {
        boxShadow: '0px 5px 15px #5B5B5B17',
    },
    tooltip: {
        backgroundColor: theme.palette.primary.light,
        borderRadius: '5px 0px 5px 5px',
        fontSize: '1.875rem',
        padding: '0 20px 20px 20px',
        margin: '10px 0 0 0',
        lineHeight: '1.25rem',
        boxShadow: '0px 6px 17px #00000012',
        pointerEvents: 'auto',
        border: `1px solid ${theme.palette.primary.contrastText}`,
    },
    tooltipArrow: {
        transform: 'rotate(180deg)',
        float: 'right',
        position: 'relative',
        top: '-12.5px',
        color: theme.palette.primary.light,
        left: '28.5px',
        stroke: theme.palette.primary.contrastText,
        strokeWidth: '1px',
        strokeDasharray: '27 13 28.284',
    },
    tooltipParagraph: {
        textAlign: 'justify',
        fontSize: {
            xs: '0.875rem',
            lg: '1rem',
        },
        lineHeight: {
            lg: '1.375rem',
        },
        letterSpacing: '0px',
    },
    tolltipHeading: {
        textAlign: 'left',
        fontSize: '1.4375rem',
        letterSpacing: '0px',
        paddingBottom: '10px',
        color: theme.typography.h1.color,
        fontWeight: theme.typography.fontWeightMedium,
        display: 'flex',
        alignItems: 'center',
    },
    tooltipButtonContainer: {
        display: 'flex',
        alignItems: 'center',
        paddingTop: {
            xs: '10px',
            sm: 'inherit',
        },
        justifyContent: {
            xs: 'left',
            sm: 'flex-end',
        },
    },

    tooltipIcon: {
        width: '29px',
        height: '27px',
        marginRight: '5px',
        marginBottom: '-3px',
    },
    scrollable: {
        overflowY: 'auto',
        listStyle: 'none',
        scrollbarWidth: 'none',
        scrollbarColor: 'transparent',
        height: {
            sm: theme.spacing(35),
            md: 'auto',
        },
        '&::-webkit-scrollbar': {
            width: '0px',
        },
        '&::-webkit-scrollbar-track': {
            boxShadow: 'inset 0 0 0px rgba(0,0,0,0.00)',
            webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
        },
        '&::-webkit-scrollbar-thumb': {
            backgroundColor: 'transparent',
        },
    },
    scrollableFirst: {
        overflowY: 'auto',
        listStyle: 'none',
        height: {
            sm: theme.spacing(46),
            md: 'auto',
        },
        '&::-webkit-scrollbar': {
            width: '0px',
        },
        '&::-webkit-scrollbar-track': {
            boxShadow: 'inset 0 0 0px rgba(0,0,0,0.00)',
            webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
        },
        '&::-webkit-scrollbar-thumb': {
            backgroundColor: 'transparent',
        },
    },
    mt10: {
        marginTop: '10px',
    },
});

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 4,
    borderRadius: 5,
    width: 'auto',
    [theme.breakpoints.up('sm')]: {
        width: '266px',
    },
    backgroundColor: `${theme.palette.grey[theme.palette.mode === 'light' ? 200 : 700]} !important`,
    marginBottom: theme.spacing(1),
    '& .MuiLinearProgress-colorPrimary': {
        backgroundColor: `${theme.palette.grey[theme.palette.mode === 'light' ? 200 : 700]} !important`,
    },
    '& 	.MuiLinearProgress-bar': {
        borderRadius: 5,
        backgroundColor: `${theme.palette.primary.contrastText}`,
    },
}));
interface FormValues {
    squareFootage: string;
    typeOfService: string;
    homeSystem: any;
    splitOrPackage: string;
    numberOfHVAC: string;
    splitReplacementType: string;
    packageReplacementType: string;
    partialSystemType: string;
    ductlessFirstSquareFootage: string;
    ductlessSecondSquareFootage: string;
    ductlessNumberOfRooms: string;
    efficiency: string;
}

interface Props {
    loading: boolean;
    criteria: ProductCriteria;
    isDoubleSystem: boolean;
    addSecondSystemFromCart?: boolean;
    onNext: (data: ProductCriteria) => void;
    onExit: () => void;
    width?: 'xs' | 'sm' | 'md' | 'lg' | 'xl';
    children: React.ReactNode;
}
const tooltipParagraphText = {
    fullText:
        'Air and Heat Done Right offers turnkey HVAC replacement solutions for your home, whether your are replacing your entire system, part of your system, or adding a new system to your home.',
    HVACSystem:
        'Select <b style="font-weight:500">"Single"</b> to quote one HVAC system or partial system (like AC only or heat only) and choose <b style="font-weight:500">“Multiple“</b> if you would like us to quote more than one system',
    TypeOfService:
        'Whether you want Motili’s full-service installation or have your own contractor, we’re here to help.',
    HVACProductTypes:
        'Select <b style="font-weight:500">"Complete"</b> to quote an entire heating/cooling system, <b style="font-weight:500">“Partial”</b> if you only require an AC, furnace or heat pump replacement and <b style="font-weight:500">Ductless"</b> for a ductless (Mini-Split) system',
    SplitOrPackage:
        'A <b style="font-weight:500">"Split-System"</b> has two primary components (the in-home unit and a separate outdoor unit) while a <b style="font-weight:500">“Packaged Outdoor Unit"</b> has a single large box placed outside or on the roof containing both heating and AC components',
    SplitReplacement: 'Select the heating and cooling options you would like for your home',
    PackageReplacement: 'Please select the type of packaged system that you will be replacing.',
    PartialSystem:
        'If you only need to replace your AC choose <b style="font-weight:500">"AC/Coil”</b>. Select <b style="font-weight:500">“Furnace”</b> for a replacement gas furnace and choose <b style="font-weight:500">“Upgrade Furnace Efficiency”</b> to upgrade your current ≤ 80% efficient furnace with a ≥ 90% efficient furnace',
    efficiency:
        'Select <b style="font-weight:500">"≤ 80% efficient (metal flue pipe)"</b> if your current system has a single metal exhaust flue pipe and <b style="font-weight:500">"≥ 90% efficient (PVC Flue Pipes)"</b> if your system has two PVC flue pipes (one for fresh air intake and one for exhaust)',
    ductless: 'Select the quantity of rooms you will install the indoor ductless air handler units into.',
    ductlessOneRoom:
        'Select the room size for each room that the indoor ductless air handlers will be heating and cooling',
    ductlessTwoRoom:
        'Select the room size for each room that the indoor ductless air handlers will be heating and cooling',
    squareFootage:
        'Select your home’s approximate square footage. If you have a basement, exclude that footage from your home’s total area.',
};

const HvacSystemOptions: Options[] = [
    { id: 'hvac-system-opt1', value: NumberOfHVAC.Single, label: 'One' },
    { id: 'hvac-system-opt2', value: NumberOfHVAC.Double, label: 'Two' },
    { id: 'hvac-system-opt3', value: NumberOfHVAC.Multiple, label: 'Three +' },
];
const TypeOfServiceOptions: Options[] = [
    { id: 'type-of-service-opt1', value: TypeOfService.FullyInstalled, label: 'Fully-Installed Replacement' },
    { id: 'type-of-service-opt2', value: TypeOfService.EquipmentOnly, label: 'Purchase Equipment Only' },
];
const DuctlessRoomsOptions: Options[] = [
    { id: 'ductless-system-opt1', value: DuctlessNumberOfRooms.Single, label: 'One' },
    { id: 'ductless-system-opt2', value: DuctlessNumberOfRooms.Double, label: 'Two' },
    { id: 'ductless-system-opt3', value: DuctlessNumberOfRooms.Multiple, label: 'Three +' },
];
const DuctlessFirstSquareFootageOptions: Options[] = [
    { id: 'ductless-area-system-opt1', value: DuctlessFirstSquareFootage.Square250, label: '< 250 sq ft' },
    { id: 'ductless-area-system-opt2', value: DuctlessFirstSquareFootage.Square400, label: '251-400 sq ft' },
    { id: 'ductless-area-system-opt3', value: DuctlessFirstSquareFootage.Square600, label: '401-600 sq ft' },
    { id: 'ductless-area-system-opt4', value: DuctlessFirstSquareFootage.Square850, label: '601-850 sq ft' },
];
const DuctlessSecondSquareFootageOptions: Options[] = [
    { id: 'ductless-area-system-opt1', value: DuctlessSecondSquareFootage.Square250, label: '< 250 sq ft' },
    { id: 'ductless-area-system-opt2', value: DuctlessSecondSquareFootage.Square400, label: '251-400 sq ft' },
    { id: 'ductless-area-system-opt3', value: DuctlessSecondSquareFootage.Square600, label: '401-600 sq ft' },
    { id: 'ductless-area-system-opt4', value: DuctlessSecondSquareFootage.Square850, label: '601-850 sq ft' },
];
const SquareFeetOptions: Options[] = [
    { id: 'square-feet-opt1', value: SquareFootage.Square750, label: '<750 sq ft' },
    { id: 'square-feet-opt2', value: SquareFootage.Square1000, label: '751-1000 sq ft' },
    { id: 'square-feet-opt3', value: SquareFootage.Square1250, label: '1001-1250 sq ft' },
    { id: 'square-feet-opt4', value: SquareFootage.Square1500, label: '1251-1500 sq ft' },
    { id: 'square-feet-opt5', value: SquareFootage.Square1750, label: '1501-1750 sq ft' },
    { id: 'square-feet-opt6', value: SquareFootage.Square2000, label: '1751 – 2000 sq ft' },
    { id: 'square-feet-opt7', value: SquareFootage.Square2500, label: '2001-2500 sq ft' },
    { id: 'square-feet-opt8', value: SquareFootage.Square2600, label: '2500+ sq ft' },
];
const CompleteOrPartialOrDuctlessOptions: Options[] = [
    { id: 'complete-system-opt1', value: HomeSystem.Complete, label: 'Complete (Heat & AC)' },
    { id: 'Partial-system-opt2', value: HomeSystem.Partial, label: 'Partial (Heat or AC)' },
    { id: 'Ductless-system-opt3', value: HomeSystem.Ductless, label: 'Ductless' },
    { id: 'Ductless-system-opt4', value: HomeSystem.IAQ, label: 'Indoor Air Quality Package' },
];
const CompleteOrPartialOrDuctlessEquipmentOnlyOptions: Options[] = [
    { id: 'complete-system-opt1', value: HomeSystem.Complete, label: 'Complete (Heat & AC)' },
    { id: 'Partial-system-opt2', value: HomeSystem.Partial, label: 'Partial (Heat or AC)' },
    { id: 'Ductless-system-opt3', value: HomeSystem.Ductless, label: 'Ductless' },
];
const SplitTypeReplacementOptions: Options[] = [
    { id: 'split-system-opt1', value: SplitReplacementType.HeatPump, label: 'Split Heat Pump' },
    { id: 'split-system-opt2', value: SplitReplacementType.FurnaceAC, label: 'AC & Gas Furnace' },
    {
        id: 'split-system-opt3',
        value: SplitReplacementType.ElectricalFurnaceAC,
        label: 'AC & Electric Furnace',
    },
    {
        id: 'split-system-opt4',
        value: SplitReplacementType.HighEfficiencyFurnaceAC,
        label: 'Replace AC & Upgrade Furnace Efficiency',
    },
];
const PackagedTypeReplacementOptions: Options[] = [
    { id: 'packaged-system-opt1', value: PackageReplacementType.HeatPumpPack, label: 'Heat Pump' },
    { id: 'packaged-system-opt2', value: PackageReplacementType.ElectricalHeatAC, label: 'AC/Electrical Heat' },
    { id: 'packaged-system-opt3', value: PackageReplacementType.GasHeatAC, label: 'AC/Gas Heat' },
];
const PartialTypeReplacementOptions: Options[] = [
    { id: 'partial-system-opt1', value: PartialSystemType.CoilAC, label: 'AC/Coil' },
    { id: 'partial-system-opt2', value: PartialSystemType.Furnace, label: 'Furnace' },
    { id: 'partial-system-opt3', value: PartialSystemType.FurnaceEfficiency, label: 'Upgrade Furnace Efficiency' },
];
const SplitOrPackageOptions: Options[] = [
    { id: 'home-system-opt1', value: SplitOrPackage.Split, label: 'Split System' },
    { id: 'home-system-opt2', value: SplitOrPackage.Package, label: 'Packaged Outdoor Unit' },
];
const EfficiencyOptions: Options[] = [
    { id: 'efficiency-opt1', value: FurnaceEfficiency.Percent80, label: '≤ 80% Efficient, metal pipe' },
    { id: 'efficiency-opt2', value: FurnaceEfficiency.Percent90, label: '≥ 90% Efficient, plastic pipe(s)' },
];

function WizardHomeInfo(props: PropsWithChildren<Props> & FormikProps<FormValues>) {
    let { homeSystem, splitOrPackage, numberOfHVAC, typeOfService } = props.values;
    const [efficiencyQuestion, setEfficiencyQuestion] = React.useState(false);
    // const [squareFootageQuestion, setSquareFootageQuestion] = React.useState(false);
    const [refreshButton, setRefreshButton] = React.useState(false);
    const [open, setOpen] = React.useState(false);
    const cssObject: SXCssObject = createCssObject(useTheme());
    const { loading, isValid, submitForm, isDoubleSystem } = props;
    // const { images } = useTheme<ThemeWithImages>();
    const multipleOpenProjects = useSelector(selectMultipleOpenProjects);
    const theme = useTheme();
    const isNoProductPopup = useSelector(selectNoProduct);
    const upLg = useMediaQuery(theme.breakpoints.up('lg'));
    const upSm = useMediaQuery(theme.breakpoints.up('sm'));
    const downXs = useMediaQuery(theme.breakpoints.only('xs'));
    const upMd = useMediaQuery(theme.breakpoints.up('md'));
    const [questionProgress, setQuestionProgress] = React.useState<number>(0);
    const fieldRef = React.useRef<HTMLInputElement>(null);
    const bottomRef = React.useRef<HTMLInputElement>(null);
    const questionsBoxRef = React.useRef<HTMLDivElement>(null);

    const canShowNumberOfQuestions = React.useMemo(() => {
        if (multipleOpenProjects) {
            return (
                (typeOfService === TypeOfService.EquipmentOnly || typeOfService === TypeOfService.FullyInstalled) &&
                !props.isDoubleSystem &&
                !props.addSecondSystemFromCart
            );
        } else return !props.isDoubleSystem && !props.addSecondSystemFromCart;
    }, [typeOfService, props.isDoubleSystem, props.addSecondSystemFromCart, multipleOpenProjects]);

    function clearFormValues() {
        props.values.squareFootage = '';
        props.values.homeSystem = '';
        props.values.splitOrPackage = '';
        props.values.numberOfHVAC = '';
        props.values.splitReplacementType = '';
        props.values.packageReplacementType = '';
        props.values.partialSystemType = '';
        props.values.ductlessFirstSquareFootage = '';
        props.values.ductlessSecondSquareFootage = '';
        props.values.ductlessNumberOfRooms = '';
        props.values.efficiency = '';
        setEfficiencyQuestion(false);
        // setSquareFootageQuestion(false);
        setQuestionProgress(0);
        setRefreshButton(false);
    }
    const clearDuctlessFormValues = () => {
        props.values.ductlessFirstSquareFootage = '';
        props.values.ductlessSecondSquareFootage = '';
        props.values.ductlessNumberOfRooms = '';
    };
    const clearCompleteSystemFormValues = () => {
        props.values.splitOrPackage = '';
        props.values.splitReplacementType = '';
        props.values.packageReplacementType = '';
    };

    React.useEffect(() => {
        let totalprogresslength = isDoubleSystem ? 4 : multipleOpenProjects ? 6 : 5;
        let progress: number = isDoubleSystem ? 4 : multipleOpenProjects ? 6 : 5;
        let cal = 0;
        //setTooltipText(tooltipParagraphText.fullText);
        //Partial HVAC set of questions
        if (homeSystem === HomeSystem.Partial) {
            //setTooltipText(tooltipParagraphText.HVACSystem);
            clearCompleteSystemFormValues();
            clearDuctlessFormValues();
            if (!props.values.partialSystemType) {
                props.values.squareFootage = '';
                props.values.efficiency = '';
            }
            totalprogresslength = isDoubleSystem ? 3 : multipleOpenProjects ? 5 : 4;
        }

        //Complete HVAC set of questions
        else if (homeSystem === HomeSystem.Complete) {
            //setTooltipText(tooltipParagraphText.splitOrPackage);
            clearDuctlessFormValues();
            props.values.partialSystemType = '';
            if (!splitOrPackage) {
                clearCompleteSystemFormValues();
                props.values.squareFootage = '';
            }
            if (splitOrPackage === SplitOrPackage.Split) {
                props.values.packageReplacementType = '';
                if (props.values.splitReplacementType === '') {
                    props.values.squareFootage = '';
                }
            } else if (splitOrPackage === SplitOrPackage.Package) {
                props.values.splitReplacementType = '';
                if (props.values.packageReplacementType === '') {
                    props.values.squareFootage = '';
                    props.values.efficiency = '';
                }
            }
        }
        //Ductless System set of questions
        else if (homeSystem === HomeSystem.Ductless) {
            //setTooltipText(tooltipParagraphText.ductless);
            clearCompleteSystemFormValues();
            props.values.partialSystemType = '';
            props.values.squareFootage = '';
            totalprogresslength = isDoubleSystem ? 3 : multipleOpenProjects ? 5 : 4;
            if (!props.values.ductlessNumberOfRooms) {
                clearDuctlessFormValues();
            }
            if (props.values.ductlessNumberOfRooms === DuctlessNumberOfRooms.Single) {
                props.values.ductlessSecondSquareFootage = '';
            } else if (props.values.ductlessNumberOfRooms === DuctlessNumberOfRooms.Double) {
                totalprogresslength = isDoubleSystem ? 4 : multipleOpenProjects ? 6 : 5;
            } else if (props.values.ductlessNumberOfRooms === DuctlessNumberOfRooms.Multiple) {
                totalprogresslength = isDoubleSystem ? 2 : multipleOpenProjects ? 4 : 3;
            }
        } else if (homeSystem === HomeSystem.IAQ) {
            clearCompleteSystemFormValues();
            clearDuctlessFormValues();
            props.values.partialSystemType = '';
            totalprogresslength = isDoubleSystem ? 1 : multipleOpenProjects ? 3 : 2;
        }

        //question#0
        if (multipleOpenProjects) {
            if ((props.values.typeOfService = '')) {
                clearFormValues();
                totalprogresslength = 6;
            }
            if (typeOfService === TypeOfService.FullyInstalled) {
                props.values.typeOfService = TypeOfService.FullyInstalled;
            }
            if (typeOfService === TypeOfService.EquipmentOnly) {
                props.values.typeOfService = TypeOfService.EquipmentOnly;
            }
            if (!props.values.homeSystem && numberOfHVAC === NumberOfHVAC.Single) {
                clearFormValues();
                props.values.numberOfHVAC = NumberOfHVAC.Single;
            }
            if (!props.values.homeSystem && numberOfHVAC === NumberOfHVAC.Double) {
                clearFormValues();
                props.values.numberOfHVAC = NumberOfHVAC.Double;
            }
            if (!props.values.typeOfService) {
                props.values.numberOfHVAC = NumberOfHVAC.Double;
                if (!isDoubleSystem) {
                    clearFormValues();
                }
            }
            if (numberOfHVAC === NumberOfHVAC.Multiple) {
                clearFormValues();
                props.values.numberOfHVAC = NumberOfHVAC.Multiple;
                totalprogresslength = 1;
            }
        }

        if (!multipleOpenProjects) {
            //question#1
            if (!props.values.numberOfHVAC) {
                clearFormValues();
            }
            if (numberOfHVAC === NumberOfHVAC.Multiple) {
                clearFormValues();
                props.values.numberOfHVAC = NumberOfHVAC.Multiple;
                totalprogresslength = 1;
            }
            //question#2
            if (!props.values.homeSystem && numberOfHVAC === NumberOfHVAC.Single) {
                clearFormValues();
                props.values.numberOfHVAC = NumberOfHVAC.Single;
            }
            if (!props.values.homeSystem && numberOfHVAC === NumberOfHVAC.Double) {
                clearFormValues();
                props.values.numberOfHVAC = NumberOfHVAC.Double;
            }
        }

        //Efficiency Question
        if (
            props.values.splitReplacementType === SplitReplacementType.FurnaceAC ||
            props.values.partialSystemType === PartialSystemType.Furnace
        ) {
            totalprogresslength++;
            setEfficiencyQuestion(true);
        } else {
            props.values.efficiency = '';
            setEfficiencyQuestion(false);
        }

        //Tooltip
        if (
            props.values.packageReplacementType ||
            props.values.partialSystemType ||
            props.values.splitReplacementType ||
            props.values.ductlessNumberOfRooms
        ) {
            //setTooltipText(tooltipParagraphText.squareFootage);
        }
        //Progress calculation
        progress = Object.keys(props.values).length - Object.values(props.values).filter((a) => a === '').length;
        progress = isDoubleSystem ? progress - 1 : progress;
        if (progress >= totalprogresslength) {
            cal =
                props.values.numberOfHVAC === NumberOfHVAC.Double
                    ? props.isDoubleSystem || props.addSecondSystemFromCart
                        ? 100
                        : 50
                    : 100;
        } else {
            cal =
                props.values.numberOfHVAC === NumberOfHVAC.Double
                    ? props.isDoubleSystem
                        ? 50 + ((progress / totalprogresslength) * 100) / 2
                        : ((progress / totalprogresslength) * 100) / 2
                    : (progress / totalprogresslength) *
                    100; /*( if criteria length is grater than 1 then do 50 +) (progress / totalprogresslength) * 100/2 : (progress / totalprogresslength) * 100;*/
        }
        cal = Math.floor(cal);
        setQuestionProgress(cal);
        cal ? setRefreshButton(true) : setRefreshButton(false);

        //Auto-Scroll to Current Question
        if (fieldRef.current) {
            if (downXs || upSm) {
                window.scroll({
                    top: fieldRef.current.offsetTop - 100,
                    behavior: 'smooth',
                });
            }
            questionsBoxRef.current?.scrollTo({
                top: fieldRef.current.offsetTop,
                behavior: 'smooth',
            });
        }
        if (cal === 100 && bottomRef.current) {
            window.scrollTo({
                top: document.body.scrollHeight,
                behavior: 'smooth',
            });
        }
        setOpen(false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.values]);

    React.useEffect(() => {
        if (open) {
            if (upMd || downXs) {
                window.scrollTo({
                    top: document.body.scrollHeight,
                    behavior: 'smooth',
                });
            } else {
                questionsBoxRef.current?.scrollTo({
                    top: questionsBoxRef.current.scrollHeight,
                    behavior: 'smooth',
                });
            }
        }
    }, [downXs, open, upMd]);

    const handleTooltipOpen = () => {
        setOpen(true);
    };

    const handleTooltipClose = () => {
        setOpen(false);
    };

    const tooltipContent = (text?: string) => (
        <ClickAwayListener touchEvent={false} onClickAway={handleTooltipClose}>
            <Grid sx={cssObject.tooltip}>
                <span>
                    <PlayArrowIcon sx={cssObject.tooltipArrow} />
                </span>
                <Grid container>
                    <Grid item xs={12} sm={9}>
                        <Grid sx={cssObject.tolltipHeading}>
                            <TooltipIcon /> &nbsp; HVAC Tips
                        </Grid>
                        <Grid sx={cssObject.tooltipParagraph}>
                            {!!text ? (
                                <div dangerouslySetInnerHTML={{ __html: text }}></div>
                            ) : (
                                'Air and Heat Done Right offers turnkey HVAC replacement solutions for your home, whether your are replacing your entire system, part of your system, or adding a new system to your home.'
                            )}
                        </Grid>
                    </Grid>
                    <Grid sx={cssObject.tooltipButtonContainer} item xs={12} sm={3}>
                        <CustomButton onClick={handleTooltipClose} isContrast isTransparent>
                            GOT IT
                        </CustomButton>
                    </Grid>
                </Grid>
            </Grid>
        </ClickAwayListener>
    );

    if (props.addSecondSystemFromCart) {
        props.values.numberOfHVAC = NumberOfHVAC.Single;
    }

    const NumberOfSystemQuestion = () => {
        return (
            <>
                <WizardSelection
                    id={'question-hvac-system'}
                    name="numberOfHVAC"
                    pic={
                        props.values.numberOfHVAC
                            ? theme.customProperties.commonGreenDark
                            : theme.customProperties.commonOrange
                    }
                    label={'How many separate systems do you need for your HVAC  project?'}
                    isBold={!props.values.numberOfHVAC ? true : false}
                    options={HvacSystemOptions}
                    handleTooltipOpen={handleTooltipOpen}
                />
                {open && !props.values.numberOfHVAC && tooltipContent(tooltipParagraphText.HVACSystem)}
            </>
        );
    };

    const renderEquipmentSelectionDetails = () => (
        <Box>
            <Paragraph>
                Answer a few simple questions about your HVAC home project, and we’ll provide you with HVAC options for
                your home and budget.
            </Paragraph>
            {isHomeVestors() && (
                <Paragraph bold sx={cssObject.mt10}>
                    For Equipment-Only orders, please work with your preferred contractor to confirm the HVAC options
                    provided before completing your order
                </Paragraph>
            )}
        </Box>
    );

    return (
        <Box sx={cssObject.root}>
            {props.children}
            <LoadingOverlay open={loading} />
            <WizardHeader
                showBackButton={true}
                showRestartButton={refreshButton}
                onHelp={() => { }}
                productCriteria={clearFormValues}
                shadow={true}
                isFixed={true}
            />
            <Divider />
            <Box sx={cssObject.main}>
                <ContentContainer disableGrid background={'bg2'}>
                    <Container maxWidth={'lg'} disableGutters>
                        <ContentContainer size={'wizardsmall'} sx={cssObject.wizard}>
                            <Grid container spacing={2}>
                                <Grid item container spacing={2} xs={12} sm={12} md={5} lg={6}>
                                    {upLg ? (
                                        <Grid item xs={12}>
                                            <Box sx={cssObject.fix}>
                                                <HeadlineParagraph
                                                    shouldHideEmptyGridAtBottom={true}
                                                    label={'Select your equipment'}
                                                    headlineSize={'h2'}
                                                ></HeadlineParagraph>
                                                <Grid sx={cssObject.subHeadingPadding}>
                                                    {renderEquipmentSelectionDetails()}
                                                </Grid>
                                                <BorderLinearProgress variant="determinate" value={questionProgress} />
                                                <Paragraph semibold>{questionProgress}% completed</Paragraph>
                                            </Box>
                                        </Grid>
                                    ) : (
                                        <Grid item xs={12}>
                                            <HeadlineParagraph
                                                shouldHideEmptyGridAtBottom={true}
                                                label={'Select your equipment'}
                                                headlineSize={upSm ? 'h2' : 'h3'}
                                            ></HeadlineParagraph>
                                            <Grid sm={12} sx={cssObject.subHeadingPadding}>
                                                {renderEquipmentSelectionDetails()}
                                            </Grid>
                                            <BorderLinearProgress variant="determinate" value={questionProgress} />
                                            <Paragraph sx={{ mb: 1 }} semibold>
                                                {questionProgress}% completed
                                            </Paragraph>
                                        </Grid>
                                    )}
                                </Grid>
                                {isNoProductPopup && (
                                    <NoProductPopup open={isNoProductPopup} isDoubleSystem={props.isDoubleSystem} />
                                )}
                                <Grid item xs={12} container spacing={2} sm={12} md={7} lg={6}>
                                    {props.isDoubleSystem && <FirstSystemInfo />}
                                    <br />
                                    <Grid
                                        sx={[
                                            !props.values.homeSystem && cssObject.scrollable,
                                            props.values.homeSystem && cssObject.scrollableFirst,{
                                                marginTop:{
                                                    sm:'0px'
                                                }
                                            }
                                        ]}
                                        ref={questionsBoxRef}
                                        container
                                        item
                                        spacing={2}
                                    >
                                        {multipleOpenProjects &&
                                            !props.isDoubleSystem &&
                                            !props.addSecondSystemFromCart && (
                                                <Grid item ref={fieldRef} xs={12}>
                                                    {/* Question #0  */}
                                                    <WizardSelection
                                                        id={'question-type-of-service'}
                                                        name="typeOfService"
                                                        pic={
                                                            props.values.typeOfService
                                                                ? theme.customProperties.commonGreenDark
                                                                : theme.customProperties.commonOrange
                                                        }
                                                        label={'What type of service are you looking for?'}
                                                        isBold={!props.values.typeOfService ? true : false}
                                                        options={TypeOfServiceOptions}
                                                        handleTooltipOpen={handleTooltipOpen}
                                                    />
                                                    {open &&
                                                        !props.values.typeOfService &&
                                                        tooltipContent(tooltipParagraphText.TypeOfService)}
                                                </Grid>
                                            )}
                                        {canShowNumberOfQuestions && (
                                            <Grid item ref={fieldRef} xs={12}>
                                                {/* Question #0.1  */}
                                                {NumberOfSystemQuestion()}
                                            </Grid>
                                        )}
                                        {!props.isDoubleSystem &&
                                            (numberOfHVAC === NumberOfHVAC.Single ||
                                                numberOfHVAC === NumberOfHVAC.Double) && (
                                                <Grid item ref={fieldRef} xs={12}>
                                                    {/* Question #2 */}
                                                    <WizardSelection
                                                        id={'question-HomeSystem'}
                                                        name="homeSystem"
                                                        pic={
                                                            props.values.homeSystem
                                                                ? theme.customProperties.commonGreenDark
                                                                : theme.customProperties.commonOrange
                                                        }
                                                        label={'What HVAC products are you upgrading in your home?'}
                                                        isBold={!props.values.homeSystem ? true : false}
                                                        options={
                                                            props.values.typeOfService === TypeOfService.EquipmentOnly
                                                                ? CompleteOrPartialOrDuctlessEquipmentOnlyOptions
                                                                : CompleteOrPartialOrDuctlessOptions
                                                        }
                                                        handleTooltipOpen={handleTooltipOpen}
                                                    />
                                                    {open &&
                                                        !props.values.homeSystem &&
                                                        tooltipContent(tooltipParagraphText.HVACProductTypes)}
                                                </Grid>
                                            )}
                                        {props.isDoubleSystem && (
                                            <Grid item ref={fieldRef} xs={12}>
                                                {/* Question #2 */}
                                                <WizardSelection
                                                    id={'question-HomeSystem'}
                                                    name="homeSystem"
                                                    pic={
                                                        props.values.homeSystem
                                                            ? theme.customProperties.commonGreenDark
                                                            : theme.customProperties.commonOrange
                                                    }
                                                    label={'What HVAC products are you upgrading in your home?'}
                                                    isBold={!props.values.homeSystem ? true : false}
                                                    options={
                                                        props.values.typeOfService === TypeOfService.EquipmentOnly
                                                            ? CompleteOrPartialOrDuctlessEquipmentOnlyOptions
                                                            : CompleteOrPartialOrDuctlessOptions
                                                    }
                                                    handleTooltipOpen={handleTooltipOpen}
                                                />
                                                {open &&
                                                    !props.values.homeSystem &&
                                                    tooltipContent(tooltipParagraphText.HVACProductTypes)}
                                            </Grid>
                                        )}
                                        {props.values.homeSystem === HomeSystem.Complete && (
                                            <Grid item ref={fieldRef} xs={12}>
                                                {/* Question #3A */}
                                                <WizardSelection
                                                    id={'question-home-system'}
                                                    name="splitOrPackage"
                                                    pic={
                                                        props.values.splitOrPackage
                                                            ? theme.customProperties.commonGreenDark
                                                            : theme.customProperties.commonOrange
                                                    }
                                                    isBold={!props.values.splitOrPackage ? true : false}
                                                    label={'Do you need a split system or a packaged unit?'}
                                                    options={SplitOrPackageOptions}
                                                    handleTooltipOpen={handleTooltipOpen}
                                                />
                                                {open &&
                                                    !props.values.splitOrPackage &&
                                                    tooltipContent(tooltipParagraphText.SplitOrPackage)}
                                            </Grid>
                                        )}
                                        {props.values.splitOrPackage === SplitOrPackage.Split &&
                                            props.values.homeSystem === HomeSystem.Complete && (
                                                <Grid item ref={fieldRef} xs={12}>
                                                    {/* Question number 4A */}
                                                    <WizardSelection
                                                        id={'question-split-system'}
                                                        name="splitReplacementType"
                                                        pic={
                                                            props.values.splitReplacementType
                                                                ? theme.customProperties.commonGreenDark
                                                                : theme.customProperties.commonOrange
                                                        }
                                                        label={'What type of replacement system do you need?'}
                                                        isBold={!props.values.splitReplacementType ? true : false}
                                                        options={SplitTypeReplacementOptions}
                                                        handleTooltipOpen={handleTooltipOpen}
                                                    />
                                                    {open &&
                                                        !props.values.splitReplacementType &&
                                                        tooltipContent(tooltipParagraphText.SplitReplacement)}
                                                </Grid>
                                            )}
                                        <br />
                                        {props.values.splitOrPackage === SplitOrPackage.Package &&
                                            props.values.homeSystem === HomeSystem.Complete && (
                                                <Grid item ref={fieldRef} xs={12}>
                                                    {/* Question number 4B */}
                                                    <WizardSelection
                                                        id={'question-package-location'}
                                                        name="packageReplacementType"
                                                        pic={
                                                            props.values.packageReplacementType
                                                                ? theme.customProperties.commonGreenDark
                                                                : theme.customProperties.commonOrange
                                                        }
                                                        label={'What type of replacement do you prefer?'}
                                                        isBold={!props.values.packageReplacementType ? true : false}
                                                        options={PackagedTypeReplacementOptions}
                                                        handleTooltipOpen={handleTooltipOpen}
                                                    />
                                                    {open &&
                                                        !props.values.packageReplacementType &&
                                                        tooltipContent(tooltipParagraphText.PackageReplacement)}
                                                </Grid>
                                            )}
                                        <br />
                                        {props.values.homeSystem === HomeSystem.Partial && (
                                            <Grid item ref={fieldRef} xs={12}>
                                                {/* Question number 3B */}
                                                <WizardSelection
                                                    id={'question-partial-type'}
                                                    name="partialSystemType"
                                                    pic={
                                                        props.values.partialSystemType
                                                            ? theme.customProperties.commonGreenDark
                                                            : theme.customProperties.commonOrange
                                                    }
                                                    label={'What type of replacement do you prefer?'}
                                                    isBold={!props.values.partialSystemType ? true : false}
                                                    options={PartialTypeReplacementOptions}
                                                    handleTooltipOpen={handleTooltipOpen}
                                                />
                                                {open &&
                                                    !props.values.partialSystemType &&
                                                    tooltipContent(tooltipParagraphText.PartialSystem)}
                                            </Grid>
                                        )}
                                        <br />
                                        {efficiencyQuestion && (
                                            <Grid item ref={fieldRef} xs={12}>
                                                {/* Question number 4 */}
                                                <WizardSelection
                                                    id={'question-furnace-efficiency'}
                                                    name="efficiency"
                                                    pic={
                                                        props.values.efficiency
                                                            ? theme.customProperties.commonGreenDark
                                                            : theme.customProperties.commonOrange
                                                    }
                                                    label={'What is the efficiency of your current furnace?'}
                                                    isBold={!props.values.efficiency ? true : false}
                                                    options={EfficiencyOptions}
                                                    handleTooltipOpen={handleTooltipOpen}
                                                />
                                                {open &&
                                                    !props.values.efficiency &&
                                                    tooltipContent(tooltipParagraphText.efficiency)}
                                            </Grid>
                                        )}
                                        <br />
                                        {(props.values.splitReplacementType ||
                                            props.values.packageReplacementType ||
                                            props.values.partialSystemType) &&
                                            (!efficiencyQuestion ||
                                                (efficiencyQuestion && props.values.efficiency)) && (
                                                <Grid item ref={fieldRef} xs={12}>
                                                    {/* Question number 5 */}
                                                    <WizardSelection
                                                        id={'question-square-footage'}
                                                        name="squareFootage"
                                                        pic={
                                                            props.values.squareFootage
                                                                ? theme.customProperties.commonGreenDark
                                                                : theme.customProperties.commonOrange
                                                        }
                                                        label={
                                                            "What's the total square footage of your home, excluding basement?"
                                                        }
                                                        isBold={!props.values.squareFootage ? true : false}
                                                        options={SquareFeetOptions}
                                                        handleTooltipOpen={handleTooltipOpen}
                                                    />
                                                    {open &&
                                                        !props.values.squareFootage &&
                                                        tooltipContent(tooltipParagraphText.squareFootage)}
                                                </Grid>
                                            )}
                                        <br />
                                        {props.values.homeSystem === HomeSystem.Ductless && (
                                            <Grid item ref={fieldRef} xs={12}>
                                                {/* Question number 2A */}
                                                <WizardSelection
                                                    id={'question-ductless-type'}
                                                    name="ductlessNumberOfRooms"
                                                    pic={
                                                        props.values.ductlessNumberOfRooms
                                                            ? theme.customProperties.commonGreenDark
                                                            : theme.customProperties.commonOrange
                                                    }
                                                    label={'How many separate rooms will the system heat and cool? '}
                                                    isBold={!props.values.ductlessNumberOfRooms ? true : false}
                                                    options={DuctlessRoomsOptions}
                                                    handleTooltipOpen={handleTooltipOpen}
                                                />
                                                {open &&
                                                    !props.values.ductlessNumberOfRooms &&
                                                    tooltipContent(tooltipParagraphText.ductless)}
                                            </Grid>
                                        )}
                                        <br />
                                        {props.values.homeSystem === HomeSystem.Ductless &&
                                            props.values.ductlessNumberOfRooms === DuctlessNumberOfRooms.Single && (
                                                <Grid item ref={fieldRef} xs={12}>
                                                    {/* Question number 2Bi */}
                                                    <WizardSelection
                                                        id={'question-ductless-area-type'}
                                                        name="ductlessFirstSquareFootage"
                                                        pic={
                                                            props.values.ductlessFirstSquareFootage
                                                                ? theme.customProperties.commonGreenDark
                                                                : theme.customProperties.commonOrange
                                                        }
                                                        label={'How big is the room?'}
                                                        isBold={!props.values.ductlessFirstSquareFootage ? true : false}
                                                        options={DuctlessFirstSquareFootageOptions}
                                                        handleTooltipOpen={handleTooltipOpen}
                                                    />
                                                    {open &&
                                                        !props.values.ductlessFirstSquareFootage &&
                                                        tooltipContent(tooltipParagraphText.ductlessOneRoom)}
                                                </Grid>
                                            )}

                                        {props.values.homeSystem === HomeSystem.Ductless &&
                                            props.values.ductlessNumberOfRooms === DuctlessNumberOfRooms.Double && (
                                                <Grid item ref={fieldRef} xs={12}>
                                                    <Grid
                                                        container
                                                        sx={[
                                                            cssObject.wizardQuestionCard,
                                                            !props.values.ductlessSecondSquareFootage &&
                                                            cssObject.boxShadow,
                                                        ]}
                                                    >
                                                        {/* Question number 2Bii */}
                                                        <Grid item container>
                                                            <Grid item xs={1}>
                                                                <Icon
                                                                    style={{
                                                                        color:
                                                                            props.values.ductlessFirstSquareFootage &&
                                                                                props.values.ductlessSecondSquareFootage
                                                                                ? theme.customProperties.commonGreenDark
                                                                                : theme.customProperties.commonOrange,
                                                                        fontSize: '14px',
                                                                        padding: '1.5px 0 0 0',
                                                                        marginLeft: '15px',
                                                                    }}
                                                                >
                                                                    radio_button_unchecked
                                                                </Icon>
                                                            </Grid>
                                                            <Grid item xs={10}>
                                                                <Paragraph
                                                                    size="normal"
                                                                    semibold={
                                                                        props.values.ductlessFirstSquareFootage &&
                                                                            props.values.ductlessSecondSquareFootage
                                                                            ? false
                                                                            : true
                                                                    }
                                                                >
                                                                    How big is each room?
                                                                </Paragraph>
                                                            </Grid>
                                                            <Grid item xs={1}>
                                                                {!props.values.ductlessFirstSquareFootage && (
                                                                    <Button
                                                                        onClick={handleTooltipOpen}
                                                                        sx={[cssObject.tooltipIconButton, { padding: '0px' }]}
                                                                    >
                                                                        <InfoIcon />
                                                                    </Button>
                                                                )}
                                                            </Grid>
                                                        </Grid>
                                                        <WizardSelection
                                                            id={'question-ductless-area-type'}
                                                            name="ductlessFirstSquareFootage"
                                                            label={'Room 1'}
                                                            options={DuctlessFirstSquareFootageOptions}
                                                            isBold={false}
                                                            handleTooltipOpen={handleTooltipOpen}
                                                        />
                                                        <WizardSelection
                                                            id={'question-ductless-area-type'}
                                                            name="ductlessSecondSquareFootage"
                                                            label={'Room 2'}
                                                            isBold={false}
                                                            options={DuctlessSecondSquareFootageOptions}
                                                            handleTooltipOpen={handleTooltipOpen}
                                                        />
                                                    </Grid>
                                                    {open &&
                                                        !props.values.ductlessFirstSquareFootage &&
                                                        !props.values.ductlessSecondSquareFootage &&
                                                        tooltipContent(tooltipParagraphText.ductlessTwoRoom)}
                                                </Grid>
                                            )}
                                    </Grid>
                                    {/* </GridList> */}
                                </Grid>
                            </Grid>
                        </ContentContainer>
                    </Container>
                </ContentContainer>
            </Box>
            <br />
            {!props.isDoubleSystem && <WizardNotification isFixed={true} />}
            <br />
            <Box sx={[cssObject.actions, { display: 'flex', alignItems: 'center' }]}>
                <Container disableGutters maxWidth="xl">
                    <ContentContainer>
                        <Grid container>
                            <Grid container sx={cssObject.helpFooter} alignItems={'center'} item xs={12} sm={8} md={9}>
                                {typeOfService !== TypeOfService.EquipmentOnly && (
                                    <>
                                        <Grid item display={'flex'}>
                                            <Shield />
                                        </Grid>
                                        <Grid item xs={10}>
                                            <Paragraph align="left">
                                                Did you know your new installed HVAC system will be protected by our
                                                1-Year Full Labor Warranty!
                                            </Paragraph>
                                        </Grid>
                                    </>
                                )}
                            </Grid>
                            <Grid ref={bottomRef} item xs={12} sm={3} md={3}>
                                <WizardCriteriaFooterNew nextLabel="Continue" onNext={submitForm} disabled={!isValid} />
                            </Grid>
                        </Grid>
                    </ContentContainer>
                </Container>
            </Box>
        </Box>
    );
}

const formikComponent = withFormik<Props, FormValues>({
    enableReinitialize: true,
    mapPropsToValues: ({ criteria }) => ({
        typeOfService: criteria.typeOfService || '',
        numberOfHVAC: criteria.numberOfHVAC || '',
        squareFootage: criteria.squareFootage || '',
        homeSystem: criteria.homeSystem || '',
        splitOrPackage: criteria.splitOrPackage || '',
        splitReplacementType: criteria.splitReplacementType || '',
        packageReplacementType: criteria.packageReplacementType || '',
        partialSystemType: criteria.partialSystemType || '',
        ductlessNumberOfRooms: criteria.ductlessNumberOfRooms || '',
        ductlessFirstSquareFootage: criteria.ductlessFirstSquareFootage || '',
        ductlessSecondSquareFootage: criteria.ductlessSecondSquareFootage || '',
        efficiency: criteria.efficiency || '',
    }),
    handleSubmit: (values, { props }) => {
        props.onNext({
            typeOfService: values.typeOfService as TypeOfService,
            numberOfHVAC: values.numberOfHVAC as NumberOfHVAC,
            squareFootage: values.squareFootage as SquareFootage,
            homeSystem: values.homeSystem as HomeSystem,
            splitOrPackage: values.splitOrPackage as SplitOrPackage,
            splitReplacementType: values.splitReplacementType as SplitReplacementType,
            packageReplacementType: values.packageReplacementType as PackageReplacementType,
            partialSystemType: values.partialSystemType as PartialSystemType,
            ductlessNumberOfRooms: values.ductlessNumberOfRooms as DuctlessNumberOfRooms,
            ductlessFirstSquareFootage: values.ductlessFirstSquareFootage as DuctlessFirstSquareFootage,
            ductlessSecondSquareFootage: values.ductlessSecondSquareFootage as DuctlessSecondSquareFootage,
            efficiency: values.efficiency as FurnaceEfficiency,
        });
    },
    validateOnMount: true,
    validationSchema: schemaAboutYouHome,
})(WizardHomeInfo);

export default formikComponent;
