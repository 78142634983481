export interface Financial {
    brainTree?: BrainTree;
}

export interface BrainTree {
    clientToken?: string;
}

export enum PaymentTypes {
    PAYPAL = 'PAYPAL',
    CREDIT_CARD = 'CREDIT_CARD',
}
export enum PaymentConstants {
    PAYPAL_INTENT = 'authorize',
    PAYPAL_CODE = 'braintree_paypal',
    PAYPAL_FLOW = 'checkout',
    BRAINTREE = 'braintree',
    FINANCING = 'checkmo',
    PARTIAL_PAYMENT = 'motili_partial_payment',
    OFFLINE_PAYMENT = 'motili_offline_payment'
}
