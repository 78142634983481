import React from 'react';
import { useField } from 'formik';
import { DateFieldSchedulePage } from './DateFieldSchedulePage';

interface Props {
    id?: string;
    fullWidth?: boolean;
    name: string;
    label?: string;
    type?: string;
    placeholder?: string;
    maxDate?: Date;
    minDate?: Date;
}

export default function FormikDateField(props: Props) {
    const { id, fullWidth = true, name, label, ...other } = props;
    const [field, meta, { setValue }] = useField(props);
    return (
        <DateFieldSchedulePage
            id={id ? id : `${name}-input`}
            label={label}
            fullWidth={fullWidth}
            placeholder={'MM/DD/YYYY'}
            disablePast
            format="MM/dd/yyyy"
            error={meta.touched && Boolean(meta.error) && meta.value}
            helperText={meta.touched || meta.value ? meta.error : ''}
            {...other}
            {...field}
            // enableErrorDisplay={(meta.value)}
            enableErrorDisplay={false}
            onChange={(v) => setValue(v || null)}
        />
    );
}
