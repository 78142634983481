import * as React from 'react';
import { useTheme } from '@mui/material/styles';
import { getThemePrimaryLight, getThemePrimaryMain } from 'components/common/Utilities';

export default function CallUs(props: any) {
    const theme = useTheme();
    const themePrimaryMain = getThemePrimaryMain(theme);
    const themePrimaryLight = getThemePrimaryLight(theme);
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={72} height={72} {...props}>
            <defs>
                <style>
                    {'.d-call{fill:none;stroke:#84b048;stroke-linecap:round;stroke-linejoin:round;stroke-width:3px}'}
                </style>
            </defs>
            <g transform="translate(-930.379 -360.006)">
                <path
                    fill={`${themePrimaryLight}`}
                    d="M952.558 389.545s-6.149 1.411 0 11.374 7.751 14.969 10.472 15.53a17.2 17.2 0 0 0 5.735 0l-5.593-10.361"
                />
                <rect
                    fill={`${themePrimaryLight}`}
                    width={10.11}
                    height={11.995}
                    rx={5.055}
                    transform="rotate(-28.683 1222.387 -1671.433)"
                />
                <rect
                    fill={`${themePrimaryLight}`}
                    width={10.11}
                    height={11.995}
                    rx={5.055}
                    transform="rotate(-28.683 1279.622 -1686.064)"
                />
                <path
                    fill={`${themePrimaryMain}`}
                    d="M957 390.813a6.561 6.561 0 0 1-5.762-3.408l-.9-1.653a6.555 6.555 0 0 1 11.5-6.292l.9 1.654a6.551 6.551 0 0 1-5.74 9.7Zm-.92-11.764a3.557 3.557 0 0 0-3.114 5.263l.905 1.654a3.554 3.554 0 1 0 6.236-3.413l-.9-1.654a3.529 3.529 0 0 0-2.119-1.7 3.569 3.569 0 0 0-1.005-.15ZM971.05 416.488a6.56 6.56 0 0 1-5.762-3.408l-.9-1.652a6.555 6.555 0 1 1 11.5-6.293l.9 1.652a6.552 6.552 0 0 1-5.739 9.7Zm-.922-11.761a3.553 3.553 0 0 0-3.112 5.26l.9 1.653a3.555 3.555 0 1 0 6.237-3.412l-.9-1.653a3.559 3.559 0 0 0-3.125-1.848Z"
                />
                <path
                    fill={`${themePrimaryMain}`}
                    d="M966.255 418.53a10.743 10.743 0 0 1-9.437-5.581l-10.939-19.993a10.748 10.748 0 0 1 4.266-14.571l2.8-1.53a1.5 1.5 0 1 1 1.44 2.632l-2.8 1.53a7.746 7.746 0 0 0-3.073 10.5l10.938 19.993a7.743 7.743 0 0 0 10.5 3.073l2.8-1.529a1.5 1.5 0 0 1 1.44 2.631l-2.8 1.53a10.673 10.673 0 0 1-5.135 1.315Z"
                />
                <path
                    fill={`${themePrimaryMain}`}
                    d="M966.787 414.192a1.5 1.5 0 0 1-1.317-.78L951.241 387.4a1.5 1.5 0 1 1 2.633-1.439l14.226 26.012a1.5 1.5 0 0 1-1.316 2.219Z"
                />
                <path
                    fill={`${themePrimaryLight}`}
                    d="M977.567 396.693a1.5 1.5 0 0 1-.72-2.815 2.948 2.948 0 0 0-2.829-5.172 1.5 1.5 0 1 1-1.44-2.631 5.947 5.947 0 1 1 5.708 10.434 1.477 1.477 0 0 1-.719.184Z"
                />
                <path
                    fill={`${themePrimaryLight}`}
                    d="M980.4 403.888a1.5 1.5 0 0 1-.72-2.816 10.617 10.617 0 1 0-10.192-18.627 1.5 1.5 0 1 1-1.439-2.632 13.616 13.616 0 1 1 13.07 23.89 1.489 1.489 0 0 1-.719.185Z"
                />
                <path
                    fill={`${themePrimaryMain}`}
                    d="M976.362 395.225a1.5 1.5 0 0 1-.72-2.817 2.947 2.947 0 1 0-2.83-5.17 1.5 1.5 0 1 1-1.439-2.633 5.947 5.947 0 1 1 5.708 10.436 1.494 1.494 0 0 1-.719.184Z"
                />
                <path
                    fill={`${themePrimaryMain}`}
                    d="M979.191 402.419a1.5 1.5 0 0 1-.72-2.816 10.617 10.617 0 1 0-10.192-18.627 1.5 1.5 0 1 1-1.439-2.632 13.616 13.616 0 1 1 13.07 23.891 1.488 1.488 0 0 1-.719.184Z"
                />
            </g>
        </svg>
    );
}
