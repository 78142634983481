import { useTheme } from '@mui/material/styles';
import { getThemePrimaryLight, getThemePrimaryMain } from 'components/common/Utilities';
import * as React from 'react';

function MailIcon(props: any) {
    const theme = useTheme();
    const themePrimaryMain = getThemePrimaryMain(theme);
    const themePrimaryLight = getThemePrimaryLight(theme);
    return (
        <svg id="Mail" xmlns="http://www.w3.org/2000/svg" width="27" height="27" viewBox="0 0 27 27">
            <circle id="Ellipse_18" data-name="Ellipse 18" cx="13.5" cy="13.5" r="13.5" fill={`${themePrimaryLight}`} />
            <g id="Group_3021" data-name="Group 3021" transform="translate(-692.374 -420.507)">
                <g
                    id="Rectangle_679"
                    data-name="Rectangle 679"
                    transform="translate(698.374 428.507)"
                    fill="none"
                    stroke={`${themePrimaryMain}`}
                    strokeWidth="1.5"
                >
                    <rect width="15" height="11" rx="1" stroke="none" />
                    <rect x="0.75" y="0.75" width="13.5" height="9.5" rx="0.25" fill="none" />
                </g>
                <path
                    id="Path_55"
                    data-name="Path 55"
                    d="M699.827,429.809l5.857,4.712,6.183-4.278"
                    transform="translate(0.183 0.139)"
                    fill="none"
                    stroke={`${themePrimaryMain}`}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="1.5"
                />
            </g>
        </svg>
    );
}

export default MailIcon;
