import IconDeleteNew from 'components/icons/IconDeleteNew';
import { EditIconNew } from 'components/icons/EditIconNew';
export enum PropertyMenusLabel {
    EDIT = 'Edit',
    DELETE = 'Delete',
}
export interface PropertyMenu {
    label: string;
    icon: (props: any) => JSX.Element;
}
export const PropertyMenus = [
    {
        label: PropertyMenusLabel.EDIT,
        icon: EditIconNew,
    },
    {
        label: PropertyMenusLabel.DELETE,
        icon: IconDeleteNew,
    },
];
