import * as React from 'react';
import { useTheme } from '@mui/material/styles';
import { getThemePrimaryMain } from 'components/common/Utilities';

export default function DoneIcon(props: any) {
    const theme = useTheme();
    const themePrimaryMain = getThemePrimaryMain(theme);
    return (
        <svg data-name="Component 23 \u2013 1" xmlns="http://www.w3.org/2000/svg" width={64} height={64} {...props}>
            <rect data-name="Rectangle 411" width={64} height={64} rx={4} fill={`${themePrimaryMain}`} />
            <g data-name="Group 235">
                <path
                    data-name="Path 46"
                    d="M33.834 27.299l1.085-5.214a1.786 1.786 0 00-.841-2.02 1.71 1.71 0 00-2.146.37q-3.186 3.169-6.357 6.354a2.332 2.332 0 00-.72 1.716c0 3.873-.006 7.746.006 11.619a2.292 2.292 0 002.386 2.38q5.206.011 10.413 0a2.189 2.189 0 002.145-1.343c1.33-3.038 2.668-6.074 3.911-9.147a3.44 3.44 0 00-2.781-4.655 7 7 0 00-.982-.058c-2.008-.005-4.015-.002-6.119-.002zm-11.348 8.254V31.01a3.174 3.174 0 00-.1-.863 2.317 2.317 0 00-4.543.636c-.015 3.138-.007 6.276 0 9.415a2.274 2.274 0 00.073.594 2.352 2.352 0 002.49 1.709 2.312 2.312 0 002.073-2.24c.026-1.57.007-3.139.007-4.708z"
                    fill="#fff"
                />
            </g>
        </svg>
    );
}
