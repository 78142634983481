import { CartItem, CartStatus, ItemType, SystemGroup } from 'models/cart';
import { OrderStatus, OrderType } from 'models/order';
import { RelatedProduct } from 'models/product';
import { createSelector } from 'reselect';
import { RootState } from '../reducers';
import { selectOrder } from './order';
import { selectMultipleOpenProjects } from './storeSelector';

export const selectCart = (state: RootState) => state.cart;

export const selectCartProcessing = createSelector(selectCart, (cart) => cart.loading || false);
export const selectNonCompatibleAddOns = createSelector(selectCart, (cart) => {
    let combinedRelatedProducts: RelatedProduct[] = [];
    let nonCompatibleAddOns: CartItem[] = [];
    if (cart && cart.items && cart.items.length > 0) {
        const mainSystemsInCart = cart!.items!.filter((cartItem) => cartItem.itemType === ItemType.MainSystem);
        const addOnsInCart: CartItem[] = cart!.items!.filter((cartItem) => cartItem.itemType === ItemType.AddOn);
        mainSystemsInCart.forEach((mainProduct) => {
            if (mainProduct.relatedProducts) {
                combinedRelatedProducts.push(...mainProduct.relatedProducts);
            }
        });
        nonCompatibleAddOns = addOnsInCart.filter(
            (addOn) => !combinedRelatedProducts.find((relatedProduct) => relatedProduct.sku === addOn.sku)
        );
    }
    return nonCompatibleAddOns;
});
export const selectItems = createSelector(selectCart, (cart) => cart.items);
export const selectFirstSystemMain = createSelector(
    selectItems,
    (items) =>
        items &&
        items.find((item) => item.systemGroup === SystemGroup.SystemGroup1 && item.itemType === ItemType.MainSystem)
);
export const selectSecondSystemMain = createSelector(
    selectItems,
    (items) =>
        items &&
        items.find((item) => item.systemGroup === SystemGroup.SystemGroup2 && item.itemType === ItemType.MainSystem)
);
export const selectFirstSystemIAQ = createSelector(
    selectItems,
    (items) =>
        items &&
        items.find((item) => item.systemGroup === SystemGroup.SystemGroup1 && item.itemType === ItemType.CrossSell)
);
export const selectSecondSystemIAQ = createSelector(
    selectItems,
    (items) =>
        items &&
        items.find((item) => item.systemGroup === SystemGroup.SystemGroup2 && item.itemType === ItemType.CrossSell)
);
export const selectAddons = createSelector(
    selectItems,
    (items) => items && items && items.filter((cartItem) => cartItem.itemType === ItemType.AddOn || !cartItem.itemType)
);
export const selectCartItems = createSelector(
    selectFirstSystemMain,
    selectSecondSystemMain,
    selectFirstSystemIAQ,
    selectSecondSystemIAQ,
    selectAddons,
    (firstSystemMain, secondSystemMain, firstSystemIAQ, secondSystemIAQ, addOns) => {
        return {
            firstSystemMain: firstSystemMain,
            secondSystemMain: secondSystemMain,
            firstSystemIAQ: firstSystemIAQ,
            secondSystemIAQ: secondSystemIAQ,
            addOnsList: addOns,
        };
    }
);
export const selectCartStatus = createSelector(selectCart, (cart) => cart.status);
export const selectHomeAddress = createSelector(selectCart, (cart) => cart.shippingAddress);
export const selectBillingAddress = createSelector(selectCart, (cart) => cart.billingAddress);
export const selectCartPrice = createSelector(selectCart, (cart) => cart.prices);
export const selectContactInformation = createSelector(selectCart, (cart) => cart.contactInformation);

export const selectProceedToCart = createSelector(
    selectCart,
    selectOrder,
    selectMultipleOpenProjects,
    (cart, order, multipleOpenProjects) => {
        if (
            (!(cart.status && cart.status !== CartStatus.READY_TO_SCHEDULE) &&
                //true when order status is not there OR order is different from partial payment
                !(order.status && order.status === OrderStatus.PENDING_PAYMENT)) ||
            //always proceed to cart in all cases when this flag is true
            multipleOpenProjects
        ) {
            return true;
        } else {
            return false;
        }
    }
);
export const selectCartInstructions = createSelector(selectCart, (cart) => cart.instructions);

export const isCartForEquipmentOnly = createSelector(
    selectCart,
    (cart) => cart?.contactInformation?.orderType === OrderType.EquipmentOnly
);
