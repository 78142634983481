import React from 'react';
import { Theme } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import { Button } from 'components/Button';
import ContentContainer from 'components/ContentContainer';
import { Paragraph } from '../Paragraph';
import Container from '@mui/material/Container';
import chicagoland from '../../assets/images/airandheatdoneright/aahdr_chicagoland.webp';
import missouri from '../../assets/images/airandheatdoneright/aahdr_missouri.webp';
import washington from '../../assets/images/airandheatdoneright/aahdr_washington.webp';
import CardMedia from '@mui/material/CardMedia';
import { Box, Typography, useMediaQuery, useTheme } from '@mui/material';
import { SXCssObject } from 'models/ThemeImages';

const createCssObject = (theme: Theme): SXCssObject => ({
    content: {
        '& .MuiGrid-item': {
            paddingBottom: theme.spacing(2),
        },
        '& .MuiButton-contained': {
            width: '150px',
        },
    },
    tileContent: {
        backgroundColor: theme.palette.common.white,
        borderRadius: {
            xs: '18px',
            sm: '8px',
        },
        padding: {
            xs: theme.spacing(3),
            sm: theme.spacing(2),
        },
        marginBottom: {
            xs: theme.spacing(-2.1),
            sm: theme.spacing(-3),
        },
        maxHeight: {
            xs: '271px',
            sm: 'inherit',
        },
        maxWidth: {
            xs: '288px',
        },
        lineHeight: {
            xs: '0px',
            sm: 'inherit',
        },
        '&:hover': {
            boxShadow: '0px 8px 52px #0000000F',
        },
    },
    imageContainer: {
        maxHeight: '100%',
        width: '100%',
    },
    image: {
        width: '100%',
        display: 'block',
        margin: '0 auto',
        overflow: 'hidden',
        maxHeight: {
            xs: '136px',
            sm: '100%',
        },
        maxWidth: {
            xs: '168px',
            sm: '100%',
        },
    },
    heading: {
        px: theme.spacing(1.5),
        paddingBottom: {
            xs: theme.spacing(5),
            sm: theme.spacing(9),
        },
    },
    navigateSite: {
        paddingTop: {
            xs: '10px',
            md: '20px',
        },
    },
    headingStyle: {
        px: theme.spacing(1.5),
        paddingBottom: {
            xs: theme.spacing(3),
            sm: theme.spacing(5),
        },
    },
    centerContent: {
        display: 'flex',
        justifyContent: 'center',
    },
});
interface Props {
    scrollTo?: boolean;
}
function NavigateSite(props: Props) {
    const cssObject: SXCssObject = createCssObject(useTheme());
    const theme = useTheme();
    const navigateSiteRef = React.useRef<HTMLInputElement>(null);
    const { scrollTo } = props;
    const isXs = useMediaQuery(theme.breakpoints.only('xs'));
    const isSm = useMediaQuery(theme.breakpoints.down('md'));
    React.useEffect(() => {
        if (scrollTo && navigateSiteRef.current) {
            isXs
                ? window.scrollTo({
                      top: 550,
                      behavior: 'smooth',
                  })
                : navigateSiteRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    }, [isXs, scrollTo]);

    return (
        <div ref={navigateSiteRef}>
            <ContentContainer disableGrid size={'medium'} sx={cssObject.navigateSite}>
                <Container maxWidth={'lg'} disableGutters>
                    <Box sx={cssObject.headingStyle}>
                        <Typography
                            variant={isXs ? 'h4' : isSm ? 'h3' : 'h2'}
                            sx={cssObject.centerContent}
                            align="center"
                        >
                            Select a location below to get {!isXs && <br />} started and save today!
                        </Typography>
                    </Box>
                    <Grid container alignItems={'flex-start'} justifyContent={'center'} spacing={isXs ? 0 : 4}>
                        <Grid
                            item
                            xs={12}
                            sm={4}
                            md={3}
                            container
                            direction={'column'}
                            justifyContent={'center'}
                            alignItems={'center'}
                            spacing={isXs ? 0 : 2}
                            sx={cssObject.content}
                        >
                            <Grid sx={cssObject.tileContent}>
                                <Grid item xs={12} sx={cssObject.imageContainer}>
                                    <CardMedia component={'img'} image={chicagoland} sx={cssObject.image} />
                                </Grid>
                                <Grid item>
                                    <Typography variant={isSm ? 'h5' : 'h4'} align={'center'}>
                                        Chicagoland
                                    </Typography>
                                    <br />
                                    <Paragraph size={'normal'} color={'primary'} align={'center'}>
                                        <hr
                                            style={{
                                                display: 'inline-block',
                                                width: '30%',
                                                verticalAlign: 'text-top',
                                            }}
                                        />
                                        {' IL  '}
                                        <hr
                                            style={{
                                                display: 'inline-block',
                                                width: '30%',
                                                verticalAlign: 'text-top',
                                            }}
                                        />
                                    </Paragraph>
                                </Grid>
                            </Grid>
                            <Grid item>
                                <Button
                                    id={'navigate-chicago'}
                                    color={'primary'}
                                    onClick={() => {
                                        window.location.href =
                                            `http://chicagoland.${window.location.host}`.toLowerCase();
                                    }}
                                >
                                    Select
                                </Button>
                            </Grid>
                        </Grid>
                        <Grid
                            item
                            xs={12}
                            sm={4}
                            md={3}
                            container
                            direction={'column'}
                            justifyContent={'center'}
                            alignItems={'center'}
                            spacing={isXs ? 0 : 2}
                            sx={cssObject.content}
                        >
                            <Grid sx={cssObject.tileContent}>
                                <Grid item sx={cssObject.imageContainer}>
                                    <CardMedia component={'img'} image={missouri} sx={cssObject.image} />
                                </Grid>
                                <Grid item>
                                    <Typography variant={isSm ? 'h5' : 'h4'} align={'center'}>
                                        Missouri
                                    </Typography>
                                    <br />
                                    <Paragraph size={'normal'} color={'primary'} align={'center'}>
                                        <hr
                                            style={{
                                                display: 'inline-block',
                                                width: '20%',
                                                verticalAlign: 'text-top',
                                            }}
                                        />
                                        {' MO, KS '}
                                        <hr
                                            style={{
                                                display: 'inline-block',
                                                width: '20%',
                                                verticalAlign: 'text-top',
                                            }}
                                        />
                                    </Paragraph>
                                </Grid>
                            </Grid>
                            <Grid item>
                                <Button
                                    id={'navigate-missouri'}
                                    color={'primary'}
                                    onClick={() => {
                                        window.location.href = `http://missouri.${window.location.host}`.toLowerCase();
                                    }}
                                >
                                    Select
                                </Button>
                            </Grid>
                        </Grid>
                        <Grid
                            item
                            xs={12}
                            sm={4}
                            md={3}
                            container
                            direction={'column'}
                            justifyContent={'center'}
                            alignItems={'center'}
                            spacing={isXs ? 0 : 2}
                            sx={cssObject.content}
                        >
                            <Grid sx={cssObject.tileContent}>
                                <Grid item sx={cssObject.imageContainer}>
                                    <CardMedia component={'img'} image={washington} sx={cssObject.image} />
                                </Grid>
                                <Grid item style={{ whiteSpace: 'nowrap' }}>
                                    <Typography variant={isSm ? 'h5' : 'h4'} align={'center'}>
                                        Washington DC
                                    </Typography>
                                    <br />
                                    <Paragraph size={'normal'} color={'primary'} align={'center'}>
                                        <hr
                                            style={{
                                                display: 'inline-block',
                                                width: '10%',
                                                verticalAlign: 'text-top',
                                            }}
                                        />
                                        {' VA, MD, DE '}
                                        <hr
                                            style={{
                                                display: 'inline-block',
                                                width: '10%',
                                                verticalAlign: 'text-top',
                                            }}
                                        />
                                    </Paragraph>
                                </Grid>
                            </Grid>
                            <Grid item>
                                <Button
                                    id={'navigate-washington'}
                                    color={'primary'}
                                    onClick={() => {
                                        window.location.href = `http://greaterdc.${window.location.host}`.toLowerCase();
                                    }}
                                >
                                    Select
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </Container>
            </ContentContainer>
        </div>
    );
}

export default NavigateSite;
