import { Action } from 'ts-action';
import { ofType } from 'ts-action-operators';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { productReview, productReviewSuccess, productReviewError } from 'store/actions/productReviewActions';
import { getProductReview } from 'store/services/productSevices';
import { AxiosResponse } from 'axios';
import { ProductReviews } from 'models/productReviews';

export const getReviewRatings$ = (action$: Observable<Action>) =>
    action$.pipe(
        ofType(productReview),
        switchMap(() =>
            getProductReview().pipe(
                map((res: AxiosResponse<ProductReviews[]>) => productReviewSuccess(res.data)),
                catchError((error) => {
                    return of(productReviewError(error));
                })
            )
        )
    );
