import * as React from "react"
import { SVGProps } from "react"

const DownPaymentIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    data-name="Component 33 \u2013 1"
    xmlns="http://www.w3.org/2000/svg"
    width={18.763}
    height={18.754}
    {...props}
  >
    <path
      data-name="Path 371"
      d="M4.53 5.167V.731a.654.654 0 0 1 .85-.71c2.008.4 4.014.805 6.024 1.194a1.756 1.756 0 0 0 .653-.027c1.949-.382 3.9-.775 5.845-1.164a.654.654 0 0 1 .86.716v15.99a1.938 1.938 0 0 1-2.025 2.021H3.314a3.225 3.225 0 0 1-3.313-3.329V7.174a1.936 1.936 0 0 1 2-2.006h2.284Zm12.939-3.724c-.071.008-.124.01-.175.02l-5.29 1.055a1.4 1.4 0 0 1-.517.035c-1.422-.274-2.84-.563-4.26-.846l-1.4-.278v12.09a.658.658 0 0 1-.407.659.65.65 0 0 1-.886-.654V6.469c-.064 0-.1-.007-.143-.007H2.046a.654.654 0 0 0-.745.744v8.187a1.943 1.943 0 0 0 2.063 2.065h13.322c.555 0 .789-.232.789-.784V2.766Z"
      fill="#1dbaaf"
    />
    <path
      data-name="Path 372"
      d="M9.057 4.52h.618a.646.646 0 1 1 .009 1.292c-.377.005-.755 0-1.132 0-.237 0-.382.125-.385.321s.144.32.38.325a5.368 5.368 0 0 1 .665.02 1.614 1.614 0 0 1 .047 3.188l-.2.038v.6a.647.647 0 1 1-1.292 0V9.71l-.138-.034a.646.646 0 0 1 .146-1.277c.357-.006.714 0 1.071 0q.46 0 .457-.328c0-.216-.152-.314-.467-.32a4.194 4.194 0 0 1-.644-.033 1.609 1.609 0 0 1-.571-2.94.272.272 0 0 0 .148-.289 6.535 6.535 0 0 1 0-.626.645.645 0 0 1 1.289-.013c.007.215-.001.43-.001.67Z"
      fill="#1dbaaf"
    />
    <path
      data-name="Path 373"
      d="M13.595 14.871h1.859a.65.65 0 1 1-.006 1.293h-3.739a.65.65 0 1 1 .006-1.294l1.88.001Z"
      fill="#1dbaaf"
    />
    <path
      data-name="Path 374"
      d="M14.242 13.577h-1.253a.647.647 0 1 1-.006-1.292h2.506a.647.647 0 1 1 .006 1.292c-.418.005-.835 0-1.253 0Z"
      fill="#1dbaaf"
    />
  </svg>
)

export default DownPaymentIcon
