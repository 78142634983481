import { OrderStatus } from 'models/order';
import React from 'react';

export function getOrderStatus(orderStatus: OrderStatus) {
    if (orderStatus === OrderStatus.COMPLETE) {
        return 'Complete';
    } else if (orderStatus === OrderStatus.PENDING_PAYMENT || orderStatus === OrderStatus.PROCESSING) {
        return 'Processing';
    } else if (orderStatus === OrderStatus.PENDING_INSPECTION) {
        return 'Pending Inspection';
    } else if (orderStatus === OrderStatus.READY_FOR_PAYMENT) {
        return 'Ready For Payment';
    } else if (orderStatus === OrderStatus.PENDING) {
        return 'Pending';
    } else if (orderStatus === OrderStatus.CANCELLED) {
        return 'Cancelled';
    } else return orderStatus;
}

export function getOrderStatusDescription(ordeStatus: OrderStatus) {
    if (ordeStatus === OrderStatus.COMPLETE) {
        return 'Successfully Installed';
    } else if (ordeStatus === OrderStatus.PROCESSING) {
        return <span>Complete amount collected</span>;
    } else if (ordeStatus === OrderStatus.PENDING) {
        return;
    } else if (ordeStatus === OrderStatus.PENDING_PAYMENT) {
        return (
            <span>
                Downpayment Collected
                <br />
                Pending Amount is still remaining
            </span>
        );
    }
}
export function showPayNow(orderStatus: OrderStatus) {
    return orderStatus === OrderStatus.PENDING_PAYMENT || orderStatus === OrderStatus.READY_FOR_PAYMENT;
}
export function orderProcessing(orderStatus: OrderStatus) {
    return orderStatus === OrderStatus.PROCESSING || orderStatus === OrderStatus.PENDING;
}
