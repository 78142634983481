import React, { useRef } from 'react';
import { Theme, useTheme } from '@mui/material/styles';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import CalendarTodayOutlinedIcon from '@mui/icons-material/CalendarTodayOutlined';

import { Box, FormControl, FormHelperText, TextField } from '@mui/material';
import { SXCssObject } from 'models/ThemeImages';
const createCssObject = (theme: Theme): SXCssObject => ({
    root: {},
    datePicker: {
        '& .MuiTextField-root': {
            margin: '0px ',
            height: '100%',
            fontSize: '1.25rem',
            '& .MuiOutlinedInput-inputMarginDense': {
                padding: '10px 10px !important',
                fontSize: '1rem',
                fontWeight: theme.typography.fontWeightMedium,
            },
        },
        '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
            border: `1px solid ${theme.palette.action.hover}`,
        },
        '& .MuiTextField-root .MuiInputBase-root': {
            height: '100%',
            borderRadius: '0',
            paddingRight: '0px',
            fontSize: '0.875rem',
            width: '100%',
            margin: '0px !important',
        },
    },
    helperText: {
        color: '#f44336',
    },
    popOverPaperStyle: {
        borderRadius: '8px',
        minWidth: '280px !important',
    },
    calendarBg: {
        background: theme.palette.common.white,
        '& .MuiTypography-caption': {
            color: theme.palette.text.primary,
        },
        '& .PrivatePickersSlideTransition-root': {
            minHeight: '200px',
        },
    },
});

interface Props {
    id: string;
    type?: string;
    label?: string;
    fullWidth?: boolean;
    helperText?: string;
    error?: boolean;
    format?: string;
    placeholder?: string;
    disablePast?: boolean;
    enableErrorDisplay?: boolean;
    value?: any;
    onChange: (value: any) => void;
    minDate?: Date;
}
export function DateFieldSchedulePage(props: Props) {
    const classes: SXCssObject = createCssObject(useTheme());
    const {
        id,
        label,
        fullWidth,
        helperText,
        error,
        placeholder,
        format,
        enableErrorDisplay = false,
        value,
        minDate,
        onChange,
        ...other
    } = props;

    const disableWeekends = (date: any) => {
        return date.getDay() === 0 || date.getDay() === 6;
    };
    const ref = useRef<HTMLDivElement>(null);

    return (
        <Box sx={classes.datePicker} ref={ref}>
            <FormControl id={`${id}-root`} fullWidth={fullWidth} sx={classes.root} error={enableErrorDisplay && error}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DesktopDatePicker
                        shouldDisableDate={disableWeekends}
                        toolbarPlaceholder={placeholder}
                        value={value || minDate}
                        onChange={(v: Date | null) => {
                            onChange(v);
                        }}
                        minDate={minDate}
                        PopperProps={{
                            anchorEl: ref.current,
                            placement: 'bottom-end',
                        }}
                        PaperProps={{
                            sx: classes.calendarBg,
                        }}
                        inputFormat={format}
                        renderInput={(params: any) => (
                            <TextField
                                sx={{
                                    svg: { color: 'primary.main' },
                                    input: { height: 14, width: 'calc(100% - 65px)' },
                                }}
                                size="medium"
                                {...params}
                            />
                        )}
                        components={{
                            OpenPickerIcon: CalendarTodayOutlinedIcon,
                        }}
                        {...other}
                    />
                </LocalizationProvider>

                {enableErrorDisplay && (
                    <FormHelperText id={`${id}-helper`} sx={classes.helperText}>
                        {helperText}
                    </FormHelperText>
                )}
            </FormControl>
        </Box>
    );
}
