import { useTheme } from '@mui/material/styles';
import { getThemePrimaryMain } from 'components/common/Utilities';
import * as React from 'react';

function EditIcon(props: any) {
    const theme = useTheme();
    const themePrimaryMain = getThemePrimaryMain(theme);
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} {...props}>
            <path
                d="M17.222 4.524L6.965 17.46l-.126 4.246 4.282-.997L21.377 7.773z"
                stroke={`${themePrimaryMain}`}
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2.0004}
                fill="none"
            />
        </svg>
    );
}

export default EditIcon;
